import { Column } from '@tanstack/react-table';
import { useCallback } from 'react';

import { MDTableProps } from '@/components/common/MDTable/MDTable.types';
import { isFunctionType, isStringType } from '@/utils/variableTypes/variableTypes';

import { DebouncedInput } from './FilterInputs';

type Props<T extends object> = {
  column: Column<T, unknown>;
  isTableExpandable?: boolean;
  manualFiltering?: MDTableProps<T>['manualFiltering'];
};

const oneSpace = ' '; // it is necessary to trigger filtering in table with expandable rows

export const FilterCell = <T extends object>({ column, isTableExpandable, manualFiltering }: Props<T>) => {
  const { getFilterValue, setFilterValue, columnDef } = column;
  const filterValue = getFilterValue();
  const columnFilterValue = isStringType(filterValue) ? filterValue.trim() : filterValue;

  const onChange = useCallback((value: string) => {
    manualFiltering
      ? isFunctionType(columnDef.filterFn) && columnDef.filterFn(null, column.id, value, undefined)
      : setFilterValue(isTableExpandable ? value || oneSpace : value);
  }, []);

  return <DebouncedInput onChange={onChange} value={(columnFilterValue ?? '') as string} />;
};
