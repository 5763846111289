import { FormattedAttendance } from 'kadro-helpers/lib/types';
import moment from 'moment/moment';
import { useEffect, useMemo, useState } from 'react';

import { formatDuration } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTracker.helpers.ts';

export const useCurrentAttendanceAndBreakDuration = (currentAttendance: FormattedAttendance) => {
  const initialCurrentAttendanceDuration = moment().diff(moment(currentAttendance?.start_timestamp), 'seconds');
  const [currentAttendanceDuration, setCurrentAttendanceDuration] = useState(initialCurrentAttendanceDuration);

  useEffect(() => {
    if (!currentAttendance) return;
    const interval = setInterval(() => {
      setCurrentAttendanceDuration(moment().diff(moment(currentAttendance.start_timestamp), 'seconds'));
    }, 1000);

    return () => clearInterval(interval);
  }, [currentAttendance]);

  const currentAttendanceDurationFormatted = formatDuration(currentAttendanceDuration);
  const currentBreak = currentAttendance?.breaks && currentAttendance.breaks.find(br => !br.end_timestamp);
  const breaksDuration = useMemo(
    () =>
      currentAttendance?.breaks?.reduce((acc, br) => {
        const endTimestamp = br.end_timestamp || moment();
        return acc + moment(endTimestamp).diff(br.start_timestamp, 'seconds');
      }, 0),
    [currentAttendance],
  );

  return {
    currentAttendanceDurationFormatted,
    breaksDuration,
    currentBreak,
  };
};
