import { flexRender, Header } from '@tanstack/react-table';
import classNames from 'classnames';
import { useMemo } from 'react';

import { useMediaQuery } from '@/hooks';

import { ColumnsWidths, MDTableProps, TableRow } from '../../MDTable.types';
import { getCommonPinningStyles } from '../../TableUtils';

type Props<T extends object> = {
  columnsWidths: ColumnsWidths;
  footer: Header<TableRow<T>, unknown>;
  headers: Header<TableRow<T>, unknown>[];
  index: number;
  isExpand: boolean;
  isOverflowX: boolean;
  row: MDTableProps<T, object>['footerData'][number];
  tableStyles: MDTableProps<T>['tableStyles'];
};

export const FooterCell = <T extends object>({
  columnsWidths,
  footer,
  headers,
  index,
  isExpand,
  isOverflowX,
  row,
  tableStyles,
}: Props<T>) => {
  const { isAtLeastTabletLandscape } = useMediaQuery();
  const context = useMemo(() => ({ ...footer.getContext(), footerRowIndex: index }), [footer, index]);
  const header = useMemo(() => headers.find(h => h.id === footer.column.id), [headers, footer.column.id]);
  const footerCell = useMemo(
    () => flexRender(footer.column.columnDef.footer, context),
    [footer.column.columnDef.footer, context],
  );
  return (
    <th
      key={footer.id}
      className={classNames('mdTableFooter__cell', {
        'mdTableFooter__cell--dense': tableStyles.hasDensePadding,
      })}
      style={getCommonPinningStyles(footer.column, isOverflowX, columnsWidths, isAtLeastTabletLandscape)}
    >
      {!isAtLeastTabletLandscape &&
        (footerCell ? '' : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>)}
      {footerCell || (isExpand ? flexRender(row[footer.column.id], null) : null)}
    </th>
  );
};
