import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import { KebabMenu } from '@/components/common/Basic/KebabMenu/KebabMenu';
import { OVERTIME_COLLECTION } from '@/constants/absences';
import { ADD_ABSENCE_PROPOSAL_MODAL } from '@/constants/modalTypes.js';
import { downloadFile } from '@/utils/fileHelpers.js';

const messages = defineMessages({
  absenceOptionsCalendar: {
    id: 'absence.options.calendar',
    defaultMessage: 'Kalendarz nieobecności',
  },
  absenceOptionsHistory: {
    id: 'absence.options.history',
    defaultMessage: 'Zobacz historię',
  },
  absenceOptionsProposal: {
    id: 'absence.options.proposal',
    defaultMessage: 'Szczegóły wniosku',
  },
  absenceOptionsPrint: {
    id: 'absence.options.print',
    defaultMessage: 'Drukuj',
  },
});

const AbsenceViewOptions = (
  { getAbsenceHistory, showAbsenceCalendar, rowData, showModal, exportAbsenceItemToPdf },
  { intl },
) => {
  const isOvertimeCollectionProposal = rowData.category === OVERTIME_COLLECTION;
  const options = [
    {
      id: '1',
      icon: 'description',
      title: intl.formatMessage(messages.absenceOptionsProposal),
      onClick: () => showModal(ADD_ABSENCE_PROPOSAL_MODAL, rowData),
      show: true,
    },
    {
      id: '2',
      icon: 'print',
      title: intl.formatMessage(messages.absenceOptionsPrint),
      onClick: async () => {
        const url = await exportAbsenceItemToPdf(rowData);
        downloadFile(url);
      },
      show: true,
    },
    {
      id: '3',
      icon: 'date_range',
      title: intl.formatMessage(messages.absenceOptionsCalendar),
      onClick: async () => {
        await showAbsenceCalendar(rowData);
      },
      show: true,
    },
  ];

  if (!isOvertimeCollectionProposal) {
    const historyOption = {
      id: '0',
      icon: 'history',
      title: intl.formatMessage(messages.absenceOptionsHistory),
      onClick: async () => {
        await getAbsenceHistory(rowData);
      },
      show: true,
    };
    options.splice(0, 0, historyOption);
  }

  return <KebabMenu options={options} />;
};

AbsenceViewOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceViewOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceViewOptions.propTypes = {
  showModal: PropTypes.func,
  rowData: PropTypes.shape({
    status_for_searching: PropTypes.shape({
      pl: PropTypes.string,
    }),
    category: PropTypes.string,
  }),
  getAbsenceHistory: PropTypes.func,
  exportAbsenceItemToPdf: PropTypes.func,
  showAbsenceCalendar: PropTypes.func,
};

export default AbsenceViewOptions;
