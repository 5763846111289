import { ExpandedState } from '@tanstack/react-table';
import { parseMinutesToHumanForm } from 'kadro-helpers/lib/helpers';
import { IntlShape } from 'react-intl';

import { FilteringFnEnum, SortingFnEnum, TABLE_COLUMN_STICKY_ENUM } from '@/components/common/MDTable/MDTable.types';
import { absenceStatuses } from '@/constants/absences';
import {
  ATTENDANCE_ABSENCE,
  ATTENDANCE_EARLY_QUIT,
  ATTENDANCE_EARLY_START,
  ATTENDANCE_LATE_QUIT,
  ATTENDANCE_LATE_START,
  ATTENDANCE_WITHOUT_SHIFT,
  FINISHED_ATTENDANCE,
  UNFINISHED_ATTENDANCE,
} from '@/constants/attendanceDetailTypes';
import { HOUR_PLACEHOLDER } from '@/constants/strings';
import { AbsencesStoreState } from '@/redux-store/absences';
import { Attendance, AttendancesData } from '@/redux-store/attendances/attendancesData';
import { ContractsStoreState } from '@/redux-store/contracts';
import { EmployeesNamesStoreState } from '@/redux-store/employeesNames';
import { EmploymentConditionsFilterStoreState } from '@/redux-store/employmentConditionsFilter';
import { JobTitleFilterStoreState } from '@/redux-store/jobTitleFilter';
import { ScheduleLoanedEmployeesStoreState } from '@/redux-store/scheduleLoanedEmployees';
import { SettingsStoreState } from '@/redux-store/settings';
import { UserEmployeesStoreState } from '@/redux-store/userEmployees';
import { UserLocationsStoreState } from '@/redux-store/userLocations';
import { EmployeeWhole, UserLocation } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { MainDateStore } from '@/types/mainDateStore';
import { difference, isEmptyArray, mapId } from '@/utils/array/array.helpers';
import { getAttendanceStatus, getAttendanceStatuses } from '@/utils/attendance/attendance.utils';
import { getRelevantContractForDate } from '@/utils/contracts';
import { convertDateToHourMin } from '@/utils/dateHelper';
import { getDiffInMinutes } from '@/utils/dates/dates.helpers';
import { validateTimeFormat } from '@/utils/inputValidation';
import { filterAttendances, parseLoanedEmployees } from '@/utils/loanedAttendances';
import { transformToBoolDict } from '@/utils/objectHelpers/objectHelpers';

import { messages } from './AttendanceManyDaysTable.messages';
import { AttendanceManyDaysTableColumn, AttendanceRowItem, SubRowType } from './AttendanceManyDaysTable.types';
import { AttendanceAlertsCell, AttendanceStatusCell, AttendanceTimeCell, ExpandCell } from './Cells';

const BIG_WIDTH = 154;
const MEDIUM_WIDTH = 124;

export const getMobileDateColumn = ({ formatMessage }: IntlShape): AttendanceManyDaysTableColumn => ({
  accessorKey: 'mobileDate',
  cannotBeHidden: true,
  header: formatMessage(messages.date),
  sticky: TABLE_COLUMN_STICKY_ENUM.LEFT,
  filterFn: FilteringFnEnum.SUB_ROWS,
});

export const getColumns = ({ formatMessage }: IntlShape): AttendanceManyDaysTableColumn[] => [
  {
    accessorKey: 'locationName',
    header: formatMessage(messages.location),
    sticky: TABLE_COLUMN_STICKY_ENUM.LEFT,
    size: BIG_WIDTH,
    cell: props => <ExpandCell {...props} />,
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
    filterFn: FilteringFnEnum.SUB_ROWS,
  },
  {
    accessorKey: 'employee',
    cannotBeHidden: true,
    header: formatMessage(messages.employee),
    sticky: TABLE_COLUMN_STICKY_ENUM.LEFT,
    size: BIG_WIDTH,
    cell: props => <ExpandCell {...props} />,
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
    filterFn: FilteringFnEnum.SUB_ROWS,
  },
  {
    accessorKey: 'statusFormatted',
    header: formatMessage(messages.status),
    size: BIG_WIDTH,
    cell: props => <AttendanceStatusCell {...props} />,
    filterFn: FilteringFnEnum.SUB_ROWS,
  },
  {
    accessorKey: 'position',
    header: formatMessage(messages.jobTitle),
    size: BIG_WIDTH,
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
    filterFn: FilteringFnEnum.SUB_ROWS,
  },
  {
    accessorKey: 'start',
    header: formatMessage(messages.start),
    size: MEDIUM_WIDTH,
    cell: props => <AttendanceTimeCell {...props} />,
    filterFn: FilteringFnEnum.SUB_ROWS,
  },
  {
    accessorKey: 'end',
    header: formatMessage(messages.end),
    size: MEDIUM_WIDTH,
    cell: props => <AttendanceTimeCell {...props} />,
    filterFn: FilteringFnEnum.SUB_ROWS,
  },
  {
    accessorKey: 'breaksFormatted',
    header: formatMessage(messages.breakTime),
    size: MEDIUM_WIDTH,
    filterFn: FilteringFnEnum.SUB_ROWS,
  },
  {
    accessorKey: 'durationFormatted',
    header: formatMessage(messages.duration),
    size: BIG_WIDTH,
    filterFn: FilteringFnEnum.SUB_ROWS,
  },
  {
    accessorKey: 'alerts',
    centerColumn: true,
    enableColumnFilter: false,
    enableSorting: false,
    header: formatMessage(messages.alerts),
    sticky: TABLE_COLUMN_STICKY_ENUM.RIGHT,
    size: 110,
    cell: props => <AttendanceAlertsCell {...props} />,
    withoutVerticalPadding: true,
  },
];

export const getExpandedRows = (dataDates: StandardDate[], notExpandedDates: StandardDate[]): ExpandedState => {
  const notExpandedDict = transformToBoolDict(notExpandedDates);
  return dataDates.reduce((p, date, index) => (notExpandedDict[date] ? p : { ...p, [index]: true }), {});
};

export const getExpandingForDates = (
  dataDates: StandardDate[],
  expandedRows: ExpandedState,
): { expanded: StandardDate[]; notExpanded: StandardDate[] } => {
  const expanded = Object.keys(expandedRows).map(elIndex => dataDates[Number(elIndex)]);
  const notExpanded = difference(dataDates, expanded);
  return { expanded, notExpanded };
};

export const insertAttendanceTypeForWeekView = (
  startTimeAttendance: SubRowType['startTimeAttendance'],
  startTimeShift: SubRowType['startTimeShift'],
  endTimeAttendance: SubRowType['endTimeAttendance'],
  endTimeShift: SubRowType['endTimeShift'],
  attendanceWithoutShift: boolean,
) => {
  const typeOfAttendances = [];
  if (!validateTimeFormat(endTimeAttendance)) {
    typeOfAttendances.push(UNFINISHED_ATTENDANCE);
    if (attendanceWithoutShift) typeOfAttendances.push(ATTENDANCE_WITHOUT_SHIFT);
    return typeOfAttendances;
  }
  typeOfAttendances.push(FINISHED_ATTENDANCE);
  if (attendanceWithoutShift) {
    typeOfAttendances.push(ATTENDANCE_WITHOUT_SHIFT);
    return typeOfAttendances;
  }
  if (startTimeAttendance < startTimeShift) typeOfAttendances.push(ATTENDANCE_EARLY_START);
  if (startTimeAttendance > startTimeShift) typeOfAttendances.push(ATTENDANCE_LATE_START);
  if (endTimeAttendance < endTimeShift) typeOfAttendances.push(ATTENDANCE_EARLY_QUIT);
  if (endTimeAttendance > endTimeShift) typeOfAttendances.push(ATTENDANCE_LATE_QUIT);
  return typeOfAttendances;
};

const removeDaysWithoutAttendances = (data: AttendanceRowItem[]) => data.filter(item => !isEmptyArray(item.subRows));

const createAttendancesTableDataForLocation = (
  {
    location,
    relevantEmployeesPerDay,
  }: {
    location: UserLocation;
    relevantEmployeesPerDay: {
      relevantEmployees: {
        id: EmployeeWhole['id'];
        first_name: EmployeeWhole['first_name'];
        last_name: EmployeeWhole['last_name'];
        attendances: Attendance[];
        absence?: TODO;
        shifts: TODO[];
      }[];
      date: StandardDate;
    }[];
  },
  attendancesSettings: SettingsStoreState['attendancesSettings'],
  intl: IntlShape,
  selectedJobTitlesIds: JobTitleFilterStoreState['selectedJobtitles'][number]['id'][],
): AttendanceRowItem[] => {
  const attendanceStatuses = getAttendanceStatuses(intl);
  return relevantEmployeesPerDay.map(day => {
    const subRows: SubRowType[] = [];

    day.relevantEmployees.forEach(employee => {
      const matchingShiftIds = [];
      employee.attendances.forEach(attendance => {
        const attendanceWithShift = !!attendance.matching_shift;
        const attendanceWithAbsence = !!employee.absence;
        const attendanceOutOfRange = attendance.out_of_range_open;
        const currentDuration = attendance.end_timestamp
          ? attendance.duration
          : getDiffInMinutes(attendance.start_timestamp);
        const longAttendance = currentDuration > 12 * 60;

        const startTimeAttendance = convertDateToHourMin(attendance.start_timestamp);
        const startTimeShift = convertDateToHourMin(attendance.matching_shift?.start_timestamp);
        const endTimeAttendance = convertDateToHourMin(attendance.end_timestamp);
        const endTimeShift = convertDateToHourMin(attendance.matching_shift?.end_timestamp);
        const matchingShift = attendance.matching_shift;

        const position = attendanceWithShift ? attendance.matching_shift_job_title.title : '';
        const start = attendanceWithShift
          ? `${convertDateToHourMin(attendance.start_timestamp)} / ${convertDateToHourMin(
              attendance.matching_shift.start_timestamp,
            )}`
          : `${convertDateToHourMin(attendance.start_timestamp)}`;
        const end = attendanceWithShift
          ? `${convertDateToHourMin(attendance.end_timestamp)} / ${convertDateToHourMin(
              attendance.matching_shift.end_timestamp,
            )}`
          : `${convertDateToHourMin(attendance.end_timestamp)}`;
        const breaks = attendance.breaks.reduce((p, b) => p + getDiffInMinutes(b.start_timestamp, b.end_timestamp), 0);

        const latitude = attendance.out_of_range_open ? attendance.open_coords.lat : null;
        const longitude = attendance.out_of_range_open ? attendance.open_coords.lon : null;
        const hasMoreTime = attendance.early_in || attendance.late_out;
        const status = getAttendanceStatus(attendance);
        const type = insertAttendanceTypeForWeekView(
          startTimeAttendance,
          startTimeShift,
          endTimeAttendance,
          endTimeShift,
          !attendanceWithShift,
        );

        if (attendance.matching_shift) {
          matchingShiftIds.push(attendance.matching_shift.id);
        }

        if (matchingShift && !selectedJobTitlesIds.includes(matchingShift.job_title.id)) return;
        subRows.push({
          attendanceObject: attendance,
          attendanceOutOfRange,
          attendanceWithAbsence,
          attendanceWithoutShift: !attendanceWithShift,
          attendanceWithShift,
          breaks,
          breaksFormatted: parseMinutesToHumanForm(breaks),
          date: day.date,
          duration: currentDuration,
          durationFormatted: parseMinutesToHumanForm(Number.isNaN(currentDuration) ? 0 : currentDuration),
          employee: `${employee.first_name} ${employee.last_name}`,
          employeeObject: employee,
          end,
          endTimeAttendance,
          endTimeShift,
          hasEarlyIn: attendance.start_timestamp < attendance.matching_shift?.start_timestamp,
          hasLateOut: attendance.end_timestamp > attendance.matching_shift?.end_timestamp || !attendance.matching_shift,
          hasMoreTime,
          id: attendance.id,
          latitude,
          location,
          locationName: location.name,
          longAttendance,
          longitude,
          mobileDate: day.date,
          position,
          shift: attendance.matching_shift,
          start,
          startTimeAttendance,
          startTimeShift,
          status,
          statusFormatted: attendanceStatuses[status]?.text,
          type,
        });
      });

      const hasAbsenceBasedOnShits =
        employee.absence?.count_only_days_with_shifts && employee.absence?.status === absenceStatuses.accepted;

      if (!hasAbsenceBasedOnShits) {
        const employeeShiftsWithoutAttendance = employee.shifts.filter(shift => !matchingShiftIds.includes(shift.id));
        employeeShiftsWithoutAttendance.forEach(shift => {
          const status = getAttendanceStatus(null, shift);
          if (!selectedJobTitlesIds.includes(shift.job_title.id)) return;
          subRows.push({
            employee: `${employee.first_name} ${employee.last_name}`,
            position: shift.job_title.title,
            start: shift.start_timestamp
              ? `${HOUR_PLACEHOLDER} / ${convertDateToHourMin(shift.start_timestamp)}`
              : null,
            end: shift.end_timestamp ? `${HOUR_PLACEHOLDER} / ${convertDateToHourMin(shift.end_timestamp)}` : null,
            mobileDate: day.date,
            breaks: 0,
            breaksFormatted: parseMinutesToHumanForm(0),
            duration: 0,
            durationFormatted: parseMinutesToHumanForm(0),
            attendanceObject: {
              location,
              open_coords: {},
              close_coords: {},
            },
            employeeObject: employee,
            attendanceWithShift: false,
            attendanceWithoutShift: false,
            longAttendance: false,
            hasMoreTime: false,
            attendanceWithAbsence: false,
            attendanceOutOfRange: false,
            latitude: null,
            longitude: null,
            startTimeAttendance: null,
            startTimeShift: convertDateToHourMin(shift.start_timestamp),
            endTimeAttendance: null,
            endTimeShift: convertDateToHourMin(shift.end_timestamp),
            location,
            locationName: location.name,
            status,
            statusFormatted: attendanceStatuses[status]?.text,
            type: ATTENDANCE_ABSENCE,
          });
        });
      }
    });

    const filteredRows = subRows.filter(row =>
      attendancesSettings.some(setting => row.type.includes(setting.type) && setting.value),
    );

    return {
      date: day.date,
      subRows: filteredRows,
    };
  });
};

export const createAttendancesTableData = (
  locations: UserLocation[],
  dateArray: MainDateStore['dateArray'],
  userEmployees: UserEmployeesStoreState,
  selectedJobTitlesIds: JobTitleFilterStoreState['selectedJobtitles'][number]['id'][],
  selectedEmploymentConditionsFilter: EmploymentConditionsFilterStoreState['selected'],
  scheduleAbsences: AbsencesStoreState['scheduleAbsences'],
  attendancesSettings: SettingsStoreState['attendancesSettings'],
  userLocations: UserLocationsStoreState,
  hasManagerAccessToOtherLocations: boolean,
  contracts: ContractsStoreState,
  attendancesData: AttendancesData['attendances'],
  employeesNamesData: EmployeesNamesStoreState['data'],
  scheduleLoanedEmployees: ScheduleLoanedEmployeesStoreState['scheduleLoanedEmployees'],
  intl: IntlShape,
) => {
  const userLocationIds = mapId(userLocations);
  const result: AttendanceRowItem[] = [];

  dateArray.forEach(day =>
    result.push({
      date: day,
      subRows: [],
    }),
  );

  locations
    .map(location => {
      const relevantEmployeesPerDay = dateArray.map(currentDate => {
        const relevantEmployees = userEmployees
          .filter(employee => {
            const employeeContracts = contracts[employee.id] || [];
            const relevantContract = getRelevantContractForDate(employeeContracts, currentDate);
            const hasJobTitle = (relevantContract?.job_titles || []).some(({ job_title_id }) =>
              selectedJobTitlesIds.includes(job_title_id),
            );

            return (
              !employee.inactive &&
              (!hasManagerAccessToOtherLocations || employee.locations.some(l => userLocationIds.includes(l.id))) &&
              employee.locations.some(l => l.id === location.id) &&
              hasJobTitle &&
              selectedEmploymentConditionsFilter.some(({ id }) => id === employee.employment_conditions.template_id) &&
              (employee.shifts.some(s => s.date === currentDate && s.location.id === location.id) ||
                attendancesData[employee.id]?.some(a => a.date === currentDate && a.location.id === location.id) ||
                employee.availability_blocks.some(
                  availability => availability.date === currentDate && availability.count_hours_in_payroll,
                ) ||
                employee.availability_blocks.some(
                  availability => availability.date === currentDate && availability.count_hours_in_payroll,
                ) ||
                scheduleAbsences[employee.id]?.some(
                  absence => currentDate >= absence.from && currentDate <= absence.to,
                ))
            );
          })
          .map(employee => {
            const relevantShifts = employee.shifts.filter(s => s.date === currentDate && s.location.id === location.id);
            const relevantAttendances =
              attendancesData[employee.id]?.filter(a => a.date === currentDate && a.location.id === location.id) || [];
            const relevantAbsence = (scheduleAbsences[employee.id] || []).find(
              absence =>
                currentDate <= absence.to && currentDate >= absence.from && absence.status === absenceStatuses.accepted,
            );

            return {
              ...employee,
              shifts: relevantShifts,
              attendances: relevantAttendances,
              absence: relevantAbsence,
            };
          });

        const filteredAttendances = filterAttendances(attendancesData, [location.id], userEmployees, currentDate);

        const loanedEmployees = parseLoanedEmployees(
          filteredAttendances,
          employeesNamesData,
          scheduleLoanedEmployees,
          currentDate,
          [location.id],
        );
        return {
          date: currentDate,
          relevantEmployees: [...relevantEmployees, ...loanedEmployees],
        };
      });

      return {
        location,
        relevantEmployeesPerDay,
      };
    })
    .forEach(item => {
      const attendanceDays = createAttendancesTableDataForLocation(
        item,
        attendancesSettings,
        intl,
        selectedJobTitlesIds,
      );
      attendanceDays.forEach((day, index) => {
        result[index].subRows = [...result[index].subRows, ...day.subRows];
      });
    });
  return removeDaysWithoutAttendances(result);
};
