/* eslint-disable camelcase */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import OvertimeInformation from '@/components/absences/modals/AbsenceProposalModal/OvertimeInformation/OvertimeInformation';
import { MDInfoWithLabel } from '@/components/common/inputs/MDComponents.jsx';
import MDChip from '@/components/common/MDChip/MDChip.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { absenceStatuses, OVERTIME_COLLECTION } from '@/constants/absences.js';
import { ABSENCES_EDIT_HIDE } from '@/constants/Restrictions.js';
import useDelayFunction from '@/hooks/useDelayFunction/useDelayFunction.js';
import {
  getAbsenceDuration,
  getAbsenceStatusName,
  getAbsenceTypeName,
  getColorFromAbsenceStatus,
} from '@/utils/absenceHelpers.js';
import { downloadFile } from '@/utils/fileHelpers';

import { getLocationsDisabledIds } from '../AbsenceAddModal/AbsenceAddModal.helpers.js';
import {
  emptyAbsence,
  getAbsenceCreatedDate,
  getAbsenceDurationDetails,
  getAbsenceInterval,
} from './AbsenceProposalModal.helpers.js';
import { messages } from './AbsenceProposalModal.messages';

import './AbsenceProposalModal.scss';

const AbsenceProposalModal = (
  {
    editAbsence,
    deleteAbsence,
    showModal,
    onHide,
    exportAbsenceItemToPdf,
    restrictions,
    modalObject = {},
    deleteNewOvertimeCollections,
    locationSettings,
    userEmployees,
    getAbsence,
    isEmployee,
    userId,
    getAbsenceHistory,
  },
  context,
) => {
  const [absence, setAbsence] = useState(emptyAbsence);
  const [loading, setLoading] = useState(false);
  const isOvertimeProposal = absence.category === OVERTIME_COLLECTION;

  const delayFunction = useDelayFunction();

  const fetchAbsence = async id => {
    try {
      const newAbsence = await getAbsence(id);
      setAbsence(newAbsence);
    } catch (err) {
      onHide();
    } finally {
      setLoading(false);
    }
  };

  const isEditDisabled = useMemo(() => restrictions.includes(ABSENCES_EDIT_HIDE), [restrictions]);
  const canBeAbsenceStatusChanged = absence?.status === absenceStatuses.draft && !isEmployee && !isEditDisabled;

  useEffect(() => {
    if (showModal && modalObject && Object.keys(modalObject).length !== 0) {
      setLoading(true);
      setAbsence(emptyAbsence);
      fetchAbsence(modalObject.id);
    }
  }, [modalObject, showModal]);

  const handleEditAbsence = newStatus => {
    editAbsence(absence.id, newStatus);
    onHide();
  };

  const handleDeleteAbsence = async () => {
    await deleteAbsence(absence);
  };

  const handleDeleteOvertimeCollection = async () => {
    await deleteNewOvertimeCollections([absence.id]);
  };

  const getAbsenceHistoryFunc = () => {
    delayFunction(async () => {
      await getAbsenceHistory(modalObject, true);
    });
  };

  const handlePrinterClick = async () => {
    setLoading(true);
    const url = await exportAbsenceItemToPdf(absence, 'regular');
    downloadFile(url);
    setLoading(false);
  };

  const getFooterOptions = () => {
    if (isEmpty(modalObject)) return [];
    const employeeLocations = userEmployees.find(({ id }) => id === modalObject.employee_id).locations;
    const employeeLocationIds = employeeLocations.map(({ id }) => id);
    const locationsDisabledIds = getLocationsDisabledIds(locationSettings, modalObject.from, employeeLocationIds);

    const locationName =
      locationsDisabledIds.length === 1 ? employeeLocations.find(({ id }) => id === locationsDisabledIds[0]).name : '';

    const options = [
      {
        icon: 'print',
        text: <FormattedMessage id="absenceModal.option.print" defaultMessage="Drukuj wniosek" />,
        handleClick: handlePrinterClick,
      },
    ];
    if (!isEmployee) {
      const isRemoveDisabled = isEditDisabled || locationsDisabledIds.length > 0;
      let tooltipMessage;
      if (locationsDisabledIds.length > 0)
        tooltipMessage = context.intl.formatMessage(messages.scheduleBlocked, {
          locationName,
          locations: locationsDisabledIds.length,
        });

      const removeOption = {
        icon: 'remove_circle_outline',
        text: <FormattedMessage id="absenceModal.option.remove" defaultMessage="Usuń wniosek" />,
        handleClick: isOvertimeProposal ? handleDeleteOvertimeCollection : handleDeleteAbsence,
        disabled: isRemoveDisabled,
        tooltip: tooltipMessage,
      };
      options.splice(1, 0, removeOption);
    }
    if (userId === absence.employee_id && absence.status === absenceStatuses.draft) {
      const cancelOption = {
        icon: 'cancel',
        text: <FormattedMessage id="absenceModal.option.cancel" defaultMessage="Anuluj wniosek" />,
        handleClick: () => handleEditAbsence(absenceStatuses.canceled),
      };
      options.splice(1, 0, cancelOption);
    }
    if (!isOvertimeProposal) {
      const historyOption = {
        icon: 'history',
        text: <FormattedMessage id="absenceModal.option.history" defaultMessage="Historia" />,
        handleClick: getAbsenceHistoryFunc,
      };
      options.splice(0, 0, historyOption);
    }
    return options;
  };

  return (
    <div className="absenceProposalModal">
      <MDKadroModal
        show={showModal}
        onHide={onHide}
        onCancelFooter={canBeAbsenceStatusChanged ? () => handleEditAbsence(absenceStatuses.rejected) : onHide}
        onSubmit={() => handleEditAbsence(absenceStatuses.accepted)}
        modifiers={['narrow']}
        title={<FormattedMessage id="absenceModal.header" defaultMessage="Wniosek urlopowy" />}
        footerOptions={getFooterOptions()}
        cancelText={
          canBeAbsenceStatusChanged ? (
            <FormattedMessage id="absenceModal.button.reject" defaultMessage="Odrzuć" />
          ) : (
            <FormattedMessage id="absenceModal.button.close" defaultMessage="Zamknij" />
          )
        }
        disableConfirm={isEditDisabled}
        confirmText={<FormattedMessage id="absenceModal.button.accept" defaultMessage="Akceptuj" />}
        hideConfirmButton={!canBeAbsenceStatusChanged}
        loading={loading}
      >
        <div className="absenceProposalModal__content">
          <MDInfoWithLabel
            label={<FormattedMessage id="absenceModal.data.nr" defaultMessage="Nr wniosku" />}
            info={<span>{absence.request_number}</span>}
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="absenceModal.data.type" defaultMessage="Typ wniosku" />}
            info={<span>{getAbsenceTypeName(absence.category || absence.absence_type_name, context.intl)}</span>}
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="absenceModal.data.status" defaultMessage="Status wniosku" />}
            info={
              <MDChip modifiers={[getColorFromAbsenceStatus(absence.status)]}>
                {getAbsenceStatusName(absence.status, context.intl)}
              </MDChip>
            }
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="absenceModal.data.createdAtTimestamp" defaultMessage="Data złożenia" />}
            info={<span>{getAbsenceCreatedDate(absence)}</span>}
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="absenceModal.data.interval" defaultMessage="Okres" />}
            info={<span>{getAbsenceInterval(absence, context.intl)}</span>}
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="absenceModal.data.duration" defaultMessage="Czas trwania" />}
            info={
              <>
                <span>{getAbsenceDuration(absence, context.intl)}</span>{' '}
                <span className="absenceProposalModal__details">
                  {getAbsenceDurationDetails(absence, context.intl)}
                </span>
              </>
            }
          />
          {isOvertimeProposal && <OvertimeInformation absence={absence} />}
          <MDInfoWithLabel
            label={<FormattedMessage id="absenceModal.data.comment" defaultMessage="Notatka" />}
            info={<span>{absence.note || ''}</span>}
            infoModifiers={['note']}
          />
        </div>
      </MDKadroModal>
    </div>
  );
};

AbsenceProposalModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceProposalModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    id: PropTypes.string,
    employee_id: PropTypes.string,
    from: PropTypes.string,
  }),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  isEmployee: PropTypes.bool,
  editAbsence: PropTypes.func,
  deleteAbsence: PropTypes.func,
  exportAbsenceItemToPdf: PropTypes.func,
  onHide: PropTypes.func,
  getAbsenceHistory: PropTypes.func,
  userId: PropTypes.string,
  getAbsence: PropTypes.func,
  deleteNewOvertimeCollections: PropTypes.func,
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      employment_conditions: PropTypes.shape({ show_absences: PropTypes.bool }),
    }),
  ),
  locationSettings: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      disable_location_attendances_edit_until: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      disable_location_schedule_shifts_edit_until: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    }),
  }),
};

export default AbsenceProposalModal;
