import { flexRender, Table } from '@tanstack/react-table';
import { Fragment } from 'react/jsx-runtime';
import { FormattedMessage } from 'react-intl';

import { useMediaQuery } from '@/hooks';

import { ColumnsWidths, MDTableProps, TableRow } from '../../../MDTable.types';
import { checkIsTableSelectable, getCommonPinningStyles } from '../../../TableUtils';
import { FilterCell } from './FilterCell/FilterCell';

import './FilterRow.scss';

type Props<T extends object> = {
  columnsWidths: ColumnsWidths;
  isOverflowX: boolean;
  manualFiltering?: MDTableProps<T>['manualFiltering'];
  table: Table<TableRow<T>>;
};

export const FilterRow = <T extends object>({ columnsWidths, isOverflowX, manualFiltering, table }: Props<T>) => {
  const { isAtLeastTabletLandscape } = useMediaQuery();
  const isTableExpandable = table.getCanSomeRowsExpand();
  const isTableSelectable = checkIsTableSelectable(table);
  const indexRowForFilterText = isTableSelectable ? 1 : 0;

  return table.getHeaderGroups().map(headerGroup => (
    <tr key={headerGroup.id} className="mdTableFilterRow">
      {headerGroup.headers.map((header, index) => {
        const canBeFiltered = header.column.getCanFilter();
        if (!isAtLeastTabletLandscape && !canBeFiltered) return null;
        return (
          <Fragment key={header.id}>
            {index === indexRowForFilterText && !isAtLeastTabletLandscape && (
              <td className="mdTableFilterRow__cell mdTableFilterRow__cell--filter">
                <FormattedMessage id="filterRow.filter" defaultMessage="Filtruj" />
              </td>
            )}
            <td
              className="mdTableFilterRow__cell"
              colSpan={header.colSpan}
              style={getCommonPinningStyles(header.column, isOverflowX, columnsWidths, isAtLeastTabletLandscape)}
            >
              {canBeFiltered ? (
                <>
                  {!isAtLeastTabletLandscape && (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                  <FilterCell
                    column={header.column}
                    isTableExpandable={isTableExpandable}
                    manualFiltering={manualFiltering}
                  />
                </>
              ) : null}
            </td>
          </Fragment>
        );
      })}
    </tr>
  ));
};
