import { AttendanceTableEmployee } from './employees.types.ts';
import { Shift } from './shifts.types.ts';

export enum AttendanceStatus {
  ATTENDANCE_WITHOUT_MATCHING_SHIFT = 'ATTENDANCE_WITHOUT_MATCHING_SHIFT',
  LATE_START = 'LATE_START',
  NOT_PRESENT = 'NOT_PRESENT',
  ON_BREAK = 'ON_BREAK',
  PRESENT = 'PRESENT',
}

export interface EmployeeAttendance {
  bonus_amount: number;
  created_by_employee: boolean;
  date: string;
  duration: number;
  early_in: boolean;
  employee_id: string;
  end_timestamp: string;
  hours: string;
  id: string;
  late_out: boolean;
  location?: {
    id: string;
  };
  location_id?: string;
  out_of_range_close: boolean;
  out_of_range_open: boolean;
  start_timestamp: string;
  use_client_end_timestamp: boolean;
  use_client_start_timestamp: boolean;
  matching_shift: Shift;
  matching_shift_job_title?: Shift['job_title'];
  matching_shift_id?: { id: Shift['id'] };
  breaks: TODO[];
  labels: TODO[];
}

export interface AttendanceDataBlocks {
  animate: boolean;
  detailType: string;
  duration: number;
  endTimestamp: string;
  length: number;
  onClick: () => void;
  position: number;
  startTimestamp: string;
  type: {
    color: string;
    id: number;
    name: string;
  };
  width: number;
}

export interface AttendanceTableData {
  relevantEmployees: AttendanceTableEmployee[];
  viewToRender: {
    props: {
      employee: {
        id: string;
      };
      attendanceData: {
        blocks: AttendanceDataBlocks[];
      };
    };
  }[];
  locationId: string;
}
export interface DisplayText {
  id: string;
  defaultMessage: string;
}

export type AttendanceSettingType =
  | 'attendance_early_start'
  | 'attedance_late_start'
  | 'attendance_early_quit'
  | 'attendance_late_quit'
  | 'attendance_without_shift'
  | 'absence';

export interface AttendanceSetting {
  id: number;
  value: boolean;
  type: AttendanceSettingType;
  displayText: DisplayText;
}
