import { CellContext } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import MDAlert from '@/components/common/Basic/MDAlert/MDAlert';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { messages } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTable.messages';

import { PayrollRow } from '../../PayrollTable.types';

import './PayrollAlertCell.scss';

type Props = CellContext<TableRow<PayrollRow>, unknown>;

export const PayrollAlertCell = (props: Props) => {
  const intl = useIntl();
  const { warnings } = props.row.original;
  const {
    absenceOverlappedByAttendance,
    draftAbsence,
    longAttendance,
    outOfRangeAttendance,
    overlappingAttendances,
    unfinishedAttendance,
    unfinishedBreak,
    overlappingFreeDayMarkingWithAbsence,
    overlappingFreeDayMarkingWithAttendance,
    overlappingFreeDayMarkingWithShift,
  } = warnings;

  const freeDaysMarkingsWarnings = useMemo(
    () => ({
      overlappingFreeDayMarkingWithAbsence,
      overlappingFreeDayMarkingWithAttendance,
      overlappingFreeDayMarkingWithShift,
    }),
    [overlappingFreeDayMarkingWithAbsence, overlappingFreeDayMarkingWithAttendance, overlappingFreeDayMarkingWithShift],
  );

  const numberOfFreeDaysMarkingsWarnings = useMemo(
    () => Object.values(freeDaysMarkingsWarnings).filter(Boolean).length,
    [freeDaysMarkingsWarnings],
  );

  const freeDaysMarkingsWarningMessage = useMemo(
    () =>
      Object.keys(freeDaysMarkingsWarnings)
        .reduce(
          (prev, warning) =>
            !freeDaysMarkingsWarnings[warning] || !messages[warning]
              ? prev
              : [...prev, intl.formatMessage(messages[warning])],
          [],
        )
        .join('\n'),
    [freeDaysMarkingsWarnings, intl],
  );

  return (
    <div className="k-payrollAlertCell">
      <div className="k-payrollAlertCell__innerContainer">
        {absenceOverlappedByAttendance && (
          <MDAlert
            iconModifiers="table"
            icon="flight"
            label={intl.formatMessage(messages.absenceOverlappedByAttendance)}
          />
        )}
        {draftAbsence && (
          <MDAlert iconModifiers="table" icon="unpublished" label={intl.formatMessage(messages.draftAbsence)} />
        )}
        {longAttendance && (
          <MDAlert iconModifiers="table" icon="nights_stay" label={intl.formatMessage(messages.longAttendance)} />
        )}
        {outOfRangeAttendance && (
          <MDAlert
            iconModifiers="table"
            icon="wrong_location"
            label={intl.formatMessage(messages.outOfRangeAttendance)}
          />
        )}
        {overlappingAttendances && (
          <MDAlert
            iconModifiers="table"
            icon="punch_clock"
            label={intl.formatMessage(messages.overlappingAttendances)}
          />
        )}
        {unfinishedAttendance && (
          <MDAlert
            iconModifiers="table"
            icon="pending_actions"
            label={intl.formatMessage(messages.unfinishedAttendance)}
          />
        )}
        {unfinishedBreak && (
          <MDAlert iconModifiers="table" icon="warning" label={intl.formatMessage(messages.unfinishedBreak)} />
        )}
        {numberOfFreeDaysMarkingsWarnings > 0 && (
          <MDAlert
            iconModifiers="table"
            icon="person_alert"
            materialSymbols
            style={{ width: '24px', height: '24px' }}
            label={freeDaysMarkingsWarningMessage}
            numberOfAlerts={numberOfFreeDaysMarkingsWarnings > 1 ? numberOfFreeDaysMarkingsWarnings : null}
          />
        )}
      </div>
    </div>
  );
};
