import { Row } from '@tanstack/react-table';
import { useCallback } from 'react';

import { redirectToPayroll } from '@/actions/payroll/payroll';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { useAppDispatch } from '@/redux-store';

import { PayrollLocationRow } from './PayrollLocationTable.types';

export const usePayrollLocationTable = () => {
  const dispatch = useAppDispatch();

  const onRowClick = useCallback(
    (row: Row<TableRow<PayrollLocationRow>>) => {
      dispatch(redirectToPayroll(row.original.employee_id));
    },
    [dispatch],
  );

  return { onRowClick };
};
