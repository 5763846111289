import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';

import ErrorMessage from '@/components/common/Basic/ErrorMessage/ErrorMessage';
import Icon from '@/components/common/Basic/Icon/Icon';
import Popover from '@/components/common/Popover/Popover';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { DIRECTIONS } from '@/constants/ui.ts';
import useMultiSelectDragAndDrop from '@/hooks/useMultiSelectDragAndDrop/useMultiSelectDragAndDrop';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import './MDMultiSelectFooter.scss';

const MDMultiSelectFooter = ({
  disabled,
  selectedItems,
  deleteItem,
  customHeight,
  errorMessage,
  shouldSort = true,
  autoHeight,
  title,
  isDragAndDrop,
  setAllItems,
  allItems,
  readOnly,
  modifiers,
  titleTooltipMessage,
}) => {
  const itemClassNames = classnames('mdMultiSelectFooter__item', { 'mdMultiSelectFooter__item--readOnly': readOnly });
  const itemHideClassNames = 'mdMultiSelectFooter__item mdMultiSelectFooter__item--hide';

  const { items, draggable, setDraggable, handleDragStart, handleDragOver, handleDrop } = useMultiSelectDragAndDrop(
    selectedItems,
    shouldSort,
    setAllItems,
    allItems,
    itemClassNames,
    itemHideClassNames,
  );
  const boxClassNames = classnames('k-overlay-scrollbar mdMultiSelectFooter__box', {
    'k-overlay-scrollbar--auto-height': autoHeight,
    'k-overlay-scrollbar--readOnly': readOnly,
  });

  const footerTitleClassNames = classnames(
    'mdMultiSelectFooter__title',
    ...(modifiers || []).map(modifier => `mdMultiSelectFooter__title--${modifier}`),
    {
      'mdMultiSelectFooter__title--readOnly': readOnly,
    },
  );

  const labelClassNames = classnames(
    'mdMultiSelectFooter__label',
    ...(modifiers || []).map(modifier => `mdMultiSelectFooter__label--${modifier}`),
  );

  return (
    <div className="mdMultiSelectFooter">
      {title &&
        (title.length > 60 ? (
          <Tooltip content={titleTooltipMessage} yOffset={12} className="mdMultiSelectFooter__tooltip">
            <div className={footerTitleClassNames}>{title}</div>
          </Tooltip>
        ) : (
          <div className={footerTitleClassNames}>{title}</div>
        ))}
      <OverlayScrollbarsComponent className={boxClassNames} style={{ minHeight: customHeight }}>
        {items.map((item, index) => {
          const itemTooltipContent = item.label.length >= 60 ? item.label : '';

          return (
            <Tooltip
              key={item.value}
              content={itemTooltipContent}
              className="mdMultiSelectFooter__tooltip"
              yOffset={16}
            >
              <div
                className={item.classNames}
                draggable={isDragAndDrop && draggable}
                onDragStart={e => handleDragStart(e, item)}
                onDrop={handleDrop}
                onDragEnd={handleDrop}
              >
                <div className="mdMultiSelectFooter__content">
                  {isDragAndDrop && (
                    <div
                      className="mdMultiSelectFooter__dragHandle"
                      role="presentation"
                      onDragOver={e => handleDragOver(e, index)}
                      onMouseDown={() => setDraggable(true)}
                    >
                      <Icon type="material" name="drag_handle" className="mdMultiSelectFooter__dragHandleIcon" />
                    </div>
                  )}
                  <span style={{ backgroundColor: item.color }} className="mdMultiSelectFooter__bar" />
                  <span className={labelClassNames}>{item.label}</span>
                  {item.tooltipError && (
                    <Popover
                      content={<div>{item.tooltipError}</div>}
                      yOffset={10}
                      popoverStyle={{ fontSize: '12px', maxWidth: '300px' }}
                      position={DIRECTIONS.TOP}
                      showShadow={false}
                      centerOnMobile
                      isDark
                      noArrow
                      showOnHover
                      preventPopoverHide
                      childExitOffsetOnHover={-10}
                      popoverExitOffsetOnHover={7}
                    >
                      <div>
                        <Icon
                          type="material-outlined"
                          name="info"
                          className="k-icon-info mdMultiSelectFooter__errorIcon"
                        />
                      </div>
                    </Popover>
                  )}
                  {item?.description && (
                    <span className="mdMultiSelectFooter__descriptionLabel">{item.description}</span>
                  )}
                </div>
                <div className="mdMultiSelectFooter__actions">
                  {!disabled && deleteItem && (
                    <button onClick={() => deleteItem(item)}>
                      <span className="material-icons">clear</span>
                    </button>
                  )}
                </div>
              </div>
            </Tooltip>
          );
        })}
      </OverlayScrollbarsComponent>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
};

MDMultiSelectFooter.propTypes = {
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      sortValue: PropTypes.string,
    }),
  ).isRequired,
  deleteItem: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  autoHeight: PropTypes.bool,
  disabled: PropTypes.bool,
  customHeight: PropTypes.string,
  errorMessage: PropTypes.string,
  shouldSort: PropTypes.bool,
  isDragAndDrop: PropTypes.bool,
  setAllItems: PropTypes.func,
  allItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
  readOnly: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  titleTooltipMessage: PropTypes.string,
};

export default MDMultiSelectFooter;
