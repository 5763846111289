export const throttle = <T extends (...args: unknown[]) => void>(
  callback: T,
  delay: number = 500,
): ((...args: Parameters<T>) => void) => {
  let shouldWait = false;
  let waitingArgs: Parameters<T> | undefined;

  const timeoutFunction = () => {
    if (waitingArgs) {
      callback(...waitingArgs);
      waitingArgs = undefined;
      setTimeout(timeoutFunction, delay);
    } else {
      shouldWait = false;
    }
  };

  return (...args: Parameters<T>) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }
    callback(...args);
    shouldWait = true;
    setTimeout(timeoutFunction, delay);
  };
};
