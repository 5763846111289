import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import { connect } from 'react-redux';

import { deleteBudgetMetrics } from '@/actions/budgetMetrics';
import { showModal } from '@/actions/uiState';

import BudgetMetricsTable from './BudgetMetricsTable';

const mapStateToProps = ({ reducer }) => ({
  canEdit: !reducer.userPermissions.restrictions.some(restriction =>
    [
      RESTRICTIONS.BUDGET_TARGETS_HIDE,
      RESTRICTIONS.BUDGET_INFO_HIDE,
      RESTRICTIONS.BUDGET_TARGETS_DISABLE_EDIT,
      RESTRICTIONS.BUDGET_INFO_DISABLE_EDIT,
    ].includes(restriction),
  ),
});

const mapDispatchToProps = {
  showModal,
  deleteBudgetMetrics,
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetMetricsTable);
