import { CellContext } from '@tanstack/react-table';

import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { PayrollRow } from '../../PayrollTable.types';
import PayrollTableLabelsRedux from '../../PayrollTableLabels.redux';

type Props = CellContext<TableRow<PayrollRow>, unknown>;

export const PayrollLabelCell = (props: Props) => {
  const { labels = [] } = props.cell.row.original;

  if (isEmptyArray(labels) || !Array.isArray(labels)) return '-';

  return <PayrollTableLabelsRedux labelsFromRow={labels} numberOfMaxItems={3} />;
};
