import { IntlShape } from 'react-intl';

import { getHistoryForAttendance } from '@/actions/history';
import { DropDownOption } from '@/components/common/DropdownMenu/DropdownMenu';
import { AppDispatch } from '@/redux-store';
import { RcpPhoto } from '@/redux-store/rcpPhotos';

import { SubRowType } from '../../../AttendanceManyDaysTable.types';
import { messages } from './KebabOptions.messages';

export const getOptions = (
  attendanceObject: SubRowType['attendanceObject'],
  availablePhotos: RcpPhoto,
  dispatch: AppDispatch,
  hasAttendanceCoords: boolean,
  intl: IntlShape,
  isEditingDisabled: boolean,
  isGeolocationEnabled: boolean,
  showAttendanceMapModal: () => void,
  showEditModal: () => void,
  showPhotoPreviewModal: () => void,
): DropDownOption[] => [
  {
    id: 'edit',
    icon: 'create',
    title: isEditingDisabled
      ? intl.formatMessage(messages.attendancesTableOptionsEditBlocked)
      : intl.formatMessage(messages.attendancesTableOptionsEdit),
    onClick: showEditModal,
    disabled: isEditingDisabled,
    show: true,
  },
  {
    id: 'history',
    icon: 'history',
    title: intl.formatMessage(messages.attendancesTableOptionsHistory),
    onClick: () => dispatch(getHistoryForAttendance(attendanceObject)),
    show: true,
  },
  {
    id: 'images',
    icon: 'portrait',
    title: intl.formatMessage(messages.attendancesTableOptionsPhotos),
    onClick: showPhotoPreviewModal,
    show: !!availablePhotos,
  },
  {
    id: 'gps',
    icon: 'gps_fixed',
    title: intl.formatMessage(messages.attendancesTableOptionsLocation),
    onClick: showAttendanceMapModal,
    disabled: !hasAttendanceCoords,
    show: isGeolocationEnabled,
  },
];
