import { connect } from 'react-redux';

import { addShift } from '@/actions';
import { assignUsersOpenShift, changeOpenShift, createOpenShift, deleteOpenShift } from '@/actions/openShifts.js';
import { showDeleteOpenShiftConfirmModal } from '@/actions/schedule/openShifts';
import { closeOpenShiftModal } from '@/actions/uiState.js';

import AddOpenShiftModal from './AddOpenShiftModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showOpenShiftModal,
  modalObject: state.reducer.uiState.openShiftModalObject,
  userShiftblocks: state.reducer.userShiftblocks,
  userEmployees: state.reducer.userEmployees,
  userCustomTypes: state.reducer.userCustomTypes,
  demo: state.reducer.demo,
  selectedJobTitlesGrouped: state.reducer.jobtitleFilter.selectedJobtitlesGrouped,
  contracts: state.reducer.contracts,
  userJobTitles: state.reducer.userJobTitles,
});

const mapDispatchToProps = {
  onHide: closeOpenShiftModal,
  createOpenShift,
  changeOpenShift,
  deleteOpenShift,
  addShift,
  showDeleteOpenShiftConfirmModal,
  assignUsersOpenShift,
};
const AddOpenShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddOpenShiftModal);

export default AddOpenShiftModalContainer;
