import { FilterFn } from '@tanstack/react-table';

import { isEmptyArray } from '@/utils/array/array.helpers';
import { normalizeDiacritics } from '@/utils/stringHelpers';

import { FilteringFnEnum } from '../MDTable.types';

const customSubRowFilter: FilterFn<unknown> = (row, columnId, filterValue) => {
  if (!row.subRows || isEmptyArray(row.subRows)) return false;
  let isAnyRowVisible = false;
  const subRows = row.subRows.map(subRow => {
    const isVisible = normalizeDiacritics(subRow.original[columnId] || '')
      .toLocaleLowerCase()
      .includes(normalizeDiacritics(filterValue?.trim()).toLowerCase());
    if (isVisible) isAnyRowVisible = true;
    subRow.hiddenColumnsInRowForFilter = { ...(subRow.hiddenColumnsInRowForFilter || {}), [columnId]: !isVisible };
    return subRow;
  });
  row.subRows = subRows;
  return isAnyRowVisible;
};

export const filterFns: Record<string, FilterFn<unknown>> = {
  [FilteringFnEnum.SUB_ROWS]: customSubRowFilter,
};
