import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import PublishButton from '@/components/common/PublishButton.jsx';

const messages = defineMessages({
  tooltip: {
    id: 'payrollLocationLoadDataButton.tooltip',
    defaultMessage: 'Aby wczytać dane,\nuzupełnij wszystkie filtry',
  },
});

const PayrollLocationLoadDataButton = props => {
  const intl = useIntl();
  const {
    getPayrollLocationViewData,
    isLoadPayrollLocationButtonEnabled,
    multipleLocationFilter,
    selectedEmploymentConditions,
    selectedJobtitles,
  } = props;

  const isButtonEnabled =
    isLoadPayrollLocationButtonEnabled &&
    multipleLocationFilter.length > 0 &&
    selectedEmploymentConditions.length > 0 &&
    selectedJobtitles.length > 0;

  const onClick = useCallback(() => {
    getPayrollLocationViewData();
  }, [getPayrollLocationViewData]);

  const showTooltip =
    multipleLocationFilter.length === 0 || selectedEmploymentConditions.length === 0 || selectedJobtitles.length === 0;

  return (
    <PublishButton
      text={<FormattedMessage id="loadData" defaultMessage="Wczytaj" />}
      onClickFunc={onClick}
      key="getData"
      disabled={!isButtonEnabled}
      icon="update"
      tooltip={showTooltip ? intl.formatMessage(messages.tooltip) : null}
    />
  );
};

PayrollLocationLoadDataButton.propTypes = {
  getPayrollLocationViewData: PropTypes.func,
  isLoadPayrollLocationButtonEnabled: PropTypes.bool,
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  selectedEmploymentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  selectedJobtitles: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PayrollLocationLoadDataButton;
