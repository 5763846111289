import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { deleteAbsenceTypes } from '@/actions/absences';
import { showModal } from '@/actions/uiState';
import { ABSENCE_TYPE_MODAL } from '@/constants/modalTypes';
import { useAppDispatch } from '@/redux-store';

import { AbsenceTypeRow } from '../ManageAbsenceTypesView.types';
import { getOptions } from './AbsenceTypesOptionCell.utils';

export const useAbsenceTypesOptionCell = (rowData: AbsenceTypeRow) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const onEdit = useCallback(() => {
    dispatch(
      showModal(ABSENCE_TYPE_MODAL, {
        absenceType: rowData,
      }),
    );
  }, [dispatch, rowData]);

  const onDelete = useCallback(() => {
    dispatch(deleteAbsenceTypes([rowData]));
  }, [dispatch, rowData]);

  const options = useMemo(() => getOptions(onEdit, onDelete, intl, rowData), [onEdit, onDelete, intl, rowData]);

  return { options };
};
