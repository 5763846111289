import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import EmptyState from '@/components/emptyState/EmptyState.jsx';

const PayrollTableEmpty = () => (
  <EmptyState
    name="payrollCTA"
    imgSrc="/img/payrollCTA.png"
    emptyStateHeader={
      <FormattedMessage
        id="payroll.emptyTable.emptyFilterMsg"
        defaultMessage="Wybierz lokalizacje oraz pracownika i naciśnij przycisk Wczytaj"
      />
    }
    emptyStateText={
      <FormattedMessage id="payroll.emptyTable.emptyStateFilterTitle" defaultMessage="Brak wyników do wyświetlenia " />
    }
    ctaText=""
  />
);

export default memo(PayrollTableEmpty);
