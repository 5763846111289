import { Attendance } from '@/redux-store/attendances/attendancesData';
import { Label } from '@/types';

export enum PayloadStatus {
  UNAPPROVED = 'unapproved',
  APPROVED = 'approved',
}

export type PayrollRow = {
  date: string;
  from: string;
  to: string;
  workHours: number;
  breaks: string;
  absence: TODO;
  workTime: string;
  jobTitleName: string;
  locationName: string;
  nightWorkTime: string;
  overtime50: TODO;
  overtime100: string;
  potentialOvertime: string;
  fillUp: string;
  overtimeWeekly: string;
  plan: TODO;
  difference: TODO;
  bonusAmount: number;
  wage: string;
  payout: string;
  warnings: {
    absenceOverlappedByAttendance: TODO;
    draftAbsence: TODO;
    longAttendance: TODO;
    outOfRangeAttendance: TODO;
    overlappingAttendances: TODO;
    unfinishedAttendance: TODO;
    unfinishedBreak: TODO;
    overlappingFreeDayMarkingWithAbsence: TODO;
    overlappingFreeDayMarkingWithAttendance: TODO;
    overlappingFreeDayMarkingWithShift: TODO;
  };
  isUnapproved: boolean;
  isLoanedEmployee: boolean;
  disabledCheckbox: boolean;
  hasOvertimeToAccept: boolean;
  labels: Label[];
  freeDayMarkingName: string;
  attendanceId: Attendance['id'];
  matchingShiftFrom: string;
  matchingShiftTo: string;
  attendanceEarlyStartAccepted: boolean;
  attendanceLateEndAccepted: boolean;
};
