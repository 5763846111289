import classNames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import onClickOutside from 'react-onclickoutside';

import { isNumberType } from '@/utils/variableTypes/variableTypes';

import { formatChars } from './constants';
import { compareTime, compareWorkingHours, getInputValue, setChar } from './helpers';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import './MDTimeRangeInput.scss';

function MDTimeRangeInput({
  className,
  errorMessage,
  isFocused,
  label,
  max,
  modifiers = [],
  options = [],
  value,
  onChange,
  onBlur,
}) {
  const [isActive, setIsActive] = useState(false);
  const maskInputRef = useRef();

  const modifiersArray = Array.isArray(modifiers) ? modifiers : modifiers.split(' ');

  const inputClassName = classNames(
    'kmd-textInput',
    'mdTimeRangeInput',
    className,
    ...modifiersArray.map(modifier => `kmd-textInput--${modifier}`),
    {
      'kmd-textInput--valid': value && !errorMessage,
      'kmd-textInput--error': errorMessage,
    },
  );

  const filteredTimes = [...new Set(options.filter(time => compareTime(time, value)).sort(compareWorkingHours))];

  const handleOnItemClick = option => {
    setIsActive(false);
    onChange(option);
  };

  const handleInputChange = e => {
    if (!e.target.value) {
      return;
    }
    const { inputValue, newCursorPosition } = getInputValue(e.target.value);
    onChange(inputValue);
    if (isNumberType(newCursorPosition)) {
      setTimeout(() => {
        maskInputRef.current.setCursorPosition(newCursorPosition);
      }, 1);
    }
    setIsActive(true);
  };

  const handleKeyDown = e => {
    const { key } = e;
    let { value: inputValue } = e.target;
    const cursor = maskInputRef.current.getCursorPosition();
    if (key === 'Tab') {
      if (cursor < 6) {
        e.preventDefault();
        maskInputRef.current.setCursorPosition(6);
        inputValue = inputValue.substring(0, 6).replace(/_/g, '0') + inputValue.substring(6);
      } else {
        setIsActive(false);
        inputValue = inputValue.replace(/_/g, '0');
      }

      if (inputValue === '00:00-00:00') {
        inputValue = '00:00-23:59';
      }

      const out = { target: { value: inputValue } };
      handleInputChange(out);
    } else if (key === ' ') {
      if (inputValue.charAt(cursor) === '_') {
        inputValue = setChar(inputValue, cursor, '0');
        const out = { target: { value: inputValue } };
        handleInputChange(out);
      }
      maskInputRef.current.setCursorPosition(cursor + 1);
    }
  };

  MDTimeRangeInput.handleClickOutside = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (isFocused) {
      setIsActive(true);
      maskInputRef.current.onFocus();
    }
  }, []);

  return (
    <div className={inputClassName}>
      <div className="kmd-textInput__inputContainer">
        <InputMask
          autoFocus={isFocused}
          className="k-textInput"
          max={max}
          value={value}
          onClick={() => setIsActive(true)}
          onChange={handleInputChange}
          mask="99:59-99:59"
          maskChar="_"
          datatype="time"
          type="input"
          formatChars={formatChars}
          onKeyDown={handleKeyDown}
          ref={maskInputRef}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
          }}
        />
        <label>{label}</label>
      </div>

      <span className="kmd-textInput__bar" />

      <div className="kmd-textInput__error">{errorMessage}</div>

      {isActive && (
        <div className="mdTimeRangeInput__dropdown">
          <OverlayScrollbarsComponent className="k-overlay-scrollbar">
            {filteredTimes.map(option => (
              <div
                key={option}
                className="mdTimeRangeInput__listItem"
                onClick={() => handleOnItemClick(option)}
                role="none"
              >
                {option}
              </div>
            ))}
          </OverlayScrollbarsComponent>
        </div>
      )}
    </div>
  );
}

MDTimeRangeInput.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  isFocused: PropTypes.bool,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const clickOutsideConfig = {
  handleClickOutside: () => MDTimeRangeInput.handleClickOutside,
};

export default onClickOutside(MDTimeRangeInput, clickOutsideConfig);
