import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/redux-store';
import { selectPayoutSetting } from '@/selectors/payrollSettings.selectors';
import { selectTableData } from '@/selectors/reports.selectors';

import { getFooterData, getReportTableColumns, prepareData } from './reportsTable.helpers';

export const useReportsTable = () => {
  const intl = useIntl();
  const reportData = useAppSelector(selectTableData);
  const payoutSetting = useAppSelector(selectPayoutSetting);

  const columns = useMemo(() => getReportTableColumns(intl, payoutSetting), [intl, payoutSetting]);

  const data = useMemo(() => prepareData(reportData), [reportData]);

  const footerData = useMemo(() => getFooterData(reportData), [reportData]);

  return { columns, data, intl, footerData };
};
