import { Table } from '@tanstack/react-table';

import { isEmptyArray } from '@/utils/array/array.helpers';

import { ColumnsWidths, MDTableProps, TableRow } from '../MDTable.types';
import { FooterCell } from './FooterCell/FooterCell';

import './TableFooter.scss';

type Props<T extends object, FooterT extends object> = {
  columnsWidths: ColumnsWidths;
  footerData: MDTableProps<T, FooterT>['footerData'];
  isOverflowX: boolean;
  table: Table<TableRow<T>>;
  tableStyles: MDTableProps<T>['tableStyles'];
};

export const TableFooter = <T extends object, FooterT extends object>({
  columnsWidths,
  footerData,
  isOverflowX,
  table,
  tableStyles,
}: Props<T, FooterT>) => {
  if (isEmptyArray(footerData)) return null;
  const [{ headers: footers }] = table.getFooterGroups();
  const [{ headers }] = table.getHeaderGroups();
  const rowsExpanding = table.getState().footerRowsExpanding;

  return (
    <tfoot className="mdTableFooter">
      {footerData.map((row, index) => {
        const isExpand = rowsExpanding[index];
        return (
          <tr key={index} className="mdTableFooter__row">
            {footers.map(footer => (
              <FooterCell
                key={footer.id}
                {...{ columnsWidths, footer, headers, index, isExpand, isOverflowX, row, tableStyles }}
              />
            ))}
          </tr>
        );
      })}
    </tfoot>
  );
};
