import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { showModal } from '@/actions/uiState';
import PhotoPreviewContainer from '@/components/common/PhotoPreview/PhotoPreview.redux.js';
import PhotoPreviewPayrollIcon from '@/components/common/PhotoPreview/PhotoPreviewPayrollIcon.redux.js';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { ATTENDANCE_SUCCESS_COLOR } from '@/constants/colors.js';
import { ADD_SHIFT_MODAL, EDIT_ATTENDANCE_MODAL } from '@/constants/modalTypes.js';
import { useAppDispatch } from '@/redux-store';
import {
  ATTENDANCE_TOOLTIP_OFFSET_Y,
  getAttendanceWithoutMatchingShiftTooltip,
  getEndAttendanceTooltip,
  getStartAttendanceTooltip,
} from '@/utils/attendance/attendance.utils';
import { getEndAttendanceColor, getStartAttendanceColor } from '@/utils/attendanceHelpers';
import { mapTimestampsToShift } from '@/utils/dateHelper';

import {
  findRelevantBlockToType,
  getTimeColorWithoutMatchingShift,
  isAttendanceWithoutShift,
} from './AttendanceTimeSpan.helpers';

const AttendanceTimeSpan = props => {
  const {
    text,
    attendance,
    type,
    employee,
    isAttendanceEditingBlocked,
    isShiftEditingBlocked,
    blocks,
    showPhotoPreview = true,
  } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const triggerShowModal = useCallback(
    (modalType, modalData) => {
      dispatch(showModal(modalType, modalData));
    },
    [dispatch],
  );

  let className = isAttendanceEditingBlocked ? 'nonClickable' : 'clickable';
  let color = props.color || '#000';
  let tooltip = '';
  let component = null;
  let onClick = !isAttendanceEditingBlocked
    ? () => {
        triggerShowModal(EDIT_ATTENDANCE_MODAL, {
          employee,
          attendance,
        });
      }
    : () => {};
  if (attendance) color = ATTENDANCE_SUCCESS_COLOR;
  if (attendance && attendance.matching_shift) {
    if (type === 'start') {
      const startShift = `${attendance.matching_shift.date} ${
        attendance.matching_shift.working_hours.split('-')[0]
      }:00`;
      color = getStartAttendanceColor(attendance.start_timestamp, startShift, attendance.early_in);
      tooltip = getStartAttendanceTooltip(intl, attendance.start_timestamp, startShift, attendance.early_in);
      component = (
        <span className={className} role="presentation" style={{ color, display: 'block' }} onClick={onClick}>
          {attendance.hours.split('-')[0]}
        </span>
      );
    } else {
      const endShift = mapTimestampsToShift(attendance.matching_shift).end_timestamp;
      color = getEndAttendanceColor(attendance.end_timestamp, endShift, attendance.late_out);
      tooltip = getEndAttendanceTooltip(intl, attendance.end_timestamp, endShift, attendance.late_out);
      component = (
        <span className={className} role="presentation" style={{ color, display: 'block' }} onClick={onClick}>
          {attendance.hours.split('-')[1]}
        </span>
      );
    }
  } else if (attendance) {
    let attendanceText = '';
    if (type === 'start') {
      attendanceText = attendance.hours.split('-')[0];
    } else {
      attendanceText = attendance.hours.split('-')[1];
    }
    color = getTimeColorWithoutMatchingShift(type, attendance, blocks);
    tooltip = getAttendanceWithoutMatchingShiftTooltip(intl);
    const block = findRelevantBlockToType(type, blocks, attendance);

    const blockType = block?.type?.name;
    const attendanceIsWithoutShift = isAttendanceWithoutShift(blockType);
    if (attendanceIsWithoutShift && !employee.isLoaned) {
      className = !isShiftEditingBlocked ? 'clickable' : '';
      onClick = !isShiftEditingBlocked
        ? () => {
            triggerShowModal(ADD_SHIFT_MODAL, {
              employee,
              date: attendance.date,
              location: attendance.location,
              defaultHours: attendance.hours,
              attendanceToDelete: attendance,
            });
          }
        : () => {};
    }
    component = (
      <span className={className} role="presentation" onClick={onClick} style={{ color, display: 'block' }}>
        {attendanceText}
      </span>
    );
  } else {
    component = (
      <span role="presentation" style={{ color, display: 'block' }}>
        {text}
      </span>
    );
  }
  if (type === 'start' && attendance)
    return (
      <div>
        <Tooltip content={tooltip} yOffset={ATTENDANCE_TOOLTIP_OFFSET_Y}>
          {component}
        </Tooltip>
        {showPhotoPreview && (
          <PhotoPreviewContainer
            attendanceId={attendance.id}
            locationId={attendance.location.id}
            class="k-photoPreview--small"
          >
            <PhotoPreviewPayrollIcon attendanceId={attendance.id} />
          </PhotoPreviewContainer>
        )}
      </div>
    );

  return (
    <Tooltip content={tooltip} yOffset={ATTENDANCE_TOOLTIP_OFFSET_Y}>
      {component}
    </Tooltip>
  );
};

AttendanceTimeSpan.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  attendance: PropTypes.shape({
    start_timestamp: PropTypes.string,
    end_timestamp: PropTypes.string,
    early_in: PropTypes.bool,
    late_out: PropTypes.bool,
  }),
  type: PropTypes.string,
  employee: PropTypes.shape({}),
  showPhotoPreview: PropTypes.bool,
  isAttendanceEditingBlocked: PropTypes.bool,
  isShiftEditingBlocked: PropTypes.bool,
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceTimeSpan;
