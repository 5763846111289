import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import MDTable from '@/components/common/MDTable/MDTable';

import AbsenceTypeModal from '../modals/AbsenceTypeModal/AbsenceTypeModal.redux.js';
import { AbsenceTypesOptionCell } from './AbsenceTypesOptionCell/AbsenceTypesOptionCell';
import AbsenceTypesOptions from './AbsenceTypesOptions/AbsenceTypesOptions.jsx';
import { messages } from './ManageAbsenceTypesView.messages';
import { getColumns, tableStyles } from './ManageAbsenceTypesView.utils';

const OVERTIME_COLLECTION_ABSENCE_TYPE = {
  adding_by_employee: false,
  allow_attendance: false,
  code: 'Wzn',
  defined_in_hours: true,
  deleted_at_timestamp: null,
  has_limit: false,
  has_note: true,
  id: 'overtimeCollectionFakeId',
  is_default_uw_absence: false,
  is_paid: true,
  limit_period: null,
  limit_unit: null,
  name: 'Odbiór nadgodzin',
  reduce_uw_limit: false,
  requires_approval: false,
  short_name: 'ON',
  isOvertimeCollection: true,
};

const ManageAbsenceTypesView = ({ absencesTypes = [], deleteAbsenceTypes, showModal }) => {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);

  const tableData = useMemo(
    () =>
      [...absencesTypes, OVERTIME_COLLECTION_ABSENCE_TYPE]
        .filter(absenceType => absenceType.deleted_at_timestamp === null)
        .map(absenceType => ({
          ...absenceType,
          adding_by_employee_text: intl.formatMessage(
            absenceType.adding_by_employee
              ? messages.manageAbsenceTypesAddingByEmployeeTrue
              : messages.manageAbsenceTypesAddingByEmployeeFalse,
          ),
          has_limit_text: intl.formatMessage(
            absenceType.has_limit ? messages.manageAbsenceTypesHasLimitTrue : messages.manageAbsenceTypesHasLimitFalse,
            {},
          ),
          is_paid_text: intl.formatMessage(
            absenceType.is_paid ? messages.manageAbsenceTypesIsPaidTrue : messages.manageAbsenceTypesIsPaidFalse,
            {},
          ),
          disabledCheckbox: absenceType.is_default_uw_absence || absenceType.isOvertimeCollection || false,
        })),
    [absencesTypes, intl],
  );

  return (
    <>
      <AbsenceTypeModal />
      <MDTable
        data={tableData}
        columns={columns}
        enableRowSelection={row => !row.original.disabledCheckbox}
        options={<AbsenceTypesOptionCell />}
        tableOptions={<AbsenceTypesOptions showModal={showModal} deleteAbsenceTypes={deleteAbsenceTypes} />}
        tableStyles={tableStyles}
      />
    </>
  );
};

ManageAbsenceTypesView.propTypes = {
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  deleteAbsenceTypes: PropTypes.func,
  showModal: PropTypes.func,
};

export default ManageAbsenceTypesView;
