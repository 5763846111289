import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Icon from '@/components/common/Basic/Icon/Icon';
import PayrollReloadInstruction from '@/components/common/PayrollReloadInstruction/PayrollReloadInstruction';
import TopBarMultipleColumnFilter from '@/components/TopBars/filters/TopBarMultipleColumnFilter/TopBarMultipleColumnFilter.redux';
import { REVERSE_GREEN, REVERSE_RED } from '@/constants/buttonModifiers';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { isEmptyObject } from '@/utils/baseHelpers';

import PayrollLocationActionButtons from './PayrollLocationActionButtons/PayrollLocationActionButtons.redux';
import PayrollLocationSummary from './PayrollLocationSummary/PayrollLocationSummary.redux';

import './PayrollLocationButtonBar.scss';

const PayrollLocationButtonBar = ({
  visibleRows,
  toggleFilterRow,
  isLoadButtonEnabled,
  getPayrollLocationViewData,
  changePayrollDaysStatusForMultipleEmployees,
  rowSelection,
  isFetching,
  payrollApprovalEnabled,
  selectedLocations,
  selectedJobTitles,
  selectedEmploymentConditions,
}) => {
  const selectedEmployeesIds = useMemo(
    () =>
      Object.keys(rowSelection)
        .map(rowIndex => visibleRows[Number(rowIndex)]?.employee_id)
        .filter(Boolean),
    [rowSelection, visibleRows],
  );

  const handleApproveClick = useCallback(() => {
    if (!isEmptyArray(selectedEmployeesIds))
      changePayrollDaysStatusForMultipleEmployees(selectedEmployeesIds, 'approved');
  }, [changePayrollDaysStatusForMultipleEmployees, selectedEmployeesIds]);

  const handleUnapproveClick = useCallback(() => {
    if (!isEmptyArray(selectedEmployeesIds))
      changePayrollDaysStatusForMultipleEmployees(selectedEmployeesIds, 'unapproved');
  }, [changePayrollDaysStatusForMultipleEmployees, selectedEmployeesIds]);

  const visibleEmployeeIds = useMemo(() => visibleRows.map(item => item.employee_id), [visibleRows]);

  const showReloadInstruction =
    isLoadButtonEnabled &&
    !isFetching &&
    selectedLocations.length > 0 &&
    selectedJobTitles.length > 0 &&
    selectedEmploymentConditions.length > 0;

  return (
    <div className="k-payrollLocationButtonBar">
      <div className="k-payrollButtonBar__row">
        <div className="k-payrollLocationButtonBar__right">
          {showReloadInstruction && <PayrollReloadInstruction handleClick={getPayrollLocationViewData} />}
        </div>
        <div className="k-payrollLocationButtonBar__right">
          {payrollApprovalEnabled && (
            <>
              <Button modifiers={REVERSE_GREEN} onClick={handleApproveClick} disabled={isEmptyObject(rowSelection)}>
                <span className="material-icons">check</span>
                <FormattedMessage id="payrollLocationTable.approve.button" defaultMessage="Oznacz jako zatwierdzone" />
              </Button>
              <Button modifiers={REVERSE_RED} onClick={handleUnapproveClick} disabled={isEmptyObject(rowSelection)}>
                <span className="material-icons">remove_done</span>
                <FormattedMessage id="payrollLocationTable.unapprove.button" defaultMessage="Oznacz jako oczekujące" />
              </Button>
            </>
          )}
          <TopBarMultipleColumnFilter
            key="multipleColumnFilter"
            payrollType="newPayrollLocation"
            onlyIcon
            className="k-topBarChoice--small k-topBarChoice--pinned-right"
            icon={<Icon type="material-outlined" name="view_column" />}
          />
          <Button modifiers="onlyIcon reverse-gray" onClick={toggleFilterRow}>
            <i className="material-icons">search</i>
          </Button>
          <PayrollLocationActionButtons {...{ visibleEmployeeIds }} />
        </div>
      </div>
      <PayrollLocationSummary />
    </div>
  );
};

PayrollLocationButtonBar.propTypes = {
  toggleFilterRow: PropTypes.func,
  getPayrollLocationViewData: PropTypes.func,
  isLoadButtonEnabled: PropTypes.bool,
  changePayrollDaysStatusForMultipleEmployees: PropTypes.func,
  payrollApprovalEnabled: PropTypes.bool,
  visibleRows: PropTypes.arrayOf(PropTypes.shape({ employee_id: PropTypes.string })),
  rowSelection: PropTypes.shape({}),
  isFetching: PropTypes.bool,
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  selectedEmploymentConditions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};

export default PayrollLocationButtonBar;
