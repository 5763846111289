import moment from 'moment';

import { NOT_COUNTED_ABSENCE_STATUSES } from '@/constants/absences';
import { Absence, OvertimeCollection } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { Shift } from '@/types/shifts.types';

import { getIndexOfLastConnectingBlock } from '../../ScheduleShiftBlock/ScheduleShiftBlock.helpers';
import { BlockTypeEnum } from './blocks/blocks.types';

export const getRelevantAbsences = (absences: Absence[]) =>
  (absences || []).filter(absence => !NOT_COUNTED_ABSENCE_STATUSES.includes(absence.status));

export const getAbsencesForDate = (absences: Absence[], date: StandardDate, index: number) =>
  absences.reduce(
    (prev, absence) =>
      absence.from === date || (index === 0 && absence.from < date && absence.to >= date)
        ? [...prev, { ...absence, blockType: BlockTypeEnum.ABSENCE }]
        : prev,
    [],
  );

export const getUnfilteredAbsencesForDate = (absences: Absence[], date: StandardDate) =>
  absences.reduce(
    (prev, absence) =>
      absence.from === date || (absence.from < date && absence.to >= date)
        ? [...prev, { ...absence, blockType: BlockTypeEnum.ABSENCE }]
        : prev,
    [],
  );

export const checkHasAbsence = (absences: Absence[], date: StandardDate) =>
  absences.some(absence => absence.from < date && absence.to >= date);

export const getOvertimeCollectionsForDate = (employeeOvertimeCollections: OvertimeCollection[], date: StandardDate) =>
  (employeeOvertimeCollections || []).reduce(
    (prev, overtimeCollection) =>
      moment(overtimeCollection.start_timestamp).isSame(date, 'day')
        ? [...prev, { ...overtimeCollection, blockType: BlockTypeEnum.OVERTIME_COLLECTION }]
        : prev,
    [],
  );

export const getLastConnectingAbsenceShiftBlockIndex = (
  unfilteredAbsencesForDate: Absence[],
  overtimeCollectionsForDate: OvertimeCollection[],
  hasAbsencesAndOvertimeCollectionsInDay: boolean,
  shiftsForDate: Shift[],
) =>
  !hasAbsencesAndOvertimeCollectionsInDay &&
  (unfilteredAbsencesForDate.length === 1 || overtimeCollectionsForDate.length === 1)
    ? getIndexOfLastConnectingBlock(shiftsForDate, unfilteredAbsencesForDate[0] || overtimeCollectionsForDate[0])
    : null;
