import { FormattedAttendance } from 'kadro-helpers/lib/types';
import { useCallback, useState } from 'react';

import { startAttendance } from '@/actions/attendances.js';
import { ContentState } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTracker.helpers.ts';
import { DashboardTimeTrackerConfirm } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTrackerConfirm.tsx';
import { DashboardTimeTrackerContentBody } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTrackerContentBody.tsx';
import { DashboardTimeTrackerContentSummary } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTrackerContentSummary.tsx';
import { DashboardTimeTrackerSelectLocation } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTrackerSelectLocation.tsx';
import { useAppDispatch } from '@/redux-store';
import { LocationSettingsStoreState } from '@/redux-store/settings/locationSettings';
import { EmployeeShift, UserEmployee, UserLocation } from '@/types';

import { DashboardTimeTrackerContentHeader } from './DashboardTimeTrackerContentHeader.tsx';

type Props = {
  nextShift: EmployeeShift & { location: UserLocation };
  previousShift: EmployeeShift & { location: UserLocation };
  employee: UserEmployee;
  relevantLocations: UserLocation[];
  currentAttendance: FormattedAttendance;
  locationSettings: LocationSettingsStoreState;
  hidePopover: () => void;
};

export const DashboardTimeTrackerContent = ({
  nextShift,
  employee,
  relevantLocations,
  currentAttendance,
  locationSettings,
  previousShift,
  hidePopover,
}: Props) => {
  const [contentState, setContentState] = useState(ContentState.ATTENDANCE);
  const dispatch = useAppDispatch();

  const showConfirmState = useCallback(() => {
    setContentState(ContentState.NO_SHIFT_CONFIRM);
  }, []);

  const showSelectLocationState = useCallback(() => {
    setContentState(ContentState.SELECT_LOCATION);
  }, []);

  const showAttendanceState = useCallback(() => {
    setContentState(ContentState.ATTENDANCE);
  }, []);

  const showSummaryState = useCallback(() => {
    setContentState(ContentState.SUMMARY);
  }, []);

  return (
    <div className="dashboardTimeTracker__content">
      <DashboardTimeTrackerContentHeader nextShift={nextShift} contentState={contentState} hidePopover={hidePopover} />
      {contentState === ContentState.ATTENDANCE && (
        <DashboardTimeTrackerContentBody
          showConfirmState={showConfirmState}
          showSelectLocationState={showSelectLocationState}
          nextShift={nextShift}
          previousShift={previousShift}
          employee={employee}
          relevantLocations={relevantLocations}
          currentAttendance={currentAttendance}
          showSummaryState={showSummaryState}
          locationSettings={locationSettings}
        />
      )}
      {contentState === ContentState.SELECT_LOCATION && (
        <DashboardTimeTrackerSelectLocation
          relevantLocations={relevantLocations}
          nextShift={nextShift}
          handleCancel={showAttendanceState}
          showAttendanceState={showAttendanceState}
          employeeId={employee.id}
        />
      )}
      {contentState === ContentState.NO_SHIFT_CONFIRM && (
        <DashboardTimeTrackerConfirm
          handleConfirm={async () => {
            if (relevantLocations.length > 1) {
              showSelectLocationState();
              return;
            }
            await dispatch(startAttendance(relevantLocations[0].id, employee.id));
            showAttendanceState();
          }}
          handleCancel={showAttendanceState}
          previousShift={previousShift}
          nextShift={nextShift}
        />
      )}
      {contentState === ContentState.SUMMARY && (
        <DashboardTimeTrackerContentSummary
          currentAttendance={currentAttendance}
          showAttendanceState={showAttendanceState}
        />
      )}
    </div>
  );
};
