import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { KADRO_LIGHT_ORANGE_COLOR } from '@/constants/colors';
import { DATE_MODE_CUSTOM_ID, DATE_MODE_MONTH_ID, DATE_MODE_WEEK_ID } from '@/constants/dateModes';
import { initialMinMaxDates } from '@/constants/dates';
import { viewDateModes } from '@/constants/viewDateModes';
import { getMinAndMaxDate, getStaticCalendarRanges } from '@/utils/calendarHelpers';
import { getMinMaxDatesIncludedHireAndReleaseDate, getToday } from '@/utils/dateHelper';
import { getLocaleConfig } from '@/utils/locale/locale.utils';

import './KadroCalendarRange.scss';

const messages = defineMessages({
  maxSelection: {
    id: 'kadroCalendarRange.maxSelection',
    defaultMessage: 'Możesz wybrać maksymalnie {maxDayDiff} {maxDayDiff, plural, one {dzień} other {dni}}',
  },
});

const KadroCalendarRange = (
  {
    isMobileView,
    selectedRange,
    mainDateStore = {},
    modes = [],
    hidePicker,
    initialMinMaxDatesEnable,
    setLocalDateRanges,
    minDate,
    maxDate,
    ...props
  },
) => {
  const intl = useIntl();
  const tooltipRef = useRef(null);
  const currentRoute = useLocation().pathname;
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [minMaxDates, setMinMaxDates] = useState(initialMinMaxDates);
  const [selection, setSelection] = useState([
    {
      startDate: new Date(selectedRange.start || getToday()),
      endDate: new Date(selectedRange.end || getToday()),
      key: 'selection',
    },
  ]);
  const maxDayDiff = viewDateModes[currentRoute]?.maxDayDiff;

  useEffect(() => {
    setSelection([
      {
        startDate: new Date(selectedRange.start || getToday()),
        endDate: new Date(selectedRange.end || getToday()),
        key: 'selection',
      },
    ]);
    if (minDate || maxDate) {
      setMinMaxDates(getMinMaxDatesIncludedHireAndReleaseDate(minDate, maxDate));
    }
  }, [selectedRange]);

  const staticRanges = hidePicker ? [] : getStaticCalendarRanges(mainDateStore, modes, selectedRange);
  const predefinedRanges = createStaticRanges(staticRanges);
  const calendarClassName = classnames('kadroCalendarRange', {
    'kadroCalendarRange--hidePicker': hidePicker,
  });

  const getStartAndEndForSingleMode = (mode, start, end) => {
    if (mode === DATE_MODE_MONTH_ID) {
      return {
        start: moment(start).startOf('month').toDate(),
        end: moment(start).endOf('month').toDate(),
      };
    }
    if (mode === DATE_MODE_WEEK_ID) {
      return {
        start: moment(start).startOf('week').toDate(),
        end: moment(start).endOf('week').toDate(),
      };
    }

    return {
      start,
      end,
    };
  };

  const onChange = item => {
    const isCustomModeAllowed = modes.includes(DATE_MODE_CUSTOM_ID);
    const isOnlyOneModeAllowed = modes.length === 1;
    const shouldSelectAfterFirstSelection = !isCustomModeAllowed && isOnlyOneModeAllowed;
    setSelection([item.selection]);
    const isEndDateSelected = focusedRange[1] === 1;
    const { startDate: start } = item.selection;
    if (setLocalDateRanges) {
      setLocalDateRanges({ start, end: item.selection.endDate }, isEndDateSelected);
    }
    if (isEndDateSelected || item.selection.isStaticRange) {
      const { endDate: end } = item.selection;
      setTimeout(() => props.onChange({ start, end }), 10);
      if (!minDate && !maxDate) setMinMaxDates(initialMinMaxDates);
    } else if (shouldSelectAfterFirstSelection) {
      const mode = modes[0];
      const { start, end } = getStartAndEndForSingleMode(mode, item.selection.startDate, item.selection.endDate);
      setTimeout(() => props.onChange({ start, end }), 10);
      if (!minDate && !maxDate) setMinMaxDates(initialMinMaxDates);
    } else if (initialMinMaxDatesEnable) {
      setMinMaxDates(getMinAndMaxDate(start, currentRoute, initialMinMaxDatesEnable));
    }
  };

  const onMouseMove = useCallback(e => {
    if (tooltipRef.current) {
      const { clientX, clientY } = e;
      tooltipRef.current.style.left = `${clientX - 180}px`;
      tooltipRef.current.style.top = `${clientY - 40}px`;
    }
  }, []);

  return (
    <div className={calendarClassName} onMouseMove={onMouseMove}>
      {maxDayDiff && (
        <div className="kadroCalendarRange__tooltip" ref={tooltipRef}>
          {intl.formatMessage(messages.maxSelection, { maxDayDiff })}
        </div>
      )}
      <DateRangePicker
        staticRanges={predefinedRanges}
        focusedRange={focusedRange}
        onRangeFocusChange={setFocusedRange}
        onChange={onChange}
        moveRangeOnFirstSelection={false}
        ranges={selection}
        direction="horizontal"
        months={isMobileView ? 1 : 2}
        weekStartsOn={1}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        locale={getLocaleConfig(intl.locale).dateFnsLocale}
        monthDisplayFormat="LLLL yyyy"
        rangeColors={[KADRO_LIGHT_ORANGE_COLOR]}
        preventSnapRefocus
        calendarFocus="backwards"
        minDate={minMaxDates.min}
        maxDate={minMaxDates.max}
      />
    </div>
  );
};

KadroCalendarRange.propTypes = {
  hidePicker: PropTypes.bool,
  selectedRange: PropTypes.shape({
    start: PropTypes.shape({}),
    end: PropTypes.shape({}),
  }),
  onChange: PropTypes.func,
  mainDateStore: PropTypes.shape({}),
  isMobileView: PropTypes.bool,
  modes: PropTypes.arrayOf(PropTypes.number),
  initialMinMaxDatesEnable: PropTypes.bool,
  setLocalDateRanges: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default KadroCalendarRange;
