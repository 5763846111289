export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export enum ContentState {
  NO_SHIFT_CONFIRM = 'no_shift_confirm',
  SELECT_LOCATION = 'select_location',
  ATTENDANCE = 'attendance',
  SUMMARY = 'summary',
}

export const CONFIRM_MODIFIERS = ['orange', 'small', 'high'];

export const CANCEL_MODIFIERS = ['reverse-orange', 'small', 'high'];
