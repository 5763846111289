// AUTH
export const AUTH_USER_SUCCESSFUL = 'AUTH_USER_SUCCESSFUL';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';
export const TOO_MANY_AUTH_REQUEST_ERROR = 'TOO_MANY_AUTH_REQUEST_ERROR';
export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';
export const AUTH_USER_LOGOUT_ERROR = 'AUTH_USER_LOGOUT_ERROR';
export const NO_TOKEN_STORED = 'NO_TOKEN_STORED';
export const STORE_AUTH_TOKEN_SUCCESS = 'STORE_AUTH_TOKEN_SUCCESS';
export const GET_ROUTE_INIT_DATA = 'GET_ROUTE_INIT_DATA';
export const REQUEST_NEW_PASSWORD = 'REQUEST_NEW_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const BLOCKED_NOT_OWNER_ACCOUNT = 'BLOCKED_NOT_OWNER_ACCOUNT';

// FILTERS
export const CHANGE_JOBTITLE_FILTER = 'CHANGE_JOBTITLE_FILTER';
export const CHANGE_JOBTITLE_FILTER_GROUPED = 'CHANGE_JOBTITLE_FILTER_GROUPED';

export const CHANGE_LOCATION_FILTER = 'CHANGE_LOCATION_FILTER';
export const CHANGE_MULTIPLE_LOCATION_FILTER = 'CHANGE_MULTIPLE_LOCATION_FILTER';
export const CHANGE_SCHEDULE_LOCATION_FILTER = 'CHANGE_SCHEDULE_LOCATION_FILTER';

export const CHANGE_EMPLOYEE_SORTING = 'CHANGE_EMPLOYEE_SORTING';
export const CHANGE_SINGLE_EMPLOYEE_FILTER = 'CHANGE_SINGLE_EMPLOYEE_FILTER';

export const CHANGE_PAYROLL_SETTING = 'CHANGE_PAYROLL_SETTING';
export const CHANGE_PAYROLL_COLUMNS = 'CHANGE_PAYROLL_COLUMNS';
export const CHANGE_PAYROLL_LOCATION_COLUMNS = 'CHANGE_PAYROLL_LOCATION_COLUMNS';
// UI
export const SHOW_BLOCKING_LOADER = 'SHOW_BLOCKING_LOADER';
export const CONNECTION_ERROR = 'CONNECTION_ERROR';
export const SET_CURRENT_NAVBAR_ELEMENT_ID = 'SET_CURRENT_NAVBAR_ELEMENT_ID';
export const SHOW_SHADOW = 'SHOW_SHADOW';
export const HIDE_SHADOW = 'HIDE_SHADOW';
export const INCREASE_LOADER_COUNTER = 'INCREASE_LOADER_COUNTER';
export const DECREASE_LOADER_COUNTER = 'DECREASE_LOADER_COUNTER';
export const RESET_LOADER_COUNTER = 'RESET_LOADER_COUNTER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const REPLACE_MODAL = 'REPLACE_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const HIDE_MODAL_WITHOUT_CLEARING_MODAL_OBJECT = 'HIDE_MODAL_WITHOUT_CLEARING_MODAL_OBJECT';
export const CLEAR_MODAL_OBJECT = 'CLEAR_MODAL_OBJECT';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const MAIN_DATE_CHANGE_MODE = 'MAIN_DATE_CHANGE_MODE';
export const MAIN_DATE_CHANGE_MODE_AND_DATE = 'MAIN_DATE_CHANGE_MODE_AND_DATE';
export const MAIN_DATE_MOVE_LEFT = 'MAIN_DATE_MOVE_LEFT';
export const MAIN_DATE_MOVE_RIGHT = 'MAIN_DATE_MOVE_RIGHT';
export const MAIN_DATE_MOVE_TO_DATE = 'MAIN_DATE_MOVE_TO_DATE';
export const MAIN_DATE_CHANGE_CUSTOM_DATE = 'MAIN_DATE_CHANGE_CUSTOM_DATE';
export const SET_MAIN_DATE_STORE = 'SET_MAIN_DATE_STORE';

export const SHOW_NOTIFICATION_PANEL = 'SHOW_NOTIFICATION_PANEL';
export const CLOSE_NOTIFICATION_PANEL = 'CLOSE_NOTIFICATION_PANEL';

export const CHANGE_SEND_PANEL_VISIBILITY = 'CHANGE_SEND_PANEL_VISIBILITY';
export const CHANGE_ADD_SHIFT_MODAL_VISIBILITY = 'CHANGE_ADD_SHIFT_MODAL_VISIBILITY';
export const SHOW_OPEN_SHIFT_MODAL = 'SHOW_OPEN_SHIFT_MODAL';
export const CLOSE_OPEN_SHIFT_MODAL = 'CLOSE_OPEN_SHIFT_MODAL';
export const SHOW_APPLY_FOR_OPEN_SHIFT_MODAL = 'SHOW_APPLY_FOR_OPEN_SHIFT_MODAL';
export const CLOSE_APPLY_FOR_OPEN_SHIFT_MODAL = 'CLOSE_APPLY_FOR_OPEN_SHIFT_MODAL';
export const SHOW_ASSIGN_USERS_OPEN_SHIFT_MODAL = 'SHOW_ASSIGN_USERS_OPEN_SHIFT_MODAL';
export const CLOSE_ASSIGN_USERS_OPEN_SHIFT_MODAL = 'CLOSE_ASSIGN_USERS_OPEN_SHIFT_MODAL';
export const SHOW_REQUEST_TRADE_SHIFT_MODAL = 'SHOW_REQUEST_TRADE_SHIFT_MODAL';
export const CLOSE_REQUEST_TRADE_SHIFT_MODAL = 'CLOSE_REQUEST_TRADE_SHIFT_MODAL';
export const SHOW_COWORKER_TRADE_SHIFT_MODAL = 'SHOW_COWORKER_TRADE_SHIFT_MODAL';
export const CLOSE_COWORKER_TRADE_SHIFT_MODAL = 'CLOSE_COWORKER_TRADE_SHIFT_MODAL';
export const SHOW_APPROVAL_TRADE_SHIFT_MODAL = 'SHOW_APPROVAL_TRADE_SHIFT_MODAL';
export const CLOSE_APPROVAL_TRADE_SHIFT_MODAL = 'CLOSE_APPROVAL_TRADE_SHIFT_MODAL';

export const SHOW_ADD_AVAILABILITY_MODAL = 'SHOW_ADD_AVAILABILITY_MODAL';
export const CLOSE_ADD_AVAILABILITY_MODAL = 'CLOSE_ADD_AVAILABILITY_MODAL';

export const SHOW_EDIT_AVAILABILITY_MODAL = 'SHOW_EDIT_AVAILABILITY_MODAL';
export const CLOSE_EDIT_AVAILABILITY_MODAL = 'CLOSE_EDIT_AVAILABILITY_MODAL';

export const SHOW_ADD_ATTENDANCE_MODAL = 'SHOW_ADD_ATTENDANCE_MODAL';
export const CLOSE_ADD_ATTENDANCE_MODAL = 'CLOSE_ADD_ATTENDANCE_MODAL';

export const CHANGE_SORTING_PAYROLL_LOCATION = 'CHANGE_SORTING_PAYROLL_LOCATION';

export const SET_EMPLOYEES_VISIBLE_COLUMNS = 'SET_EMPLOYEES_VISIBLE_COLUMNS';

export const TOGGLE_REPEAT_SHIFT_FORM = 'TOGGLE_REPEAT_SHIFT_FORM';

export const SHOW_EXPORT_WIDGET_OPEN_BUTTON = 'SHOW_EXPORT_WIDGET_OPEN_BUTTON';
export const HIDE_EXPORT_WIDGET_OPEN_BUTTON = 'HIDE_EXPORT_WIDGET_OPEN_BUTTON';
export const SHOW_EXPORT_WIDGET = 'SHOW_EXPORT_WIDGET';
export const HIDE_EXPORT_WIDGET = 'HIDE_EXPORT_WIDGET';

export const SET_FILTERS_SUCCESS = 'SET_FILTERS_SUCCESS';
export const UPDATE_URL_FILTER_STATUS = 'UPDATE_URL_FILTER_STATUS';

// listUI actions for companymanage page

export const TOGGLE_COMPANYMANAGE_CHECKBOX = 'TOGGLE_COMPANYMANAGE_CHECKBOX';
export const TOGGLE_ALL_COMPANYMANAGE_CHECKBOXES = 'TOGGLE_ALL_COMPANYMANAGE_CHECKBOXES';
export const DESELECT_ALL_COMPANYMANAGE_CHECKBOXES = 'DESELECT_ALL_COMPANYMANAGE_CHECKBOXES';
export const TOGGLE_COMPANYMANAGE_MODAL = 'TOGGLE_COMPANYMANAGE_MODAL';
export const COMPANYMANAGE_START_EDIT = 'COMPANYMANAGE_START_EDIT';
export const COMPANYMANAGE_CHANGE_SORTING = 'COMPANYMANAGE_CHANGE_SORTING';
// SCHEDULE

export const CLEAR_VIEW_SCHEDULE_SUCCESS = 'CLEAR_VIEW_SCHEDULE_SUCCESS';
export const CLEAR_VIEW_SCHEDULE_ERROR = 'CLEAR_VIEW_SCHEDULE_ERROR';
export const DUPLICATE_PREVIOUS_VIEW_SUCCESS = 'DUPLICATE_PREVIOUS_VIEW_SUCCESS';

export const TOGGLE_SCHEDULE_ROW = 'TOGGLE_SCHEDULE_ROW';
export const TOGGLE_SCHEDULE_COLUMN = 'TOGGLE_SCHEDULE_COLUMN';
export const DESELECT_ALL_SCHEDULE = 'DESELECT_ALL_SCHEDULE';
export const MOVE_SELECTED_COLUMNS = 'MOVE_SELECTED_COLUMNS';

export const CHANGE_SCHEDULE_MODE = 'CHANGE_SCHEDULE_MODE';
export const FLIP_SCHEDULE_MODE = 'FLIP_SCHEDULE_MODE';
export const TOGGLE_SCHEDULE_SETTING = 'TOGGLE_SCHEDULE_SETTING';
export const TURN_SCHEDULE_SETTINGS_ON = 'TURN_SCHEDULE_SETTINGS_ON';

export const AUTO_GENERATE_ERROR = 'AUTO_GENERATE_ERROR';
export const AUTO_GENERATE_CANCELED = 'AUTO_GENERATE_CANCELED';
export const AUTO_GENERATE_IMPORT_SUCCESS = 'AUTO_GENERATE_IMPORT_SUCCESS';
export const AUTO_GENERATE_IMPORT_ERROR = 'AUTO_GENERATE_IMPORT_ERROR';
export const AUTO_GENERATE_SUBMIT_NO_EMPLOYEES_SELECTED = 'AUTO_GENERATE_SUBMIT_NO_EMPLOYEES_SELECTED';

export const FLEX_SCHEDULE_SELECTED_DAY_CHANGED = 'FLEX_SCHEDULE_SELECTED_DAY_CHANGED';
export const FLEX_SCHEDULE_DATA_CHANGED = 'FLEX_SCHEDULE_DATA_CHANGED';

export const SHOW_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL = 'SHOW_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL';
export const CLOSE_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL = 'CLOSE_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL';

export const SET_CAN_DELETE = 'SET_CAN_DELETE';

export const TOGGLE_NON_WORKING_DAYS = 'TOGGLE_NON_WORKING_DAYS';

export const IMPORT_SCHEDULE_DATA = 'IMPORT_SCHEDULE_DATA';
export const CLEAR_IMPORT_MODAL = 'CLEAR_IMPORT_MODAL';
export const UPLOAD_FILE_TO_IMPORT = 'UPLOAD_FILE_TO_IMPORT';
export const TOGGLE_IMPORT_MODAL_OVERLAY = 'TOGGLE_IMPORT_MODAL_OVERLAY';
export const TOGGLE_IMPORT_BUDGET_MODAL_OVERLAY = 'TOGGLE_IMPORT_BUDGET_MODAL_OVERLAY';
export const TOGGLE_IMPORT_BUDGET_MODAL_ERROR = 'TOGGLE_IMPORT_BUDGET_MODAL_ERROR';
export const CLEAR_SCHEDULE_IMPORTS = 'CLEAR_SCHEDULE_IMPORTS';
export const CLEAR_BUDGET_IMPORTS = 'CLEAR_BUDGET_IMPORTS';
export const CLEAR_BUDGET_TARGETS_IMPORTS = 'CLEAR_BUDGET_TARGETS_IMPORTS';
export const CLEAR_BUDGET_METRICS_IMPORTS = 'CLEAR_BUDGET_METRICS_IMPORTS';
export const CHANGE_SCHEDULE_TABLE_SIZE = 'CHANGE_SCHEDULE_TABLE_SIZE';
export const GET_BUDGET_TARGETS_SUCCESS = 'GET_BUDGET_TARGETS_SUCCESS';
export const GET_BUDGET_TARGET_USAGE_SUCCESS = 'GET_BUDGET_TARGET_USAGE_SUCCESS';
export const TOOGLE_AUTO_GENERATE_SCHEDULE_STATUS = 'TOOGLE_AUTO_GENERATE_SCHEDULE_STATUS';
export const SET_AUTOSCHEDULER_LOCATION = 'SET_AUTOSCHEDULER_LOCATION';
export const START_AUTOSCHEDULER = 'START_AUTOSCHEDULER';
export const FINISH_AUTOSCHEDULER = 'FINISH_AUTOSCHEDULER';
export const CANCEL_AUTOSCHEDULER = 'CANCEL_AUTOSCHEDULER';

// ENDPOINTS
export const GET_CURRENT_USER_SUCCESFUL = 'GET_CURRENT_USER_SUCCESFUL';
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';
export const GET_CURRENT_USER_PAYMENT_ERROR = 'GET_CURRENT_USER_PAYMENT_ERROR';
export const UPDATE_CURRENT_USER_SUCCESFUL = 'UPDATE_CURRENT_USER_SUCCESFUL';
export const UPDATE_CURRENT_USER_ERROR = 'UPDATE_CURRENT_USER_ERROR';
export const GET_CURRENT_COMPANY_SUCCESFUL = 'GET_CURRENT_COMPANY_SUCCESFUL';
export const GET_CURRENT_COMPANY_ERROR = 'GET_CURRENT_COMPANY_ERROR';
export const GET_COMPANY_LIMITS_USAGE_SUCCESSFUL = 'GET_COMPANY_LIMITS_USAGE_SUCCESSFUL';
export const UPDATE_CURRENT_COMPANY_SUCCESFUL = 'UPDATE_CURRENT_COMPANY_SUCCESFUL';
export const UPDATE_CURRENT_USER_LANGUAGE_SUCCESFUL = 'UPDATE_CURRENT_USER_LANGUAGE_SUCCESFUL';
export const GET_EMPLOYEES_SUCCESFUL = 'GET_EMPLOYEES_SUCCESFUL';
export const GET_JOBTITLES_SUCCESFUL = 'GET_JOBTITLES_SUCCESFUL';
export const GET_LOCATIONS_SUCCESFUL = 'GET_LOCATIONS_SUCCESFUL';
export const GET_ATTENDANCES_SUCCESFUL = 'GET_ATTENDANCES_SUCCESFUL';
export const GET_ATTENDANCES_IMAGES_SUCCESFUL = 'GET_ATTENDANCES_IMAGES_SUCCESFUL';
export const GET_ATTENDANCES_IMAGE_URLS_SUCCESFUL = 'GET_ATTENDANCES_IMAGE_URLS_SUCCESFUL';
export const GET_SHIFTBLOCKS_SUCCESFUL = 'GET_SHIFTBLOCKS_SUCCESFUL';
export const GET_SCHEDULE_SUCCESFUL = 'GET_SCHEDULE_SUCCESFUL';
export const GET_SCHEDULE_SUCCESFUL_FOR_EMPLOYEE = 'GET_SCHEDULE_SUCCESFUL_FOR_EMPLOYEE';
export const GET_SCHEDULE_SHIFTS_SUCCESFUL_FOR_EMPLOYEE = 'GET_SCHEDULE_SHIFTS_SUCCESFUL_FOR_EMPLOYEE';
export const GET_ATTENDANCES_SUCCESSFUL_FOR_EMPLOYEE = 'GET_ATTENDANCES_SUCCESSFUL_FOR_EMPLOYEE';
export const GET_SCHEDULE_FAIL = 'GET_SCHEDULE_FAIL';
export const GET_NOTIFICATIONS_SUCCESFUL = 'GET_NOTIFICATIONS_SUCCESFUL';
export const GET_NOTIFICATIONS_UNREAD_NUMBER_SUCCESFUL = 'GET_NOTIFICATIONS_UNREAD_NUMBER_SUCCESFUL';
export const DELETE_NOTIFICATION_SUCCESFUL = 'DELETE_NOTIFICATION_SUCCESFUL';
export const DELETE_ALL_NOTIFICATION_SUCCESFUL = 'DELETE_ALL_NOTIFICATION_SUCCESFUL';
export const MARK_ALL_NOTIFICATION_AS_READ_SUCCESFUL = 'MARK_ALL_NOTIFICATION_AS_READ_SUCCESFUL';
export const MARK_NOTIFICATION_AS_READ_SUCCESFUL = 'MARK_NOTIFICATION_AS_READ_SUCCESFUL';
export const MARK_NOTIFICATION_AS_READ_ERROR = 'MARK_NOTIFICATION_AS_READ_ERROR';
export const MARK_NOTIFICATION_AS_UNREAD_SUCCESFUL = 'MARK_NOTIFICATION_AS_UNREAD_SUCCESFUL';
export const MARK_NOTIFICATION_AS_UNREAD_ERROR = 'MARK_NOTIFICATION_AS_UNREAD_ERROR';
export const ADD_NEW_SHIFTBLOCK = 'ADD_NEW_SHIFTBLOCK';
export const DELETE_SHIFTBLOCK = 'DELETE_SHIFTBLOCK';
export const DELETE_SHIFTBLOCK_SET = 'DELETE_SHIFTBLOCK_SET';
export const UPDATE_SHIFTBLOCK = 'UPDATE_SHIFTBLOCK';
export const ADD_NEW_EMPLOYEE = 'ADD_NEW_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SET = 'DELETE_EMPLOYEE_SET';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_MASS_SUCCESS = 'UPDATE_EMPLOYEE_MASS_SUCCESS';
export const UPDATE_EMPLOYEE_MASS_SUCCESS_TOASTER = 'UPDATE_EMPLOYEE_MASS_SUCCESS_TOASTER';
export const UPDATE_EMPLOYEE_MASS_FAILURE = 'UPDATE_EMPLOYEE_MASS_FAILURE';
export const GET_AVA_TYPES_SUCCESFUL = 'GET_AVA_TYPES_SUCCESFUL';
export const ADD_NEW_AVA_TYPE = 'ADD_NEW_AVA_TYPE';
export const DELETE_AVA_TYPE = 'DELETE_AVA_TYPE';
export const DELETE_AVA_TYPE_SET = 'DELETE_AVA_TYPE_SET';
export const UPDATE_AVA_TYPE = 'UPDATE_AVA_TYPE';
export const ADD_NEW_JOBTITLE = 'ADD_NEW_JOBTITLE';
export const NOTIFY_ADD_JOB_TITLE_SUCCESS = 'NOTIFY_ADD_JOB_TITLE_SUCCESS';
export const NOTIFY_ADD_JOB_TITLE_ERROR = 'NOTIFY_ADD_JOB_TITLE_ERROR';
export const DELETE_JOBTITLE = 'DELETE_JOBTITLE';
export const DELETE_JOBTITLE_SET = 'DELETE_JOBTITLE_SET';
export const UPDATE_JOBTITLE = 'UPDATE_JOBTITLE';
export const ADD_NEW_LOCATION = 'ADD_NEW_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SET = 'DELETE_LOCATION_SET';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const ADD_SHIFT_TO_EMPLOYEE = 'ADD_SHIFT_TO_EMPLOYEE';
export const ADD_SHIFTS_TO_EMPLOYEES = 'ADD_SHIFTS_TO_EMPLOYEES';
export const ADD_SHIFT_SUCCESFUL = 'ADD_SHIFT_SUCCESFUL';
export const ADD_SHIFTS_SUCCESFUL = 'ADD_SHIFTS_SUCCESFUL';
export const ADD_SHIFT_FAILURE = 'ADD_SHIFT_FAILURE';
export const EDIT_SHIFT_SUCCESFUL = 'EDIT_SHIFT_SUCCESFUL';
export const EDIT_SHIFT_FAILURE = 'EDIT_SHIFT_FAILURE';
export const DELETE_SHIFT = 'DELETE_SHIFT';
export const DELETE_MASS_SHIFTS_SUCCESFUL = 'DELETE_MASS_SHIFTS_SUCCESFUL';
export const ADD_MASS_SHIFTS_SUCCESFUL = 'ADD_MASS_SHIFTS_SUCCESFUL';
export const ADD_MASS_SHIFTS_FAILURE = 'ADD_MASS_SHIFTS_FAILURE';
export const EDIT_MASS_SHIFTS_SUCCESFUL = 'EDIT_MASS_SHIFTS_SUCCESFUL';
export const PUBLISH_DRAFT_SHIFTS_SUCCESFUL = 'PUBLISH_DRAFT_SHIFTS_SUCCESFUL';
export const DELETE_UNPUBLISHED_SHIFTS_SUCCESFUL = 'DELETE_UNPUBLISHED_SHIFTS_SUCCESFUL';
export const DELETE_MULTIPLE_SHIFTS_SUCCESFUL = 'DELETE_MULTIPLE_SHIFTS_SUCCESFUL';
export const DELETE_MULTIPLE_SHIFTS_ERROR = 'DELETE_MULTIPLE_SHIFTS_ERROR';
export const ADD_MASS_EMPLOYEES = 'ADD_MASS_EMPLOYEES';
export const ADD_MASS_EMPLOYEES_FAILURE = 'ADD_MASS_EMPLOYEES_FAILURE';
export const SWAP_SHIFTS_SUCCESS = 'SWAP_SHIFTS_SUCCESS';
export const SWAP_SHIFTS_FAILURE = 'SWAP_SHIFTS_FAILURE';

// Availabilities
export const GET_AVAILABILITIES_SUCCESFUL = 'GET_AVAILABILITIES_SUCCESFUL';
export const ADD_AVAILABILITY_SUCCESFUL = 'ADD_AVAILABILITY_SUCCESFUL';
export const DELETE_AVAILABILITY_SUCCESFUL = 'DELETE_AVAILABILITY_SUCCESFUL';
export const CHANGE_AVAILABILITY_SUCCESFUL = 'CHANGE_AVAILABILITY_SUCCESFUL';
export const UPDATE_EMPLOYEE_AVAILABILITIES = 'UPDATE_EMPLOYEE_AVAILABILITIES';
export const DELETE_AVAILABILITIES_ERROR = 'DELETE_AVAILABILITIES_ERROR';
export const DELETE_AVAILABILITIES_SUCCESFUL = 'DELETE_AVAILABILITIES_SUCCESFUL';

// Attendances
export const ADD_ATTENDANCE_SUCCESFUL = 'ADD_ATTENDANCE_SUCCESFUL';
export const ADD_MASS_ATTENDANCE_SUCCESSFUL = 'ADD_MASS_ATTENDANCE_SUCCESSFUL';
export const ADD_ATTENDANCE_ERROR = 'ADD_ATTENDANCE_ERROR';
export const ADD_MATCHING_SHIFT_TO_ATTENDANCES = 'ADD_MATCHING_SHIFT_TO_ATTENDANCES';
export const CHANGE_ATTENDANCE_SUCCESFUL = 'CHANGE_ATTENDANCE_SUCCESFUL';
export const CHANGE_MULTIPLE_ATTENDANCES_SUCCESFUL = 'CHANGE_MULTIPLE_ATTENDANCES_SUCCESFUL';
export const ADD_ATTENDANCE_BONUS_SUCCESFUL = 'ADD_ATTENDANCE_BONUS_SUCCESFUL';
export const DELETE_ATTENDANCE_SUCCESFUL = 'DELETE_ATTENDANCE_SUCCESFUL';
export const DELETE_ATTENDANCES_SUCCESSFUL = 'DELETE_ATTENDANCES_SUCCESSFUL';
export const DELETE_ATTENDANCES_ERROR = 'DELETE_ATTENDANCES_ERROR';
export const SET_ATTENDANCES_SEARCH_STRING = 'SET_ATTENDANCES_SEARCH_STRING';
export const START_ATTENDANCE_SUCCESFUL = 'START_ATTENDANCE_SUCCESFUL';
export const END_ATTENDANCE_SUCCESFUL = 'END_ATTENDANCE_SUCCESFUL';
export const START_ATTENDANCE_ERROR = 'START_ATTENDANCE_ERROR';
export const END_ATTENDANCE_ERROR = 'END_ATTENDANCE_ERROR';
export const CHANGE_ATTENDANCES_MODE = 'CHANGE_ATTENDANCES_MODE';
export const TOGGLE_ATTENDANCE_SELECT = 'TOGGLE_ATTENDANCE_SELECT';
export const SELECT_ALL_ATTENDANCES = 'SELECT_ALL_ATTENDANCES';
export const UNSELECT_ALL_ATTENDANCES = 'UNSELECT_ALL_ATTENDANCES';
export const TOGGLE_ATTENDANCE_SETTINGS = 'TOGGLE_ATTENDANCE_SETTINGS';
export const SELECT_ALL_ATENDANCES_SETTINGS = 'SELECT_ALL_ATENDANCES_SETTINGS';
export const DESELLECT_ALL_ATTENDNACES_SETTINGS = 'DESELLECT_ALL_ATTENDNACES_SETTINGS';
export const ASSIGN_LABEL_TO_ATTENDANCE = 'ASSIGN_LABEL_TO_ATTENDANCE';
export const UNASSIGN_LABEL_FROM_ATTENDANCE = 'UNASSIGN_LABEL_FROM_ATTENDANCE';
export const ASSIGN_LABEL_TO_ATTENDANCE_ERROR = 'ASSIGN_LABEL_TO_ATTENDANCE_ERROR';
export const UNASSIGN_LABEL_TO_ATTENDANCE_ERROR = 'UNASSIGN_LABEL_TO_ATTENDANCE_ERROR';
// History objects
export const ADD_HISTORY_OBJECT = 'ADD_HISTORY_OBJECT';

// availability
export const ADD_EMP_AVA = 'ADD_EMP_AVA';
export const UPDATE_EMP_AVA = 'UPDATE_EMP_AVA';
export const DELETE_EMP_AVA = 'DELETE_EMP_AVA';

// CopyPaste actions
export const COPY_CONTENT = 'COPY_CONTENT';
export const SET_CAN_PASTE = 'SET_CAN_PASTE';
export const SET_CAN_COPY = 'SET_CAN_COPY';

// Device info
export const UPDATE_WINDOW_SIZE = 'UPDATE_WINDOW_SIZE';
export const GET_USER_MEDIA = 'GET_USER_MEDIA';
export const GET_GEOLOCATION = 'GET_GEOLOCATION';

// New user
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_NEW_COMPANY_DETAILS = 'SET_NEW_COMPANY_DETAILS';

// Demo
export const SET_DEMO_ACCOUNT = 'SET_DEMO_ACCOUNT';
export const CLEAR_DEMO_DATA = 'CLEAR_DEMO_DATA';

// Schedule Templates
export const GET_TEMPLATES_SUCCESFUL = 'GET_TEMPLATES_SUCCESFUL';
export const DELETE_TEMPLATE_SUCCESFUL = 'DELETE_TEMPLATE_SUCCESFUL';
export const ADD_TEMPLATES_SHIFT_SUCCESFUL = 'ADD_TEMPLATES_SHIFT_SUCCESFUL';
export const EDIT_TEMPLATES_SHIFT_SUCCESFUL = 'EDIT_TEMPLATES_SHIFT_SUCCESFUL';
export const DELETE_TEMPLATES_SHIFT_SUCCESFUL = 'DELETE_TEMPLATES_SHIFT_SUCCESFUL';
export const DELETE_UNPUBLISHED_TEMPLATES_SHIFTS_SUCCESFUL = 'DELETE_UNPUBLISHED_TEMPLATES_SHIFTS_SUCCESFUL';
export const CHANGE_TEMPLATE_NAME_SUCCESFUL = 'CHANGE_TEMPLATE_NAME_SUCCESFUL';
export const PUBLISH_DRAFT_TEMPLATE_SHIFTS_SUCCESFUL = 'PUBLISH_DRAFT_TEMPLATE_SHIFTS_SUCCESFUL';
export const CHANGE_CURRENT_TEMPLATE_SUCCESFUL = 'CHANGE_CURRENT_TEMPLATE_SUCCESFUL';
export const ADD_NEW_TEMPLATE_SUCCESFUL = 'ADD_NEW_TEMPLATE_SUCCESFUL';
export const DELETE_OPEN_SHIFT_TEMPLATE = 'DELETE_OPEN_SHIFT_TEMPLATE';

// User Permissions
export const UPDATE_PERMISSIONS_SUCCESSFUL = 'UPDATE_PERMISSIONS_SUCCESSFUL';
export const SET_PRODUCTION_QUOTAS_PERMISSION_SUCCESFUL = 'SET_PRODUCTION_QUOTAS_PERMISSION_SUCCESFUL';
export const SET_USER_RESTICTIONS_SUCCESSFUL = 'SET_USER_RESTICTIONS_SUCCESSFUL';

// Settings
export const GET_LOCATION_SETTINGS_SUCCESSFUL = 'GET_LOCATION_SETTINGS_SUCCESSFUL';
export const CHANGE_LOCATION_SETTINGS_SUCCESSFUL = 'CHANGE_LOCATION_SETTINGS_SUCCESSFUL';
export const CHANGE_MASS_LOCATIONS_SETTINGS_SUCCESSFUL = 'CHANGE_MASS_LOCATIONS_SETTINGS_SUCCESSFUL';
export const SET_COMPANY_SETTINGS_SUCCESSFUL = 'SET_COMPANY_SETTINGS_SUCCESSFUL';
export const GET_USERS_SETTINGS_SUCCESFUL = 'GET_USERS_SETTINGS_SUCCESFUL';
export const SET_USERS_SETTINGS_SUCCESFUL = 'SET_USERS_SETTINGS_SUCCESFUL';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_SUCCESSFUL = 'CHANGE_PASSWORD_SUCCESSFUL';

// NotyficationBar
export const SHOW_NOTIFICATION_BAR = 'SHOW_NOTIFICATION_BAR';
export const CLOSE_NOTIFICATION_BAR = 'CLOSE_NOTIFICATION_BAR';

// ExportModal
export const TOGGLE_EXPORT_MODAL = 'TOGGLE_EXPORT_MODAL';

// Open Shifts
export const GET_OPEN_SHIFTS_SUCCESFUL = 'GET_OPEN_SHIFTS_SUCCESFUL';
export const CREATE_OPEN_SHIFT_SUCCESFUL = 'CREATE_OPEN_SHIFT_SUCCESFUL';
export const CREATE_OPEN_SHIFT_ERROR = 'CREATE_OPEN_SHIFT_ERROR';
export const CHANGE_OPEN_SHIFT_SUCCESFUL = 'CHANGE_OPEN_SHIFT_SUCCESFUL';
export const DELETE_OPEN_SHIFT_SUCCESFUL = 'DELETE_OPEN_SHIFT_SUCCESFUL';
export const MASS_DELETE_OPEN_SHIFTS_SUCCESFUL = 'MASS_DELETE_OPEN_SHIFTS_SUCCESFUL';
export const USER_APPLY_OPEN_SHIFT_SUCCESFUL = 'USER_APPLY_OPEN_SHIFT_SUCCESFUL';
export const USER_REMOVE_APPLICATION_OPEN_SHIFT_SUCCESFUL = 'USER_REMOVE_APPLICATION_OPEN_SHIFT_SUCCESFUL';
export const GET_OPEN_SHIFT_SUCCESFUL = 'GET_OPEN_SHIFT_SUCCESFUL';
export const ASSIGN_USER_OPEN_SHIFT_SUCCESFUL = 'ASSIGN_USER_OPEN_SHIFT_SUCCESFUL';
export const PUBLISH_DRAFT_OPEN_SHIFTS_SUCCESFUL = 'PUBLISH_DRAFT_OPEN_SHIFTS_SUCCESFUL';

// Production Quotas
export const GET_PRODUCTION_QUOTAS_SUCCESFUL = 'GET_PRODUCTION_QUOTAS_SUCCESFUL';
export const CHANGE_PRODUCTION_QUOTAS_SUCCESFUL = 'CHANGE_PRODUCTION_QUOTAS_SUCCESFUL';
export const GET_PRODUCTION_QUOTAS_PAYROLL_SUCCESFUL = 'GET_PRODUCTION_QUOTAS_PAYROLL_SUCCESFUL';

// OfflineMsg
export const OFFLINE_MESSAGE_ON = 'OFFLINE_MESSAGE_ON';
export const OFFLINE_MESSAGE_OFF = 'OFFLINE_MESSAGE_OFF';

// HelpModal
export const TOGGLE_HELP_MODAL = 'TOGGLE_HELP_MODAL';

// Trade Shifts
export const CREATE_TRADE_SHIFT_SUCCESFUL = 'CREATE_TRADE_SHIFT_SUCCESFUL';
export const CHANGE_TRADE_SHIFT_SUCCESFUL = 'CHANGE_TRADE_SHIFT_SUCCESFUL';
export const DELETE_TRADE_SHIFT_SUCCESFUL = 'DELETE_TRADE_SHIFT_SUCCESFUL';
export const GET_TRADE_SHIFTS_SUCCESFUL = 'GET_TRADE_SHIFTS_SUCCESFUL';
export const CLOSE_TRADE_SHIFT_SUCCESFUL = 'CLOSE_TRADE_SHIFT_SUCCESFUL';
export const CHANGE_STATUS_TRADE_SHIFT_SUCCESFUL = 'CHANGE_STATUS_TRADE_SHIFT_SUCCESFUL';
export const CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL = 'CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL';

// Working Rules Violations
export const GET_WORKING_RULES_VIOLATIONS = 'GET_WORKING_RULES_VIOLATIONS';
export const GET_WORKING_RULES_VIOLATIONS_SUCCESS = 'GET_WORKING_RULES_VIOLATIONS_SUCCESS';
export const UPDATE_WORKING_RULES_VIOLATIONS_SUCCESS = 'UPDATE_WORKING_RULES_VIOLATIONS_SUCCESS';
export const GET_WORKING_RULES_VIOLATIONS_FOR_EMPLOYEE_SUCCESS = 'GET_WORKING_RULES_VIOLATIONS_FOR_EMPLOYEE_SUCCESS';
export const GET_WORKING_RULES_VIOLATIONS_FAILURE = 'GET_WORKING_RULES_VIOLATIONS_FAILURE';
export const ACTIVATE_VERIFY_WORKING_RULES_BUTTON = 'ACTIVATE_VERIFY_WORKING_RULES_BUTTON';
export const DEACTIVATE_VERIFY_WORKING_RULES_BUTTON = 'DEACTIVATE_VERIFY_WORKING_RULES_BUTTON';
export const CLEAR_WORKING_RULES = 'CLEAR_WORKING_RULES';
export const CLEAR_WORKING_RULES_FOR_EMPLOYEE_SUCCESS = 'CLEAR_WORKING_RULES_FOR_EMPLOYEE_SUCCESS';

// Payroll UI
export const PAYROLL_LOCATION_SET_SELECTED = 'PAYROLL_LOCATION_SET_SELECTED';
export const PAYROLL_LOCATION_TOGGLE_SELECTED = 'PAYROLL_LOCATION_TOGGLE_SELECTED';
export const TOGGLE_PAYROLL_SETTINGS_MODAL = 'TOGGLE_PAYROLL_SETTINGS_MODAL';
export const SET_BONUS_VALUES = 'SET_BONUS_VALUES';

export const SET_PAYROLL_SEARCH_STRING = 'SET_PAYROLL_SEARCH_STRING';

// Breaks
export const ADD_BREAK_SUCCESFUL = 'ADD_BREAK_SUCCESFUL';
export const CHANGE_BREAK_SUCCESFUL = 'CHANGE_BREAK_SUCCESFUL';
export const DELETE_BREAK_SUCCESFUL = 'DELETE_BREAK_SUCCESFUL';
export const START_BREAK_SUCCESFUL = 'START_BREAK_SUCCESFUL';
export const END_BREAK_SUCCESFUL = 'END_BREAK_SUCCESFUL';
export const START_BREAK_ERROR = 'START_BREAK_ERROR ';
export const END_BREAK_ERROR = 'END_BREAK_ERROR ';

export const TOGGLE_IMPORT_OPEN_SHIFTS_MODAL = 'TOGGLE_IMPORT_OPEN_SHIFTS_MODAL';

// General modal toggle
export const TOGGLE_MAIN_MODAL = 'TOGGLE_MAIN_MODAL';

export const TOGGLE_OPEN_SHIFT_ROW_SELECT = 'TOGGLE_OPEN_SHIFT_ROW_SELECT';

export const ACCEPT_CURRENT_REGULATIONS = 'ACCEPT_CURRENT_REGULATIONS';

export const CHANGE_DEFAULT_VIEW_DATE_STORE = 'CHANGE_DEFAULT_VIEW_DATE_STORE';
export const CHANGE_ALL_DEFAULT_VIEW_DATE_STORES = 'CHANGE_ALL_DEFAULT_VIEW_DATE_STORES';

// Custom sorting
export const START_CUSTOM_SORTING = 'START_CUSTOM_SORTING';
export const GET_CUSTOM_SORTING_SUCCESS = 'GET_CUSTOM_SORTING_SUCCESS';
export const CHANGE_CUSTOM_SORTING = 'CHANGE_CUSTOM_SORTING';
export const CHANGE_CUSTOM_SORTING_SUCCESS = 'CHANGE_CUSTOM_SORTING_SUCCESS';

// Schedule cycle
export const GET_SCHEDULE_STATS = 'GET_SCHEDULE_STATS';
export const GET_MONTHLY_NORMS = 'GET_MONTHLY_NORMS';
export const GET_HOLIDAYS = 'GET_HOLIDAYS';

export const CHANGE_SORTING_NAME_TYPE = 'CHANGE_SORTING_NAME_TYPE'; // Use last/first name in sorting first
export const CHANGE_SCHEDULE_SEARCH_STRING = 'CHANGE_SCHEDULE_SEARCH_STRING';
export const GET_SCHEDULE_INIT_DATA = 'GET_SCHEDULE_INIT_DATA';

// Employment Conditions
export const GET_EMPLOYMENT_CONDITIONS_SUCCESFUL = 'GET_EMPLOYMENT_CONDITIONS_SUCCESFUL';
export const ADD_EMPLOYMENT_CONDITION = 'ADD_EMPLOYMENT_CONDITION';
export const UPDATE_EMPLOYMENT_CONDITION = 'UPDATE_EMPLOYMENT_CONDITION';
export const DELETE_EMPLOYMENT_CONDITION = 'DELETE_EMPLOYMENT_CONDITION';
export const MASS_DELETE_EMPLOYMENT_CONDITIONS = 'MASS_DELETE_EMPLOYMENT_CONDITIONS';
export const INIT_EMPLOYMENT_CONDITION_FILTER = 'INIT_EMPLOYMENT_CONDITION_FILTER';
export const CHANGE_EMPLOYMENT_CONDITION_FILTER = 'CHANGE_EMPLOYMENT_CONDITION_FILTER';

// Devices
export const GET_DEVICES_SUCCESFUL = 'GET_DEVICES_SUCCESFUL';
export const SAVE_SETTINGS_DEVICES_SUCCESFUL = 'SAVE_SETTINGS_DEVICES_SUCCESFUL';
export const ADD_DEVICES_SUCCESFUL = 'ADD_DEVICES_SUCCESFUL';
export const DELETE_DEVICES_SUCCESFUL = 'DELETE_DEVICES_SUCCESFUL';

// Budget Estimates
export const GET_BUDGET_ESTIMATES_SUCCESS = 'GET_BUDGET_ESTIMATES_SUCCESS';
export const EDIT_BUDGET_ESTIMATES_SUCCESS = 'EDIT_BUDGET_ESTIMATES_SUCCESS';
export const DELETE_BUDGET_ESTIMATES_SUCCESS = 'DELETE_BUDGET_ESTIMATES_SUCCESS';
export const ADD_MULTIPLE_ESTIMATES_SUCCESS = 'ADD_MULTIPLE_ESTIMATES_SUCCESS';
export const ADD_MULTIPLE_ESTIMATES_ERROR = 'ADD_MULTIPLE_ESTIMATES_ERROR';
export const EDIT_BUDGET_ESTIMATES_ERROR = 'EDIT_BUDGET_ESTIMATES_ERROR';

// Budget Targets
export const EDIT_BUDGET_TARGETS_SUCCESS = 'EDIT_BUDGET_TARGETS_SUCCESS';
export const EDIT_BUDGET_TARGETS_ERROR = 'EDIT_BUDGET_TARGETS_ERROR';

// Overtime
export const GET_OVERTIME_SUCCESS = 'GET_OVERTIME_SUCCESS';
export const GET_OVERTIME_STATS_SUCCESS = 'GET_OVERTIME_STATS_SUCCESS';
export const HIDE_REDUNDANT_OVERTIME_STATS = 'HIDE_REDUNDANT_OVERTIME_STATS';

// Recommended Schedule
export const GET_RECOMMENDED_SCHEDULE_SUCCESS = 'GET_RECOMMENDED_SCHEDULE_SUCCESS';
export const ADD_RECOMMENDED_SCHEDULE_SUCCESS = 'ADD_RECOMMENDED_SCHEDULE_SUCCESS';
export const DELETE_RECOMMENDED_SCHEDULE_SUCCESS = 'DELETE_RECOMMENDED_SCHEDULE_SUCCESS';
export const ADD_RECOMMENDED_SCHEDULE_ERROR = 'ADD_RECOMMENDED_SCHEDULE_ERROR';

// Payments
export const GET_PAYMENT_DATA_SUCCESSFULL = 'GET_PAYMENT_DATA_SUCCESSFULL';
export const CHANGE_PAYMENT_NOTIFICATION_DATA = 'CHANGE_PAYMENT_NOTIFICATION_DATA';
export const GET_UNLOCK_PAYMENT_DATA = 'GET_UNLOCK_PAYMENT_DATA';
export const GET_UNLOCK_PAYMENT_DATA_SUCCESSFULL = 'GET_UNLOCK_PAYMENT_DATA_SUCCESSFULL';
export const CHANGE_PAYMENT_DATA_SUCCESSFULL = 'CHANGE_PAYMENT_DATA_SUCCESSFULL';
export const REDIRECT_PAYU_FAILURE = 'REDIRECT_PAYU_FAILURE';
export const CHANGE_PAYMENT_DATA_FAILED = 'CHANGE_PAYMENT_DATA_FAILED';

// Promo Contents
export const GET_PROMO_CONTENT_SUCCESS = 'GET_PROMO_CONTENT_SUCCESS';

// Attendance Overtime
export const ADD_ATTENDANCES_OVERTIME_SUCCESS = 'ADD_ATTENDANCES_OVERTIME_SUCCESS';

// Custom notifications
export const SEND_CUSTOM_NOTIFICATION_SUCCESS = 'SEND_CUSTOM_NOTIFICATION_SUCCESS';
export const SEND_CUSTOM_NOTIFICATION_ERROR = 'SEND_CUSTOM_NOTIFICATION_ERROR';

// Schedule view
export const UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST = 'UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST';

// Permissions roles
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const TOGGLE_GROUP_SUCCESS = 'TOGGLE_GROUP_SUCCESS';
export const TOGGLE_PERMISSION_SUCCESS = 'TOGGLE_PERMISSION_SUCCESS';
export const CHECK_PERMISSIONS_SUCCESS = 'CHECK_PERMISSIONS_SUCCESS';
export const UNCHECK_PERMISSIONS_SUCCESS = 'UNCHECK_PERMISSIONS_SUCCESS';
export const SAVE_ROLES_SUCCESS = 'SAVE_ROLES_SUCCESS';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const REMOVE_SUPPLEMENTARY_LOCATIONS_AFTER_ROLE_ASSIGN = 'REMOVE_SUPPLEMENTARY_LOCATIONS_AFTER_ROLE_ASSIGN';

// New exports
export const GET_AVAILABLE_EXPORTS_SUCCESS = 'GET_AVAILABLE_EXPORTS_SUCCESS';
export const GET_EXPORT_ERROR_NOTIFICATION = 'GET_EXPORT_ERROR_NOTIFICATION';
export const GET_EXPORT_SUCCESS_NOTIFICATION = 'GET_EXPORT_SUCCESS_NOTIFICATION';
export const GET_EXPORT_SUCCESS = 'GET_EXPORT_SUCCESS';
export const GET_EXPORT_ERROR = 'GET_EXPORT_ERROR';
export const GET_EXPORT_FAILED = 'GET_EXPORT_FAILED';
export const POST_EXPORT = 'POST_EXPORT';
export const HIDE_EXPORT_IN_WIDGET = 'HIDE_EXPORT_IN_WIDGET';
export const REMOVE_ALL_EXPORTS_FROM_WIDGET = 'REMOVE_ALL_EXPORTS_FROM_WIDGET';

// Enova exports
export const EXPORT_ENOVA_ATTENDANCES_ERROR = 'EXPORT_ENOVA_ATTENDANCES_ERROR';
export const EXPORT_ENOVA_ATTENDANCES_SUCCESS = 'EXPORT_ENOVA_ATTENDANCES_ERSUCCESS';
export const EXPORT_ENOVA_SCHEDULE_ERROR = 'EXPORT_ENOVA_SCHEDULE_ERROR';
export const EXPORT_ENOVA_SCHEDULE_SUCCESS = 'EXPORT_ENOVA_SCHEDULE_ERSUCCESS';
export const EXPORT_ENOVA_NO_DATA_TO_EXPORT = 'EXPORT_ENOVA_NO_DATA_TO_EXPORT';

// JobTitles
export const GROUP_JOBTITLES_SUCCESS = 'GROUP_JOBTITLES_SUCCESS';

// Overtime collections
export const ADD_OVERTIME_COLLECTION = 'ADD_OVERTIME_COLLECTION';
export const ADD_OVERTIME_COLLECTION_SUCCESS = 'ADD_OVERTIME_COLLECTION_SUCCESS';
export const ADD_OVERTIME_COLLECTION_FAILURE = 'ADD_OVERTIME_COLLECTION_FAILURE';
export const EDIT_OVERTIME_COLLECTION = 'EDIT_OVERTIME_COLLECTION';
export const EDIT_OVERTIME_COLLECTION_SUCCESS = 'EDIT_OVERTIME_COLLECTION_SUCCESS';
export const EDIT_OVERTIME_COLLECTION_FAILURE = 'EDIT_OVERTIME_COLLECTION_FAILURE';
export const GET_OVERTIME_COLLECTIONS = 'GET_OVERTIME_COLLECTIONS';
export const ADD_INIT_OVERTIME_COLLECTION = 'ADD_INIT_OVERTIME_COLLECTION';
export const DELETE_OVERTIME_COLLECTIONS_SUCCESS = 'DELETE_OVERTIME_COLLECTIONS_SUCCESS';
export const DELETE_OVERTIME_COLLECTIONS_FAILURE = 'DELETE_OVERTIME_COLLECTIONS_FAILURE';
export const PUBLISH_OVERTIME_COLLECTIONS_SUCCESSFUL = 'PUBLISH_OVERTIME_COLLECTIONS_SUCCESSFUL';
export const GET_OVERTIME_COLLECTIONS_FAILURE = 'GET_OVERTIME_COLLECTIONS_FAILURE';

export const ADD_INIT_OLD_OVERTIME_COLLECTION = 'ADD_INIT_OLD_OVERTIME_COLLECTION';

export const ADD_OLD_OVERTIME_COLLECTION_SUCCESS = 'ADD_OLD_OVERTIME_COLLECTION_SUCCESS';

export const ADD_OLD_OVERTIME_COLLECTION_FAILURE = 'ADD_OLD_OVERTIME_COLLECTION_FAILURE';

// Absence
export const CHANGE_ABSENCE_FILTER = 'CHANGE_ABSENCE_FILTER';
export const GET_ABSENCES_FOR_SCHEDULE = 'GET_ABSENCES_FOR_SCHEDULE';
export const GET_ABSENCES = 'GET_ABSENCES';
export const GET_ABSENCE_SUCCESS = 'GET_ABSENCE_SUCCESS';
export const GET_ABSENCE_FAILURE = 'GET_ABSENCE_FAILURE';
export const ADD_ABSENCE_SUCCESS = 'ADD_ABSENCE_SUCCESS';
export const ADD_ABSENCE_FAILURE = 'ADD_ABSENCE_FAILURE';
export const EDIT_ABSENCE_SUCCESS = 'EDIT_ABSENCE_SUCCESS';
export const EDIT_ABSENCE_FAILURE = 'EDIT_ABSENCE_FAILURE';
export const DELETE_ABSENCE_SUCCESS = 'DELETE_ABSENCE_SUCCESS';
export const DELETE_ABSENCE_FAILURE = 'DELETE_ABSENCE_FAILURE';
export const GET_ABSENCES_TYPES = 'GET_ABSENCES_TYPES';
export const ADD_ABSENCE_TYPE_SUCCESS = 'ADD_ABSENCE_TYPE_SUCCESS';
export const ADD_ABSENCE_TYPE_FAILURE = 'ADD_ABSENCE_TYPE_FAILURE';
export const EDIT_ABSENCE_TYPE_SUCCESS = 'EDIT_ABSENCE_TYPE_SUCCESS';
export const EDIT_ABSENCE_TYPE_FAILURE = 'EDIT_ABSENCE_TYPE_FAILURE';
export const DELETE_ABSENCE_TYPE_SUCCESS = 'DELETE_ABSENCE_TYPE_SUCCESS';
export const DELETE_ABSENCE_TYPE_FAILURE = 'DELETE_ABSENCE_TYPE_FAILURE';
export const CHANGE_ABSENCES_TABLE_DATA = 'CHANGE_ABSENCES_TABLE_DATA';
export const GET_ABSENCE_LIMIT_USAGE_SUCCESS = 'GET_ABSENCE_LIMIT_USAGE_SUCCESS';
export const GET_ABSENCE_LIMIT_USAGE_FAILURE = 'GET_ABSENCE_LIMIT_USAGE_FAILURE';
export const CHANGE_ABSENCE_LIMIT_USAGE_TABLE_DATA = 'CHANGE_ABSENCE_LIMIT_USAGE_TABLE_DATA';
export const SET_ABSENCES_LIMITS = 'SET_ABSENCES_LIMITS';
export const ADD_ABSENCES_LIMITS_SUCCESS = 'ADD_ABSENCES_LIMITS_SUCCESS';
export const ADD_ABSENCES_LIMITS_FAILURE = 'ADD_ABSENCES_LIMITS_FAILURE';
export const CHANGE_ABSENCE_LIMITS_FOR_EMPLOYEE = 'CHANGE_ABSENCE_LIMITS_FOR_EMPLOYEE';
export const CHANGE_ABSENCES_MODE = 'CHANGE_ABSENCES_MODE';
export const ACCEPT_ABSENCE_SUCCESS = 'ACCEPT_ABSENCE_SUCCESS';
export const REJECT_ABSENCE_SUCCESS = 'REJECT_ABSENCE_SUCCESS';

// RCP
export const GET_RCP_SUCCESS = 'GET_RCP_SUCCESS';
export const GET_RCP_FAILURE = 'GET_RCP_FAILURE';

// dashboard
export const GET_DASHBOARD_SUCCESSFUL = 'GET_DASHBOARD_SUCCESSFUL';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';
export const SET_DASHBOARD_SORTING = 'SET_DASHBOARD_SORTING';

// reports
export const GET_WORKTIME_REPORT = 'GET_WORKTIME_REPORT';
export const GET_SALES_REPORT = 'GET_SALES_REPORT';
export const GET_SYSTEM_BONUS_EXPORT_DATA_ERROR = 'GET_SYSTEM_BONUS_EXPORT_DATA_ERROR';
export const REPORTS_LOCATIONS_FILTER_CHANGE = 'REPORTS_LOCATIONS_FILTER_CHANGE';
export const REPORTS_JOB_TITLES_FILTER_CHANGE = 'REPORTS_JOB_TITLES_FILTER_CHANGE';
export const REPORTS_EMPLOYMENT_CONDITIONS_FILTER_CHANGE = 'REPORTS_EMPLOYMENT_CONDITIONS_FILTER_CHANGE';

export const REPORTS_DATE_MOVE_LEFT = 'REPORTS_DATE_MOVE_LEFT';
export const REPORTS_DATE_MOVE_RIGHT = 'REPORTS_DATE_MOVE_RIGHT';
export const REPORTS_DATE_CHANGE_MODE = 'REPORTS_DATE_CHANGE_MODE';
export const REPORTS_DATE_CHANGE_CUSTOM_DATE = 'REPORTS_DATE_CHANGE_CUSTOM_DATE';
export const REPORTS_DATE_CHANGE_MODE_AND_DATE = 'REPORTS_DATE_CHANGE_MODE_AND_DATE';

// company trial status
export const GET_COMPANY_STATUS_SUCCESS = 'GET_COMPANY_STATUS_SUCCESS';
export const HIDE_TRIAL_INFO_BAR = 'HIDE_TRIAL_INFO_BAR';

// bonuses
export const CALCULATE_SPMH_BASED_BONUSES_SUCCESS = 'CALCULATE_SPMH_BASED_BONUSES_SUCCESS';
export const CALCULATE_SPMH_BASED_BONUSES_FAILURE = 'CALCULATE_SPMH_BASED_BONUSES_FAILURE';
export const CHANGE_BONUS_SYSTEM_SETTINGS_ERROR = 'CHANGE_BONUS_SYSTEM_SETTINGS_ERROR';
export const CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS = 'CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS';
export const CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS_MASS = 'CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS_MASS';

export const CHOOSE_ONBOARDING_OPTION = 'CHOOSE_ONBOARDING_OPTION';
export const GET_COMPANY_MILESTONES_SUCCESS = 'GET_COMPANY_MILESTONES_SUCCESS';

export const CHANGE_SCHEDULE_CHARTS_DATA = 'CHANGE_SCHEDULE_CHARTS_DATA';
export const CHANGE_SCHEDULE_CHARTS_MAX_VALUES = 'CHANGE_SCHEDULE_CHARTS_MAX_VALUES';

export const CHANGE_SHIFTS_SUMMARY = 'CHANGE_SHIFTS_SUMMARY';
export const TOGGLE_BUDGET_OPTION = 'TOGGLE_BUDGET_OPTION';

// schedule | drag and drop
export const START_SHIFT_DRAG = 'START_SHIFT_DRAG';
export const STOP_SHIFT_DRAG = 'STOP_SHIFT_DRAG';
export const START_OPEN_SHIFT_DRAG = 'START_OPEN_SHIFT_DRAG';
export const STOP_OPEN_SHIFT_DRAG = 'STOP_OPEN_SHIFT_DRAG';
export const START_AVAILABILITY_DRAG = 'START_AVAILABILITY_DRAG';
export const STOP_AVAILABILITY_DRAG = 'STOP_AVAILABILITY_DRAG';
export const NO_COMMON_JOB_TITLES = 'NO_COMMON_JOB_TITLES';
export const START_SWAPPING = 'START_SWAPPING';
export const STOP_SWAPPING = 'STOP_SWAPPING';

// schedule | select cells
export const ADD_SCHEDULE_SELECTED_CELLS = 'ADD_SCHEDULE_SELECTED_CELLS';
export const REMOVE_SCHEDULE_SELECTED_CELLS = 'REMOVE_SCHEDULE_SELECTED_CELLS';
export const CLEAR_ALL_SCHEDULE_SELECTED_CELLS = 'CLEAR_ALL_SCHEDULE_SELECTED_CELLS';
export const REPLACE_SCHEDULE_SELECTED_CELLS = 'REPLACE_SCHEDULE_SELECTED_CELLS';
export const TOGGLE_SCHEDULE_OPEN_SHIFTS_SELECT = 'TOGGLE_SCHEDULE_OPEN_SHIFTS_SELECT';
export const TOGGLE_SCHEDULE_TRADE_SHIFTS_SELECT = 'TOGGLE_SCHEDULE_TRADE_SHIFTS_SELECT';

// schedule | row statistics
export const ROW_STATISTICS_CHANGED = 'ROW_STATISTICS_CHANGED';

// schedule | sticky rows
export const TOGGLE_OPEN_SHIFTS_ROW_STICKINESS = 'TOGGLE_OPEN_SHIFTS_ROW_STICKINESS';
export const TOGGLE_TRADE_SHIFTS_ROW_STICKINESS = 'TOGGLE_TRADE_SHIFTS_ROW_STICKINESS';
export const TOGGLE_LOAN_EMPLOYEES_PROPOSAL_ROW_STICKINESS = 'TOGGLE_LOAN_EMPLOYEES_PROPOSAL_ROW_STICKINESS';

// chat
export const CREATE_CONVERSATION_ERROR = 'CREATE_CONVERSATION_ERROR';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCES';
export const SET_SELECTED_CONVERSATION_ID = 'SET_SELECTED_CONVERSATION_ID';
export const SET_CHAT_SIGNATURE = 'SET_CHAT_SIGNATURE';
export const GET_CHAT_USERS = 'GET_CHAT_USERS';
export const GET_CHAT_USERS_ERRORS = 'GET_CHAT_USERS_ERRORS';

// timesheet
export const DELETE_SHIFTS_FOR_CURRENT_USER_ERROR = 'DELETE_SHIFTS_FOR_CURRENT_USER_ERROR';
export const DELETE_SHIFTS_FOR_CURRENT_USER_SUCCESS = 'DELETE_SHIFTS_FOR_CURRENT_USER_SUCCESS';
export const ADD_SHIFTS_FOR_CURRENT_USER_ERROR = 'ADD_SHIFTS_FOR_CURRENT_USER_ERROR';
export const ADD_SHIFTS_FOR_CURRENT_USER_SUCCESS = 'ADD_SHIFTS_FOR_CURRENT_USER_SUCCESS';
export const EDIT_SHIFT_FOR_CURRENT_USER_ERROR = 'EDIT_SHIFT_FOR_CURRENT_USER_ERROR';
export const EDIT_SHIFT_FOR_CURRENT_USER_SUCCESS = 'EDIT_SHIFT_FOR_CURRENT_USER_SUCCESS';

// schedule | view mode
export const TOGGLE_SCHEDULE_AVAILABILITIES_MODE = 'TOGGLE_SCHEDULE_AVAILABILITIES_MODE';
export const SELECT_AVAILABILITIES_MODE = 'SELECT_AVAILABILITIES_MODE';
export const SELECT_SHIFTS_MODE = 'SELECT_SHIFTS_MODE';

export const GET_FREE_PIN = 'GET_FREE_PIN';

// schedule | quick planning
export const TOGGLE_QUICK_PLANNING = 'TOGGLE_QUICK_PLANNING';
export const QUICK_PLANNING_SET_NEW_SELECTED = 'QUICK_PLANNING_SET_NEW_SELECTED';
export const QUICK_PLANNING_ADD_TO_SELECTED = 'QUICK_PLANNING_ADD_TO_SELECTED';
export const QUICK_PLANNING_REMOVE_FROM_SELECTED = 'QUICK_PLANNING_REMOVE_FROM_SELECTED';
export const QUICK_PLANNING_TOGGLE_SINGLE_SELECTION = 'QUICK_PLANNING_TOGGLE_SINGLE_SELECTION';
export const QUICK_PLANNING_DYNAMIC_SET_ITEM_DOWN = 'QUICK_PLANNING_DYNAMIC_SET_ITEM_DOWN';
export const QUICK_PLANNING_SET_POSITION = 'QUICK_PLANNING_SET_POSITION';
export const QUICK_PLANNING_SET_DATA_TO_COPY = 'QUICK_PLANNING_SET_DATA_TO_COPY';
export const QUICK_PLANNING_NO_SHIFTS_TO_PASTE = 'QUICK_PLANNING_NO_SHIFTS_TO_PASTE';
export const QUICK_PLANNING_SET_SHIFTS_SUMMARY = 'QUICK_PLANNING_SET_SHIFTS_SUMMARY';
export const QUICK_PLANNING_SET_FREE_DAYS_SUMMARY = 'QUICK_PLANNING_SET_FREE_DAYS_SUMMARY';
export const QUICK_PLANNING_SET_TRANSLATION_WITH_SHIFT = 'QUICK_PLANNING_SET_TRANSLATION_WITH_SHIFT';
export const QUICK_PLANNNING_SET_HOVERED_ITEMS = 'QUICK_PLANNNING_SET_HOVERED_ITEMS';
export const QUICK_PLANNING_CLEAR_SELECTION = 'QUICK_PLANNING_CLEAR_SELECTION';
export const QUICK_PLANNING_SET_SHORTCUT_FOR_NEW_FREE_DAY = 'QUICK_PLANNING_SET_SHORTCUT_FOR_NEW_FREE_DAY';

// new payroll
export const PAYROLL_GET_DATA_SUCCESS = 'PAYROLL_GET_DATA_SUCCESS';
export const PAYROLL_GET_DATA_ERROR = 'PAYROLL_GET_DATA_ERROR';
export const PAYROLL_CHANGE_VISIBLE_COLUMNS = 'PAYROLL_CHANGE_VISIBLE_COLUMNS';
export const PAYROLL_GET_SHIFT_OR_ATTENDANCE_ERROR = 'PAYROLL_GET_SHIFT_OR_ATTENDANCE_ERROR';
export const DELETE_SHIFT_FOR_PAYROLL_ERROR = 'DELETE_SHIFT_FOR_PAYROLL_ERROR';
export const DELETE_SHIFT_FOR_PAYROLL_SUCCESS = 'DELETE_SHIFT_FOR_PAYROLL_SUCCESS';
export const EDIT_SHIFT_FOR_PAYROLL_ERROR = 'EDIT_SHIFT_FOR_PAYROLL_ERROR';
export const EDIT_SHIFT_FOR_PAYROLL_SUCCESS = 'EDIT_SHIFT_FOR_PAYROLL_SUCCESS';
export const ADD_SHIFT_FOR_PAYROLL_ERROR = 'ADD_SHIFT_FOR_PAYROLL_ERROR';
export const ADD_SHIFT_FOR_PAYROLL_SUCCESS = 'ADD_SHIFT_FOR_PAYROLL_SUCCESS';
export const EDIT_ATTENDANCE_FOR_PAYROLL_ERROR = 'EDIT_ATTENDANCE_FOR_PAYROLL_ERROR';
export const EDIT_ATTENDANCE_FOR_PAYROLL_SUCCESS = 'EDIT_ATTENDANCE_FOR_PAYROLL_SUCCESS';
export const PAYROLL_CHANGE_MODE = 'PAYROLL_CHANGE_MODE';
export const CHANGE_PAYROLL_DAYS_STATUS_SUCCESS = 'CHANGE_PAYROLL_DAYS_STATUS_SUCCESS';
export const CHANGE_PAYROLL_DAYS_STATUS_FAILURE = 'CHANGE_PAYROLL_DAYS_STATUS_FAILURE';
export const CHANGE_PAYROLL_DAYS_STATUS_FOR_MULTIPLE_EMPLOYEES_SUCCESS =
  'CHANGE_PAYROLL_DAYS_STATUS_FOR_MULTIPLE_EMPLOYEES_SUCCESS';
export const CHANGE_PAYROLL_DAYS_STATUS_FOR_MULTIPLE_EMPLOYEES_FAILURE =
  'CHANGE_PAYROLL_DAYS_STATUS_FOR_MULTIPLE_EMPLOYEES_FAILURE';
export const SAVE_NEW_PAYROLL_SUCCESS = 'SAVE_NEW_PAYROLL_SUCCESS';
export const SAVE_NEW_PAYROLL_FAILURE = 'SAVE_NEW_PAYROLL_FAILURE';
export const CHOOSE_PAYROLL_SUMMARY_VALUES = 'CHOOSE_PAYROLL_SUMMARY_VALUES';

export const START_FETCHING_PAYROLL = 'START_FETCHING_PAYROLL';

// new payroll location
export const PAYROLL_LOCATION_GET_DATA_SUCCESS = 'PAYROLL_LOCATION_GET_DATA_SUCCESS';
export const PAYROLL_LOCATION_GET_DATA_ERROR = 'PAYROLL_LOCATION_GET_DATA_ERROR';
export const PAYROLL_LOCATION_CHANGE_VISIBLE_COLUMNS = 'PAYROLL_LOCATION_CHANGE_VISIBLE_COLUMNS';
export const START_FETCHING_PAYROLL_LOCATION_IN_BATCHES = 'START_FETCHING_PAYROLL_LOCATION_IN_BATCHES';

export const FINISH_FETCHING_PAYROLL_LOCATION_IN_BATCHES = 'FINISH_FETCHING_PAYROLL_LOCATION_IN_BATCHES';

export const GET_PAYROLL_LOCATION_DATA_BATCH_SUCCESS = 'GET_PAYROLL_LOCATION_DATA_BATCH_SUCCESS';

export const GET_PAYROLL_LOCATION_SUMMARY_SUCCESS = 'GET_PAYROLL_LOCATION_SUMMARY_SUCCESS';

export const START_FETCHING_PAYROLL_LOCATION = 'START_FETCHING_PAYROLL_LOCATION';

// exports history
export const EXPORTS_HISTORY_GET_SUCCESS = 'EXPORTS_HISTORY_GET_SUCCESS';
export const EXPORTS_HISTORY_GET_ERROR = 'EXPORTS_HISTORY_GET_ERROR';
export const EXPORTS_HISTORY_DELETE_SUCCESS = 'EXPORTS_HISTORY_DELETE_SUCCESS';
export const EXPORTS_HISTORY_DELETE_ERROR = 'EXPORTS_HISTORY_DELETE_ERROR';
export const CHANGE_EXPORTS_HISTORY_PAGINATION = 'CHANGE_EXPORTS_HISTORY_PAGINATION';
export const EXPORT_DOWNLOAD_ERROR = 'EXPORT_DOWNLOAD_ERROR';
export const EXPORT_DOWNLOAD_SUCCESS = 'EXPORT_DOWNLOAD_SUCCESS';
export const QUICK_PLANNING_SET_WORKING_HOURS_FOR_NEW_SHIFT = 'QUICK_PLANNING_SET_WORKING_HOURS_FOR_NEW_SHIFT';

// freemium
export const SET_ACCOUNT_AS_FREEMIUM_ERROR = 'SET_ACCOUNT_AS_FREEMIUM_ERROR';

// Budget metrics data
export const GET_BUDGET_METRIC_DATA_SUCCESS = 'GET_BUDGET_METRIC_DATA_SUCCESS';
export const GET_BUDGET_METRIC_DATA_FAILURE = 'GET_BUDGET_METRIC_DATA_FAILURE';

export const UPDATE_BUDGET_METRIC_DATA_SUCCESS = 'UPDATE_BUDGET_METRIC_DATA_SUCCESS';
export const UPDATE_BUDGET_METRIC_DATA_FAILURE = 'UPDATE_BUDGET_METRIC_DATA_FAILURE';

// Budget metrics
export const DELETE_BUDGET_METRICS_FAILURE = 'DELETE_BUDGET_METRICS_FAILURE';
export const DELETE_BUDGET_METRICS_SUCCESS = 'DELETE_BUDGET_METRICS_SUCCESS';
export const GET_BUDGET_METRICS_SUCCESS = 'GET_BUDGET_METRICS_SUCCESS';
export const CREATE_BUDGET_METRICS_SUCCESS = 'CREATE_BUDGET_METRICS_SUCCESS';
export const EDIT_BUDGET_METRICS_SUCCESS = 'EDIT_BUDGET_METRICS_SUCCESS';
export const CREATE_BUDGET_METRICS_FAILURE = 'CREATE_BUDGET_METRICS_FAILURE';
export const EDIT_BUDGET_METRICS_FAILURE = 'EDIT_BUDGET_METRICS_FAILURE';
export const SET_VISIBLE_METRICS = 'SET_VISIBLE_METRICS';

export const GET_BUDGET_METRICS_FAILURE = 'GET_BUDGET_METRICS_FAILURE';

// export info popover
export const SHOW_EXPORT_INFO_POPOVER = 'SHOW_EXPORT_INFO_POPOVER';
export const HIDE_EXPORT_INFO_POPOVER = 'HIDE_EXPORT_INFO_POPOVER';

export const ENABLE_PAYROLL_LOAD_BUTTON = 'ENABLE_PAYROLL_LOAD_BUTTON';
export const DELETE_RELEASED_EMPLOYEES_DATA_SUCCESS = 'DELETE_RELEASED_EMPLOYEES_DATA_SUCCESS';
export const DELETE_RELEASED_EMPLOYEES_SHIFTS_SUCCESS = 'DELETE_RELEASED_EMPLOYEES_SHIFTS_SUCCESS';
export const DELETE_RELEASED_EMPLOYEES_ATTENDANCES_SUCCESS = 'DELETE_RELEASED_EMPLOYEES_ATTENDANCES_SUCCESS';
export const DELETE_RELEASED_EMPLOYEES_ABSENCES_SUCCESS = 'DELETE_RELEASED_EMPLOYEES_ABSENCES_SUCCESS';
export const DELETE_RELEASED_EMPLOYEES_SHIFTS_FAILURE = 'DELETE_RELEASED_EMPLOYEES_SHIFTS_FAILURE';
export const DELETE_RELEASED_EMPLOYEES_ATTENDANCES_FAILURE = 'DELETE_RELEASED_EMPLOYEES_ATTENDANCES_FAILURE';
export const DELETE_RELEASED_EMPLOYEES_ABSENCES_FAILURE = 'DELETE_RELEASED_EMPLOYEES_ABSENCES_FAILURE';
export const DELETE_RELEASED_EMPLOYEES_DATA_FAILURE = 'DELETE_RELEASED_EMPLOYEES_DATA_FAILURE';

// router
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const INVITE_EMPLOYEES_SUCCESS = 'INVITE_EMPLOYEES_SUCCESS';

// labels
export const GET_LABELS = 'GET_LABELS';
export const CREATE_LABEL = 'CREATE_LABEL';
export const UPDATE_LABEL = 'UPDATE_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const GET_LABELS_ERROR = 'GET_LABELS_ERROR';
export const CREATE_LABEL_ERROR = 'CREATE_LABEL_ERROR';
export const UPDATE_LABEL_ERROR = 'UPDATE_LABEL_ERROR';
export const DELETE_LABELS_ERROR = 'DELETE_LABELS_ERROR';

// google OAuth
export const GET_GOOGLE_OAUTH_USER_DATA = 'GET_GOOGLE_OAUTH_USERDATA';

// employee payroll
export const GET_EMPLOYEE_PAYROLL_SUCCESS = 'GET_EMPLOYEE_PAYROLL_SUCCESS';
export const GET_EMPLOYEE_PAYROLL_ERROR = 'GET_EMPLOYEE_PAYROLL_ERROR';
export const START_FETCHING_EMPLOYEE_PAYROLL_DATA = 'START_FETCHING_EMPLOYEE_PAYROLL_DATA';
// contracts

export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_FAILURE = 'GET_CONTRACTS_FAILURE';
export const ADD_CONTRACTS_SUCCESS = 'ADD_CONTRACTS_SUCCESS';
export const ADD_CONTRACTS_FAILURE = 'ADD_CONTRACTS_FAILURE';
export const DELETE_CONTRACTS_SUCCESS = 'DELETE_CONTRACTS_SUCCESS';
export const DELETE_CONTRACTS_FAILURE = 'DELETE_CONTRACTS_FAILURE';
export const UPDATE_CONTRACTS_SUCCESS = 'UPDATE_CONTRACTS_SUCCESS';
export const UPDATE_CONTRACTS_FAILURE = 'UPDATE_CONTRACTS_FAILURE';

// new new reports
export const GET_REPORT_DATA_INIT = 'GET_REPORT_DATA_INIT';
export const GET_REPORT_DATA_SUCCESS = 'GET_REPORT_DATA_SUCCESS';
export const GET_REPORT_DATA_ERROR = 'GET_REPORT_DATA_ERROR';

// loaned employees
export const GET_LOANED_EMPLOYEES = 'GET_LOANED_EMPLOYEES';
export const ADD_LOANED_EMPLOYEES = 'ADD_LOANED_EMPLOYEES';
export const GET_LOANED_ATTENDANCES_SUCCESFUL = 'GET_LOANED_ATTENDANCES_SUCCESFUL';
export const GET_PAYROLL_LOANED_EMPLOYEES = 'GET_PAYROLL_LOANED_EMPLOYEES';

// loan employees proposal
export const GET_LOAN_EMPLOYEES_PROPOSALS_SUCCESSFUL = 'GET_LOAN_EMPLOYEES_PROPOSALS_SUCCESSFUL';
export const GET_LOAN_EMPLOYEES_PROPOSAL_ERROR = 'LOAN_EMPLOYEES_PROPOSAL_ERROR';
export const INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL = 'INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL';
export const ACCEPT_LOAN_EMPLOYEES_PROPOSAL_ERROR = 'ACCEPT_LOAN_EMPLOYEES_PROPOSAL_ERROR';
export const ACCEPT_LOAN_EMPLOYEES_PROPOSAL_SUCCESSFUL = 'ACCEPT_LOAN_EMPLOYEES_PROPOSAL_SUCCESSFUL';
export const OUTGOING_LOAN_EMPLOYEES_PROPOSAL_MODAL = 'OUTGOING_LOAN_EMPLOYEES_PROPOSAL_MODAL';
export const CREATE_LOAN_EMPLOYEES_POROPOSAL_ERROR = 'CREATE_LOAN_EMPLOYEES_POROPOSAL_ERROR';
export const CREATE_LOAN_EMPLOYEES_POROPOSAL_SUCCESSFUL = 'CREATE_LOAN_EMPLOYEES_POROPOSAL_SUCCESSFUL';
export const DELETE_LOAN_EMPLOYEES_POROPOSAL_SUCCESSFUL = 'DELETE_LOAN_EMPLOYEES_POROPOSAL_SUCCESSFUL';
export const DELETE_LOAN_EMPLOYEES_POROPOSAL_ERROR = 'DELETE_LOAN_EMPLOYEES_POROPOSAL_ERROR';
export const GET_LOAN_EMPLOYEES_PROPOSALS_ASSIGNMENTS_SUCCESSFUL =
  'GET_LOAN_EMPLOYEES_PROPOSALS_ASSIGNMENTS_SUCCESSFUL';

// Group location
export const GET_BY_LOCATION_GROUP_AND_JOB_TITLE_ERROR = 'GET_BY_LOCATION_GROUP_AND_JOB_TITLE_ERROR';

// employees names
export const GET_EMPLOYEES_NAMES = 'GET_EMPLOYEES_NAMES';
export const ADD_EMPLOYEES_NAMES = 'ADD_EMPLOYEES_NAMES';
export const LOCATION_CHANGE = 'LOCATION_CHANGE';

// custom fields
export const GET_USERS_CUSTOM_FIELDS = 'GET_USERS_CUSTOM_FIELDS';
export const GET_CUSTOM_FIELDS_DEFINITIONS = 'GET_CUSTOM_FIELDS_DEFINITIONS';
export const GET_CUSTOM_FIELDS_DEFINITIONS_ERROR = 'GET_CUSTOM_FIELDS_DEFINITIONS_ERROR';
export const GET_CUSTOM_FIELDS_DEFINITIONS_SUCCESSFUL = 'GET_CUSTOM_FIELDS_DEFINITIONS_SUCCESSFUL';
export const GET_USER_CUSTOM_FIELDS_ERROR = 'GET_USER_CUSTOM_FIELDS_ERROR';
export const GET_USER_CUSTOM_FIELDS_SUCCESSFUL = 'GET_USER_CUSTOM_FIELDS_SUCCESSFUL';

export const LOGIN_VIA_GHOST_LOGIN_SUCCESS = 'LOGIN_VIA_GHOST_LOGIN_SUCCESS';
