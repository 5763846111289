import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { ORANGE, REVERSE_ORANGE } from '@/constants/buttonModifiers';
import { ABSENCE_TYPE_MODAL } from '@/constants/modalTypes.js';
import { isEmptyObject } from '@/utils/objectHelpers/objectHelpers';

import './AbsenceTypesOptions.scss';

const AbsenceTypesOptions = ({ deleteAbsenceTypes, showModal, visibleRows, rowSelection }) => {
  const [loading, setLoading] = useState(false);

  const handleRemoveAbsenceTypes = async () => {
    setLoading(true);
    const ids = Object.keys(rowSelection).map(rowIndex => visibleRows[Number(rowIndex)].id);
    await deleteAbsenceTypes(ids);
    setLoading(false);
  };

  return (
    <div className="absenceTypesOptions">
      <Button modifiers={ORANGE} onClick={() => showModal(ABSENCE_TYPE_MODAL)}>
        <span className="material-icons">add</span>
        <FormattedMessage id="absenceTypeOptions.add.button" defaultMessage="Dodaj typ urlopu" />
      </Button>
      <Button
        modifiers={REVERSE_ORANGE}
        onClick={handleRemoveAbsenceTypes}
        disabled={isEmptyObject(rowSelection) || loading}
      >
        <span className="material-icons">remove</span>
        <FormattedMessage id="absenceTypeOptions.remove.button" defaultMessage="Usuń zaznaczone" />
      </Button>
    </div>
  );
};

AbsenceTypesOptions.propTypes = {
  showModal: PropTypes.func,
  visibleRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  rowSelection: PropTypes.shape({}),
  deleteAbsenceTypes: PropTypes.func,
};

export default AbsenceTypesOptions;
