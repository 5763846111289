import { RootState } from '../types';

export const selectLoanedEmployeeById = (employeeId: string) => (state: RootState) =>
  state.reducer.scheduleLoanedEmployees.scheduleLoanedEmployees[employeeId];

export const selectLoanedEmployeeFullNameById = (employeeId: string) => (state: RootState) => {
  const employee = selectLoanedEmployeeById(employeeId)(state);
  if (!employee) return undefined;
  return `${employee.first_name} ${employee.last_name}`;
};
export const selectScheduleLoanedEmployees = (state: RootState) =>
  state.reducer.scheduleLoanedEmployees.scheduleLoanedEmployees;
