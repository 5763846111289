/* eslint no-use-before-define: 0 camelcase: 0 */
/* eslint array-callback-return:0 */
/* eslint-disable no-await-in-loop */

import { isEmpty } from 'lodash';
import moment from 'moment';
import { defineMessages } from 'react-intl';

import { trackIntercomEvent } from '@/actions/intercom';
import * as actionTypes from '@/constants/ActionTypes.js';
import { ADD_SHIFT_TO_EMPLOYEE, PUBLISH_DRAFT_SHIFTS_SUCCESFUL } from '@/constants/ActionTypes.js';
import { appVersion } from '@/constants/appVersion';
import { CONFIRM_MODAL } from '@/constants/modalTypes.js';
import { LOAN_EMPLOYEES_ENABLE, OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE } from '@/constants/Permissions.js';
import KadroConnections from '@/containers/MainConnections.js';
import { getExtendedAvailabilityBlock } from '@/utils/availabilitiesHelpers';
import { arrayIntersection, uuid4 } from '@/utils/baseHelpers.js';
import { getLocationIdsWithPhotoEnabled } from '@/utils/companyManage/devices/devicesHelpers';
import { getCookie, setCookie } from '@/utils/cookieHandlers';
import {
  getDatesFromRepeatObj,
  getRangeBetweenDates,
  getRelevantDateRange,
  getRelevantDateRangeForLocation,
  mapTimestampsToShift,
} from '@/utils/dateHelper.js';
import { allNavs } from '@/utils/routes';
import { getAvaCornerPopover } from '@/utils/schedule/availabilityCornerHelpers';
import { getShiftsToAdd, handleAddShiftFailureErrorMessage, shiftErrorCodesToMessages } from '@/utils/shiftHelpers.js';

import { getEmployeesNames } from './employeesNames.ts';
import { getOpenShiftsSuccesful, massDeleteOpenShifts, publishDraftOpenShiftsSuccesful } from './openShifts.js';
import { publishOvertimeCollectionsSuccessful } from './overtimeCollections/overtimeCollections.js';
import { addMultipleShifts, getVisibleEmployeesIdsForScheduleView } from './schedule.jsx';
import { getScheduleLoanedEmployees } from './scheduleLoanedEmployees.ts';
import { changeSingleEmployeeFilter } from './singleEmployeeFilter.js';
import { getTradeShiftsSuccesful } from './tradeShifts.js';
import { decreaseLoaderCounter, increaseLoaderCounter, showModal } from './uiState.js';
import { clearWorkingRules } from './workingRules.js';

export const messages = defineMessages({
  addShiftErrorBusy: { id: 'error.addShift.busy', defaultMessage: 'Pracownik ma w tym czasie już inną zmiane' },
  addShiftErrorDefault: { id: 'error.addShift.default', defaultMessage: 'Nie udało się dodać zmiany!' },
  repeatShiftErrorEmployee: {
    id: 'error.repeatShift.employee',
    defaultMessage: 'Nie udało się dodać zmiany dla danego pracownika',
  },
  repeatShiftErrorOverlap: {
    id: 'error.repeatShift.overlap',
    defaultMessage: 'Powtarzające się zmiany nachodzą na inne zmiany',
  },
  repeatShiftErrorDisabled: {
    id: 'error.repeatShift.disabled',
    defaultMessage: 'Nie możesz dodać zmian dla wybranych dat',
  },
  repeatShiftNoDates: {
    id: 'error.repeatShift.repeatShiftNoDates',
    defaultMessage: 'Proszę wybrać przynajmniej jedną datą',
  },
  addAvaSuccesful: {
    id: 'success.addAvaSuccesful',
    defaultMessage: 'Poprawnie dodano dostępność',
  },
  changeAvaSuccesful: {
    id: 'success.changeAvaSuccesful',
    defaultMessage: 'Poprawnie zmieniono dostępność',
  },
  deleteAvaSuccesull: {
    id: 'sucess.deleteAvaSuccesull',
    defaultMessage: 'Poprawnie usunięto dostępności',
  },
  failToast: {
    id: 'error.changeAva.fail',
    defaultMessage: 'Wystąpił błąd',
  },
  successToast: {
    id: 'toastr.successTitle',
    defaultMessage: 'Sukces!',
  },
  addShift: {
    id: 'toastr.addShift',
    defaultMessage: 'Dodano zmianę!',
  },
  addShiftDesc: {
    id: 'toastr.addShiftDesc',
    defaultMessage: 'Dodano zmianę dla {first_name} {last_name}!',
  },
  deletedShiftTitle: {
    id: 'scheduleTemplates.deletedShift.title',
    defaultMessage: 'Usunięto zmianę!',
  },
  editShiftSuccesful: {
    id: 'success.editShiftSuccesful',
    defaultMessage: 'Poprawnie edytowano zmiane.',
  },
  deletedUnpublishedShiftsDesc: {
    id: 'scheduleTemplates.deletedUnpublishedShifts.desc',
    defaultMessage: 'Usunięto nieopublikowane zmiany.',
  },
  deleteShiftCorrectly: {
    id: 'success.shiftChangedCorrectly',
    defaultMessage: 'Poprawnie usunięto zmiany (ilość: {shifts_length})',
  },
  changeCurrentUserError: {
    id: 'error.changeCurrentUserError',
    defaultMessage: 'Nie udało się zmienić danych użytkownika.',
  },
  swapShiftsSuccess: {
    id: 'success.swapShifts',
    defaultMessage: 'Poprawnie wymieniono zmiany',
  },
  swapShiftsFailure: {
    id: 'failure.swapShifts',
    defaultMessage: 'Nie udało się poprawnie wymienić zmian',
  },
  editShiftFailure: {
    id: 'success.editShiftFailure',
    defaultMessage: 'Wystąpił błąd podczas edycji zmiany.',
  },
});

export const connections = {
  master: {
    core: 'https://api.kadromierz.pl',
    workers: 'https://api.kadromierz.pl/workers',
    socket: 'https://api.kadromierz.pl',
  },
  alfa: {
    core: 'https://alfa-api.kadro.dev',
    workers: 'https://alfa-api.kadro.dev/workers',
    socket: 'https://alfa-api.kadro.dev',
  },
  staging: {
    core: 'https://stagingapi.kadromierz.pl/api',
    workers: 'https://stagingapi.kadromierz.pl/workers',
    socket: 'https://stagingapi.kadromierz.pl',
  },
  'local-api-dev': {
    core: 'http://kadromierz.test',
    workers: 'http://localhost:3000/workers',
    socket: 'https://sockets.kadromierz.pl',
  },
  local: {
    core: 'https://app.kadromierz.pl',
    workers: 'http://localhost:3000/workers',
    socket: 'https://sockets.kadromierz.pl',
  },
  'medicover-staging': {
    core: 'https://api.medicover-staging.kadro.dev',
    workers: 'https://api.medicover-staging.kadro.dev/workers',
    socket: 'https://api.medicover-staging.kadro.dev',
  },
  'medicover-production': {
    core: 'https://api-medicover.kadromierz.pl/api/',
    workers: 'https://api-medicover.kadromierz.pl/workers',
    socket: 'https://api-medicover.kadromierz.pl',
  },
  pattern: {
    core: '{{RUNTIME_ENV_TARGET_CORE}}',
    workers: '{{RUNTIME_ENV_TARGET_WORKERS}}',
    socket: '{{RUNTIME_ENV_TARGET_SOCKET}}',
  },
};

export const analyticsIds = {
  master: '1778331671',
  alfa: '2291893605',
  staging: '226082037',
};

export const conn = new KadroConnections(connections[import.meta.env.VITE_TARGET_API]);

export const showConfirmModal = confObject => dispatch => {
  dispatch(showModal(CONFIRM_MODAL, confObject));
};

export const showBlockingLoader = forWhat => ({
  type: actionTypes.SHOW_BLOCKING_LOADER,
  payload: forWhat,
});

export const getAvaTypesSuccesful = data => ({
  type: actionTypes.GET_AVA_TYPES_SUCCESFUL,
  payload: data,
});

export const getEmployeesSuccesful = data => ({
  type: actionTypes.GET_EMPLOYEES_SUCCESFUL,
  payload: data,
});

export const getShiftblocksSuccesful = data => ({
  type: actionTypes.GET_SHIFTBLOCKS_SUCCESFUL,
  payload: data,
});

export const getJobTitlesSuccesful = data => ({
  type: actionTypes.GET_JOBTITLES_SUCCESFUL,
  payload: data,
});
export const getLocationsSuccesful = data => ({
  type: actionTypes.GET_LOCATIONS_SUCCESFUL,
  payload: data,
});
export const changeCurrentUserSuccesful = (data, displayNotification = true) => {
  const notification = displayNotification
    ? {
        title: 'Zmieniono dane!',
        description: 'Pomyślnie zmieniono dane użytkownika!',
        type: 'success',
      }
    : undefined;
  return {
    type: actionTypes.UPDATE_CURRENT_USER_SUCCESFUL,
    payload: data,
    notification,
  };
};

export const changeCurrentCompanySuccesful = data => ({
  type: actionTypes.UPDATE_CURRENT_COMPANY_SUCCESFUL,
  payload: data,
});

export const changeCurrentUserLanguageSuccesful = language => ({
  type: actionTypes.UPDATE_CURRENT_USER_LANGUAGE_SUCCESFUL,
  payload: language,
});

/**
 * Fetches all the data for locations and merges them in one go
 * @param {Array<string>} location_id_array - Parameter description.
 * @param {string} from - Parameter description.
 * @param {string} to - Parameter description.
 */
export const getScheduleAndAttendancesForLocations =
  (location_id_array, from, to, employeeIds, requestType) => (dispatch, getState) =>
    new Promise(async resolve => {
      const downloadedData = await conn.getScheduleForMultipleLocations(
        location_id_array,
        from,
        to,
        employeeIds,
        requestType,
      );
      if (!downloadedData.data.schedule) {
        downloadedData.data.schedule = { open_shifts: [], trade_shifts: [] };
      }
      const openShiftsArray = downloadedData.data.schedule.open_shifts;
      const tradeShiftsArray = downloadedData.data.schedule.trade_shifts;
      const { employees } = downloadedData.data.schedule;
      dispatch(getOpenShiftsSuccesful(openShiftsArray));
      dispatch(getTradeShiftsSuccesful(tradeShiftsArray));
      dispatch(getScheduleSuccesful(employees));
      const { permissions } = getState().reducer.userPermissions;
      if (permissions.includes(LOAN_EMPLOYEES_ENABLE) || permissions.includes(OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE)) {
        const { userEmployees } = getState().reducer;
        const loanedEmployees = employees.filter(
          employee =>
            !userEmployees.some(({ id }) => id === employee.id) || employee.shifts.some(({ isLoaned }) => isLoaned),
        );

        dispatch(getScheduleLoanedEmployees(loanedEmployees));

        const loanedEmployeeIds = loanedEmployees.map(({ id }) => id);
        dispatch(getEmployeesNames(loanedEmployeeIds));
      }

      dispatch(getScheduleSuccesful(employees));
      resolve();
    });

export const getNewDataForDate =
  (from, to, path = '', requestType = 'blocking') =>
  (dispatch, getState) => {
    const { demo, currentUser, userPermissions } = getState().reducer;
    if (demo.demoAccount || !currentUser.authenticated || !userPermissions.initialized) return;

    const pathname = path || window.location.pathname;
    const currentNav = allNavs().find(nav => nav.path === `/${pathname.split('/')[1]}`);
    if (currentNav && currentNav.updateFunc) {
      dispatch(currentNav.updateFunc(from, to, requestType));
    }
  };

/**
 * Fetches the schedule for given a time period and maps the shifts and availabilities to users.
 * @param {integer} location_id
 * @param {string} from - YYYY-MM-DD
 * @param {string} to  - YYYY-MM-DD
 * @param {boolean} show_draft - Show shift drafts?
 */

export const getSchedule =
  (locationId, from, to, requestType, showDraft = true) =>
  dispatch => {
    conn
      .getSchedule(locationId, from, to, 'blocking', showDraft)
      .then(response => {
        const pulledData = response.data.schedule;
        // TODO: remove after BACKEND is fixed!!!
        dispatch(getScheduleSuccesful(pulledData.employees.filter(employee => !employee.deleted)));
        dispatch(getOpenShiftsSuccesful([pulledData.open_shifts]));
        dispatch(getTradeShiftsSuccesful([pulledData.trade_shifts]));
      })
      .catch(err => {
        dispatch(getScheduleFailed(err));
      });
  };
/**
 * Fetches the schedule visible for current employee for a given time period
 * and maps the shifts and availabilities to users.
 * @param {integer} location_id
 * @param {string} from - YYYY-MM-DD
 * @param {string} to  - YYYY-MM-DD
 * @param {boolean} show_draft - Show shift drafts?
 */

export const getScheduleForEmployee =
  (location_id, from, to, show_draft = false, requestType) =>
  (dispatch, getState) => {
    const { currentUser } = getState().reducer;
    return conn
      .getSchedule(location_id, from, to, requestType, show_draft)
      .then(response => {
        // TODO: remove after BACKEND is fixed!!!
        let pulledData = response.data.schedule.employees.filter(employee => !employee.deleted);
        pulledData = pulledData.map(employee => ({
          ...employee,
          locations: [{ id: location_id }],
          availability_blocks: [],
        }));
        const thisEmployeeIndex = pulledData.findIndex(employee => employee.id === currentUser.user.id);
        conn.getAttendanceForEmployee(from, to).then(result => {
          pulledData[thisEmployeeIndex].attendances = result.data.attendances;
          dispatch(getScheduleForEmployeeSuccesful(pulledData));
          dispatch(getAttendancesForEmployeeSuccessful(result.data.attendances, currentUser.user.id));
          dispatch(getOpenShiftsSuccesful(response.data.schedule.open_shifts));
          dispatch(getTradeShiftsSuccesful([response.data.schedule.trade_shifts]));
          const thisEmployee = pulledData[thisEmployeeIndex];
          dispatch(changeSingleEmployeeFilter(thisEmployee));
        });
      })
      .catch(err => {
        dispatch(getScheduleFailed(err));
      });
  };

export const getAttendancesImages = (locationIds, from, to, requestType) => (dispatch, getState) => {
  const { devices } = getState().reducer;
  const locationIdsWithPhotoEnabled = arrayIntersection(locationIds, getLocationIdsWithPhotoEnabled(devices));
  if (!locationIdsWithPhotoEnabled) return;
  conn
    .getAttendancesImages(locationIdsWithPhotoEnabled, from, to, requestType)
    .then(response => {
      dispatch(getAttendancesImagesSuccesful(response.data.photo_availability));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export function getAttendanceImageUrls(locationId, attendanceId) {
  return (dispatch, getState) => {
    const { rcpPhotos } = getState().reducer;
    if (rcpPhotos.data[attendanceId] && rcpPhotos.data[attendanceId].urlReady) return;
    conn
      .getAttendanceImageUrls(locationId, attendanceId)
      .then(response => {
        dispatch({
          type: actionTypes.GET_ATTENDANCES_IMAGE_URLS_SUCCESFUL,
          payload: response.data,
        });
      })
      .catch(err => {
        dispatch(connectionError(err));
      });
  };
}

export const getAttendances =
  (companyId, locationIds, from, to, requestType, matchWithDraftShifts = false) =>
  (dispatch, getState) => {
    const { permissions } = getState().reducer.userPermissions;
    return conn.getAttendances(companyId, locationIds, from, to, requestType, matchWithDraftShifts).then(response => {
      dispatch(getAttendancesSuccesful(response.data));
      const employeeIds = Object.keys(response.data);
      if (
        employeeIds.length &&
        (permissions.includes(LOAN_EMPLOYEES_ENABLE) || permissions.includes(OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE))
      )
        dispatch(getEmployeesNames(employeeIds));
    });
  };

export const getAttendancesSuccesful = data => ({
  type: actionTypes.GET_ATTENDANCES_SUCCESFUL,
  payload: data,
});

export const getAttendancesImagesSuccesful = data => ({
  type: actionTypes.GET_ATTENDANCES_IMAGES_SUCCESFUL,
  payload: data,
});

export const getScheduleSuccesful = data => (dispatch, getState, intl) => {
  const { userCustomTypes } = getState().reducer;

  const payload = data.map(employee => ({
    ...employee,
    availability_blocks: employee.availability_blocks.map(availability =>
      getExtendedAvailabilityBlock(availability, userCustomTypes, employee, intl),
    ),
  }));

  dispatch({
    type: actionTypes.GET_SCHEDULE_SUCCESFUL,
    payload,
  });
  dispatch(clearWorkingRules());
};
export const getAttendancesForEmployeeSuccessful = (data, employeeId) => ({
  type: actionTypes.GET_ATTENDANCES_SUCCESSFUL_FOR_EMPLOYEE,
  payload: { data, employeeId },
});

export const getScheduleForEmployeeSuccesful = data => ({
  type: actionTypes.GET_SCHEDULE_SUCCESFUL_FOR_EMPLOYEE,
  payload: data,
});

export const getScheduleFailed = data => ({
  type: actionTypes.GET_SCHEDULE_FAIL,
  payload: data,
});

const changeCurrentUserError = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.UPDATE_CURRENT_USER_ERROR,
    notification: {
      title: intl.formatMessage(messages.changeCurrentUserError),
      type: 'error',
    },
  });
};

export const changeCurrentUser =
  (userObject, displayNotification = true) =>
  dispatch =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await conn.changeCurrentUserInfo(userObject);
        dispatch(changeCurrentUserSuccesful({ user: response.data }, displayNotification));
        resolve();
      } catch (err) {
        dispatch(changeCurrentUserError());
        reject(err);
      }
    });

export const changeCurrentCompany = companyObject => dispatch => {
  conn.changeCurrentCompany(companyObject).then(response => {
    dispatch(changeCurrentCompanySuccesful(response.data));
  });
};

export const pullAvaTypes = (companyId, requestType) => dispatch => {
  conn
    .getAvaTypes(companyId, requestType)
    .then(response => {
      dispatch(getAvaTypesSuccesful(response.data['availability-types']));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const pullShiftblocks = (companyId, requestType) => dispatch => {
  conn
    .getShiftblocks(companyId, requestType)
    .then(response => {
      dispatch(getShiftblocksSuccesful(response.data.shiftblocks));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const pullJobTitles = (companyId, requestType) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .getJobTitles(companyId, false, requestType)
      .then(response => {
        dispatch(getJobTitlesSuccesful(response.data['job-titles']));
        resolve();
      })
      .catch(err => {
        dispatch(connectionError(err));
        reject(err);
      });
  });

export const pullLocations = () => dispatch => {
  conn
    .getLocations()
    .then(response => {
      dispatch(getLocationsSuccesful(response.data.locations));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const connectionError = err => {
  console.error(err);
  return {
    type: actionTypes.CONNECTION_ERROR,
    payload: err,
  };
};

/* SHIFTS  */
export const publishDraftShifts =
  (publishShifts, publishOpenShifts, sendNotif, comment, sendSms) => async (dispatch, getState) => {
    const {
      mainDateStore,
      openShifts,
      overtimeCollections,
      settings: { locationSettings },
      scheduleState,
      userEmployees,
      scheduleLocationFilter,
      jobtitleFilter,
    } = getState().reducer;

    try {
      const locationsIds = scheduleLocationFilter;
      const { selectedJobtitles } = jobtitleFilter;
      const selectedJobTitlesIds = selectedJobtitles.map(({ id }) => id);
      const publishShitsPromises = locationsIds.map(async locId => {
        const { from, to } = getRelevantDateRangeForLocation(locationSettings[locId], mainDateStore.dateArray);
        const visibleEmployeeIds = [
          ...scheduleState.locations[locId].visible,
          ...scheduleState.locations[locId].supplementary,
        ];
        const shiftsToPublish = userEmployees
          .filter(e => !e.inactive)
          .flatMap(e =>
            e.shifts.filter(
              s =>
                mainDateStore.dateArray.includes(s.date) &&
                locationsIds.includes(s.location.id) &&
                s.draft &&
                visibleEmployeeIds.includes(s.employee.id),
            ),
          );

        const employeeIdsWithShiftsToPublish = Array.from(new Set(shiftsToPublish.map(s => s.employee.id)));
        const visibleEmployeesWithShiftsToPublish = visibleEmployeeIds.filter(employeeId => {
          const hasOvertimeCollectionToPublish = overtimeCollections[employeeId]?.some(overtime =>
            mainDateStore.dateArray.includes(moment(overtime.start_timestamp).format('YYYY-MM-DD')),
          );
          return employeeIdsWithShiftsToPublish.includes(employeeId) || hasOvertimeCollectionToPublish;
        });
        const hasShiftsForEmployee = visibleEmployeesWithShiftsToPublish.length > 0;
        const hasOpenShifts = openShifts.some(openShift => openShift.location.id === locId);

        const visibleJobTitlesIdsForOpenShiftFilters = openShifts.reduce((acc, openShift) => {
          if (
            selectedJobTitlesIds.includes(openShift.job_title.id) &&
            openShift.draft &&
            !acc.includes(openShift.job_title.id)
          )
            return [...acc, openShift.job_title.id];
          return acc;
        }, []);

        const visibleJobTitlesIdsForScheduleShiftFilters = shiftsToPublish.reduce((acc, openShift) => {
          if (openShift.draft && !acc.includes(openShift.job_title.id)) return [...acc, openShift.job_title.id];
          return acc;
        }, []);

        const {
          data: { numberOfPublishedBlocks },
        } = await conn.publishOvertimeCollections(from, to, visibleEmployeesWithShiftsToPublish);

        if (numberOfPublishedBlocks > 0) {
          dispatch(publishOvertimeCollectionsSuccessful(from, to, visibleEmployeesWithShiftsToPublish));
        }

        if (!hasOpenShifts && !hasShiftsForEmployee) {
          return;
        }

        const hasEmployeesToNotify = visibleEmployeesWithShiftsToPublish.length > 0;
        const notifyShifts = sendNotif && hasEmployeesToNotify && publishShifts;
        const notifyOpenShifts = sendNotif && publishOpenShifts;
        const notifyWithSms = notifyShifts && sendSms;

        const scheduleObject = {
          from,
          to,
          selectedLocationId: locId,
          scheduleShiftFilters: {
            selectedEmployeeIds: visibleEmployeesWithShiftsToPublish,
            selectedJobTitleIds: visibleJobTitlesIdsForScheduleShiftFilters,
          },
          openShiftFilters: {
            selectedJobTitleIds: visibleJobTitlesIdsForOpenShiftFilters,
          },
          publishShifts,
          publishOpenShifts,
          notifyAboutShifts: notifyShifts,
          notifyAboutOpenShifts: notifyOpenShifts,
          sendAdditionalSMSNotifications: notifyWithSms,
          comment,
        };

        await conn.publishSchedule(scheduleObject);

        const dateArray = getRangeBetweenDates(from, to);
        if (publishShifts) {
          dispatch(publishDraftShiftsSuccesful(locId, dateArray, visibleEmployeesWithShiftsToPublish));
          dispatch(trackIntercomEvent(PUBLISH_DRAFT_SHIFTS_SUCCESFUL));
        }
        if (publishOpenShifts)
          dispatch(publishDraftOpenShiftsSuccesful(locId, from, to, mainDateStore.dateArray, selectedJobTitlesIds));
      });
      await Promise.all(publishShitsPromises);
    } catch (error) {
      dispatch(connectionError(error));
    }
  };

const publishDraftShiftsSuccesful = (locationId, dateArray, employeeIds) => ({
  type: actionTypes.PUBLISH_DRAFT_SHIFTS_SUCCESFUL,
  payload: {
    location_id: locationId,
    dateArray,
    employeeIds,
  },
});

export const addInitShift = (employeeId, shift) => ({
  type: actionTypes.ADD_SHIFT_TO_EMPLOYEE,
  payload: {
    employee_id: employeeId,
    shift,
  },
});

export const addInitShifts = shifts => ({
  type: actionTypes.ADD_SHIFTS_TO_EMPLOYEES,
  payload: shifts,
});

export const addShiftSuccessfulByEmployeeId = (newShift, uid, employeeId) => (dispatch, getState) => {
  const { shifts } = getState().reducer;
  dispatch({
    type: actionTypes.ADD_SHIFT_SUCCESFUL,
    payload: {
      new_shift: newShift,
      uid,
      employee_id: employeeId,
      shift: shifts.data[employeeId].shifts[uid],
    },
  });
};

export const addShiftSuccesful =
  (newShift, uid, employee, notify = true) =>
  (dispatch, getState, intl) => {
    const { shifts } = getState().reducer;
    const { first_name, last_name } = employee;
    let notification;
    if (notify) {
      notification = {
        title: intl.formatMessage(messages.addShift),
        description: intl.formatMessage(messages.addShiftDesc, { first_name, last_name }),
        type: 'success',
      };
    }
    dispatch({
      type: actionTypes.ADD_SHIFT_SUCCESFUL,
      payload: {
        new_shift: newShift,
        uid,
        employee_id: employee.id,
        shift: shifts.data[employee.id].shifts[uid],
      },
      notification,
    });
  };

export const addShiftsSuccessful = (newShifts, uuids) => ({
  type: actionTypes.ADD_SHIFTS_SUCCESFUL,
  newShifts,
  uuids,
});

export const addShiftFailure =
  (employeeId, shift, err, notify = true) =>
  (dispatch, getState, intl) => {
    let notification;
    if (notify) {
      const { description, title } = handleAddShiftFailureErrorMessage(err, intl);
      notification = {
        title: title || 'Błąd!',
        description,
        type: 'error',
      };
    }
    dispatch(
      (() => ({
        type: actionTypes.ADD_SHIFT_FAILURE,
        payload: {
          employee_id: employeeId,
          shift_id: shift.id,
          shift,
        },
        notification,
      }))(),
    );
  };

/**
 * Adds a shift to employee_id
 * @param {integer} employee_id
 * @param {shift} shift -
 *          comment, date, draft, employee: {id}, job_title, title, working_hours
 */
export const addShift =
  (employee, shift, notify = false, requestType) =>
  dispatch =>
    new Promise((resolve, reject) => {
      // Generate Random UID
      const uid = uuid4();
      // Add Shift to Employee still loading
      shift.id = uid;
      const newShift = mapTimestampsToShift(shift);
      dispatch(addInitShift(employee.id, newShift));
      dispatch(trackIntercomEvent(ADD_SHIFT_TO_EMPLOYEE));
      conn
        .addShift(shift, requestType)
        .then(response => {
          // Finish adding shift
          dispatch(addShiftSuccesful(response.data, uid, employee, notify));
          resolve(response.data.id);
        })
        .catch(err => {
          // Catch failure
          console.error('[ADD SHIFT ACTION] ', err);
          dispatch(addShiftFailure(employee.id, shift, err));
          reject(err);
        });
    });

export const swapShifts = (originShift, targetShift) => dispatch => {
  conn
    .swapShifts(originShift, targetShift)
    .then(res => {
      dispatch(deleteShift(originShift.employee.id, originShift.id, false));
      dispatch(deleteShift(targetShift.employee.id, targetShift.id, false));
      res.data.map(shift => {
        const employeeId = shift.employee.id;
        const uid = uuid4();
        const newShift = mapTimestampsToShift(shift);
        dispatch(addInitShift(employeeId, { ...newShift, id: uid }));
        dispatch(addShiftSuccessfulByEmployeeId(newShift, uid, employeeId));
      });
      dispatch(swapShiftsSuccess());
    })
    .catch(() => {
      dispatch(swapShiftsFailure());
    });
};

export const swapShiftsSuccess = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.SWAP_SHIFTS_SUCCESS,
    notification: {
      title: intl.formatMessage(messages.successToast),
      description: intl.formatMessage(messages.swapShiftsSuccess),
      type: 'success',
    },
  });
};

export const swapShiftsFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.SWAP_SHIFTS_FAILURE,
    notification: {
      title: intl.formatMessage(messages.failToast),
      description: intl.formatMessage(messages.swapShiftsFailure),
      type: 'error',
    },
  });
};

export const addObjWithRepeat = (object, repeatObj) => {
  const dateRange = getDatesFromRepeatObj(repeatObj);

  return dateRange.map(date => ({
    ...object,
    date,
  }));
};

export const getDaysFreeFromWork = () => (dispach, getState) => {
  const { calendarData } = getState().reducer;
  return calendarData.holidays.reduce((acc, { freeFromWork, date }) => {
    if (freeFromWork) {
      return [...acc, date];
    }
    return acc;
  }, []);
};

export const massAddShiftsWithRepeat = (employee, shifts, repeatObj) => dispatch => {
  const errors = [];
  errors.push(shifts.forEach(newShift => dispatch(addShiftsWithRepeat(employee, newShift, repeatObj))));
  return errors.find(err => err !== '');
};

export const addShiftsWithRepeat = (employee, shift, repeatObj) => (dispatch, getState, intl) => {
  const { userEmployees, settings, scheduleUIState, calendarData } = getState().reducer;

  const { shiftsToAdd, error } = getShiftsToAdd(
    employee,
    shift,
    repeatObj,
    userEmployees,
    settings,
    scheduleUIState,
    calendarData,
    intl,
  );

  if (!error) dispatch(addMultipleShifts(shiftsToAdd));
  return error;
};

export const addMultipleShiftsWithRepeat = (selectedEmployees, shift, repeatObj) => (dispatch, getState, intl) => {
  const { userEmployees, settings, scheduleUIState, calendarData } = getState().reducer;

  const shifts = selectedEmployees.reduce((acc, employee) => {
    const shiftsForEmployee = getShiftsToAdd(
      employee,
      { ...shift, employee },
      repeatObj,
      userEmployees,
      settings,
      scheduleUIState,
      calendarData,
      intl,
    );

    acc.push(shiftsForEmployee);

    return acc;
  }, []);

  const numberOfEmployeesWithError = shifts.filter(({ error }) => error).length;

  if (!numberOfEmployeesWithError) {
    const allShifts = shifts.flatMap(({ shiftsToAdd }) => shiftsToAdd);
    dispatch(addMultipleShifts(allShifts));
  }

  return numberOfEmployeesWithError;
};

export const deleteShiftRequest =
  (employeeId, shiftId, notify = true) =>
  dispatch =>
    new Promise((resolve, reject) => {
      conn
        .deleteShift(shiftId)
        .then(() => {
          dispatch(deleteShift(employeeId, shiftId, notify));
          resolve();
        })
        .catch(err => {
          dispatch(connectionError(err));
          reject(err);
        });
    });

export const deleteShift =
  (employeeId, shiftId, notify = true) =>
  (dispatch, getState, intl) => {
    const { shifts, scheduleLoanedEmployees } = getState().reducer;
    const isLoanedEmployee = !isEmpty(scheduleLoanedEmployees.scheduleLoanedEmployees[employeeId]);
    let notification;
    if (notify === true) {
      notification = {
        type: 'success',
        title: intl.formatMessage(messages.deletedShiftTitle),
        description: '',
      };
    }
    dispatch({
      type: actionTypes.DELETE_SHIFT,
      payload: {
        employee_id: employeeId,
        shift_id: shiftId,
        shift: shifts.data[employeeId].shifts[shiftId],
        isLoanedEmployee,
      },
      notification,
    });
  };

export const massDeleteShifts =
  (employeeId, shiftsIds, notify = true) =>
  dispatch => {
    shiftsIds.forEach(shiftId => dispatch(deleteShiftRequest(employeeId, shiftId, notify)));
  };

export const deleteUnpublishedShifts = () => async (dispatch, getState) => {
  const { userEmployees, mainDateStore, scheduleLocationFilter: selectedLocationIds, settings } = getState().reducer;
  const visibleEmployeeIds = dispatch(getVisibleEmployeesIdsForScheduleView());

  const localShiftsToDelete = [];
  const { allShiftsToDeleteIds } = selectedLocationIds.reduce(
    (agg, locationId) => {
      const selectedLocationSettings = settings.locationSettings[locationId];
      const shiftEditDisabledUntil = selectedLocationSettings
        ? selectedLocationSettings.disable_location_schedule_shifts_edit_until
        : null;
      const relevantDateArray = getRelevantDateRange(mainDateStore.dateArray, shiftEditDisabledUntil);
      const shiftsToDeleteIdArray = userEmployees
        .filter(e => visibleEmployeeIds.includes(e.id))
        .map(e =>
          e.shifts
            .filter(s => {
              if (relevantDateArray.includes(s.date) && s.location.id === locationId && s.draft) {
                localShiftsToDelete.push({
                  employee_id: e.id,
                  shift_id: s.id,
                });
                return true;
              }
              return false;
            })
            .map(s => s.id),
        )
        .reduce((a, b) => a.concat(b), []);
      return {
        allShiftsToDeleteIds: [...agg.allShiftsToDeleteIds, ...shiftsToDeleteIdArray],
      };
    },
    { allShiftsToDeleteIds: [] },
  );
  dispatch(increaseLoaderCounter('blocking'));
  const chunkSize = 1000;
  for (let i = 0; i < allShiftsToDeleteIds.length; i += chunkSize) {
    const chunk = allShiftsToDeleteIds.slice(i, i + chunkSize);
    try {
      await conn.deleteMassShifts(chunk);
      await new Promise(resolve => setTimeout(resolve, 500));
    } catch (err) {
      dispatch(connectionError(err));
    }
  }
  const actions = localShiftsToDelete.map(s => {
    dispatch(deleteShift(s.employee_id, s.shift_id, false));
  });
  await Promise.all(actions);
  dispatch(decreaseLoaderCounter('blocking'));
  dispatch(deleteUnpublishedShiftsSuccesful());
  dispatch(clearWorkingRules());

  dispatch(massDeleteOpenShifts(selectedLocationIds, mainDateStore.customDate.start, mainDateStore.customDate.end));
};

const deleteUnpublishedShiftsSuccesful = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_UNPUBLISHED_SHIFTS_SUCCESFUL,
    payload: {},
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.successToast),
      description: intl.formatMessage(messages.deletedUnpublishedShiftsDesc),
    },
  });
};

export const massEditShifts = shiftsPromises => dispatch => {
  Promise.all(shiftsPromises)
    .then(() => {
      dispatch(editMassShiftSuccesful(shiftsPromises.length));
    })
    .catch(err => {
      connectionError(err);
    });
};

export const massEditShiftsForPayroll = (employee, newShiftsObjectsArray) => dispach => {
  newShiftsObjectsArray.forEach(newShift => dispach(editShift(employee, newShift)));
};

// Updates the shift, accepts a NEW SHIFT OBJECT.
export const editShift =
  (employee, newShiftObject, notify = true) =>
  dispatch =>
    new Promise((resolve, reject) => {
      conn
        .changeShift(newShiftObject)
        .then(response => {
          dispatch(editShiftSuccesful(employee.id, mapTimestampsToShift(newShiftObject), response, notify));
          resolve(response);
        })
        .catch(err => {
          console.error('[EDIT SHIFT ACTION] ', err);
          dispatch(editShiftError(err.response.data));
          reject(err);
        });
    });

export const editShiftError = error => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.EDIT_SHIFT_FAILURE,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.failToast),
      description: intl.formatMessage(
        error.errorCode ? shiftErrorCodesToMessages[error.errorCode] : messages.editShiftFailure,
      ),
    },
  });
};

export const editShiftSuccesful = (employeeId, newShiftObject, response, notify) => (dispatch, getState, intl) => {
  let notification;
  if (notify) {
    notification = {
      type: 'success',
      title: intl.formatMessage(messages.successToast),
      description: intl.formatMessage(messages.editShiftSuccesful),
    };
  }

  dispatch({
    type: actionTypes.EDIT_SHIFT_SUCCESFUL,
    notification,
    payload: {
      employee_id: employeeId,
      newShiftObject,
    },
  });
};

export const addAvailability =
  (availabilityObject, notification = true, requestType) =>
  (dispatch, getState) => {
    const { userPermissions, userCustomTypes } = getState().reducer;
    const customAva = userCustomTypes.find(ava => ava.id === availabilityObject.type_id);
    let newAvailabilityObject =
      availabilityObject.type === 'custom'
        ? {
            ...availabilityObject,
            count_hours: customAva.count_hours ? customAva.count_hours : null,
            count_hours_in_payroll: customAva.count_hours_in_payroll ? customAva.count_hours_in_payroll : false,
          }
        : availabilityObject;

    if (userPermissions.isEmployee) {
      if (newAvailabilityObject.type === 'custom') {
        newAvailabilityObject = {
          ...newAvailabilityObject,
          customAva,
        };
      }
      conn
        .createCurrentAvailability(newAvailabilityObject, requestType)
        .then(response => {
          dispatch(addAvailabilitySuccesful(response.data.id, newAvailabilityObject, notification));
        })
        .catch(err => connectionError(err));
    } else {
      conn
        .createAvailability(newAvailabilityObject.employee.id, newAvailabilityObject, requestType)
        .then(response => {
          dispatch(addAvailabilitySuccesful(response.data.id, newAvailabilityObject, notification));
        })
        .catch(err => connectionError(err));
    }
  };

export const addAvailabilitySuccesful = (newId, availabilityObject, showNotification) => (dispatch, getState, intl) => {
  const { userCustomTypes, employees } = getState().reducer;

  const employee = employees.data[availabilityObject?.employee?.id];
  const addedAvailabilityObject = { ...availabilityObject, id: newId };
  const availabilityWithTypeData = getExtendedAvailabilityBlock(
    addedAvailabilityObject,
    userCustomTypes,
    employee,
    intl,
  );
  const notification = showNotification
    ? {
        title: intl.formatMessage(messages.successToast),
        description: intl.formatMessage(messages.addAvaSuccesful),
        type: 'success',
      }
    : undefined;
  dispatch({
    type: actionTypes.ADD_AVAILABILITY_SUCCESFUL,
    payload: availabilityWithTypeData,
    notification,
  });
};

export const deleteAvailabilitySuccesful = (employeeId, availabilityObject, notify) => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_AVAILABILITY_SUCCESFUL,
    payload: { employee_id: employeeId, availabilityObject },
    ...(notify && {
      notification: {
        title: intl.formatMessage(messages.successToast),
        description: intl.formatMessage(messages.deleteAvaSuccesull),
        type: 'success',
      },
    }),
  });
};

export const deleteAvailability =
  (employeeId, availabilityObject, notify = true, requestType) =>
  dispatch =>
    // This accepts an array of IDs (["1", "2'"]) for whatever reason

    new Promise((resolve, reject) => {
      conn
        .deleteAvailability([availabilityObject.id], requestType)
        .then(() => {
          dispatch(deleteAvailabilitySuccesful(employeeId, availabilityObject, notify));
          resolve();
        })
        .catch(err => {
          connectionError(err);
          reject(err);
        });
    });

export const changeAvailability = availabilityObject => dispatch => {
  conn
    .changeAvailability(availabilityObject)
    .then(dispatch(changeAvailabilitySuccesful(availabilityObject)))

    .catch(err => connectionError(err));
};

export const changeAvailabilitySuccesful = availbilityObject => (dispatch, getState, intl) => {
  const { userCustomTypes } = getState().reducer;
  const availabilityWithTypeData = getExtendedAvailabilityBlock(
    availbilityObject,
    userCustomTypes,
    availbilityObject.employee,
    intl,
  );
  dispatch({
    type: actionTypes.CHANGE_AVAILABILITY_SUCCESFUL,
    payload: availabilityWithTypeData,
    notification: {
      title: intl.formatMessage(messages.successToast),
      description: intl.formatMessage(messages.changeAvaSuccesful),

      type: 'success',
    },
  });
};
export const confirmAvailability = availabilityObject => (dispatch, getState, intl) => {
  conn
    .publishAvailability(availabilityObject)
    .then(() => {
      const { employees } = getState().reducer;
      const ava = { ...availabilityObject, draft: false };
      const employee = employees.data[ava.employee.id];
      dispatch(
        confirmAvailabilitySuccesful({
          ...ava,
          popover: getAvaCornerPopover(ava, employee, intl),
        }),
      );
    })
    .catch(err => connectionError(err));
};

export const confirmAvailabilitySuccesful = availbilityObject => ({
  type: actionTypes.CHANGE_AVAILABILITY_SUCCESFUL,
  payload: availbilityObject,
});

export const deleteShiftArraySuccesful = shifts => (dispatch, getState, intl) => {
  const shifts_length = shifts.length;
  dispatch({
    type: actionTypes.DELETE_MASS_SHIFTS_SUCCESFUL,
    payload: shifts.length,
    notification: {
      title: intl.formatMessage(messages.successToast),
      description: intl.formatMessage(messages.deleteShiftCorrectly, { shifts_length }),
      type: 'success',
    },
  });
};

export const editMassShiftSuccesful = shiftsLength => ({
  type: actionTypes.EDIT_MASS_SHIFTS_SUCCESFUL,
  payload: shiftsLength,
  notification: {
    title: 'Sukces!',
    description: `Poprawnie edytowano zmiany (ilość: ${shiftsLength})`,
    type: 'success',
  },
});

export const addMassShiftSuccesful = shiftsLength => ({
  type: actionTypes.ADD_MASS_SHIFTS_SUCCESFUL,
  payload: shiftsLength,
  notification: {
    title: 'Sukces!',
    description: `Poprawnie dodany zmiany (ilość: ${shiftsLength})`,
    type: 'success',
  },
});

/**
 * Delets an array of shifts.
 * @param {Array<Object>} shifts
 */
export const deleteShiftArray = (shifts, requestType) => async dispatch => {
  const chunkSize = 1000;
  const deletedShifts = [];
  for (let i = 0; i < shifts.length; i += chunkSize) {
    const chunk = shifts.slice(i, i + chunkSize);
    try {
      await conn.deleteMassShifts(
        chunk.map(s => s.id),
        requestType,
      );
      chunk.map(s => {
        dispatch(deleteShift(s.employee.id, s.id, false));
      });
      deletedShifts.push(...chunk);
      await new Promise(resolve => setTimeout(resolve, 500));
    } catch (err) {
      dispatch(connectionError(err));
    }
  }
  if (deletedShifts.length > 0) {
    dispatch(deleteShiftArraySuccesful(deletedShifts));
  }
};

export const addMassShift = shiftsPromises => dispatch => {
  Promise.all(shiftsPromises)
    .then(() => {
      dispatch(addMassShiftSuccesful(shiftsPromises.length));
    })
    .catch(err => {
      connectionError(err);
    });
};

export const setUserEmail = email => ({
  type: actionTypes.SET_USER_EMAIL,
  payload: email,
});

export const getRegulations = () => () =>
  new Promise((resolve, reject) => {
    conn
      .getRegulations()
      .then(result => {
        resolve(result.data);
      })
      .catch(err => {
        console.warn(err);
        reject(err);
      });
  });

export const confirmRegulations = regulations_id => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .confirmRegulations(regulations_id)
      .then(result => {
        resolve(result.data);
        dispatch({
          type: actionTypes.ACCEPT_CURRENT_REGULATIONS,
        });
      })
      .catch(err => {
        console.warn(err);
        reject(err);
      });
  });

export const getShiftsSummary = (date, locationIds) => (dispatch, getState) => {
  const { userEmployees } = getState().reducer;
  let getBefore = 0;
  let getAfter = 0;

  userEmployees.forEach(employee => {
    if (
      !employee.employment_conditions ||
      !employee.employment_conditions.schedule_cycle ||
      !employee.employment_conditions.schedule_cycle.year
    ) {
      return;
    }
    const scheduleCycle = employee.employment_conditions.schedule_cycle;
    const startObj = moment(date).year(scheduleCycle.year).month(scheduleCycle.month).startOf('month');
    const monthDiff = moment(date).diff(startObj, 'month') + 1;
    const monthOfSpan = monthDiff % scheduleCycle.duration;
    const before = monthOfSpan;
    const after = scheduleCycle.duration - before - 1;

    if (before > getBefore) getBefore = before;
    if (after > getAfter) getAfter = after;
  });

  const months = getBefore + getAfter + 1;

  const start = moment(date).subtract(getBefore, 'month').startOf('month');
  const from = start.format('YYYY-MM-DD');
  const to = moment(date).add(getAfter, 'month').endOf('month').format('YYYY-MM-DD');

  const startMonth = start.month();
  const startYear = start.year();
  const monthsArray = [];
  let currentMonth = startMonth;
  let currentYear = startYear;
  for (let i = 0; i < months; i++) {
    monthsArray.push({ month: currentMonth, year: currentYear });
    if (currentMonth === 11) currentYear++;
    currentMonth = (currentMonth + 1) % 12;
  }
  conn
    .getShiftsSummary(from, to, locationIds)
    .then(result => {
      const { data } = result;
      const scheduleStatsPayload = userEmployees.map(employee => {
        const scheduleStats = [];
        monthsArray.forEach(({ month, year }) => {
          const stat = data.find(
            item => item.month === String(month + 1) && item.year === String(year) && item.employee_id === employee.id,
          ) || {
            minutes: 0,
            month: String(month + 1),
            year: String(year),
          };
          scheduleStats.push(stat);
        });
        return {
          employee_id: employee.id,
          scheduleStats,
        };
      });
      dispatch({
        type: actionTypes.GET_SCHEDULE_STATS,
        payload: scheduleStatsPayload,
      });
    })
    .catch(err => {
      console.warn(err.response);
    });
};

const AUTO_UPDATE_COOKIE_NAME = 'autoUpdateLock';

export const getAppVersionAndUpdateIfNeeded = async () => {
  const response = await conn.getAppVersion();
  const versionInfo = response.data.frontend;
  const { version } = versionInfo;
  console.info(`Production target version ${version}`);
  if (version > appVersion) {
    const valueFromCookie = getCookie(AUTO_UPDATE_COOKIE_NAME);
    if (!valueFromCookie || valueFromCookie !== version) {
      setCookie(AUTO_UPDATE_COOKIE_NAME, versionInfo.version, 1);
      if (import.meta.env.VITE_NODE_ENV !== 'development' && location?.reload) {
        location.reload(true);
      } else {
        console.info('Auto update would run if not for dev mode');
      }
    } else {
      console.info('New version reload blocked by cookie');
    }
  }
};
