/* eslint no-use-before-define: 0 */
import moment from 'moment';

import {
  ATTENDANCE_ABSENCE,
  ATTENDANCE_EARLY_QUIT,
  ATTENDANCE_EARLY_START,
  ATTENDANCE_LATE_QUIT,
  ATTENDANCE_LATE_START,
  ATTENDANCE_WITHOUT_SHIFT,
} from '@/constants/attendanceDetailTypes.js';
import {
  ATTENDANCE_ABSENCE_COLOR,
  ATTENDANCE_ACCEPTED_OVERTIME_COLOR,
  ATTENDANCE_LATE_COLOR,
  ATTENDANCE_SUCCESS_COLOR,
  KADRO_BLUE_COLOR,
  KADRO_ORANGE_COLOR,
} from '@/constants/colors.js';
import {
  ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE,
  ATTENDANCE_EDIT_OTHERS_DISABLE,
  MANAGER_EDIT_OWN_ATTENDANCES,
} from '@/constants/Restrictions';
import { getEmployeeRank } from '@/utils/userEmployeesHelpers';

import {
  calculateDurationBetweenTimestamps,
  calculateDurationMinutes,
  getNowInMinutes,
  moveDateForwardByOne,
  parseMinutesToHumanForm,
} from './dateHelper.js';

export const calculateAttendanceBarStyle = hours => {
  const duration = calculateDurationMinutes(hours);
  const startHour = calculateDurationMinutes(`00:00-${hours.split('-')[0]}`);
  const endSplit = hours.split('-')[1];
  let endHour;
  if (endSplit.includes('_')) {
    endHour = getNowInMinutes();
  } else {
    endHour = calculateDurationMinutes(`00:00-${endSplit}`);
  }
  let width = (duration / 60 / 24) * 100;
  let left;
  if (startHour === 0) {
    left = 0.5;
  } else {
    left = (startHour / 60 / 24) * 100;
  }
  if (endHour < startHour) {
    width = 100 - left;
  }
  return { left: `${left}%`, width: `${width}%` };
};

/**
 * Returns the style for TimeBar
 */
export const calculateTimeBarStyle = () => {
  const startHour = moment().hour() * 60 + moment().minutes();
  let left;
  if (startHour === 0) {
    left = 0.5;
  } else {
    left = (startHour / 60 / 24) * 100 - 0.1;
  }
  return { left: `${left}%` };
};

const timestampTypePriority = ['ss', 'as', 'ae', 'se'];

// Attendance types - defines name, id and display color
const attendanceTypes = [
  { id: 0, name: 'present', color: ATTENDANCE_SUCCESS_COLOR },
  { id: 1, name: ATTENDANCE_LATE_START, color: ATTENDANCE_LATE_COLOR },
  { id: 2, name: ATTENDANCE_EARLY_START, color: KADRO_BLUE_COLOR },
  { id: 3, name: ATTENDANCE_WITHOUT_SHIFT, color: KADRO_ORANGE_COLOR },
  { id: 4, name: ATTENDANCE_ABSENCE, color: ATTENDANCE_ABSENCE_COLOR },
  { id: 5, name: ATTENDANCE_EARLY_QUIT, color: ATTENDANCE_LATE_COLOR },
  { id: 6, name: ATTENDANCE_LATE_QUIT, color: KADRO_BLUE_COLOR },
  { id: 7, name: 'attendanceOverlapping', color: ATTENDANCE_ABSENCE_COLOR },
];

//  We have 4 types of timestamps:
//  - ss - shift start - timestamp of starting shiftInt
//  - se - shift end
//  - as - attendance start
//  - ae - attendance end
//  Every 2 timestamps are combined to create block type
//  eg. shift started (ss) and then attendance started (as)
//  which gives ssas that is matched with block types below ass
//  late start.
//
//  Sometimes there is more then one meaning of type eg. asae which
//  can be normal attendance or attendance without shift. In such case
//  we add extra block type and contition to block mapping func.

const blockTypes = {
  ssas: attendanceTypes[1],
  asss: attendanceTypes[2],
  asae: attendanceTypes[0],
  asse: attendanceTypes[0],
  ssae: attendanceTypes[0],
  aese: attendanceTypes[5],
  seae: attendanceTypes[6],
  ssse: attendanceTypes[4],
  asas: attendanceTypes[0],
  aeae: attendanceTypes[0],
  noShift: attendanceTypes[3],
  overlap: attendanceTypes[7],
};

export const generateAttendancesDataForEmployee = (employee, date, relevantShifts, relevantAttendances) => {
  const now = moment().format('YYYY-MM-DD HH:mm:ss');
  const today = moment().format('YYYY-MM-DD');
  const startTimestamp = `${date} 00:00:00`;
  const isToday = date === today;
  const minutesInADay = 24 * 60;

  // for all shifts and attendances we extract start and end timestamps
  // then sort them chronologically
  let timestamps = [];
  relevantShifts.forEach(shift => {
    timestamps.push({ timestamp: shift.start_timestamp, type: 'ss' });
    timestamps.push({ timestamp: shift.end_timestamp, type: 'se' });
  });
  relevantAttendances.forEach(attendance => {
    timestamps.push({
      timestamp: attendance.start_timestamp,
      type: 'as',
      attendance,
    });
    timestamps.push({
      timestamp: attendance.end_timestamp,
      type: 'ae',
      attendance,
    });
  });

  // If we displayed day is today then we exchange all
  // empty end timestamps to current time, otherwise
  // we exchange all to 00:00 of next day.
  timestamps.forEach(ts => {
    if (ts.timestamp === null) {
      ts.timestamp = now;
      ts.notDefined = true;
    }
  });
  timestamps = timestamps.sort((a, b) => {
    if (a.timestamp > b.timestamp) {
      return 1;
    }
    if (a.timestamp < b.timestamp) {
      return -1;
    }
    // There was problem with shifts with the same timestamps
    // if shift end is in the same moment as other shift start then end goes first
    const aPriority = timestampTypePriority.indexOf(a.type);
    const bPriority = timestampTypePriority.indexOf(b.type);
    if (aPriority < bPriority) {
      return 1;
    }
    return -1;
  });

  // blocks used for display and for generating details
  let blocks = [];
  let lastType = '';

  for (let i = 0; i < timestamps.length - 1; i++) {
    const t1 = timestamps[i];
    const t2 = timestamps[i + 1];

    // if start and end timestamp are equal then we
    // dont add the block
    // TODO: mabe add some function with variable accuracy
    // cause now it will show even 1ms difference
    if (t1.timestamp === t2.timestamp) continue;

    let type = t1.type + t2.type; // Block type eg. ssas

    if (lastType === 'asas') type = 'overlap';

    lastType = type;
    // if asae (attendance start and end) then we check if before was
    // shift start and if not its attendance without shift
    // TODO: Mabe will have to check more then one timestamp
    if (['asae', 'asas', 'aeae'].includes(type)) {
      if (type === 'asae' && t1.attendance.id !== t2.attendance.id) {
        type = 'overlap';
      } else if (i === 0 || (i > 0 && timestamps[i - 1].type !== 'ss')) {
        type = 'noShift';
      }
      // if ssse is after 'as' then it's presence,
      // otherwise its absence
    } else if (type === 'ssse') {
      if (i > 0) {
        for (let j = i - 1; j >= 0; j--) {
          if (timestamps[j].type === 'as') {
            type = 'asae';
            break;
          } else if (timestamps[j].type === 'ae') {
            break;
          }
        }
      }
      // sess can be between 2 shifts in one attendance
      // then we want to add extra hours
    } else if (type === 'sess') {
      if (i > 0) {
        for (let j = i - 1; j >= 0; j--) {
          if (timestamps[j].type === 'as') {
            type = 'seae';
            break;
          } else if (timestamps[j].type === 'ae') {
            break;
          }
        }
      }
    }

    // if the type is not defined in blockTypes array
    // then we dont show it

    if (!blockTypes[type]) continue;
    type = { ...blockTypes[type] };

    // if the start timestamp was not defined
    // then we display only gray block whitout any type
    if (t1.notDefined) {
      type.color = '#dedede';
      type.id = null;
    }
    // Calculating length and position
    const length = calculateDurationBetweenTimestamps(t2.timestamp, t1.timestamp);
    const position = (calculateDurationBetweenTimestamps(t1.timestamp, startTimestamp) * 100) / minutesInADay - 0.1;

    const animate = false;

    const attendanceForTimestamp = relevantAttendances?.find(
      ({ start_timestamp: attendancesStartTimestamp }) => attendancesStartTimestamp === t1.timestamp,
    );
    blocks.push({
      startTimestamp: t1.timestamp,
      endTimestamp: t2.timestamp,
      type,
      length,
      width: (length * 100) / minutesInADay,
      position,
      animate,
      labels: attendanceForTimestamp?.labels,
      isLoaned: Boolean(employee.isLoaned),
    });
  }

  // copy blocks to use as details before adding
  // gray areas if isToday.
  let detailBlocks = blocks.slice();
  // If displayed day is today we break all absent blocks
  if (isToday) {
    const newBlocks = [];
    blocks.map(block => {
      if (block.type.id === 4) {
        if (block.startTimestamp > now) {
          // No breaking needed
          newBlocks.push({ ...block, type: { color: '#dedede' } });
          return undefined;
        }
        if (block.endTimestamp > now) {
          // Breaking into 2 blocks
          const grayLen = calculateDurationBetweenTimestamps(block.endTimestamp, now);
          const colorLen = block.length - grayLen;
          const colorWidth = (colorLen * 100) / minutesInADay;
          newBlocks.push({ ...block, length: colorLen, width: colorWidth, endTimestamp: now });
          newBlocks.push({
            ...block,
            type: { color: '#dedede' },
            length: grayLen,
            width: (grayLen * 100) / minutesInADay,
            position: block.position + colorWidth - 0.1,
            startTimestamp: now,
          });
          return undefined;
        }
      }
      newBlocks.push(block);
    });

    blocks = newBlocks;
  }

  // Generating detail blocks

  // for absent blocks that are today, but not yet happende
  // we want to hide them. Setting id = null makes it so that
  // this block will be filtered

  detailBlocks.forEach(block => {
    if (isToday && block.type.id === 4 && block.startTimestamp > now) {
      block.type.id = null;
    }
  });

  // Filtering all blocks with id > 0, meaning all except presence and gray blocks
  detailBlocks = detailBlocks.filter(block => block.type.id > 0);
  detailBlocks.forEach(block => {
    block.duration = block.length;
    block.detailType = block.type.name;
    block.sourceAttendance = {};
    if (['attendance_without_shift', 'attendance_early_start'].includes(block.detailType)) {
      block.sourceAttendance = relevantAttendances.find(
        attendance => attendance.start_timestamp === block.startTimestamp,
      );

      if (!block.sourceAttendance) {
        const unfinishedAttendanceInTheSameDay = relevantAttendances.find(
          a => !a.end_timestamp && moment(a.start_timestamp).isSame(block.startTimestamp, 'day'),
        );

        if (unfinishedAttendanceInTheSameDay) {
          block.sourceAttendance = unfinishedAttendanceInTheSameDay;
        }
      }
    }
    if (block.detailType === 'attendance_late_quit') {
      relevantAttendances.forEach(attendance => {
        if (attendance.end_timestamp === block.endTimestamp) {
          block.sourceAttendance = attendance;
        }
      });
    }
    if (
      ['attendance_early_start', 'attendance_late_quit', 'attendance_without_shift'].includes(block.detailType) &&
      block.sourceAttendance
    ) {
      const { early_in: earlyIn, late_out: lateOut } = block.sourceAttendance;
      if (
        (earlyIn && block.detailType === 'attendance_early_start') ||
        (lateOut && block.detailType === 'attendance_late_quit') ||
        (earlyIn && lateOut && block.detailType === 'attendance_without_shift')
      ) {
        block.type.color = ATTENDANCE_ACCEPTED_OVERTIME_COLOR;
        block.type.accepted = true;
      }
    }
  });

  const combinedBlocks = [];
  if (blocks.length) {
    let combinedBlock = {
      startTimestamp: blocks[0].startTimestamp,
      endTimestamp: blocks[0].endTimestamp,
    };
    for (let i = 1; i < blocks.length; i++) {
      if (blocks[i].startTimestamp === combinedBlock.endTimestamp) {
        combinedBlock.endTimestamp = blocks[i].endTimestamp;
      } else {
        combinedBlocks.push({ ...combinedBlock });
        combinedBlock = {
          startTimestamp: blocks[i].startTimestamp,
          endTimestamp: blocks[i].endTimestamp,
        };
      }
    }
    combinedBlocks.push(combinedBlock);
  }

  const animatedBlock = combinedBlocks.find(cb => cb.startTimestamp <= now && cb.endTimestamp >= now);
  if (isToday && animatedBlock) {
    blocks.forEach(block => {
      if (block.endTimestamp >= animatedBlock.startTimestamp && block.endTimestamp <= animatedBlock.endTimestamp) {
        block.animate = true;
        block.combinedBlockStart = animatedBlock.startTimestamp;
        block.combinedBlockEnd = animatedBlock.endTimestamp;
      }
    });
  }
  return { blocks, detailBlocks, combinedBlocks };
};

export const generateDataForAttendances = (employees, date) =>
  employees.map(employee => {
    const relevantShifts = employee.shifts.filter(s => s.date === date);
    const filteredAttendances = employee.attendances.filter(a => a.date === date);

    return generateAttendancesDataForEmployee(employee, date, relevantShifts, filteredAttendances);
  });

export const generateAttendancesDataForReportView = (employees, date, attendancesData) =>
  employees.map(employee => {
    const relevantShifts = employee.shifts.filter(s => s.date === date);
    const filteredAttendances = attendancesData[employee.id]?.filter(a => a.date === date) || [];

    return generateAttendancesDataForEmployee(employee, date, relevantShifts, filteredAttendances);
  });

export const calculatePositionAndLength = arr =>
  arr.map(item => {
    const MINUTES_A_DAY = 24 * 60;
    const startOfDay = moment(item.start_timestamp).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const length = calculateDurationBetweenTimestamps(item.end_timestamp, item.start_timestamp);
    const width = (length * 100) / MINUTES_A_DAY - 0.1;
    const position = (calculateDurationBetweenTimestamps(item.start_timestamp, startOfDay) * 100) / MINUTES_A_DAY - 0.1;
    return { ...item, length, position, width };
  });

/* eslint-enable */

export const getFirstAndLastAttendancesForEmployees = (employees, date) =>
  employees.reduce(
    (prev, employee) => {
      if (!employee.attendances.length) return prev;
      const relevantAttendances = employee.attendances.filter(attendance => attendance.date === date);
      const sortedAttendancesByStart = [
        ...relevantAttendances.sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1)),
      ];
      const sortedAttendancesByEnd = [
        ...relevantAttendances.sort((a, b) => (a.end_timestamp < b.end_timestamp ? 1 : -1)),
      ];

      const startAttendances = [...prev.startAttendances];
      const endAttendances = [...prev.endAttendances];
      if (sortedAttendancesByStart.length) startAttendances.push(sortedAttendancesByStart[0]);
      if (sortedAttendancesByEnd.length) endAttendances.push(sortedAttendancesByEnd[0]);

      return {
        startAttendances,
        endAttendances,
      };
    },
    { startAttendances: [], endAttendances: [] },
  );

export const computeAttendanceStatistics = (employees, date, attendancesSettings) => {
  const attendanceData = employees ? generateDataForAttendances(employees, date, attendancesSettings) : [];
  return attendanceData
    .reduce((attendances, data) => attendances.concat(data.blocks), [])
    .reduce(
      (groupedAttendances, attendance) => ({
        ...groupedAttendances,
        [attendance.type.name]: groupedAttendances[attendance.type.name] + 1,
      }),
      {
        absence: 0,
        attedance_late_start: 0,
        attendance_without_shift: 0,
        present: 0,
        attendance_late_quit: 0,
      },
    );
};

export const computeTimestampsForNewAttendanceHours = (hours, attendance) => {
  const hourSplit = hours.split('-');
  const startDate = attendance.date;
  let endDate = attendance.date;
  if (hourSplit[1] < hourSplit[0]) {
    endDate = moveDateForwardByOne('day', startDate);
  }
  return {
    start_timestamp: `${startDate} ${hourSplit[0]}:00`,
    end_timestamp: `${endDate} ${hourSplit[1]}:00`,
  };
};

export const getAttendancesWithoutShiftsForSelectedEmployees = (attendancesTypeData, selectedEmployees) =>
  (attendancesTypeData || []).filter(attendanceData =>
    (selectedEmployees || []).some(
      selected =>
        selected.employeeId === attendanceData.employee?.id &&
        selected.locationId === attendanceData.detail?.sourceAttendance?.location?.id,
    ),
  );

export const getShiftsWithoutAttendancesForSelectedEmployees = (shiftsWithoutAttendances, selectedEmployees) =>
  shiftsWithoutAttendances.filter(shiftWithoutAtt =>
    selectedEmployees.some(
      selectedEmp =>
        selectedEmp.employeeId === shiftWithoutAtt.employee.id &&
        selectedEmp.locationId === shiftWithoutAtt.locationId &&
        shiftWithoutAtt.detail,
    ),
  );

export const pickAttendanceToEditForBlock = (block, attendances, date) => {
  const endTimestamp = `${moment(date).add(1, 'day').format('YYYY-MM-DD')} 00:00:00`;

  const possibleAttendances = attendances.filter(attendance => {
    const attendanceStart = attendance.start_timestamp;
    let attendanceEnd = attendance.end_timestamp;
    if (!attendanceEnd) attendanceEnd = endTimestamp;
    if (block.type.name === 'present') {
      if (
        (block.startTimestamp >= attendanceStart && block.startTimestamp < attendanceEnd) ||
        (block.endTimestamp > attendanceStart && block.endTimestamp <= attendanceEnd)
      ) {
        return true;
      }
    } else if (
      (block.startTimestamp >= attendanceStart && block.startTimestamp <= attendanceEnd) ||
      (block.endTimestamp >= attendanceStart && block.endTimestamp <= attendanceEnd)
    ) {
      return true;
    }

    return false;
  });
  if (possibleAttendances.length > 1) console.warn('There should never be more than one attendance matching block');
  return possibleAttendances[0];
};

export const checkIfEndHourIsAfterAttendancesStart = (endHour, endAttendances) => {
  if (!endAttendances) return false;
  if (!endHour) return true;
  const latestHour = endAttendances.reduce(
    (acc, att) => (att.hours?.split('-')[0] > acc ? att.hours.split('-')[0] : acc),
    '',
  );
  return endHour > latestHour;
};

export const getBreaksSumFromBlock = breaks => {
  if (!breaks) {
    return '-';
  }
  const sum = breaks.reduce((acc, cur) => {
    const diff = moment(cur.end_timestamp).diff(cur.start_timestamp, 'minutes');
    return acc + diff;
  }, 0);

  return parseMinutesToHumanForm(sum);
};

export const formatAttendancesToAlign = (dates, startHour, endHour, attendances) => {
  const result = [];
  dates.forEach(date => {
    let objects = [];
    const relevantStartAttendances = attendances.startAttendances.filter(attendance => attendance.date === date);
    const relevantEndAttendances = attendances.endAttendances.filter(attendance => attendance.date === date);
    const startTime = moment(`${date} ${startHour}`);
    if (startHour.length > 0) {
      objects = [
        ...objects,
        {
          type: 'start',
          time: startTime.format('YYYY-MM-DD HH:mm:ss'),
          attendances_ids: relevantStartAttendances.map(attendance => parseInt(attendance.id)),
        },
      ];
    }

    if (endHour.length > 0) {
      const endDateTime = moment(`${date} ${endHour}`);

      const endTime = startTime.isAfter(endDateTime, 'minutes') ? endDateTime.add(1, 'day') : endDateTime;
      objects = [
        ...objects,
        {
          type: 'end',
          time: endTime.format('YYYY-MM-DD HH:mm:ss'),
          attendances_ids: relevantEndAttendances.map(attendance => parseInt(attendance.id)),
        },
      ];
    }

    result.push(objects);
  });

  return result;
};

export const groupSelectedAttendances = (relevantAttendances, selectedAttendances) =>
  Object.keys(relevantAttendances).reduce((acc, cur) => {
    const attendances = relevantAttendances[cur];
    return {
      ...acc,
      [cur]: attendances.filter(att =>
        selectedAttendances.some(
          ({ locationId, employeeId }) => att.employee_id === employeeId && att.location.id === locationId,
        ),
      ),
    };
  }, []);

export const getStartAttendanceColor = (attendanceTime, shiftTime, earlyIn = false) => {
  if (!shiftTime) return KADRO_ORANGE_COLOR;
  if (earlyIn) return ATTENDANCE_ACCEPTED_OVERTIME_COLOR;
  if (shiftTime < attendanceTime) return ATTENDANCE_LATE_COLOR;
  if (shiftTime > attendanceTime) return KADRO_BLUE_COLOR;
  return ATTENDANCE_SUCCESS_COLOR;
};

export const getEndAttendanceColor = (attendanceTime, shiftTime, lateOut = false) => {
  if (!shiftTime) return KADRO_ORANGE_COLOR;
  if (lateOut) return ATTENDANCE_ACCEPTED_OVERTIME_COLOR;
  if (shiftTime > attendanceTime) return ATTENDANCE_LATE_COLOR;
  if (shiftTime < attendanceTime) return KADRO_BLUE_COLOR;
  return ATTENDANCE_SUCCESS_COLOR;
};

export const isAttendanceEditDisabled = (userPermissions, currentUser, employee, companyRoles) => {
  if (!userPermissions || !currentUser || !employee || !companyRoles) return false;

  const currentUserRank = getEmployeeRank(currentUser.user, companyRoles);
  const isEditOwnAttendancesDisabled =
    userPermissions.restrictions.includes(MANAGER_EDIT_OWN_ATTENDANCES) && currentUser.user.id === employee.id;
  const isEditOtherAttendancesDisabled =
    userPermissions.restrictions.includes(ATTENDANCE_EDIT_OTHERS_DISABLE) && currentUser.user.id !== employee.id;
  const employeeRank = getEmployeeRank(employee, companyRoles);
  const isEditHigherOrEqualRankDisabled =
    employee.id !== currentUser.user.id &&
    userPermissions.restrictions.includes(ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE) &&
    employeeRank >= currentUserRank;
  return isEditOwnAttendancesDisabled || isEditOtherAttendancesDisabled || isEditHigherOrEqualRankDisabled;
};

export const getAttendancesFromShifts = shiftsWithoutAttendancesData =>
  shiftsWithoutAttendancesData.map(({ detail, employee, locationId }) => ({
    location_id: locationId,
    employee_id: employee.id,
    start_timestamp: detail.startTimestamp,
    end_timestamp: detail.endTimestamp,
    draft: false,
    breaks: [],
  }));
export const checkIfAttendancesOverlap = (attendances, startTimestamp, endTimestamp) =>
  attendances.some(
    ({ start_timestamp: startAttendance, end_timestamp: endAttendance }) =>
      (startAttendance < endTimestamp && endAttendance > startTimestamp) ||
      (startAttendance === startTimestamp && endAttendance === endTimestamp),
  );
