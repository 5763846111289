import { CellContext } from '@tanstack/react-table';
import { memo } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon';
import { TableRow } from '@/components/common/MDTable/MDTable.types';

import { BudgetMetricRow } from '../../BudgetMetricsTable.types';

import './BudgetMetricIconCell.scss';

type Props = CellContext<TableRow<BudgetMetricRow>, unknown>;

export const BudgetMetricIconCell = memo((props: Props) => {
  const { icon } = props.cell.row.original;

  return <Icon className="k-budgetMetricsTableIconCell" type="material" name={icon} />;
});

BudgetMetricIconCell.displayName = 'BudgetMetricIconCell';
