import { SortingFn } from '@tanstack/react-table';

import { getPayrollStatus } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTable.helpers';
import { PayloadStatus } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTable.types';
import { Label } from '@/types/labels.types';
import { mapName } from '@/utils/array/array.helpers';
import { compareNumbers, stringToFloat } from '@/utils/numberHelpers';
import { compareLocaleStringIgnoreCase } from '@/utils/stringHelpers';

import { SortingFnEnum } from '../MDTable.types';

const PAYROLL_LOCATION_STATUSES_ORDER = ['unapproved', 'pending', 'approved'];

const sortNumbers: SortingFn<unknown> = (rowA, rowB, columnId) => {
  const valueA = stringToFloat(rowA.original[columnId]);
  const valueB = stringToFloat(rowB.original[columnId]);
  return compareNumbers(valueA, valueB);
};

const sortLabels: SortingFn<unknown> = (rowA, rowB, columnId) => {
  const getLabels = (labels: Label[]) => labels.map(({ label }) => label).join(',');
  const valueA = getLabels(rowA.original[columnId] || []);
  const valueB = getLabels(rowB.original[columnId] || []);
  return compareLocaleStringIgnoreCase(valueA, valueB);
};

const sortLocaleIgnoreCase: SortingFn<unknown> = (rowA, rowB, columnId) => {
  const valueA = rowA.original[columnId];
  const valueB = rowB.original[columnId];
  return compareLocaleStringIgnoreCase(valueA, valueB);
};

const sortLocations: SortingFn<unknown> = (rowA, rowB, columnId) => {
  const getNames = (locations: { name: string }[]) => mapName(locations).join(',');
  const valueA = getNames(rowA.original[columnId]);
  const valueB = getNames(rowB.original[columnId]);
  return compareLocaleStringIgnoreCase(valueA, valueB);
};

const sortPayrollLocationStatus: SortingFn<unknown> = (rowA, rowB, columnId) => {
  const aOrder = PAYROLL_LOCATION_STATUSES_ORDER.indexOf(rowA.original[columnId]);
  const bOrder = PAYROLL_LOCATION_STATUSES_ORDER.indexOf(rowB.original[columnId]);
  return compareNumbers(aOrder, bOrder);
};

const sortPayrollStatus: SortingFn<unknown> = (rowA, rowB) => {
  const getStatus = original => Object.values(PayloadStatus).indexOf(getPayrollStatus(original.isUnapproved));
  const aOrder = getStatus(rowA.original);
  const bOrder = getStatus(rowB.original);
  return compareNumbers(aOrder, bOrder);
};

export const sortingFns: Record<string, SortingFn<unknown>> = {
  [SortingFnEnum.CURRENCY]: sortNumbers,
  [SortingFnEnum.LABELS]: sortLabels,
  [SortingFnEnum.LOCALE_IGNORE_CASE]: sortLocaleIgnoreCase,
  [SortingFnEnum.LOCATIONS]: sortLocations,
  [SortingFnEnum.NUMBER]: sortNumbers,
  [SortingFnEnum.PAYROLL_LOCATION_STATUS]: sortPayrollLocationStatus,
  [SortingFnEnum.PAYROLL_STATUS]: sortPayrollStatus,
};
