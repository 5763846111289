import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import Chceckbox from '@/components/common/inputs/Checkbox.jsx';

import './RolesTableRowCheckboxes.scss';

const RolesTableRowCheckboxes = props => {
  const { roles, grouppedPermissions, groupId, checkedPermissions, checkPermissions, uncheckPermissions } = props;

  return roles.map(role => {
    const avaPermissions = grouppedPermissions.filter(
      perm => perm.for_employee === !role.is_manager || perm.for_manager === role.is_manager,
    );
    const checkedPermissionsForRoleAndGroup =
      checkedPermissions[role.id].filter(perm => perm.groupId === groupId) || [];
    const isAllChecked = checkedPermissionsForRoleAndGroup.length >= avaPermissions.length;
    const isSomethingChecked = checkedPermissionsForRoleAndGroup.length > 0;
    const disabled = role.id === 'manager' || role.id === 'employee' || avaPermissions.length === 0 || !role.canEdit;
    const checkboxClass = classnames('k-rolesTableRowCheckboxes__checkbox', {
      'k-rolesTableRowCheckboxes__checkbox--notAllChecked': !isAllChecked,
      'k-rolesTableRowCheckboxes__disabledCheckedCheckbox': disabled && isAllChecked && isSomethingChecked,
      'k-rolesTableRowCheckboxes__disabledCheckedCheckbox--notAllChecked':
        disabled && !isAllChecked && isSomethingChecked,
    });
    return (
      <td className="k-rolesTableRowCheckboxes k-colname day" key={role.id}>
        <div className="k-rolesTableRowCheckboxes__checkboxContainer">
          <Chceckbox
            className={checkboxClass}
            checked={isSomethingChecked}
            disabled={disabled}
            toggle={() =>
              isSomethingChecked ? uncheckPermissions(role.id, groupId) : checkPermissions(role.id, avaPermissions)
            }
          />
        </div>
      </td>
    );
  });
};

RolesTableRowCheckboxes.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({})),
  grouppedPermissions: PropTypes.arrayOf(PropTypes.shape({})),
  groupId: PropTypes.string,
  checkedPermissions: PropTypes.shape({}),
  checkPermissions: PropTypes.func,
  uncheckPermissions: PropTypes.func,
};

export default RolesTableRowCheckboxes;
