import { CellContext } from '@tanstack/react-table';
import { memo } from 'react';

import { TableRow } from '@/components/common/MDTable/MDTable.types';

import { BudgetMetricRow } from '../../BudgetMetricsTable.types';

import './BudgetMetricNameCell.scss';

type Props = CellContext<TableRow<BudgetMetricRow>, unknown>;

export const BudgetMetricNameCell = memo((props: Props) => {
  const { color, name } = props.cell.row.original;

  return (
    <div className="k-budgetMetricsTableNameCell">
      <span className="k-budgetMetricsTableNameCell__circle" style={{ backgroundColor: color }} />
      <span>{name}</span>
    </div>
  );
});

BudgetMetricNameCell.displayName = 'BudgetMetricNameCell';
