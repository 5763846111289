import { UserEmployeesStoreState } from '@/redux-store/userEmployees';
import { UserLocationsStoreState } from '@/redux-store/userLocations';
import { UserLocation } from '@/types';

import { mapId } from '../array/array.helpers';

export const getLocationsFromEmployeesWithCommonLocations = (
  userEmployees: UserEmployeesStoreState,
  userLocations: UserLocationsStoreState,
): UserLocation[] => {
  const filteredLocations: UserLocation[] = [];
  const userLocationIds = mapId(userLocations);
  userEmployees.forEach(employee => {
    const hasAccessToEmployeesAttendances = employee.locations.some(({ id }) => userLocationIds.includes(id));
    if (hasAccessToEmployeesAttendances)
      employee.locations.forEach(location => {
        if (!filteredLocations.some(({ id }) => id === location.id)) filteredLocations.push(location);
      });
  });
  return filteredLocations;
};
