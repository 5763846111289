import classNames from 'classnames';
import { useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { StandardDate } from '@/types/dates.types.ts';
import { checkIsOnlyMonthMode } from '@/utils/dates/dates.helpers';
import { mapModifiers, Modifiers } from '@/utils/styles';

import Popover from '../../Popover/Popover.jsx';
import { CalendarPopoverContent } from './Content/Content';

const messages = defineMessages({
  placeholder: {
    id: 'common.calendarPopoverInput.placeholder',
    defaultMessage: 'Kliknij by zaznaczyć okres',
  },
});

const popoverStyle: React.CSSProperties = {
  padding: 0,
  border: 'none',
  marginTop: '-10px',
};

export type KadroCalendarPopoverInputProps = {
  inputValue: string;
  position: string;
  selectedRange?: { start: StandardDate; end: StandardDate };
  onInputClick: () => void;
  onRangeChange?: (e: { start: StandardDate; end: StandardDate }) => void;
  modifiers?: Modifiers;
  singleDay?: boolean;
  customDate?: string;
  changeDate?: (date: string) => void;
  label: string;
  inputEnabled?: boolean;
  hidePicker?: boolean;
  mainDateStore: { from: StandardDate; to: StandardDate };
  modes?: number[];
  minDate?: Date;
  maxDate?: Date;
};

const KadroCalendarPopoverInput = ({
  onRangeChange,
  changeDate,
  inputValue,
  position,
  selectedRange,
  onInputClick,
  modifiers,
  singleDay,
  customDate,
  label,
  inputEnabled,
  hidePicker = true,
  mainDateStore,
  modes,
  minDate,
  maxDate,
}: KadroCalendarPopoverInputProps) => {
  const intl = useIntl();
  const popoverRef = useRef(null);
  const className = classNames('k-calendarPopoverInput', ...mapModifiers(modifiers, 'k-calendarPopoverInput'));
  const inputClassName = classNames('k-calendarPopoverInput__input form-control k-textInput', {
    'k-textInput--disabled': !inputEnabled,
  });
  const isOnlyMonthMode = useMemo(() => checkIsOnlyMonthMode(modes), [modes]);

  const styles = useMemo(() => (isOnlyMonthMode ? popoverStyle : {}), [isOnlyMonthMode]);

  return (
    <div onClick={onInputClick} className={className} role="presentation">
      <Popover
        noArrow={isOnlyMonthMode}
        ref={popoverRef}
        position={position}
        popoverStyle={styles}
        sideOffset={550}
        content={
          <CalendarPopoverContent
            {...{
              singleDay,
              customDate,
              popoverRef,
              onRangeChange,
              changeDate,
              selectedRange,
              hidePicker,
              mainDateStore,
              modes,
              minDate,
              maxDate,
            }}
          />
        }
      >
        <div>
          {label && (
            <label className="k-calendarPopoverInput__label" htmlFor="inputCalendar">
              {label}
            </label>
          )}
          <input
            className={inputClassName}
            id="inputCalendar"
            placeholder={intl.formatMessage(messages.placeholder)}
            value={inputValue}
            readOnly={!inputEnabled}
          />
        </div>
      </Popover>
    </div>
  );
};

export default KadroCalendarPopoverInput;
