import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { useAppSelector } from '@/redux-store';
import { selectEmployeeOrLoanedEmployeeById } from '@/redux-store/employees';
import { selectIsQuickPlanningEnabled } from '@/redux-store/schedule/quickPlanning';
import { selectShiftForEmployeeByShiftId } from '@/redux-store/shifts';
import { EmployeeWhole, UserJobTitle } from '@/types';
import { Shift } from '@/types/shifts.types';
import { getShiftPopover } from '@/utils/shiftHelpers';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers';

type Props = {
  children: React.ReactNode;
  isFromOtherLocation: boolean;
  employeeId: EmployeeWhole['id'];
  isJobTitleNotAssigned: boolean;
  shiftId: Shift['id'];
  shiftJobTitle?: UserJobTitle;
  shiftLocationName?: string;
  withoutPopover?: boolean;
};

export const ScheduleShiftPopup = (props: Props) => {
  const {
    shiftId,
    employeeId,
    isFromOtherLocation,
    isJobTitleNotAssigned,
    children,
    shiftJobTitle,
    shiftLocationName,
    withoutPopover,
  } = props;
  const intl = useIntl();
  const isQuickPlanningEnabled = useAppSelector(selectIsQuickPlanningEnabled);
  const relevantEmployee = useAppSelector(selectEmployeeOrLoanedEmployeeById(employeeId));
  const shift = useAppSelector(selectShiftForEmployeeByShiftId(employeeId, shiftId));
  const { comment, date, isLoaned, isOpenShiftFromLocationGroups } = shift || {};

  const employeeName = useMemo(
    () =>
      relevantEmployee
        ? getEmployeeNameShort({ first_name: relevantEmployee.first_name, last_name: relevantEmployee.last_name }, 17)
        : '',
    [relevantEmployee],
  );

  const popover = getShiftPopover(
    employeeName,
    date,
    comment,
    shiftJobTitle,
    isJobTitleNotAssigned,
    isFromOtherLocation,
    shiftLocationName,
    intl,
    isLoaned,
    isOpenShiftFromLocationGroups,
  );

  if (popover && !withoutPopover && !isQuickPlanningEnabled)
    return (
      <HoverPopOver popover={{ ...popover, content: popover.content.map((info, i) => <div key={i}>{info}</div>) }}>
        <div>{children}</div>
      </HoverPopOver>
    );

  return children;
};
