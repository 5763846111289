import classNames from 'classnames';
import { memo, useMemo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectScheduleAbsencesByEmployeeId } from '@/redux-store/absences';
import { selectEmployeeOrLoanedEmployeeById } from '@/redux-store/employees';
import { selectFreeDay } from '@/redux-store/freeDaysMarking';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { selectOvertimeCollectionsByEmployeeId } from '@/redux-store/overtimeCollections';
import { selectIsScheduleViewMode } from '@/redux-store/schedule/viewMode';
import { selectAreShiftsFromOtherLocationsShown } from '@/redux-store/schedule/viewSettings';
import { selectShiftsForEmployee } from '@/redux-store/shifts';
import { EmployeeWhole, UserLocation } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { checkIsMonthlyScheduleView, checkIsWeekScheduleView } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import AvaOverlay from '../../AvaMode/AvaOverlay/AvaOverlay.redux';
import { calculateAbsenceConnectorLength } from '../../ScheduleAbsenceBlock/ScheduleAbsenceBlock.helpers';
import { ScheduleFreeDay } from '../../ScheduleFreeDay/ScheduleFreeDay';
import { GroupNodeType } from '../../ScheduleTableBody/GroupedEmployees/GroupedEmployees.types';
import { ScheduleTableItem } from '../../ScheduleTableItem/ScheduleTableItem';
import { getPlaceholderPadding, getShiftsForDate } from '../ScheduleTableRow.utils';
import { AbsencesBlocks, OvertimeCollectionsBlocks, ShiftsBlocks } from './blocks';
import {
  checkHasAbsence,
  getAbsencesForDate,
  getLastConnectingAbsenceShiftBlockIndex,
  getOvertimeCollectionsForDate,
  getRelevantAbsences,
  getUnfilteredAbsencesForDate,
} from './ScheduleTableDate.utils';

type Props = {
  date: StandardDate;
  employeeId: EmployeeWhole['id'];
  groupNodeId: Nullable<GroupNodeType['id']>;
  index: number;
  locationId: UserLocation['id'];
};

export const ScheduleTableDate = memo(({ date, employeeId, groupNodeId, index, locationId }: Props) => {
  const showShiftsFromOtherLocation = useAppSelector(selectAreShiftsFromOtherLocationsShown);
  const employeeShifts = useAppSelector(selectShiftsForEmployee(employeeId));
  const relevantEmployee = useAppSelector(selectEmployeeOrLoanedEmployeeById(employeeId));
  const dateArray = useAppSelector(selectDateArray);
  const employeeAbsences = useAppSelector(selectScheduleAbsencesByEmployeeId(employeeId));
  const employeeOvertimeCollections = useAppSelector(selectOvertimeCollectionsByEmployeeId(employeeId));
  const freeDayForEmployee = useAppSelector(selectFreeDay(employeeId, date));
  const isScheduleModeView = useAppSelector(selectIsScheduleViewMode);
  const isMonthView = useMemo(() => checkIsMonthlyScheduleView(dateArray), [dateArray]);
  const isWeekView = useMemo(() => checkIsWeekScheduleView(dateArray), [dateArray]);
  const overtimeCollectionsForDate = useMemo(
    () => getOvertimeCollectionsForDate(employeeOvertimeCollections, date),
    [employeeOvertimeCollections, date],
  );
  const relevantEmployeeAbsences = useMemo(() => getRelevantAbsences(employeeAbsences), [employeeAbsences]);
  const absencesForDate = useMemo(
    () => getAbsencesForDate(relevantEmployeeAbsences, date, index),
    [relevantEmployeeAbsences, date, index],
  );
  const unfilteredAbsencesForDate = useMemo(
    () => getUnfilteredAbsencesForDate(relevantEmployeeAbsences, date),
    [relevantEmployeeAbsences, date],
  );
  const hasAbsence = useMemo(() => checkHasAbsence(relevantEmployeeAbsences, date), [relevantEmployeeAbsences, date]);
  const hasAbsenceStartingToday = relevantEmployeeAbsences.some(absence => absence.from === date);
  const needsAbsencePadding = hasAbsence && !hasAbsenceStartingToday && index !== 0;
  const isAbsenceInDay = hasAbsence || hasAbsenceStartingToday;
  const isOvertimeInDay = !isEmptyArray(overtimeCollectionsForDate);
  const isAbsenceDraft = unfilteredAbsencesForDate[0]?.status === 'draft';
  const absenceConnectorClassNames = classNames('k-absenceConnector', {
    'k-absenceConnector--month': isMonthView,
    'k-absenceConnector--week': isWeekView,
    'k-absenceConnector--draft': isAbsenceDraft,
  });
  const paddingToAdd = getPlaceholderPadding(isAbsenceInDay, isOvertimeInDay, Boolean(freeDayForEmployee), isMonthView);
  const { visible: shiftsForDate } = useMemo(
    () => getShiftsForDate(relevantEmployee, showShiftsFromOtherLocation, employeeShifts, locationId, date),
    [locationId, date, relevantEmployee, showShiftsFromOtherLocation, employeeShifts],
  );
  const hasAbsencesAndOvertimeCollectionsInDay =
    !isEmptyArray(unfilteredAbsencesForDate) && !isEmptyArray(overtimeCollectionsForDate);
  const lastConnectingAbsenceShiftBlockIndex = useMemo(
    () =>
      getLastConnectingAbsenceShiftBlockIndex(
        unfilteredAbsencesForDate,
        overtimeCollectionsForDate,
        hasAbsencesAndOvertimeCollectionsInDay,
        shiftsForDate,
      ),
    [unfilteredAbsencesForDate, overtimeCollectionsForDate, hasAbsencesAndOvertimeCollectionsInDay, shiftsForDate],
  );

  const connectorLength = useMemo(
    () =>
      lastConnectingAbsenceShiftBlockIndex
        ? calculateAbsenceConnectorLength(lastConnectingAbsenceShiftBlockIndex, isWeekView, isMonthView)
        : 0,
    [lastConnectingAbsenceShiftBlockIndex, isWeekView, isMonthView],
  );
  const absenceConnectorStyle = useMemo(() => ({ height: connectorLength }), [connectorLength]);

  return (
    <ScheduleTableItem
      key={`${employeeId}-${date}`}
      date={date}
      locationId={locationId}
      employeeId={employeeId}
      paddingToAdd={paddingToAdd}
      isEmpty={isEmptyArray(shiftsForDate)}
      groupNodeId={groupNodeId}
    >
      <AvaOverlay employeeId={employeeId} date={date} isMonthView={isMonthView} />
      {needsAbsencePadding ? <div className="k-absencePlaceholder" /> : null}
      {lastConnectingAbsenceShiftBlockIndex > 0 && (
        <div className={absenceConnectorClassNames} style={absenceConnectorStyle} />
      )}
      {isScheduleModeView && <AbsencesBlocks absencesForDate={absencesForDate} date={date} />}
      {isScheduleModeView && <OvertimeCollectionsBlocks overtimeCollections={overtimeCollectionsForDate} />}
      {isScheduleModeView && (
        <ShiftsBlocks
          connectorLength={connectorLength}
          employeeId={employeeId}
          groupNodeId={groupNodeId}
          locationId={locationId}
          shiftsForDate={shiftsForDate}
          unfilteredAbsencesForDate={unfilteredAbsencesForDate}
        />
      )}
      {isScheduleModeView && <ScheduleFreeDay {...{ date, employeeId }} />}
      {(isAbsenceInDay || isOvertimeInDay) && Boolean(freeDayForEmployee) && isEmptyArray(shiftsForDate) && (
        <div className="k-placeholder" />
      )}
    </ScheduleTableItem>
  );
});

ScheduleTableDate.displayName = 'ScheduleTableDate';
