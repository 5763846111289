import { combineReducers } from 'redux';

import { attendancesUiStateReducer } from '@/redux-store/attendances/uiState';

 import attendancesData from './attendancesData.ts';
import displayMode from './displayMode';
import selected from './selected';

export default combineReducers({
  displayMode,
  selected,
  attendancesData,
  uiState: attendancesUiStateReducer,
});
