import { LocationGroup } from '@/redux-store/locationGroups/locationGroups.types.ts';

export const mergeLocationGroupsWithLocationIds = (locationGroups: LocationGroup[], locationIds: string[]) => {
  const idsSet = new Set(locationIds);
  for (const locationGroup of locationGroups) {
    for (const locationId of locationGroup.locationIds) {
      idsSet.add(locationId);
    }
  }
  return Array.from(idsSet);
};
