import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import KeyboardButtonWithDescription from '@/components/common/KeyboardButton/KeyboardButtonWithDescription.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { splitInto2EqualParts } from '@/utils/arrayHelpers.js';

import { getKeyboardShortcuts } from './ShortcutsModal.helpers';

import './ShortcutsModal.scss';

const messages = defineMessages({
  title: {
    id: 'shortcutsModal.title',
    defaultMessage: 'Skróty klawiszowe',
  },
  more: {
    id: 'shortcutsModal.more',
    defaultMessage: 'Dowiedz się więcej',
  },
});

const ShortcutsModal = ({ show, hideModal }, { intl }) => {
  const shortcutButtons = getKeyboardShortcuts(intl);
  const [firstColumn, secondColumn] = splitInto2EqualParts(shortcutButtons);

  return (
    <MDKadroModal
      modifiers={['wide']}
      show={show}
      title={intl.formatMessage(messages.title)}
      onHide={hideModal}
      hideConfirmButton
      hideCancelButton
    >
      <div className="shortcutModal__buttons">
        <div>
          {firstColumn.map(keyboardShortcut => (
            <KeyboardButtonWithDescription key={keyboardShortcut.text} {...keyboardShortcut} />
          ))}
        </div>
        <div>
          {secondColumn.map(keyboardShortcut => (
            <KeyboardButtonWithDescription key={keyboardShortcut.text} {...keyboardShortcut} />
          ))}
        </div>
      </div>
      <div className="shortcutModal__link">
        <a
          href="https://pomoc.kadromierz.pl/pl/articles/5826946-skroty-klawiszowe-w-sekcji-grafik-pracy"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage {...messages.more} />
          <Icon name="east" />
        </a>
      </div>
    </MDKadroModal>
  );
};

ShortcutsModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ShortcutsModal.propTypes = {
  hideModal: PropTypes.func,
  show: PropTypes.bool,
};

export default ShortcutsModal;
