import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { Children, cloneElement, Component } from 'react';
import { Portal } from 'react-portal';

import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import { calculatePopoverPosition, getArrowPosition } from './popoverHelpers.js';

// Check README for examples and props desc.

const allowedPositions = ['top', 'bottom', 'left', 'right'];
const arrowPositions = ['right', 'left'];
const MOUSE_X_OFFSET = 9;

class Popover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      childId: null,
      isPopoverHovered: false,
    };
    this.arrowSize = this.props.arrowSize || 10;
    this.showPopover = this.props.showPopover;
    bindPrototypeFunctions(this);
  }

  componentDidMount() {
    if (this.child) {
      if (this.props.showOnHover) {
        this.child.addEventListener('mouseenter', this.handleChildMouseEnterEvent);
        this.child.addEventListener('mouseleave', this.handleMouseLeaveHoverOnPopover);
      }
      this.child.addEventListener('click', this.onClick);
      if (this.props.addUpdateListeners) {
        this.observer = new IntersectionObserver(([entry]) => {
          if (!entry.isIntersecting) this.hide();
        });
        this.observer.observe(this.child);
      }
    }

    if (this.showPopover) {
      this.show();
    }

    if (this.props.hideOnBlur) window.addEventListener('click', this.hideOnBlur);

    if (this.props.addUpdateListeners) {
      window.addEventListener('scroll', this.handleScrollAndResize, { capture: true });
      window.addEventListener('resize', this.handleScrollAndResize, { capture: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isControlled) {
      return;
    }

    if (prevProps.isVisible !== this.props.isVisible) {
      if (this.props.isVisible) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  componentWillUnmount() {
    if (this.child) {
      if (this.props.showOnHover) {
        this.child.removeEventListener('mouseenter', this.show);
        this.child.removeEventListener('mouseleave', this.hide);
        this.popover?.removeEventListener('mouseleave', this.hide);
      }
      this.child.removeEventListener('click', this.onClick);
      if (this.props.addUpdateListeners) this.observer.disconnect();
    }

    if (this.props.hideOnBlur) window.removeEventListener('click', this.hideOnBlur);
    if (this.props.addUpdateListeners) {
      window.removeEventListener('scroll', this.handleScrollAndResize, { capture: true });
      window.removeEventListener('resize', this.handleScrollAndResize, { capture: true });
    }
  }

  onClick(e) {
    if (this.props.disabled) {
      return;
    }
    const hideOnChildClick = this.props.hideOnChildClick && this.state.display && this.child?.contains(e.target);
    if (this.props.hideOnClick) {
      this.hide();
      return;
    }
    if (hideOnChildClick) {
      this.hide();
      return;
    }
    this.show(e);
  }

  hideOnBlur(e) {
    if (this.state.display) {
      const hideDropdown =
        !this.popover?.contains(e.target) &&
        !this.child?.contains(e.target) &&
        e.target.getBoundingClientRect().width !== 0 &&
        this.props.hideOnBlur;
      if (hideDropdown) {
        if (this.props.onBlur) this.props.onBlur();
        this.hide();
      }
    }
  }

  clearHideTimeout() {
    clearTimeout(this.closeTimeout);
  }

  show() {
    this.display();
    if (this.props.showOnHover) {
      this.popover?.addEventListener('mouseleave', this.hide);
      this.popover?.addEventListener('mouseenter', this.clearHideTimeout);
    }

    if (this.props.onShow) this.props.onShow();
    if (this.props.updatePosition) {
      this.oldWidth = 0;
      this.updateInterval = setInterval(() => {
        if (!this.popover) return;
        const width = this.popover.offsetWidth;
        if (width - this.oldWidth > 5) {
          this.display();
          this.oldWidth = width;
        }
      }, 100);
      setTimeout(() => {
        clearInterval(this.updateInterval);
      }, 3000);
    }
  }

  update() {
    if (!this.popover) return;
    const rect = this.child.getBoundingClientRect();
    const { top, left } = calculatePopoverPosition(
      rect,
      this.popover,
      this.child,
      this.props.position,
      this.props.sideOffset,
      this.props.autoSideOffset,
      this.props.xOffset,
      this.props.yOffset,
      this.props.useParentDimensions,
      this.props.align,
    );
    this.popover.style.opacity = 1;
    this.popover.style.top = top;
    this.popover.style.left = left;
    this.popover.style.visibility = 'visible';
  }

  display() {
    if (this.props.setDisplayStatus) this.props.setDisplayStatus(true);
    this.setState({ display: true }, () => {
      setTimeout(() => {
        this.update();
        this.popover?.addEventListener('dragenter', this.hide);
      }, 100);
    });
  }

  hide() {
    this.closeTimeout = setTimeout(() => {
      if (this.props.onHide) this.props.onHide();
      if (this.props.showOnHover && this.popover) {
        this.popover.removeEventListener('mouseleave', this.hide);
        this.popover.removeEventListener('mouseenter', this.clearHideTimeout);
      }
      if (this.props.setArrowStatus) this.props.setArrowStatus(false);
      if (this.popover) this.popover.removeEventListener('dragenter', this.hide);
      this.setState({ display: false });
      if (this.props.setDisplayStatus) this.props.setDisplayStatus(false);
    }, this.props.hideTimeout);
  }

  handleScrollAndResize() {
    if (!this.state.display) return;
    this.display();
  }

  onDropdownClick() {
    if (this.props.hideOnDropDownClick) {
      this.hide();
    }
  }

  handleMouseLeaveHoverOnPopover(e) {
    if (!this.popover || !this.props.preventPopoverHide) this.hide();
    setTimeout(() => {
      const { isPopoverHovered } = this.state;
      if (isPopoverHovered) {
        this.popover?.addEventListener('mouseleave', this.handlePopoverMouseLeave);
      } else {
        this.hide();
      }
    }, 200);
  }

  handleChildMouseEnterEvent() {
    if (this.props.childId) {
      this.setState({ childId: this.props.childId });
    }
    this.show();
  }

  handlePopoverMouseLeave(e) {
    const { childId: childIdFromProps } = this.props;
    const { childId: childIdFromState } = this.state;
    const xMousePosition = e.clientX;
    const yMousePosition = e.clientY + (this.props.popoverExitOffsetOnHover || 0);

    const isChildHovered = document
      .elementsFromPoint(xMousePosition - MOUSE_X_OFFSET, yMousePosition)
      .some(el => this.child?.contains(el));

    if (!isChildHovered || (childIdFromProps && childIdFromState && childIdFromProps !== childIdFromState)) {
      this.hide();
    }
  }

  onPopoverMouseEnter() {
    this.setState({ isPopoverHovered: true });
  }

  onPopoverMouseLeave() {
    this.setState({ isPopoverHovered: false });
  }

  render() {
    const { noPointerEvents, isDark, noArrow, centerOnMobile, arrowPosition, noBorder } = this.props;
    if (!allowedPositions.includes(this.props.position)) {
      throw new Error('Popover position is invalid. Allowed positions are: top, bottom, left, right.');
    }
    if (arrowPosition && !arrowPositions.includes(arrowPosition)) {
      throw new Error('Arrow position is invalid. Allowed positions are: left, right.');
    }
    const arrowClassNames = classnames('kpopover__triangle', this.props.position);
    const popoverClassNames = classnames('kpopover', this.props.className, {
      'kpopover--noPointerEvents': noPointerEvents,
      'kpopover--dark': isDark,
      'kpopover--no-arrow': noArrow,
      'kpopover--center': centerOnMobile,
      'kpopover--no-border': noBorder,
    });

    const a = -1 * this.arrowSize + 1;
    const b = this.props.autoSideOffset
      ? this.popover?.clientHeight / 2 - this.arrowSize / 2
      : this.props.sideOffset - this.arrowSize / 2;
    const arrowHorizontalPosition = getArrowPosition(arrowPosition || null);

    const arrowStyles = {
      bottom: { top: a, ...arrowHorizontalPosition },
      right: { top: b, left: a, right: 'auto' },
      left: { top: b, left: 'auto', right: a },
      top: { bottom: a, ...arrowHorizontalPosition },
    };

    return (
      <div style={this.props.wrapperStyle} className={this.props.wrapperClassName}>
        {Children.only(
          cloneElement(this.props.children, {
            ref: ref => {
              this.child = ref;
              if (this.props.getRef) {
                this.props.getRef(ref);
              }
            },
          }),
        )}
        {this.state.display && (
          <Portal>
            <div>
              {this.props.showShadow ? <div className="kshadow" onClick={this.hide} role="presentation" /> : null}
              <div
                className={popoverClassNames}
                style={{ ...this.props.popoverStyle, opacity: 0 }}
                ref={ref => {
                  this.popover = ref;
                }}
                onMouseEnter={this.onPopoverMouseEnter}
                onMouseLeave={this.onPopoverMouseLeave}
              >
                <div className={arrowClassNames} style={arrowStyles[this.props.position]} />
                <div onClick={this.onDropdownClick}>{this.props.content}</div>
              </div>
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

Popover.defaultProps = {
  wrapperStyle: {},
  popoverStyle: {},
  xOffset: 0,
  yOffset: 0,
  sideOffset: 50,
  position: 'bottom',
  showShadow: true,
  hideOnBlur: false,
  showOnHover: false,
  hideOnClick: false,
  updatePosition: false,
  className: '',
  centerOnMobile: true,
  hideTimeout: 200,
  useParentDimensions: false,
};

Popover.propTypes = {
  wrapperStyle: PropTypes.shape({}), // Style of div wrapping our trigger
  popoverStyle: PropTypes.shape({}), // Style of popover element
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // Content of the popover
  xOffset: PropTypes.number, // Offsets popover position on X axis
  yOffset: PropTypes.number, // Offsets popover position on Y axis
  sideOffset: PropTypes.number, // Offsets arrow position on Y axis when popover is on left or right
  arrowSize: PropTypes.number, // Arrow size in px
  children: PropTypes.node, // Trigger of the popover
  position: PropTypes.string, // Position of the popover (left,right,top,bottom)
  className: PropTypes.string, // Classnames for popover element
  wrapperClassName: PropTypes.string, // Classnames for wrapper element
  showShadow: PropTypes.bool, // Should show shadow
  hideOnBlur: PropTypes.bool, // Hides popover when clicking ouside of it (use this if there is no shadow)
  showOnHover: PropTypes.bool, // Shows popover when mouse is hovering over trigger
  hideOnClick: PropTypes.bool, // Use with showOnHover if u want to hide it when it's clicked
  updatePosition: PropTypes.bool, // It will check for change in width of popover for 3s and update if so
  // Specially usefull for position right, since in this case by default it's not using parent width
  useParentDimensions: PropTypes.bool,
  autoSideOffset: PropTypes.bool,
  centerOnMobile: PropTypes.bool,
  isDark: PropTypes.bool,
  noArrow: PropTypes.bool,
  noPointerEvents: PropTypes.bool,
  hideTimeout: PropTypes.number,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  showPopover: PropTypes.bool,
  setArrowStatus: PropTypes.func,
  getRef: PropTypes.func,
  addUpdateListeners: PropTypes.bool,
  hideOnChildClick: PropTypes.bool,
  noBorder: PropTypes.bool,
  arrowPosition: PropTypes.string,
  align: PropTypes.string,
  preventPopoverHide: PropTypes.bool,
  childId: PropTypes.string,
  setDisplayStatus: PropTypes.func,
  popoverExitOffsetOnHover: PropTypes.number,
  childExitOffsetOnHover: PropTypes.number,
};

export default Popover;
