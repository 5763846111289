import classnames from 'classnames';
import { forwardRef, MouseEvent, useCallback, useEffect, useRef } from 'react';

import { useMediaQuery } from '@/hooks';

import { CheckboxCellProps } from './CheckboxCell.types';

import './MDTableCheckbox.scss';

const MDTableCheckbox = (
  { indeterminate, checked, onChange, onCustomChange, disabled, isHeader }: CheckboxCellProps,
  ref,
) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  const { isAtLeastTabletLandscape } = useMediaQuery();

  useEffect(() => {
    if (resolvedRef.current) resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const onClick = useCallback(
    (e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
      if (onCustomChange) return onCustomChange();
      onChange && onChange({ ...e, target: { ...e.target, checked: !checked } });
    },
    [checked, onChange, onCustomChange],
  );

  if (!isAtLeastTabletLandscape && isHeader) return null;

  return (
    <span
      className={classnames('mdTableCheckbox', 'material-icons', {
        'mdTableCheckbox--disabled': disabled,
        'mdTableCheckbox--mobile': !isAtLeastTabletLandscape,
      })}
      role="button"
      ref={resolvedRef}
      tabIndex={0}
      onClick={onClick}
    >
      {checked ? 'done' : 'x'}
    </span>
  );
};

const TableCheckboxWithRef = forwardRef(MDTableCheckbox);
TableCheckboxWithRef.displayName = 'TableCheckboxWithRef';

export default TableCheckboxWithRef;
