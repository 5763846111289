import { flexRender, Table } from '@tanstack/react-table';
import classNames from 'classnames';

import { ColumnsWidths, MDTableProps, TableRow } from '../MDTable.types';
import { getCommonPinningStyles } from '../TableUtils';

import './TableHead.scss';

type Props<T extends object> = {
  columnsWidths?: ColumnsWidths;
  isOverflowX: boolean;
  table: Table<TableRow<T>>;
  tableStyles: MDTableProps<T>['tableStyles'];
};

export const TableHead = <T extends object>({ columnsWidths, table, tableStyles, isOverflowX }: Props<T>) => (
  <thead className="mdTableThead">
    {table.getHeaderGroups().map(headerGroup => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map(header => (
          <th
            key={header.id}
            colSpan={header.colSpan}
            className={classNames('mdTableThead__cell', {
              'mdTableThead__cell--wrap': tableStyles.hasHeaderTextWrapping,
            })}
            style={getCommonPinningStyles(header.column, isOverflowX, columnsWidths)}
          >
            <div
              className={classNames('mdTableThead__cellContent', {
                'mdTableThead__cellContent--sortable': header.column.getCanSort(),
                'mdTableThead__cellContent--center': header.column.columnDef.centerColumn,
              })}
              role="presentation"
              onClick={header.column.getToggleSortingHandler()}
            >
              <div className="mdTableThead__cellContent--left">
                {flexRender(header.column.columnDef.header, header.getContext())}
              </div>
              <div className="mdTableThead__cellContent--right">
                {{
                  asc: <i className="material-icons">arrow_drop_up</i>,
                  desc: <i className="material-icons">arrow_drop_down</i>,
                }[header.column.getIsSorted() as string] ?? null}
              </div>
            </div>
          </th>
        ))}
      </tr>
    ))}
  </thead>
);
