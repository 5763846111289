import { connect } from 'react-redux';

import { changePayrollDaysStatusForMultipleEmployees } from '@/actions/payroll/payroll';
import { getPayrollLocationViewData } from '@/actions/payrollLocation/payrollLocation';
import PayrollLocationButtonBar from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationTable/PayrollLocationButtonBar/PayrollLocationButtonBar';

const mapStateToProps = ({ reducer }) => ({
  isLoadButtonEnabled: reducer.payrollLocation.isLoadButtonEnabled,
  payrollApprovalEnabled: reducer.currentCompany.settings.payroll_approval_enabled,
  isFetching: reducer.payrollLocation.isFetching,
  selectedLocations: reducer.multipleLocationFilter,
  selectedJobTitles: reducer.jobtitleFilter.selectedJobtitles,
  selectedEmploymentConditions: reducer.employmentConditionsFilter.selected,
});

const mapDispatchToProps = {
  getPayrollLocationViewData,
  changePayrollDaysStatusForMultipleEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationButtonBar);
