import classnames from 'classnames';
import { useState } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon';
import KebabMenuWrapper from '@/components/common/Basic/KebabMenu/KebabMenuWrapper/KebabMenuWrapper';
import DropDownMenu, { DropDownOption } from '@/components/common/DropdownMenu/DropdownMenu';
import Popover from '@/components/common/Popover/Popover';

import './kebabMenu.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  title?: string;
  options: DropDownOption[];
};

export const KebabMenu = ({ options, title, disabled, className }: Props) => {
  const [dropdownStatus, setDropdownStatus] = useState(false);

  const handleClick = () => {
    if (disabled) return;
    setDropdownStatus(prev => !prev);
  };

  const kebabClassName = classnames('k-kebabMenu', className);
  return (
    <KebabMenuWrapper disabled={disabled} title={title}>
      <div className={kebabClassName}>
        <Popover
          addUpdateListeners
          showShadow={false}
          hideOnBlur
          popoverStyle={{ padding: 0 }}
          setArrowStatus={setDropdownStatus}
          centerOnMobile={false}
          showPopover={dropdownStatus}
          showOnHover={false}
          content={<DropDownMenu options={options} />}
          disabled={disabled}
          hideOnDropDownClick
          arrowPosition="right"
          align="right"
        >
          <button
            type="button"
            className={classnames('k-kebabMenu__icon', { 'k-kebabMenu__icon--disabled': disabled })}
            onClick={handleClick}
          >
            <Icon name="more_vert" />
          </button>
        </Popover>
      </div>
    </KebabMenuWrapper>
  );
};
