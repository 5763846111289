import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StandardDate } from '@/types/dates.types';
import { difference } from '@/utils/array/array.helpers';

import { AttendancesUiStateStoreState } from './attendancesUiState.types';

const initialState: AttendancesUiStateStoreState = {
  notExpandedDates: [],
  visibleColumns: {},
};

const sliceName = 'attendancesUiState';

const attendancesUiStateSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setAttendanceExpandedRows(
      state,
      action: PayloadAction<{ expanded?: StandardDate[]; notExpanded?: StandardDate[] }>,
    ) {
      const { expanded = [], notExpanded = [] } = action.payload;
      state.notExpandedDates = difference(state.notExpandedDates, expanded);
      state.notExpandedDates = [...new Set([...state.notExpandedDates, ...notExpanded])];
    },
    setAttendanceVisibleColumn(state, action: PayloadAction<AttendancesUiStateStoreState['visibleColumns']>) {
      state.visibleColumns = action.payload;
    },
  },
});

export const { setAttendanceExpandedRows, setAttendanceVisibleColumn } = attendancesUiStateSlice.actions;

export const attendancesUiStateReducer = attendancesUiStateSlice.reducer;
