import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Button = props => {
  let modifiers = props.modifiers || [];
  if (!Array.isArray(modifiers)) {
    modifiers = modifiers.split(' ');
  }
  const disabled = props.disabled || modifiers.includes('disabled');
  const className = classNames('kButton', props.className, ...modifiers.map(modifier => `kButton--${modifier}`), {
    'kButton--disabled': disabled,
  });
  return (
    <button
      ref={props.forwardedRef}
      type={props.type || 'button'}
      id={props.id}
      className={className}
      onClick={e => !disabled && props.onClick && props.onClick(e)}
      data-test={props.testId}
      onBlur={props.onBlur}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  forwardedRef: PropTypes.func,
  testId: PropTypes.string,
  onBlur: PropTypes.func,
};

const withForwardedRef = Comp => {
  const handle = (props, ref) => <Comp {...props} forwardedRef={ref} />;

  handle.displayName = 'Button';

  return forwardRef(handle);
};

export default withForwardedRef(Button);
