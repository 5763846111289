import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addShift: {
    id: 'attendanceButtonBar.tooltip.addAttendance',
    defaultMessage: 'Dodaj zmianę',
  },
  refresh: {
    id: 'attendanceButtonBar.tooltip.refresh',
    defaultMessage: 'Odśwież',
  },
  delete: {
    id: 'attendanceButtonBar.tooltip.delete',
    defaultMessage: 'Usuń',
  },
  unselectAll: {
    id: 'attendanceButtonBar.tooltip.unselectAll',
    defaultMessage: 'Odznacz wszystkich',
  },
  selectAll: {
    id: 'attendanceButtonBar.tooltip.selectAll',
    defaultMessage: 'Zaznacz wszystkich',
  },
  edit: {
    id: 'attendanceButtonBar.tooltip.edit',
    defaultMessage: 'Edytuj',
  },
  add: {
    id: 'attendanceButtonBar.tooltip.add',
    defaultMessage: 'Oznacz jako obecny',
  },
  confirmAttendances: {
    id: 'attendanceButtonBar.tooltip.confirmAttendances',
    defaultMessage: 'Zatwierdź obecność bez zmiany',
  },
  acceptOvertime: {
    id: 'attendanceButtonBar.tooltip.acceptOvertime',
    defaultMessage: 'Zatwierdź nadgodziny',
  },
  createAttendance: {
    id: 'attendanceButtonBar.tooltip.createAttendance',
    defaultMessage: 'Dodaj obecność',
  },
});
