import { createSelector } from '@reduxjs/toolkit';

import { mapId } from '@/utils/array/array.helpers';

import { RootState } from '../types';

const selectSelectedJobTitles = (state: RootState) => state.reducer.jobtitleFilter.selectedJobtitles;

export const selectSelectedJobTitlesIds = createSelector(selectSelectedJobTitles, selectedJobTitles =>
  mapId(selectedJobTitles),
);

const selectSelectedJobTitlesGrouped = (state: RootState) => state.reducer.jobtitleFilter.selectedJobtitlesGrouped;

export const selectSelectedJobTitlesGroupedIds = createSelector(
  selectSelectedJobTitlesGrouped,
  selectedJobTitlesGrouped => mapId(selectedJobTitlesGrouped),
);
