import classnames from 'classnames';
import PropTypes from 'prop-types';

import { DIRECTIONS } from '@/constants/ui';

import Popover from '../Popover/Popover';

import './Tooltip.scss';

const Tooltip = ({
  children,
  content,
  position = DIRECTIONS.TOP,
  popoverStyle = {},
  wrapperStyle = {},
  xOffset,
  yOffset,
  className,
  wrapperClassName,
}) => {
  const tooltipClassName = classnames('k-tooltip__content', className, {
    'k-tooltip__content--hide': !content,
  });

  return (
    <Popover
      position={position}
      showOnHover
      showShadow={false}
      popoverStyle={{ padding: 0, ...popoverStyle }}
      wrapperStyle={wrapperStyle}
      noArrow
      xOffset={xOffset}
      yOffset={yOffset}
      content={<div className={tooltipClassName}>{content}</div>}
      preventPopoverHide
      wrapperClassName={wrapperClassName}
    >
      {children}
    </Popover>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
  position: PropTypes.string,
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
  popoverStyle: PropTypes.shape({}),
  wrapperStyle: PropTypes.shape({}),
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default Tooltip;
