import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import './kadroComposedChart.scss';

const KadroComposedChart = ({
  data,
  metadata,
  axisMetadata,
  tooltipLabelFormatter,
  hideTicks,
  xTickFormatter,
  yTickFormatter,
  margin,
  showLegend = true,
  valueFormatter = value => value,
}) => {
  const hasRightAxis = metadata.some(item => item.yAxisId === 'right');
  const tooltipFormatter = useCallback(
    payload => `${valueFormatter(payload)} ${axisMetadata?.left?.unit || ''}`,
    [valueFormatter, axisMetadata],
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        className="k-composedChart"
        width={500}
        height={300}
        data={data}
        margin={
          margin || {
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }
        }
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={axisMetadata?.main?.dataKey || 'date'} tick={!hideTicks} tickFormatter={xTickFormatter} />
        <YAxis
          yAxisId="left"
          unit={axisMetadata?.left?.unit}
          tick={!hideTicks}
          width={30}
          tickFormatter={yTickFormatter}
        />
        {hasRightAxis && <YAxis yAxisId="right" orientation="right" unit={axisMetadata?.right?.unit} />}
        <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
        {showLegend ? <Legend /> : null}
        {metadata
          .sort((a, b) => a.order - b.order)
          .map(item => {
            if (item.chartType === 'bar') {
              return (
                <Bar
                  yAxisId={item.yAxisId}
                  type="monotone"
                  dataKey={item.key}
                  fill={item.color}
                  name={item.label}
                  stackId={item.stackId}
                />
              );
            }

            if (item.chartType === 'line') {
              return (
                <Line
                  yAxisId={item.yAxisId}
                  type="monotone"
                  dataKey={item.key}
                  stroke={item.color}
                  activeDot={{ r: 8 }}
                  strokeWidth={3}
                  name={item.label}
                />
              );
            }

            return null;
          })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

KadroComposedChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  metadata: PropTypes.arrayOf(PropTypes.shape({})),
  axisMetadata: PropTypes.shape({
    left: PropTypes.shape({
      unit: PropTypes.string,
    }),
    right: PropTypes.shape({
      unit: PropTypes.string,
    }),
    main: PropTypes.shape({
      dataKey: PropTypes.string,
    }),
  }),
  hideTicks: PropTypes.bool,
  tooltipLabelFormatter: PropTypes.func,
  xTickFormatter: PropTypes.func,
  yTickFormatter: PropTypes.func,
  valueFormatter: PropTypes.func,
  showLegend: PropTypes.bool,
};

export default KadroComposedChart;
