import rawMoment from 'moment';
import { defineMessages } from 'react-intl';

import { DATE_MODE_CUSTOM, DATE_MODE_CUSTOM_ID, dateModes, dateMoveDirections } from '@/constants/dateModes.ts';
import { viewDateModes } from '@/constants/viewDateModes.ts';
import { KadroLocaleEnum } from '@/types/locale.types.ts';

import { pad } from './baseHelpers.js';
import { getLocaleConfig } from './locale/locale.utils.ts';
import { getDateRangeFromDateArray } from './mainDateStoreHelpers.js';

const config = getLocaleConfig(KadroLocaleEnum.PL);
rawMoment.updateLocale(config.momentLanguage, config.momentLocale);

export const moment = rawMoment;

const messages = defineMessages({
  dateHelperMonthInflectedJanuary: {
    id: 'dateHelper.monthInflected.january',
    defaultMessage: 'styczniu',
  },
  dateHelperMonthInflectedFebruary: {
    id: 'dateHelper.monthInflected.february',
    defaultMessage: 'lutym',
  },
  dateHelperMonthInflectedMarch: {
    id: 'dateHelper.monthInflected.march',
    defaultMessage: 'marcu',
  },
  dateHelperMonthInflectedApril: {
    id: 'dateHelper.monthInflected.april',
    defaultMessage: 'kwietniu',
  },
  dateHelperMonthInflectedMay: {
    id: 'dateHelper.monthInflected.may',
    defaultMessage: 'maju',
  },
  dateHelperMonthInflectedJune: {
    id: 'dateHelper.monthInflected.june',
    defaultMessage: 'czerwcu',
  },
  dateHelperMonthInflectedJuly: {
    id: 'dateHelper.monthInflected.july',
    defaultMessage: 'lipcu',
  },
  dateHelperMonthInflectedAugust: {
    id: 'dateHelper.monthInflected.august',
    defaultMessage: 'sierpniu',
  },
  dateHelperMonthInflectedSeptember: {
    id: 'dateHelper.monthInflected.september',
    defaultMessage: 'wrześniu',
  },
  dateHelperMonthInflectedOctober: {
    id: 'dateHelper.monthInflected.october',
    defaultMessage: 'październiku',
  },
  dateHelperMonthInflectedNovember: {
    id: 'dateHelper.monthInflected.november',
    defaultMessage: 'listopadzie',
  },
  dateHelperMonthInflectedDecember: {
    id: 'dateHelper.monthInflected.december',
    defaultMessage: 'grudniu',
  },

  todayText: {
    id: 'time.today',
    defaultMessage: 'Dzisiaj',
  },
  tommorowText: {
    id: 'time.tomorow',
    defaultMessage: 'Jutro',
  },
  daySpanText: {
    id: 'time.dayDiff',
    defaultMessage: 'Za {dayDiff} dni',
  },
});

export const EMPTY_HOUR = '__:__';

export const calculateDateShiftInt = (dateStore, newModeType) => {
  if (newModeType === 'custom') return 0;
  const oldMode = dateModes.find(mode => mode.type === dateStore.dateMode);
  const newMode = dateModes.find(mode => mode.type === newModeType);
  if (!oldMode || !newMode) return 0;
  let start = moment(dateStore.dateArray[0]).startOf(newMode.type);
  switch (oldMode.type) {
    case 'week':
      if (start.week() === moment().week()) start = moment();
      break;
    case 'month':
      if (moment(dateStore.dateArray[0]).month() === moment().month()) start = moment();
      break;
    default:
      break;
  }
  let dateShiftInt = 0;
  switch (newModeType) {
    case 'day':
      if (start.week() === moment().week()) {
        dateShiftInt = 0;
      } else {
        dateShiftInt = start.diff(moment().startOf('day'), newMode.type);
      }
      break;
    case 'week':
      dateShiftInt = start.week() - moment().week();
      break;
    case 'month':
      dateShiftInt = start.month() - moment().month();
      break;
    default:
      return 0;
  }
  return dateShiftInt;
};

/**
 * Generates an array of dates in the format YYYY-MM-DD
 *
 * @param {string} mode - "week","month"
 * @param {integer} shiftInt
 * @returns {Array<string>}
 */
export const generateDateArrayWithShiftInt = (mode, shiftInt) => {
  let base;
  if (shiftInt >= 0) {
    base = moment().add(shiftInt, mode);
  } else {
    base = moment().subtract(-shiftInt, mode);
  }
  const start = base.clone().startOf(mode);
  const end = base.clone().endOf(mode);
  const days = [];
  let day = start;
  while (day <= end) {
    const dText = day.format('YYYY-MM-DD');
    days.push(dText);
    day = day.clone().add(1, 'd');
  }

  return days;
};

export const generateDateArrayForCustomMode = (dateArray, moveDirection) => {
  const isForNextDates = moveDirection === dateMoveDirections.next;
  const [firstDate, lastDate] = getFromToFromDateStore({ dateArray });
  const daysDiff = dateArray.length - 1;
  const startDate = isForNextDates ? moment(lastDate).add(1, 'd') : moment(firstDate).subtract(daysDiff + 1, 'd');
  const endDate = startDate.clone().add(daysDiff, 'd');
  return getRangeBetweenDates(startDate, endDate);
};

/**
 * Returns from, to for given int
 *
 * @param {string} mode - "week","month"
 * @param {integer} shiftInt
 * @returns {object}
 */
export const generateFromToWithShiftInt = (mode, shiftInt) => {
  let base;
  if (shiftInt >= 0) {
    base = moment().add(shiftInt, mode);
  } else {
    base = moment().subtract(-shiftInt, mode);
  }
  const start = base.clone().startOf(mode);
  const end = base.clone().endOf(mode);
  return { from: start.format('YYYY-MM-DD'), to: end.format('YYYY-MM-DD') };
};

/**
 * Generates an array of dates in the format YYYY-MM-DD
 *
 * @param {string} mode - "week","month"
 * @param {integer} shiftInt
 * @returns {Array<string>}
 */
export const generateHeaderDateArrayWithShiftInt = (mode, shiftInt) => {
  let base;
  if (shiftInt >= 0) {
    base = moment().add(shiftInt, mode);
  } else {
    base = moment().subtract(-shiftInt, mode);
  }
  const start = base.clone().startOf(mode);
  const end = base.clone().endOf(mode);
  const days = [];
  let day = start;
  const dayFormat = mode === 'week' ? 'dddd' : 'ddd';
  while (day <= end) {
    const dText = day.format(`${dayFormat} DD.MM`);
    days.push(dText);
    day = day.clone().add(1, 'd');
  }

  return days;
};

/**
 * Remaps dateArray to another dateFormat based on dateMode
 *
 * @param {string} mode - "week","month","custom"
 * @param {Array<string>} dateArray - array with range of dates
 * @returns {Array<string>}
 */
export const generateHeaderDateArray = (mode, dateArray) => {
  const dayFormat = mode === 'week' ? 'dddd' : 'ddd';
  return dateArray.map(date => moment(date).format(`${dayFormat} DD.MM`));
};

/**
 *  Converts to a date in the format of DD.MM
 * @param {string} date - YYYY-MM-DD
 * @returns {string} DD.MM
 */
const convertToHumanMonthDayDate = date => {
  const d = date.split('-');
  return `${d[2]}.${d[1]}`;
};

/**
 *  Returns text for the date component
 * @param {string} mode
 * @param {Array} dateArray
 */
export const generateDateTextFromArray = (mode, dateArray) => {
  if (mode === 'week') {
    return `${convertToHumanMonthDayDate(dateArray[0])} - ${convertToHumanMonthDayDate(
      dateArray[dateArray.length - 1],
    )}`;
  }
  if (mode === 'month') {
    return `${convertToHumanMonthDayDate(dateArray[0])} - ${convertToHumanMonthDayDate(
      dateArray[dateArray.length - 1],
    )}`;
  }
  if (mode === 'day') {
    return `${moment(dateArray[0]).format('ddd')} ${convertToHumanMonthDayDate(dateArray[0])}`;
  }
  if (mode === 'year') {
    return `${moment(dateArray[0]).format('YYYY')}`;
  }
  return `${convertToHumanMonthDayDate(dateArray[0])} - ${convertToHumanMonthDayDate(dateArray[dateArray.length - 1])}`;
};

/**
 * Returns from / to pair for init data. Defaults to two weeks up front and back
 */
export const generateFromToForInitData = () => {
  const from = moment().startOf('week').format('YYYY-MM-DD');
  const to = moment().endOf('week').format('YYYY-MM-DD');
  return { from, to };
};

export const generateFromToForDateAndPeriod = (date, period) => {
  const from = moment(date).startOf(period).format('YYYY-MM-DD');
  const to = moment(date).endOf(period).format('YYYY-MM-DD');
  return { from, to };
};

/**
 * Calculates the duration of "hours" in minutes
 * @param {string} workingHours - ex. "10:00-18:00"
 *
 * @return {int} - returns minutes in int
 */
export const calculateDurationMinutes = workingHours => {
  if (typeof workingHours !== 'string') {
    return undefined;
  }
  // Attendances can have _ for end_timestap if not finished..
  const wh = workingHours.replace('_', '0').split('-');
  const endTime = wh[1];
  const startTime = wh[0];
  // this weird thing is for working_hours that end the next day..
  // f.e. 18:00-04:00 - the date 26-05 was chosen on the day this was crafted ^_^
  if (endTime < startTime) {
    const day1 = '26-05-2016 ';
    const day2 = '27-05-2016 ';
    return moment(day2 + endTime, 'DD-MM-YYYY HH:mm').diff(moment(day1 + startTime, 'DD-MM-YYYY HH:mm'), 'minutes');
  }
  return moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minutes');
};

export const getToday = () => moment().format('YYYY-MM-DD');

export const addNYearsFromToday = numOfYears => moment().add(numOfYears, 'year').toDate();

export const substractNYearsFromToday = numOfYears => moment().subtract(numOfYears, 'year').toDate();

export const substractNMonthsFromDate = (date, numOfMonths) =>
  date && numOfMonths && moment(date).clone().subtract(numOfMonths, 'month').format('YYYY-MM-DD');

export const getNowInMinutes = () => {
  const now = moment().format('HH:mm').split(':');
  return parseInt(now[0]) * 60 + parseInt(now[1]);
};

export const parseMinutesToHumanForm = (
  minutes,
  { dontDisplayZeroHours = false, roundToWholeMinutes = false } = {},
) => {
  const formattedMinutes = roundToWholeMinutes ? Math.round(minutes) : minutes;

  if (formattedMinutes >= 0) {
    if (formattedMinutes < 60 && dontDisplayZeroHours) return `${formattedMinutes}m`;
    return `${Math.floor(formattedMinutes / 60)}h ${formattedMinutes % 60}m`;
  }
  const absMinutes = Math.abs(formattedMinutes);
  if (absMinutes < 60 && dontDisplayZeroHours) return `-${absMinutes}m`;
  return `-${Math.floor(absMinutes / 60)}h ${absMinutes % 60}m`;
};
export const parseMinutesToFullHours = minutes =>
  `${`0${Math.floor(minutes / 60)}`.slice(-2)}:${`0${minutes % 60}`.slice(-2)}`;

export const parseMinutesToHours = minutes => {
  const resultHours = Math.floor(minutes / 60);
  const resultMinutes = String(minutes % 60).padStart(2, '0');

  return `${resultHours}:${resultMinutes}`;
};

export const parseMinutesToDecimalForm = (minutes, round) => (round ? (minutes / 60).toFixed(2) : minutes / 60);

// Teaching Hour equals to 45 minutes
export const parseMinutesToTeachingHoursForm = (minutes, round) => (round ? (minutes / 45).toFixed(2) : minutes / 45);

/**
 * This is the main function for formatting time
 * @param {number} minutes - number of minutes
 * @param {string} format - type of format
 * @returns {string} formated time
 */
export const parseMinutesToFormat = (minutes, format = 'hours', returnFloatForDecimalFormat = false, round = true) => {
  const parsedMinutes = Math.round(Number(minutes));
  switch (format) {
    case 'decimal':
      if (returnFloatForDecimalFormat) return parseFloat(parseMinutesToDecimalForm(parsedMinutes, round));
      return parseMinutesToDecimalForm(parsedMinutes, round);
    case 'teachingHours':
      if (returnFloatForDecimalFormat) return parseFloat(parseMinutesToTeachingHoursForm(parsedMinutes, round));
      return parseMinutesToTeachingHoursForm(parsedMinutes, round);
    default:
      return parseMinutesToHumanForm(parsedMinutes);
  }
};

export const getInitCalendarRange = () => ({
  start: moment().startOf('week').toDate(),
  end: moment().endOf('week').toDate(),
});

export const getInitCalendarChoice = date => [moment(date, 'YYYY-MM-DD').toDate()];

export const getCalendarRangeText = (from, to) =>
  `Od: ${moment(from).format('DD.MM.YYYY')}    Do: ${moment(to).format('DD.MM.YYYY')}`;
/**
 *
 * @param startDate
 * @param stopDate
 */
export const getRangeBetweenDates = (startDate, stopDate) => {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopDateObj = moment(stopDate);
  while (currentDate <= stopDateObj) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
};

/**
 * This converts the date to a "standard format" which is recognized by the backend
 * @param date
 */
export const convertDateToStandardFormat = date => moment(date).format('YYYY-MM-DD');

export const convertDateToHumanText = date => moment(date).format('dddd DD.MM');

export const convertDateToHumanTextShortcut = date => moment(date).format('ddd DD.MM');

export const convertDateToHourMin = date => moment(date).format('HH:mm');

export const convertDateToCustomFormat = (date, format = 'DD.MM.YYYY') => moment(date).format(format);

export const mapTimestampsToShift = shift => {
  const workingHoursSplit = shift.working_hours.split('-');
  const startTimestamp = moment(`${shift.date} ${workingHoursSplit[0]}`, 'YYYY-MM-DD HH:mm');
  let endTimestamp;
  if (workingHoursSplit[1] < workingHoursSplit[0]) {
    endTimestamp = moment(`${shift.date} ${workingHoursSplit[1]}`, 'YYYY-MM-DD HH:mm').add(1, 'day');
  } else {
    endTimestamp = moment(`${shift.date} ${workingHoursSplit[1]}`, 'YYYY-MM-DD HH:mm');
  }
  return {
    ...shift,
    start_timestamp: startTimestamp.format('YYYY-MM-DD HH:mm:SS'),
    end_timestamp: endTimestamp.format('YYYY-MM-DD HH:mm:SS'),
  };
};

/**
 * Returns timestamp of current date in "kadroformat"
 */
export const getNowTimestamp = () => moment().format('YYYY-MM-DD HH:mm:SS');

/**
 * Return duration in minutes from timestamp. IF THE END TIMESTAMP IS NULL WE CHANGE IT TO CURRENT TIME
 * @param {string} start - timestamp
 * @param {string} end - timestamp YYYY-MM-DD HH:mm:SS
 * @return {number} minutes
 */
export const calculateDurationBetweenTimestamps = (end, start) => {
  let newEnd = end;
  if (end === null) {
    newEnd = getNowTimestamp();
    return Math.round(moment().diff(moment(start), 'minutes'));
  }
  return Math.round(moment(newEnd).diff(moment(start), 'minutes'));
};

/**
 * Return duration in minutes from timestamp. IF THE END TIMESTAMP IS NULL WE CHANGE IT TO CURRENT TIME
 * @param {string} start - timestamp
 * @param {string} end - timestamp YYYY-MM-DD HH:mm:SS
 * @return {float} minutes
 */
export const calculateDurationBetweenTimestampsWithFractions = (end, start) => {
  let newEnd = end;
  if (end === null) {
    newEnd = getNowTimestamp();
    return moment().diff(moment(start), 'seconds') / 60;
  }
  return moment(newEnd).diff(moment(start), 'seconds') / 60;
};

/**
 * Returns duration in minutes from timestamp based on specific payroll settings provided
 * @param {string} end
 * @param {string} start
 * @param {object} roundingSetting
 */
export const calculateDurationBetweenTimestampsForPayroll = (end, start, roundingSetting) => {
  const duration = calculateDurationBetweenTimestamps(end, start);
  // TODO: Should we fail here or just return the duration in minutes?
  if (typeof roundingSetting === 'undefined') {
    console.error('roundingSetting was not specified, returning minutes');
    return duration;
  }
  // Rounding Number is an integer representing minutes to which we round a
  // particular attendance / shift time
  const roundingNumber = roundingSetting.roundNum;
  return Math.round(duration / roundingNumber) * roundingNumber;
};

export const isSameTimestamp = (first, second, mode = 'second') => {
  const firstTS = moment(first);
  const secondTS = moment(second);
  return firstTS.isSame(secondTS, mode);
};
export const isBeforeTimestamp = (first, second, mode = 'second') => {
  const firstTS = moment(first);
  const secondTS = moment(second);
  return firstTS.isBefore(secondTS, mode);
};
export const isAfterTimestamp = (first, second, mode = 'second') => {
  const firstTS = moment(first);
  const secondTS = moment(second);
  return firstTS.isAfter(secondTS, mode);
};
export const isSameOrBeforeTimestamp = (first, second, mode = 'second') => {
  const firstTS = moment(first);
  const secondTS = moment(second);
  return firstTS.isSameOrBefore(secondTS, mode);
};
export const isSameOrAfterTimestamp = (first, second, mode = 'second') => {
  const firstTS = moment(first);
  const secondTS = moment(second);
  return firstTS.isSameOrAfter(secondTS, mode);
};

export const isBetweenTimestamp = (first, second, mode = 'second') => {
  const firstTS = moment(first);
  const secondTS = moment(second);
  return firstTS.isBetween(secondTS, mode);
};

// The object here contains start_timestamp and end_timestamp
const extractMomentTimestampsFromObject = object => ({
  start: moment(object.start_timestamp),
  end: moment(object.end_timestamp),
});
// TODO: move
export const isAttendanceContainedInShift = (att, shift, timeMode = 'minute', compare = '[]') => {
  const attTS = extractMomentTimestampsFromObject(att);
  const shiftTS = extractMomentTimestampsFromObject(shift);
  return (
    attTS.start.isBetween(shiftTS.start, shiftTS.end, timeMode, compare) ||
    attTS.end.isBetween(shiftTS.start, shiftTS.end, timeMode, compare)
  );
  // Check if
};

export const isAttendanceRelevantForShift = (att, shift, minutesRelevant = 30) => {
  const attTS = extractMomentTimestampsFromObject(att);
  const shiftTS = extractMomentTimestampsFromObject(shift);
  // TODO: Make the magic numbers a settings
  // Does the attendance start up to 30 minutes before
  const testStart = attTS.start.diff(shiftTS.start, 'minutes') <= minutesRelevant;
  const testEnd = attTS.end.diff(shiftTS.end, 'minutes') <= minutesRelevant;
  const testContained =
    attTS.start.isBetween(shiftTS.start, shiftTS.end, 'minute', '[]') ||
    attTS.end.isBetween(shiftTS.start, shiftTS.end, 'minute', '[]');
  return testStart || testEnd || testContained;
};

export const isNextDay = (end, start) => moment(end).diff(moment(start), 'days') > 0;
export const moveDateForwardByOne = (mode, date) => {
  if (['day', 'week', 'month'].includes(mode)) {
    return moment(date).add(mode, 1).format('YYYY-MM-DD');
  }
  throw Error('Not implemented yet.');
};

/**
 * Calculates dateShiftInt from given date
 *
 * @param {string} mode - "day", "week", "month"
 * @param {string} date
 * @returns {number}
 */
export const getDateShiftInt = (mode, date) => {
  if (!['day', 'week', 'month', 'year'].includes(mode)) {
    throw Error('Not implemented yet.');
  }
  return moment(date).startOf(mode).diff(moment().startOf(mode), mode);
};

/**
 * Shifts all dates in array by given span of time
 *
 * @param {array} dates - array of dates to shift
 * @param {integer} dateShiftInt -  how much should we shift of given time span
 * @param {string} dateMode - time span for the shift "week", "month" ect.
 * @returns {array}
 */
export const shiftArrayOfDates = (dates, dateShiftInt, dateMode) => {
  if (!['day', 'week', 'month', 'year'].includes(dateMode)) return [];

  return dates.map(date => moment(date).add(dateShiftInt, dateMode).format('YYYY-MM-DD'));
};

/**
 * Function returns true is both array have the same days of week/month in them
 *
 * @param {array} dates1 - array of dates to compare
 * @param {array} dates2 - array of dates to compare
 * @param {string} dateMode - week if we compare as day of weeks or month -||-
 * @returns {boolean}
 */
export const compareDateArraysDayOfSpanMatches = (dates1, dates2, dateMode) => {
  if (!['week', 'month'].includes(dateMode)) return false;

  if (dateMode === 'week') {
    const dates2Relative = dates2.map(d => moment(d).day());
    for (const date of dates1) {
      if (!dates2Relative.includes(moment(date).day())) return false;
    }
  } else {
    const dates2Relative = dates2.map(d => moment(d).date());
    for (const date of dates1) {
      if (!dates2Relative.includes(moment(date).date())) return false;
    }
  }

  return true;
};

// Hardcoded array where 0 means sunday
export const generateTemplatesDateArray = () => [1, 2, 3, 4, 5, 6, 0];

/**
 * Function returns array of dates only with given weekdays
 *
 * @param {array} range - array of dates
 * @param {array} weekdays - array of day numbers
 *
 * @returns {array}
 */
export const getRelevantDatesInRange = (range, weekdays) =>
  range.reduce((prev, day) => (weekdays.includes(moment(day).day()) ? prev.concat(day) : prev), []);

/**
 * Function checks if current time is after shift start_timestamp
 *
 * @param {object} shift - standard shift object with start_timestamp
 *
 * @returns {boolean}
 */
export const didShiftStarted = shift => moment(moment.now()).isAfter(shift.start_timestamp);

/**
 * Function takes working hours and return array of starttime and endtime in minutes
 * @param {string} workingHours - string with working hours in format "HH:mm-HH:mm"
 *
 * @returns {Array<integer>} - array of two integer representing hours in minutes
 */
export const splitWorkingHours = workingHours => {
  const hoursArray = workingHours.split('-');
  const startTime = hoursArray[0].split(':');
  const endTime = hoursArray[1].split(':');

  return [Number(startTime[0]) * 60 + Number(startTime[1]), Number(endTime[0]) * 60 + Number(endTime[1])];
};

export const getTodayWeekDay = () => moment().day();

/**
 *Function takes number from 0 to 6 or date in string format and returns true if dayNumber is 0 or 6.
 It was made for function to take number or string because in base code there are cases where this solution is needed.
 * @param {number or string} day
 *
 * @returns {boolean}
 */
export const isWeekend = day => {
  let dayNumber;
  if (Number.isInteger(day)) {
    dayNumber = day;
  } else {
    dayNumber = moment(day).day();
  }
  return dayNumber === 6 || dayNumber === 0;
};

export const isHoliday = (date, holidays) => {
  const momentDate = moment(date);
  const holiday = holidays && holidays.find(h => momentDate.isSame(moment(h.date, 'YYYY-MM-DD')));
  return {
    name: holiday ? holiday.name : '',
    freeFromWork: holiday ? holiday.freeFromWork : false,
    workDay: holiday ? !holiday.freeFromWork : false,
  };
};
/**
 * This function conferts minutes to hours and rounds them to 15 min
 */

export const minutesToHour = (srcMinutes, rounding, type) => {
  let minutes = srcMinutes;
  if (minutes === 1440) minutes = 0;
  if (type === 'start') {
    if (minutes < 0) minutes = 0;
  } else if (minutes < 0) minutes += 1440;
  if (minutes > 1440) minutes -= 1440;
  let min = minutes % 60;
  let hours = (minutes - min) / 60;
  min = Math.round(min / rounding) * rounding;
  if (min === 60) {
    min = 0;
    hours++;
  }
  return `${`0${hours}`.slice(-2)}:${`0${min}`.slice(-2)}`;
};

export const humanFormToMinutes = humanHours => {
  const isNegative = humanHours.startsWith('-');
  const cleanedHours = isNegative ? humanHours.slice(1) : humanHours;
  const regex = /(\d+)h\s*(\d+)m/;
  const matches = cleanedHours.match(regex);

  const digitHours = +matches[1];
  const digitMinutes = +matches[2];

  const totalMinutes = digitHours * 60 + digitMinutes;

  return isNegative && totalMinutes !== 0 ? -totalMinutes : totalMinutes;
};

export const hoursToMinutes = hours => {
  let minutes = 0;
  minutes += hours.split(':')[0] * 60;
  minutes += hours.split(':')[1] * 1;
  return minutes;
};

export const calculateBusinessDays = (firstDate, secondDate) => {
  // Initiallize variables
  let from = moment(firstDate);
  let to = moment(secondDate);
  let adjust = 0;

  if (from.format('YYYY-MM-DD') === to.format('YYYY-MM-DD')) {
    return 0;
  }

  // Check if second date is before first date to switch
  if (to.isBefore(from)) {
    to = moment(firstDate);
    from = moment(secondDate);
  }

  // Check if first date starts on weekends
  if (from.day() === 6) {
    // Saturday
    // Move date to next week monday
    from.day(8);
  } else if (from.day() === 0) {
    // Sunday
    // Move date to current week monday
    from.day(1);
  }

  // Check if second date starts on weekends
  if (to.day() === 6) {
    // Saturday
    // Move date to current week friday
    to.day(5);
  } else if (to.day() === 0) {
    // Sunday
    // Move date to previous week friday
    to.day(-2);
  }
  const fromWeek = from.week();
  let toWeek = to.week();

  // Check if two dates are in different week of the year
  if (fromWeek !== toWeek) {
    // Check if second date's year is different from first date's year
    if (toWeek < fromWeek) {
      toWeek += moment(to).subtract(1, 'year').weeksInYear();
    }
    // Calculate adjust value to be substracted from difference between two dates
    adjust = -2 * (toWeek - fromWeek);
  }

  return to.diff(from, 'days') + adjust + 1;
};

export const timestampsToWorkingHours = (startTimestamp, endTimestamp) => {
  const [startTimestampWithoutTimezone] = startTimestamp.replace('T', ' ').split('.');
  const endTimestampWithoutTimezone = endTimestamp?.replace('T', ' ').split('.')[0];
  const endHours = endTimestampWithoutTimezone ? moment(endTimestampWithoutTimezone).format('HH:mm') : '__:__';
  const startHours = moment(startTimestampWithoutTimezone).format('HH:mm');

  return `${startHours}-${endHours}`;
};

export const workingHoursToTimestamp = (workingHours, date) => {
  const [startTime, endTime] = workingHours.split('-');
  return {
    start_timestamp: `${date} ${startTime}:00`,
    end_timestamp:
      startTime <= endTime
        ? `${date} ${endTime}:00`
        : moment(`${date} ${endTime}`).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  };
};

export const sortShiftsByStartTimestamp = shifts =>
  shifts.sort((a, b) => new Date(a.start_timestamp) - new Date(b.start_timestamp));

export const extractDateFromTimestamp = timestamp => {
  const date = timestamp.split(' ')[0];
  const isDateValid = moment(date, 'YYYY-MM-DD', true).isValid();
  return isDateValid ? date : null;
};

export const getTimeFormatFromHour = hour => {
  if (!Number.isInteger(hour) || hour < 0 || hour > 24) return '';
  const stringHour = hour.toString();
  if (stringHour.length === 1) {
    return `0${stringHour}:00`;
  }
  return `${stringHour}:00`;
};

export const getDateFormatForGraphTooltip = date => {
  const isDateValid = moment(date, 'YYYY-MM-DD', true).isValid();
  if (!isDateValid) return '';
  const [year, , day] = date.split('-');
  const monthName = moment(date).format('MMMM').charAt(0).toUpperCase() + moment(date).format('MMMM').slice(1);
  return `${monthName} ${day}, ${year}`;
};

export const getTimeFormatFromHourAndMinutes = (hour, minutes) => {
  if (hour < 0 || hour > 24 || minutes < 0 || minutes > 60) return '';
  return moment({ h: hour, m: minutes }).format('HH:mm');
};

// inclusivity => "()", "[)", "[]", "(]"
export const checkIfHourIsInHourlyRange = (workingHours, time, inclusivity) => {
  const [startTimestamp, endTimestamp] = workingHours.split('-');
  const [startTimestampHour, startTimestampMinutes] = startTimestamp.split(':');
  const [endTimestampHour, endTimestampMinutes] = endTimestamp.split(':');
  const [hour, minutes] = time.split(':');
  const beginMoment = moment({ h: startTimestampHour, m: startTimestampMinutes });
  const endMoment = moment({ h: endTimestampHour, m: endTimestampMinutes });
  const timeMoment = moment({ h: hour, m: minutes });
  if (endMoment.isBefore(beginMoment)) {
    return timeMoment.isBetween(beginMoment, moment({ h: 23, m: 59 }), null, inclusivity);
  }
  return (
    timeMoment.isBetween(beginMoment, endMoment, null, inclusivity) ||
    (startTimestampHour === hour && endTimestampHour === hour)
  );
};

/**
 * Function returns array of dates based on repeat obj
 * repeatObj is set in shifts or availabilities addModal
 *
 * @param {object} repeatObj - contains type of repeat and selected object
 *
 * @returns {array}
 */
export const getDatesFromRepeatObj = repeatObj => {
  const { selectedRange, perWhichDay, selectedWeekdays, selectedChoices } = repeatObj.selected;

  let dateRange = [];

  switch (repeatObj.type) {
    case 'period': {
      dateRange = getRangeBetweenDates(selectedRange.start, selectedRange.end);
      if (selectedWeekdays.length) {
        dateRange = getRelevantDatesInRange(dateRange, selectedWeekdays);
      }
      break;
    }
    case 'days': {
      dateRange = getRangeBetweenDates(selectedRange.start, selectedRange.end);
      dateRange = dateRange.filter((date, idx) => idx % perWhichDay === 0);
      break;
    }
    case 'choice': {
      dateRange = selectedChoices.map(choice => convertDateToStandardFormat(choice));
      break;
    }
    default:
      break;
  }
  return dateRange;
};

export const getArrayWithWeekDaysAndDayNumbers = () =>
  [1, 2, 3, 4, 5, 6, 0].map((dayNo, index) => ({
    dayNumber: dayNo,
    dayName: moment()
      .isoWeekday(index + 1)
      .format('dddd'),
  }));

export const getArrayWithWeekdayNames = () =>
  Array.from({ length: 7 }, (v, index) =>
    moment()
      .isoWeekday(index + 1)
      .format('dddd'),
  );

export const getArrayWithHourRanges = () => Array.from({ length: 24 }, (v, index) => `${pad(index)}-${pad(index + 1)}`);

/**
 * Function returns array of dates considering disabled until date
 *
 * @param {array} dateArray - mainDateStore dateArray
 * @param {string} disabledUntilDate - date by which we should filter dateArray
 *
 * @returns {array}
 */
export const getRelevantDateRange = (dateArray, disabledUntilDate) => {
  if (!disabledUntilDate) {
    return dateArray;
  }
  if (moment(disabledUntilDate).isBefore(dateArray[0])) {
    return dateArray;
  }
  if (moment(disabledUntilDate).isSameOrAfter(dateArray[dateArray.length - 1])) {
    return [];
  }
  const startDate = moment(disabledUntilDate).add(1, 'day').format('YYYY-MM-DD');
  const endDate = dateArray[dateArray.length - 1];
  return getRangeBetweenDates(startDate, endDate);
};

export const getRelevantDateRangeForLocation = (locationSettings, dateArray) => {
  const shiftEditDisabledUntil = locationSettings?.disable_location_schedule_shifts_edit_until;
  const relevantDateArray = getRelevantDateRange(dateArray, shiftEditDisabledUntil);
  return getDateRangeFromDateArray(relevantDateArray);
};

export const defaultDateStores = Object.keys(viewDateModes).reduce((result, key) => {
  const dateMode = viewDateModes[key].default;
  const dateArray = generateDateArrayWithShiftInt(dateMode, 0);
  const previousDateArray = generateDateArrayWithShiftInt(dateMode, -1);
  const headerDateArray = generateHeaderDateArray(dateMode, dateArray);
  const templatesDateArray = generateTemplatesDateArray();
  const dateText = generateDateTextFromArray(dateMode, dateArray);

  return {
    ...result,
    [key]: {
      dateShiftInt: 0,
      dateMode,
      dateArray,
      customDate: {
        start: dateArray[0],
        end: dateArray[dateArray.length - 1],
      },
      previousDateArray,
      headerDateArray,
      templatesDateArray,
      dateText,
      today: getToday(),
    },
  };
}, {});

export const getScheduleCycleForDateAndEmployee = (date, scheduleCycle) => {
  const { month, year, duration } = scheduleCycle;
  const endCycleDate = moment(`${year}-${month}-01`);

  while (endCycleDate.isSameOrBefore(moment(date))) {
    endCycleDate.add(duration, 'months');
  }

  const startCycleDate = moment(endCycleDate).subtract(duration, 'months');
  endCycleDate.subtract(1, 'days');

  return `${startCycleDate.format('YYYY-MM-DD')};${endCycleDate.format('YYYY-MM-DD')}`;
};

export const isDateInScheduleCycle = (date, scheduleCycle) => {
  const [start, end] = scheduleCycle.split(';');
  const dateArray = getRangeBetweenDates(start, end);

  return dateArray.includes(date);
};

export const getTextForTimeDiff = dayDiff => {
  if (dayDiff === 0) {
    return messages.todayText;
  }
  if (dayDiff === 1) {
    return messages.tommorowText;
  }
  return messages.daySpanText;
};
export const getOmittedDays = (from, to, omitHolidays, omitWeekends, holidays) => {
  if (!omitWeekends && !omitHolidays) {
    return [];
  }
  const numberOfDays = moment(to).diff(from, 'days') + 1;
  const datesArray = Array.from({ numberOfDays }, (_, index) => moment(from).add(index, 'days').format('YYYY-MM-DD'));
  const holidayDates = holidays.filter(holiday => holiday.freeFromWork).map(holiday => holiday.date);
  return datesArray.filter(date => {
    if (omitWeekends && omitHolidays) {
      return [6, 7].includes(moment(date).isoWeekday()) || holidayDates.includes(date);
    }
    if (omitWeekends) {
      return [6, 7].includes(moment(date).isoWeekday());
    }
    if (omitHolidays) {
      return holidayDates.includes(date);
    }
    return false;
  });
};

export const getMonthInflected = (month, intl) => {
  switch (month) {
    case 1:
      return intl.formatMessage(messages.dateHelperMonthInflectedJanuary, {});
    case 2:
      return intl.formatMessage(messages.dateHelperMonthInflectedFebruary, {});
    case 3:
      return intl.formatMessage(messages.dateHelperMonthInflectedMarch, {});
    case 4:
      return intl.formatMessage(messages.dateHelperMonthInflectedApril, {});
    case 5:
      return intl.formatMessage(messages.dateHelperMonthInflectedMay, {});
    case 6:
      return intl.formatMessage(messages.dateHelperMonthInflectedJune, {});
    case 7:
      return intl.formatMessage(messages.dateHelperMonthInflectedJuly, {});
    case 8:
      return intl.formatMessage(messages.dateHelperMonthInflectedAugust, {});
    case 9:
      return intl.formatMessage(messages.dateHelperMonthInflectedSeptember, {});
    case 10:
      return intl.formatMessage(messages.dateHelperMonthInflectedOctober, {});
    case 11:
      return intl.formatMessage(messages.dateHelperMonthInflectedNovember, {});
    case 12:
      return intl.formatMessage(messages.dateHelperMonthInflectedDecember, {});
    default:
      return 'unknown month';
  }
};

export const createFullDate = (day, time) => {
  const date = moment(day);
  const [hour, minute] = time.split(':');
  date.set({ hour, minute });
  return date;
};

export const checkIfLastDay = (date, dateMode) =>
  (dateMode === 'week' && moment(date).endOf('week').format('YYYY-MM-DD') === date) ||
  (dateMode === 'month' && moment(date).endOf('month').format('YYYY-MM-DD') === date);

export const isTimeRangeFormat = value => value?.match(/^[0-9]{2}:[0-9]{2}-[0-9]{2}:[0-9]{2}$/);

export const getFilteredShiftBlockOptions = (shiftblocks, selectedJobTitleId) =>
  shiftblocks.reduce(
    (list, shiftBlock) =>
      shiftBlock.job_titles === null || shiftBlock.job_titles.some(jobTitleId => jobTitleId === selectedJobTitleId)
        ? [...list, shiftBlock.working_hours]
        : list,
    [],
  );

export const getFromToFromDateStore = (mainDateStore, minDate, maxDate) => [
  minDate || mainDateStore.dateArray[0],
  maxDate || mainDateStore.dateArray[mainDateStore.dateArray.length - 1],
];

export const areDateArraysDifferent = (prevMainDateStore, mainDateStore) => {
  const [oldFromDate, oldToDate] = getFromToFromDateStore(prevMainDateStore);
  const [newFromDate, newToDate] = getFromToFromDateStore(mainDateStore);
  return newFromDate !== oldFromDate || oldToDate !== newToDate;
};

export const checkWorkingHoursOverlaps = workingHoursArray => {
  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  return workingHoursArray.some((workingHours, index) =>
    workingHoursArray.some((currentHours, i) => {
      if (index === i) return false;
      const [start, end] = workingHours.split('-');
      const [startToCompare, endToCompare] = currentHours.split('-');
      const block1 = {
        startHour: `${today} ${start}`,
        endHour: start < end ? `${today} ${end}` : `${tomorrow} ${end}`,
      };
      const block2 = {
        startHour: `${today} ${startToCompare}`,
        endHour: startToCompare < endToCompare ? `${today} ${endToCompare}` : `${tomorrow} ${endToCompare}`,
      };
      return (
        (block1.startHour >= block2.startHour && block1.startHour < block2.endHour) ||
        (block1.endHour > block2.startHour && block1.endHour <= block2.endHour) ||
        (block1.startHour <= block2.startHour && block1.endHour >= block2.endHour)
      );
    }),
  );
};

export const isWholePeriodSelected = ({ start, end }, period) => {
  const startOfPeriod = convertDateToStandardFormat(moment(start).startOf(period));
  const endOfPeriod = convertDateToStandardFormat(moment(start).endOf(period));
  return start === startOfPeriod && end === endOfPeriod;
};

export const getDateModeFromDates = dateRanges => {
  const dateMode = dateModes.find(({ type }) => type !== DATE_MODE_CUSTOM && isWholePeriodSelected(dateRanges, type));
  return dateMode || { type: DATE_MODE_CUSTOM, id: DATE_MODE_CUSTOM_ID };
};

export const isCustomDateMode = ({ dateMode }) => dateMode === DATE_MODE_CUSTOM;

export const getNextRoundedHourForTime = hoursWithMinutes => {
  const [hour, minutes] = hoursWithMinutes.split(':');
  const newHour = parseInt(minutes) === 0 ? parseInt(hour) : parseInt(hour) + 1;
  return newHour === 24 ? 0 : newHour;
};

export const getPreviousRoundedHourForTime = hoursWithMinutes => {
  const [hour] = hoursWithMinutes.split(':');
  return parseInt(hour);
};

export const singleHourToFormattedTime = hour => (hour < 10 ? `0${hour}:00` : `${hour}:00`);

export const getStartAndEndTimestamp = (workingHours, date) => {
  const workingHoursSplit = workingHours.split('-');
  const startTimestamp = moment(`${date} ${workingHoursSplit[0]}`, 'YYYY-MM-DD HH:mm');
  let endTimestamp;
  if (workingHoursSplit[1] < workingHoursSplit[0]) {
    endTimestamp = moment(`${date} ${workingHoursSplit[1]}`, 'YYYY-MM-DD HH:mm').add(1, 'day');
  } else {
    endTimestamp = moment(`${date} ${workingHoursSplit[1]}`, 'YYYY-MM-DD HH:mm');
  }
  return [startTimestamp.format('YYYY-MM-DD HH:mm:SS'), endTimestamp.format('YYYY-MM-DD HH:mm:SS')];
};

export const isMonthPeriod = (from, to) =>
  moment(from).startOf('month').format('YYYY-MM-DD') === from && moment(to).endOf('month').format('YYYY-MM-DD') === to;

export const startOfMonth = (date, format) =>
  moment(date)
    .startOf('month')
    .format(format || 'YYYY-MM-DD');

export const endOfMonth = (date, format) =>
  moment(date)
    .endOf('month')
    .format(format || 'YYYY-MM-DD');

export const getMonthName = date => moment(date).format('MMMM');
export const getMonthNameFromNumber = monthNumber =>
  moment()
    .month(monthNumber - 1)
    .format('MMMM');

export const getBillingPeriod = (firstDayInSchedule, scheduleCycle, dateFormat) => {
  if (!scheduleCycle) {
    return {
      start: moment(firstDayInSchedule).startOf('month').format(dateFormat),
      end: moment(firstDayInSchedule).endOf('month').format(dateFormat),
    };
  }
  const billingCycle = moment(`${scheduleCycle.year}-${scheduleCycle.month}`).startOf('month');
  const isCycleAfterFirstDay = moment(billingCycle).isAfter(moment(firstDayInSchedule));
  if (isCycleAfterFirstDay) {
    const start = moment(billingCycle).format(dateFormat);
    const end = moment(billingCycle)
      .add(scheduleCycle.duration - 1, 'months')
      .endOf('month')
      .format(dateFormat);
    return {
      start,
      end,
    };
  }
  if (scheduleCycle.duration === 1) {
    return {
      start: moment(firstDayInSchedule).startOf('month').format(dateFormat),
      end: moment(firstDayInSchedule)
        .endOf('month')
        .add(scheduleCycle.duration - 1, 'months')
        .endOf('month')
        .format(dateFormat),
    };
  }
  const monthDifference = Math.abs(moment(billingCycle).diff(firstDayInSchedule, 'months'));
  if (monthDifference < scheduleCycle.duration) {
    return {
      start: moment(billingCycle).format(dateFormat),
      end: moment(billingCycle)
        .add(scheduleCycle.duration - 1, 'months')
        .endOf('month')
        .format(dateFormat),
    };
  }
  const start = moment(firstDayInSchedule)
    .subtract(monthDifference % scheduleCycle.duration, 'months')
    .startOf('month')
    .format(dateFormat);
  return {
    start,
    end: moment(start)
      .add(scheduleCycle.duration - 1, 'months')
      .endOf('month')
      .format(dateFormat),
  };
};

export const getBillingWeek = (firstDayInSchedule, scheduleCycle, dateFormat) => {
  const currentBillingPeriod = getBillingPeriod(firstDayInSchedule, scheduleCycle).start;
  const diffDaysFromBillingPeriod = Math.abs(moment(currentBillingPeriod).diff(firstDayInSchedule, 'days'));
  const isCycleAfterFirstDay = moment(currentBillingPeriod).isAfter(moment(firstDayInSchedule));

  if (isCycleAfterFirstDay) {
    return { firstDayOfBillingWeek: null, lastDayOfBillingWeek: null };
  }
  if (diffDaysFromBillingPeriod <= 6) {
    return {
      firstDayOfBillingWeek: moment(currentBillingPeriod).format(dateFormat),
      lastDayOfBillingWeek: moment(currentBillingPeriod).add(6, 'days').format(dateFormat),
    };
  }
  return {
    firstDayOfBillingWeek: moment(currentBillingPeriod)
      .add(diffDaysFromBillingPeriod - (diffDaysFromBillingPeriod % 7), 'days')
      .format(dateFormat),
    lastDayOfBillingWeek: moment(currentBillingPeriod)
      .add(diffDaysFromBillingPeriod - (diffDaysFromBillingPeriod % 7) + 6, 'days')
      .format(dateFormat),
  };
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  const now = moment(startDate).clone();
  const dates = [];
  while (now.isSameOrBefore(moment(endDate))) {
    dates.push(now.format('YYYY/MM/DD'));
    now.add(1, 'days');
  }
  return dates;
};

export const getFirstDayOfWeekAsMonday = date => (moment(date).format('d') === '0' ? '7' : moment(date).format('d'));

export const getNumOfDaysInMonth = date => moment(date).daysInMonth();

export const extractDataForSpecificMonth = (monthDate, data) =>
  Object.entries(data).reduce((agg, [key, value]) => {
    if (moment(key).isSame(moment(monthDate), 'month')) return { ...agg, [key]: value };
    return { ...agg };
  }, {});

export const generateArrOfWeeksInMonth = (monthDate, data) => {
  const firstDayOfWeek = getFirstDayOfWeekAsMonday(monthDate);
  const numOfDays = getNumOfDaysInMonth(monthDate);
  const blanks = [];
  for (let i = 1; i < firstDayOfWeek; i++) {
    blanks.push({ dayNum: '' });
  }

  const daysInMonth = [];

  for (let i = 0; i < numOfDays; i++) {
    const dayDate = moment(monthDate).add(i !== 0 ? i : 0, 'days');
    daysInMonth.push({ day: i + 1, data: data[dayDate.format('YYYY-MM-DD')] });
  }
  const totalSlots = [...blanks, ...daysInMonth];
  const weeks = [];
  let dayCells = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      dayCells.push(row);
    } else {
      weeks.push(dayCells);
      dayCells = [];
      dayCells.push(row);
    }
    if (i === totalSlots.length - 1) {
      weeks.push(dayCells);
    }
  });
  return weeks;
};

export const getMinMaxDatesIncludedHireAndReleaseDate = (minDate, maxDate) => ({
  min: minDate || undefined,
  max: maxDate || undefined,
});
export const changeTimeHour = (timeString, newHour) => {
  if (!newHour) return EMPTY_HOUR;
  const [date] = timeString.split(' ');
  let [newHours, newMinutes] = newHour.split(':');

  if (newHours === '24') newHours = '00';
  if (newHours > '23') newHours = '23';
  if (newMinutes > '59') newMinutes = '59';

  const newTime = `${newHours.padStart(2, '0')}:${newMinutes}:00`;
  return `${date} ${newTime}`;
};
export const isHourFulfilled = hour => !hour.includes('_');

export const getNumberOfWeekday = date => moment(date).format('d');

export const getHoursFromTimeRange = timeRange => {
  const [startTime, endTime] = timeRange.split('-');

  const startHour = parseInt(startTime.split(':')[0], 10);

  let endHour = parseInt(endTime.split(':')[0], 10);
  const endMinute = parseInt(endTime.split(':')[1], 10);

  if (endHour === 0 && endMinute === 0) endHour = 23;
  else if (endMinute === 0) endHour--;

  if (endHour === 24) endHour = 0;
  const hours = [];

  let currentHour = startHour === 24 ? 0 : startHour;

  while (currentHour !== endHour) {
    hours.push(currentHour);
    currentHour += 1;

    if (currentHour === 24) {
      currentHour = 0;
    }
  }

  hours.push(endHour);

  return hours.map(hour => `${hour < 10 ? '0' : ''}${hour}:00`);
};

export const getDatesRangeWithDayBeforeAndAfterFromDate = date => ({
  from: moment(date).subtract(1, 'day').format('YYYY-MM-DD'),
  to: moment(date).add(1, 'day').format('YYYY-MM-DD'),
});

export const getDatesRangeWithDayBeforeAndAfterFromDates = (from, to) => ({
  from: moment(from).subtract(1, 'day').format('YYYY-MM-DD'),
  to: moment(to).add(1, 'day').format('YYYY-MM-DD'),
});

export const calculateMinutesFromMidnight = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  const dateMoment = moment(date, format);
  const midnightMoment = moment(dateMoment).startOf('day');
  return dateMoment.diff(midnightMoment, 'minutes');
};
export const getDiff = (start, end, unit, returnFloat = false) => moment(end).diff(moment(start), unit, returnFloat);

export const sortDateByDateAndStartTimestamp = data =>
  data.sort((a, b) => {
    const dateA = moment(a.date).format('YYYY-MM-DD');
    const dateB = moment(b.date).format('YYYY-MM-DD');

    if (dateA === dateB) {
      const startTimeA = moment(a.start_timestamp).format('HH:mm');
      const startTimeB = moment(b.start_timestamp).format('HH:mm');
      return startTimeA.localeCompare(startTimeB);
    }

    return dateA.localeCompare(dateB);
  });

export const sortByDateAndAbsenceHours = data =>
  data.sort((a, b) => {
    const dateA = a.from;
    const dateB = b.from;
    if (dateA === dateB) {
      if (!a.absence_hours && !b.absence_hours) return 0;
      if (!a.absence_hours) return 1;
      if (!b.absence_hours) return -1;
      const beginTimeA = a.absence_hours.split('-')[0];
      const beginTimeB = b.absence_hours.split('-')[0];
      return beginTimeA.localeCompare(beginTimeB);
    }

    return dateA.localeCompare(dateB);
  });

export const getPreviousPeriod = (from, to) => {
  const fromDate = moment(from, 'YYYY-MM-DD');
  const toDate = moment(to, 'YYYY-MM-DD');

  const isFullMonthsPeriod =
    fromDate.isSame(fromDate.clone().startOf('month'), 'day') && toDate.isSame(toDate.clone().endOf('month'), 'day');

  if (isFullMonthsPeriod) {
    const diffMonths = toDate.diff(fromDate, 'months');

    const previousFromDate = fromDate
      .clone()
      .subtract(diffMonths + 1, 'months')
      .format('YYYY-MM-DD');

    const previousToDate = fromDate.clone().subtract(1, 'days').endOf('month');

    return {
      previousFrom: previousFromDate,
      previousTo: previousToDate.format('YYYY-MM-DD'),
    };
  }
  const diffDays = toDate.diff(fromDate, 'days');

  const previousToDate = fromDate.clone().subtract(1, 'days');
  const previousFromDate = previousToDate.clone().subtract(diffDays, 'days');

  return {
    previousFrom: previousFromDate.format('YYYY-MM-DD'),
    previousTo: previousToDate.format('YYYY-MM-DD'),
  };
};

export const getDisabledDates = (
  userEmployees,
  selectedEmployee,
  selectedLocation,
  minDate,
  maxDate,
  loanEmployeesProposalsAssignments,
  openShiftsAssignments,
) => {
  const relatedEmployee = userEmployees.find(employee => employee.id === selectedEmployee);
  if (relatedEmployee && relatedEmployee.locations.some(location => location.id === selectedLocation)) return [];
  const dateArray = getRangeBetweenDates(minDate, maxDate);
  const loanEmployeesProposalsForSelectedEmployeeAndLocation = loanEmployeesProposalsAssignments.filter(
    assignment => assignment.location_id === selectedLocation && assignment.employee_id === selectedEmployee,
  );

  return dateArray.reduce((agg, dateFromDateArray) => {
    if (
      loanEmployeesProposalsForSelectedEmployeeAndLocation.some(
        assignment => moment(assignment.start_timestamp).format('YYYY-MM-DD') === dateFromDateArray,
      ) ||
      openShiftsAssignments?.some(
        assignment => moment(assignment.start_timestamp).format('YYYY-MM-DD') === dateFromDateArray,
      )
    )
      return agg;
    return [...agg, dateFromDateArray];
  }, []);
};

export const getFirstDateThatIsNotDisabledFromRange = (minDate, maxDate, disabledDates) => {
  const range = getRangeBetweenDates(minDate, maxDate);
  return range.find(date => !disabledDates.includes(date));
};
