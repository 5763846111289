import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from '@/components/common/Basic/Button';
import { BUTTON_BAR_TOOLTIP_OFFSET } from '@/components/common/Basic/ButtonBar/ButtonBar.utils';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { useAppDispatch } from '@/redux-store';
import { addShiftsForAttendances } from '@/redux-store/attendances/attendancesData';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { SubRowType } from '../../../AttendanceManyDaysView/Table/AttendanceManyDaysTable.types';
import { messages } from '../../AttendanceButtonBar.messages';

type Props = {
  data: SubRowType['attendanceObject'][];
};

export const AddShiftButton = ({ data }: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const shifts = useMemo(() => data.filter(({ id }) => Boolean(id)), [data]);

  const onClick = useCallback(() => {
    dispatch(addShiftsForAttendances(shifts));
  }, [dispatch, shifts]);

  return (
    <Tooltip content={intl.formatMessage(messages.addShift)} yOffset={BUTTON_BAR_TOOLTIP_OFFSET}>
      <Button disabled={isEmptyArray(shifts)} onClick={onClick} modifiers="reverse-blue onlyIcon">
        <i className="material-icons">check</i>
      </Button>
    </Tooltip>
  );
};
