import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MDImportBox from '../../MDImportBox.jsx';
import MDFileDropInput from './MDFileDropInput.jsx';

const MDFileDrop = props => {
  const { messages, isFileImported, handleImportData, fileFormats = ['xlsx'], hideButton, onUpload } = props;

  return isFileImported ? (
    <MDImportBox messages={messages} />
  ) : (
    <MDFileDropInput
      confirmText={<FormattedMessage id="import" defaultMessage="Importuj" />}
      onChange={handleImportData}
      accept={fileFormats}
      hideButton={hideButton}
      onUpload={onUpload}
    />
  );
};

MDFileDrop.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ),
  isFileImported: PropTypes.bool,
  handleImportData: PropTypes.func,
  fileFormats: PropTypes.arrayOf(PropTypes.string),
  hideButton: PropTypes.bool,
  onUpload: PropTypes.func,
};

export default MDFileDrop;
