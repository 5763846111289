import { endOfMonth, startOfMonth } from 'date-fns';
import { MutableRefObject, useCallback, useMemo } from 'react';

import { KadroCalendarMultiple, KadroCalendarRange } from '@/components/common/KadroCalendars';
import Popover from '@/components/common/Popover/Popover';
import { payrollLocationModesIds } from '@/constants/viewDateModes';
import { convertDateToStandardFormat } from '@/utils/dateHelper';
import { checkIsOnlyMonthMode, splitDate } from '@/utils/dates/dates.helpers';

import { MonthPickerContent } from '../../MDComponents';
import { KadroCalendarPopoverInputProps } from '../KadroCalendarPopoverInput';

type Props = {
  changeDate: KadroCalendarPopoverInputProps['changeDate'];
  customDate: KadroCalendarPopoverInputProps['customDate'];
  hidePicker: KadroCalendarPopoverInputProps['hidePicker'];
  mainDateStore: KadroCalendarPopoverInputProps['mainDateStore'];
  maxDate: KadroCalendarPopoverInputProps['maxDate'];
  minDate: KadroCalendarPopoverInputProps['minDate'];
  modes: KadroCalendarPopoverInputProps['modes'];
  onRangeChange: KadroCalendarPopoverInputProps['onRangeChange'];
  popoverRef: MutableRefObject<Popover>;
  selectedRange: KadroCalendarPopoverInputProps['selectedRange'];
  singleDay: KadroCalendarPopoverInputProps['singleDay'];
};

export const CalendarPopoverContent = ({
  singleDay,
  customDate,
  popoverRef,
  onRangeChange,
  changeDate: changeDateCallback,
  selectedRange,
  hidePicker,
  mainDateStore,
  modes,
  minDate,
  maxDate,
}: Props) => {
  const relevantModes = useMemo(() => modes || payrollLocationModesIds, [modes]);
  const onCalendarChange: Props['onRangeChange'] = useCallback(
    e => {
      popoverRef.current.hide();
      onRangeChange(e);
    },
    [onRangeChange, popoverRef],
  );

  const changeDate: Props['changeDate'] = useCallback(
    date => {
      const formattedDate = convertDateToStandardFormat(date);
      popoverRef.current.hide();
      changeDateCallback(formattedDate);
    },
    [changeDateCallback, popoverRef],
  );

  const changeMonth = useCallback(
    (month: number) => {
      const start = startOfMonth(new Date(selectedRange.start).setMonth(month - 1));
      const end = endOfMonth(start);
      onRangeChange({ start: convertDateToStandardFormat(start), end: convertDateToStandardFormat(end) });
    },
    [onRangeChange, selectedRange],
  );

  const changeYear = useCallback(
    (diff: number) => {
      const { year: prevYearFrom } = splitDate(selectedRange.start);
      const { year: prevYearTo } = splitDate(selectedRange.end);
      const start = new Date(selectedRange.start).setFullYear(prevYearFrom + diff);
      const end = new Date(selectedRange.end).setFullYear(prevYearTo + diff);
      onRangeChange({ start: convertDateToStandardFormat(start), end: convertDateToStandardFormat(end) });
    },
    [onRangeChange, selectedRange],
  );

  if (singleDay) return <KadroCalendarMultiple onChange={changeDate} selected={[customDate]} />;

  if (checkIsOnlyMonthMode(modes)) {
    const { year, month } = splitDate(selectedRange.start);
    return <MonthPickerContent {...{ month: month + 1, year, changeMonth, changeYear }} />;
  }

  return (
    <KadroCalendarRange
      onChange={onCalendarChange}
      selectedRange={selectedRange}
      hidePicker={hidePicker}
      minDate={minDate}
      maxDate={maxDate}
      modes={relevantModes}
      mainDateStore={mainDateStore}
    />
  );
};
