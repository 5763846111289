import { CellContext } from '@tanstack/react-table';
import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useMemo } from 'react';

import { addBonusToAttendance } from '@/actions/attendances';
import { setBonusValues } from '@/actions/payrollUI';
import TextInput from '@/components/common/inputs/TextInput';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { PAYROLL_MODES } from '@/constants/payrollSettings';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectOpenShiftsAssignments } from '@/redux-store/openShiftsAssignments/openShiftsAssignments.selectors';
import { selectPayrollMode } from '@/redux-store/payroll';
import { selectPayoutSettingType } from '@/redux-store/payrollSettings';
import { selectBonusValuesForEmployee } from '@/redux-store/payrollUI';
import { selectSingleEmployeeIdFilter } from '@/redux-store/singleEmployeeFilter';
import { selectCurrentUserLocations } from '@/selectors/currentUser.selectors';
import { isDefined } from '@/utils/baseHelpers';

import { PayrollRow } from '../../PayrollTable.types';

type Props = CellContext<TableRow<PayrollRow>, unknown>;

export const PayrollBonusCell = (props: Props) => {
  const { attendanceId, bonusAmount, from, locationName, matchingShiftFrom, matchingShiftTo } = props.cell.row.original;
  const dispatch = useAppDispatch();
  const filteredEmployeeId = useAppSelector(selectSingleEmployeeIdFilter);
  const openShiftsAssignments = useAppSelector(selectOpenShiftsAssignments(filteredEmployeeId));
  const payrollMode = useAppSelector(selectPayrollMode);
  const payoutSettingType = useAppSelector(selectPayoutSettingType);
  const bonusValues = useAppSelector(selectBonusValuesForEmployee(filteredEmployeeId));

  const isSameAsOpenShiftAssignments = useMemo(
    () =>
      (openShiftsAssignments || []).some(assignment => {
        const formattedStart = moment(assignment.start_timestamp).utc().format('YYYY-MM-DD HH:mm:ss');
        const formattedEnd = moment(assignment.end_timestamp).utc().format('YYYY-MM-DD HH:mm:ss');
        return formattedStart === matchingShiftFrom && formattedEnd === matchingShiftTo;
      }),
    [openShiftsAssignments, matchingShiftFrom, matchingShiftTo],
  );
  const currentUserLocations = useAppSelector(selectCurrentUserLocations);
  const currentUserLocationsNames = useMemo(() => currentUserLocations.map(({ name }) => name), [currentUserLocations]);

  const disabled =
    !attendanceId ||
    payoutSettingType === 'shifts' ||
    payrollMode !== PAYROLL_MODES.editable ||
    (isSameAsOpenShiftAssignments && !currentUserLocationsNames.includes(locationName));

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const attendance = { id: attendanceId, start_timestamp: from };
      dispatch(addBonusToAttendance(attendance, +e.target.value * 100, filteredEmployeeId));
    },
    [attendanceId, from, dispatch, filteredEmployeeId],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setBonusValues(attendanceId, e.target.value, filteredEmployeeId));
    },
    [attendanceId, dispatch, filteredEmployeeId],
  );

  return (
    <TextInput
      onBlur={onBlur}
      type="number"
      className={classNames('k-payrollTable__bonusInput', { 'k-payrollTable__bonusInput--disabled': disabled })}
      value={isDefined(bonusValues[attendanceId]) ? bonusValues[attendanceId] : bonusAmount}
      disabled={disabled}
      onChange={onChange}
      isPositive={false}
    />
  );
};
