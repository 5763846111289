import { Table } from '@tanstack/react-table';

import { CHECKBOX_COLUMN_WIDTH } from '@/constants/tables';
import { isEmptyArray } from '@/utils/array/array.helpers';

import {
  ColumnStaticAccessorEnum,
  MDTableProps,
  TABLE_COLUMN_STICKY_ENUM,
  TableColumnType,
  TableRow,
} from '../../MDTable.types';
import CheckboxCell from './CheckboxCell';
import { CheckboxCellHeader } from './CheckboxCellHeader';

const customGetToggleAllRowsSelectedHandler =
  <T extends object>(table: Table<TableRow<T>>) =>
  e => {
    const isChecked = e.target.checked;
    table.toggleAllRowsSelected(isChecked);
    table.getCoreRowModel().rows.forEach(row => {
      row.toggleSelected(isChecked);
      if (row.subRows) row.subRows.forEach(subRow => subRow.toggleSelected(isChecked));
    });
  };

const checkAreAllRowsSelected = <T extends object>(table: Table<TableRow<T>>) => {
  if (table.getCanSomeRowsExpand())
    return !isEmptyArray(table.getRowModel().rows) && table.getRowModel().rows.every(row => row.getIsSelected());
  return table.getIsAllRowsSelected();
};

export const getCheckboxColumn = <T extends object>(
  customOnSelectHandler: MDTableProps<T>['customOnSelectHandler'],
): TableColumnType<T> => ({
  accessorKey: ColumnStaticAccessorEnum.CHECKBOX,
  header: ({ table }) => (
    <CheckboxCellHeader
      checked={checkAreAllRowsSelected(table)}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={customGetToggleAllRowsSelectedHandler(table)}
    />
  ),
  cell: ({ row, table }) => (
    <CheckboxCell
      checked={'subRows' in row.original ? row.getIsAllSubRowsSelected() : row.getIsSelected()}
      disabled={!row.getCanSelect()}
      indeterminate={row.getIsSomeSelected()}
      onChange={row.getToggleSelectedHandler()}
      onCustomChange={customOnSelectHandler ? () => customOnSelectHandler(row, table.getRowModel().rows) : undefined}
    />
  ),
  enableColumnFilter: false,
  enableSorting: false,
  maxSize: CHECKBOX_COLUMN_WIDTH,
  sticky: TABLE_COLUMN_STICKY_ENUM.LEFT,
  customSize: true,
});
