import PropTypes from 'prop-types';

import './MDTextArea.scss';

const MDTextArea = props => (
  <div className="kmd-textArea">
    <label className="kmd-textArea__label" htmlFor={props.id}>
      {props.label}
    </label>
    <textarea
      className="kmd-textArea__textArea"
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      id={props.id}
      name={props.name}
      rows="4"
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
    <div className="kmd-textArea__error">{props.errorMessage}</div>
  </div>
);

MDTextArea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MDTextArea;
