import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  attendancesTableAlertsNoShift: {
    id: 'attendancesTable.alerts.noShift',
    defaultMessage: 'Brak zaplanowanej zmiany w grafiku ',
  },
  attendancesTableAlertsAbsenceWithAttendance: {
    id: 'attendancesTable.alerts.absenceWithAttendance',
    defaultMessage: 'Praca podczas planowanej nieobecności ',
  },
  attendancesTableAlertsLongAttendance: {
    id: 'attendancesTable.alerts.longAttendance',
    defaultMessage: 'Długa obecność ',
  },
  attendancesTableAlertsMoreTime: {
    id: 'attendancesTable.alerts.moreTime',
    defaultMessage: 'Zatwierdzono nadgodziny {hours}',
  },
  wrongLocation: {
    id: 'attendancesTable.alerts.wrongLocation',
    defaultMessage: 'Pracownik był poza wyznaczonym promieniem podczas rejestracji obecności',
  },
  and: {
    id: 'and',
    defaultMessage: 'i',
  },
});
