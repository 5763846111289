import classNames from 'classnames';
import { PropTypes } from 'prop-types';

import Icon from '@/components/common/Basic/Icon/Icon';
import { SPECIAL_CHARACTERS_PATTERN } from '@/constants/regexPatterns.ts';
import { DIRECTIONS } from '@/constants/ui.ts';

import Tooltip from '../../Tooltip/Tooltip.jsx';
import MDInputTooltip from '../MDInputTooltip/MDInputTooltip.jsx';
import Toggle from '../Toggle.jsx';

const kadroIconType = 'kadro';

const MDTextInput = props => {
  let modifiers = props.modifiers || [];
  if (!Array.isArray(modifiers)) {
    modifiers = modifiers.split(' ');
  }

  const className = classNames(
    'kmd-textInput',
    props.className,
    ...modifiers.map(modifier => `kmd-textInput--${modifier}`),
    {
      'kmd-textInput--valid': props.value && !props.errorMessage,
      'kmd-textInput--error': props.errorMessage,
      'kmd-textInput--readOnly': props.readOnly,
    },
  );

  const inputContainerClassName = classNames('kmd-textInput__inputContainer', {
    'kmd-textInput__inputContainer--disabled': props.disabled,
    'kmd-textInput__inputContainer--borderTop': props.borderTop,
  });

  const iconClassname =
    (props.iconType || kadroIconType) === 'kadro'
      ? classNames('k-icon', props.icon, {
          'k-icon--clicky': !!props.onIconClick,
        })
      : '';

  const validateNumberInput = event => {
    const { type, isNumberPositive = true, isNumberInteger } = props;
    if (type !== 'number') return;
    const dynamicInvalidCharacters = [
      { condition: isNumberInteger, character: '.' },
      { condition: isNumberPositive, character: '-' },
    ];
    const invalidCharacters = dynamicInvalidCharacters.reduce(
      (invalidChars, el) => (el.condition ? [...invalidChars, el.character] : invalidChars),
      ['+', 'e', 'E'],
    );
    if (invalidCharacters.includes(event.key)) {
      event.preventDefault();
    }
  };

  const isNumberBiggerThanMax = (event, maxValue) => parseFloat(event.target.value) > maxValue;

  const isNumberLowerThanMin = (event, minValue) => event.target.value && parseFloat(event.target.value) < minValue;

  const isCurrentNumberNegative = event => parseFloat(event.target.value) < 0;

  const handleChange = event => {
    const {
      type,
      maxValue = 1000000,
      minValue = Number.MIN_SAFE_INTEGER,
      isNumberPositive = true,
      onChange,
      preventSpecialCharacters,
    } = props;

    if (preventSpecialCharacters && SPECIAL_CHARACTERS_PATTERN.test(event.target.value)) return;
    if (
      type === 'number' &&
      (isNumberLowerThanMin(event, minValue) ||
        isNumberBiggerThanMax(event, maxValue) ||
        (isNumberPositive && isCurrentNumberNegative(event)))
    ) {
      return;
    }
    onChange(event);
  };

  const labelLength = props.label?.length || 0;

  const inputTooltipContent = props.value?.length >= props.maxInputLength ? props.value : '';
  const showLabelTootlip = labelLength > props.maxLabelLength;
  const position = showLabelTootlip ? DIRECTIONS.BOTTOM : DIRECTIONS.TOP;
  const yOffset = showLabelTootlip ? -11 : props.withToogle ? 18 : 21;

  return (
    <div className={className}>
      <div className={inputContainerClassName}>
        {inputTooltipContent ? (
          <Tooltip
            position={position}
            wrapperClassName="kmd-textInput__inputTooltip"
            content={inputTooltipContent}
            yOffset={yOffset}
            className={props.tooltipClassName}
          >
            <input
              className="kmd-textInput__input"
              type={props.type}
              value={props.value}
              onChange={handleChange}
              onKeyDown={validateNumberInput}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              style={props.style}
              id={props.id}
              name={props.id}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder}
              disabled={props.disabled || props.readOnly}
              required={props.required}
              autoComplete={props.autoComplete}
              data-test={props.testId && `${props.testId}-input`}
            />
          </Tooltip>
        ) : (
          <input
            className="kmd-textInput__input"
            type={props.type}
            value={props.value}
            onChange={handleChange}
            onKeyDown={validateNumberInput}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            style={props.style}
            id={props.id}
            name={props.id}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            disabled={props.disabled || props.readOnly}
            required={props.required}
            autoComplete={props.autoComplete}
            data-test={props.testId && `${props.testId}-input`}
          />
        )}
        {showLabelTootlip ? (
          <Tooltip content={props.label} yOffset={12} xOffset={7} className={props.tooltipClassName}>
            <label>{props.label}</label>
          </Tooltip>
        ) : (
          <label>{props.label}</label>
        )}
        <span className="kmd-textInput__icons">
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              if (props.onIconClick) props.onIconClick();
            }}
            className="kmd-textInput__icon"
            tabIndex={-1}
          >
            <Icon
              className={iconClassname}
              type={props.iconType || kadroIconType}
              style={props.iconStyle}
              name={props.icon}
            />
          </button>
          <MDInputTooltip inputValue={props.value} content={props.tooltip} />
        </span>
        {props.withToogle && <Toggle modifiers="small" value={props.toggleValue} toggleInInput disabled />}
      </div>

      {props.disabled ? null : <span className="kmd-textInput__bar" />}
      <div className="kmd-textInput__error">{props.errorMessage}</div>
    </div>
  );
};

MDTextInput.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({}),
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  icon: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onIconClick: PropTypes.func,
  disabled: PropTypes.bool,
  isNumberPositive: PropTypes.bool,
  isNumberInteger: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  iconStyle: PropTypes.shape({}),
  autoComplete: PropTypes.string,
  tooltip: PropTypes.string,
  testId: PropTypes.string,
  iconType: PropTypes.string,
  preventSpecialCharacters: PropTypes.bool,
  readOnly: PropTypes.bool,
  tooltipClassName: PropTypes.string,
  borderTop: PropTypes.bool,
  maxLabelLength: PropTypes.number,
};

export default MDTextInput;
