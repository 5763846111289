import { useMemo } from 'react';

import {
  ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE,
  ATTENDANCE_EDIT_OTHERS_DISABLE,
  MANAGER_EDIT_OWN_ATTENDANCES,
} from '@/constants/Restrictions';
import { useAppSelector } from '@/redux-store';
import { selectCompanyRoles } from '@/redux-store/roles/companyRoles';
import { selectLocationSetting } from '@/redux-store/settings/locationSettings';
import { selectRestrictionsDict } from '@/redux-store/userPermissions';
import { selectCurrentUser } from '@/selectors/currentUser.selectors';
import { getEmployeeRank } from '@/utils/userEmployeesHelpers';

import { SubRowType } from '../AttendanceManyDaysTable.types';

export const useEditAttendanceDisabled = ({
  attendanceObject,
  employeeObject,
  location,
}: Pick<SubRowType, 'attendanceObject' | 'employeeObject' | 'location'>) => {
  const locationSetting = useAppSelector(selectLocationSetting(location.id));
  const currentUser = useAppSelector(selectCurrentUser);
  const restrictionsDict = useAppSelector(selectRestrictionsDict);
  const companyRoles = useAppSelector(selectCompanyRoles);

  const isEditOwnAttendancesDisabled =
    restrictionsDict[MANAGER_EDIT_OWN_ATTENDANCES] && currentUser.id === employeeObject.id;

  const currentUserRank = useMemo(() => getEmployeeRank(currentUser, companyRoles), [currentUser, companyRoles]);

  const employeeRank = useMemo(() => getEmployeeRank(employeeObject, companyRoles), [employeeObject, companyRoles]);

  const isEditOtherAttendancesDisabled =
    currentUser.id !== employeeObject.id &&
    (restrictionsDict[ATTENDANCE_EDIT_OTHERS_DISABLE] ||
      (restrictionsDict[ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE] && employeeRank >= currentUserRank));

  const isRowDisabled = isEditOwnAttendancesDisabled || isEditOtherAttendancesDisabled;

  const isAttendanceEditDisabled =
    locationSetting.disable_location_attendances_edit_until >= attendanceObject.date || isRowDisabled;

  const isShiftEditDisabled =
    locationSetting.disable_location_schedule_shifts_edit_until >= attendanceObject.date || isRowDisabled;

  return { isRowDisabled, isAttendanceEditDisabled, isShiftEditDisabled };
};
