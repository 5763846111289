import moment from 'moment';
import { IntlShape } from 'react-intl';
import { v4 } from 'uuid';

import { emplMassEditActions } from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { Employee } from '@/types';
import { Contract } from '@/types/contracts.types.ts';
import { mergeTwoArraysByKeyAndRemoveDuplicates } from '@/utils/arrayHelpers';
import { getRelevantContractForDate } from '@/utils/contracts';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers';

import { getContractLabel } from '../../EmployeeModal/EmployeeModalContracts/EmployeeModalContracts.helpers';
import { FieldValue } from '../EmployeesEditMassValueField.helpers';

export interface NewContract {
  id: string;
  start_date: string;
  job_titles?: { job_title_id: string; wage?: number }[];
  isTemp: boolean;
  employee_id: string;
}

interface ContractsForSelectedEmployees {
  id: string;
  name: string;
  lastName: string;
  contracts: Contract[];
}

const compareContracts = (a: Pick<Contract, 'start_date'>, b: Pick<Contract, 'start_date'>) => {
  if (!a.start_date) {
    return -1;
  }
  if (!b.start_date) {
    return 1;
  }
  return a.start_date > b.start_date ? 1 : -1;
};

const calculateEndDate = (contract: NewContract | Contract, nextContract?: NewContract | Contract) => {
  if (nextContract?.start_date && (contract.start_date || !contract.isTemp)) {
    return moment(nextContract.start_date).subtract(1, 'day').format('YYYY-MM-DD');
  }
  return null;
};

const getNewContractDescription = (contracts: NewContract[] | Contract[], tempId: string, intl: IntlShape) => {
  const sortedNewContract = contracts.sort((a, b) => compareContracts(a, b));
  const { start_date, isTemp } = sortedNewContract.find(({ id }) => id === tempId);

  return getContractLabel({ start_date, end_date: undefined, isTemp, showNoEndDate: true }, intl);
};

const getNewContracts = (
  fieldValue: FieldValue,
  employee: { id: string; contracts: Contract[] },
  tempId: string,
  jobTitles?: { job_title_id: string; wage?: number }[],
) => {
  const newContract: NewContract = {
    id: tempId,
    start_date: fieldValue.date,
    job_titles: jobTitles || [],
    isTemp: true,
    employee_id: employee.id,
  };

  const sortedContracts = [...employee.contracts, newContract].sort((a, b) => compareContracts(a, b));

  return sortedContracts.map((contract, i) => ({
    id: contract.id || tempId,
    start_date: contract.start_date,
    job_titles: contract.job_titles || [],
    isTemp: contract.isTemp || false,
    employee_id: contract.employee_id,
    end_date: calculateEndDate(contract, sortedContracts[i + 1]),
  }));
};

export const isInvalidContractForAdding = (contracts: Contract[], date: string) =>
  contracts.some(contract => {
    if (contract.start_date === null) {
      return false;
    }
    return contract.start_date === date;
  });

export const handleAddContracts = (
  contractsForSelectedEmployees: ContractsForSelectedEmployees[],
  fieldValue: FieldValue,
  intl,
) =>
  contractsForSelectedEmployees.reduce(
    (acc, employee) => {
      const hasInvalidContract = isInvalidContractForAdding(employee.contracts, fieldValue.date);

      if (hasInvalidContract) {
        const parsedEmployee = {
          label: getEmployeeNameShort({ first_name: employee.name, last_name: employee.lastName }, 17),
          value: employee.id,
        };
        acc.incorrectEmployees.push(parsedEmployee);
      } else {
        const tempId = v4();
        const jobTitlesForNewContracts = fieldValue?.assignJobTitlesAndWageForContract
          ? getRelevantContractForDate(employee.contracts, fieldValue.date).job_titles
          : fieldValue.jobTitles.map(j => ({
              job_title_id: j.job_title_id,
              wage: !Number.isNaN(Number(j.wage ?? undefined)) ? Number(j.wage) : undefined,
            }));

        const newContracts = getNewContracts(fieldValue, employee, tempId, jobTitlesForNewContracts);

        const parsedNewContract = getNewContractDescription(newContracts, tempId, intl);
        const parsedEmployee = {
          label: getEmployeeNameShort({ first_name: employee.name, last_name: employee.lastName }, 17),
          value: employee.id,
          description: parsedNewContract,
          contracts: newContracts,
        };
        acc.correctEmployees.push(parsedEmployee);
      }

      return acc;
    },
    { incorrectEmployees: [], correctEmployees: [], relatedContracts: [] },
  );

export const handleDeleteContracts = (
  contractsForSelectedEmployees: ContractsForSelectedEmployees[],
  fieldValue: FieldValue,
  intl: IntlShape,
) =>
  contractsForSelectedEmployees.reduce(
    (acc, employee) => {
      const contractToDelete = getRelevantContractForDate(employee.contracts, fieldValue.date);
      if (employee.contracts.length <= 1 || !contractToDelete.start_date) {
        const parsedEmployee = {
          label: getEmployeeNameShort({ first_name: employee.name, last_name: employee.lastName }, 17),
          value: employee.id,
        };
        acc.incorrectEmployees.push(parsedEmployee);
      } else {
        const newContracts = employee.contracts.filter(contract => contract.id !== contractToDelete.id);
        const deletedContractDescription = getContractLabel(contractToDelete, intl);
        const parsedEmployee = {
          label: getEmployeeNameShort({ first_name: employee.name, last_name: employee.lastName }, 17),
          value: employee.id,
          contracts: newContracts,
          description: deletedContractDescription,
        };
        acc.correctEmployees.push(parsedEmployee);
        acc.relatedContracts.push(contractToDelete);
      }

      return acc;
    },
    { incorrectEmployees: [], correctEmployees: [], relatedContracts: [] },
  );

export const getContractsForSelectedEmployees = (
  selectedEmployees: Employee[],
  selectedEmployeesIds: string[],
  contracts: Contract[],
) =>
  selectedEmployees.reduce((acc, employee) => {
    if (selectedEmployeesIds.includes(employee.id)) {
      const contractForSelectedEmployee = {
        id: employee.id,
        name: employee.first_name,
        lastName: employee.last_name,
        contracts: contracts[employee.id] || [],
      };
      acc.push(contractForSelectedEmployee);
    }
    return acc;
  }, []);

export const handleAddContractsJobTitles = (
  contractsForSelectedEmployees: ContractsForSelectedEmployees[],
  fieldValue: FieldValue,
  intl: IntlShape,
) =>
  contractsForSelectedEmployees.reduce(
    (acc, employee) => {
      const contractToAddJobTitle = getRelevantContractForDate(employee.contracts, fieldValue.date);
      if (contractToAddJobTitle) {
        const contractToAddJobTitleDescription = getContractLabel(contractToAddJobTitle, intl);
        const jobTitlesForNewContracts = fieldValue.jobTitles;
        const contractToAddJobTitleWithNewJobTitles = {
          ...contractToAddJobTitle,
          job_titles: mergeTwoArraysByKeyAndRemoveDuplicates(
            jobTitlesForNewContracts,
            contractToAddJobTitle.job_titles,
            'job_title_id',
          ),
        };
        const parsedEmployee = {
          label: getEmployeeNameShort({ first_name: employee.name, last_name: employee.lastName }, 17),
          value: employee.id,
          description: contractToAddJobTitleDescription,
        };
        acc.correctEmployees.push(parsedEmployee);
        acc.relatedContracts.push(contractToAddJobTitleWithNewJobTitles);
      }
      return acc;
    },
    { incorrectEmployees: [], correctEmployees: [], relatedContracts: [] },
  );

export const handleRemoveContractsJobTitles = (
  contractsForSelectedEmployees: ContractsForSelectedEmployees[],
  fieldValue: FieldValue,
  intl: IntlShape,
) =>
  contractsForSelectedEmployees.reduce(
    (acc, employee) => {
      const contractToRemoveJobTitles = getRelevantContractForDate(employee.contracts, fieldValue.date);
      if (contractToRemoveJobTitles) {
        const contractToRemoveJobTitlesDescription = getContractLabel(contractToRemoveJobTitles, intl);
        const jobTitlesToRemoveFromContract = fieldValue.jobTitles.map(jobTitle => jobTitle.job_title_id);
        const contractWithRemovedJobTitles = {
          ...contractToRemoveJobTitles,
          job_titles: contractToRemoveJobTitles.job_titles.filter(
            jobTitle => !jobTitlesToRemoveFromContract.includes(jobTitle.job_title_id),
          ),
        };

        const parsedEmployee = {
          label: getEmployeeNameShort({ first_name: employee.name, last_name: employee.lastName }, 17),
          value: employee.id,
          description: contractToRemoveJobTitlesDescription,
        };
        acc.correctEmployees.push(parsedEmployee);
        acc.relatedContracts.push(contractWithRemovedJobTitles);
      }
      return acc;
    },
    { incorrectEmployees: [], correctEmployees: [], relatedContracts: [] },
  );

export const handleEditContractsStartDate = (
  contractsForSelectedEmployees: ContractsForSelectedEmployees[],
  fieldValue: FieldValue,
  intl: IntlShape,
) =>
  contractsForSelectedEmployees.reduce(
    (acc, employee) => {
      const hasInvalidContract = isInvalidContractForAdding(employee.contracts, fieldValue.newStartContractDate);
      if (employee.contracts.length <= 1 || hasInvalidContract) {
        const parsedEmployee = {
          label: getEmployeeNameShort({ first_name: employee.name, last_name: employee.lastName }, 17),
          value: employee.id,
        };
        acc.incorrectEmployees.push(parsedEmployee);
      } else {
        const contractToUpdate = getRelevantContractForDate(employee.contracts, fieldValue.date);
        const contractToUpdateDescription = getContractLabel(contractToUpdate, intl);
        const parsedEmployee = {
          label: getEmployeeNameShort({ first_name: employee.name, last_name: employee.lastName }, 17),
          value: employee.id,
          description: contractToUpdateDescription,
        };
        const updatedContract = {
          ...contractToUpdate,
          start_date: fieldValue.newStartContractDate,
        };
        acc.correctEmployees.push(parsedEmployee);
        acc.relatedContracts.push(updatedContract);
      }

      return acc;
    },
    { incorrectEmployees: [], correctEmployees: [], relatedContracts: [] },
  );

export const getRelevantAndIncorrectEmployee = (
  contractsForSelectedEmployees: ContractsForSelectedEmployees[],
  fieldValue: FieldValue,
  intl: IntlShape,
  actionType: string,
) => {
  switch (actionType) {
    case emplMassEditActions.addContract:
      return handleAddContracts(contractsForSelectedEmployees, fieldValue, intl);
    case emplMassEditActions.remove:
      return handleDeleteContracts(contractsForSelectedEmployees, fieldValue, intl);
    case emplMassEditActions.addContractsJobTitles:
      return handleAddContractsJobTitles(contractsForSelectedEmployees, fieldValue, intl);
    case emplMassEditActions.removeContractsJobTitles:
      return handleRemoveContractsJobTitles(contractsForSelectedEmployees, fieldValue, intl);
    case emplMassEditActions.editContractsStartDate:
      return handleEditContractsStartDate(contractsForSelectedEmployees, fieldValue, intl);
    default:
      return null;
  }
};
