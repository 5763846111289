import { IntlShape } from 'react-intl';

import { SortingFnEnum, TABLE_COLUMN_STICKY_ENUM, TableColumnType } from '@/components/common/MDTable/MDTable.types';

import { messages } from './EmployeeTimesheetTable.messages';

export const getColumns = ({ formatMessage }: IntlShape): TableColumnType<object>[] => [
  {
    header: formatMessage(messages.date),
    accessorKey: 'date',
    sticky: TABLE_COLUMN_STICKY_ENUM.LEFT,
  },
  {
    header: formatMessage(messages.location),
    accessorKey: 'locationName',
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
  },
  {
    header: formatMessage(messages.from),
    accessorKey: 'startHour',
  },
  {
    header: formatMessage(messages.to),
    accessorKey: 'endHour',
  },
  {
    header: formatMessage(messages.jobTitle),
    accessorKey: 'jobTitleName',
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
  },
  {
    header: formatMessage(messages.absence),
    accessorKey: 'absenceInfo',
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
  },
  {
    header: formatMessage(messages.duration),
    accessorKey: 'durationTime',
  },
];
