import { IntlShape } from 'react-intl';

import { mapId } from '@/utils/array/array.helpers';
import { transformToBoolDict } from '@/utils/objectHelpers/objectHelpers';

import { getColumns } from '../../AttendanceManyDaysTable.utils';

export const getColumnsForFilter = (intl: IntlShape) =>
  getColumns(intl).reduce(
    (p, column) => (column.cannotBeHidden ? p : [...p, { ...column, id: column.accessorKey }]),
    [],
  );

export const getInitialSelectedAttendanceColumnsForFilter = (intl: IntlShape) =>
  transformToBoolDict(mapId(getColumnsForFilter(intl)));
