import { Break, FormattedAttendance } from 'kadro-helpers/lib/types';
import moment from 'moment';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { endBreak, startAttendance, startBreak } from '@/actions/attendances.js';
import Button from '@/components/common/Basic/Button.jsx';
import { useAppDispatch } from '@/redux-store';
import { EmployeeShift, UserEmployee, UserLocation } from '@/types';

import { messages } from './DashboardTimeTracker.messages.ts';

type Props = {
  currentAttendance: FormattedAttendance;
  showConfirmState: () => void;
  showSelectLocationState: () => void;
  nextShift: EmployeeShift & { location: UserLocation };
  previousShift: EmployeeShift & { location: UserLocation };
  employee: UserEmployee;
  relevantLocations: UserLocation[];
  showSummaryState: () => void;
  breaksEnabled: boolean;
  currentBreak: Break;
};

const START_MODIFIERS = ['lime', 'high', 'small'];
const END_MODIFIERS = ['dark-red', 'small', 'high'];
const BREAK_MODIFIERS = ['reverse-dark-gray', 'small', 'high'];

export const DashboardTimeTrackerContentButtons = ({
  currentAttendance,
  nextShift,
  previousShift,
  showSelectLocationState,
  showConfirmState,
  employee,
  relevantLocations,
  showSummaryState,
  breaksEnabled,
  currentBreak,
}: Props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const handleStartAttendance = useCallback(async () => {
    const now = moment();
    const toleranceMinutes = 30;

    const hasShift =
      (nextShift &&
        moment(nextShift.start_timestamp).subtract(toleranceMinutes, 'minutes').isBefore(now) &&
        moment(nextShift.end_timestamp).isAfter(now)) ||
      (previousShift && moment(previousShift.end_timestamp).add(toleranceMinutes, 'minutes').isAfter(now));

    if (!hasShift) {
      showConfirmState();
      return;
    }

    if (relevantLocations.length > 1) {
      showSelectLocationState();
      return;
    }

    await dispatch(startAttendance(relevantLocations[0].id, employee.id));
  }, [nextShift, relevantLocations, dispatch, employee.id, showConfirmState, showSelectLocationState]);

  const handleStartBreak = useCallback(() => {
    dispatch(startBreak(currentAttendance.id, currentAttendance.employee_id));
  }, [currentAttendance, dispatch]);

  const handleEndBreak = useCallback(() => {
    dispatch(endBreak(currentAttendance.id, currentBreak.id, currentAttendance.employee_id));
  }, [currentAttendance, currentBreak, dispatch]);

  const endModifiers = breaksEnabled ? END_MODIFIERS : END_MODIFIERS.filter(modifier => modifier !== 'small');

  return (
    <div className="dashboardTimeTracker__buttons">
      {currentAttendance ? (
        <Button modifiers={endModifiers} onClick={showSummaryState}>
          <span className="material-icons">stop</span>
          {intl.formatMessage(messages.endAttendance)}
        </Button>
      ) : (
        <Button modifiers={START_MODIFIERS} onClick={handleStartAttendance}>
          <span className="material-icons">play_arrow</span>
          {intl.formatMessage(messages.startAttendance)}
        </Button>
      )}
      {breaksEnabled &&
        (currentBreak ? (
          <Button modifiers={BREAK_MODIFIERS} onClick={handleEndBreak}>
            <span className="material-icons">stop</span>
            {intl.formatMessage(messages.endBreak)}
          </Button>
        ) : (
          <Button modifiers={BREAK_MODIFIERS} onClick={handleStartBreak}>
            <span className="material-icons">coffee</span>
            {intl.formatMessage(messages.startBreak)}
          </Button>
        ))}
    </div>
  );
};
