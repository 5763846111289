import { EmployeeWhole } from '@/types';
import { AvailabilityType } from '@/types/availabilityTypes.types';
import { StandardDate } from '@/types/dates.types';
import { OpenShift } from '@/types/openShifts.types';
import { Shift } from '@/types/shifts.types';

export enum DragAndDropItemEnum {
  AVAILABILITY = 'availability',
  OPEN_SHIFT = 'openShift',
  SHIFT = 'shift',
}

export type DragAndDropStoreState = {
  draggedShiftId: Shift['id'];
  draggedShiftDate: StandardDate;
  originEmployeeId: EmployeeWhole['id'];
  draggedOpenShiftId: OpenShift['id'];
  blockedCells: Record<EmployeeWhole['id'], Record<StandardDate, boolean>>;
  draggedAvailability: Partial<AvailabilityType>;
  itemType: DragAndDropItemEnum;
  isSwapping: boolean;
};
