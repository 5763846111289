import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  date: {
    id: 'attendanceManyDaysTable.date',
    defaultMessage: 'Data',
  },
  location: {
    id: 'attendanceManyDaysTable.location',
    defaultMessage: 'Lokalizacja',
  },
  employee: {
    id: 'attendanceManyDaysTable.employee',
    defaultMessage: 'Pracownik',
  },
  status: {
    id: 'attendanceManyDaysTable.status',
    defaultMessage: 'Status',
  },
  jobTitle: {
    id: 'attendanceManyDaysTable.jobTitle',
    defaultMessage: 'Stanowisko',
  },
  start: {
    id: 'attendanceManyDaysTable.start',
    defaultMessage: 'Start',
  },
  end: {
    id: 'attendanceManyDaysTable.end',
    defaultMessage: 'Koniec',
  },
  breakTime: {
    id: 'attendanceManyDaysTable.breakTime',
    defaultMessage: 'Czas przerwy',
  },
  duration: {
    id: 'attendanceManyDaysTable.duration',
    defaultMessage: 'Czas trwania',
  },
  alerts: {
    id: 'attendanceManyDaysTable.alerts',
    defaultMessage: 'Alerty',
  },
});
