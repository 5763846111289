import classnames from 'classnames';
import { useCallback, useMemo } from 'react';

import { handleMouseDownItem, handleMouseOverItem, handleMouseUpItem } from '@/actions/schedule/quickPlanning';
import { showModal } from '@/actions/uiState';
import { ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes.js';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectEmployeeOrLoanedEmployeeById, selectIsEmployeeHiredInDate } from '@/redux-store/employees';
import { selectDateArray, selectTodayFromMainDateStore } from '@/redux-store/mainDateStore';
import { selectCanDeselectButtonsState } from '@/redux-store/schedule/buttonsState';
import { selectIsQuickPlanningEnabled } from '@/redux-store/schedule/quickPlanning';
import { selectIsAvailabilitiesViewMode, selectIsScheduleViewMode } from '@/redux-store/schedule/viewMode';
import { selectIsEmployeeLoanedForLocation } from '@/redux-store/scheduleState/locations';
import { selectRestrictionsDict } from '@/redux-store/userPermissions';
import { EmployeeWhole, UserLocation } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { getScheduleBlockSize } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import PersonOffIconWithTooltip from '../PersonOffIconWithTooltup/PersonOffIconWithTooltip';
import ScheduleItemSelectMarker from '../ScheduleItemSelectMarker/ScheduleItemSelectMarker.redux';
import ScheduleQuickPlanningItem from '../ScheduleQuickPlanningItem/ScheduleQuickPlanningItem.redux';
import { ScheduleQuickPlanningNewFreeDay } from '../ScheduleQuickPlanningNewFreeDay/ScheduleQuickPlanningNewFreeDay';
import ScheduleQuickPlanningNewShift from '../ScheduleQuickPlanningNewShift/ScheduleQuickPlanningNewShift.redux';
import { GroupNodeType } from '../ScheduleTableBody/GroupedEmployees/GroupedEmployees.types';
import ScheduleTableDropArea from '../ScheduleTableDropArea/ScheduleTableDropArea.redux';
import ScheduleTableItemBackground from '../ScheduleTableItemBackground/ScheduleTableItemBackground.redux';
import { getGroupJobTitleId } from '../ScheduleTableRow/ScheduleTableRow.utils';

import './ScheduleTableItem.scss';

type Props = {
  children: React.ReactNode;
  date: StandardDate;
  employeeId: EmployeeWhole['id'];
  groupNodeId: GroupNodeType['groupId'];
  isEmpty: boolean;
  locationId: UserLocation['id'];
  paddingToAdd: string;
};

export const ScheduleTableItem = ({
  children,
  date,
  employeeId,
  groupNodeId,
  isEmpty,
  locationId,
  paddingToAdd,
}: Props) => {
  const dispatch = useAppDispatch();
  const isDateWithinEmploymentPeriod = useAppSelector(selectIsEmployeeHiredInDate(employeeId, date));
  const todayMainDateStore = useAppSelector(selectTodayFromMainDateStore);
  const isQuickPlanningEnabled = useAppSelector(selectIsQuickPlanningEnabled);
  const restrictions = useAppSelector(selectRestrictionsDict);
  const dateArray = useAppSelector(selectDateArray);
  const employee = useAppSelector(selectEmployeeOrLoanedEmployeeById(employeeId));
  const isAvaViewMode = useAppSelector(selectIsAvailabilitiesViewMode);
  const isScheduleViewMode = useAppSelector(selectIsScheduleViewMode);
  const canDeselect = useAppSelector(selectCanDeselectButtonsState);
  const isLoanedEmployee = useAppSelector(selectIsEmployeeLoanedForLocation(locationId, employeeId));
  const isEditDisabled = restrictions[SCHEDULE_EDIT_DISABLE];
  const size = useMemo(() => getScheduleBlockSize(dateArray), [dateArray]);
  const initialJobTitleId = getGroupJobTitleId(groupNodeId);
  const isShowModalDisabled = isAvaViewMode && canDeselect;

  const tableItemClassName = classnames('scheduleTable__item', {
    empty: isEmpty,
    today: date === todayMainDateStore,
    'scheduleTable__item--editDisabled':
      isEditDisabled || isShowModalDisabled || !isDateWithinEmploymentPeriod || isLoanedEmployee,
  });

  const addIconClassName = classnames(
    'material-icons-outlined scheduleTable__item__add',
    paddingToAdd && `scheduleTable__item__add--${paddingToAdd}`,
  );

  const personOffIconClassName = classnames(addIconClassName, 'scheduleTable__item__personOff');

  const blockEdit = isEditDisabled || isQuickPlanningEnabled || isLoanedEmployee;

  const disableOnTableItemClick = !isEmpty || blockEdit || isShowModalDisabled || !isDateWithinEmploymentPeriod;

  const handleOnTableItemClick = useCallback(() => {
    if (disableOnTableItemClick) return;
    dispatch(
      showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
        employee,
        date,
        location: { id: locationId },
        initialJobTitleId,
      }),
    );
  }, [disableOnTableItemClick, dispatch, employee, date, locationId, initialJobTitleId]);

  const onMouseDown = useCallback(() => {
    if (!isQuickPlanningEnabled) return;
    dispatch(handleMouseDownItem(employeeId, date));
  }, [dispatch, isQuickPlanningEnabled, employeeId, date]);

  const onMouseUp = useCallback(
    (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
      if (!isQuickPlanningEnabled) return;
      dispatch(handleMouseUpItem(employeeId, date, e));
    },
    [dispatch, isQuickPlanningEnabled, employeeId, date],
  );

  const onMouseOver = useCallback(() => {
    if (!isQuickPlanningEnabled) return;
    dispatch(handleMouseOverItem(employeeId, date));
  }, [dispatch, isQuickPlanningEnabled, employeeId, date]);

  const showPersonOffIconForScheduleView = (!isDateWithinEmploymentPeriod || isLoanedEmployee) && isScheduleViewMode;
  const showPersonOffIconForQuickPlanningMode = !isDateWithinEmploymentPeriod && isEmpty;

  return (
    <td
      className={tableItemClassName}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onClick={handleOnTableItemClick}
      onMouseOver={onMouseOver}
      role="presentation"
    >
      <ScheduleTableItemBackground date={date} />
      {!isLoanedEmployee && <ScheduleTableDropArea date={date} employeeId={employeeId} />}
      <ScheduleItemSelectMarker date={date} employeeId={employeeId} locationId={locationId} />
      {!isLoanedEmployee && (
        <ScheduleQuickPlanningItem
          date={date}
          employeeId={employeeId}
          locationId={locationId}
          showIcon={showPersonOffIconForQuickPlanningMode}
        />
      )}
      {(showPersonOffIconForScheduleView && (
        <PersonOffIconWithTooltip
          isLoanedEmployee={isLoanedEmployee}
          isQuickPlanningEnabled={isQuickPlanningEnabled}
          personOffIconClassName={personOffIconClassName}
        />
      )) ||
        (!blockEdit && <span className={addIconClassName}>add</span>)}
      {children}
      {!isLoanedEmployee && (
        <ScheduleQuickPlanningNewShift
          date={date}
          employeeId={employeeId}
          size={size}
          isEditEnabled={isDateWithinEmploymentPeriod}
        />
      )}
      <ScheduleQuickPlanningNewFreeDay date={date} employeeId={employeeId} />
    </td>
  );
};
