import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Tooltip from '@/components/common/Tooltip/Tooltip';

import './MDAlert.scss';

const MDAlert = ({
  icon,
  label,
  iconType,
  iconModifiers = '',
  numberOfAlerts,
  materialSymbols,
  style,
  position,
  yOffset,
}) => {
  const iconModifiersClasses = useMemo(
    () =>
      (!Array.isArray(iconModifiers) ? iconModifiers.split(' ') : iconModifiers).map(
        modifier => `mdAlert__icon--${modifier}`,
      ),
    [iconModifiers],
  );

  const icoClassName = materialSymbols
    ? classNames('material-symbols-outlined', ...iconModifiersClasses)
    : classNames('mdAlert__icon', ...iconModifiersClasses, {
        'material-icons': !iconType,
        [`material-icons-${iconType}`]: iconType,
      });

  const content = <span className={icoClassName}>{icon}</span>;

  return (
    <div className="mdAlert" style={style}>
      {label ? (
        <Tooltip yOffset={yOffset} position={position} content={label}>
          {content}
        </Tooltip>
      ) : (
        content
      )}
      {numberOfAlerts && <span className="mdAlert__counter">{numberOfAlerts <= 9 ? numberOfAlerts : '+9'}</span>}
    </div>
  );
};

MDAlert.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  iconType: PropTypes.string,
  numberOfAlerts: PropTypes.number,
  materialSymbols: PropTypes.bool,
  style: PropTypes.shape({}),
  iconModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  position: PropTypes.string,
  yOffset: PropTypes.number,
};

export default MDAlert;
