import { defineMessages, IntlShape } from 'react-intl';

import { DropDownOption } from '@/components/common/DropdownMenu/DropdownMenu';

export const messages = defineMessages({
  budgetMetricsTableRowOptionsEditIcon: {
    id: 'budgetMetricsTableRowOptions.editIcon',
    defaultMessage: 'Edytuj',
  },
  budgetMetricsTableRowOptionsDeleteIcon: {
    id: 'budgetMetricsTableRowOptions.deleteIcon',
    defaultMessage: 'Usuń',
  },
});

export const getOptions = (
  onEdit: () => void,
  onDelete: () => void,
  intl: IntlShape,
  canEdit: boolean,
): DropDownOption[] => [
  {
    id: 'edit',
    icon: 'create',
    title: intl.formatMessage(messages.budgetMetricsTableRowOptionsEditIcon),
    onClick: onEdit,
    disabled: !canEdit,
    show: true,
  },
  {
    id: 'delete',
    icon: 'delete',
    title: intl.formatMessage(messages.budgetMetricsTableRowOptionsDeleteIcon),
    onClick: onDelete,
    disabled: !canEdit,
    show: true,
  },
];
