import classNames from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';

type Props = {
  month: number;
  year: number;
  changeMonth: (diff: number) => void;
  changeYear: (diff: number) => void;
};

export const MonthPickerContent = ({ changeMonth, changeYear, month, year }: Props) => {
  const goPrevYear = useCallback(() => {
    changeYear(-1);
  }, [changeYear]);

  const goNextYear = useCallback(() => {
    changeYear(1);
  }, [changeYear]);

  return (
    <div className="md-monthPicker" data-test="monthPicker">
      <div className="md-monthPicker__row">
        <i role="presentation" className="material-icons" onClick={goPrevYear} data-test="monthPicker-previousCycle">
          keyboard_arrow_left
        </i>
        <div className="md-monthPicker__year" data-test="monthPicker-year">
          {year}
        </div>
        <i role="presentation" className="material-icons" onClick={goNextYear} data-test="monthPicker-nextCycle">
          keyboard_arrow_right
        </i>
      </div>
      {moment.monthsShort().map((momentMonth, i) => {
        const className = classNames('md-monthPicker__item', {
          'md-monthPicker__item--picked': i === month - 1,
        });
        return (
          <div role="presentation" className={className} key={i} data-test="month" onClick={() => changeMonth(i + 1)}>
            {momentMonth.toUpperCase()}
          </div>
        );
      })}
    </div>
  );
};
