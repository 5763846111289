import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import KadroCalendarPopoverInput from '@/components/common/inputs/CalendarPopoverInput/KadroCalendarPopoverInput';
import { MDSelect, MDTextArea, MDTimeRangeInput } from '@/components/common/inputs/MDComponents';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import RepeatShift from '@/components/scheduler/modals/AddShiftAndAbsenceModal/RepeatShift/RepeatShift.jsx';
import { getRelevantContractForDate } from '@/utils/contracts';
import { getToday } from '@/utils/dateHelper';
import { createEvent, validateInput as validateInputHelper } from '@/utils/inputHelpers';

import {
  createNewShift,
  createRepeatObject,
  getInitialState,
  getJobTitlesOptions,
  getLocationsOptions,
  getSummaryErrorMessage,
  handleRequestError,
} from './EmployeeShiftModal.helpers';
import { messages } from './EmployeeShiftModal.messages';

const EmployeeShiftModal = (props, context) => {
  const initialContract = useMemo(
    () => getRelevantContractForDate(props.userContracts, props.modalObject?.date || getToday()),
    [props.userContracts, props.modalObject],
  );
  const [state, setState] = useState(getInitialState(props.user, props.mainDateStore, initialContract));
  const [loading, setLoading] = useState(false);

  const [relevantContract, setRelevantContract] = useState(initialContract);

  useEffect(() => {
    const newRelevantContract = getRelevantContractForDate(props.userContracts, state.selectedDate);

    setRelevantContract(newRelevantContract);
  }, [state.selectedDate]);

  useEffect(() => {
    setState(getInitialState(props.user, props.mainDateStore, relevantContract, props.modalObject));
  }, [props.show]);

  const handleInputChange = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
    validateInput(createEvent(name, value));
    if (state.errors.requestError) setError('requestError', null);
  };

  const setError = (name, error) => {
    setState(prevState => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [name]: error && context.intl.formatMessage(error),
      },
    }));
  };

  const validateInput = async event => {
    const [name, error] = await validateInputHelper(event);
    setError(name, error);
    return error;
  };

  const validateAll = async () => {
    const inputs = ['workingHours'];
    const validationResult = await Promise.all(
      inputs.map(inputName => validateInput(createEvent(inputName, state[inputName]))),
    );
    return !Object.values(validationResult).some(err => err !== '');
  };

  const onSubmit = async () => {
    const valid = await validateAll();
    if (!valid) return;
    try {
      setLoading(true);
      const newShift = createNewShift(state);
      if (state.displayRepeat) {
        await props.addCurrentUserShiftsWithRepeat(newShift, createRepeatObject(state));
      } else if (state.id) {
        await props.editCurrentUserShift({ id: state.id, ...newShift });
      } else {
        await props.addCurrentUserShifts([newShift]);
      }
      props.hideModal();
    } catch (err) {
      const requestError = handleRequestError(err);
      setError('requestError', requestError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MDKadroModal
      show={props.show}
      title={
        props.modalObject?.id
          ? context.intl.formatMessage(messages.editShift)
          : context.intl.formatMessage(messages.addShift)
      }
      onSubmit={onSubmit}
      onHide={props.hideModal}
      modifiers={['narrow']}
      errorMessage={getSummaryErrorMessage(state.errors)}
      disableConfirm={loading}
    >
      <KadroCalendarPopoverInput
        inputValue={state.selectedDate}
        customDate={state.selectedDate}
        changeDate={day => handleInputChange('selectedDate', day)}
        singleDay
        modifiers="extra-padding"
        label={context.intl.formatMessage(messages.date)}
        inputEnabled
      />
      <MDSelect
        name={context.intl.formatMessage(messages.location)}
        options={getLocationsOptions(props.user.locations)}
        defaultValue={state.selectedLocationId}
        onChange={id => handleInputChange('selectedLocationId', id)}
        closeOnClick
      />
      <MDSelect
        name={context.intl.formatMessage(messages.jobTitle)}
        options={getJobTitlesOptions(relevantContract, props.userJobTitles)}
        defaultValue={state.selectedJobTitleId}
        onChange={id => handleInputChange('selectedJobTitleId', id)}
        closeOnClick
      />
      <MDTimeRangeInput
        value={state.workingHours}
        onChange={hours => handleInputChange('workingHours', hours)}
        label={context.intl.formatMessage(messages.time)}
        errorMessage={state.errors.workingHours}
        modifiers={['modal']}
      />
      <MDTextArea
        label={context.intl.formatMessage(messages.note)}
        placeholder={context.intl.formatMessage(messages.notePlaceholder)}
        value={state.comment}
        onChange={e => handleInputChange(e.target.name, e.target.value)}
        name="comment"
        errorMessage={state.errors.comment}
      />
      {!props.modalObject?.id && (
        <RepeatShift
          displayRepeat={state.displayRepeat}
          activeRepeatTab={state.activeRepeatTab}
          selectedRange={state.selectedRange}
          selectedChoices={state.selectedChoices}
          selectedWeekdays={state.selectedWeekdays}
          perWhichDay={state.perWhichDay}
          handleInputChange={handleInputChange}
          nonWorkingDays={props.nonWorkingDays}
          toggleNonWorkingDays={props.toggleNonWorkingDays}
          repeatShiftMessage={context.intl.formatMessage(messages.repeatShiftMessage)}
        />
      )}
    </MDKadroModal>
  );
};

EmployeeShiftModal.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeeShiftModal.propTypes = {
  show: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  nonWorkingDays: PropTypes.bool,
  toggleNonWorkingDays: PropTypes.func,
  modalObject: PropTypes.shape({}),
  editCurrentUserShift: PropTypes.func,
  addCurrentUserShiftsWithRepeat: PropTypes.func,
  addCurrentUserShifts: PropTypes.func,
};

export default EmployeeShiftModal;
