import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { createMassAttendances } from '@/actions/attendances';
import Button from '@/components/common/Basic/Button';
import { BUTTON_BAR_TOOLTIP_OFFSET } from '@/components/common/Basic/ButtonBar/ButtonBar.utils';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { useAppDispatch } from '@/redux-store';
import { EmployeeWhole, Location } from '@/types';
import { Timestamp } from '@/types/dates.types';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { messages } from '../../AttendanceButtonBar.messages';

export type AttendanceToCreate = {
  location_id: Location['id'];
  employee_id: EmployeeWhole['id'];
  start_timestamp: Timestamp;
  end_timestamp: Timestamp;
  draft: boolean;
};

type Props = {
  data: AttendanceToCreate[];
  disabled: boolean;
};

export const AttendanceAddButton = ({ data, disabled }: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(createMassAttendances(data, { addMatchingShifts: true }));
  }, [dispatch, data]);

  return (
    <Tooltip content={intl.formatMessage(messages.add)} yOffset={BUTTON_BAR_TOOLTIP_OFFSET}>
      <Button disabled={disabled || isEmptyArray(data)} onClick={onClick} modifiers="reverse-blue onlyIcon">
        <i className="material-icons">add</i>
      </Button>
    </Tooltip>
  );
};
