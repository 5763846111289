import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Location } from '@/types';

import { ScheduleTableRow } from '../../ScheduleTableRow/ScheduleTableRow';
import { useLoanedEmployees } from './useLoanedEmployees';

type Props = {
  locationId: Location['id'];
};

export const LoanedEmployees = memo(({ locationId }: Props) => {
  const { colSpan, hideLoanedEmployees, loanedEmployeeIds } = useLoanedEmployees(locationId);

  if (hideLoanedEmployees) return null;

  return (
    <>
      <tr>
        <td>
          <FormattedMessage id="schedule.loanedEmployees" defaultMessage="Pracownicy udostępnieni" />
        </td>
        <td colSpan={colSpan} />
      </tr>
      {loanedEmployeeIds.map(employeeId => (
        <ScheduleTableRow key={employeeId} employeeId={employeeId} locationId={locationId} />
      ))}
    </>
  );
});

LoanedEmployees.displayName = 'LoanedEmployees';
