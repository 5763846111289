import { FormattedMessage } from 'react-intl';

import { OPTIONS_COLUMN_WIDTH } from '@/constants/tables';

import { ColumnStaticAccessorEnum, MDTableProps, TABLE_COLUMN_STICKY_ENUM, TableColumnType } from '../../MDTable.types';
import { OptionCell } from './OptionCell';

export const getOptionsColumn = <T extends object>(options: MDTableProps<T>['options']): TableColumnType<T> => ({
  accessorKey: ColumnStaticAccessorEnum.OPTION,
  centerColumn: true,
  enableColumnFilter: false,
  header: () => <FormattedMessage id="table.header.options" defaultMessage="Opcje" />,
  cell: cellProps => <OptionCell options={options} {...cellProps} />,
  enableSorting: false,
  maxSize: OPTIONS_COLUMN_WIDTH,
  sticky: TABLE_COLUMN_STICKY_ENUM.RIGHT,
  withoutVerticalPadding: true,
  customSize: true,
});
