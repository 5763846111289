import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  MDCheckbox,
  MDDatesPicker,
  MDMonthPicker,
  MDSelect,
  MDTextInput,
  MDTimeInput,
} from '@/components/common/inputs/MDComponents.jsx';
import { weeklyWorkingHours } from '@/constants/employmentConditions.js';
import { workingMinutesToHours } from '@/utils/employmentConditionsHelpers.ts';

import './EmployeeModalConditions.scss';

const messages = defineMessages({
  custom: {
    id: 'employemtnConditions.partTimeMultiplier.custom',
    defaultMessage: 'Własny',
  },
  scheduleCycleDurationPlaceholder: {
    id: 'employmentConditions.scheduleCycleDurationPlaceholder',
    defaultMessage: 'od 1 do 12 miesięcy',
  },
  week: {
    id: 'employmentConditons.availabilityPeriod.week',
    defaultMessage: 'tydzień',
  },
  month: {
    id: 'employmentConditons.availabilityPeriod.month',
    defaultMessage: 'miesiąc',
  },
  year: {
    id: 'employmentConditons.availabilityPeriod.year',
    defaultMessage: 'rok',
  },
  validateWorkingRules: {
    id: 'companymanage.employmentConditions.validateWorkingRules',
    defaultMessage: 'Weryfikuj zgodność grafiku pracy z regułami Kodeksu Pracy',
  },
  validateWorkingRulesDescription: {
    id: 'companymanage.employmentConditions.validateWorkingRulesDescription',
    defaultMessage: 'Włącz tę opcję, jeśli chcesz dla tego pracownika walidować reguły kodeksu pracy w grafiku pracy',
  },
  elasticWorkDay: {
    id: 'companymanage.employmentConditions.elasticWorkDay',
    defaultMessage: 'Ruchomy czas pracy',
  },
  elasticWorkDayDescription: {
    id: 'companymanage.employmentConditions.elasticWorkDayDescription',
    defaultMessage:
      'Włącz tę opcję, jeśli pracownik korzysta z ruchomego czasu pracy. Będzie to miało wpływ na wyliczanie nadgodzin dobowych oraz walidacje reguły doby pracowniczej w grafiku pracy',
  },
  showAbsences: {
    id: 'companymanage.employmentConditions.showAbsences',
    defaultMessage: 'Zezwól na ewidencjonowanie wniosków urlopowych',
  },
  showAbsencesDescription: {
    id: 'companymanage.employmentConditions.showAbsencesDescription',
    defaultMessage:
      'Włącz tę opcje, aby zezwolić na dodawanie planowanych nieobecności, urlopów i delegacji, które będą uwzględnione w ewidencji godzin oraz harmonogramie',
  },
  isMedical: {
    id: 'companymanage.employmentConditions.isMedical',
    defaultMessage: 'Pracownik medyczny',
  },
  isMedicalDescription: {
    id: 'companymanage.employmentConditions.isMedicalDescription',
    defaultMessage:
      'Włącz tę opcję, jeżeli jest to pracownik placówki medycznej. Wówczas podstawą do wyliczenia nadgodzin dla tego pracownika będzie 7 godzin 35 minut.',
  },
  hireDate: {
    id: 'companymanage.employmentConditions.hireDate',
    defaultMessage: 'Data zatrudnienia',
  },
  hireDateTooltip: {
    id: 'companymanage.employmentConditions.hireDateTooltip',
    defaultMessage:
      'Gdy pracownik rozpoczyna współpracę z Twoją firmą i data jego zatrudnienia jest inna niż pierwszy dzień miesiąca możesz ustawić początek jego zatrudnienia. Pozwoli to na poprawne obliczenia normy w pierwszym miesiącu pracy dla pracownika i będzie miało wpływ na reguły kodeksu pracy.',
  },
  releaseDate: {
    id: 'companymanage.employmentConditions.releaseDate',
    defaultMessage: 'Data zwolnienia',
  },
  releaseDateTooltip: {
    id: 'companymanage.employmentConditions.releaseDateTooltip',
    defaultMessage:
      'Gdy pracownik kończy współpracę z Twoją firmą możesz ustawić Datę jego zwolnienia. Pozwoli to na poprawne obliczenia normy w ostatnim miesiącu pracy dla pracownika i będzie miało wpływ na reguły kodeksu pracy.',
  },
  nightWorkingHours: {
    id: 'companymanage.employmentConditions.nightWorkingHours',
    defaultMessage: 'Dopuszczalna praca w godzinach nocnych',
  },
  nightWorkingHoursDescription: {
    id: 'companymanage.employmentConditions.nightWorkingHoursDescription',
    defaultMessage: 'Włącz tę opcję, jeśli pracownik może pracować \nw godzinach nocnych.',
  },
});

const EmployeeModalConditions = (props, context) => {
  const partTimeMultiplier = weeklyWorkingHours.map(hours => ({
    key: context.intl.formatMessage(hours.name),
    value: workingMinutesToHours(hours.value),
  }));

  let partTimeMultiplierDefault = partTimeMultiplier.find(
    multiplier => multiplier.value === props.weeklyWorkingMinutes,
  );

  if (!partTimeMultiplierDefault) {
    partTimeMultiplier.push({
      key: context.intl.formatMessage(messages.custom, {}),
      value: '-1',
    });
    partTimeMultiplierDefault = {
      key: context.intl.formatMessage(messages.custom, {}),
      value: '-1',
    };
  }

  const handleSelectChange = e => {
    if (props.templateId !== 'uoz') {
      props.handleInputChange({
        target: { name: 'weeklyWorkingMinutes', value: e },
      });
    }
  };

  const handleFieldChange = e => {
    if (props.templateId !== 'uoz' || e.target.name === 'showAbsences') {
      props.handleInputChange(e);
    }
  };

  const handleDailyWorkingMinutes = e => {
    if (props.templateId !== 'uoz') {
      props.handleInputChange({
        target: {
          value: e,
          name: 'maxDailyWorkingMinutes',
          type: 'input',
        },
      });
    }
  };

  const handleWeeklyWorkingMinutes = e => {
    if (props.templateId !== 'uoz') {
      props.handleInputChange({
        target: {
          value: e,
          name: 'weeklyWorkingMinutes',
          type: 'input',
        },
      });
    }
  };

  const {
    templateId,
    validateInput,
    weeklyWorkingMinutes,
    maxDailyWorkingMinutes,
    errors,
    scheduleCycleDuration,
    validateWorkingRules,
    scheduleCycleStart,
    hireDate,
    releaseDate,
    elasticWorkDay,
    showAbsences,
    isMedical,
    allowedToWorkAtNightHours,
    showObjectName,
    showHireAndReleaseDate,
    isTemplateEditing,
  } = props;

  const isUozSelected = templateId === 'uoz';
  const isPodstSelected = templateId === 'podst';

  const isRownSelected = templateId === 'rown';

  const showAllowedToWorkAtNightHoursCheckbox = Boolean(
    isTemplateEditing || (allowedToWorkAtNightHours && !isUozSelected) || isPodstSelected || isRownSelected,
  );

  const allowedToWorkAtNightHoursDisabled = !isTemplateEditing && !isPodstSelected && !isRownSelected;

  return (
    <div className="employeeModalConditions">
      {showObjectName ? (
        <MDTextInput
          id="objectName"
          modifiers={['modal']}
          type="text"
          label={<FormattedMessage id="companymanage.employmentConditions.objectName" defaultMessage="Nazwa" />}
          value={props.objectName}
          onBlur={validateInput}
          errorMessage={errors.objectName}
          onChange={props.handleInputChange}
          testId="employeeConditionName"
        />
      ) : (
        <MDSelect
          name={<FormattedMessage id="employee.employmentConditions.select" defaultMessage="Wybierz szablon" />}
          id="employmentConditionsSelect"
          options={props.conditionsSelectOptions}
          onChange={props.handleSelectChange}
          defaultValue={props.conditionsDefaultValue}
          closeOnClick
          testId="employmentConditionsSelect"
        />
      )}
      <MDSelect
        name={
          <FormattedMessage id="companymanage.employmentConditions.partTimeMultiplier" defaultMessage="Wymiar etatu" />
        }
        id="partTimeMultiplier"
        options={isUozSelected ? [] : partTimeMultiplier}
        onChange={handleSelectChange}
        defaultValue={isUozSelected ? null : partTimeMultiplierDefault.value}
        disabled={isUozSelected}
        closeOnClick
        testId="employmentConditionsPartTimeMultiplierSelect"
      />
      <MDTimeInput
        label={
          <FormattedMessage
            id="companymanage.employmentConditions.weeklyWorkingMinutes"
            defaultMessage="Tygodniowy wymiar czasu pracy"
          />
        }
        value={isUozSelected ? '' : weeklyWorkingMinutes}
        modifiers={['modal', isUozSelected ? 'disabled' : null]}
        onChange={handleWeeklyWorkingMinutes}
        onBlur={validateInput}
        errorMessage={errors.weeklyWorkingMinutes}
        max={99}
        short
        testId="weeklyWorkingMinutes"
      />
      <MDTimeInput
        label={
          <FormattedMessage
            id="companymanage.employmentConditions.maxDailyWorkingMinutes"
            defaultMessage="Dobowy wymiar czasu pracy"
          />
        }
        value={isUozSelected ? '' : maxDailyWorkingMinutes}
        modifiers={['modal', isUozSelected ? 'disabled' : null]}
        onChange={handleDailyWorkingMinutes}
        onBlur={validateInput}
        errorMessage={errors.maxDailyWorkingMinutes}
        short
        testId="maxDailyWorkingMinutes"
      />
      <MDMonthPicker
        onChange={handleFieldChange}
        value={scheduleCycleStart}
        name="scheduleCycleStart"
        disabled={weeklyWorkingMinutes === '00:00'}
        testId="showMonthPicker"
        label={
          <FormattedMessage
            id="companymanage.employmentConditions.scheduleCycleStart"
            defaultMessage="Początek okresu rozliczeniowego"
          />
        }
      />
      <MDTextInput
        id="scheduleCycleDuration"
        type="number"
        modifiers={['modal', props.weeklyWorkingMinutes === '00:00' ? 'disabled' : null]}
        label={
          <FormattedMessage
            id="companymanage.employmentConditions.scheduleCycleDuration"
            defaultMessage="Długość okresu rozliczeniowego"
          />
        }
        value={weeklyWorkingMinutes === '00:00' ? '' : scheduleCycleDuration}
        onChange={handleFieldChange}
        onBlur={validateInput}
        errorMessage={errors.scheduleCycleDuration}
        disabled={weeklyWorkingMinutes === '00:00'}
        testId="scheduleCycleDuration"
      />
      {!showObjectName && showHireAndReleaseDate && (
        <>
          <MDDatesPicker
            id="hireDate"
            values={hireDate}
            maxDate={releaseDate?.length ? new Date(releaseDate[0]) : undefined}
            handleInputChange={props.handleInputChange}
            singleDate
            label={context.intl.formatMessage(messages.hireDate, {})}
            tooltip={context.intl.formatMessage(messages.hireDateTooltip, {})}
          />
          <MDDatesPicker
            id="releaseDate"
            values={releaseDate}
            minDate={hireDate?.length ? new Date(hireDate[0]) : undefined}
            handleInputChange={props.handleInputChange}
            singleDate
            label={context.intl.formatMessage(messages.releaseDate, {})}
            tooltip={context.intl.formatMessage(messages.releaseDateTooltip, {})}
          />
        </>
      )}
      <MDCheckbox
        value={validateWorkingRules}
        id="validateWorkingRules"
        text={context.intl.formatMessage(messages.validateWorkingRules, {})}
        tooltip={context.intl.formatMessage(messages.validateWorkingRulesDescription, {})}
        onChange={handleFieldChange}
        reverse
        disabled={isUozSelected}
        testId="validateWorkingRules"
      />
      <MDCheckbox
        value={showAbsences}
        id="showAbsences"
        text={context.intl.formatMessage(messages.showAbsences, {})}
        tooltip={context.intl.formatMessage(messages.showAbsencesDescription, {})}
        onChange={handleFieldChange}
        reverse
        testId="showAbsences"
      />
      <MDCheckbox
        value={elasticWorkDay}
        id="elasticWorkDay"
        text={context.intl.formatMessage(messages.elasticWorkDay, {})}
        tooltip={context.intl.formatMessage(messages.elasticWorkDayDescription, {})}
        onChange={handleFieldChange}
        reverse
        disabled={isUozSelected}
        testId="elasticWorkDay"
      />
      {Boolean(isTemplateEditing || isMedical) && (
        <MDCheckbox
          value={isMedical}
          id="isMedical"
          text={context.intl.formatMessage(messages.isMedical, {})}
          tooltip={context.intl.formatMessage(messages.isMedicalDescription, {})}
          onChange={handleFieldChange}
          reverse
          disabled={!isTemplateEditing}
          testId="isMedical"
        />
      )}
      {showAllowedToWorkAtNightHoursCheckbox && (
        <MDCheckbox
          value={allowedToWorkAtNightHours}
          id="allowedToWorkAtNightHours"
          text={context.intl.formatMessage(messages.nightWorkingHours)}
          tooltip={context.intl.formatMessage(messages.nightWorkingHoursDescription)}
          onChange={handleFieldChange}
          reverse
          disabled={allowedToWorkAtNightHoursDisabled}
          testId="nightWorkingHours"
        />
      )}
    </div>
  );
};

EmployeeModalConditions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeeModalConditions.propTypes = {
  showObjectName: PropTypes.bool,
  objectName: PropTypes.string,
  weeklyWorkingMinutes: PropTypes.string,
  maxDailyWorkingMinutes: PropTypes.string,
  scheduleCycleStart: PropTypes.shape({}),
  scheduleCycleDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  validateWorkingRules: PropTypes.bool,
  elasticWorkDay: PropTypes.bool,
  showAbsences: PropTypes.bool,
  errors: PropTypes.shape({
    objectName: PropTypes.string,
    weeklyWorkingMinutes: PropTypes.string,
    maxDailyWorkingMinutes: PropTypes.string,
    scheduleCycleDuration: PropTypes.string,
    availabilityLimits: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  validateInput: PropTypes.func,
  templateId: PropTypes.string,
  hireDate: PropTypes.arrayOf(PropTypes.string),
  releaseDate: PropTypes.arrayOf(PropTypes.string),
  showHireAndReleaseDate: PropTypes.bool,
  isMedical: PropTypes.bool,
  allowedToWorkAtNightHours: PropTypes.bool,
  isTemplateEditing: PropTypes.bool,
};

export default EmployeeModalConditions;
