import * as Sentry from '@sentry/react';

import { changePayrollColumns as changeNewPayrollColumns, choosePayrollSummaryValues } from '@/actions/payroll/payroll';
import { changePayrollLocationColumns as changeNewPayrollLocationColumns } from '@/actions/payrollLocation/payrollLocation';
import { settingsSchema } from '@/actions/settingsSaver/settingsSaver.dto.ts';
import { getInitialSelectedAttendanceColumnsForFilter } from '@/components/attendance/AttendanceManyDaysView/Table/ButtonBar/ColumnFilter/AttendanceManyDaysColumnFilter.utils';
import { filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings } from '@/components/newPayrollViews/columns.helpers';
import { employeeSortingOptions } from '@/constants/employeeSortingOptions.js';
import { employmentConditionsFilterOptions } from '@/constants/employmentConditions.js';
import { TEMPLATES_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes.js';
import { DATE_MATCH_REGEXP } from '@/constants/settingsConstants.js';
import { payrollTableColumnsOptions } from '@/constants/tables/payroll/payrollTableColumns';
import { payrollLocationTableColumnsOptions } from '@/constants/tables/payrollLocation/payrollLocationTableColumns';
import { getInitSummaryValues } from '@/reducers/payroll/payroll.helpers';
import {
  selectAttendancesNotExpandedDates,
  selectAttendancesVisibleColumns,
  setAttendanceExpandedRows,
  setAttendanceVisibleColumn,
} from '@/redux-store/attendances/uiState';
import { setSelectedEmployeeGroups } from '@/redux-store/employeeGrouping';
import { EmployeeGroupingEnum } from '@/types/employeeGrouping';
import { isEmptyObject } from '@/utils/baseHelpers.js';
import { getDateModeFromDates } from '@/utils/dateHelper.js';
import {
  areFiltersPresentInUrl,
  getMatchingValues,
  getRelevantValueDependOnQuery,
} from '@/utils/settingsSaverHelpers.ts';
import { generateQueryParametersFromSearchString } from '@/utils/urlModifyHelpers';

import { changeAbsenceLimitUsageTableData, changeAbsencesTableData } from '../absences.js';
import { changeEmployeeSorting } from '../employeeSorting.js';
import { changeEmploymentConditionsFilter } from '../employmentConditionsFilter.js';
import { changeQuickPlanningAndDisplayMode } from '../filters.js';
import { changeJobTitleFilter, changeJobTitleFilterGrouped } from '../jobtitleFilter.js';
import { changeLocationFilter } from '../locationFilter.js';
import { mainDateChangeModeAndDate } from '../mainDate.js';
import { changeMultipleLocationFilter } from '../multipleLocationFilter.js';
import { changeScheduleLocationFilter } from '../schedule/scheduleLocationFilter.js';
import { changeSingleEmployeeFilter } from '../singleEmployeeFilter.js';
import {
  changePayrollColumns,
  changePayrollLocationColumns,
  checkDefaultViewDateStore,
  setEmployeesVisibleColumns,
  setFiltersFromUrlSuccess,
  updateUrlFilterStatus,
} from '../uiState.js';

const currentVersion = 'PastelDeNata';

export const saveAllViewSettings = () => (dispatch, getState) =>
  new Promise<void>(resolve => {
    dispatch(checkDefaultViewDateStore());
    const state = getState();
    const {
      employeeGrouping: { selectedGroups },
      mainDateStore,
      locationFilter,
      employeeSorting,
      jobtitleFilter,
      multipleLocationFilter,
      scheduleLocationFilter,
      singleEmployeeFilter,
      employmentConditionsFilter,
      uiState,
      currentUser,
      absences,
      payroll,
      payrollLocation,
      reports,
    } = state.reducer;
    const attendanceVisibleColumns = selectAttendancesVisibleColumns(state);
    const attendanceNotExpandedRows = selectAttendancesNotExpandedDates(state);

    const prevSettings = localStorage.getItem(`k-viewsSettings${currentUser.user.id}`);
    const prevSettingsJson = prevSettings ? JSON.parse(prevSettings) : { scheduleSelectedLocations: undefined };

    const scheduleSelectedLocations =
      scheduleLocationFilter.length > 4 ? prevSettingsJson.scheduleSelectedLocations : scheduleLocationFilter;

    const settingsObject = {
      // Version to prevent getting old data structs. Only tasty versions allowed xD
      version: currentVersion,
      dateMode: mainDateStore.dateMode,
      selectedLocation: locationFilter.selectedLocation,
      employeeSorting: employeeSorting.currentSorting.id,
      selectedGroups,
      selectedJobtitles: jobtitleFilter.selectedJobtitles,
      selectedJobtitlesGrouped: jobtitleFilter.selectedJobtitlesGrouped,
      selectedLocations: currentUser.user.role !== 'employee' ? multipleLocationFilter : undefined,
      scheduleSelectedLocations,
      selectedEmploymentConditions: employmentConditionsFilter.selected,
      selectedEmployee: singleEmployeeFilter,
      payrollLocationVisibleColumns: uiState.payrollLocationVisibleColumns,
      payrollVisibleColumns: uiState.payrollVisibleColumns,
      employeesTableVisibleColumns: uiState.employeesVisibleColumns,
      absencesTable: absences.absencesTable,
      absenceLimitsUsageTable: absences.absenceLimitsUsageTable,
      newPayrollVisibleColumns: payroll.visibleColumns,
      newPayrollLocationVisibleColumns: payrollLocation.visibleColumns,
      newPayrollSummaryValues: payroll.summaryValues,
      attendanceVisibleColumns,
      attendanceNotExpandedRows,
      reportsViewFilters: reports.filters,
    };
    localStorage.setItem(`k-viewsSettings${currentUser.user.id}`, JSON.stringify(settingsObject));
    resolve();
  });

export const setDisplayModeFromUrl = queryDisplayMode => (dispatch, getState) => {
  if (!queryDisplayMode) return;

  const { displayModes } = getState().reducer.scheduleUIState;

  const displayModeFromUrl = queryDisplayMode;

  const filteredMode = displayModes.find(({ type }) => type === displayModeFromUrl);
  dispatch(changeQuickPlanningAndDisplayMode(filteredMode));
};

export const setJobTitlesFromUrl = queryJobTitles => (dispatch, getState) => {
  const { userJobTitles } = getState().reducer;

  const filteredJobTitles = getMatchingValues(queryJobTitles, userJobTitles);

  dispatch(changeJobTitleFilter(filteredJobTitles));
};

export const setLocationsFromUrl = queryLocations => (dispatch, getState) => {
  const { userLocations } = getState().reducer;

  const filteredLocationIds = userLocations.reduce((acc, { id }) => {
    if (queryLocations.includes(id)) {
      acc.push(id);
    }
    return acc;
  }, []);

  dispatch(changeMultipleLocationFilter(filteredLocationIds, true));
};

export const setScheduleLocationsFromUrl = queryScheduleLocations => (dispatch, getState) => {
  const { userLocations } = getState().reducer;

  const filteredScheduleLocations = queryScheduleLocations.filter(locId =>
    userLocations.find(location => location.id === locId),
  );
  dispatch(changeScheduleLocationFilter(filteredScheduleLocations, true));
};

export const setGroupedJobTitles = queryJobTitlesGrouped => (dispatch, getState) => {
  const { userJobTitles } = getState().reducer;

  const filteredGroupedJobTitles = getMatchingValues(queryJobTitlesGrouped, userJobTitles);

  dispatch(changeJobTitleFilterGrouped(filteredGroupedJobTitles));
};

export const setEmploymentConditionsFromUrl = queryEmploymentConditions => (dispatch, getState) => {
  const { employmentConditions } = getState().reducer;

  const conditionsToChoose = [...employmentConditionsFilterOptions, ...employmentConditions];

  const filteredEmploymentConditions = getMatchingValues(queryEmploymentConditions, conditionsToChoose);

  dispatch(changeEmploymentConditionsFilter(filteredEmploymentConditions));
};

export const setDateRangeFromUrl = (queryFrom, queryTo) => (dispatch, getState) => {
  const { start, end } = getState().reducer.mainDateStore.customDate;
  const dateFrom = queryFrom?.match(DATE_MATCH_REGEXP) ? queryFrom : start;
  const dateTo = queryTo?.match(DATE_MATCH_REGEXP) ? queryTo : end;

  const dateRanges = { start: dateFrom, end: dateTo };
  const dateMode = getDateModeFromDates(dateRanges);

  dispatch(mainDateChangeModeAndDate(dateFrom, dateTo, dateMode.type));
};

const setEmployeeGroups = groups => dispatch => {
  const group = Object.values(EmployeeGroupingEnum).find(g => groups.includes(g));
  dispatch(setSelectedEmployeeGroups(group || EmployeeGroupingEnum.NONE));
};

export const getSettingsViewFromUrl = () => (dispatch, getState) => {
  const { search } = window.location;
  const query = generateQueryParametersFromSearchString(search);
  const {
    jobTitles: queryJobTitles,
    displayMode: queryDisplayMode,
    locations: queryLocations,
    scheduleLocations: queryScheduleLocations,
    jobTitlesGrouped: queryJobTitlesGrouped,
    employmentConditions: queryemploymentConditions,
    from: queryFrom,
    to: queryTo,
    groups: queryGroups,
  } = query;
  const { currentUser } = getState().reducer;

  const settings = JSON.parse(localStorage.getItem(`k-viewsSettings${currentUser.user.id}`) || '{}');

  const selectedJobtitles = settings?.selectedJobtitles || [];
  const selectedJobtitlesGrouped = settings?.selectedJobtitlesGrouped || [];
  const selectedLocations = settings?.selectedLocations || [];
  const scheduleSelectedLocations = settings?.scheduleSelectedLocations || [];
  const selectedEmploymentConditions = settings?.selectedEmploymentConditions || [];
  const selectedGroups = settings?.selectedGroups || [];

  const jobTitles = getRelevantValueDependOnQuery(queryJobTitles, selectedJobtitles);

  const jobTitlesGrouped =
    queryDisplayMode === TEMPLATES_DISPLAY_MODE_TYPE
      ? jobTitles
      : getRelevantValueDependOnQuery(queryJobTitlesGrouped, selectedJobtitlesGrouped);
  const scheduleLocations = getRelevantValueDependOnQuery(queryScheduleLocations, scheduleSelectedLocations);
  const employmentConditions = getRelevantValueDependOnQuery(queryemploymentConditions, selectedEmploymentConditions);
  const locations = getRelevantValueDependOnQuery(queryLocations, selectedLocations);
  const groups = getRelevantValueDependOnQuery(queryGroups, selectedGroups);

  dispatch(setScheduleLocationsFromUrl(scheduleLocations));

  dispatch(setLocationsFromUrl(locations));

  dispatch(setEmploymentConditionsFromUrl(employmentConditions));

  dispatch(setDateRangeFromUrl(queryFrom, queryTo));

  dispatch(setDisplayModeFromUrl(queryDisplayMode));

  dispatch(setJobTitlesFromUrl(jobTitles));

  dispatch(setGroupedJobTitles(jobTitlesGrouped));

  dispatch(setEmployeeGroups(groups));
};

export const getAllViewSettings = () => (dispatch, getState, intl) =>
  new Promise<void>(resolve => {
    const state = getState();
    const { reducer } = state;
    const {
      userEmployees,
      userLocations,
      currentUser,
      userPermissions,
      currentCompany: { settings: companySettings },
      payrollSettings: {
        payoutSetting: { type: payoutSettingType },
      },
    } = reducer;
    const areFiltersExistInUrl = areFiltersPresentInUrl();

    dispatch(updateUrlFilterStatus(areFiltersExistInUrl));
    if (areFiltersExistInUrl) {
      dispatch(getSettingsViewFromUrl());
    } else {
      const settings = JSON.parse(localStorage.getItem(`k-viewsSettings${currentUser.user.id}`) || '{}');
      if (settings) {
        if (settings.version === currentVersion) {
          const {
            selectedGroups,
            selectedLocation,
            employeeSorting,
            selectedJobtitles,
            selectedJobtitlesGrouped,
            selectedLocations,
            scheduleSelectedLocations,
            selectedEmploymentConditions,
            selectedEmployee,
            payrollLocationVisibleColumns,
            payrollVisibleColumns,
            employeesTableVisibleColumns,
            absencesTable,
            absenceLimitsUsageTable,
            newPayrollVisibleColumns,
            newPayrollLocationVisibleColumns,
            newPayrollSummaryValues,
            attendanceVisibleColumns,
            attendanceNotExpandedRows,
          } = settings;

          // Setting location filter
          const selectedLocationDto = settingsSchema.shape.selectedLocation.safeParse(selectedLocation);
          if (selectedLocationDto.success) {
            const locationId =
              typeof selectedLocationDto.data === 'string' ? selectedLocationDto.data : selectedLocationDto.data?.id;
            if (userLocations.find(location => location.id === locationId)) {
              dispatch(changeLocationFilter(locationId, true));
            }
          } else {
            Sentry.captureException({
              message: 'Selected location filter failed',
              extra: { error: JSON.stringify(selectedLocationDto.error.issues) },
            });
          }

          // Employee sorting
          const employeeSortingDto = settingsSchema.shape.employeeSorting.safeParse(employeeSorting);
          if (employeeSortingDto.success) {
            const option = employeeSortingOptions.find(o => o.id === employeeSortingDto.data);
            if (option) dispatch(changeEmployeeSorting(option));
          } else {
            Sentry.captureException({
              message: 'Employee sorting failed',
              extra: { error: JSON.stringify(employeeSortingDto.error.issues) },
            });
          }

          // Job title filter
          const selectedJobTitlesDto = settingsSchema.shape.selectedJobtitles.safeParse(selectedJobtitles);
          if (selectedJobTitlesDto.success) {
            dispatch(changeJobTitleFilter(selectedJobTitlesDto.data));
          } else {
            Sentry.captureException({
              message: 'Selected job titles filter failed',
              extra: { error: JSON.stringify(selectedJobTitlesDto.error.issues) },
            });
          }

          const selectedJobtitlesGroupedDto =
            settingsSchema.shape.selectedJobtitlesGrouped.safeParse(selectedJobtitlesGrouped);
          if (selectedJobtitlesGroupedDto.success) {
            dispatch(changeJobTitleFilterGrouped(selectedJobtitlesGroupedDto.data));
          } else {
            Sentry.captureException({
              message: 'Selected job titles grouped filter failed',
              extra: { error: JSON.stringify(selectedJobtitlesGroupedDto.error.issues) },
            });
          }

          // Multiple locations filter
          const selectedLocationsDto = settingsSchema.shape.selectedLocations.safeParse(selectedLocations);
          if (selectedLocationsDto.success) {
            const filteredLocations = selectedLocationsDto.data.reduce((acc, item) => {
              const idToCheck = typeof item !== 'string' ? item.id : item;
              if (userLocations.some(location => location.id === idToCheck)) {
                acc.push(idToCheck);
              }
              return acc;
            }, []);

            if (filteredLocations.length) {
              dispatch(changeMultipleLocationFilter(filteredLocations, true));
            }
          } else {
            Sentry.captureException({
              message: 'Selected locations filter failed',
              extra: { error: JSON.stringify(selectedLocationsDto.error.issues) },
            });
          }

          // Schedule locations filter
          const scheduleSelectedLocationsDto =
            settingsSchema.shape.scheduleSelectedLocations.safeParse(scheduleSelectedLocations);
          if (scheduleSelectedLocationsDto.success) {
            const filteredLocations = scheduleSelectedLocationsDto.data.filter(locId =>
              userLocations.find(location => location.id === locId),
            );
            if (filteredLocations.length) dispatch(changeScheduleLocationFilter(filteredLocations, true));
          } else {
            Sentry.captureException({
              message: 'Schedule selected locations filter failed',
              extra: { error: JSON.stringify(scheduleSelectedLocationsDto.error.issues) },
            });
          }

          // Employment conditions filter
          const selectedEmploymentConditionsDto =
            settingsSchema.shape.selectedEmploymentConditions.safeParse(selectedEmploymentConditions);
          if (selectedEmploymentConditionsDto.success) {
            dispatch(changeEmploymentConditionsFilter(selectedEmploymentConditionsDto.data));
          } else {
            Sentry.captureException({
              message: 'Selected employment conditions filter failed',
              extra: { error: JSON.stringify(selectedEmploymentConditionsDto.error.issues) },
            });
          }

          // Employee grouping
          const selectedGroupsDto = settingsSchema.shape.selectedGroups.safeParse(selectedGroups);
          if (selectedGroupsDto.success) {
            dispatch(setEmployeeGroups(selectedGroupsDto.data));
          } else {
            Sentry.captureException({
              message: 'Selected groups filter failed',
              extra: { error: JSON.stringify(selectedGroupsDto.error.issues) },
            });
          }

          // Employee
          const selectedEmployeeDto = settingsSchema.shape.selectedEmployee.safeParse(selectedEmployee);
          if (selectedEmployeeDto.success) {
            if (userEmployees.find(employee => employee.id === selectedEmployeeDto.data.id))
              dispatch(changeSingleEmployeeFilter(selectedEmployee.data));
          } else {
            Sentry.captureException({
              message: 'Selected employee filter failed',
              extra: { error: JSON.stringify(selectedEmployeeDto.error.issues) },
            });
          }

          // Attendances
          const attendanceVisibleColumnsDto =
            settingsSchema.shape.attendanceVisibleColumns.safeParse(attendanceVisibleColumns);
          if (attendanceVisibleColumnsDto.success && !isEmptyObject(attendanceVisibleColumnsDto.data))
            dispatch(setAttendanceVisibleColumn(attendanceVisibleColumns));
          else dispatch(setAttendanceVisibleColumn(getInitialSelectedAttendanceColumnsForFilter(intl.intl)));

          const attendanceNotExpandedRowsDto =
            settingsSchema.shape.attendanceNotExpandedRows.safeParse(attendanceNotExpandedRows);
          if (attendanceNotExpandedRowsDto.success)
            dispatch(setAttendanceExpandedRows({ notExpanded: attendanceNotExpandedRows || [] }));

          // Payroll location columns
          const payrollLocationVisibleColumnsDto =
            settingsSchema.shape.payrollLocationVisibleColumns.safeParse(payrollLocationVisibleColumns);
          if (payrollLocationVisibleColumnsDto.success) {
            dispatch(changePayrollLocationColumns(payrollLocationVisibleColumnsDto.data));
          } else {
            Sentry.captureException({
              message: 'Payroll location columns filter failed',
              extra: { error: JSON.stringify(payrollLocationVisibleColumnsDto.error.issues) },
            });
          }

          const newPayrollLocationVisibleColumnsDto = settingsSchema.shape.newPayrollLocationVisibleColumns.safeParse(
            newPayrollLocationVisibleColumns,
          );
          if (newPayrollLocationVisibleColumnsDto.success) {
            const relevantColumns = filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings(
              payrollLocationTableColumnsOptions,
              userPermissions,
              payoutSettingType,
              companySettings,
            );
            const alwaysVisibleColumns = relevantColumns.filter(option => option.cannotBeHidden);
            const uniqueVisibleColumns = [...newPayrollLocationVisibleColumnsDto.data, ...alwaysVisibleColumns].reduce(
              (acc, column) => {
                if (!acc.some(c => c.id === column.id)) {
                  acc.push(column);
                }
                return acc;
              },
              [],
            );
            dispatch(changeNewPayrollLocationColumns(uniqueVisibleColumns));
          } else {
            Sentry.captureException({
              message: 'New payroll location columns filter failed',
              extra: { error: JSON.stringify(newPayrollLocationVisibleColumnsDto.error.issues) },
            });
          }

          // Payroll columns
          const payrollVisibleColumnsDto = settingsSchema.shape.payrollVisibleColumns.safeParse(payrollVisibleColumns);
          if (payrollVisibleColumnsDto.success) {
            const relevantColumns = filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings(
              payrollTableColumnsOptions,
              userPermissions,
              payoutSettingType,
              companySettings,
            );
            const alwaysVisibleColumns = relevantColumns.filter(option => option.cannotBeHidden);
            const uniqueVisibleColumns = [...payrollVisibleColumnsDto.data, ...alwaysVisibleColumns].reduce(
              (acc, column) => {
                if (!acc.some(c => c.id === column.id)) {
                  acc.push(column);
                }
                return acc;
              },
              [],
            );
            dispatch(changePayrollColumns(uniqueVisibleColumns));
          } else {
            Sentry.captureException({
              message: 'Payroll columns filter failed',
              extra: { error: JSON.stringify(payrollVisibleColumnsDto.error.issues) },
            });
          }

          const newPayrollVisibleColumnsDto =
            settingsSchema.shape.newPayrollVisibleColumns.safeParse(newPayrollVisibleColumns);
          if (newPayrollVisibleColumnsDto.success) {
            dispatch(changeNewPayrollColumns(newPayrollVisibleColumnsDto.data));
          } else {
            Sentry.captureException({
              message: 'New payroll columns filter failed',
              extra: { error: JSON.stringify(newPayrollVisibleColumnsDto.error.issues) },
            });
          }

          const employeesTableVisibleColumnsDto =
            settingsSchema.shape.employeesTableVisibleColumns.safeParse(employeesTableVisibleColumns);
          if (employeesTableVisibleColumnsDto.success) {
            dispatch(setEmployeesVisibleColumns(employeesTableVisibleColumnsDto.data));
          } else {
            Sentry.captureException({
              message: 'Employees table columns filter failed',
              extra: { error: JSON.stringify(employeesTableVisibleColumnsDto.error.issues) },
            });
          }

          // Absences table
          const absencesTableDto = settingsSchema.shape.absencesTable.safeParse(absencesTable);
          if (absencesTableDto.success) {
            dispatch(changeAbsencesTableData(absencesTableDto.data));
          } else {
            Sentry.captureException({
              message: 'Absences table failed',
              extra: { error: JSON.stringify(absencesTableDto.error.issues) },
            });
          }

          const absenceLimitsUsageTableDto =
            settingsSchema.shape.absenceLimitsUsageTable.safeParse(absenceLimitsUsageTable);
          if (absenceLimitsUsageTableDto.success) {
            dispatch(changeAbsenceLimitUsageTableData(absenceLimitsUsageTableDto.data));
          } else {
            Sentry.captureException({
              message: 'Absence limits usage table failed',
              extra: { error: JSON.stringify(absenceLimitsUsageTableDto.error.issues) },
            });
          }

          const newPayrollSummaryValuesDto =
            settingsSchema.shape.newPayrollSummaryValues.safeParse(newPayrollSummaryValues);
          if (newPayrollSummaryValuesDto.success) {
            const initSummaryValues = getInitSummaryValues();

            const newPayrollSummaryValuesWithRestrictions = initSummaryValues.map(value => {
              const itemFromLocalStorage = newPayrollSummaryValuesDto.data.find(item => item.id === value.id);
              return {
                ...value,
                ...itemFromLocalStorage,
                restrictions: value.restrictions,
              };
            });
            dispatch(choosePayrollSummaryValues(newPayrollSummaryValuesWithRestrictions));
          } else {
            Sentry.captureException({
              message: 'New payroll summary values failed',
              extra: { error: JSON.stringify(newPayrollSummaryValuesDto.error.issues) },
            });
          }
        } else {
          dispatch(setAttendanceVisibleColumn(getInitialSelectedAttendanceColumnsForFilter(intl.intl)));
        }
      }
    }

    dispatch(setFiltersFromUrlSuccess());
    resolve();
  });
