import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

import './MDTooltip.scss';

const MDTooltip = ({ icon = 'help', text, children, withoutIcon = false, className }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isPopoverHovered, setIsPopoverHovered] = useState(false);
  const tooltipClassName = classNames('mdTooltip', className);

  const onMouseEnterPopover = () => setIsPopoverHovered(true);

  const onMouseLeavePopover = () => {
    setIsPopoverHovered(false);
    setIsPopoverVisible(false);
  };

  const onMouseLeaveIcon = () => {
    setTimeout(() => {
      if (!isPopoverHovered) {
        setIsPopoverVisible(false);
      }
    }, 200);
  };

  const onMouseEnterIcon = () => {
    setIsPopoverVisible(true);
  };

  return (
    <div className={tooltipClassName}>
      {children && <div className="mdTooltip__content">{children}</div>}
      {!withoutIcon && (
        <span
          className="material-icons mdTooltip__icon"
          onMouseLeave={onMouseLeaveIcon}
          onMouseEnter={onMouseEnterIcon}
        >
          {icon}
        </span>
      )}
      {text && isPopoverVisible && (
        <div className="mdTooltip__text" onMouseEnter={onMouseEnterPopover} onMouseLeave={onMouseLeavePopover}>
          {text}
        </div>
      )}
    </div>
  );
};

MDTooltip.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  children: PropTypes.node,
  withoutIcon: PropTypes.bool,
  className: PropTypes.string,
};

export default MDTooltip;
