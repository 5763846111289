import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { violationMessages } from '@/constants/workingRules.js';
import { convertDateToStandardFormat, generateFromToForDateAndPeriod, parseMinutesToFormat } from '@/utils/dateHelper';
import { calculateWorkingDaysForEmployee } from '@/utils/employmentConditionsHelpers.ts';
import { getNormForDate } from '@/utils/schedulerHelpers';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers.js';

import './ShowConflictsModal.scss';

const ShowConflictsModal = (props, context) => {
  const getValueForMessage = (conflict, employee) => {
    const { code, start_timestamp: startTimestamp } = conflict;
    switch (code) {
      case 'OVERTIME': {
        const time = parseMinutesToFormat(employee.employment_conditions.max_daily_working_minutes);
        return { time };
      }
      case 'STANDARD_WORKING_TIME': {
        const date = convertDateToStandardFormat(startTimestamp);
        const monthlyNorms = props.calendarData ? props.calendarData.monthlyNorms : [];
        const time = parseMinutesToFormat(getNormForDate(date, monthlyNorms, employee).totalHours * 60);
        return { time };
      }
      case 'STANDARD_WORKING_TIME_DAYS': {
        const date = convertDateToStandardFormat(startTimestamp);
        const { from, to } = generateFromToForDateAndPeriod(date, 'month');

        const numberOfWorkingDays = calculateWorkingDaysForEmployee(
          employee.employment_conditions,
          from,
          to,
          props.calendarData.holidays,
        );
        return { numberOfWorkingDays };
      }
      default:
        return {};
    }
  };

  const conflicts = (props.modalObject?.conflicts || []).sort(
    (a, b) => new Date(a.start_timestamp) - new Date(b.start_timestamp),
  );

  return (
    <MDKadroModal
      show={props.showModal}
      onHide={props.hideModal}
      title={<FormattedMessage id="scheduler.showConflictsModal.title" defaultMessage="Konflikty" />}
      hideFooter
      modifiers={['wide']}
    >
      <div className="showConflictsModal">
        {conflicts.map((conflict, i) => {
          const relevantEmployee = props.userEmployees.find(employee => employee.id === conflict.employee_id);
          return (
            <div className="showConflictsModal__item" key={i}>
              <p>{getEmployeeNameShort(relevantEmployee, 17)}</p>
              <p>
                {' '}
                {conflict.start_timestamp.slice(0, 10)} - {conflict.end_timestamp.slice(0, 10)}
              </p>
              <p className="showConflictsModal__description">
                {context.intl.formatMessage(
                  violationMessages[conflict.code],
                  getValueForMessage(conflict, relevantEmployee),
                )}
              </p>
            </div>
          );
        })}
      </div>
    </MDKadroModal>
  );
};

ShowConflictsModal.contextTypes = {
  intl: PropTypes.shape({}),
};

ShowConflictsModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    conflicts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  hideModal: PropTypes.func,
  dateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  calendarData: PropTypes.shape({
    monthlyNorms: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ShowConflictsModal;
