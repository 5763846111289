import { memo } from 'react';

import { ScheduleShift } from '@/components/scheduler/ScheduleTable/ScheduleShift/ScheduleShift';
import { GroupNodeType } from '@/components/scheduler/ScheduleTable/ScheduleTableBody/GroupedEmployees/GroupedEmployees.types';
import { Absence, EmployeeWhole, UserLocation } from '@/types';
import { Shift } from '@/types/shifts.types';

type Props = {
  connectorLength: number;
  employeeId: EmployeeWhole['id'];
  groupNodeId: Nullable<GroupNodeType['id']>;
  locationId: UserLocation['id'];
  shiftsForDate: Shift[];
  unfilteredAbsencesForDate: Absence[];
};

export const ShiftsBlocks = memo(
  ({ connectorLength, groupNodeId, employeeId, locationId, shiftsForDate, unfilteredAbsencesForDate }: Props) =>
    shiftsForDate.map(block => (
      <ScheduleShift
        key={block.id}
        shiftId={block.id}
        employeeId={employeeId}
        currentLocationId={locationId}
        groupNodeId={groupNodeId}
        absencesForDate={unfilteredAbsencesForDate}
        isAbsenceConnectingWithAnyScheduleBlock={connectorLength > 0}
      />
    )),
);

ShiftsBlocks.displayName = 'ShiftsBlocks';
