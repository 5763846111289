import { CellContext } from '@tanstack/react-table';
import { useIntl } from 'react-intl';

import MDAlert from '@/components/common/Basic/MDAlert/MDAlert';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { PAYROLL_HOURS_RANGE_PLACEHOLDER, TIME_FORMAT } from '@/constants/tables/payroll/payrollTableColumns';
import { useAppSelector } from '@/redux-store';
import { selectPayoutSettingType } from '@/redux-store/payrollSettings';
import { getEndAttendanceColor, getStartAttendanceColor } from '@/utils/attendanceHelpers';
import { timestampsToWorkingHours } from '@/utils/dateHelper';

import { messages } from '../../PayrollTable.messages';
import { PayrollRow } from '../../PayrollTable.types';

type Props = CellContext<TableRow<PayrollRow>, unknown>;

export const PayrollTimeCell = ({ cell }: Props) => {
  const {
    from: fromTimestamp,
    to: toTimestamp,
    matchingShiftFrom,
    matchingShiftTo,
    attendanceId,
    attendanceEarlyStartAccepted,
    attendanceLateEndAccepted,
  } = cell.row.original;
  const intl = useIntl();
  const payoutSettingType = useAppSelector(selectPayoutSettingType);

  const isFrom = cell.column.id === 'from';

  if (!fromTimestamp) return PAYROLL_HOURS_RANGE_PLACEHOLDER;

  const [from, to] = timestampsToWorkingHours(fromTimestamp, toTimestamp).split('-');

  if (payoutSettingType === 'shifts') return isFrom ? from : to;

  const attendanceFrom = attendanceId ? from : TIME_FORMAT;
  const attendanceTo = attendanceId ? to : TIME_FORMAT;

  let [shiftFrom, shiftTo] =
    matchingShiftFrom && matchingShiftTo ? timestampsToWorkingHours(matchingShiftFrom, matchingShiftTo).split('-') : [];

  if (!attendanceId) {
    shiftFrom = from;
    shiftTo = to;
  }

  if (isFrom)
    return (
      <div className="k-payrollTable__hoursWrapper">
        {attendanceFrom !== TIME_FORMAT ? (
          <span style={{ color: getStartAttendanceColor(fromTimestamp, matchingShiftFrom) }}>{attendanceFrom}</span>
        ) : (
          TIME_FORMAT
        )}
        <span> / {shiftFrom || TIME_FORMAT}</span>
        {attendanceEarlyStartAccepted && (
          <MDAlert iconModifiers="table" icon="more_time" label={intl.formatMessage(messages.acceptedOvertime)} />
        )}
      </div>
    );

  return (
    <div className="k-payrollTable__hoursWrapper">
      {attendanceTo !== TIME_FORMAT ? (
        <span style={{ color: getEndAttendanceColor(toTimestamp, matchingShiftTo) }}>{attendanceTo}</span>
      ) : (
        TIME_FORMAT
      )}
      <span> / {shiftTo || TIME_FORMAT}</span>
      {attendanceLateEndAccepted && (
        <MDAlert iconModifiers="table" icon="more_time" label={intl.formatMessage(messages.acceptedOvertime)} />
      )}
    </div>
  );
};
