import moment from 'moment';
import { defineMessages } from 'react-intl';

import { limitPeriods, limitUnits } from '@/components/absences/modals/AbsenceTypeModal/constants';
import {
  absenceDetailTitles,
  absenceStatuses,
  NOT_COUNTED_ABSENCE_STATUSES,
  OVERTIME_COLLECTION,
} from '@/constants/absences';
import { MULTIPLE_ABSENCES_DURING_DAY_ENABLE } from '@/constants/Permissions';
import {
  ABSENCES_EDIT_HIDE,
  ABSENCES_FOR_OTHERS_HIDE_ADD,
  ABSENCES_LEAVE_REQUEST_VIEW_HIDE,
  ABSENCES_LIMIT_VIEW_HIDE,
  FREEMIUM_HIDE_ABSENCES_VIEW,
} from '@/constants/Restrictions';
import {
  calculateDurationMinutes,
  checkWorkingHoursOverlaps,
  enumerateDaysBetweenDates,
  hoursToMinutes,
  parseMinutesToHumanForm,
} from '@/utils/dateHelper';

export const absenceTypeIds = {
  overtimeCollection: 'overtimeCollection',
};

const messages = defineMessages({
  absenceStatusDraft: {
    id: 'absence.status.draft',
    defaultMessage: 'Do rozpatrzenia',
  },
  absenceStatusAccepted: {
    id: 'absence.status.accepted',
    defaultMessage: 'Zaakceptowany',
  },
  absenceStatusRejected: {
    id: 'absence.status.rejected',
    defaultMessage: 'Odrzucony',
  },
  absenceStatusCanceled: {
    id: 'absence.status.canceled',
    defaultMessage: 'Anulowany',
  },
  absenceHistoryCreateMessage: {
    id: 'absenceHistory.create.message',
    defaultMessage: 'Utworzono nowy',
  },
  absenceHistoryCreateStatus: {
    id: 'absenceHistory.create.status',
    defaultMessage: 'wniosek',
  },
  absenceHistoryAcceptMessage: {
    id: 'absenceHistory.accept.message',
    defaultMessage: 'Wniosek zmienił status na',
  },
  absenceHistoryAcceptStatus: {
    id: 'absenceHistory.accept.status',
    defaultMessage: 'zaakceptowany',
  },
  absenceHistoryRejectMessage: {
    id: 'absenceHistory.reject.message',
    defaultMessage: 'Wniosek zmienił status na',
  },
  absenceHistoryRejectStatus: {
    id: 'absenceHistory.reject.status',
    defaultMessage: 'odrzucony',
  },
  absenceHistoryCancelMessage: {
    id: 'absenceHistory.cancel.message',
    defaultMessage: 'Wniosek zmienił status na',
  },
  absenceHistoryCancelStatus: {
    id: 'absenceHistory.cancel.status',
    defaultMessage: 'anulowany',
  },
  absenceTypePeriodWeek: {
    id: 'absenceType.period.week',
    defaultMessage: 'tydzień',
  },
  absenceTypePeriodMonth: {
    id: 'absenceType.period.month',
    defaultMessage: 'miesiąc',
  },
  absenceTypePeriodYear: {
    id: 'absenceType.period.year',
    defaultMessage: 'rok',
  },
  absenceTypeUnitMinutes: {
    id: 'absenceType.unit.minutes',
    defaultMessage: 'godzin',
  },
  absenceTypeUnitDays: {
    id: 'absenceType.unit.days',
    defaultMessage: 'dni',
  },
  absenceCalendarPopoverAbsenceNr: {
    id: 'absence.calendar.popover.absenceNr',
    defaultMessage: 'Nr wniosku:',
  },
  absenceCalendarPopoverAbsenceType: {
    id: 'absence.calendar.popover.absenceType',
    defaultMessage: 'Typ wniosku:',
  },
  absenceCalendarPopoverPeriod: {
    id: 'absence.calendar.popover.period',
    defaultMessage: 'Okres:',
  },
  absenceCalendarPopoverCreated: {
    id: 'absence.calendar.popover.created',
    defaultMessage: 'Data złożenia:',
  },
  absenceCalendarPopoverPeriodLength: {
    id: 'absence.calendar.popover.periodLength',
    defaultMessage: 'Czas trwania:',
  },
  absenceHoursDays: {
    id: 'absenceHours.Days',
    defaultMessage: '{dayDuration, plural, one {dzień} other {dni}}',
  },

  absenceHoursFrom: {
    id: 'absenceHours.from',
    defaultMessage: 'od',
  },
  absenceHoursTo: {
    id: 'absenceHours.to',
    defaultMessage: 'do',
  },
  omitWeekendsAndHolidays: {
    id: 'absenceModal.omitWeekendsAndHolidays',
    defaultMessage: 'pominięto weekendy oraz dni wolne od pracy',
  },
  omitWeekends: {
    id: 'absenceModal.omitWeekends',
    defaultMessage: 'pominięto weekendy',
  },
  omitHolidays: {
    id: 'absenceModal.omitHolidays',
    defaultMessage: 'pominięto dni wolne od pracy',
  },
  countedDaysWithShifts: {
    id: 'absenceModal.countedDaysWithShifts',
    defaultMessage: 'nieobecność liczona na podstawie zmian w grafiku pracy',
  },
  overtimeCollection: {
    id: 'absence.overtimeCollection',
    defaultMessage: 'Odbiór nadgodzin',
  },
  overtimeCollectionProposalName: {
    id: 'absence.overtimeCollectionProposalName',
    defaultMessage: 'Wniosek o udzielenie czasu wolnego za godziny nadliczbowe',
  },
});

export const getAbsenceStatusName = (status, intl) => {
  const statusNames = {
    [absenceStatuses.draft]: intl.formatMessage(messages.absenceStatusDraft, {}),
    [absenceStatuses.accepted]: intl.formatMessage(messages.absenceStatusAccepted, {}),
    [absenceStatuses.rejected]: intl.formatMessage(messages.absenceStatusRejected, {}),
    [absenceStatuses.canceled]: intl.formatMessage(messages.absenceStatusCanceled, {}),
  };
  return statusNames[status];
};

export const calculateDaysBetweenTwoDates = (dateOne, dateTwo) => {
  const dateA = moment(dateOne);
  const dateB = moment(dateTwo);
  const duration = moment.duration(dateB.diff(dateA)).asDays();
  return Math.ceil(duration);
};

export const getUserName = (userId, userEmployees) => {
  const user = userEmployees.find(u => u.id === userId);
  if (!user) return null;
  const name = `${user?.first_name} ${user?.last_name}`;
  return name;
};

export const getAbsenceHistoryIcon = item => {
  if (item.action === 'create') {
    return 'add';
  }
  if (item.action === 'accept') {
    return 'check';
  }
  if (item.action === 'reject') {
    return 'close';
  }

  return '';
};

export const getAbsenceHistoryMessage = (item, context) => {
  const absenceHistoryMessages = {
    [absenceStatuses.draft]: {
      message: context.intl.formatMessage(messages.absenceHistoryCreateMessage, {}),
      status: context.intl.formatMessage(messages.absenceHistoryCreateStatus, {}),
    },
    [absenceStatuses.accepted]: {
      message: context.intl.formatMessage(messages.absenceHistoryAcceptMessage, {}),
      status: context.intl.formatMessage(messages.absenceHistoryAcceptStatus, {}),
    },
    [absenceStatuses.rejected]: {
      message: context.intl.formatMessage(messages.absenceHistoryRejectMessage, {}),
      status: context.intl.formatMessage(messages.absenceHistoryRejectStatus, {}),
    },
    [absenceStatuses.canceled]: {
      message: context.intl.formatMessage(messages.absenceHistoryCancelMessage, {}),
      status: context.intl.formatMessage(messages.absenceHistoryCancelStatus, {}),
    },
  };
  return absenceHistoryMessages[item.new_status];
};

export const getHoursForSingleDayView = (absence, shifts) => {
  if (absence.absence_hours) {
    return absence.absence_hours;
  }

  if (shifts.length === 1) {
    return shifts[0].working_hours;
  }

  if (shifts.length > 1) {
    const { startHour, endHour } = shifts.reduce(
      (result, { working_hours: workingHours }) => {
        const [shiftStartHour, shiftEndHour] = workingHours.split('-');

        return {
          startHour: !result.startHour || shiftStartHour < result.startHour ? shiftStartHour : result.startHour,
          endHour: !result.endHour || shiftEndHour > result.endHour ? shiftEndHour : result.endHour,
        };
      },
      {
        startHour: '',
        endHour: '',
      },
    );

    return `${startHour}-${endHour}`;
  }

  return '00:00-23:59';
};

const getActionName = (action, intl) => {
  if (action === 'create') {
    return intl.formatMessage({
      id: 'absences.created',
      defaultMessage: 'Utworzony',
    });
  }

  if (action === 'accept') {
    return intl.formatMessage({
      id: 'absences.accepted',
      defaultMessage: 'Zaakceptowany',
    });
  }

  if (action === 'reject') {
    return intl.formatMessage({
      id: 'absences.rejected',
      defaultMessage: 'Odrzucony',
    });
  }

  return '-';
};

export const formatHistoryEntryToPdf = (historyEntry, userEmployees, intl) => {
  const user = userEmployees.find(employee => employee.id === historyEntry.user_id);
  const userFullName = user ? `${user.first_name} ${user.last_name}` : historyEntry.user_name;
  const timestamp = moment(historyEntry.timestamp).format('DD.MM.YYYY, HH:mm');
  const status = getActionName(historyEntry.action, intl);

  return {
    userFullName,
    timestamp,
    status,
  };
};

export const checkIfShiftIncludesAbsence = ({ startShift, endShift }, absence) => {
  const { absence_hours: absenceHours, dateArray } = absence;

  if (dateArray.every(absenceDate => !moment(startShift).isSame(absenceDate, 'days'))) {
    return false;
  }

  const [absenceStartHour, absenceEndHour] = absenceHours.split('-');
  const absenceDate = absence.dateArray[0];
  const absenceStart = `${absenceDate} ${absenceStartHour}`;
  const absenceEnd = `${absenceDate} ${absenceEndHour}`;

  return (
    moment(absenceStart).isBetween(startShift, endShift, 'minute', '[]') &&
    moment(absenceEnd).isBetween(startShift, endShift, 'minute', '[]')
  );
};

export const getAbsencesPerDatesFromAbsences = (absences, dateStore) =>
  absences.flatMap(absence => {
    const dateArray = Array.from({ length: moment(absence.to).diff(absence.from, 'days') + 1 }, (_, index) => {
      const date = moment(absence.from).add(index, 'days').format('YYYY-MM-DD');
      return absence.omitted_dates.includes(date) ||
        date < dateStore.customDate.start ||
        date > dateStore.customDate.end
        ? null
        : date;
    }).filter(Boolean);

    return dateArray.map(date => ({
      ...absence,
      dateArray: [date],
      date,
      omitted_dates: undefined,
      from: undefined,
      to: undefined,
    }));
  });

export const getAbsencesCalculatedFromEmploymentConditions = absencesPerDate =>
  absencesPerDate.filter(absence => !absence.count_only_days_with_shifts);

export const getAbsencesForPayroll = (absences = [], absenceTypes, dateStore, showUnpaidAbsences = false) =>
  absences.filter(absence => {
    const absenceType = absenceTypes.find(type => type.id === absence.type_id);

    return (
      absenceType &&
      absenceType.code !== 'OWP' &&
      !absence.deleted_at_timestamp &&
      ['accepted', 'draft'].includes(absence.status) &&
      (absenceType.is_paid || showUnpaidAbsences) &&
      absence.from <= dateStore.customDate.end &&
      absence.to >= dateStore.customDate.start
    );
  });

export const checkIfAbsencesOverlap = (absences, absence, permissions) => {
  if (absence.absence_hours && permissions.includes(MULTIPLE_ABSENCES_DURING_DAY_ENABLE)) {
    const otherAbsencesHours = absences
      .filter(
        a =>
          a.employee_id === absence.employee_id &&
          !a.deleted_at_timestamp &&
          a.from === absence.from &&
          !NOT_COUNTED_ABSENCE_STATUSES.includes(a.status),
      )
      .map(a => a.absence_hours);
    const allAbsencesHours = [...otherAbsencesHours, absence.absence_hours];

    return checkWorkingHoursOverlaps(allAbsencesHours);
  }

  return absences.some(
    a =>
      a.employee_id === absence.employee_id &&
      !a.deleted_at_timestamp &&
      a.from <= absence.to &&
      a.to >= absence.from &&
      !NOT_COUNTED_ABSENCE_STATUSES.includes(a.status),
  );
};

export const isOvertimeCollectionsDurationIncorrect = (
  multiplier,
  amount50,
  amount100,
  amountPotential,
  availableOvertimes,
  absenceHours,
) => {
  const { sum50, sum100, sumPotential, totalDailyOvertime } = availableOvertimes;
  const absenceHoursToMinutes = Math.floor(calculateDurationMinutes(absenceHours) / multiplier);
  const absenceHoursTooLarge = absenceHoursToMinutes > totalDailyOvertime;
  const amount50ToMinutes = hoursToMinutes(amount50);
  const amount100ToMinutes = hoursToMinutes(amount100);
  const amountPotentialToMinutes = hoursToMinutes(amountPotential);
  const amount50TooLarge = amount50ToMinutes > sum50;
  const amount100TooLarge = amount100ToMinutes > sum100;
  const amountPotentialTooLarge = amountPotentialToMinutes > sumPotential;
  if (amount50TooLarge || amount100TooLarge || amountPotentialTooLarge || absenceHoursTooLarge) return true;
  return absenceHoursToMinutes < amount50ToMinutes + amount100ToMinutes + amountPotentialToMinutes;
};

export const getAbsenceTypePeriod = (absenceType, intl) => {
  if (absenceType.limit_period === 'week') {
    return intl.formatMessage(messages.absenceTypePeriodWeek, {});
  }
  if (absenceType.limit_period === 'month') {
    return intl.formatMessage(messages.absenceTypePeriodMonth, {});
  }
  if (absenceType.limit_period === 'year') {
    return intl.formatMessage(messages.absenceTypePeriodYear, {});
  }
  return '';
};

export const getAbsenceTypeUnit = (absenceType, intl) => {
  if (absenceType.limit_unit === 'minutes') {
    return intl.formatMessage(messages.absenceTypeUnitMinutes, {});
  }
  if (absenceType.limit_unit === 'days') {
    return intl.formatMessage(messages.absenceTypeUnitDays, {});
  }
  return '';
};

export const getInitialLimitsState = (absencesTypes, absencesLimitTemplates) =>
  absencesTypes
    .filter(absencesType => absencesType.has_limit && !absencesType.deleted_at_timestamp)
    .reduce((state, absencesType) => {
      const absenceLimit = absencesLimitTemplates.find(
        absencesLimit => absencesType.id === absencesLimit.absence_type_id,
      );
      if (absenceLimit) {
        if (absencesType.limit_unit === 'minutes') {
          return { ...state, [absencesType.id]: absenceLimit.limit_minutes };
        }
        if (absencesType.limit_unit === 'days') {
          return { ...state, [absencesType.id]: absenceLimit.limit_days };
        }
      }
      return { ...state, [absencesType.id]: '' };
    }, {});

export const createLimits = (absencesTypes, limits, changedLimits) =>
  absencesTypes
    .filter(
      absenceType =>
        absenceType.has_limit && changedLimits.includes(absenceType.id) && !absenceType.deleted_at_timestamp,
    )
    .map(absencesType => ({
      absence_type_id: absencesType.id,
      limit_minutes: absencesType.limit_unit === 'minutes' ? parseInt(limits[absencesType.id]) : undefined,
      limit_days: absencesType.limit_unit === 'days' ? parseInt(limits[absencesType.id]) : undefined,
    }));

export const getColorFromAbsenceStatus = status => {
  switch (status) {
    case absenceStatuses.accepted:
      return 'green';
    case absenceStatuses.rejected:
      return 'red';
    case absenceStatuses.draft:
      return 'yellow';
    case absenceStatuses.canceled:
      return 'gray';
    default:
      return '';
  }
};

export const canBeAccessedAbsencesView = (userPermissions, currentUser) => {
  const allAbsencesViewNotAllowed =
    (userPermissions?.restrictions.includes(ABSENCES_LEAVE_REQUEST_VIEW_HIDE) &&
      userPermissions?.restrictions.includes(ABSENCES_LIMIT_VIEW_HIDE)) ||
    userPermissions?.restrictions.includes(FREEMIUM_HIDE_ABSENCES_VIEW);
  const canUserShowAbsences = !userPermissions?.isEmployee || currentUser.employment_conditions.show_absences;
  return !allAbsencesViewNotAllowed && canUserShowAbsences;
};

export const getMaxLimit = absenceType => {
  const periodDuration = limitPeriods[absenceType.limit_period];
  const unitDuration = limitUnits[absenceType.limit_unit];
  const maxLimit = periodDuration * unitDuration;
  return maxLimit;
};

export const createAbsenceObject = (
  employeeId,
  {
    selectedAbsence: selectedAbsenceType,
    selectedAbsenceRange,
    selectedDay,
    absenceHours,
    absenceOmitWeekends,
    absenceOmitHolidays,
    countOnlyDaysWithShifts,
    allDay,
    absenceComment,
  },
) => {
  const absenceObject = {
    type_id: selectedAbsenceType.id,
    employee_id: employeeId,
    from: selectedAbsenceRange.start,
    to: selectedAbsenceRange.end,
    omit_weekends: absenceOmitWeekends,
    omit_holidays: absenceOmitHolidays,
    note: selectedAbsenceType.has_note && absenceComment !== '' ? absenceComment : undefined,
    count_only_days_with_shifts: countOnlyDaysWithShifts,
  };
  if (!allDay && selectedAbsenceType.defined_in_hours) {
    absenceObject.absence_hours = absenceHours;
    absenceObject.from = selectedDay;
    absenceObject.to = selectedDay;
  }
  return absenceObject;
};

export const getAbsenceDuration = (absence, intl) => {
  if (absence.isEmpty) return '';
  const timeDuration = parseMinutesToHumanForm(absence.duration);
  const dayDuration = moment(absence.to).diff(absence.from, 'days') + 1;
  const dayWord = intl.formatMessage(messages.absenceHoursDays, { dayDuration });
  return `${timeDuration} / ${dayDuration} ${dayWord}`;
};

export const getAbsenceTypeName = (absenceTypeName, intl) =>
  absenceTypeName === OVERTIME_COLLECTION ? intl.formatMessage(messages.overtimeCollection) : absenceTypeName;

export const formatAbsencesForAbsencesCalendar = (absences, intl) => {
  const formattedAbsences = absences.reduce((formattedAbsencesAgg, absence) => {
    const {
      from: startDate,
      to: endDate,
      request_number: requestNumber,
      absence_type_name: absenceType,
      created_at_timestamp: createdAt,
      category,
      status,
    } = absence;
    const dates = enumerateDaysBetweenDates(startDate, endDate);
    if (status === 'accepted' || status === 'draft') {
      const datesObj = dates.reduce((datesObjAgg, date) => {
        const popoverData = [
          { absenceNrMessageId: requestNumber },
          { absenceTypeMessageId: getAbsenceTypeName(category || absenceType, intl) },
          { periodMessageId: `${startDate} — ${endDate}` },
          { createdMessageId: moment(createdAt).format('YYYY-MM-DD') },
          { periodLengthId: getAbsenceDuration(absence, intl) },
        ];
        const dateObj = {
          date: moment(date).format('YYYY-MM-DD'),
          isStartDate: moment(startDate).isSame(moment(date), 'day'),
          isEndDate: moment(endDate).isSame(moment(date), 'day'),
          weekDayNumber: moment(date).day(),
          popoverData,
          color: status === 'accepted' ? '#73a13d' : '#ffc124',
        };
        return { ...datesObjAgg, [moment(date).format('YYYY-MM-DD')]: dateObj };
      }, {});
      return { ...formattedAbsencesAgg, ...datesObj };
    }
    return formattedAbsencesAgg;
  }, {});

  return Array(12)
    .fill({})
    .map((month, i) =>
      Object.entries(formattedAbsences).reduce((agg, [key, value]) => {
        const monthIndex = Number(moment(key).format('M')) - 1;
        if (monthIndex === i) return { ...agg, [key]: value };
        return agg;
      }, month),
    );
};

export const getDetailTitleName = (detailTitle, intl) => {
  const detailTitleNames = {
    [absenceDetailTitles.absenceNrMessageId]: intl.formatMessage(messages.absenceCalendarPopoverAbsenceNr),
    [absenceDetailTitles.absenceTypeMessageId]: intl.formatMessage(messages.absenceCalendarPopoverAbsenceType),
    [absenceDetailTitles.periodMessageId]: intl.formatMessage(messages.absenceCalendarPopoverPeriod),
    [absenceDetailTitles.createdMessageId]: intl.formatMessage(messages.absenceCalendarPopoverCreated),
    [absenceDetailTitles.periodLengthId]: intl.formatMessage(messages.absenceCalendarPopoverPeriodLength),
  };
  return detailTitleNames[detailTitle];
};

export const checkAbsenceEditDisabled = (restrictions, employee, currentUserId) => {
  const { id: employeeId, employment_conditions: employmentConditions } = employee;
  return (
    !employmentConditions?.show_absences ||
    (restrictions.includes(ABSENCES_FOR_OTHERS_HIDE_ADD) && employeeId !== currentUserId)
  );
};
