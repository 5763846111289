import { memo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { EmployeeWhole, UserLocation } from '@/types';

import { GroupNodeType } from '../ScheduleTableBody/GroupedEmployees/GroupedEmployees.types';
import ScheduleTableRowTitle from '../ScheduleTableRowTitle/ScheduleTableRowTitle.redux';
import { ScheduleTableDate } from './ScheduleTableDate/ScheduleTableDate';

import './ScheduleTableRow.scss';

type Props = {
  employeeId: EmployeeWhole['id'];
  locationId: UserLocation['id'];
  groupNodeId?: GroupNodeType['id'];
};

export const ScheduleTableRow = memo(({ employeeId, locationId, groupNodeId }: Props) => {
  const dateArray = useAppSelector(selectDateArray);

  return (
    <tr className="scheduleTable__row">
      <ScheduleTableRowTitle employeeId={employeeId} key={employeeId} locationId={locationId} />
      {dateArray.map((date, index) => (
        <ScheduleTableDate
          key={date}
          date={date}
          index={index}
          employeeId={employeeId}
          groupNodeId={groupNodeId}
          locationId={locationId}
        />
      ))}
    </tr>
  );
});

ScheduleTableRow.displayName = 'ScheduleTableRow';
