import { CellContext } from '@tanstack/react-table';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { getAttendanceStatuses } from '@/utils/attendance/attendance.utils';

import { AttendanceRowItem } from '../../AttendanceManyDaysTable.types';

import './StatusCell.scss';

type StatusCellProps = CellContext<TableRow<AttendanceRowItem>, unknown>;

const Component = memo(({ cell }: StatusCellProps) => {
  const intl = useIntl();
  const allStatuses = useMemo(() => getAttendanceStatuses(intl), [intl]);
  const { status } = cell.row.original;
  const option = allStatuses[status];

  if (!option) return null;

  return (
    <div className="k-attendanceStatusCell">
      <div style={{ background: option.color }} className="k-attendanceStatusCell__dot" />
      {option.text}
    </div>
  );
});

Component.displayName = 'StatusCellComponent';

export const AttendanceStatusCell = memo((props: StatusCellProps) => {
  if ('subRows' in props.row.original) return null;

  return <Component {...props} />;
});

AttendanceStatusCell.displayName = 'AttendanceStatusCell';
