import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import MDFileDrop from '@/components/common/inputs/MDFileDrop/MDFileDrop.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { toBase64 } from '@/utils/fileHelpers.js';

import '@/components/common/MDImportModal/MDImportModal.scss';

const MDImportBudgetModal = props => {
  const {
    show,
    hideModal,
    dateArray,
    runExport,
    pendingExports,
    locationSettings,
    scheduleLocationFilter,
    toggleImportBudgetModalOverlay,
    showOverlay,
    toggleImportBudgetModalInputError,
    showInputError,
    clearBudgetImports,
  } = props;
  const selectedLocationId = scheduleLocationFilter[0];
  const selectedLocationSettings = locationSettings[selectedLocationId];
  const currentTemplate = pendingExports.find(e => e.type === 'f-budget-import' && e.link);
  const isTemplateGenerated = currentTemplate && currentTemplate.link;

  const [file, setFile] = useState(null);
  const [isTemplatedDownloaded, setIsTemplateDownloaded] = useState(false);

  useEffect(() => {
    if (isTemplateGenerated && !isTemplatedDownloaded) {
      window.downloadInProgress = true;
      const link = document.createElement('a');
      link.href = currentTemplate.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsTemplateDownloaded(true);
      clearBudgetImports();
      setTimeout(() => {
        window.downloadInProgress = false;
      }, 1000);
    }
  }, [currentTemplate, isTemplatedDownloaded, isTemplateGenerated]);

  useEffect(() => {
    if (show) {
      setFile(null);
      toggleImportBudgetModalInputError(false);
      toggleImportBudgetModalOverlay(false);
    }
  }, [show]);

  const handleSubmit = useCallback(async () => {
    if (showOverlay) {
      return;
    }
    toggleImportBudgetModalInputError(false);
    toggleImportBudgetModalOverlay(true);
    const base64File = await toBase64(file);
    await runExport('f-budget-import', 'Import budżetu', {
      type: 'import',
      file: base64File,
      locationId: selectedLocationId,
      dateArray,
      budgetEstimatesPeriod: selectedLocationSettings?.budget_estimates_period || 'day',
    });
  }, [file]);

  const handleExport = async () => {
    toggleImportBudgetModalOverlay(true);
    await runExport('f-budget-import', 'Szablon budżetu', {
      type: 'template',
      dateArray,
      budgetEstimatesPeriod: selectedLocationSettings?.budget_estimates_period || 'day',
    });
    setIsTemplateDownloaded(false);
  };

  const confirmBtnModifiers = file ? ['orange'] : ['hide'];

  const handleUpload = uploadedFile => {
    const fileToSet = Array.isArray(uploadedFile) || uploadedFile instanceof FileList ? uploadedFile[0] : uploadedFile;
    setFile(fileToSet);
  };

  return (
    <MDKadroModal
      show={show}
      onHide={hideModal}
      onSubmit={handleSubmit}
      modifiers={['wide']}
      confirmBtnModifiers={[...confirmBtnModifiers, showOverlay ? 'disabled' : '']}
      cancelBtnModifiers={['gray', 'reverse-orange']}
      title={<FormattedMessage id="importBudgetModal.importData" defaultMessage="Importuj dane" />}
      confirmText={<FormattedMessage id="common.saveChanges" defaultMessage="Zapisz zmiany" />}
      loading={showOverlay}
    >
      <div className="mdImportModal">
        <div className="mdImportModal__export">
          <div className="mdImportModalExport__header">
            <FormattedMessage id="importBudgetModal.loadData" defaultMessage="Wczytaj dane" />
          </div>
          <div className="mdImportModalExport__text">
            <FormattedMessage
              id="importBudgetModal.fileImport.exportFileText"
              defaultMessage="Wczytaj dane z pliku Excel. {downloadTemplate}  i wypełnij w nim dane."
              values={{
                downloadTemplate: (
                  <span className="mdImportModalExport__link" onClick={handleExport} role="presentation">
                    {' '}
                    <FormattedMessage id="downloadTemplate" defaultMessage="Pobierz szablon" />{' '}
                  </span>
                ),
              }}
            />
          </div>
        </div>
        <div className="mdImportModal__fileDrop">
          <MDFileDrop fileFormats={['xlsx']} hideButton onUpload={handleUpload} />
        </div>
        {showInputError && (
          <div className="mdImportModal__error">
            <FormattedMessage
              id="importBudgetModal.inputError"
              defaultMessage="Wczytany plik zawiera niepoprawne dane"
            />
          </div>
        )}
      </div>
    </MDKadroModal>
  );
};

MDImportBudgetModal.propTypes = {
  budgetEstimates: PropTypes.shape({
    imported: PropTypes.bool,
    errorMessages: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
        line: PropTypes.string,
      }),
    ),
  }),
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  dateArray: PropTypes.arrayOf(PropTypes.string),
  runExport: PropTypes.func,
  pendingExports: PropTypes.arrayOf(PropTypes.shape({})),
  locationSettings: PropTypes.shape({}),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  toggleImportBudgetModalOverlay: PropTypes.func,
  showOverlay: PropTypes.bool,
  toggleImportBudgetModalInputError: PropTypes.func,
  showInputError: PropTypes.bool,
  clearBudgetImports: PropTypes.func,
};

export default MDImportBudgetModal;
