import { useCallback } from 'react';

import { enableLoadButton } from '@/actions/payroll';
import TopBarCalendar from '@/components/TopBars/filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBarEmploymentConditionsFilter from '@/components/TopBars/filters/TopBarEmploymentConditionsFilter/TopBarEmploymentConditionsFilter.redux.js';
import TopBarMultipleLocationFilter from '@/components/TopBars/filters/TopBarMultipleLocationFilter/TopBarMultipleLocationFilter.redux';
import TopBar from '@/components/TopBars/TopBar';
import { payrollLocationModesIds } from '@/constants/viewDateModes';
import TopBarJobTitleFilterContainer from '@/containers/TopBars/filters/TopBarJobTitleFilterContainer.js';
import TopBarNameFilterContainer from '@/containers/TopBars/filters/TopBarNameFilterContainer.js';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectPayoutSetting } from '@/selectors/payrollSettings.selectors.ts';

import PayrollLocationLoadDataButton from './PayrollLocationLoadDataButton/PayrollLocationLoadDataButton.redux';

const PayrollLocationTopBar = () => {
  const dispatch = useAppDispatch();
  const payoutSetting = useAppSelector(selectPayoutSetting);
  const onChangeCallback = useCallback(() => {
    dispatch(enableLoadButton());
  }, [dispatch]);

  return (
    <TopBar
      className="k-topBar--payrollLocation"
      leftElements={[
        <TopBarCalendar key="calswitch" modes={payrollLocationModesIds} onChangeCallback={onChangeCallback} />,
        <TopBarMultipleLocationFilter key="multipleLocationFilter" />,
        <TopBarJobTitleFilterContainer key="jobTitleFilter" showNoJobTitlesOption={payoutSetting.type === 'ecp'} />,
        <TopBarNameFilterContainer key="nameFilter" />,
        <TopBarEmploymentConditionsFilter key="employmentConditionsFilter" />,
      ]}
      rightElements={[<PayrollLocationLoadDataButton key="loadDataButton" />]}
    />
  );
};

export default PayrollLocationTopBar;
