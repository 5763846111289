import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { showModal } from '@/actions/uiState';
import Button from '@/components/common/Basic/Button';
import { BUTTON_BAR_TOOLTIP_OFFSET } from '@/components/common/Basic/ButtonBar/ButtonBar.utils';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { ACCEPT_OVERTIME_MODAL } from '@/constants/modalTypes';
import { useAppDispatch } from '@/redux-store';
import { Attendance } from '@/redux-store/attendances/attendancesData';
import { UserEmployee } from '@/types';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { messages } from '../../AttendanceButtonBar.messages';

type Props = {
  data: {
    employee: UserEmployee;
    attendance: Attendance;
  }[];
  disabled: boolean;
};

export const AttendanceAcceptOvertime = ({ data, disabled }: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(
      showModal(ACCEPT_OVERTIME_MODAL, {
        selectedEmployeesAttendanceOvertimeData: data,
      }),
    );
  }, [dispatch, data]);

  return (
    <Tooltip content={intl.formatMessage(messages.acceptOvertime)} yOffset={BUTTON_BAR_TOOLTIP_OFFSET}>
      <Button onClick={onClick} disabled={disabled || isEmptyArray(data)} modifiers="reverse-blue onlyIcon">
        <i className="material-icons">more_time</i>
      </Button>
    </Tooltip>
  );
};
