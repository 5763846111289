import { UserJobTitle } from '@/types/jobTitles.types';
import { transformToDict } from '@/utils/objectHelpers/objectHelpers';

import { RootState } from '../types';

export const selectUserJobTitle = (id?: Nullable<UserJobTitle['id']>) => (state: RootState) => {
  if (!id) return undefined;
  return state.reducer.userJobTitles.find(jobTitle => jobTitle.id === id);
};

export const selectUserJobTitles = (state: RootState) => state.reducer.userJobTitles;

export const selectUserJobTitlesDict = (state: RootState) => transformToDict(state.reducer.userJobTitles, 'id');
