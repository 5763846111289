import { CellContext } from '@tanstack/react-table';
import { cloneElement } from 'react';

import { MDTableProps, TableRow } from '../../MDTable.types';

type Props<T extends object> = CellContext<TableRow<T>, unknown> & {
  options: MDTableProps<T>['options'];
};

export const OptionCell = <T extends object>({ options, cell }: Props<T>) => {
  if (cell.row.getCanExpand()) return null;
  return <div className="mdTableOptionCell">{cloneElement(options, { rowData: cell.row.original })}</div>;
};
