import classNames from 'classnames';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { startShiftDrag, startSwapping, stopShiftDrag, stopSwapping } from '@/actions/schedule/dragAndDrop';
import { showDeleteShiftForEmployeeConfirmModal } from '@/actions/schedule/shifts';
import { showModal } from '@/actions/uiState';
import { absenceStatuses } from '@/constants/absences';
import { LIGHT_GRAY, SCSS_COLORS } from '@/constants/colors';
import { ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectEmployeeOrLoanedEmployeeById, selectIsEmployeeHiredInDate } from '@/redux-store/employees';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { selectIsAnyShiftDragged } from '@/redux-store/schedule/dragAndDrop';
import { selectIsQuickPlanningEnabled } from '@/redux-store/schedule/quickPlanning';
import { selectIsAvailabilitiesViewMode } from '@/redux-store/schedule/viewMode';
import { selectShiftForEmployeeByShiftId } from '@/redux-store/shifts';
import { selectUserLocationsDict } from '@/redux-store/userLocations';
import { selectRestrictionsDict } from '@/redux-store/userPermissions';
import { selectUserJobTitles } from '@/selectors/userJobTitles.selectors';
import { Absence, EmployeeWhole, UserJobTitle, UserLocation } from '@/types';
import { Shift } from '@/types/shifts.types';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { throttle } from '@/utils/performance/performance.utils';
import { getScheduleBlockSize } from '@/utils/schedule/scheduleStyles/scheduleStyles';
import { getScheduleShiftTitle } from '@/utils/shiftHelpers';
import { checkIsSwappingActive } from '@/utils/styles';

import {
  addTooltipToDragImage,
  dragAndDropCleanup,
  shouldShiftConnectWithAbsence,
} from '../../ScheduleShiftBlock/ScheduleShiftBlock.helpers';
import ScheduleShiftBlockDropAreaRedux from '../../ScheduleShiftBlock/ScheduleShiftBlockDropArea/ScheduleShiftBlockDropArea.redux';
import { GroupNodeType } from '../../ScheduleTableBody/GroupedEmployees/GroupedEmployees.types';
import { getGroupJobTitleId } from '../../ScheduleTableRow/ScheduleTableRow.utils';

type Props = {
  absencesForDate: Absence[];
  employeeId: EmployeeWhole['id'];
  groupNodeId: Nullable<GroupNodeType['id']>;
  isAbsenceConnectingWithAnyScheduleBlock: boolean;
  isFromOtherLocation: boolean;
  isJobTitleNotAssigned: boolean;
  shiftId: Shift['id'];
  shiftJobTitle?: UserJobTitle;
  shiftLocationName?: UserLocation['name'];
  currentLocationId: UserLocation['id'];
};

export const ScheduleShiftContent = memo(
  ({
    absencesForDate,
    employeeId,
    groupNodeId,
    isAbsenceConnectingWithAnyScheduleBlock,
    isFromOtherLocation,
    isJobTitleNotAssigned,
    shiftId,
    shiftJobTitle,
    shiftLocationName,
    currentLocationId,
  }: Props) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const shift = useAppSelector(selectShiftForEmployeeByShiftId(employeeId, shiftId));
    const { comment, date, draft, location, working_hours } = shift || {};
    const userJobTitles = useAppSelector(selectUserJobTitles);
    const restrictions = useAppSelector(selectRestrictionsDict);
    const userLocationsDict = useAppSelector(selectUserLocationsDict);
    const dateArray = useAppSelector(selectDateArray);
    const isQuickPlanningEnabled = useAppSelector(selectIsQuickPlanningEnabled);
    const isAvaViewMode = useAppSelector(selectIsAvailabilitiesViewMode);
    const relevantEmployee = useAppSelector(selectEmployeeOrLoanedEmployeeById(employeeId));
    const isDateWithinEmploymentPeriod = useAppSelector(selectIsEmployeeHiredInDate(employeeId, date));
    const isAnyShiftDragged = useAppSelector(selectIsAnyShiftDragged);
    const isEditDisabled = restrictions[SCHEDULE_EDIT_DISABLE];
    const hasComment = comment?.length > 0;
    const [from, to] = working_hours?.split('-') || [];
    const size = useMemo(() => getScheduleBlockSize(dateArray), [dateArray]);
    const missingJobTitle = !shiftJobTitle;
    const title = useMemo(
      () => (missingJobTitle ? '-' : getScheduleShiftTitle(shift, userJobTitles)),
      [missingJobTitle, shift, userJobTitles],
    );
    const dayHasMultipleAbsences = absencesForDate.length > 1;
    const isAbsenceDraft = absencesForDate[0]?.status === absenceStatuses.draft;
    const shouldConnectWithAbsence = useMemo(
      () => shouldShiftConnectWithAbsence(absencesForDate[0], shift || {}, dayHasMultipleAbsences),
      [absencesForDate, shift, dayHasMultipleAbsences],
    );
    const style = useMemo(
      () => ({
        backgroundColor: shouldConnectWithAbsence ? LIGHT_GRAY : shiftJobTitle?.color || SCSS_COLORS.red_500,
      }),
      [shiftJobTitle, shouldConnectWithAbsence],
    );
    const isEditAllowed = !!shiftLocationName && !isEditDisabled;
    const isInvalid = missingJobTitle || isJobTitleNotAssigned;

    const hideDeleteIcon = shift?.isLoaned && !userLocationsDict[location.id];

    const className = classNames('k-scheduleBlock', `k-scheduleBlock--${size}`, {
      'k-scheduleBlock--fromOtherLocation': isFromOtherLocation,
      'k-scheduleBlock--draft': draft,
      'k-scheduleBlock--invalid': isInvalid,
      'k-scheduleBlock--noTitle': !title,
      'k-scheduleBlock--readonly': isQuickPlanningEnabled || isEditDisabled,
      'k-scheduleBlock--avaMode': isAvaViewMode,
      'k-scheduleBlock--hidden': isAnyShiftDragged,
      'k-scheduleBlock--absenceInDay': !isEmptyArray(absencesForDate) && isAbsenceConnectingWithAnyScheduleBlock,
      'k-scheduleBlock--isAbsenceInDayDraft': isAbsenceDraft,
      'k-scheduleBlock--connectWithAbsence': shouldConnectWithAbsence,
    });

    const onAdd = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        if (isEditDisabled) return;
        const initialJobTitleId = getGroupJobTitleId(groupNodeId);
        dispatch(
          showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
            employee: relevantEmployee,
            date,
            location: { id: currentLocationId },
            initialJobTitleId,
          }),
        );
      },
      [dispatch, isEditDisabled, relevantEmployee, date, location, groupNodeId],
    );

    const onEdit = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        if (isQuickPlanningEnabled || !isEditAllowed) return;
        dispatch(
          showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
            employee: relevantEmployee,
            shift,
            location: { id: currentLocationId },
            isLoanedEmployee: shift.isLoaned,
            deleteDisabled: !userLocationsDict[location.id],
          }),
        );
      },
      [
        dispatch,
        isEditAllowed,
        isQuickPlanningEnabled,
        shift,
        location,
        relevantEmployee,
        userLocationsDict,
        currentLocationId,
      ],
    );

    const onDelete = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        if (isEditDisabled) return;
        dispatch(showDeleteShiftForEmployeeConfirmModal(employeeId, shiftId));
      },
      [dispatch, employeeId, isEditDisabled, shiftId],
    );

    const onDragStart = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        addTooltipToDragImage(e, intl);
        requestAnimationFrame(() => {
          dispatch(startShiftDrag(shiftId, employeeId, from, to, date));
        });
      },
      [intl, dispatch, shiftId, employeeId, date, from, to],
    );

    const onDragEnd = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        requestAnimationFrame(() => {
          dragAndDropCleanup();
          if (checkIsSwappingActive(e)) dispatch(stopSwapping());
          dispatch(stopShiftDrag());
        });
      },
      [dispatch],
    );

    const onDragCapture = useCallback(
      throttle((e: React.DragEvent<HTMLDivElement>) => {
        if (checkIsSwappingActive(e)) dispatch(startSwapping());
        else dispatch(stopSwapping());
      }),
      [dispatch],
    );

    return (
      <div
        className={className}
        style={style}
        onClick={onEdit}
        draggable={isEditAllowed && !isQuickPlanningEnabled && !shift.isLoaned}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={e => e.preventDefault()}
        role="presentation"
        onDragCapture={onDragCapture}
      >
        <ScheduleShiftBlockDropAreaRedux shiftId={shiftId} employeeId={employeeId} />
        <div className="k-scheduleBlock__infoContainer">
          <span className="k-scheduleBlock__hours k-scheduleBlock__hours--from">{from}</span>
          <span className="k-scheduleBlock__hours k-scheduleBlock__hours--to">{to}</span>
          <span className="k-scheduleBlock__jobTitle">{title}</span>
          {isInvalid && <i className="material-icons-outlined k-scheduleBlock__errorIcon">error_outline</i>}
          {hasComment && <i className="material-icons k-scheduleBlock__commentIcon">message</i>}
        </div>
        <div className="k-scheduleBlock__iconContainer">
          {!hideDeleteIcon && (
            <i
              className="material-icons k-scheduleBlock__icon k-scheduleBlock__icon--delete"
              onClick={onDelete}
              role="presentation"
            >
              delete
            </i>
          )}
          <i
            className="material-icons k-scheduleBlock__icon k-scheduleBlock__icon--edit"
            onClick={onEdit}
            role="presentation"
          >
            edit
          </i>

          {isDateWithinEmploymentPeriod && (
            <i
              className="material-icons k-scheduleBlock__icon k-scheduleBlock__icon--add"
              onClick={onAdd}
              role="presentation"
            >
              add
            </i>
          )}
        </div>
      </div>
    );
  },
);

ScheduleShiftContent.displayName = 'ScheduleShiftContent';
