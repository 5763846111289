import moment from 'moment';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import ScheduleAbsenceBlock from '@/components/scheduler/ScheduleTable/ScheduleAbsenceBlock/ScheduleAbsenceBlock';
import { useAppSelector } from '@/redux-store';
import { selectAbsenceTypeById } from '@/redux-store/absences';
import { selectEmployeeFullNameById } from '@/redux-store/employees';
import { Absence } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { getAbsenceStatusName } from '@/utils/absenceHelpers';

type AbsenceBlockProps = {
  date: StandardDate;
  absence: Absence;
  isMonthView: boolean;
};

export const AbsenceBlock = memo(({ absence, date, isMonthView }: AbsenceBlockProps) => {
  const { absence_hours, from, to, type_id, status } = absence;
  const intl = useIntl();
  const absenceType = useAppSelector(selectAbsenceTypeById(type_id));
  const employeeName = useAppSelector(selectEmployeeFullNameById(absence.employee_id));
  const absencesDatesArray = useMemo(
    () =>
      Array.from({ length: moment(to).diff(from, 'days') + 1 }, (_, idx) =>
        moment(from).add(idx, 'days').format('YYYY-MM-DD'),
      ),
    [from, to],
  );
  const absenceName =
    (isMonthView || absence_hours) && absencesDatesArray.length === 1 ? absenceType?.short_name : absenceType?.name;
  const typeName = absenceType?.name;
  const statusName = useMemo(() => getAbsenceStatusName(status, intl), [status, intl]);

  return (
    <ScheduleAbsenceBlock
      absence={absence}
      absenceName={absenceName}
      date={date}
      employeeName={employeeName}
      typeName={typeName}
      statusName={statusName}
    />
  );
});

AbsenceBlock.displayName = 'AbsenceBlock';
