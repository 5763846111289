import classNames from 'classnames';
import { PropTypes } from 'prop-types';

const Wrapper = props => {
  let { modifiers = [] } = props;
  if (!Array.isArray(modifiers)) {
    modifiers = modifiers.split(' ');
  }
  const className = classNames(
    'k-wrapper',
    'animated',
    'fadeInRight',
    props.className,
    ...modifiers.map(modifier => `k-wrapper--${modifier}`),
  );

  return <div className={className}>{props.children}</div>;
};
Wrapper.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.node,
};
export default Wrapper;
