import { memo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectAreEmployeesGrouped } from '@/redux-store/employeeGrouping';
import { selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { Location } from '@/types';

import { GroupedEmployees } from './GroupedEmployees/GroupedEmployees';
import { LoanedEmployees } from './LoanedEmployees/LoanedEmployees';
import { RegularEmployees } from './RegularEmployees/RegularEmployees';
import { SupplementaryEmployees } from './SupplementaryEmployees/SupplementaryEmployees';

type Props = {
  locationId: Location['id'];
};

export const ScheduleTableBody = memo(({ locationId }: Props) => {
  const areEmployeesGrouped = useAppSelector(selectAreEmployeesGrouped);
  const isSingleDay = useAppSelector(selectIsDayDateMode);

  if (areEmployeesGrouped && !isSingleDay) return <GroupedEmployees locationId={locationId} />;

  return (
    <tbody>
      <RegularEmployees locationId={locationId} />
      <SupplementaryEmployees locationId={locationId} />
      <LoanedEmployees locationId={locationId} />
    </tbody>
  );
});

ScheduleTableBody.displayName = 'ScheduleTableBody';
