import { AvailabilityType } from 'kadro-helpers/lib/types';

import { AttendancesStoreState } from '@/redux-store/attendances/attendances.types';
import { Attendance } from '@/redux-store/attendances/attendancesData';
import { UserEmployee } from '@/types';
import { EmployeeNameData, EmployeeNameObject } from '@/types/employeesNames';
import { LoanedEmployees, LoanedEmployeesData } from '@/types/scheduleLoanedEmployees';
import { Shift } from '@/types/shifts.types';

interface ProcessedEmployee {
  id: string;
  first_name: string;
  last_name: string;
  attendances: Attendance[];
  shifts: Shift[];
  availability_blocks: AvailabilityType[];
  isLoaned: boolean;
}

export const filterAttendances = (
  attendancesData: AttendancesStoreState['attendancesData']['attendances'],
  selectedLocationIds: string[],
  userEmployees: UserEmployee[],
  currentDate: string,
) =>
  Object.values(attendancesData)
    .flat()
    .filter(attendance => {
      const isAttendanceInLocations = selectedLocationIds.includes(attendance.location.id);
      const locationIdsEmployeeConnectedWithAttendance = userEmployees
        .find(({ id }) => id === attendance.employee_id)
        ?.locations.map(location => location.id);

      const isValidDate = currentDate === attendance.date;
      if (!locationIdsEmployeeConnectedWithAttendance?.length && isAttendanceInLocations && isValidDate) {
        return true;
      }
      const hasEmployeeLocation = locationIdsEmployeeConnectedWithAttendance?.includes(attendance.location.id);

      return isAttendanceInLocations && !hasEmployeeLocation && isValidDate;
    });

export const parseLoanedEmployees = (
  filteredAttendances: Attendance[],
  employeesNames: EmployeeNameData,
  scheduleLoanedEmployees: LoanedEmployeesData,
  currentDate: string,
  selectedLocationIds: string[],
) => {
  const processedEmployees = processExistingEmployees(
    filteredAttendances,
    employeesNames,
    scheduleLoanedEmployees,
    currentDate,
    selectedLocationIds,
  );
  const processedNewEmployees = processNewEmployees(
    scheduleLoanedEmployees,
    processedEmployees,
    currentDate,
    selectedLocationIds,
  );

  return Object.values(processedNewEmployees);
};

export const processExistingEmployees = (
  filteredAttendances: Attendance[],
  employeesNames: EmployeeNameData,
  scheduleLoanedEmployees: LoanedEmployeesData,
  currentDate: string,
  selectedLocationIds: string[],
) =>
  filteredAttendances.reduce((processedEmployees, attendance) => {
    const basicEmployeeData = employeesNames[attendance.employee_id];
    const isAttendnaceInLocations = selectedLocationIds.includes(attendance.location.id);
    if (basicEmployeeData && isAttendnaceInLocations) {
      if (!processedEmployees[attendance.employee_id]) {
        const loanedEmployee = scheduleLoanedEmployees[attendance.employee_id];
        const shifts = getShiftsForEmployee(loanedEmployee, currentDate, selectedLocationIds);
        processedEmployees[attendance.employee_id] = createProcessedEmployee(basicEmployeeData, shifts);
      }
      processedEmployees[attendance.employee_id].attendances.push(attendance);
    }
    return processedEmployees;
  }, {});

export const processNewEmployees = (
  scheduleLoanedEmployees: LoanedEmployeesData,
  processedEmployees: { [key: string]: ProcessedEmployee },
  currentDate: string,
  selectedLocationIds: string[],
) =>
  Object.entries(scheduleLoanedEmployees).reduce(
    (acc, [employeeId, loanedEmployee]) => {
      const shifts = getShiftsForEmployee(loanedEmployee, currentDate, selectedLocationIds);

      const isEmployeeExisting = processedEmployees[employeeId];
      if (!isEmployeeExisting && shifts.length) {
        acc[employeeId] = createProcessedEmployeeForNewEmployee(loanedEmployee, shifts);
      }
      return acc;
    },
    { ...processedEmployees },
  );

export const createProcessedEmployee = (basicEmployeeData: EmployeeNameObject, shifts: Shift[]): ProcessedEmployee => ({
  id: String(basicEmployeeData.id),
  first_name: basicEmployeeData.first_name,
  last_name: basicEmployeeData.last_name,
  attendances: [],
  shifts,
  availability_blocks: [],
  isLoaned: true,
});

export const createProcessedEmployeeForNewEmployee = (
  loanedEmployee: LoanedEmployees,
  shifts: Shift[],
): ProcessedEmployee => ({
  first_name: loanedEmployee.first_name,
  last_name: loanedEmployee.last_name,
  id: String(loanedEmployee.id),
  attendances: [],
  shifts,
  availability_blocks: [],
  isLoaned: true,
});

export const getShiftsForEmployee = (
  employee: LoanedEmployees,
  date: string,
  selectedLocationIds: string[],
): Shift[] =>
  employee
    ? employee.shiftsData.filter(
        ({ date: shiftDate, location, isLoaned }) =>
          shiftDate === date && selectedLocationIds.includes(location.id) && isLoaned,
      )
    : [];
