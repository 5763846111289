import { CellContext } from '@tanstack/react-table';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { TableRow } from '@/components/common/MDTable/MDTable.types';

import { AbsenceLimitRow } from '../AbsencesLimitsView.types';
import { messages } from '../messages';

import './AbsenceLeftCell.scss';

type Props = CellContext<TableRow<AbsenceLimitRow>, unknown>;

export const AbsenceLeftCell = (props: Props) => {
  const { left, unit, period } = props.cell.row.original;
  const intl = useIntl();

  return left || left === 0 ? (
    <span className={classNames(left < 0 && 'k-absenceLeftCell--error')}>{`${left} ${unit} / ${period}`}</span>
  ) : (
    intl.formatMessage(messages.absencesLimitsViewAbsenceLimitUnlimited)
  );
};
