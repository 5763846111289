import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { filterMetricsLocations } from './BudgetMetricsView.helpers';
import AddOrEditBudgetMetricModal from './BudgetMetricsViewComponents/AddOrEditBudgetMetricModal/AddOrEditBudgetMetricModal.redux';
import BudgetMetricsTable from './BudgetMetricsViewComponents/BudgetMetricsTable/BudgetMetricsTable.redux';

const BudgetMetricsView = ({ userLocations, budgetMetrics }) => {
  const [budgetMetricsWithLocations, setBudgetMetricsWithLocations] = useState(budgetMetrics);

  useEffect(() => {
    setBudgetMetricsWithLocations(
      budgetMetrics.map(({ location_ids: locationIds, ...rest }) => ({
        ...rest,
        locations: filterMetricsLocations(locationIds, userLocations),
      })),
    );
  }, [userLocations, budgetMetrics]);

  return (
    <div className="animated fadeInRight">
      <AddOrEditBudgetMetricModal />
      <BudgetMetricsTable budgetMetrics={budgetMetricsWithLocations} />
    </div>
  );
};

BudgetMetricsView.propTypes = {
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  budgetMetrics: PropTypes.arrayOf(PropTypes.shape({})),
};

export default BudgetMetricsView;
