import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DashboardTimeTracker } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTracker';
import { DashboardDateMode } from '@/components/dashboard/employee/DashboardDateMode';
import { useAppSelector } from '@/redux-store';
import { selectIsLoggedUserEmployee } from '@/redux-store/userPermissions';
import { selectCurrentUserFirstName } from '@/selectors/currentUser.selectors';

import './DashboardTopBar.scss';

export const DashboardTopBar = () => {
  const isEmployee = useAppSelector(selectIsLoggedUserEmployee);
  const initialTime = moment().format('HH:mm');
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format('HH:mm'));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const firstName = useAppSelector(selectCurrentUserFirstName);
  const currentDate = useMemo(() => moment().format('dddd, DD MMM YYYY'), [time]);

  return (
    <div className="dashboardTopBar">
      {isEmployee ? (
        <DashboardDateMode modes={[0, 1]} />
      ) : (
        <h2 className="dashboardTopBar__title">
          <FormattedMessage id="dashboard.topBar.goodMorning" defaultMessage="Dzień dobry" /> {firstName}!
        </h2>
      )}
      <div className="dashboardTopBar__right">
        <div className="dashboardTopBar__timeTracker">
          <DashboardTimeTracker />
        </div>
        <div className="dashboardTopBar__dateTime">
          <div className="dashboardTopBar__time">{time}</div>
          <div className="dashboardTopBar__date">{currentDate}</div>
        </div>
      </div>
    </div>
  );
};
