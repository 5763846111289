import AttendanceTableViewContainer from '@/components/attendance/AttendanceTableView.redux.js';
import { useAppSelector } from '@/redux-store';
import { selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { selectIsLoggedUserEmployee } from '@/redux-store/userPermissions';

import TopBarAttendance from '../TopBars/TopBarAttendance';
import { AttendanceManyDaysView } from './AttendanceManyDaysView/AttendanceManyDaysView';

const AttendanceView = () => {
  const isEmployee = useAppSelector(selectIsLoggedUserEmployee);
  const isSingleDay = useAppSelector(selectIsDayDateMode);

  return (
    <>
      <TopBarAttendance isSingleDay={isSingleDay} isEmployee={isEmployee} />
      {isSingleDay ? <AttendanceTableViewContainer /> : <AttendanceManyDaysView />}
    </>
  );
};

export default AttendanceView;
