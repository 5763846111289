import MDTable from '@/components/common/MDTable/MDTable';
import EmptyState from '@/components/emptyState/EmptyState';

import AbsencesLimitsViewOptionsContainer from '../AbsencesLimitsViewOptions/AbsencesLimitsViewOptions.redux';
import { messages } from '../messages';
import { useAbsencesLimitsTable } from './useAbsencesLimitsTable';

export const AbsencesLimitsTable = () => {
  const {
    changeCurrentPage,
    changeNumberOfItemsPerPage,
    columns,
    currentPage,
    data,
    intl,
    isEmployee,
    numberOfItems,
    numberOfItemsPerPage,
  } = useAbsencesLimitsTable();

  return (
    <MDTable
      data={data}
      columns={columns}
      currentPage={currentPage}
      changeCurrentPage={changeCurrentPage}
      numberOfItemsPerPage={numberOfItemsPerPage}
      changeNumberOfItemsPerPage={changeNumberOfItemsPerPage}
      numberOfPages={Math.ceil(numberOfItems / numberOfItemsPerPage)}
      options={<AbsencesLimitsViewOptionsContainer />}
      tableEmpty={
        <EmptyState
          name="jobtitleCTA"
          imgSrc="/img/jobtitlesCTA.png"
          emptyStateHeader={intl.formatMessage(messages.absencesLimitsViewEmptyTable)}
        />
      }
      withPagination={!isEmployee}
      evenlyColumns
      manualFiltering
    />
  );
};
