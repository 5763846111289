import { EmployeeWhole, Location } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { EmployeeGroupingEnum } from '@/types/employeeGrouping';
import { LoanedEmployees } from '@/types/scheduleLoanedEmployees';
import { Shift, ShiftsDict } from '@/types/shifts.types';
import { isEmptyArray, sortByStartTimestamp } from '@/utils/array/array.helpers';

import { GroupNodeType } from '../ScheduleTableBody/GroupedEmployees/GroupedEmployees.types';
import { decodeGroupNodeId } from '../ScheduleTableBody/GroupedEmployees/GroupedEmployees.utils';

export const getShiftsForDate = (
  employee: EmployeeWhole | LoanedEmployees,
  areShiftsFromOtherLocationsVisible,
  shifts: ShiftsDict,
  locationId: Location['id'],
  date: StandardDate,
): { visible: Shift[]; hidden: Shift[] } => {
  const shiftsIds = employee.shifts[date];

  if (!shiftsIds) return { visible: [], hidden: [] };

  const employeeShifts = shiftsIds.reduce(
    (acc, shiftId) => {
      const shift = shifts[shiftId];
      if (!shift) return acc;
      return !areShiftsFromOtherLocationsVisible && shift.location.id !== locationId
        ? { ...acc, hidden: [...acc.hidden, shift] }
        : { ...acc, visible: [...acc.visible, shift] };
    },
    { visible: [], hidden: [] },
  );

  return {
    visible: sortByStartTimestamp(employeeShifts.visible),
    hidden: sortByStartTimestamp(employeeShifts.hidden),
  };
};

export const getPlaceholderPadding = (
  isAbsence: boolean,
  isOvertime: boolean,
  isFreeDay: boolean,
  isMonth: boolean,
) => {
  const addMonth = isMonth ? '--month' : '';
  const truthyArr = [isAbsence, isOvertime, isFreeDay].filter(Boolean);
  if (isEmptyArray(truthyArr)) return '';
  return `withPadding_${truthyArr.length}${addMonth}`;
};

export const getGroupJobTitleId = (id: GroupNodeType['id']) => {
  const { groupId, groupKey } = decodeGroupNodeId(id);
  if (groupId === EmployeeGroupingEnum.JOB_TITLE) return groupKey;
  return undefined;
};
