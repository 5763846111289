import { CellContext } from '@tanstack/react-table';
import { memo, useCallback, useMemo } from 'react';

import { ExpandButton } from '@/components/common/Basic/ExpandButton/ExpandButton';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { useAppSelector } from '@/redux-store';
import { selectAttendancesVisibleColumns } from '@/redux-store/attendances/uiState';
import { convertDateToCustomFormat } from '@/utils/dateHelper';

import { AttendanceRowItem } from '../../AttendanceManyDaysTable.types';

import './ExpandCell.scss';

export type ExpandCellProps = CellContext<TableRow<AttendanceRowItem>, unknown>;

export const ExpandCell = memo((props: ExpandCellProps) => {
  const { cell, column } = props;
  const { row } = cell;
  const visibleColumns = useAppSelector(selectAttendancesVisibleColumns);

  const onToggle = useCallback(() => row.toggleExpanded(), [row]);

  const date = useMemo(() => convertDateToCustomFormat(row.original.date, 'DD.MM dddd'), [row]);

  if ((column.id === 'employee' && visibleColumns.locationName) || !row.getCanExpand())
    return cell.renderValue() as string;

  return (
    <div className="k-expandCell">
      <div>
        <ExpandButton isOpen={row.getIsExpanded()} toggle={onToggle} />
      </div>
      <div className="k-expandCell__text">{date}</div>
    </div>
  );
});

ExpandCell.displayName = 'ExpandCell';
