import { defineMessages, IntlShape } from 'react-intl';

import { addMultipleShifts, notifyAboutFailureAddManyShifts } from '@/actions/schedule';
import { notifyAboutNoShiftsToPaste } from '@/actions/schedule/quickPlanning';
import { selectContracts } from '@/redux-store/contracts';
import { AppDispatch, GetStateType } from '@/redux-store/types';
import { selectUserEmployeeById } from '@/redux-store/userEmployees';
import { selectUserJobTitles } from '@/redux-store/userJobTitles';
import { ShiftToCreate } from '@/types/shifts.types';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { getJobTitleFromContracts } from '@/utils/contracts';
import { timestampsToWorkingHours } from '@/utils/dateHelper';
import { checkShiftOverlap } from '@/utils/schedulerHelpers';

import { Attendance } from './attendancesData.types';

const messages = defineMessages({
  shiftForAttendanceComment: {
    id: 'shiftForAttendanceComment',
    defaultMessage: 'Zatwierdzona zmiana z listy obecności',
  },
});

export const createShiftKey = (shift: ShiftToCreate) =>
  `${shift.employee.id}_${shift.location.id}_${shift.date}_${shift.working_hours}`;

export const addShiftsForAttendances =
  (
    attendances: Partial<
      Pick<Attendance, 'employee_id' | 'date' | 'location' | 'start_timestamp' | 'end_timestamp' | 'id'>
    >[],
  ) =>
  (dispatch: AppDispatch, getState: GetStateType, intl: IntlShape) => {
    const state = getState();
    let notAddedShiftsAmount = 0;
    const contracts = selectContracts(state);
    const userJobTitles = selectUserJobTitles(state);
    const shiftKeyAttendanceIds: Record<string, Attendance['id']> = {};
    const shifts = attendances.reduce((prev, attendance) => {
      const userEmployee = selectUserEmployeeById(attendance.employee_id)(state);
      if (!userEmployee) return prev;
      const jobTitle = getJobTitleFromContracts(contracts, userJobTitles, userEmployee.id, attendance.date);
      const newShift: ShiftToCreate = {
        comment: intl.formatMessage(messages.shiftForAttendanceComment),
        date: attendance.date,
        draft: false,
        employee: { id: attendance.employee_id },
        job_title: jobTitle || userEmployee.terms[0].job_title,
        location: { id: attendance.location.id },
        working_hours: timestampsToWorkingHours(attendance.start_timestamp, attendance.end_timestamp),
      };
      const isOverlap = checkShiftOverlap(userEmployee, newShift);
      if (isOverlap) {
        notAddedShiftsAmount++;
        return prev;
      }
      shiftKeyAttendanceIds[createShiftKey(newShift)] = attendance.id;
      return [...prev, newShift];
    }, []);

    if (isEmptyArray(shifts)) {
      if (notAddedShiftsAmount) dispatch(notifyAboutFailureAddManyShifts(notAddedShiftsAmount));
      else dispatch(notifyAboutNoShiftsToPaste());
    } else {
      dispatch(addMultipleShifts(shifts, notAddedShiftsAmount, 'blocking', shiftKeyAttendanceIds));
    }
  };
