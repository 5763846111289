import { createSelector } from '@reduxjs/toolkit';

import { EmployeeWhole } from '@/types';

import { RootState } from '../types';

const selectScheduleAbsences = (state: RootState) => state.reducer.absences.scheduleAbsences;

export const selectScheduleAbsencesByEmployeeId = (employeeId: EmployeeWhole['id']) =>
  createSelector(selectScheduleAbsences, scheduleAbsences => scheduleAbsences[employeeId]);

export const selectAbsencesTypes = (state: RootState) => state.reducer.absences.absencesTypes;

export const selectAbsenceTypeById = (absenceTypeId: string) =>
  createSelector(selectAbsencesTypes, absencesTypes =>
    absencesTypes.find(absenceType => absenceType.id === absenceTypeId),
  );

export const selectAbsencesSchedule = (state: RootState) => state.reducer.absences.scheduleAbsences;

export const selectAbsenceTableCurrentPage = (state: RootState) => state.reducer.absences.absencesTable.currentPage;

export const selectAbsenceTableNumberOfItemsPerPage = (state: RootState) =>
  state.reducer.absences.absencesTable.numberOfItemsPerPage;

export const selectAbsenceTableNumberOfItems = (state: RootState) => state.reducer.absences.absencesTable.numberOfItems;

export const selectAbsenceLimitsUsage = (state: RootState) => state.reducer.absences.absenceLimitsUsage;

export const selectAbsenceLimitsForEmployee = (state: RootState) => state.reducer.absences.absenceLimitsForEmployee;
