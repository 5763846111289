import Icon from '@/components/common/Basic/Icon/Icon';
import TopBarMultipleChoice from '@/components/TopBars/common/TopBarMultipleChoice/TopBarMultipleChoice';

import { useAttendanceManyDaysColumnFilter } from './useAttendanceManyDaysColumnFilter';

export const AttendanceManyDaysColumnFilter = () => {
  const { columns, onChange, selectedColumns } = useAttendanceManyDaysColumnFilter();

  return (
    <div className="k-topBarMultipleColumnFilter k-topBarMultipleColumnFilter--onlyIcon">
      <TopBarMultipleChoice
        disableDefaultSort
        objectsToChoose={columns}
        selectedObjects={selectedColumns}
        onChange={onChange}
        className="k-topBarChoice--small k-topBarChoice--pinned-right"
        icon={<Icon type="material-outlined" name="view_column" />}
        displayKey="header"
        onlyIcon
      />
    </div>
  );
};
