import TopBarAbsencesLimits from '@/components/TopBars/TopBarAbsencesLimits/TopBarAbsencesLimits.redux.js';

import AbsenceCalendarModal from '../modals/AbsenceCalendarModal/AbsenceCalendarModal.redux';
import { AbsencesLimitsTable } from './AbsencesLimitsTable/AbsencesLimitsTable';

export const AbsencesLimitsView = () => (
  <>
    <AbsenceCalendarModal />
    <TopBarAbsencesLimits />
    <AbsencesLimitsTable />
  </>
);
