import { KebabMenu } from '@/components/common/Basic/KebabMenu/KebabMenu';

import { SubRowType } from '../../../AttendanceManyDaysTable.types';
import { useKebabOptions } from './useKebabOptions';

type Props = {
  rowData: SubRowType;
};

export const AttendanceKebabOptions = ({ rowData }: Props) => {
  const { disabled, options } = useKebabOptions(rowData);

  return <KebabMenu options={options} disabled={disabled} />;
};
