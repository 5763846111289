import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { getAbsenceLimitsForEmployee, getAbsenceLimitsUsage } from '@/actions/absences';
import { mainDateChangeMode } from '@/actions/mainDate';
import { MDTableProps } from '@/components/common/MDTable/MDTable.types';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectSelectedAbsence } from '@/redux-store/absenceFilter';
import {
  selectAbsenceLimitsForEmployee,
  selectAbsenceLimitsUsage,
  selectAbsencesTypes,
  selectAbsenceTableCurrentPage,
  selectAbsenceTableNumberOfItems,
  selectAbsenceTableNumberOfItemsPerPage,
} from '@/redux-store/absences';
import { selectCustomDate, selectMainDateStore } from '@/redux-store/mainDateStore';
import { selectUserEmployees } from '@/redux-store/userEmployees';
import { selectIsLoggedUserEmployee } from '@/redux-store/userPermissions';
import { selectCurrentUserId } from '@/selectors/currentUser.selectors';

import { AbsenceLimitRow } from '../AbsencesLimitsView.types';
import { getColumns, getEmployeeColumns } from '../columns';
import { getTableData } from '../utils';

export const useAbsencesLimitsTable = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const isEmployee = useAppSelector(selectIsLoggedUserEmployee);
  const customDate = useAppSelector(selectCustomDate);
  const mainDateStore = useAppSelector(selectMainDateStore);
  const selectedAbsence = useAppSelector(selectSelectedAbsence);
  const currentPage = useAppSelector(selectAbsenceTableCurrentPage);
  const numberOfItemsPerPage = useAppSelector(selectAbsenceTableNumberOfItemsPerPage);
  const numberOfItems = useAppSelector(selectAbsenceTableNumberOfItems);
  const userEmployees = useAppSelector(selectUserEmployees);
  const absenceLimitsUsage = useAppSelector(selectAbsenceLimitsUsage);
  const absenceLimitsForEmployee = useAppSelector(selectAbsenceLimitsForEmployee);
  const absencesTypes = useAppSelector(selectAbsencesTypes);

  const { dateMode } = mainDateStore;

  const [filters, setFilters] = useState<Record<string, string>>({});

  const onFilter = useCallback(
    (column: string, value: string) => {
      setFilters(prevFilters => ({
        ...prevFilters,
        [column]: value,
      }));
    },
    [setFilters],
  );

  const columns = useMemo(
    () => (!isEmployee ? getColumns(intl, onFilter) : getEmployeeColumns(intl)),
    [intl, onFilter, isEmployee],
  );

  const data = useMemo(
    () =>
      getTableData(
        absenceLimitsUsage,
        absenceLimitsForEmployee,
        absencesTypes,
        userEmployees,
        mainDateStore,
        isEmployee,
        intl,
      ),
    [absenceLimitsUsage, absenceLimitsForEmployee, absencesTypes, isEmployee, intl, mainDateStore, userEmployees],
  );

  const updateData = useCallback(
    (newPage: number, newNumberOfItemsPerPage?: number) => {
      const { employee: employeeQueryString } = filters;
      const { start, end } = customDate;
      dispatch(
        getAbsenceLimitsUsage(
          selectedAbsence,
          start,
          end,
          newPage,
          newNumberOfItemsPerPage || numberOfItemsPerPage,
          employeeQueryString,
        ),
      );
    },
    [customDate, filters, dispatch, numberOfItemsPerPage, selectedAbsence],
  );

  const changeNumberOfItemsPerPage: MDTableProps<AbsenceLimitRow>['changeNumberOfItemsPerPage'] = useCallback(
    event => {
      updateData(1, +event.target.value);
    },
    [updateData],
  );

  const changeCurrentPage: MDTableProps<AbsenceLimitRow>['changeCurrentPage'] = useCallback(
    newPage => {
      updateData(newPage);
    },
    [updateData],
  );

  useEffect(() => {
    if (isEmployee) {
      dispatch(getAbsenceLimitsForEmployee(currentUserId, customDate.start, customDate.end));
    } else if (dateMode !== selectedAbsence.limit_period) {
      dispatch(mainDateChangeMode(selectedAbsence.limit_period));
    } else {
      updateData(1);
    }
  }, [dateMode, isEmployee, currentUserId, customDate, selectedAbsence, mainDateStore, updateData, dispatch]);

  return {
    changeCurrentPage,
    changeNumberOfItemsPerPage,
    columns,
    currentPage,
    data,
    intl,
    isEmployee,
    numberOfItems,
    numberOfItemsPerPage,
  };
};
