import { deburr, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';
import DateInput from '@/components/common/inputs/DateInput/DateInput.jsx';
import LabeledTextInput from '@/components/common/inputs/LabeledTextInput.jsx';
import Select from '@/components/common/inputs/MDSelect/Select';
import SelectBlock from '@/components/common/inputs/SelectBlock/SelectBlock.jsx';
import ToggleBlock from '@/components/common/inputs/ToggleBlock/ToggleBlock.jsx';
import ToleranceBlock from '@/components/common/inputs/ToleranceBlock/ToleranceBlock.jsx';
import { allAuthMethods } from '@/components/settings/LocationSettingsView/constants';
import { mapPropsToStateObj } from '@/components/settings/LocationSettingsView/utils';
import { emptyOpenHours } from '@/constants/openHours.js';
import {
  OPEN_SHIFTS_SETTING_SHOW,
  OPENING_HOURS_VIEW,
  PRODUCTION_QUOTAS_SETTING_SHOW,
  RCP_GEOLOCALIZATION,
  TRADE_SHIFTS_SETTING_SHOW,
  WORKING_RULES_CHECK_CHANGE,
} from '@/constants/Permissions.js';
import { OPTION_TYPE } from '@/constants/settingsConstants.js';
import { checkArrayIntersection } from '@/utils/arrayHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { inputValidation } from '@/utils/inputValidation.js';
import { toUnderscore } from '@/utils/stringHelpers';
import { meters2kilometers } from '@/utils/unitsHelpers.js';
import { checkEmployeeIsManager } from '@/utils/userEmployeesHelpers';

import CheckboxList from '../../../SettingsCheckboxList/SettingsCheckboxList.jsx';
import {
  getBudgetOptions,
  getDevicesOptions,
  getOpeningHoursOptions,
  getOtherOptions,
  getRestrictionsOptions,
  getWorkingRulesOptions,
} from '../../options';
import { validateBonusSettings } from '../../options/BudgetOptions/BudgetOptions.helpers';
import { BonusTargetOptions, SettingsNameWithLabel, SingleTimeInput, WorkingHoursInputs } from '../index';

import './LocationSettingsContent.scss';

class LocationSettingsContent extends Component {
  constructor(props, context) {
    super(props, context);

    const location =
      props.userLocations.find(loc => loc.name === (props.params ? props.params.locationName : null)) ||
      props.userLocations[0];

    this.initialState = {
      ...mapPropsToStateObj(location.id, props.settings),
      location,
      availableAuthMethods: allAuthMethods,
      errors: {},
    };
    this.state = this.initialState;
    bindPrototypeFunctions(this);
    this.filterAuthMethods();
  }

  componentDidMount() {
    const location =
      this.props.userLocations.find(loc => loc.name === this.props.params?.locationName) || this.props.userLocations[0];
    this.changeLocation(location.id);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.settings) !== JSON.stringify(this.props.settings)) {
      this.changeLocation(this.state.location.id);
    }
  }

  filterAuthMethods() {
    const authMethods = [];
    if (this.state.authorization_pin_enable) authMethods.push(allAuthMethods[0]);
    if (this.state.authorization_qrcode_enable) authMethods.push(allAuthMethods[1]);
    const chosenOption = authMethods.find(o => o.id === this.state.authorization_method);
    if (!chosenOption) {
      this.setState({
        authorization_method: authMethods[0].id,
        availableAuthMethods: authMethods,
      });
    } else {
      this.setState({ availableAuthMethods: authMethods });
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    switch (name) {
      case 'authorization_pin_enable':
      case 'authorization_qrcode_enable':
        if (!value) {
          if (name === 'authorization_pin_enable' && !this.state.authorization_qrcode_enable) {
            this.setState({ authorization_qrcode_enable: true });
          } else if (name === 'authorization_qrcode_enable' && !this.state.authorization_pin_enable) {
            this.setState({ authorization_pin_enable: true });
          }
        }
        this.setState(
          {
            [name]: value,
          },
          () => {
            this.filterAuthMethods();
          },
        );
        break;
      case 'locationOpeningHoursEnabled':
        if (!value) {
          this.setState({ openHours: null, locationOpeningHoursEnabled: false });
        } else {
          this.setState({ locationOpeningHoursEnabled: true, openHours: emptyOpenHours });
        }
        break;
      default:
        this.setState({
          [name]: target.type === 'tolerance' ? Number(value) : value,
        });
        break;
    }
  }

  async validateInput(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const error = await inputValidation(target.name, value, {
      required: target.required,
    });
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [target.name]: error ? this.context.intl.formatMessage(error, {}) : error,
      },
    }));
  }

  validateAll() {
    const { allowEmployeeAttendaceOpenClose, enableLocalizationCheck, openHours, rcpRequiresGeolocation } = this.state;

    const newBonusSettings = validateBonusSettings(this.state, this.context.intl);

    return new Promise(resolve => {
      this.setState(
        {
          errors: {
            bonusHoursOverlaps: newBonusSettings.bonusHoursOverlapsError,
          },
          bonusHoursBlocks: newBonusSettings.bonusHoursBlocks,
          bonusTargetOptions: newBonusSettings.bonusTargetOptions,
        },
        async () => {
          const inputsToValidate = ['autoclose_attendances_at_time'];
          if (allowEmployeeAttendaceOpenClose && (enableLocalizationCheck || rcpRequiresGeolocation)) {
            inputsToValidate.push('latitude', 'longitude', 'attendanceLocationRadiusLimit');
          }

          if (openHours) {
            inputsToValidate.push('openHours');
          }

          await Promise.all(
            inputsToValidate.map(async input => {
              await this.validateInput({
                target: { name: input, value: this.state[input] },
              });
            }),
          );
          const errorValues = Object.values(this.state.errors);
          const canContinue =
            (errorValues.length === 0 || !errorValues.some(error => !!error)) &&
            newBonusSettings.areBonusSettingsCorrect;
          resolve(canContinue);
        },
      );
    });
  }

  async submit(options = { forAllLocations: false }) {
    const { forAllLocations } = options;
    const { currentUser, companyRoles } = this.props;
    const isOwner = currentUser.user.role === 'owner';
    const isManager = checkEmployeeIsManager(currentUser.user, companyRoles);

    const valid = await this.validateAll();
    if (!valid) return;
    let exclude = [
      'location',
      'errors',
      'authorization_qrcode_activity_autodisable',
      'availableAuthMethods',
      'location_coordinates',
      'longitude',
      'latitude',
      'attendanceLocationRadiusLimit',
    ];
    if (!isManager && !isOwner) {
      exclude = [...exclude, 'disable_location_attendances_edit_until', 'disable_location_schedule_shifts_edit_until'];
    }
    const keys = Object.keys(this.state).filter(k => !exclude.includes(k));
    const result = {};
    for (const key of keys) {
      switch (key) {
        default:
          result[toUnderscore(key)] = this.state[key];
          break;
      }
    }
    if (!this.state.authorization_qrcode_activity_autodisable) result.authorization_qrcode_activity_time = 0;
    if (this.state.enableLocalizationCheck || this.state.rcpRequiresGeolocation) {
      result.location_coordinates = `${Number(this.state.latitude).toFixed(7)} ${Number(this.state.longitude).toFixed(
        7,
      )}`;
      result.attendance_location_radius_limit = meters2kilometers(this.state.attendanceLocationRadiusLimit);
    }
    result.open_hours_based_on_schedule =
      this.state.locationOpeningHoursEnabled && this.state.openHoursMethod === 'schedule';
    if (result.open_hours_based_on_schedule) result.openHours = emptyOpenHours;
    if (forAllLocations) {
      this.props.changeLocationSettingsMass(result, this.state.location.id);
    } else {
      this.props.changeLocationSettings(this.state.location.id, {
        settings: result,
      });
    }
  }

  checkIfCanSave() {
    const {
      errors: _omitStateError,
      availableAuthMethods: _omitStateAva,
      location: _location,
      ...stateToCompare
    } = this.state;
    const {
      errors: _omitInitError,
      availableAuthMethods: _omitInitAva,
      location: _initialLocation,
      ...initialStateToCompare
    } = this.initialState;
    if (!this.state.locationSystemBonusEnabled) {
      delete stateToCompare.bonusHoursBlocks;
      delete stateToCompare.bonusTargetOptions;
      delete initialStateToCompare.bonusHoursBlocks;
      delete initialStateToCompare.bonusTargetOptions;
    }
    return JSON.stringify(stateToCompare) !== JSON.stringify(initialStateToCompare);
  }

  changeLocation(choice) {
    const location = this.props.userLocations.find(l => l.id === choice);
    const locationSettings = {};
    const newSettings = this.props.settings.locationSettings[choice];
    locationSettings[choice] = newSettings;
    const newState = mapPropsToStateObj(location.id, { locationSettings });
    this.initialState = { ...this.state, ...newState, location };
    this.setState(prevState => ({ ...prevState, ...newState, location, errors: {} }));
  }

  render() {
    const {
      currentUser,
      companyRoles,
      userPermissions: { permissions, restrictions },
    } = this.props;
    const isRcpGeolocalizationVisible = permissions.includes(RCP_GEOLOCALIZATION);

    const isOwner = currentUser.user.role === 'owner';
    const isManager = checkEmployeeIsManager(currentUser.user, companyRoles);

    const workingRulesOptions =
      !permissions.includes(WORKING_RULES_CHECK_CHANGE) && !isOwner && !isManager
        ? {}
        : getWorkingRulesOptions(this.handleInputChange);
    const restrictionsOptions = !isOwner && !isManager ? {} : getRestrictionsOptions(this.handleInputChange);
    const devicesOptions = getDevicesOptions(
      this.handleInputChange,
      isRcpGeolocalizationVisible,
      this.state.authorizationQrcodeEnable,
      this.state.allowEmployeeAttendaceOpenClose,
      this.state.enableLocalizationCheck,
      this.state.rcpRequiresGeolocation,
      this.state.availableAuthMethods,
      this.state.errors,
    );
    const otherOptions = !permissions.some(permission =>
      [OPEN_SHIFTS_SETTING_SHOW, TRADE_SHIFTS_SETTING_SHOW, PRODUCTION_QUOTAS_SETTING_SHOW].includes(permission),
    )
      ? {}
      : getOtherOptions(this.handleInputChange, permissions, this.state);

    const openingHoursOptions = permissions.includes(OPENING_HOURS_VIEW)
      ? getOpeningHoursOptions(
          this.handleInputChange,
          this.state.locationOpeningHoursEnabled,
          this.state.openHoursMethod,
          this.context.intl,
        )
      : {};

    const budgetOptions = getBudgetOptions(
      this.context.intl,
      this.handleInputChange,
      this.props.userPermissions.permissions,
      this.props.userPermissions.restrictions,
      this.state,
    );

    this.options = [
      workingRulesOptions,
      restrictionsOptions,
      devicesOptions,
      otherOptions,
      openingHoursOptions,
      budgetOptions,
    ];

    const canSave = this.checkIfCanSave();

    return (
      <div className="k-wrapper animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="k-panel panel-nopad panel-setting">
              <fieldset className="form-group locationSettingsContent__selectWrapper">
                <span
                  style={{
                    float: 'left',
                    lineHeight: '40px',
                    marginRight: '20px',
                    marginTop: '7px',
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage
                    id="settings.locations.settingsForLocation"
                    defaultMessage="Ustawienia dla lokalizacji"
                  />
                </span>
                <div className="locationSettingsContent__select">
                  <Select
                    onChange={this.changeLocation}
                    defaultValue={this.state.location.id}
                    id="location"
                    closeOnClick
                    withSearch
                    options={sortBy(
                      this.props.userLocations.map(l => ({
                        value: l.id,
                        key: l.name,
                      })),
                      location => deburr(location.key.toLowerCase()),
                    )}
                  />
                </div>
              </fieldset>
              {this.options
                .filter(option => option.title)
                .map((block, i) => (
                  <FreemiumWrapper
                    freemiumRestrictions={block.freemiumRestrictions}
                    intercomEvent={block.intercomEvent}
                  >
                    <div className="k-locationSettings" key={i}>
                      <h2 className="k-panel__title">
                        {block.title}
                        {checkArrayIntersection(restrictions, block.freemiumRestrictions) && <FreemiumStar />}
                      </h2>
                      <fieldset className="form-group">
                        {block.options.filter(Boolean).map((option, j) => {
                          switch (option.type) {
                            case OPTION_TYPE.BOOL: {
                              return <ToggleBlock {...option} value={this.state[option.slug]} key={i * 100 + j} />;
                            }
                            case OPTION_TYPE.TOLERANCE: {
                              return (
                                <ToleranceBlock
                                  {...option}
                                  toggleValue={this.state[option.toggleSlug]}
                                  rangeValue={this.state[option.rangeSlug]}
                                  key={i * 100 + j}
                                />
                              );
                            }
                            case OPTION_TYPE.SELECT: {
                              return (
                                <SelectBlock
                                  {...option}
                                  handleInput={choice => {
                                    const newState = { ...this.state };
                                    newState[option.slug] = choice;
                                    this.setState(newState);
                                  }}
                                  value={this.state[option.slug]}
                                  key={i * 100 + j}
                                />
                              );
                            }
                            case OPTION_TYPE.DATE: {
                              return <DateInput {...option} value={this.state[option.slug]} key={i * 100 + j} />;
                            }
                            case OPTION_TYPE.TEXT: {
                              return (
                                <LabeledTextInput
                                  {...option}
                                  id={option.slug}
                                  value={this.state[option.slug]}
                                  key={i * 100 + j}
                                  onBlur={this.validateInput}
                                />
                              );
                            }
                            case OPTION_TYPE.CHECKBOX_LIST: {
                              return (
                                <CheckboxList
                                  {...option}
                                  errorMessage={this.state.errors[option.slug]}
                                  initialValue={this.initialState[option.slug]}
                                  key={i * 100 + j}
                                />
                              );
                            }
                            case OPTION_TYPE.TITLE_WITH_DESC: {
                              return <SettingsNameWithLabel {...option} key={i * 100 + j} />;
                            }
                            case OPTION_TYPE.WORKING_HOURS_INPUTS: {
                              return <WorkingHoursInputs {...option} key={i * 100 + j} />;
                            }
                            case OPTION_TYPE.BONUS_TARGET_OPTIONS: {
                              return <BonusTargetOptions {...option} key={i * 100 + j} />;
                            }
                            case OPTION_TYPE.SINGLE_TIME_INPUT: {
                              return <SingleTimeInput {...option} key={i * 100 + j} />;
                            }
                            default:
                              return null;
                          }
                        })}
                      </fieldset>
                    </div>
                  </FreemiumWrapper>
                ))}
              {canSave && (
                <>
                  <div className="settingsButton">
                    <Button onClick={() => this.submit()} modifiers="blue small">
                      <FormattedMessage id="settings.saveChanges" defaultMessage="Zapisz zmiany" />
                    </Button>
                  </div>
                  <div className="settingsButton">
                    <Button onClick={() => this.submit({ forAllLocations: true })} modifiers="blue small">
                      <FormattedMessage
                        id="settings.saveChangesForAllLocations"
                        defaultMessage="Zapisz i zastosuj dla wszystkich lokalizacji"
                      />
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LocationSettingsContent.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

LocationSettingsContent.propTypes = {
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  settings: PropTypes.shape({
    locationSettings: PropTypes.shape({}),
  }),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.string,
    }),
  }),
  changeLocationSettings: PropTypes.func,
  getLocationSettings: PropTypes.func,
  params: PropTypes.shape({
    locationName: PropTypes.string,
  }),
  changeLocationSettingsMass: PropTypes.func,
};

export default LocationSettingsContent;
