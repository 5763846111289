import { flexRender, Table } from '@tanstack/react-table';
import classNames from 'classnames';

import { SHOW_SKELETON_CELL } from '@/constants/tables';
import { useMediaQuery } from '@/hooks';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { isEven, isOdd } from '@/utils/numberHelpers';

import KadroSkeleton from '../../Skeleton/Skeleton';
import { ColumnsWidths, MDTableProps, TableRow } from '../MDTable.types';
import { getCommonPinningStyles } from '../TableUtils';
import { FilterRow } from './rows';
import { checkIsRowHidden } from './TableBody.utils';

import './TableBody.scss';

type Props<T extends object> = {
  columnsWidths: ColumnsWidths;
  isFilterRowVisible: boolean;
  isOverflowX: boolean;
  manualFiltering?: MDTableProps<T>['manualFiltering'];
  onRowClick?: MDTableProps<T>['onRowClick'];
  table: Table<TableRow<T>>;
  tableEmpty: MDTableProps<T>['tableEmpty'];
  tableStyles: MDTableProps<T>['tableStyles'];
};

export const TableBody = <T extends object>({
  columnsWidths,
  isFilterRowVisible,
  isOverflowX,
  manualFiltering,
  onRowClick,
  table,
  tableEmpty,
  tableStyles,
}: Props<T>) => {
  const { rows } = table.getRowModel();
  const isEmpty = isEmptyArray(rows);
  const { isAtLeastTabletLandscape } = useMediaQuery();
  let counterRow = 0;
  const [{ headers }] = table.getHeaderGroups();

  return (
    <tbody className="mdTableBody">
      {isFilterRowVisible && (
        <FilterRow
          columnsWidths={columnsWidths}
          isOverflowX={isOverflowX}
          table={table}
          manualFiltering={manualFiltering}
        />
      )}
      {isEmpty && (
        <tr className="mdTableBody__emptyRow">
          <td colSpan={table.getVisibleFlatColumns().length}>{tableEmpty}</td>
        </tr>
      )}
      {!isEmpty &&
        rows.map(row => {
          if (checkIsRowHidden(row)) return null;
          const canExpand = row.getCanExpand();
          const isSelected = row.getIsSelected();
          const rowClassName = classNames('mdTableBody__row', {
            'mdTableBody__row--even': !canExpand && isEven(counterRow),
            'mdTableBody__row--odd': !canExpand && isOdd(counterRow),
            'mdTableBody__row--selected': isSelected,
            'mdTableBody__row--clickable': Boolean(onRowClick),
            'mdTableBody__row--hover': !tableStyles.hasNoHover,
            mdTableBody__expandRow: canExpand,
            'mdTableBody__expandRow--closed': canExpand && !row.getIsExpanded(),
          });
          if (!canExpand) counterRow++;
          return (
            <tr key={row.id} className={rowClassName} onClick={() => onRowClick && onRowClick(row)}>
              {row.getVisibleCells().map(cell => {
                const header = headers.find(h => h.id === cell.column.id);
                return (
                  <td
                    key={cell.id}
                    className={classNames('mdTableBody__cell', {
                      'mdTableBody__cell--withoutVerticalPadding': (
                        cell.column.columnDef as unknown as { withoutVerticalPadding: boolean }
                      ).withoutVerticalPadding,
                      'mdTableBody__cell--dense': tableStyles.hasDensePadding,
                      'mdTableBody__cell--center': header.column.columnDef.centerColumn,
                    })}
                    style={getCommonPinningStyles(cell.column, isOverflowX, columnsWidths, isAtLeastTabletLandscape)}
                  >
                    {!isAtLeastTabletLandscape && (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                    {flexRender(
                      cell.renderValue() === SHOW_SKELETON_CELL ? <KadroSkeleton /> : cell.column.columnDef.cell,
                      cell.getContext(),
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
    </tbody>
  );
};
