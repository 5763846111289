import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { showModal } from '@/actions/uiState';
import Button from '@/components/common/Basic/Button';
import { BUTTON_BAR_TOOLTIP_OFFSET } from '@/components/common/Basic/ButtonBar/ButtonBar.utils';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { ATTENDANCE_ABSENCE } from '@/constants/attendanceDetailTypes';
import { ALIGN_ATTENDANCES_MODAL_WEEK } from '@/constants/modalTypes';
import { useAppDispatch } from '@/redux-store';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { dateTimeToTimestamp } from '@/utils/dates/dates.helpers';

import { SubRowType } from '../../../AttendanceManyDaysView/Table/AttendanceManyDaysTable.types';
import { messages } from '../../AttendanceButtonBar.messages';

type Props = {
  data: Partial<SubRowType>[];
  disabled: boolean;
};

export const AttendanceEditButton = ({ data, disabled }: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    const attendances = data.filter(i => i.type !== ATTENDANCE_ABSENCE);
    const shiftsWithoutAttendances = data
      .filter(i => i.type === ATTENDANCE_ABSENCE)
      .map(({ location, employeeObject, mobileDate, startTimeShift, endTimeShift }) => ({
        locationId: location.id,
        employee: { id: employeeObject.id },
        detail: {
          startTimestamp: dateTimeToTimestamp(mobileDate, startTimeShift),
          endTimestamp: dateTimeToTimestamp(mobileDate, endTimeShift),
        },
      }));
    dispatch(
      showModal(ALIGN_ATTENDANCES_MODAL_WEEK, {
        relevantAttendances: { startAttendances: attendances, endAttendances: attendances },
        selectedShiftsWithoutAttendancesForSelectedEmployees: shiftsWithoutAttendances,
      }),
    );
  }, [data, dispatch]);

  return (
    <Tooltip content={intl.formatMessage(messages.edit)} yOffset={BUTTON_BAR_TOOLTIP_OFFSET}>
      <Button disabled={disabled || isEmptyArray(data)} onClick={onClick} modifiers="reverse-blue onlyIcon">
        <i className="material-icons">edit</i>
      </Button>
    </Tooltip>
  );
};
