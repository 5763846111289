import { createSelector } from '@reduxjs/toolkit';

import { EmployeeWhole } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { isDateWithinEmploymentPeriod } from '@/utils/schedulerHelpers';

import { selectLoanedEmployeeById } from '../scheduleLoanedEmployees';
import { RootState } from '../types';

export const selectEmployeeById = (employeeId: string) => (state: RootState) =>
  state.reducer.employees.data[employeeId];

export const selectEmployeeFullNameById = (employeeId: string) => (state: RootState) => {
  const employee = selectEmployeeById(employeeId)(state);
  if (!employee) return undefined;
  return `${employee.first_name} ${employee.last_name}`;
};

export const selectEmployeeOrLoanedEmployeeById = (employeeId: string) =>
  createSelector(
    [selectEmployeeById(employeeId), selectLoanedEmployeeById(employeeId)],
    (employee, loanedEmployee) => employee || loanedEmployee,
  );

export const selectIsEmployeeHiredInDate = (employeeId: EmployeeWhole['id'], date: StandardDate) =>
  createSelector(selectEmployeeById(employeeId), employee => {
    if (!employee) return false;
    const { hire_date, release_date } = employee.employment_conditions;
    return isDateWithinEmploymentPeriod(date, hire_date, release_date);
  });
