import classnames from 'classnames';
import PropTypes from 'prop-types';

import { MDTooltip } from '../MDComponents';

import './MDRadios.scss';

const MDRadios = props => {
  const { showLabelsColor, label, elements, selectedItemType, onChangeType } = props;

  return (
    <div className="kmd-radios">
      {label && <label>{label}</label>}
      {elements.map(item => {
        const classLabel = item.type === 'important' ? 'prio' : 'note';
        const labelClassNames = classnames('event_label', classLabel);
        const itemClassNames = classnames('kmd-radios__item', { 'kmd-radios__item--disabled': item.disabled });

        return (
          <MDTooltip text={item.disabledTooltip} withoutIcon>
            <div
              key={item.name}
              className={itemClassNames}
              onClick={() => !item.disabled && onChangeType(item.type)}
              data-test={item.type}
            >
              <span className="kmd-radios__checkbox">
                {selectedItemType === item.type && <span className="kmd-radios__checkbox--selected" />}
              </span>
              <div>
                {showLabelsColor && <span className={labelClassNames} />}
                <span className="kmd-radios__label">{item.name}</span>
              </div>
              {item.description && <div className="kmd-radios__description">{item.description}</div>}
            </div>
          </MDTooltip>
        );
      })}
    </div>
  );
};

MDRadios.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      description: PropTypes.string,
      disabled: PropTypes.bool,
      disabledTooltip: PropTypes.string,
    }),
  ).isRequired,
  selectedItemType: PropTypes.string.isRequired,
  onChangeType: PropTypes.func.isRequired,
  showLabelsColor: PropTypes.bool,
};

export default MDRadios;
