import classnames from 'classnames';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { showModal } from '@/actions/uiState';
import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { ADD_ABSENCE_PROPOSAL_MODAL, ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectEmployeeOrLoanedEmployeeById } from '@/redux-store/employees';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { selectIsQuickPlanningEnabled } from '@/redux-store/schedule/quickPlanning';
import { selectRestrictionsDict } from '@/redux-store/userPermissions';
import { OvertimeCollection } from '@/types';
import { convertDateToStandardFormat, timestampsToWorkingHours } from '@/utils/dateHelper.js';
import { checkIsMonthlyScheduleView, getScheduleBlockSize } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import './ScheduleOvertimeCollection.scss';

type Props = {
  overtimeCollection: OvertimeCollection;
  isEmployee?: boolean;
};

export const ScheduleOvertimeCollection = memo(({ overtimeCollection, isEmployee }: Props) => {
  const { employee_id: employeeId } = overtimeCollection;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isQuickPlanningEnabled = useAppSelector(selectIsQuickPlanningEnabled);
  const restrictions = useAppSelector(selectRestrictionsDict);
  const isEditDisabled = restrictions[SCHEDULE_EDIT_DISABLE];
  const dateArray = useAppSelector(selectDateArray);
  const size = useMemo(() => getScheduleBlockSize(dateArray), [dateArray]);
  const isMonthView = useMemo(() => checkIsMonthlyScheduleView(dateArray), [dateArray]);
  const relevantEmployee = useAppSelector(selectEmployeeOrLoanedEmployeeById(employeeId));
  const absenceClass = classnames('k-overtimeCollectionBlock', `k-overtimeCollectionBlock--${size}`, {
    'k-overtimeCollectionBlock--disabled': isEditDisabled,
    'k-overtimeCollectionBlock--isEmployee': isEmployee,
  });

  const validComment = overtimeCollection.comment?.length > 0;
  const popoverMessage = intl.formatMessage(
    { id: 'absences.overtimeCollection', defaultMessage: 'Odbiór nadgodzin w godzinach {hours}' },
    { hours: timestampsToWorkingHours(overtimeCollection.start_timestamp, overtimeCollection.end_timestamp) },
  );
  const popover = useMemo(
    () =>
      validComment
        ? {
            title: popoverMessage,
            content: (
              <>
                <FormattedMessage id="absences.comment" defaultMessage="Komentarz do odbioru nadgodzin" />:{' '}
                <strong>{overtimeCollection.comment}</strong>
              </>
            ),
          }
        : {
            content: popoverMessage,
          },
    [validComment, overtimeCollection.comment, popoverMessage],
  );

  const editOvertimeCollection = useCallback(() => {
    if (isQuickPlanningEnabled || isEditDisabled) return;
    dispatch(
      showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
        employee: relevantEmployee,
        absence: overtimeCollection,
        isOvertimeCollection: true,
        date: convertDateToStandardFormat(overtimeCollection.start_timestamp),
      }),
    );
  }, [dispatch, isQuickPlanningEnabled, isEditDisabled, relevantEmployee, overtimeCollection]);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isEmployee || isEditDisabled) return;
      event.stopPropagation();
      !overtimeCollection.isLegacyOvertime
        ? dispatch(showModal(ADD_ABSENCE_PROPOSAL_MODAL, { ...overtimeCollection }))
        : editOvertimeCollection();
    },
    [isEmployee, isEditDisabled, overtimeCollection, editOvertimeCollection, dispatch],
  );

  return (
    <HoverPopOver popover={popover}>
      <div onClick={onClick} className={absenceClass} role="presentation">
        <div className="sch_block__info">
          <div className="absence-name">
            {isMonthView ? (
              <FormattedMessage id="absences.overtimeCollectionShortcut" defaultMessage="ON" />
            ) : (
              <FormattedMessage id="absences.overtimeCollection" defaultMessage="Odbiór nadgodzin" />
            )}{' '}
            {timestampsToWorkingHours(overtimeCollection.start_timestamp, overtimeCollection.end_timestamp)}
          </div>
        </div>
        {!isEmployee && (
          <i className={classnames('sch_block__editIcon', 'material-icons', 'sch_block__editIcon--black')}>
            fact_check
          </i>
        )}
      </div>
    </HoverPopOver>
  );
});

ScheduleOvertimeCollection.displayName = 'ScheduleOvertimeCollection';
