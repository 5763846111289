import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingSpinner from '@/components/common/Basic/LoadingSpinner.jsx';
import ExportFileFormatStep from '@/components/common/exports/ExportModal/ExportFileFormatStep/ExportFileFormatStep';
import { MDCheckbox, MDCheckboxList, MDRadiosWithSublabel } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { setCookie } from '@/utils/cookieHandlers.js';

import { messages } from '../exports.messages';

class ExportModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
    this.exporting = false;
    bindPrototypeFunctions(this);
  }

  getInitialState() {
    return {
      showDownloadButton: false,
      chosenFormat: null,
      downloadUrl: null,
      options: {},
      currentStep: 1,
      stepsCount: 2,
      cancelText: <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />,
      confirmText: <FormattedMessage id="common.next" defaultMessage="Dalej" />,
      cancelBtnModifiers: ['reverse-orange'],
      confirmBtnModifiers: ['orange', 'disabled'],
      title: <FormattedMessage id="export.export" defaultMessage="Eksport" />,
      error: '',
    };
  }

  getInitOptions() {
    return this.props.formats[this.state.chosenFormat].options.reduce((acc, cur) => {
      if (cur?.defaultValue) {
        return { ...acc, [cur.id]: cur.defaultValue };
      }

      return acc;
    }, {});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentStep !== this.state.currentStep) {
      if (this.state.currentStep === 1) {
        this.setState({
          cancelText: <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />,
          confirmText: <FormattedMessage id="common.next" defaultMessage="Dalej" />,
          title: <FormattedMessage id="export.export" defaultMessage="Eksport" />,
        });
      } else {
        this.setState({
          cancelText: <FormattedMessage id="common.back" defaultMessage="Wstecz" />,
          confirmText: <FormattedMessage id="export.export" defaultMessage="Eksport" />,
          title: <FormattedMessage id="export.exportSettings" defaultMessage="Ustawienia eksportu" />,
          options: this.getInitOptions(),
        });
      }
    }
    if (prevState.stepsCount !== this.state.stepsCount) {
      if (this.state.stepsCount === 1) {
        this.setState({ confirmText: <FormattedMessage id="export.export" defaultMessage="Eksport" /> });
      } else {
        this.setState({ confirmText: <FormattedMessage id="common.next" defaultMessage="Dalej" /> });
      }
    }
  }

  onIconClick(id) {
    if (this.exporting) return;
    if (this.state.chosenFormat === id) {
      this.setState({ chosenFormat: null, confirmBtnModifiers: ['orange', 'disabled'] });
    } else {
      const newStepsCount = this.props.formats[id].options.length === 0 ? 1 : 2;
      this.setState({ chosenFormat: id, confirmBtnModifiers: ['orange'], stepsCount: newStepsCount });
    }
  }

  handleHide() {
    const { onHide, toggleExportModal } = this.props;
    toggleExportModal();
    setTimeout(() => {
      this.exporting = false;
      this.setState(this.getInitialState());

      if (onHide) {
        onHide();
      }
    }, 500);
  }

  close() {
    if (this.state.currentStep === 2) {
      this.setState({ currentStep: 1 });
      return;
    }
    this.handleHide();
  }

  startExport() {
    const { mainDateStore } = this.props;
    const { chosenFormat } = this.state;
    const isPdfSelected = chosenFormat === 2;
    if (isPdfSelected && mainDateStore.dateArray.length > 31) {
      this.setState({ error: this.context.intl.formatMessage(messages.pdfError) });
      return;
    }

    if (!this.isLastStep()) {
      this.setState({ currentStep: this.state.currentStep + 1 });
      return;
    }
    this.exporting = true;
    this.setState(prevState => ({
      confirmText: (
        <>
          <FormattedMessage id="export.generating" defaultMessage="Generuje" />
          <LoadingSpinner />
        </>
      ),
      confirmBtnModifiers: [...prevState.confirmBtnModifiers, 'disabled'],
      cancelBtnModifiers: [...prevState.cancelBtnModifiers, 'disabled'],
    }));
    const format = this.props.formats[this.state.chosenFormat];
    const options = {};
    format.options.forEach(option => {
      if (!option) return;
      switch (option.type) {
        case 'checkbox': {
          let checked = false;
          if (this.state.options) checked = this.state.options[option.id];
          options[option.id] = checked;
          break;
        }
        case 'radio': {
          const checked = this.state.options ? this.state.options[option.id] : false;
          options[option.id] = checked ? option.values[1].id : option.values[0].id;
          break;
        }
        case 'columnList':
          if (this[option.id]) {
            options[option.id] = this[option.id].getSelected();
          }
          break;
        default:
          break;
      }
    });
    let rawData = this.props.exportRawData(format);
    rawData = { options, ...rawData };

    if (format?.text === 'PDF' && rawData?.options?.selectedColumns) {
      setCookie(
        `kadroExportPreselected-${format.text}-${rawData.type}`,
        JSON.stringify(rawData.options.selectedColumns),
      );
    }

    this.props
      .exportData(format.text + (format.subtitle || ''), rawData)
      .then(url => {
        if (url === null) {
          this.handleHide();
        } else {
          window.downloadInProgress = true;
          const a = document.createElement('a');
          a.href = url;
          a.click();
          this.handleHide();
          setTimeout(() => {
            window.downloadInProgress = false;
          }, 1000);
        }
      })
      .catch(err => {
        console.error('Couldnt get requested export file', err);
      });
  }

  handleCheckboxChange(e) {
    const { id, checked } = e.target;
    this.setState(prevState => ({
      ...prevState,
      options: { ...prevState.options, [id]: checked },
    }));
  }

  handleRadioChange(e, option) {
    const { value } = e.target;
    const { name } = e;
    this.setState(prevState => ({
      ...prevState,
      options: { ...prevState.options, [name]: value },
    }));
    if (option.onChange) {
      option.onChange(value);
    }
  }

  isLastStep() {
    return this.state.currentStep === this.state.stepsCount;
  }

  displayRelevantStep() {
    switch (this.state.currentStep) {
      case 1: {
        return (
          <ExportFileFormatStep
            chosenFormat={this.state.chosenFormat}
            formats={this.props.formats}
            onFormatClick={this.onIconClick}
          />
        );
      }
      case 2: {
        return (
          <>
            {this.props.formats[this.state.chosenFormat].options.map((option, i) => {
              if (!option) return null;
              if (option.depends)
                if (option.depends && this.state.options[option.depends.option] !== option.depends.value) return null;
              switch (option.type) {
                case 'checkbox': {
                  const formattedMessage = { defaultMessage: option.text, id: option.id };
                  return (
                    <MDCheckbox
                      key={option.id}
                      id={option.id}
                      text={<FormattedMessage {...formattedMessage} />}
                      value={this.state.options[option.id] || false}
                      onChange={this.handleCheckboxChange}
                      tooltip={option.tooltip}
                      reverse
                    />
                  );
                }
                case 'radio':
                  return (
                    <MDRadiosWithSublabel
                      options={option.values}
                      selectedItemId={this.state.options[option.id] || option.values[0].id}
                      onChange={e => this.handleRadioChange(e, option)}
                      key={i}
                      radiosId={option.id}
                    />
                  );
                case 'columnList':
                  return (
                    <MDCheckboxList
                      key={i}
                      ref={ref => {
                        this[option.id] = ref;
                      }}
                      options={option.values}
                      preselected={option.preselected}
                      hidden={option.hidden || []}
                      title={{ id: option.id, defaultMessage: option.title }}
                    />
                  );
                default:
                  return null;
              }
            })}
          </>
        );
      }
      default:
        return null;
    }
  }

  render() {
    return (
      <MDKadroModal
        show={this.props.show}
        onHide={this.handleHide}
        onCancelFooter={this.close}
        title={this.state.title}
        modifiers={['narrow']}
        onSubmit={this.startExport}
        confirmText={this.state.confirmText}
        confirmBtnModifiers={this.state.confirmBtnModifiers}
        cancelText={this.state.cancelText}
        cancelBtnModifiers={this.state.cancelBtnModifiers}
        showProgressBar
        currentStep={this.state.currentStep}
        stepsCount={this.state.stepsCount}
        errorMessage={this.state.error}
      >
        {this.displayRelevantStep()}
      </MDKadroModal>
    );
  }
}

ExportModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ExportModal.propTypes = {
  exportData: PropTypes.func,
  exportRawData: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  formats: PropTypes.arrayOf(PropTypes.shape({})),
  onHide: PropTypes.func,
  toggleExportModal: PropTypes.func,
  show: PropTypes.bool,
  showFormatsAsList: PropTypes.bool,
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
};
export default ExportModal;
