export const filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings = (
  columns,
  userPermissions,
  payoutSettingType,
  companySettings,
) =>
  columns.filter(o => {
    const hasPermissions = !o.permissions.length || o.permissions.some(p => userPermissions.permissions.includes(p));
    const hasRestrictions = o.restrictions.length && o.restrictions.some(r => userPermissions.restrictions.includes(r));
    const hasEnabledCompanySettings =
      !o.companySettings || o.companySettings.every(setting => companySettings[setting]);
    const isShiftsPayoutType = payoutSettingType === 'shifts';
    const shouldBeHiddenForColumnFilter = o.hideForShiftsPayoutType && isShiftsPayoutType;

    return hasPermissions && !hasRestrictions && !shouldBeHiddenForColumnFilter && hasEnabledCompanySettings;
  });

const defaultSortingFunction = (a, b, key, desc) =>
  desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);

export const sortByMultipleKeys = (array, keys, options = { desc: false }, columns) =>
  array.sort((a, b) => {
    for (const key of keys) {
      if (key !== 'status' && (typeof a[key] === 'object' || typeof b[key] === 'object')) {
        continue;
      }

      const relevantColumn = columns.find(column => column.accessorKey === key);

      const keyResult = relevantColumn.sortingFunction
        ? relevantColumn.sortingFunction(a, b, key, options.desc, options.timeFormatType)
        : defaultSortingFunction(a, b, key, options.desc);
      if (keyResult !== 0) {
        return keyResult;
      }
    }
    return 0;
  });
