import classNames from 'classnames';
import { FormattedAttendance } from 'kadro-helpers/lib/types';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { formatDuration } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTracker.helpers.ts';
import { DashboardTimeTrackerContentButtons } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTrackerContentButtons.tsx';
import PulsatingDot from '@/components/dashboard/DashboardTimeTracker/PulsatingDot.jsx';
import { useCurrentAttendanceAndBreakDuration } from '@/components/dashboard/DashboardTimeTracker/useCurrentAttendanceAndBreakDuration.ts';
import { LocationSettingsStoreState } from '@/redux-store/settings/locationSettings';
import { EmployeeShift, UserEmployee, UserLocation } from '@/types';

import { messages } from './DashboardTimeTracker.messages.ts';

type Props = {
  showConfirmState: () => void;
  showSelectLocationState: () => void;
  showSummaryState: () => void;
  nextShift: EmployeeShift & { location: UserLocation };
  previousShift: EmployeeShift & { location: UserLocation };
  employee: UserEmployee;
  relevantLocations: UserLocation[];
  currentAttendance: FormattedAttendance;
  locationSettings: LocationSettingsStoreState;
};

export const DashboardTimeTrackerContentBody = ({
  showConfirmState,
  showSelectLocationState,
  nextShift,
  employee,
  relevantLocations,
  currentAttendance,
  showSummaryState,
  locationSettings,
  previousShift,
}: Props) => {
  const intl = useIntl();
  const attendanceLocation = relevantLocations.find(location => location.id === currentAttendance?.location_id);

  const breaksEnabled = currentAttendance && locationSettings[currentAttendance.location_id].enable_rest_break;

  const { currentAttendanceDurationFormatted, currentBreak, breaksDuration } =
    useCurrentAttendanceAndBreakDuration(currentAttendance);

  return (
    <div className="dashboardTimeTracker__body">
      {currentAttendance && (
        <div className="dashboardTimeTracker__hoursWrapper">
          <div>
            {intl.formatMessage(messages.start, {
              hour: moment(currentAttendance.start_timestamp).format('HH:mm'),
              locationName: attendanceLocation ? ` | ${attendanceLocation.name}` : '',
            })}
          </div>
          <div>
            {intl.formatMessage(messages.breaksDuration)}: {formatDuration(breaksDuration)}
          </div>
        </div>
      )}
      {currentAttendance ? (
        <div
          className={classNames('dashboardTimeTracker__counter', {
            'dashboardTimeTracker__counter--withMargin': !currentBreak,
            'dashboardTimeTracker__counter--break': currentBreak,
          })}
        >
          {currentAttendanceDurationFormatted}
        </div>
      ) : (
        <div className="dashboardTimeTracker__counter dashboardTimeTracker__counter--inactive dashboardTimeTracker__counter--withMargin">
          00:00:00
        </div>
      )}
      {currentBreak && (
        <div className="dashboardTimeTracker__break">
          <PulsatingDot color="#2A2A2A" />
          <div>
            {intl.formatMessage(messages.breakIsRunning)}:{' '}
            {formatDuration(moment().diff(currentBreak.start_timestamp, 'seconds'))}
          </div>
        </div>
      )}
      <DashboardTimeTrackerContentButtons
        currentAttendance={currentAttendance}
        showConfirmState={showConfirmState}
        showSelectLocationState={showSelectLocationState}
        nextShift={nextShift}
        previousShift={previousShift}
        employee={employee}
        relevantLocations={relevantLocations}
        showSummaryState={showSummaryState}
        breaksEnabled={breaksEnabled}
        currentBreak={currentBreak}
      />
    </div>
  );
};
