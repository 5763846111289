import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import MDFileDrop from '@/components/common/inputs/MDFileDrop/MDFileDrop.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { toBase64 } from '@/utils/fileHelpers.js';

import { getRelevantEmployeesDetails } from './ImportScheduleModal.helpers';

const SCHEDULE_IMPORT = 'Import grafiku';
const SCHEDULE_IMPORT_TEMPLATE = 'Szablon importu grafiku';

const ImportScheduleModal = props => {
  const {
    mainDateStore: {
      dateArray,
      customDate: { start: from, end: to },
    },
    importModalObject,
    locations,
    userEmployees,
    pendingExports,
    clearImportModal,
    clearScheduleImports,
    getVisibleEmployeesIdsForScheduleView,
    hideModal,
    importScheduleData,
    runExport,
    toggleImportModalOverlay,
    uploadFileToImport,
    userJobTitles,
    contracts,
  } = props;

  if (!locations?.length) return null;

  const locationId = locations[0];
  const currentTemplate = [...pendingExports]
    .reverse()
    .find(e => e.type === 'f-schedule-import' && e.name === SCHEDULE_IMPORT_TEMPLATE);
  const importObject = [...pendingExports]
    .reverse()
    .find(e => e.type === 'f-schedule-import' && e.status === 'success' && e.name === SCHEDULE_IMPORT);

  useEffect(() => {
    if (currentTemplate?.link) {
      window.downloadInProgress = true;
      const link = document.createElement('a');
      link.href = currentTemplate.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toggleImportModalOverlay(false);
      setTimeout(() => {
        window.downloadInProgress = false;
      }, 1000);
    }
  }, [currentTemplate]);

  useEffect(() => {
    if (!importObject) return;
    toggleImportModalOverlay(false);
    importScheduleData({ errors: importObject.errors });
    if (!importObject.errors || importObject.errors.length === 0) {
      hideAndClear();
    }
  }, [importObject]);

  const handleExport = useCallback(async () => {
    toggleImportModalOverlay(true);
    const visibleEmployeesIds = getVisibleEmployeesIdsForScheduleView();
    const relevantEmployees = getRelevantEmployeesDetails(
      userEmployees,
      visibleEmployeesIds,
      contracts,
      userJobTitles,
      from,
      to,
    );
    await runExport('f-schedule-import', SCHEDULE_IMPORT_TEMPLATE, {
      type: 'template',
      employees: relevantEmployees,
      dateArray,
      locationId,
    });
  }, [userEmployees, dateArray, locationId]);

  const handleSubmit = useCallback(async () => {
    toggleImportModalOverlay(true);
    const visibleEmployeesIds = getVisibleEmployeesIdsForScheduleView();
    const relevantEmployees = getRelevantEmployeesDetails(
      userEmployees,
      visibleEmployeesIds,
      contracts,
      userJobTitles,
      from,
      to,
    );
    const base64File = await toBase64(importModalObject.uploadedFile);
    await runExport('f-schedule-import', SCHEDULE_IMPORT, {
      type: 'import',
      file: base64File,
      employees: relevantEmployees,
    });
  }, [importModalObject.uploadedFile]);

  const hideAndClear = () => {
    toggleImportModalOverlay(false);
    uploadFileToImport(null);
    hideModal();
    clearImportModal();
    clearScheduleImports();
  };

  return (
    <MDKadroModal
      show={props.showModal}
      onHide={hideAndClear}
      onSubmit={handleSubmit}
      modifiers={['wide']}
      title={<FormattedMessage id="import.importData" defaultMessage="Importuj dane" />}
      hideConfirmButton={!importModalObject.uploadedFile}
      confirmText={<FormattedMessage id="common.saveChanges" defaultMessage="Zapisz zmiany" />}
      loading={importModalObject.showOverlay}
    >
      <div className="mdImportModal">
        <div className="mdImportModal__export">
          <div className="mdImportModalExport__header">
            <FormattedMessage id="importSchedule.loadData" defaultMessage="Wczytaj dane" />
          </div>
          <div className="mdImportModalExport__text">
            <FormattedMessage
              id="importSchedule.fileImport.exportFileText"
              defaultMessage="Wczytaj dane z pliku Excel. {downloadTemplate}  i wypełnij w nim dane."
              values={{
                downloadTemplate: (
                  <span className="mdImportModalExport__link" role="none" onClick={handleExport}>
                    {' '}
                    <FormattedMessage id="downloadTemplate" defaultMessage="Pobierz szablon" />{' '}
                  </span>
                ),
              }}
            />
          </div>
        </div>
        <div className="mdImportModal__fileDrop">
          <MDFileDrop fileFormats={['xlsx']} hideButton onUpload={uploadFileToImport} />
        </div>
      </div>
    </MDKadroModal>
  );
};

ImportScheduleModal.propTypes = {
  showModal: PropTypes.bool,
  runExport: PropTypes.func,
  getVisibleEmployeesIdsForScheduleView: PropTypes.func,
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  ),
  hideModal: PropTypes.func,
  clearImportModal: PropTypes.func,
  dateArray: PropTypes.arrayOf(PropTypes.string),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  pendingExports: PropTypes.arrayOf(PropTypes.shape({})),
  importScheduleData: PropTypes.func,
  uploadFileToImport: PropTypes.func,
  toggleImportModalOverlay: PropTypes.func,
  importModalObject: PropTypes.shape({
    uploadedFile: PropTypes.string,
    importedData: PropTypes.arrayOf(PropTypes.shape({})),
    showOverlay: PropTypes.bool,
  }),
  clearScheduleImports: PropTypes.func,
};

export default ImportScheduleModal;
