import { filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings } from '@/components/newPayrollViews/columns.helpers';
import { BLUE_100, BLUE_500, GREEN_100, GREEN_500, RED_100, RED_500, YELLOW_100, YELLOW_500 } from '@/constants/colors';
import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';
import { SHOW_SKELETON_CELL } from '@/constants/tables';
import { payrollLocationTableColumnsOptions } from '@/constants/tables/payrollLocation/payrollLocationTableColumns';
import { parseMinutesToFormat } from '@/utils/dateHelper';
import { createOvertimeScheduleCycleSpanLabel } from '@/utils/overtimeHelpers.js';

export const TABS = [
  {
    id: 'all',
    color: BLUE_500,
    lightColor: BLUE_100,
  },
  {
    id: 'approved',
    color: GREEN_500,
    lightColor: GREEN_100,
  },
  {
    id: 'unapproved',
    color: RED_500,
    lightColor: RED_100,
  },
  {
    id: 'pending',
    color: YELLOW_500,
    lightColor: YELLOW_100,
  },
];

export const getColumns = (allColumns, visibleColumns, intl) =>
  visibleColumns.reduce((acc, col) => {
    if (allColumns.some(column => column.accessorKey === col.accessorKey)) {
      acc.push({
        ...col,
        header: intl.formatMessage(col.title),
        accessorKey: col.accessorKey,
        size: col.width,
        sticky: col.sticky,
      });
    }

    return acc;
  }, []);

const formatMinutes = (value, timeFormatType) => {
  if (value) return parseMinutesToFormat(value, timeFormatType);
  return '-';
};

const formatMinutesWithZero = (value, timeFormatType) => parseMinutesToFormat(value || 0, timeFormatType);

const formatMoney = value => (value ? `${(value / 100).toFixed(2)} PLN` : '-');

const formatMoneyWithZero = value => `${((value || 0) / 100).toFixed(2)} PLN`;

const formatFullName = (firstName, lastName, sortingUseLastName) => {
  if (sortingUseLastName) return `${lastName} ${firstName}`;
  return `${firstName} ${lastName}`;
};

const getOvertimeWeeklyColumnContent = (overtimeStatsForEmployee, timeFormatType) => {
  if (!overtimeStatsForEmployee?.overtime) return '-';
  const { from, to } = overtimeStatsForEmployee;
  const value = parseMinutesToFormat(overtimeStatsForEmployee.overtime.scheduleCycleOvertime, timeFormatType);
  const scheduleCycleSpan = createOvertimeScheduleCycleSpanLabel(from, to);
  return (
    <div>
      <div className="k-payrollLocationTable__scheduleCyclePeriod">{scheduleCycleSpan}</div>
      <div>{value}</div>
    </div>
  );
};

const skeletonRow = {
  employee: SHOW_SKELETON_CELL,
  absences: SHOW_SKELETON_CELL,
  workTime: SHOW_SKELETON_CELL,
  nightWorkTime: SHOW_SKELETON_CELL,
  timePlanned: SHOW_SKELETON_CELL,
  timeDiff: SHOW_SKELETON_CELL,
  overtime50: SHOW_SKELETON_CELL,
  overtime100: SHOW_SKELETON_CELL,
  potentialOvertime: SHOW_SKELETON_CELL,
  fillUp: SHOW_SKELETON_CELL,
  overtimeWeekly: SHOW_SKELETON_CELL,
  bonus: SHOW_SKELETON_CELL,
  payout: SHOW_SKELETON_CELL,
  warnings: SHOW_SKELETON_CELL,
  status: SHOW_SKELETON_CELL,
  disabledCheckbox: true,
};

export const getTableData = (
  data,
  timeFormatType,
  sortingUseLastName,
  overtimeStats,
  intl,
  dateFilter,
  isFetchingBatches,
  numberOfRelevantEmployees,
) => {
  const payrollData = data.map(rowData => ({
    ...rowData,
    employee: formatFullName(rowData.first_name, rowData.last_name, sortingUseLastName),
    absences: formatMinutes(rowData.absences_time, timeFormatType),
    workTime: formatMinutesWithZero(rowData.work_time, timeFormatType),
    nightWorkTime: formatMinutesWithZero(rowData.night_hours, timeFormatType),
    timePlanned: formatMinutesWithZero(rowData.timePlanned, timeFormatType),
    timeDiff: formatMinutes(rowData.timeDiff, timeFormatType),
    overtime50: formatMinutes(rowData.overtime50, timeFormatType),
    overtime100: formatMinutes(rowData.overtime100, timeFormatType),
    potentialOvertime: formatMinutes(rowData.potentialOvertime, timeFormatType),
    fillUp: formatMinutes(rowData.fillUp, timeFormatType),
    overtimeWeekly: getOvertimeWeeklyColumnContent(overtimeStats[rowData.employee_id], timeFormatType),
    bonus: formatMoney(rowData.bonus),
    payout: formatMoneyWithZero(rowData.payout),
    warnings: rowData.warnings,
    status: rowData.status,
    disabledCheckbox: dateFilter.end < PAYROLL_APPROVAL_START_DATE,
  }));

  if (!isFetchingBatches) {
    return payrollData;
  }

  const MAX_NUMBER_OF_SKELETONS = 50;
  const numberOfSkeletons = Math.min(numberOfRelevantEmployees - data.length + 1, MAX_NUMBER_OF_SKELETONS);

  const skeletonRows = Array.from({ length: numberOfSkeletons }, () => skeletonRow);

  return [...payrollData, ...skeletonRows];
};

export const getPayrollLocationColumns = (userPermissions, payoutSettingType, companySettings) =>
  filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings(
    payrollLocationTableColumnsOptions,
    userPermissions,
    payoutSettingType,
    companySettings,
  );

export const tableStyles = { hasHeaderTextWrapping: true };
