import { IntlShape } from 'react-intl';

import { SortingFnEnum, TableColumnType } from '@/components/common/MDTable/MDTable.types';

import { BudgetMetricRow } from './BudgetMetricsTable.types';
import { BudgetMetricIconCell, BudgetMetricLocationsCell, BudgetMetricNameCell } from './Cells';
import messages from './messages';

export const getColumns = ({ formatMessage }: IntlShape): TableColumnType<BudgetMetricRow>[] => [
  {
    header: formatMessage(messages.budgetMetricsTableName),
    accessorKey: 'name',
    cell: props => <BudgetMetricNameCell {...props} />,
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
  },
  {
    header: formatMessage(messages.budgetMetricsTableLocations),
    accessorKey: 'locations',
    cell: props => <BudgetMetricLocationsCell {...props} />,
    filterFn: (row, _columnId, filterValue) =>
      row.original.locations.some(location => location.name.toLowerCase().includes(filterValue.toLowerCase())),
    sortingFn: SortingFnEnum.LOCATIONS,
  },
  {
    header: formatMessage(messages.budgetMetricsTableFormat),
    accessorKey: 'type',
  },
  {
    header: formatMessage(messages.budgetMetricsTableTimeInterval),
    accessorKey: 'time_interval',
  },
  {
    header: formatMessage(messages.budgetMetricsTableIcon),
    accessorKey: 'icon',
    centerColumn: true,
    enableColumnFilter: false,
    enableSorting: false,
    cell: props => <BudgetMetricIconCell {...props} />,
    size: 100,
    customSize: true,
  },
];
