import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import './MDTablePagination.scss';

const MDTablePagination = ({
  isTable,
  currentPage,
  changeCurrentPage,
  numberOfPages,
  activeSelect,
  setActiveSelect,
  pageNumber,
  setPageNumber,
  handlePageOnBlur,
  numberOfItemsPerPage,
  changeNumberOfItemsPerPage,
}) => {
  const numberOfItemsPerPageOptions = [100, 50, 25, 10];

  const handlePagination = useCallback(() => {
    let result = [];
    if (numberOfPages <= 5) {
      result = Array.from({ length: numberOfPages }, (_, index) => (
        <span
          key={index}
          role="button"
          tabIndex={0}
          className={`mdTablePagination__page ${currentPage === index + 1 && 'mdTablePagination__page--active'}`}
          onClick={() => changeCurrentPage(index + 1)}
        >
          {index + 1}
        </span>
      ));
    } else if (currentPage < numberOfPages - 4) {
      result = Array.from({ length: 3 }, (_, index) => (
        <span
          key={index}
          role="button"
          tabIndex={0}
          className={`mdTablePagination__page ${currentPage === index + currentPage && 'mdTablePagination__page--active'}`}
          onClick={() => changeCurrentPage(index + currentPage)}
        >
          {index + currentPage}
        </span>
      ));
      result.push(<span className="mdTablePagination__dots">...</span>);
      result.push(
        <span
          role="button"
          tabIndex={0}
          onClick={() => changeCurrentPage(numberOfPages)}
          className={`mdTablePagination__page ${currentPage === numberOfPages && 'mdTablePagination__page--active'}`}
        >
          {numberOfPages}
        </span>,
      );
    } else {
      result = Array.from({ length: 5 }, (_, index) => (
        <span
          key={index}
          role="button"
          tabIndex={0}
          className={`mdTablePagination__page ${currentPage === index + (numberOfPages - 4) && 'mdTablePagination__page--active'}`}
          onClick={() => changeCurrentPage(index + (numberOfPages - 4))}
        >
          {index + (numberOfPages - 4)}
        </span>
      ));
    }
    return result;
  }, [numberOfPages, currentPage]);

  return (
    <div className={classNames('mdTablePagination', isTable && 'mdTablePagination--table')}>
      <div className="mdTablePagination__select">
        <div
          className={`mdTablePagination__select__dropdown ${activeSelect && 'mdTablePagination__select__dropdown--active'}`}
        >
          {numberOfItemsPerPageOptions.map(option => (
            <div
              key={option}
              role="listbox"
              tabIndex={0}
              onClick={() => {
                changeNumberOfItemsPerPage({ target: { value: option } });
                setActiveSelect(false);
              }}
              className="mdTablePagination__select__item"
            >
              {option === numberOfItemsPerPage && (
                <span className="mdTablePagination__select__checkbox material-icons">done</span>
              )}
              <span>{option}</span>
            </div>
          ))}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setActiveSelect(a => !a)}
          className="mdTablePagination__select__input"
        >
          {numberOfItemsPerPage}
        </div>
      </div>
      <div className="mdTablePagination__pageInput">
        <input
          value={pageNumber}
          type="number"
          onChange={e => setPageNumber(e.target.value)}
          onBlur={handlePageOnBlur}
        />
      </div>
      <div className="mdTablePagination__navigation">
        <span
          role="button"
          tabIndex={0}
          className="mdTablePagination__arrowLeft material-icons"
          onClick={() => {
            if (currentPage - 1 > 0) {
              changeCurrentPage(currentPage - 1);
            }
          }}
        >
          keyboard_arrow_left
        </span>
        {handlePagination()}
        <span
          role="button"
          tabIndex={0}
          className="mdTablePagination__arrowRight material-icons"
          onClick={() => {
            if (currentPage + 1 <= numberOfPages) {
              changeCurrentPage(currentPage + 1);
            }
          }}
        >
          keyboard_arrow_right
        </span>
      </div>
    </div>
  );
};

MDTablePagination.propTypes = {
  isTable: PropTypes.bool,
  currentPage: PropTypes.number,
  changeCurrentPage: PropTypes.func,
  numberOfPages: PropTypes.number,
  activeSelect: PropTypes.bool,
  setActiveSelect: PropTypes.func,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  handlePageOnBlur: PropTypes.func,
  numberOfItemsPerPage: PropTypes.number,
  changeNumberOfItemsPerPage: PropTypes.func,
};

export default MDTablePagination;
