import { Column, ColumnPinningState } from '@tanstack/react-table';

import { CHECKBOX_COLUMN_WIDTH, DEFAULT_COLUMN_WIDTH, OPTIONS_COLUMN_WIDTH } from '@/constants/tables';
import { isNumberType } from '@/utils/variableTypes/variableTypes';

import { ColumnsWidths, MDTableProps, TABLE_COLUMN_STICKY_ENUM, TableColumnType } from '../MDTable.types';

const TABLE_SCROLLBAR_WIDTH = 8;

export const getInitialPinnedColumns = <T extends { accessorKey: string; sticky?: TABLE_COLUMN_STICKY_ENUM }>(
  columns: T[],
): ColumnPinningState =>
  columns.reduce<ColumnPinningState>(
    (acc, column) => {
      if (column.sticky === TABLE_COLUMN_STICKY_ENUM.LEFT) acc.left.push(column.accessorKey);
      if (column.sticky === TABLE_COLUMN_STICKY_ENUM.RIGHT) acc.right.push(column.accessorKey);
      return acc;
    },
    { left: [], right: [] },
  );

export const getForceEvenlyColumnWidth = <T extends object>(
  clientWidth: number,
  { columns, enableRowSelection, options }: Pick<MDTableProps<T>, 'columns' | 'enableRowSelection' | 'options'>,
): ColumnsWidths['forceEvenlyColumnWidth'] => {
  if (!enableRowSelection) return undefined;
  const width = clientWidth - CHECKBOX_COLUMN_WIDTH - (options ? OPTIONS_COLUMN_WIDTH : 0);
  const usedWidth = columns.reduce((acc, column) => acc + (column.size ?? 0), 0);
  return usedWidth > width ? undefined : width / (columns.length + (options ? 1 : 0));
};

export const getColumnWidth = <T extends object>(
  clientWidth: number,
  { columns, enableRowSelection, options }: Pick<MDTableProps<T>, 'columns' | 'enableRowSelection' | 'options'>,
  isOverflowX: boolean,
): ColumnsWidths['columnWidth'] => {
  const width =
    clientWidth -
    (enableRowSelection ? CHECKBOX_COLUMN_WIDTH : 0) -
    (options ? OPTIONS_COLUMN_WIDTH : 0) -
    (isOverflowX ? TABLE_SCROLLBAR_WIDTH : 0);
  const { usedWidth, amount: amountOfColumnsWithCustomWidth } = columns.reduce(
    (acc, column) => ({
      usedWidth: column.customSize ? acc.usedWidth + (column.size || 0) : acc.usedWidth,
      amount: column.customSize ? acc.amount + 1 : acc.amount,
    }),
    { usedWidth: 0, amount: 0 },
  );
  return Math.max((width - usedWidth) / (columns.length - amountOfColumnsWithCustomWidth), DEFAULT_COLUMN_WIDTH);
};

const getWidths = <T extends object>(
  column: Column<T>,
  { columnWidth, forceEvenlyColumnWidth }: ColumnsWidths,
  isAtLeastTabletLandscape = true,
): React.CSSProperties => {
  const { customSize } = column.columnDef as { customSize?: TableColumnType<T>['customSize'] };
  const width = customSize ? undefined : columnWidth;

  const widths: React.CSSProperties = isAtLeastTabletLandscape
    ? { width: width || column.getSize(), maxWidth: width || column.getSize(), minWidth: width || column.getSize() }
    : {};

  if (isAtLeastTabletLandscape && column.getIndex() > 0 && isNumberType(forceEvenlyColumnWidth)) {
    widths.width = forceEvenlyColumnWidth;
    widths.maxWidth = forceEvenlyColumnWidth;
    widths.minWidth = forceEvenlyColumnWidth;
  }

  return widths;
};

export const getCommonPinningStyles = <T extends object>(
  column: Column<T>,
  isOverflowX: boolean,
  columnsWidths: ColumnsWidths,
  isAtLeastTabletLandscape = true,
): React.CSSProperties => {
  const stickyPosition = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isOverflowX &&
    stickyPosition === TABLE_COLUMN_STICKY_ENUM.LEFT &&
    column.getIsLastColumn(TABLE_COLUMN_STICKY_ENUM.LEFT);
  const isFirstRightPinnedColumn =
    isOverflowX &&
    stickyPosition === TABLE_COLUMN_STICKY_ENUM.RIGHT &&
    column.getIsFirstColumn(TABLE_COLUMN_STICKY_ENUM.RIGHT);
  const widths = getWidths(column, columnsWidths, isAtLeastTabletLandscape);

  return {
    boxShadow:
      (isLastLeftPinnedColumn && '5px 0px 4px rgba(0, 0, 0, 0.02)') ||
      (isFirstRightPinnedColumn && '-5px 0px 4px rgba(0, 0, 0, 0.02)') ||
      undefined,
    left:
      isOverflowX && stickyPosition === TABLE_COLUMN_STICKY_ENUM.LEFT
        ? `${column.getStart(TABLE_COLUMN_STICKY_ENUM.LEFT)}px`
        : undefined,
    right:
      isOverflowX && stickyPosition === TABLE_COLUMN_STICKY_ENUM.RIGHT
        ? `${column.getAfter(TABLE_COLUMN_STICKY_ENUM.RIGHT)}px`
        : undefined,
    position: isOverflowX && stickyPosition ? 'sticky' : 'relative',
    ...widths,
    zIndex: isOverflowX && stickyPosition ? 1 : 0,
  };
};
