import { IntlShape } from 'react-intl';

import { TableColumnType, TableStyles } from '@/components/common/MDTable/MDTable.types';

import { messages } from './ManageAbsenceTypesView.messages';
import { AbsenceTypeRow } from './ManageAbsenceTypesView.types';

export const getColumns = ({ formatMessage }: IntlShape): TableColumnType<AbsenceTypeRow>[] => [
  {
    header: formatMessage(messages.manageAbsenceTypesTableName),
    accessorKey: 'name',
  },
  {
    header: formatMessage(messages.manageAbsenceTypesTableShortName),
    accessorKey: 'short_name',
  },
  {
    header: formatMessage(messages.manageAbsenceTypesTableCode),
    accessorKey: 'code',
  },
  {
    header: formatMessage(messages.manageAbsenceTypesTableAddingByEmployee),
    accessorKey: 'adding_by_employee_text',
  },
  {
    header: formatMessage(messages.manageAbsenceTypesTableHasLimit),
    accessorKey: 'has_limit_text',
  },
  {
    header: formatMessage(messages.manageAbsenceTypesTableIsPaid),
    accessorKey: 'is_paid_text',
  },
];

export const tableStyles: TableStyles = {
  hasHeaderTextWrapping: true,
};
