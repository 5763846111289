import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absenceOverlappedByAttendance: {
    id: 'payrollTable.warnings.absenceOverlappedByAttendance',
    defaultMessage: 'Pracownik ma obecność i nieobecność nachodzące na siebie',
  },
  acceptedOvertime: {
    id: 'payrollTable.warnings.acceptedOvertime',
    defaultMessage: 'Pracownik ma zatwierdzony czas pracy w nadgodzinach',
  },
  draftAbsence: {
    id: 'payrollTable.warnings.draftAbsence',
    defaultMessage: 'Pracownik ma niezaakceptowane nieobecności w tym dniu',
  },
  longAttendance: {
    id: 'payrollTable.warnings.longAttendance',
    defaultMessage: 'Pracownik ma dłuższą obecność w tym dniu',
  },
  outOfRangeAttendance: {
    id: 'payrollTable.warnings.outOfRangeAttendance',
    defaultMessage: 'Pracownik zarejestrował obecność poza wyznaczonym obszarem',
  },
  overlappingAttendances: {
    id: 'payrollTable.warnings.overlappingAttendances',
    defaultMessage: 'Pracownik ma dwie lub więcej nachodzących na siebie obecności w tym dniu',
  },
  unfinishedAttendance: {
    id: 'payrollTable.warnings.unfinishedAttendance',
    defaultMessage: 'Pracownik ma niezakończoną obecność w tym dniu',
  },
  unfinishedBreak: {
    id: 'payrollTable.warnings.unfinishedBreak',
    defaultMessage: 'Pracownik zarejestrował przerwę trwającą do końca jego obecności',
  },
  payrollWarnings: {
    id: 'payrollTable.warnings.payrollWarnings',
    defaultMessage:
      'W wybranym okresie u pracownika występują błędy mogące wpływać na rozliczenie. Przejdź do szczegółów, aby dowiedzieć się więcej',
  },
  approveTooltip: {
    id: 'payrollTable.approveTooltip',
    defaultMessage: 'Kliknij, aby zatwierdzić',
  },
  unapproveTooltip: {
    id: 'payrollTable.unapproveTooltip',
    defaultMessage: 'Kliknij, aby anulować zatwierdzenie',
  },
  payrollApprovalUnavailable: {
    id: 'payrollTable.payrollApprovalUnavailable',
    defaultMessage: 'Ta funkcja jest dostępna od {date}',
  },
  overlappingFreeDayMarkingWithAbsence: {
    id: 'payrollTable.warnings.overlappingFreeDayMarkingWithAbsence',
    defaultMessage: 'Pracownik ma zaplanowaną nieobecność w dniu wolnym od pracy',
  },
  overlappingFreeDayMarkingWithAttendance: {
    id: 'payrollTable.warnings.overlappingFreeDayMarkingWithAttendance',
    defaultMessage: 'Pracownik ma obecność w dniu wolnym od pracy',
  },
  overlappingFreeDayMarkingWithShift: {
    id: 'payrollTable.warnings.overlappingFreeDayMarkingWithShift',
    defaultMessage: 'Pracownik ma zaplanowaną zmianę w dniu wolnym od pracy',
  },
  overtimeCollection: {
    id:'absences.overtimeCollection', 
    defaultMessage:'Odbiór nadgodzin',
  },
});

export const statusMessages = defineMessages({
  approved: {
    id: 'payrollLocationTable.status.approved',
    defaultMessage: 'Zatwierdzone',
  },
  unapproved: {
    id: 'payrollLocationTable.status.unapproved',
    defaultMessage: 'Oczekujące',
  },
  pending: {
    id: 'payrollLocationTable.status.pending',
    defaultMessage: 'W trakcie',
  },
});
