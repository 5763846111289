import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Clock from '@/components/common/Basic/Clock';
import browserHistory from '@/constants/browserHistory';

import Contact from './Contact/Contact';
import StartSteps from './StartSteps/StartSteps.redux';
import VideoTutorialModal from './VideoTutorialModal/VideoTutorialModal.redux';

import './OwnerTrialDashboard.scss';

const OwnerTrialDashboard = ({ roles, isTrialInfoBarVisible, userFirstName }) => {
  if (!roles.includes('ROLE_OWNER') || !isTrialInfoBarVisible) {
    console.log('Owner reload');
    browserHistory.push('/');
  }

  return (
    <div className="md-dashboard ownerTrialDashboard">
      <VideoTutorialModal />
      <div className="dashboardTopBar">
        <div className="dashboardTopBar__info">
          <h2 className="dashboardTopBar__title">
            <FormattedMessage id="dashboard.welcome.goodMorning" defaultMessage="Dzień dobry" />, {userFirstName}
          </h2>
          <p className="dashboardTopBar__description">
            <FormattedMessage id="dashboard.welcome.day" defaultMessage="Dzisiaj jest" />{' '}
            {moment().format('dddd, DD MMMM YYYY')}
          </p>
        </div>
        <div className="dashboardTopBar__date">
          <Clock />
        </div>
      </div>
      <div className="ownerTrialDashboard__content">
        <StartSteps />
        <Contact />
      </div>
    </div>
  );
};

OwnerTrialDashboard.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  isTrialInfoBarVisible: PropTypes.bool,
  userFirstName: PropTypes.string,
  currentCompany: PropTypes.shape({}),
  currentUser: PropTypes.shape({ user: PropTypes.shape({}) }),
};

export default OwnerTrialDashboard;
