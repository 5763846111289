import variables from '@/css/scss/_k_colours.module.scss';

export const SCSS_COLORS = variables;

export const KADRO_PRIMARY_COLOR_DARK = '#0A0322';
export const KADRO_PRIMARY_COLOR_LIGHT = '#272140';
export const KADRO_ORANGE_COLOR = '#ff6b01';
export const KADRO_LIGHT_ORANGE_COLOR = '#ff7c1f';
export const KADRO_BLUE_COLOR = '#0D94CF';

export const KADRO_REAL_COLOR = '#06BBFE';
export const KADRO_EXPECTED_COLOR = '#27215E';

export const MORE_LABELS_BACKGROUND = '#eb6623';

export const ATTENDANCE_SUCCESS_COLOR = '#86BF00';
export const ATTENDANCE_ABSENCE_COLOR = '#FF0000';
export const ATTENDANCE_LATE_COLOR = '#fdce09';
export const ATTENDANCE_ACCEPTED_OVERTIME_COLOR = '#C0E667';

export const SCHEDULE_SELECTED_BACKGROUND = '#e6f8ff';
export const SCHEDULE_PASTE_BACKGROUND = '#fbe6d7';
export const SCHEDULE_NEW_SHIFT = '#454e57';

export const FADED_PINK = '#e797a1';
export const GREEN = '#86bf00';
export const LIGHTER_GRAY = '#eaeaea';
export const LIGHT_GRAY = '#cecece';
export const GRAY = '#aaaaaa';
export const FILE_CSV_COLOR = '#666';
export const FILE_PDF_COLOR = '#c21d15';
export const FILE_QR_COLOR = '#4F93AC';
export const FILE_XLS_COLOR = '#02723b';
export const FILE_XML_COLOR = '#84c05e';

export const FILE_CSV_BACKGROUND = 'rgba(102, 102, 102, 0.2)';
export const FILE_PDF_BACKGROUND = 'rgba(194, 29, 21, 0.2)';
export const FILE_QR_BACKGROUND = '#D2E3EA';
export const FILE_XLS_BACKGROUND = 'rgba(2, 114, 59, 0.2)';
export const FILE_XML_BACKGROUND = 'rgba(132, 192, 94, 0.2)';

export const COLOR_PALETT_DEFAULT_COLOR = '#B3E17D';

export const RED_100 = '#fed7d7';
export const RED_500 = '#fd6060';

export const GREEN_100 = '#e5f4d3';
export const GREEN_500 = '#73a13d';

export const YELLOW_100 = '#ffefc8';
export const YELLOW_500 = '#ffc124';

export const BLUE_100 = '#D6ECFF';
export const BLUE_500 = '#4488BF';

export const MAIN_COLOR_PALETT = [
  '#ffd05a',
  '#f8a66d',
  '#fd9787',
  '#fd8787',
  '#ff9cbb',
  '#e2acf7',
  '#c6a5fc',
  '#ac98ff',
  '#8799ff',
  '#84c8ff',
  '#77e0f0',
  '#6cdbcf',
  '#b3e17d',
  '#88d18c',
  '#75c09d',
  '#7baec0',
  '#96b0bd',
  '#b19a91',
];

export const GREY_300 = '#cdcdcd';
export const GREY_600 = '#616161';
export const PURPLE_900 = '#0a0322';
export const LIME_GREEN_500 = '#86bf00';
export const PURPLE_600 = '#423c59';
export const CARBON = '#333';
export const MILLION_GREY = '#999';
export const TAG_YELLOW_1 = '#FFD05A';
export const ORANGE_500 = '#FF6B01';
