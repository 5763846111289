import { HOUR_PLACEHOLDER } from '@/constants/strings';
import { SHOW_SKELETON_CELL } from '@/constants/tables';
import { EmployeePayroll } from '@/types';

const MAX_NUMBER_OF_SKELETONS = 20;

export const skeletonRow = {
  date: SHOW_SKELETON_CELL,
  startHour: SHOW_SKELETON_CELL,
  bonus: SHOW_SKELETON_CELL,
  payout: SHOW_SKELETON_CELL,
  sumHours: SHOW_SKELETON_CELL,
};

export const getSkeletonRows = () => {
  const skeletonRows = Array.from({ length: MAX_NUMBER_OF_SKELETONS }, () => skeletonRow);
  return skeletonRows;
};

export const getTableData = (data: EmployeePayroll[], showSkeletons: boolean) => {
  if (showSkeletons) return getSkeletonRows();

  const payrollData = data.map(({ date, startHour, endHour, sumHours, bonus, payout }) => ({
    date,
    startHour: `${startHour || HOUR_PLACEHOLDER} - ${endHour || HOUR_PLACEHOLDER}`,
    bonus: `${bonus.toFixed(2)} PLN`,
    payout: `${(payout / 100).toFixed(2)} PLN`,
    sumHours,
  }));

  return [...payrollData];
};
