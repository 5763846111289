import { functionalUpdate, RowData, Table, TableFeature, Updater } from '@tanstack/react-table';

import { MDTableProps } from '../../../MDTable.types';
import { FooterRowsExpandingState, FooterRowsExpandingTableState } from './footerRowsExpanding.types';

export const FooterRowExpandingFeature: TableFeature<unknown> = {
  getInitialState: (state): FooterRowsExpandingTableState => ({
    footerRowsExpanding: {},
    ...state,
  }),

  createTable: <TData extends RowData>(table: Table<TData>): void => {
    table.setRowsExpanding = updater => {
      const safeUpdater: Updater<FooterRowsExpandingState> = old => {
        const newState = functionalUpdate(updater, old);
        return newState;
      };
      return table.options.onRowsExpandingChange?.(safeUpdater);
    };
    table.toggleFooterRowExpanding = value => {
      table.setRowsExpanding(prevExpanding => {
        const newState = { ...prevExpanding };
        newState[value] = !newState[value];
        return { ...newState };
      });
    };
  },
};

export const getInitialFooterRowsExpanding = (
  footerData: MDTableProps<object>['footerData'] = [],
): FooterRowsExpandingState =>
  footerData.reduce<FooterRowsExpandingState>((acc, _, index) => ({ ...acc, [index.toString()]: true }), {});
