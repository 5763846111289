import { memo } from 'react';

import { isEmptyArray } from '@/utils/array/array.helpers';

import { TableTab } from '../MDTable.types';
import MDTableTab from './MDTableTab';

import './TableTabs.scss';

type Props = {
  tabs?: TableTab[];
  activeTab: TableTab['id'];
};

export const TableTabs = memo(({ activeTab, tabs }: Props) => {
  if (isEmptyArray(tabs)) return null;

  return (
    <div className="mdTable__tabs">
      {tabs.map(tab => (
        <MDTableTab tab={tab} isActive={tab.id === activeTab} key={tab.id} />
      ))}
    </div>
  );
});

TableTabs.displayName = 'TableTabs';
