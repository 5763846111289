import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { useInputChange } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers';
import { addShiftModalOnSubmit, editShiftModalOnSubmit } from '@/utils/shiftHelpers';

import RepeatShift from '../AddShiftAndAbsenceModal/RepeatShift/RepeatShift.jsx';
import AddShiftBasic from './AddShiftBasic/AddShiftBasic.redux.js';
import {
  createItemShift,
  createShift,
  getEmployeeJobTitlesForDate,
  getFooterOptions,
  getHeaderOptions,
  getInitialState,
  getUpdatedStateForNewShift,
  getUpdatedStateForNewShiftWithLocationAndDateSelect,
  getUpdatedStateForSelectedRange,
  getUpdatedStateFromAttendance,
  getUpdatedStateFromShift,
  modalModifiers,
} from './AddShiftModal.helpers';
import { messages } from './AddShiftModal.messages';

const AddShiftModal = (props, { intl }) => {
  const [state, handlers] = useInputChange(getInitialState(props), intl);
  const [loading, setLoading] = useState(false);

  const getObjectHistory = async () => {
    setLoading(true);
    await props.getHistoryForShift(props.modalObject?.shift);
    setLoading(false);
  };

  const employeeJobTitles = useMemo(
    () => getEmployeeJobTitlesForDate(props.employeeContracts || [], props.userJobTitles, state.date),
    [props.employeeContracts, props.userJobTitles, state.date],
  );
  const headerOptions = useMemo(
    () => getHeaderOptions(props.demoAccount, state.shift, getObjectHistory),
    [state.shift?.id],
  );
  const footerOptions = useMemo(() => getFooterOptions(intl, props), [props.modalObject]);

  const updateWholeState = updatedState => handlers.updateState({ ...state, ...updatedState });

  useEffect(() => {
    if (props.modalObject?.defaultHours) {
      updateWholeState(
        getUpdatedStateFromAttendance(props.modalObject, props.employeeContracts || [], props.userJobTitles),
      );
    } else if (props.modalObject?.shift?.id) {
      updateWholeState(getUpdatedStateFromShift(props.modalObject));
    } else if (props.modalObject?.addShiftWithLocationAndDateSelect) {
      updateWholeState(
        getUpdatedStateForNewShiftWithLocationAndDateSelect(
          props.modalObject,
          props.employeeContracts || [],
          props.userJobTitles,
          props.userLocations,
        ),
      );
    } else if (props.modalObject?.date) {
      updateWholeState(
        getUpdatedStateForNewShift(
          props.modalObject,
          props.employeeContracts || [],
          props.userJobTitles,
          props.userLocations,
        ),
      );
    }
  }, [props.modalObject]);

  useEffect(() => {
    updateWholeState(getUpdatedStateForSelectedRange(props.mainDateStore));
  }, [props.mainDateStore]);

  const hideAndClear = useCallback(() => {
    props.onHide();
    handlers.setInitState();
  }, []);

  const handleInputChange = useCallback(
    (name, value, valueToCompare) => handlers.changeInput(createEvent(name, value, { valueToCompare })),
    [state],
  );

  const validateAll = async () => {
    const inputs = ['working_hours', 'shiftsOverlap'];
    const eventsToValidate = inputs.map(inputName => createEvent(inputName, state[inputName]));
    const jobTitlesValueToCompare = employeeJobTitles.map(jobTitle => ({ job_title: jobTitle }));
    eventsToValidate.push(
      createEvent('selectedJobTitle', state.selectedJobTitle, { valueToCompare: jobTitlesValueToCompare }),
      createEvent('shiftsOverlap', createItemShift(state), {
        valueToCompare: [...(state.employee.shifts || []), ...(props.employeeAbsences || [])],
      }),
    );
    const validationResult = await Promise.all(eventsToValidate.map(e => handlers.validateInput(e)));
    return !Object.values(validationResult).some(err => err !== '');
  };

  const saveShift = async () => {
    const shift = createShift(props.modalObject, state);
    if (props.modalObject?.shift) {
      editShiftModalOnSubmit(props, shift);
    } else {
      let error;
      try {
        error = await addShiftModalOnSubmit(state, props, shift);
      } catch (err) {
        if (err.message === 'BUDGET_TARGETS_HOURS_LIMIT_EXCEEDED') {
          handlers.setError('budgetTargetsHoursLimitExceeded', messages.budgetTargetsHoursLimitExceeded);
          return;
        }
        if (err.message === 'BUDGET_TARGETS_MONEY_LIMIT_EXCEEDED') {
          handlers.setError('budgetTargetsMoneyLimitExceeded', messages.budgetTargetsMoneyLimitExceeded);
          return;
        }
        if (err.message === 'employeeIsBusy') {
          handlers.setError('shiftsOverlap', messages.workingHoursSingleOverlap);
          return;
        }
      }
      if (error) {
        handlers.setError('repeatedShiftOverlap', error);
        return;
      }
    }
    hideAndClear();
  };

  const onSubmit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) return;
    await saveShift();
  }, [state]);

  const enableWorkingRulesCheck = Boolean(props.locationSettings[state.location.id]?.enable_working_rules_check);
  const disableConfirmButton = props.modalObject?.preApproved && enableWorkingRulesCheck;
  const workingRulesError = disableConfirmButton ? intl.formatMessage(messages.workingRulesError) : null;

  const modalValidationError =
    workingRulesError ||
    state.errors.repeatedShiftOverlap ||
    state.errors.budgetTargetsHoursLimitExceeded ||
    state.errors.budgetTargetsMoneyLimitExceeded;

  const useMultipleLocationFilter = props.modalObject?.locationId ? false : props.useMultipleLocationFilter;

  return (
    <MDKadroModal
      show={props.showModal}
      title={intl.formatMessage(props.modalObject?.shift ? messages.editShift : messages.addShift)}
      onSubmit={onSubmit}
      onHide={hideAndClear}
      headerOptions={headerOptions}
      errorMessage={modalValidationError}
      footerOptions={footerOptions}
      modifiers={modalModifiers}
      loading={loading}
      confirmText={intl.formatMessage(props.modalObject?.shift ? messages.edit : messages.add)}
      disableConfirm={disableConfirmButton}
    >
      <>
        <AddShiftBasic
          employeeJobTitles={employeeJobTitles}
          workingHours={state.working_hours}
          comment={state.comment}
          employee={state.employee}
          userJobTitles={props.userJobTitles}
          handleInputChange={handleInputChange}
          errors={state.errors}
          shift={state.shift}
          selectedJobTitle={state.selectedJobTitle}
          hideAndClear={hideAndClear}
          isEditing={Boolean(props.modalObject?.shift)}
          addShiftWithLocationAndDateSelect={props.modalObject?.addShiftWithLocationAndDateSelect}
          location={state.location}
          date={state.date}
          showAddAttendanceForShift={props.modalObject?.showAddAttendanceForShift}
          addAttendanceForShift={state.addAttendanceForShift}
          useMultipleLocationFilter={useMultipleLocationFilter}
        />
        {!props.modalObject?.shift && !props.modalObject?.attendanceToDelete && !props.modalObject?.hideRepeatShift && (
          <RepeatShift
            displayRepeat={state.displayRepeat}
            activeRepeatTab={state.activeRepeatTab}
            selectedRange={state.selectedRange}
            selectedChoices={state.selectedChoices}
            selectedWeekdays={state.selectedWeekdays}
            perWhichDay={state.perWhichDay}
            handleInputChange={handleInputChange}
            nonWorkingDays={props.nonWorkingDays}
            toggleNonWorkingDays={props.toggleNonWorkingDays}
            error={state.errors.repeatedShiftOverlap}
          />
        )}
      </>
    </MDKadroModal>
  );
};

AddShiftModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddShiftModal.propTypes = {
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  modalObject: PropTypes.shape({
    employee: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    date: PropTypes.string,
    attendanceToDelete: PropTypes.shape({
      id: PropTypes.string,
    }),
    location: PropTypes.shape({}),
    shift: PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      comment: PropTypes.string,
      job_title: PropTypes.string,
      working_hours: PropTypes.string,
    }),
    defaultHours: PropTypes.string,
    hideRepeatShift: PropTypes.bool,
    addShiftWithLocationAndDateSelect: PropTypes.bool,
    showAddAttendanceForShift: PropTypes.bool,
    locationId: PropTypes.string,
  }),
  onHide: PropTypes.func,
  showModal: PropTypes.bool,
  getHistoryForShift: PropTypes.func,
  demoAccount: PropTypes.bool,
  employeeAbsences: PropTypes.arrayOf(PropTypes.shape({})),
  nonWorkingDays: PropTypes.bool,
  toggleNonWorkingDays: PropTypes.func,
  useMultipleLocationFilter: PropTypes.bool,
  locationSettings: PropTypes.shape({}),
  userLocations: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};

export default AddShiftModal;
