import { memo, useMemo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { Absence } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { checkIsMonthlyScheduleView } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import { AbsenceBlock } from './AbsenceBlock';

type AbsencesBlocksProps = {
  absencesForDate: Absence[];
  date: StandardDate;
};

export const AbsencesBlocks = memo(({ absencesForDate, date }: AbsencesBlocksProps) => {
  const dateArray = useAppSelector(selectDateArray);
  const isMonthView = useMemo(() => checkIsMonthlyScheduleView(dateArray), [dateArray]);
  return absencesForDate.map(block => (
    <AbsenceBlock key={block.id} absence={block} date={date} isMonthView={isMonthView} />
  ));
});

AbsencesBlocks.displayName = 'AbsencesBlocks';
