import { defineMessages } from 'react-intl';

import { DateModeEnum } from '@/types/mainDateStore';

// Please not import these date mode and instead use DateModeEnum
export const DATE_MODE_DAY = DateModeEnum.DAY;
export const DATE_MODE_WEEK = DateModeEnum.WEEK;
export const DATE_MODE_MONTH = DateModeEnum.MONTH;
export const DATE_MODE_YEAR = DateModeEnum.YEAR;
export const DATE_MODE_CUSTOM = DateModeEnum.CUSTOM;

export const DATE_MODE_DAY_ID = 3;
export const DATE_MODE_WEEK_ID = 0;
export const DATE_MODE_MONTH_ID = 1;
export const DATE_MODE_YEAR_ID = 4;
export const DATE_MODE_CUSTOM_ID = 2;

const dateModeIntl = defineMessages({
  day: {
    id: 'common.dateMode.day',
    defaultMessage: 'Dzień',
  },
  week: {
    id: 'common.dateMode.week',
    defaultMessage: 'Tydzień',
  },
  month: {
    id: 'common.dateMode.month',
    defaultMessage: 'Miesiąc',
  },
  year: {
    id: 'common.dateMode.year',
    defaultMessage: 'Rok',
  },
  custom: {
    id: 'common.dateMode.custom',
    defaultMessage: 'Własny',
  },
});

export const dateModes = [
  {
    id: DATE_MODE_DAY_ID,
    length: 1,
    type: DateModeEnum.DAY,
    displayText: dateModeIntl.day,
  },
  {
    id: DATE_MODE_WEEK_ID,
    length: 7, // Used to check if we switch from longer to shorter or the other way around
    type: DateModeEnum.WEEK,
    displayText: dateModeIntl.week,
  },
  {
    id: DATE_MODE_MONTH_ID,
    length: 30,
    type: DateModeEnum.MONTH,
    displayText: dateModeIntl.month,
  },
  {
    id: DATE_MODE_YEAR_ID,
    length: 365,
    type: DateModeEnum.YEAR,
    displayText: dateModeIntl.year,
  },
  {
    id: DATE_MODE_CUSTOM_ID,
    length: '?',
    type: DateModeEnum.CUSTOM,
    displayText: dateModeIntl.custom,
  },
];

export const dateMoveDirections = {
  next: 'next',
  previous: 'previous',
};
