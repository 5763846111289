import { memo } from 'react';

import { ScheduleOvertimeCollection } from '@/components/scheduler/ScheduleTable/ScheduleOvertimeCollection/ScheduleOvertimeCollection';
import { OvertimeCollection } from '@/types';

type Props = {
  overtimeCollections: OvertimeCollection[];
};

export const OvertimeCollectionsBlocks = memo(({ overtimeCollections }: Props) =>
  overtimeCollections.map(block => <ScheduleOvertimeCollection key={block.id} overtimeCollection={block} />),
);

OvertimeCollectionsBlocks.displayName = 'OvertimeCollectionsBlocks';
