import { parseMinutesToHumanForm } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import MDTable from '@/components/common/MDTable/MDTable';
import { SortingFnEnum } from '@/components/common/MDTable/MDTable.types.ts';
import EmployeePayrollTableWidgets from '@/components/employee/newPayroll/employeePayrollTableWidgets/EmployeePayrollTableWidgets';
import EmptyState from '@/components/emptyState/EmptyState';
import { EmployeePayroll, EmployeePayrollSummary } from '@/types';

import { getTableData } from './EmployeePayrollTable.helpers.tsx';
import { messages } from './EmployeePayrollTable.messages';
import { EmployeePayrollColumn } from './EmployeePayrollTable.types.ts';

interface EmployeePayrollTableProps {
  data: EmployeePayroll[];
  summary: EmployeePayrollSummary;
  showWagesInEmployeeECP: boolean;
  showSkeletons: boolean;
}

const EmployeePayrollTable = (
  { data, summary, showWagesInEmployeeECP, showSkeletons }: EmployeePayrollTableProps,
  { intl },
) => {
  const columns: EmployeePayrollColumn[] = [
    {
      accessorKey: 'date',
      size: 100,
      header: intl.formatMessage(messages.date),
    },
    {
      accessorKey: 'startHour',
      size: 100,
      header: intl.formatMessage(messages.hours),
    },
    {
      accessorKey: 'sumHours',
      size: 100,
      header: intl.formatMessage(messages.hoursSum),
      cell: ({ cell }) => parseMinutesToHumanForm(cell.row.original.sumHours),
    },
    ...(showWagesInEmployeeECP
      ? [
          {
            accessorKey: 'bonus',
            size: 100,
            header: intl.formatMessage(messages.bonus),
            sortingFn: SortingFnEnum.CURRENCY,
          },
          {
            accessorKey: 'payout',
            size: 100,
            header: intl.formatMessage(messages.payout),
            sortingFn: SortingFnEnum.CURRENCY,
          },
        ]
      : []),
  ];

  const summaryData = useMemo(
    () => [
      { text: intl.formatMessage(messages.hoursSum), value: parseMinutesToHumanForm(summary.sumHours), unit: '' },
      ...(showWagesInEmployeeECP
        ? [
            { text: intl.formatMessage(messages.bonusSum), value: summary.sumBonus.toFixed(2), unit: 'PLN' },
            { text: intl.formatMessage(messages.payoutSum), value: (summary.sumPayout / 100).toFixed(2), unit: 'PLN' },
          ]
        : []),
    ],
    [summary],
  );

  const employeePayrollData = getTableData(data, showSkeletons);

  return (
    <MDTable
      tableOptions={<EmployeePayrollTableWidgets summary={summaryData} showSkeletons={showSkeletons} />}
      columns={columns}
      data={employeePayrollData as any}
      withSearch={false}
      tableEmpty={
        <EmptyState
          imgSrc="/img/jobtitlesCTA.png"
          emptyStateHeader={intl.formatMessage(messages.payrollEmptyStateHeader)}
          emptyStateText={intl.formatMessage(messages.payrollEmptyStateText)}
        />
      }
    />
  );
};

EmployeePayrollTable.contextTypes = {
  intl: PropTypes.shape({}),
};

export default EmployeePayrollTable;
