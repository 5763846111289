import { CellContext } from '@tanstack/react-table';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import MDAlert from '@/components/common/Basic/MDAlert/MDAlert';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { ATTENDANCE_TOOLTIP_OFFSET_Y } from '@/utils/attendance/attendance.utils';

import { AttendanceRowItem } from '../../AttendanceManyDaysTable.types';
import { getOvertimeHours } from './AlertsCell.utils';
import { messages } from './messages';

import './AlertsCell.scss';

export type AlertsCellProps = CellContext<TableRow<AttendanceRowItem>, unknown>;

const Alerts = memo(({ cell }: AlertsCellProps) => {
  const {
    attendanceObject,
    attendanceWithoutShift,
    attendanceWithAbsence,
    attendanceOutOfRange,
    longAttendance,
    hasMoreTime,
  } = cell.row.original;
  const intl = useIntl();
  const amount = [
    attendanceOutOfRange,
    attendanceWithoutShift,
    attendanceWithAbsence,
    longAttendance,
    hasMoreTime,
  ].filter(Boolean).length;

  return (
    <div className="k-attendanceAlertCell">
      <div
        className="k-attendanceAlertCell__innerContainer"
        style={{ gridTemplateColumns: `repeat(${Math.min(3, amount)}, 1fr` }}
      >
        {attendanceOutOfRange && (
          <MDAlert
            yOffset={ATTENDANCE_TOOLTIP_OFFSET_Y}
            iconModifiers="table"
            icon="wrong_location"
            iconType="outlined"
            label={intl.formatMessage(messages.wrongLocation)}
          />
        )}
        {attendanceWithoutShift && (
          <MDAlert
            yOffset={ATTENDANCE_TOOLTIP_OFFSET_Y}
            iconModifiers="table"
            icon="event_busy"
            label={intl.formatMessage(messages.attendancesTableAlertsNoShift)}
          />
        )}
        {attendanceWithAbsence && (
          <MDAlert
            yOffset={ATTENDANCE_TOOLTIP_OFFSET_Y}
            iconModifiers="table"
            icon="flight"
            label={intl.formatMessage(messages.attendancesTableAlertsAbsenceWithAttendance)}
          />
        )}
        {longAttendance && (
          <MDAlert
            yOffset={ATTENDANCE_TOOLTIP_OFFSET_Y}
            iconModifiers="table"
            icon="nights_stay"
            label={intl.formatMessage(messages.attendancesTableAlertsLongAttendance)}
          />
        )}
        {hasMoreTime && (
          <MDAlert
            yOffset={ATTENDANCE_TOOLTIP_OFFSET_Y}
            iconModifiers="table"
            icon="more_time"
            label={intl.formatMessage(messages.attendancesTableAlertsMoreTime, {
              hours: getOvertimeHours(attendanceObject, intl),
            })}
          />
        )}
      </div>
    </div>
  );
});

Alerts.displayName = 'Alerts';

export const AttendanceAlertsCell = memo((props: AlertsCellProps) => {
  if ('subRows' in props.row.original) return null;

  return <Alerts {...props} />;
});

AttendanceAlertsCell.displayName = 'AttendanceAlertsCell';
