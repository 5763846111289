import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  registerTime: {
    id: 'dashboardTimeTracker.registerTime',
    defaultMessage: 'Rejestruj czas',
  },
  confirm: {
    id: 'dashboardTimeTracker.confirm',
    defaultMessage: 'Rozpocznij',
  },
  cancel: {
    id: 'dashboardTimeTracker.cancel',
    defaultMessage: 'Anuluj',
  },
  confirmText: {
    id: 'dashboardTimeTracker.confirmText',
    defaultMessage: 'Czy na pewno chcesz rozpocząć obecność?',
  },
  previousShift: {
    id: 'dashboardTimeTracker.previousShift',
    defaultMessage: 'Poprzednia zmiana zakończyła się{day} {at} {hour}.',
  },
  nextShiftStarts: {
    id: 'dashboardTimeTracker.nextShift',
    defaultMessage: 'Następna zmiana rozpoczyna się{day} {at} {hour}.',
  },
  start: {
    id: 'dashboardTimeTracker.start',
    defaultMessage: 'Start: {hour} {locationName}',
  },
  breaksDuration: {
    id: 'dashboardTimeTracker.breaksDuration',
    defaultMessage: 'Czas przerw',
  },
  breakIsRunning: {
    id: 'dashboardTimeTracker.breakIsRunning',
    defaultMessage: 'Trwa przerwa',
  },
  startAttendance: {
    id: 'dashboardTimeTracker.startAttendance',
    defaultMessage: 'Rozpocznij obecność',
  },
  endAttendance: {
    id: 'dashboardTimeTracker.endAttendance',
    defaultMessage: 'Zakończ obecność',
  },
  startBreak: {
    id: 'dashboardTimeTracker.startBreak',
    defaultMessage: 'Rozpocznij przerwę',
  },
  endBreak: {
    id: 'dashboardTimeTracker.endBreak',
    defaultMessage: 'Zakończ przerwę',
  },
  currentShift: {
    id: 'dashboardTimeTracker.currentShift',
    defaultMessage: 'Obecna zmiana',
  },
  nextShift: {
    id: 'dashboardTimeTracker.nextShift',
    defaultMessage: 'Najbliższa zmiana',
  },
  noShifts: {
    id: 'dashboardTimeTracker.noShifts',
    defaultMessage: 'Brak zaplanowanych zmian na najbliższe 30 dni',
  },
  workSummary: {
    id: 'dashboardTimeTracker.workSummary',
    defaultMessage: 'Podsumowanie pracy',
  },
  selectLocation: {
    id: 'dashboardTimeTracker.selectLocation',
    defaultMessage: 'Wybierz lokalizację',
  },
  end: {
    id: 'dashboardTimeTracker.end',
    defaultMessage: 'Zakończ',
  },
  startHour: {
    id: 'dashboardTimeTracker.startHour',
    defaultMessage: 'Godzina rozpoczęcia',
  },
  endHour: {
    id: 'dashboardTimeTracker.endHour',
    defaultMessage: 'Godzina zakończenia',
  },
  breakTime: {
    id: 'dashboardTimeTracker.breakTime',
    defaultMessage: 'Czas na przerwie',
  },
  totalTime: {
    id: 'dashboardTimeTracker.totalTime',
    defaultMessage: 'Całkowity czas',
  },
  begin: {
    id: 'dashboardTimeTracker.start',
    defaultMessage: 'Rozpocznij',
  },
  location: {
    id: 'dashboardTimeTracker.location',
    defaultMessage: 'Lokalizacja',
  },
  noNextShift: {
    id: 'dashboardTimeTracker.noNextShift',
    defaultMessage: 'Brak zaplanowanej zmiany na najbliższe 12h.',
  },
  tomorrow: {
    id: 'dashboardTimeTracker.tomorrow',
    defaultMessage: 'jutro',
  },
  tomorrowCapitalized: {
    id: 'dashboardTimeTracker.tomorrowCapitalized',
    defaultMessage: 'Jutro',
  },
  todayCapitalized: {
    id: 'dashboardTimeTracker.todayCapitalized',
    defaultMessage: 'Dziś',
  },
  yesterday: {
    id: 'dashboardTimeTracker.yesterday',
    defaultMessage: 'wczoraj',
  },
  at: {
    id: 'dashboardTimeTracker.at',
    defaultMessage: 'o',
  },
});
