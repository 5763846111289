import { daysShortcuts } from '@/constants/dates.js';
import { toUnderscore } from '@/utils/stringHelpers';

import { allAuthMethods,baseLocationSettings } from './constants';
import {
  getInitialBonusHoursBlocks,
  getInitialBonusTargetOptions,
} from './options/BudgetOptions/BudgetOptions.helpers.js';

export const mapPropsToStateObj = (locationId, settings) => {
  if (!settings.locationSettings[locationId]) return baseLocationSettings;
  const result = Object.keys(baseLocationSettings).reduce((res, key) => {
    const nameInProps = toUnderscore(key);

    const val = settings.locationSettings[locationId][nameInProps];

    return {
      ...res,
      [key]: val !== undefined ? val : baseLocationSettings[key],
    };
  }, {});
  result.authorization_qrcode_activity_autodisable = result.authorization_qrcode_activity_time > 0;
  const [latitude, longitude] = result.location_coordinates ? result.location_coordinates.split(' ') : [0.0, 0.0];
  result.latitude = latitude;
  result.longitude = longitude;
  const availableAuthMethods = [];
  if (result.authorization_pin_enable) availableAuthMethods.push(allAuthMethods[0]);
  if (result.authorization_qrcode_enable) availableAuthMethods.push(allAuthMethods[1]);
  result.availableAuthMethods = availableAuthMethods;
  result.locationOpeningHoursEnabled = Boolean(result.openHours) || result.open_hours_based_on_schedule;
  result.openHoursMethod = result.open_hours_based_on_schedule ? 'schedule' : 'custom';
  result.openHours = result.open_hours_based_on_schedule
    ? daysShortcuts.reduce((days, day) => ({ ...days, [day]: null }), {})
    : result.openHours;
  result.locationSystemBonusEnabled = Boolean(result.location_system_bonus_enabled);
  result.bonusHoursBlocks = getInitialBonusHoursBlocks(result.bonusHourBlocks);
  result.bonusTargetOptions = getInitialBonusTargetOptions(result.bonusThresholds);
  return result;
};
