import { useCallback, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { useAppSelector } from '@/redux-store';
import { selectIntl } from '@/redux-store/intl';

import plMessages from '../../../../intl/compiledLang/pl.json';

type Props = {
  children: React.ReactNode;
};

export const KadroIntlProvider = ({ children }: Props) => {
  const { locale, messages: localeMessages } = useAppSelector(selectIntl);
  const textComponent = 'span';

  const messages = useMemo(() => ({ ...plMessages, ...localeMessages }), [localeMessages]);

  const onError = useCallback(err => {
    if (import.meta.env.VITE_NODE_ENV === 'translation') {
      // eslint-disable-next-line no-console
      console.log(err?.toString()?.substring(0, 100));
    }
  }, []);

  return (
    <IntlProvider onError={onError} key={locale} {...{ locale, textComponent, messages }}>
      {children}
    </IntlProvider>
  );
};
