import { IntlShape } from 'react-intl';

import { SortingFnEnum, TableColumnType } from '@/components/common/MDTable/MDTable.types';

import { AbsenceLeftCell } from './AbsenceLeftCell/AbsenceLeftCell';
import { AbsenceLimitRow } from './AbsencesLimitsView.types';
import { messages } from './messages';

export const getColumns = (
  intl: IntlShape,
  onFilter: (columnId: string, filterValue: string) => void,
): TableColumnType<AbsenceLimitRow>[] => [
  {
    header: intl.formatMessage(messages.absencesLimitsViewTableEmployee),
    accessorKey: 'employee',
    enableSorting: false,
    filterFn: (_row, columnId, filterValue) => {
      onFilter(columnId, filterValue);
      return true;
    },
  },
  {
    header: intl.formatMessage(messages.absencesLimitsViewTableLimit),
    accessorKey: 'limit',
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    header: intl.formatMessage(messages.absencesLimitsViewTableUsed),
    accessorKey: 'used',
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    header: intl.formatMessage(messages.absencesLimitsViewTableLeft),
    enableColumnFilter: false,
    accessorKey: 'left',
    enableSorting: false,
    cell: props => <AbsenceLeftCell {...props} />,
  },
];

export const getEmployeeColumns = (intl: IntlShape): TableColumnType<AbsenceLimitRow>[] => [
  {
    header: intl.formatMessage(messages.absencesLimitsViewTableAbsenceType),
    accessorKey: 'absenceType',
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
  },
  {
    header: intl.formatMessage(messages.absencesLimitsViewTableLimit),
    accessorKey: 'limit',
    enableSorting: false,
  },
  {
    header: intl.formatMessage(messages.absencesLimitsViewTableUsed),
    accessorKey: 'used',
    enableSorting: false,
  },
  {
    header: intl.formatMessage(messages.absencesLimitsViewTableLeft),
    accessorKey: 'left',
    enableSorting: false,
    cell: props => <AbsenceLeftCell {...props} />,
  },
];
