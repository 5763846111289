import moment from 'moment';

import {
  COMARCH_XLS,
  getExportPayrollAttendanceSummary,
  getExportPayrollScheduleSummary,
} from '@/constants/exportConstants';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers.js';

import { listArrayItems, roundToTwoSigDigits } from '../baseHelpers.js';
import { getCookie } from '../cookieHandlers.js';
import { calculateDurationMinutes, parseMinutesToFormat, parseMinutesToFullHours } from '../dateHelper.js';
import XLSExport from './XLSExport.js';

/**
 * This functions prepares the csv from headers and body and invokes a download.
 * @param {Array<string>} headers - array of header row
 * @param {Array<Object>} body
 * @param {string} filename - Filename of the file to download
 */
export const prepareCSVandDownload = (headers, body, filename) => {
  window.downloadInProgress = true;
  let csvContent = 'data:text/csv;charset=utf-8,';
  const csvHeaders = `${headers.join(',')}\n`;
  csvContent += csvHeaders + body.join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for FF
  link.click();
  setTimeout(() => {
    window.downloadInProgress = false;
  }, 1000);
};

export const downloadFileFromUrl = (url, filename = '') => {
  window.downloadInProgress = true;
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => {
    window.downloadInProgress = false;
  }, 1000);
};

export const createEnovaXMLandDownload = (data, filename) => {
  // Creating xml document and Root node
  const doc = document.implementation.createDocument(null, 'Root', null);
  // Setting namespaces for our document. I have no idea what for...
  doc.documentElement.setAttribute('xmlns:xsd', 'http://www.w3.org/2001/XMLSchema');
  doc.documentElement.setAttribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');
  // Creating CzasPracy node
  const CzasPracy = doc.createElement('CzasPracy');
  doc.documentElement.appendChild(CzasPracy);

  data
    .filter(row => !row.isAvailability)
    .forEach(row => {
      const DzienPracy = doc.createElement('DzienPracy');
      CzasPracy.appendChild(DzienPracy);

      const Pracownik = doc.createElement('Pracownik');
      Pracownik.innerHTML = row.employee.reference_id;
      DzienPracy.appendChild(Pracownik);

      const Data = doc.createElement('Data');
      Data.innerHTML = moment(row.date).format('YYYY-MM-DD');
      DzienPracy.appendChild(Data);
      if (!row.free) {
        const Strefy = doc.createElement('Strefy');
        DzienPracy.appendChild(Strefy);

        const StrefaPracy = doc.createElement('StrefaPracy');
        StrefaPracy.setAttribute('Definicja', 'Praca w normie');
        StrefaPracy.setAttribute('OdGodziny', row.start.split(' ')[1].slice(0, 5));
        StrefaPracy.setAttribute(
          'Czas',
          `${`0${Math.floor(row.timeWorked / 60)}`.slice(-2)}:${`0${row.timeWorked % 60}`.slice(-2)}`,
        );
        Strefy.appendChild(StrefaPracy);
      }
    });
  const serializer = new XMLSerializer();
  const xmlString = serializer.serializeToString(doc);
  const output = `data:text/xml;charset=utf-8,<?xml version="1.0" encoding="Unicode" ?>${xmlString}`;
  const encodedUri = encodeURI(output);
  downloadFileFromUrl(encodedUri, filename);
};

export const exportEnovaShifts = (data, filename) => {
  // Creating xml document and Root node
  const doc = document.implementation.createDocument(null, 'Root', null);
  // Setting namespaces for our document. I have no idea what for...
  doc.documentElement.setAttribute('xmlns:xsd', 'http://www.w3.org/2001/XMLSchema');
  doc.documentElement.setAttribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');

  const DniPlanu = doc.createElement('DniPlanu');
  doc.documentElement.appendChild(DniPlanu);

  data.forEach(row => {
    const DzienPlanu = doc.createElement('DzienPlanu');
    DniPlanu.appendChild(DzienPlanu);

    const Pracownik = doc.createElement('Pracownik');
    Pracownik.innerHTML = row.employee.reference_id;
    DzienPlanu.appendChild(Pracownik);

    const Data = doc.createElement('Data');
    Data.innerHTML = moment(row.date).format('YYYY-MM-DD');
    DzienPlanu.appendChild(Data);

    if (!row.shift.free) {
      const Definicja = doc.createElement('Definicja');
      Definicja.innerHTML = 'Pracy';
      DzienPlanu.appendChild(Definicja);

      const OdGodziny = doc.createElement('OdGodziny');
      OdGodziny.innerHTML = row.shift.start_timestamp.split(' ')[1].slice(0, 5);
      DzienPlanu.appendChild(OdGodziny);

      const Czas = doc.createElement('Czas');
      const minutes = calculateDurationMinutes(row.shift.working_hours);
      Czas.innerHTML = parseMinutesToFullHours(minutes);
      DzienPlanu.appendChild(Czas);
    } else {
      const Definicja = doc.createElement('Definicja');
      Definicja.innerHTML = 'Wolny';
      DzienPlanu.appendChild(Definicja);
    }
  });
  const serializer = new XMLSerializer();
  const xmlString = serializer.serializeToString(doc);
  const output = `data:text/xml;charset=utf-8,<?xml version="1.0" encoding="windows-1250" ?>${xmlString}`;
  const encodedUri = encodeURI(output);
  downloadFileFromUrl(encodedUri, filename);
};

const commonHeaders = ['Kod', 'Nazwisko', 'Imie', 'Data', 'TypDnia'];

const singleShiftHeaders = [
  'GodzOd',
  'GodzDo',
  'Strefa',
  'Opis_Nadg',
  'Wydzial',
  'Wydzial_adres_wezla',
  'Projekt',
  'Projekt_adres_wezla',
];

const getDailyShitsCount = dayData => parseInt((dayData.length - commonHeaders.length) / singleShiftHeaders.length) + 1;

const formatComarchXlsShiftsRows = (data, payoutSettingType) =>
  data
    .filter(row => row.date)
    .map(row => {
      const startHour = payoutSettingType === 'shifts' ? row.startShift : row.start;
      const endHour = payoutSettingType === 'shifts' ? row.endShift : row.end;
      return {
        employeeId: row.employee.id,
        date: row.date,
        rowData: [
          row.referenceId,
          row.employee.last_name,
          row.employee.first_name,
          row.date,
          'P',
          startHour,
          endHour,
          'praca.pdst',
          '',
          '',
          '',
          '',
          '',
        ],
      };
    });

const getComarchXlsShiftsRawData = (data, payoutSettingType) => {
  const formattedRows = formatComarchXlsShiftsRows(data, payoutSettingType);
  return formattedRows.reduce(
    (acc, cur) => {
      const dataKey = `${cur.employeeId}-${cur.date}`;
      const sameDayData = acc[dataKey];

      if (sameDayData) {
        const dailyShitsCount = getDailyShitsCount(sameDayData);
        const newData = [...acc[dataKey], ...cur.rowData.slice(5)];
        const maxDailyShifts = dailyShitsCount > acc.maxDailyShifts ? dailyShitsCount : acc.maxDailyShifts;

        return { ...acc, [dataKey]: newData, maxDailyShifts };
      }

      return { ...acc, [dataKey]: cur.rowData };
    },
    { maxDailyShifts: 1 },
  );
};

const createComarchXlsShiftsHeaders = rawComarchData => {
  const shiftHeaders = Array.from({ length: rawComarchData.maxDailyShifts }, (v, k) =>
    singleShiftHeaders.map(item => `W${k + 1}_${item}`),
  ).flat();
  return [...commonHeaders, ...shiftHeaders];
};

const isHourColumn = idx => {
  const offset = commonHeaders.length;
  const shiftsHeadersLength = singleShiftHeaders.length;
  const hoursColIndexes = [0, 1];
  return hoursColIndexes.includes((idx - offset) % shiftsHeadersLength);
};

const formatHoursCols = hour => (hour?.match(/^[0-9]{2}:[0-9]{2}$/) ? hour : '00:00');

const createComarchXlsShiftsBody = (headers, rawComarchData) => {
  const { maxDailyShifts: _, ...shiftsRows } = rawComarchData;
  const emptyCellIndexes = [0];

  const body = Object.values(shiftsRows).map(row =>
    Array.from({ length: headers.length }, (v, k) => {
      if (emptyCellIndexes.includes(k)) {
        return row[k] || COMARCH_XLS.EMPTY;
      }

      if (isHourColumn(k)) {
        return formatHoursCols(row[k]);
      }
      return row[k] || COMARCH_XLS.NO_DATA;
    }),
  );
  return [headers, ...body];
};

export const createComarchXlsShifts = (data, payoutSettingType) => {
  if (!data) return [];

  const rawComarchData = getComarchXlsShiftsRawData(data, payoutSettingType);
  const headers = createComarchXlsShiftsHeaders(rawComarchData);
  const body = createComarchXlsShiftsBody(headers, rawComarchData);
  return body;
};

export const createComarchXLSandDownload = (sheets, filename) => {
  const xls = new XLSExport();
  xls.aoaToXlsMultipleSheets(sheets, null, 'biff8');
  xls.download(filename);
};

const initDay = row => {
  if (!row.employee.overtimeStats || !row.employee.overtimeStats.overtime) {
    row.employee.overtimeStats = { overtime: { scheduleCycleOvertime: 0 } };
  }
  return {
    // Kiedy mamy do czynienia z absencją pracownika,
    // chcemy uzupełnić godzinami jej trwania także kolumny powiązane ze zmianami,
    // czyli Od Grafik, Do Grafik, Czas Grafik
    isAvailability: row.isAvailability,
    employee: row.employee,
    date: row.date,
    referenceId: row.referenceId,
    start: row.start,
    startAtt: row.startAtt,
    startShift: row.isAvailability ? row.start : row.startShift,
    availability: row.availability,
    end: row.end,
    endAtt: row.endAtt,
    endShift: row.isAvailability ? row.end : row.endShift,
    locations: [row.location],
    jobTitles: [row.jobTitle],
    wages: [row.wage],
    sumedOvertime50: [row.sumOvertime50],
    sumedOvertime100: [row.sumOvertime100],
    timeWorked: row.timeWorked || 0,
    nightTimeWorked: row.nightTimeWorked || 0,
    bonus: row.bonus || 0,
    sumPaid: row.sumPaid || 0,
    sumBreaks: row.sumBreaks || 0,
    timePlanned: (row.isAvailability ? row.timeWorked : row.timePlanned) || 0,
    timeWorkedReal: row.timeWorkedReal || 0,
    scheduleCycleOvertime:
      row.employee.overtimeStats && row.employee.overtimeStats.overtime
        ? row.employee.overtimeStats.overtime.scheduleCycleOvertime
        : 0,
  };
};

const incrementDay = (prevData, row) => ({
  ...prevData,
  end: prevData.end < row.end ? row.end : prevData.end,
  endAtt: prevData.endAtt < row.endAtt ? row.endAtt : prevData.endAtt,
  endShift: prevData.endShift < row.endShift ? row.endShift : prevData.endShift,
  locations: !prevData.locations.includes(row.location) ? [...prevData.locations, row.location] : prevData.locations,
  jobTitles: !prevData.jobTitles.includes(row.jobTitle) ? [...prevData.jobTitles, row.jobTitle] : prevData.jobTitles,
  wages: !prevData.wages.includes(row.wage) ? [...prevData.wages, row.wage] : prevData.wages,
  sumedOvertime50: !prevData.sumedOvertime50.includes(row.sumOvertime50)
    ? [...prevData.sumedOvertime50, row.sumOvertime50]
    : prevData.sumedOvertime50,
  sumedOvertime100: !prevData.sumedOvertime100.includes(row.sumOvertime100)
    ? [...prevData.sumedOvertime100, row.sumOvertime100]
    : prevData.sumedOvertime100,
  timeWorked: row.timeWorked ? prevData.timeWorked + row.timeWorked : prevData.timeWorked,
  nightTimeWorked: row.nightTimeWorked ? prevData.nightTimeWorked + row.nightTimeWorked : prevData.nightTimeWorked,
  bonus: row.bonus ? prevData.bonus + row.bonus : prevData.bonus,
  sumPaid: row.sumPaid ? prevData.sumPaid + row.sumPaid : prevData.sumPaid,
  sumBreaks: row.sumBreaks ? prevData.sumBreaks + row.sumBreaks : prevData.sumBreaks,
  timePlanned: row.timePlanned ? prevData.timePlanned + row.timePlanned : prevData.timePlanned,
  timeWorkedReal: row.timeWorkedReal ? prevData.timeWorkedReal + row.timeWorkedReal : prevData.timeWorkedReal,
});

export const mergePerDay = rows => {
  const result = rows
    .sort((a, b) => (a.employee.id + a.date + a.start > b.employee.id + b.date + b.start ? 1 : -1))
    .reduce((prev, row) => {
      if (!prev[row.date + row.employee.id]) {
        return {
          ...prev,
          [row.date + row.employee.id]: {
            ...initDay(row),
          },
        };
      }
      return {
        ...prev,
        [row.date + row.employee.id]: {
          ...incrementDay(prev[row.date + row.employee.id], row),
        },
      };
    }, {});

  return Object.values(result);
};

export const fillInEmptyDays = (rows, dateArray, employee) => {
  const newRows = [];
  let index = 0;
  let d = 0;
  let added = false;
  while (d < dateArray.length) {
    if (rows[index] && rows[index].date === dateArray[d]) {
      added = true;
      newRows.push(rows[index]);
      index++;
    } else {
      const newRow = {
        date: dateArray[d],
        employee,
        timeWorked: 0,
        timeWorkedReal: 0,
        timePlanned: 0,
        nightTimeWorked: 0,
        bonus: 0,
        sumPaid: 0,
        sumOvertime50: 0,
        sumOvertime100: 0,
      };

      if (!added) newRows.push(newRow);
      d++;
      added = false;
    }
  }
  return newRows;
};

export const payrollLocationRowObjectToShiftsArray = (row, timeFormatType) => {
  const {
    startShift,
    endShift,
    location,
    jobTitle,
    wage,
    bonus,
    sumPaid,
    date,
    sumOvertime50,
    sumOvertime100,
    scheduleCycleOvertime,
    timePlanned,
    nightTimeWorked,
    employee,
    referenceId,
    availability,
    sumBreaks,
  } = row;
  const momentDate = moment(date, 'YYYY-MM-DD');
  const dayName = momentDate.format('dddd');
  return [
    employee.first_name,
    employee.last_name,
    referenceId,
    date,
    dayName,
    location,
    jobTitle,
    wage,
    startShift,
    endShift,
    parseMinutesToFormat(timePlanned || 0, timeFormatType, true, false),
    parseMinutesToFormat(nightTimeWorked || 0, timeFormatType, true, false),
    bonus,
    parseMinutesToFormat(sumBreaks || 0, timeFormatType, true, true),
    parseMinutesToFormat(sumOvertime50 || 0, timeFormatType, true, false),
    parseMinutesToFormat(sumOvertime100 || 0, timeFormatType, true, false),
    parseMinutesToFormat(scheduleCycleOvertime || 0, timeFormatType, true, false),
    sumPaid || 0,
    availability,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    startShift,
    endShift,
  ];
};

export const payrollLocationRowObjectToECPArray = (row, timeFormatType) => {
  const {
    start,
    end,
    startShift,
    endShift,
    startAtt,
    endAtt,
    location,
    jobTitle,
    wage,
    bonus,
    sumPaid,
    date,
    sumOvertime50,
    sumOvertime100,
    scheduleCycleOvertime,
    timeWorked,
    timeWorkedReal,
    timePlanned,
    nightTimeWorked,
    employee,
    referenceId,
    availability,
    isAvailability,
    sumBreaks,
  } = row;
  const timeDiff =
    timePlanned !== undefined && timeWorked !== undefined
      ? parseMinutesToFormat(timeWorked - timePlanned, timeFormatType, true, false)
      : 0;
  const momentDate = moment(date, 'YYYY-MM-DD');
  const dayName = momentDate.format('dddd');

  return [
    employee.first_name,
    employee.last_name,
    referenceId,
    date,
    dayName,
    location,
    jobTitle,
    wage,
    isAvailability ? start : startShift,
    isAvailability ? end : endShift,
    isAvailability
      ? parseMinutesToFormat(timeWorked || 0, timeFormatType, true, false)
      : parseMinutesToFormat(timePlanned || 0, timeFormatType, true, false),
    startAtt,
    endAtt,
    parseMinutesToFormat(timeWorkedReal || 0, timeFormatType, true, false),
    start,
    end,
    parseMinutesToFormat(timeWorked || 0, timeFormatType, true, false),
    parseMinutesToFormat(nightTimeWorked || 0, timeFormatType, true, false),
    bonus,
    timeDiff,
    parseMinutesToFormat(sumBreaks || 0, timeFormatType, true, true),
    parseMinutesToFormat(sumOvertime50 || 0, timeFormatType, true, false),
    parseMinutesToFormat(sumOvertime100 || 0, timeFormatType, true, false),
    parseMinutesToFormat(scheduleCycleOvertime || 0, timeFormatType, true, false),
    sumPaid || 0,
    availability,
    start,
    end,
  ];
};

export const payrollLocationRowObjectToShiftsUnlessFixedArray = (row, timeFormatType) => {
  const {
    start,
    end,
    startShift,
    endShift,
    startAtt,
    endAtt,
    location,
    jobTitle,
    wage,
    bonus,
    sumPaid,
    date,
    sumOvertime50,
    sumOvertime100,
    scheduleCycleOvertime,
    timeWorked,
    timeWorkedReal,
    timePlanned,
    nightTimeWorked,
    employee,
    referenceId,
    availability,
    sumBreaks,
  } = row;
  const timeDiff =
    timePlanned !== undefined && timeWorked !== undefined
      ? parseMinutesToFormat(timeWorked - timePlanned, timeFormatType, true, false)
      : 0;
  const momentDate = moment(date, 'YYYY-MM-DD');
  const dayName = momentDate.format('dddd');

  return [
    employee.first_name,
    employee.last_name,
    referenceId,
    date,
    dayName,
    location,
    jobTitle,
    wage,
    startShift,
    endShift,
    parseMinutesToFormat(timePlanned || 0, timeFormatType, true, false),
    startAtt,
    endAtt,
    parseMinutesToFormat(timeWorkedReal || 0, timeFormatType, true, false),
    start,
    end,
    parseMinutesToFormat(timeWorked || 0, timeFormatType, true, false),
    parseMinutesToFormat(nightTimeWorked || 0, timeFormatType, true, false),
    bonus,
    timeDiff,
    parseMinutesToFormat(sumBreaks || 0, timeFormatType, true, true),
    parseMinutesToFormat(sumOvertime50 || 0, timeFormatType, true, false),
    parseMinutesToFormat(sumOvertime100 || 0, timeFormatType, true, false),
    parseMinutesToFormat(scheduleCycleOvertime || 0, timeFormatType, true, false),
    sumPaid || 0,
    availability,
    start,
    end,
  ];
};

const payrollLocationAbsencesSummary = (row, timeFormatType) => {
  const { absence, absence_time: rowAbsenceTime = 0 } = row;
  const absenceTime = parseMinutesToFormat(rowAbsenceTime * 60, timeFormatType, true, true);
  return [absence?.short_name || '', absence?.code || '', absenceTime];
};

export const payrollLocationRowObjectToArray = (
  row,
  timeFormatType,
  payoutSettingType = null,
  absenceTypes = [],
  showAvailabilities,
) => {
  if (!row || !timeFormatType) return [];

  let result = [];
  let numberOfColumns = 0;

  if (payoutSettingType === 'ecp') {
    result = payrollLocationRowObjectToECPArray(row, timeFormatType);
    numberOfColumns = 25;
  } else if (payoutSettingType === 'shifts') {
    result = payrollLocationRowObjectToShiftsArray(row, timeFormatType);
    numberOfColumns = 18;
  } else if (payoutSettingType === 'shifts_unless_fixed' || payoutSettingType === 'shifts_no_extra') {
    result = payrollLocationRowObjectToShiftsUnlessFixedArray(row, timeFormatType);
    numberOfColumns = 25;
  }

  if (showAvailabilities) {
    numberOfColumns += 1;
  }

  result = [...result.slice(0, numberOfColumns)];

  if (absenceTypes.length > 0) {
    const absencesSummary = payrollLocationAbsencesSummary(row, timeFormatType);
    const absenceTypeCols = absenceTypes.map(absenceType =>
      absenceType.id === row.absence?.type_id ? row.absence_time : '',
    );

    result = [...result, ...absencesSummary, ...absenceTypeCols];
  }

  return result;
};

export const payrollLocationMergeInDay = singleDays =>
  (singleDays || []).map(day => ({
    ...day,
    wage: listArrayItems(day.wages),
    jobTitle: listArrayItems(day.jobTitles),
    location: listArrayItems(day.locations),
    sumOvertime50: listArrayItems(day.sumedOvertime50),
    sumOvertime100: listArrayItems(day.sumedOvertime100),
  }));

export const payrollLocationFreeDays = (body, dateArray) => {
  if (body === undefined || dateArray === undefined) return [];
  let output = [];
  let employeesRows = [];
  let currentEmp = null;
  body.forEach((r, i) => {
    if (currentEmp && currentEmp.id === r.employee.id) {
      employeesRows.push(r);
      if (i === body.length - 1) {
        output = [...output, ...fillInEmptyDays(employeesRows, dateArray, currentEmp)];
      }
    } else {
      if (currentEmp) {
        output = [...output, ...fillInEmptyDays(employeesRows, dateArray, currentEmp)];
      }
      currentEmp = r.employee;
      employeesRows = [r];
      if (i === body.length - 1) {
        output = [...output, ...fillInEmptyDays(employeesRows, dateArray, currentEmp)];
      }
    }
  });
  return output;
};

export const payrollRowObjectToArray = (row, timeFormatType) => {
  if (!row || !timeFormatType) return [];
  const {
    date,
    location,
    start,
    end,
    jobTitle,
    wage,
    timeWorked,
    nightTimeWorked,
    bonus,
    sumPaid,
    sumBreaks,
    timePlanned,
    timeWorkedReal,
    employee,
    absence_short_name: absenceShortName,
    absence_time: absenceTime,
    sumOvertime50,
    sumOvertime100,
  } = row;
  const timePlannedVsReal =
    timePlanned !== undefined && timeWorkedReal !== undefined
      ? `${parseMinutesToFormat(timeWorkedReal, timeFormatType)} / ${parseMinutesToFormat(timePlanned, timeFormatType)}`
      : '---';
  const timeDiff =
    timePlanned !== undefined && timeWorkedReal !== undefined
      ? `${parseMinutesToFormat(timeWorkedReal - timePlanned, timeFormatType)}`
      : '---';
  const overtime50 = sumOvertime50 || sumOvertime50 === 0 ? parseMinutesToFormat(sumOvertime50, timeFormatType) : '-';
  const overtime100 =
    sumOvertime100 || sumOvertime100 === 0 ? parseMinutesToFormat(sumOvertime100, timeFormatType) : '-';

  return [
    employee.first_name,
    employee.last_name,
    date,
    location,
    start,
    end,
    jobTitle,
    wage,
    roundToTwoSigDigits(timeWorked / 60),
    absenceShortName ? `${absenceShortName} - ${absenceTime}h` : '',
    parseMinutesToFormat(timeWorked, timeFormatType),
    nightTimeWorked ? parseMinutesToFormat(nightTimeWorked, timeFormatType) : '---',
    bonus,
    timePlannedVsReal,
    timeDiff,
    overtime50,
    overtime100,
    '-',
    parseMinutesToFormat(sumBreaks || 0, timeFormatType),
    sumPaid,
    '',
    '',
    '',
    roundToTwoSigDigits(nightTimeWorked / 60),
    employee.reference_id,
    timeWorked,
    start,
    end,
  ];
};

const formattedHourIntoDecimal = (value, format) => {
  let result;
  if (format === 'hours' && typeof value === 'string') {
    result = (parseInt(value.split('h ')[0]) * 60 + parseInt(value.split('h ')[1].replace('m', ''))) / 60;
  } else {
    result = parseFloat(value);
  }

  return Number.isNaN(result) ? 0 : result;
};

export const createPayrollLocationExportSummary = (rows, timeFormatType, payoutSettingType) => {
  if (!rows[0] || !timeFormatType) return [];

  const summary =
    rows[0] && payoutSettingType === 'shifts' ? getExportPayrollScheduleSummary() : getExportPayrollAttendanceSummary();

  rows.forEach(row => {
    row.forEach((item, itemIndex) => {
      const type = summary[itemIndex] ? summary[itemIndex].type : 'empty';
      if (['hours', 'number'].includes(type)) {
        if (type === 'hours') summary[itemIndex].value += formattedHourIntoDecimal(item, timeFormatType);
        if (type === 'number') summary[itemIndex].value += item;
      }
    });
  });

  summary.forEach(item => {
    if (item.type === 'hours')
      item.value = parseMinutesToFormat(item.value * 60, timeFormatType === 'hours' ? 'hours' : 'decimal', true);
  });

  return summary.map(i => i.value);
};

export const payrollLocationRowObjectToArrayBrief = (row, columns, availabilitiesTypes, timeFormatType, round) => {
  const { employee, relevantRowInfo } = row;
  const {
    details,
    sumHours,
    sumNightHours,
    sumBonuses,
    sumPayout,
    sumHoursReal,
    sumHoursPlanned,
    sumOvertime50,
    sumOvertime100,
    sumAvailabilities,
    sumAbsences,
  } = relevantRowInfo;
  let jobTitles = new Set();
  let wages = [];
  details.forEach(detail => {
    let { jobTitle } = detail;
    if (jobTitle === 'Brak przypisanej zmiany') jobTitle = '?';
    if (!jobTitles.has(jobTitle)) {
      let { wage } = detail;
      if (wage === 'Brak danych') wage = '?';
      wages.push(wage);
    }
    jobTitles.add(jobTitle);
  });
  jobTitles = Array.from(jobTitles).join(' / ');
  wages = wages.join(' / ');
  const scheduleCycleOvertime = employee.overtimeStats.overtime
    ? employee.overtimeStats.overtime.scheduleCycleOvertime
    : 0;

  const rowValues = [
    getEmployeeNameShort(employee, 20),
    jobTitles,
    wages,
    sumHours,
    sumAbsences ? parseMinutesToFormat(sumAbsences, timeFormatType, timeFormatType === 'decimal', round) : '-',
    parseMinutesToFormat(sumHours, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(sumNightHours, timeFormatType, timeFormatType === 'decimal', round),
    sumBonuses,
    `${parseMinutesToFormat(sumHoursReal, timeFormatType)} / ${parseMinutesToFormat(sumHoursPlanned, timeFormatType)}`,
    parseMinutesToFormat(sumHoursReal - sumHoursPlanned, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(sumOvertime50, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(sumOvertime100, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(scheduleCycleOvertime, timeFormatType, timeFormatType === 'decimal', round),
    sumPayout,
    '',
    '',
    '',
    employee.reference_id,
    sumAvailabilities,
  ];
  return [
    ...rowValues.filter((c, i) => columns.includes(i)),
    ...availabilitiesTypes.map(type => sumAvailabilities[type]),
  ];
};

export const getMonthlyNormsForExport = (mainDateStore, monthlyNorms, employeeWeeklyHoursNorm) => {
  const { dateArray, dateMode } = mainDateStore;
  if (dateMode === 'month') {
    const selectedDate = new Date(dateArray[0]);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth() + 1;
    const standardNorm = monthlyNorms.find(
      period => period.start_year === selectedYear && period.start_month === selectedMonth,
    )?.totalHours;

    const monthlyNormHours = (standardNorm * employeeWeeklyHoursNorm) / 40;
    const monthlyNormMinutes = monthlyNormHours * 60;

    return monthlyNormMinutes;
  }

  return null;
};

export const getPayrollLocationBasicXlsRow = (
  row,
  columns,
  availabilitiesTypes,
  timeFormatType,
  round,
  mainDateStore,
  monthlyNorms,
) => {
  const { employee, relevantRowInfo } = row;
  const {
    details,
    sumHours,
    sumNightHours,
    sumBonuses,
    sumPayout,
    sumHoursReal,
    sumHoursPlanned,
    sumOvertime50,
    sumOvertime100,
    sumAvailabilities,
    sumAbsences,
  } = relevantRowInfo;
  let jobTitles = new Set();
  let wages = [];
  details.forEach(detail => {
    const { jobTitle } = detail;

    if (!jobTitles.has(jobTitle)) {
      const { wage } = detail;

      wages.push(wage);
    }
    jobTitles.add(jobTitle);
  });
  jobTitles = Array.from(jobTitles).join(' / ');
  wages = wages.join(' / ');
  const scheduleCycleOvertime = employee.overtimeStats.overtime
    ? employee.overtimeStats.overtime.scheduleCycleOvertime
    : 0;

  const employeeWeeklyHoursNorm = employee.employment_conditions.weekly_working_minutes / 60;
  const monthlyNorm = getMonthlyNormsForExport(mainDateStore, monthlyNorms, employeeWeeklyHoursNorm);
  const rowValues = [
    employee.first_name,
    employee.last_name,
    jobTitles,
    wages,
    sumHours,
    sumAbsences ? parseMinutesToFormat(sumAbsences, timeFormatType, timeFormatType === 'decimal', round) : '-',
    parseMinutesToFormat(sumHours, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(sumNightHours, timeFormatType, timeFormatType === 'decimal', round),
    sumBonuses,
    monthlyNorm ? parseMinutesToFormat(monthlyNorm, timeFormatType, timeFormatType === 'decimal', round) : '-',
    parseMinutesToFormat(sumHoursReal, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(sumHoursPlanned, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(sumHoursReal - sumHoursPlanned, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(sumOvertime50, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(sumOvertime100, timeFormatType, timeFormatType === 'decimal', round),
    parseMinutesToFormat(scheduleCycleOvertime, timeFormatType, timeFormatType === 'decimal', round),
    sumPayout,
    '',
    '',
    '',
    employee.reference_id,
    sumAvailabilities,
  ];
  return [
    ...rowValues.filter((c, i) => columns.includes(i)),
    ...availabilitiesTypes.map(type => sumAvailabilities[type]),
  ];
};

/**
 * Apparently during exports some charactes might cause serious issues, just break generating CSV files.
 * Still characters are important for customes so problematic ones have to be replaced with similar looking ones.
 * Currently: # => $. Add more steps if needed.
 * @param {string} string - to be cleaned
 *
 * @return {string} strign with replaced chars
 */
export const replaceForbiddenCharsForCSV = string => {
  if (!string) return;
  const replaceHash = string.replace(/#/g, '$');
  const replaceComma = replaceHash.replace(/,/g, '');
  return replaceComma;
};

export const generateTermAndWageString = term => {
  const wage = term.has_individual_hourly_wage ? term.individual_hourly_wage : term.job_title.hourly_wage;
  const wageWithDecimal = (wage / 100).toFixed(2);
  return `${replaceForbiddenCharsForCSV(term.job_title.title)} ${wageWithDecimal}`;
};

export const getPreselected = (format, type, defaults) => {
  const saved = getCookie(`kadroExportPreselected-${format}-${type}`);
  if (saved) {
    return JSON.parse(saved);
  }

  return defaults;
};

export const filterOnlyActiveTypes = types => types.filter(type => !type.deleted_at_timestamp);

export const getLocationForExportMetaData = (scheduleLocationFilter, userLocations) => {
  const emptyResult = { location: '', locationObject: {} };
  if (!scheduleLocationFilter.length) return emptyResult;
  const selectedLocationId = scheduleLocationFilter[0];
  const selectedLocation = userLocations.find(location => location.id === selectedLocationId);
  if (!selectedLocation) return emptyResult;
  return { location: selectedLocation.name, locationObject: selectedLocation };
};
