import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { FREEMIUM_EVENT_WORKING_RULES } from '@/constants/intercom';
import { FREEMIUM_HIDE_WORKING_RULES, WORKING_RULES_HIDE } from '@/constants/Restrictions.js';

import './WorkingRuleButton.scss';

const WorkingRuleButton = props => {
  const tooltipMessage = props.workingRulesDisabledForFreemium ? (
    <FormattedMessage id="workingRuleButton.onlyPaidVersion" defaultMessage="Opcja dostępna w wersji płatnej" />
  ) : null;

  return (
    <FeatureWrapper restrcition={WORKING_RULES_HIDE} hide={!props.isSchedule}>
      <div className="k-workingRuleButton">
        <FreemiumWrapper
          freemiumRestrictions={[FREEMIUM_HIDE_WORKING_RULES]}
          intercomEvent={FREEMIUM_EVENT_WORKING_RULES}
          modifiers={['withFullOpacity', 'enablePointer']}
        >
          <Tooltip content={tooltipMessage} yOffset={10}>
            <Button
              modifiers="blue small"
              disabled={!props.isVerifyWorkingRulesButtonActive || props.workingRulesDisabledForFreemium}
              onClick={() => props.getWorkingRulesViolationsForCurrentDateArrayWithToastr()}
            >
              <Icon type="material" name="verified" />
              <FormattedMessage id="checkWorkingRules" defaultMessage="Sprawdź kodeks" />
              {props.workingRulesDisabledForFreemium && <FreemiumStar />}
            </Button>
          </Tooltip>
        </FreemiumWrapper>
      </div>
    </FeatureWrapper>
  );
};

WorkingRuleButton.propTypes = {
  getWorkingRulesViolationsForCurrentDateArrayWithToastr: PropTypes.func,
  isSchedule: PropTypes.bool,
  isVerifyWorkingRulesButtonActive: PropTypes.bool,
};

export default WorkingRuleButton;
