import { CellContext } from '@tanstack/react-table';
import { useIntl } from 'react-intl';

import MDAlert from '@/components/common/Basic/MDAlert/MDAlert';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { messages } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTable.messages';

import { PayrollLocationRow } from '../../PayrollLocationTable.types';

type Props = CellContext<TableRow<PayrollLocationRow>, unknown>;

export const PayrollLocationAlertCell = (props: Props) => {
  const intl = useIntl();
  const { warnings } = props.row.original;
  const sumOfWarnings = Object.values(warnings).reduce((sum, value) => sum + value, 0);

  if (sumOfWarnings <= 0) return null;

  return (
    <div>
      <MDAlert
        icon="warning"
        iconType="outlined"
        label={intl.formatMessage(messages.payrollWarnings)}
        numberOfAlerts={sumOfWarnings}
      />
    </div>
  );
};
