import { useChangeDateByArrow } from '@/hooks';

import { Modals } from './Modals/Modals';
import { AttendanceManyDaysTable } from './Table/AttendanceManyDaysTable';

export const AttendanceManyDaysView = () => {
  useChangeDateByArrow();

  return (
    <>
      <Modals />
      <AttendanceManyDaysTable />
    </>
  );
};
