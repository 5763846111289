import { defineMessages, IntlShape } from 'react-intl';

import { SCSS_COLORS } from '@/constants/colors';
import { Attendance } from '@/redux-store/attendances/attendancesData';
import { AttendanceStatus } from '@/types';
import { Timestamp } from '@/types/dates.types';
import { Shift } from '@/types/shifts.types';

import { getNowTimestamp } from '../dateHelper';

export const ATTENDANCE_TOOLTIP_OFFSET_Y = 10;

const messages = defineMessages({
  attendanceWithoutMatchingShift: {
    id: 'attendanceTimeSpan.attendanceWithoutMatchingShift',
    defaultMessage: 'Obecność nieplanowana',
  },
  noAttendance: {
    id: 'attendanceTimeSpan.noAttendance',
    defaultMessage: 'Nieobecność',
  },
  earlyIn: {
    id: 'attendanceTimeSpan.earlyIn',
    defaultMessage: 'Nadgodziny niezaakceptowane',
  },
  earlyInAccepted: {
    id: 'attendanceTimeSpan.earlyInAccepted',
    defaultMessage: 'Nadgodziny zaakceptowane',
  },
  attendance: {
    id: 'attendanceTimeSpan.attendance',
    defaultMessage: 'Obecność',
  },
  lateStart: {
    id: 'attendanceTimeSpan.lateStart',
    defaultMessage: 'Spóźnienie',
  },
  lateQuit: {
    id: 'attendanceTimeSpan.lateQuit',
    defaultMessage: 'Nadgodziny niezaakceptowane',
  },
  lateQuitAccepted: {
    id: 'attendanceTimeSpan.lateQuit',
    defaultMessage: 'Nadgodziny zaakceptowane',
  },
  earlyQuit: {
    id: 'attendanceTimeSpan.earlyQuit',
    defaultMessage: 'Wczesne wyjście',
  },
  onBreakStatus: {
    id: 'attendanceTimeSpan.onBreakStatus',
    defaultMessage: 'Na przerwie',
  },
  lateStatus: {
    id: 'attendanceTimeSpan.lateStatus',
    defaultMessage: 'Spóźniony',
  },
  presentStatus: {
    id: 'attendanceTimeSpan.presentStatus',
    defaultMessage: 'Obecny',
  },
  notPresentStatus: {
    id: 'attendanceTimeSpan.notPresentStatus',
    defaultMessage: 'Nieobecny',
  },
  notPlannedAttendanceStatus: {
    id: 'attendanceTimeSpan.notPlannedAttendanceStatus',
    defaultMessage: 'Obecność nieplanowana',
  },
});

export const getNoAttendanceTooltip = ({ formatMessage }: IntlShape) => formatMessage(messages.noAttendance);

export const getAttendanceWithoutMatchingShiftTooltip = ({ formatMessage }: IntlShape) =>
  formatMessage(messages.attendanceWithoutMatchingShift);

export const getStartAttendanceTooltip = (
  { formatMessage }: IntlShape,
  attendanceTimestamp: Timestamp,
  shiftTime: Nullable<string>,
  earlyInAccepted = false,
) => {
  if (!shiftTime) return formatMessage(messages.attendanceWithoutMatchingShift);
  if (earlyInAccepted) return formatMessage(messages.earlyInAccepted);
  if (shiftTime < attendanceTimestamp) return formatMessage(messages.lateStart);
  if (shiftTime > attendanceTimestamp) return formatMessage(messages.earlyIn);
  return formatMessage(messages.attendance);
};

export const getEndAttendanceTooltip = (
  { formatMessage }: IntlShape,
  attendanceTimestamp: Timestamp,
  shiftTime: Nullable<string>,
  lateOut = false,
) => {
  if (!shiftTime) return formatMessage(messages.attendanceWithoutMatchingShift);
  if (lateOut) return formatMessage(messages.lateQuitAccepted);
  if (shiftTime > attendanceTimestamp) return formatMessage(messages.earlyQuit);
  if (shiftTime < attendanceTimestamp) return formatMessage(messages.lateQuit);
  return formatMessage(messages.attendance);
};

const checkHasNowBreak = (attendance: Nullable<Partial<Attendance>>) => {
  if (!attendance) return false;
  const now = getNowTimestamp();
  return (attendance.breaks || []).some(b => !b.end_timestamp || (b.start_timestamp < now && b.end_timestamp > now));
};

const checkIsLateStartAttendance = (attendance: Nullable<Partial<Attendance>>) => {
  if (!attendance) return false;
  const now = getNowTimestamp();
  return (
    attendance.matching_shift &&
    attendance.start_timestamp > attendance.matching_shift.start_timestamp &&
    (!attendance.end_timestamp || (attendance.start_timestamp < now && attendance.end_timestamp > now))
  );
};

const checkIsPresent = (attendance: Nullable<Partial<Attendance>>) => {
  if (!attendance) return false;
  const now = getNowTimestamp();
  return (
    attendance.matching_shift &&
    (!attendance.end_timestamp || (attendance.start_timestamp < now && attendance.end_timestamp > now))
  );
};

const checkIsPresentWithoutShift = (attendance: Nullable<Partial<Attendance>>) => {
  if (!attendance) return false;
  const now = getNowTimestamp();
  return (
    !attendance.matching_shift &&
    (!attendance.end_timestamp || (attendance.start_timestamp < now && attendance.end_timestamp > now))
  );
};

const checkIsNotPresent = (shift: Nullable<Shift>) => {
  if (!shift) return false;
  const now = getNowTimestamp();
  return shift.start_timestamp <= now && shift.end_timestamp >= now;
};

export const getAttendanceStatus = (attendance: Nullable<Partial<Attendance>>, shift?: Nullable<Shift>) => {
  if (checkHasNowBreak(attendance)) return AttendanceStatus.ON_BREAK;
  if (checkIsLateStartAttendance(attendance)) return AttendanceStatus.LATE_START;
  if (checkIsPresent(attendance)) return AttendanceStatus.PRESENT;
  if (checkIsPresentWithoutShift(attendance)) return AttendanceStatus.ATTENDANCE_WITHOUT_MATCHING_SHIFT;
  if (!attendance && checkIsNotPresent(shift)) return AttendanceStatus.NOT_PRESENT;
  return '';
};

type AttendanceStatusOption = {
  color: string;
  text: string;
};

export const getAttendanceStatuses = (intl?: IntlShape): Record<AttendanceStatus, AttendanceStatusOption> => ({
  [AttendanceStatus.ATTENDANCE_WITHOUT_MATCHING_SHIFT]: {
    color: SCSS_COLORS.orange_500,
    text: intl ? intl.formatMessage(messages.notPlannedAttendanceStatus) : '',
  },
  [AttendanceStatus.LATE_START]: {
    color: SCSS_COLORS.yellow_500,
    text: intl ? intl.formatMessage(messages.lateStatus) : '',
  },
  [AttendanceStatus.NOT_PRESENT]: {
    color: SCSS_COLORS.red_500,
    text: intl ? intl.formatMessage(messages.notPresentStatus) : '',
  },
  [AttendanceStatus.ON_BREAK]: {
    color: SCSS_COLORS.gray_500,
    text: intl ? intl.formatMessage(messages.onBreakStatus) : '',
  },
  [AttendanceStatus.PRESENT]: {
    color: SCSS_COLORS.lime_green_500,
    text: intl ? intl.formatMessage(messages.presentStatus) : '',
  },
});
