import { RowSelectionState, Table } from '@tanstack/react-table';
import React from 'react';

import { MDTableProps, TableRow } from '../MDTable.types';

import './TableTopContent.scss';

type Props<T extends object> = Pick<MDTableProps<T>, 'tableOptions' | 'withSearch'> & {
  rowSelection: RowSelectionState;
  table: Table<TableRow<T>>;
  toggleFilterRow: () => void;
  visibleRows: T[];
};

export const TableTopContent = <T extends object>({
  rowSelection,
  table,
  tableOptions,
  toggleFilterRow,
  visibleRows,
  withSearch = true,
}: Props<T>) => {
  if (!tableOptions && !withSearch) return null;

  return (
    <div className="mdTableTopContent__settings">
      <div className="mdTableTopContent__options">
        {tableOptions &&
          React.cloneElement(tableOptions, {
            rowSelection,
            table,
            toggleFilterRow,
            visibleRows,
          })}
      </div>
      {withSearch && (
        <span
          role="button"
          tabIndex={0}
          onClick={toggleFilterRow}
          className="mdTableTopContent__filterIcon material-icons"
        >
          search
        </span>
      )}
    </div>
  );
};
