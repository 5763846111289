import { CellContext } from '@tanstack/react-table';
import { useIntl } from 'react-intl';

import MDChip from '@/components/common/MDChip/MDChip';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { statusMessages } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTable.messages';

import { PayrollLocationRow } from '../../PayrollLocationTable.types';

type Props = CellContext<TableRow<PayrollLocationRow>, unknown>;

const statusColors = {
  approved: 'green',
  unapproved: 'red',
  pending: 'yellow',
};

export const PayrollLocationStatusCell = (props: Props) => {
  const intl = useIntl();
  const { status } = props.row.original;
  const label = intl.formatMessage(statusMessages[status]);

  return <MDChip modifiers={[statusColors[status]]}>{label}</MDChip>;
};
