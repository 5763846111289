import { IntlShape } from 'react-intl';

import MDChip from '@/components/common/MDChip/MDChip.jsx';
import { TableColumnType } from '@/components/common/MDTable/MDTable.types';
import { getAbsenceStatusName, getColorFromAbsenceStatus } from '@/utils/absenceHelpers.js';

import { AbsenceRow } from './Absence.types';
import { messages } from './messages.js';

export const getColumns = (
  isEmployee: boolean,
  intl: IntlShape,
  onFilter: (name: string, value: string) => void,
): TableColumnType<AbsenceRow>[] => {
  const columns: TableColumnType<AbsenceRow>[] = [
    {
      header: intl.formatMessage(messages.absencesViewTableNr),
      accessorKey: 'request_number',
      size: 100,
      customSize: true,
    },
    !isEmployee && {
      header: intl.formatMessage(messages.absencesViewTableEmployeeName),
      accessorKey: 'employee',
    },
    {
      header: intl.formatMessage(messages.absencesViewTableTypeName),
      accessorKey: 'absence_type_name',
    },
    {
      header: intl.formatMessage(messages.absencesViewTableFrom),
      accessorKey: 'from',
    },
    {
      header: intl.formatMessage(messages.absencesViewTableTo),
      accessorKey: 'to',
    },
    {
      header: intl.formatMessage(messages.absencesViewTableRecentApprovedBy),
      accessorKey: 'recent_status_change_by',
    },
    {
      header: intl.formatMessage(messages.absencesViewTableStatusName),
      accessorKey: 'statusChip',
      withoutVerticalPadding: true,
      cell: props => {
        const { status } = props.cell.row.original;
        return <MDChip modifiers={[getColorFromAbsenceStatus(status)]}>{getAbsenceStatusName(status, intl)}</MDChip>;
      },
    },
  ];
  return columns.filter(Boolean).map(column => ({
    ...column,
    filterFn: (_row, columnId, filterValue) => {
      onFilter(columnId, filterValue);
      return true;
    },
  }));
};
