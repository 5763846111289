import moment from 'moment-timezone';
import { defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import { trackIntercomEvent } from '@/actions/intercom';
import {
  ACTIVATE_VERIFY_WORKING_RULES_BUTTON,
  CLEAR_WORKING_RULES,
  CLEAR_WORKING_RULES_FOR_EMPLOYEE_SUCCESS,
  DEACTIVATE_VERIFY_WORKING_RULES_BUTTON,
  GET_WORKING_RULES_VIOLATIONS,
  GET_WORKING_RULES_VIOLATIONS_FAILURE,
  GET_WORKING_RULES_VIOLATIONS_FOR_EMPLOYEE_SUCCESS,
  GET_WORKING_RULES_VIOLATIONS_SUCCESS,
  UPDATE_WORKING_RULES_VIOLATIONS_SUCCESS,
} from '@/constants/ActionTypes.js';
import { SCHEDULE_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes';
import {
  filterActiveEmployeesByLocations,
  filterEmployeesForValidatingWorkingRules,
} from '@/utils/userEmployeesHelpers';

import { conn } from './index';

const messages = defineMessages({
  successToast: {
    id: 'schedule.verify.success',
    defaultMessage: 'Grafik został zweryfikowany z kodeksem pracy',
  },
  failToast: {
    id: 'schedule.verify.fail',
    defaultMessage: 'Wystąpił błąd podczas weryfikacji grafiku',
  },
});

export const getWorkingRulesViolations = (from, to) => ({
  type: GET_WORKING_RULES_VIOLATIONS,
  payload: {
    from,
    to,
  },
});

const getWorkingRulesViolationsSuccess = data => ({
  type: GET_WORKING_RULES_VIOLATIONS_SUCCESS,
  payload: {
    employeeViolations: data,
  },
});

const updateWorkingRulesViolationsSuccess = (data, userEmployeesIds) => (dispatch, getState) => {
  const { employeeViolations } = getState().reducer.workingRules;
  const filteredEmployeeViolations = employeeViolations.filter(
    ({ employee_id: employeeId }) => !userEmployeesIds.includes(employeeId),
  );
  dispatch({
    type: UPDATE_WORKING_RULES_VIOLATIONS_SUCCESS,
    payload: { employeeViolations: [...filteredEmployeeViolations, ...data] },
  });
};

const getWorkingRulesViolationsForEmployeeSuccess = (data, employeeId) => ({
  type: GET_WORKING_RULES_VIOLATIONS_FOR_EMPLOYEE_SUCCESS,
  payload: {
    employeeViolations: data,
    employeeId,
  },
});

const getWorkingRulesViolationsFailure = reason => {
  console.error('[workingRules] Failed to getWorkingRulesViolations reason => ', reason);
  return {
    type: GET_WORKING_RULES_VIOLATIONS_FAILURE,
    notification: {
      title: 'Ups!',
      description: 'Nie udało się pobrać kodeksu',
      type: 'error',
    },
  };
};

export const activateVerifyWorkingRulesButton = () => ({
  type: ACTIVATE_VERIFY_WORKING_RULES_BUTTON,
});

export const deactivateVerifyWorkingRulesButton = () => ({
  type: DEACTIVATE_VERIFY_WORKING_RULES_BUTTON,
});

export const clearWorkingRules = () => ({
  type: CLEAR_WORKING_RULES,
});

export const getWorkingRulesViolationsForEmployees =
  (userEmployeesIds, from, to, validateSingleEmployee = false, options = { mergeWorkingRules: false }) =>
  dispatch => {
    if (!from || !to) {
      dispatch(getWorkingRulesViolationsFailure('missing from or to parameters'));
      return [];
    }
    if (!userEmployeesIds.length) {
      dispatch(getWorkingRulesViolationsSuccess([]));
      return Promise.resolve([]);
    }

    // autoscheduler needs timestamps in UTC timezone
    const startTimestamp = moment.tz(from, 'UTC').startOf('day').format();
    const endTimestamp = moment.tz(to, 'UTC').endOf('day').format();

    return conn
      .getWorkingRulesViolations(userEmployeesIds, startTimestamp, endTimestamp)
      .then(response => {
        const alerts = response.data;

        if (userEmployeesIds.length === 1 && validateSingleEmployee) {
          dispatch(getWorkingRulesViolationsForEmployeeSuccess(alerts, userEmployeesIds[0]));
        } else if (options.mergeWorkingRules) {
          dispatch(updateWorkingRulesViolationsSuccess(alerts, userEmployeesIds));
        } else {
          dispatch(getWorkingRulesViolationsSuccess(alerts));
        }
        dispatch(deactivateVerifyWorkingRulesButton());
        return alerts;
      })
      .catch(err => {
        dispatch(getWorkingRulesViolationsFailure(`network promise failed: ${err}`));
      });
  };

// TODO: rewrite it!
export const getWorkingRulesViolationsForCurrentDateArray =
  (employeeIds = null, alwaysValidate = false, options = { mergeWorkingRules: false }) =>
  (dispatch, getState) => {
    const { mainDateStore, userEmployees, scheduleLocationFilter, scheduleUIState } = getState().reducer;
    const locationIds = scheduleLocationFilter;
    const workingRules = scheduleUIState.settings.find(({ type }) => type === 'workingRules');
    if (
      (!workingRules.value && !alwaysValidate) ||
      SCHEDULE_DISPLAY_MODE_TYPE !== scheduleUIState.selectedDisplayMode.type
    )
      return Promise.resolve([]);
    const startDate = mainDateStore.dateArray[0];
    const endDate = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
    let userEmployeesIds = employeeIds;
    if (!employeeIds) {
      const employeesForCurrentLocation = filterActiveEmployeesByLocations(userEmployees, locationIds);
      const employeesToValidate = filterEmployeesForValidatingWorkingRules(
        employeesForCurrentLocation,
        startDate,
        endDate,
        { validateAllLocations: true },
      );
      if (!employeesToValidate) return Promise.resolve([]);
      userEmployeesIds = employeesToValidate.map(employee => employee.id);
    }

    return dispatch(getWorkingRulesViolationsForEmployees(userEmployeesIds, startDate, endDate, false, options));
  };

export const getWorkingRulesViolationsForCurrentDateArrayWithToastr =
  (employeeIds = null) =>
  async (dispatch, getState, intl) => {
    dispatch(trackIntercomEvent(GET_WORKING_RULES_VIOLATIONS));
    try {
      await dispatch(getWorkingRulesViolationsForCurrentDateArray(employeeIds));
      toastr.success(intl.formatMessage(messages.successToast));
    } catch {
      toastr.error(intl.formatMessage(messages.failToast));
    }
  };

export const clearWorkingRulesForEmployee = employeeId => ({
  type: CLEAR_WORKING_RULES_FOR_EMPLOYEE_SUCCESS,
  payload: employeeId,
});
