import { ExpandedState } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ATTENDANCES_FROM_OTHER_LOCATIONS_GET } from '@/constants/Permissions';
import { useMediaQuery } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectAbsencesSchedule } from '@/redux-store/absences';
import { selectAttendancesData } from '@/redux-store/attendances/attendancesData';
import {
  selectAttendancesNotExpandedDates,
  selectAttendancesVisibleColumns,
  setAttendanceExpandedRows,
} from '@/redux-store/attendances/uiState';
import { selectContracts } from '@/redux-store/contracts';
import { selectEmployeesNamesData } from '@/redux-store/employeesNames';
import { selectSelectedEmploymentConditionsFilter } from '@/redux-store/employmentConditionsFilter/employmentConditionsFilter.selectors';
import { selectSelectedJobTitlesIds } from '@/redux-store/jobTitleFilter';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { selectCompanyRoles } from '@/redux-store/roles/companyRoles';
import { selectScheduleLoanedEmployees } from '@/redux-store/scheduleLoanedEmployees';
import { selectScheduleLocationFilter } from '@/redux-store/scheduleLocationFilter';
import { selectAttendanceSettings } from '@/redux-store/settings/attendancesSettings';
import { selectUserEmployees } from '@/redux-store/userEmployees';
import { selectUserLocations } from '@/redux-store/userLocations';
import { selectPermissionsDict } from '@/redux-store/userPermissions';
import { selectCurrentUser } from '@/selectors/currentUser.selectors';
import { getLocationsFromEmployeesWithCommonLocations } from '@/utils/locations/locations.utils';
import { checkEmployeeIsManager } from '@/utils/userEmployeesHelpers';

import {
  createAttendancesTableData,
  getColumns,
  getExpandedRows,
  getExpandingForDates,
  getMobileDateColumn,
} from './AttendanceManyDaysTable.utils';

export const useAttendanceManyDaysTable = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const allColumns = useMemo(() => getColumns(intl), [intl]);
  const notExpandedDates = useAppSelector(selectAttendancesNotExpandedDates);
  const checkedColumns = useAppSelector(selectAttendancesVisibleColumns);
  const currentUser = useAppSelector(selectCurrentUser);
  const permissionsDict = useAppSelector(selectPermissionsDict);
  const companyRoles = useAppSelector(selectCompanyRoles);
  const userEmployees = useAppSelector(selectUserEmployees);
  const userLocations = useAppSelector(selectUserLocations);
  const scheduleLocationFilter = useAppSelector(selectScheduleLocationFilter);
  const dateArray = useAppSelector(selectDateArray);
  const contracts = useAppSelector(selectContracts);
  const selectedEmploymentConditionsFilter = useAppSelector(selectSelectedEmploymentConditionsFilter);
  const scheduleLoanedEmployees = useAppSelector(selectScheduleLoanedEmployees);
  const scheduleAbsences = useAppSelector(selectAbsencesSchedule);
  const attendancesSettings = useAppSelector(selectAttendanceSettings);
  const selectedJobTitlesIds = useAppSelector(selectSelectedJobTitlesIds);
  const employeesNamesData = useAppSelector(selectEmployeesNamesData);
  const attendancesData = useAppSelector(selectAttendancesData);
  const { isAtLeastTabletLandscape } = useMediaQuery();

  const columns = useMemo(() => {
    const cols = allColumns.filter(
      c => c.cannotBeHidden || checkedColumns[c.accessorKey] || (c.showOnlyForMobile && !isAtLeastTabletLandscape),
    );
    return isAtLeastTabletLandscape ? cols : [getMobileDateColumn(intl), ...cols];
  }, [checkedColumns, allColumns, isAtLeastTabletLandscape, intl]);

  const hasManagerAccessToOtherLocations = useMemo(() => {
    const isManager = checkEmployeeIsManager(currentUser, companyRoles);
    return permissionsDict[ATTENDANCES_FROM_OTHER_LOCATIONS_GET] && isManager;
  }, [currentUser, companyRoles, permissionsDict]);

  const locations = useMemo(
    () =>
      hasManagerAccessToOtherLocations
        ? getLocationsFromEmployeesWithCommonLocations(userEmployees, userLocations)
        : userLocations.filter(loc => scheduleLocationFilter.includes(loc.id)),
    [hasManagerAccessToOtherLocations, userEmployees, userLocations, scheduleLocationFilter],
  );

  const data = useMemo(
    () =>
      createAttendancesTableData(
        locations,
        dateArray,
        userEmployees,
        selectedJobTitlesIds,
        selectedEmploymentConditionsFilter,
        scheduleAbsences,
        attendancesSettings,
        userLocations,
        hasManagerAccessToOtherLocations,
        contracts,
        attendancesData,
        employeesNamesData,
        scheduleLoanedEmployees,
        intl,
      ),
    [
      locations,
      dateArray,
      userEmployees,
      selectedJobTitlesIds,
      selectedEmploymentConditionsFilter,
      scheduleAbsences,
      attendancesSettings,
      userLocations,
      hasManagerAccessToOtherLocations,
      contracts,
      attendancesData,
      employeesNamesData,
      scheduleLoanedEmployees,
      intl,
    ],
  );

  const dataDates = useMemo(() => data.map(e => e.date), [data]);

  const expandedRows = useMemo(() => getExpandedRows(dataDates, notExpandedDates), [dataDates, notExpandedDates]);

  const onExpand = useCallback(
    (expandedState: ExpandedState) => {
      dispatch(setAttendanceExpandedRows(getExpandingForDates(dataDates, expandedState)));
    },
    [dispatch, dataDates],
  );

  return { data, columns, expandedRows, onExpand };
};
