import { memo } from 'react';

import PhotoPreviewModal from '@/components/common/PhotoPreviewModal/PhotoPreviewModal.redux';
import AddShiftModalContainer from '@/components/scheduler/modals/AddShiftModal/AddShiftModal.redux.js';
import EditAttendanceModalContainer from '@/containers/attendance/EditAttendanceModalContainer.js';
import ObjectHistoryModalContainer from '@/containers/common/ObjectHistoryModalContainer.js';

import AcceptOvertimeModal from '../../modals/AcceptOvertimeModal/AcceptOvertimeModal.redux.js';
import { AlignAttendancesMultipleDaysModal } from '../../modals/AlignAttendancesMultipleDaysModal';
import AttendanceMapModal from '../../modals/AttendanceMapModal/AttendanceMapModal.redux.jsx';
import CreateAttendanceModal from '../../modals/CreateAttendanceModal/CreateAttendanceModal.redux.js';

export const Modals = memo(() => (
  <>
    <EditAttendanceModalContainer />
    <ObjectHistoryModalContainer />
    <PhotoPreviewModal />
    <AlignAttendancesMultipleDaysModal />
    <AddShiftModalContainer />
    <AcceptOvertimeModal />
    <AttendanceMapModal />
    <CreateAttendanceModal showDatePicker showEmployeePicker />
  </>
));

Modals.displayName = 'Modals';
