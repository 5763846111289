import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import MDTable from '@/components/common/MDTable/MDTable';
import { tabsMessages } from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationTable/PayrollLocationTable.messages';
import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';

import PayrollButtonBar from './PayrollButtonBar/PayrollButtonBar.redux';
import { getColumns, getPayrollColumns, getTableData, tableStyles, TABS } from './PayrollTable.helpers';
import PayrollTableEmpty from './PayrollTableEmpty/PayrollTableEmpty';
import PayrollTableOptions from './PayrollTableOptions/PayrollTableOptions.redux';

import './PayrollTable.scss';

const PayrollTable = (props, { intl }) => {
  const {
    data,
    visibleColumns,
    timeFormatType,
    payoutSettingType,
    selectedEmployee,
    userPermissions,
    companySettings,
    getPayrollViewData,
    payrollApprovalEnabled,
    payrollLoanedEmployees,
  } = props;

  const tabs = useMemo(() => {
    if (!payrollApprovalEnabled) return [];

    return TABS.map(tab => {
      const numberOfItems =
        tab.id === 'all'
          ? data.length
          : data.filter(row => (row.isUnapproved ? tab.id === 'unapproved' : tab.id === 'approved')).length;

      return {
        ...tab,
        label: intl.formatMessage(tabsMessages[tab.id]),
        onSelect: () => {
          const allSelected = tab.id === 'all';
          setActiveTab(tab.id);
          getPayrollViewData(undefined, undefined, { mergeWithOldData: true }, 'standard', allSelected ? null : tab.id);
        },
        numberOfItems,
      };
    });
  }, [intl, data, payrollApprovalEnabled]);

  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const allColumns = useMemo(
    () => getPayrollColumns(userPermissions, payoutSettingType, companySettings),
    [userPermissions, payoutSettingType, companySettings],
  );
  const columns = useMemo(() => getColumns(allColumns, visibleColumns, intl), [allColumns, visibleColumns, intl]);

  const tableData = useMemo(() => {
    const filteredData =
      activeTab === 'all' || tabs.length === 0
        ? data
        : data.filter(item => (item.isUnapproved ? activeTab === 'unapproved' : activeTab === 'approved'));
    return getTableData(filteredData, selectedEmployee, timeFormatType, intl, payrollLoanedEmployees);
  }, [data, selectedEmployee, timeFormatType, activeTab, payrollLoanedEmployees, intl, tabs]);

  const onCheckboxToggle = useCallback((row, rows) => {
    const clickedDate = row.original.date;
    const alreadyChecked = row.getIsSelected();
    const rowsWithTheSameDate = rows.filter(r => r.original.date === clickedDate);
    rowsWithTheSameDate.forEach(r => r.toggleSelected(!alreadyChecked));
  }, []);

  const disableSelectAllCheckbox = useMemo(
    () => tableData.every(row => row.date < PAYROLL_APPROVAL_START_DATE),
    [tableData],
  );

  return (
    <MDTable
      data={tableData}
      columns={columns}
      withSearch={false}
      enableRowSelection={
        companySettings.payroll_approval_enabled
          ? row => !row.original.disabledCheckbox || disableSelectAllCheckbox
          : false
      }
      tableEmpty={<PayrollTableEmpty />}
      options={<PayrollTableOptions />}
      tableOptions={<PayrollButtonBar employeeId={selectedEmployee?.id} />}
      customOnSelectHandler={onCheckboxToggle}
      tabs={tabs}
      activeTab={activeTab}
      tableStyles={tableStyles}
    />
  );
};

PayrollTable.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  visibleColumns: PropTypes.arrayOf(PropTypes.shape({})),
  timeFormatType: PropTypes.string,
  payoutSettingType: PropTypes.string,
  selectedEmployee: PropTypes.string,
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  companySettings: PropTypes.shape({
    payroll_approval_enabled: PropTypes.bool,
  }),
  getPayrollViewData: PropTypes.func,
  payrollApprovalEnabled: PropTypes.bool,
  payrollLoanedEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      deleted_at: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      reference_id: PropTypes.string,
      isLoaned: PropTypes.bool,
      attendances: PropTypes.arrayOf(),
    }),
  ),
};

export default PayrollTable;
