import { memo } from 'react';

import { Location } from '@/types';

import { ScheduleTableRow } from '../../ScheduleTableRow/ScheduleTableRow';
import ScheduleTableSingleRow from '../../ScheduleTableSingleRow/ScheduleTableSingleRow.redux';
import { useRegularEmployees } from './useRegularEmployees';

type Props = {
  locationId: Location['id'];
};

export const RegularEmployees = memo(({ locationId }: Props) => {
  const { isSingleDay, regularEmployeeIds } = useRegularEmployees(locationId);

  return regularEmployeeIds.map(employeeId =>
    isSingleDay ? (
      <ScheduleTableSingleRow key={employeeId} employeeId={employeeId} locationId={locationId} />
    ) : (
      <ScheduleTableRow key={employeeId} employeeId={employeeId} locationId={locationId} />
    ),
  );
});

RegularEmployees.displayName = 'RegularEmployees';
