import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@/redux-store';
import { setAttendanceVisibleColumn } from '@/redux-store/attendances/uiState';
import { selectAttendancesVisibleColumns } from '@/redux-store/attendances/uiState/attendancesUiState.selectors';
import { transformToBoolDict } from '@/utils/objectHelpers/objectHelpers';

import { AttendanceManyDaysTableColumn } from '../../AttendanceManyDaysTable.types';
import { getColumnsForFilter } from './AttendanceManyDaysColumnFilter.utils';

export const useAttendanceManyDaysColumnFilter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const checkedColumns = useAppSelector(selectAttendancesVisibleColumns);
  const columns = useMemo(() => getColumnsForFilter(intl), [intl]);
  const selectedColumns = useMemo(() => columns.filter(c => checkedColumns[c.accessorKey]), [checkedColumns, columns]);

  const onChange = useCallback(
    (data: AttendanceManyDaysTableColumn[]) => {
      const newVisibleColumns = transformToBoolDict(data.map(column => column.accessorKey));
      dispatch(setAttendanceVisibleColumn(newVisibleColumns));
    },
    [dispatch],
  );

  return { columns, onChange, selectedColumns };
};
