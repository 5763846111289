import moment from 'moment';
import { IntlShape } from 'react-intl';

import { Contract } from '@/types/contracts.types';
import { getRelevantContractForDate } from '@/utils/contracts.ts';

import { messages } from './EmployeeModalContracts.messages';

export const getContractLabel = (
  {
    start_date: startDate,
    end_date: endDate,
    isTemp,
    showNoEndDate = false,
  }: Partial<Contract> & { showNoEndDate?: boolean },
  intl: IntlShape,
) => {
  if (!startDate && isTemp) {
    return intl.formatMessage(messages.addNewContract);
  }

  if (!startDate && !endDate) {
    return intl.formatMessage(messages.noDates);
  }

  if (!startDate) {
    return `${intl.formatMessage(messages.to)} ${moment(endDate).format('DD.MM.YYYY')}`;
  }

  if (!endDate && showNoEndDate) {
    return `${moment(startDate).format('DD.MM.YYYY')} - ${intl.formatMessage(messages.noEndDate)}`;
  }

  if (!endDate) {
    return `${intl.formatMessage(messages.from)} ${moment(startDate).format('DD.MM.YYYY')}`;
  }

  return `${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format('DD.MM.YYYY')}`;
};

export const getInitialContract = contracts => {
  const newContract = contracts.find(contract => !contract.start_date && contract.isTemp);
  if (newContract) {
    return newContract;
  }

  const relevantContractForToday = getRelevantContractForDate(contracts, moment().format('YYYY-MM-DD'));
  return relevantContractForToday || contracts[0];
};
