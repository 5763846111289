import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDRadios, MDTextArea, MDTimeRangeInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { ADD_EVENT_MODAL, EDIT_EVENT_MODAL } from '@/constants/modalTypes.js';
import { useInputChange } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers.js';

import RepeatShift from '../AddShiftAndAbsenceModal/RepeatShift/RepeatShift.jsx';
import {
  getEventObjectToSave,
  getEventTypesOptions,
  getFooterOptions,
  getInitialState,
  getMassEventObjectToSave,
  modifiers,
} from './EditEventModal.helpers.js';
import { messages } from './EditEventModal.messages.js';
import LocationSelect from './LocationSelect/LocationSelect.redux.js';

const EditEventModal = (
  {
    changeUserEvent,
    addUserEvent,
    hideModal,
    companyId,
    addMassUserEvent,
    modalObject = {},
    deleteUserEvent,
    scheduleLocationFilter,
    mainDateStore,
    getDaysFreeFromWork,
    nonWorkingDays,
    toggleNonWorkingDays,
    showModal: showModalFromProps,
  },
  { intl },
) => {
  const [state, handlers] = useInputChange(
    getInitialState(modalObject, showModalFromProps, scheduleLocationFilter, mainDateStore),
    intl,
  );
  const {
    displayRepeat,
    activeRepeatTab,
    selectedRange,
    selectedChoices,
    selectedWeekdays,
    perWhichDay,
    errors,
    scheduleEventType,
    workingHours,
    locationId,
    scheduleEventDescription,
    date,
    id,
  } = state;

  const eventTypesOptions = useMemo(() => getEventTypesOptions(intl), []);
  const editMode = showModalFromProps === EDIT_EVENT_MODAL;
  const showModal = useMemo(
    () => [EDIT_EVENT_MODAL, ADD_EVENT_MODAL].includes(showModalFromProps),
    [showModalFromProps],
  );
  const footerOptions = useMemo(() => getFooterOptions(modalObject, deleteUserEvent, intl), [modalObject]);

  useEffect(() => {
    if (modalObject) {
      handlers.updateState(getInitialState(modalObject, showModalFromProps, scheduleLocationFilter, mainDateStore));
    }
  }, [modalObject]);

  const validateAll = async () => {
    const inputs = ['locationId', 'workingHours', 'scheduleEventDescription'];
    const validationResult = await Promise.all(
      inputs.map(inputName => handlers.validateInput(createEvent(inputName, state[inputName]))),
    );
    return !Object.values(validationResult).some(err => err !== '');
  };

  const handleInputChange = useCallback(
    (name, value, valueToCompare) => handlers.changeInput(createEvent(name, value, { valueToCompare })),
    [state],
  );

  const onSubmit = async () => {
    const valid = await validateAll();
    if (!valid) return;
    const eventObject = getEventObjectToSave(companyId, {
      scheduleEventType,
      workingHours,
      locationId,
      scheduleEventDescription,
      date,
      id,
    });
    if (eventObject.id) {
      changeUserEvent(eventObject);
    } else if (!displayRepeat) {
      addUserEvent(eventObject);
    } else {
      const eventObjects = getMassEventObjectToSave(companyId, getDaysFreeFromWork, nonWorkingDays, {
        scheduleEventType,
        workingHours,
        locationId,
        scheduleEventDescription,
        selectedChoices,
        perWhichDay: Number(perWhichDay),
        selectedRange,
        selectedWeekdays,
        activeRepeatTab,
      });
      addMassUserEvent(eventObjects);
    }
    hideModal();
  };

  const selectEventType = useCallback(type => handlers.changeInput(createEvent('scheduleEventType', type)), []);
  const changeWorkingHours = useCallback(hours => handlers.changeInput(createEvent('workingHours', hours)), []);

  return (
    <MDKadroModal
      show={showModal}
      onHide={hideModal}
      modifiers={modifiers.modal}
      title={intl.formatMessage(messages[editMode ? 'editModalTitle' : 'addEventTitle'])}
      onSubmit={onSubmit}
      footerOptions={footerOptions}
    >
      <div>
        {intl.formatMessage(messages[editMode ? 'editEventDescription' : 'addEventDescription'])} <b>{date}</b>
      </div>
      {scheduleLocationFilter.length !== 1 && (
        <LocationSelect
          value={locationId}
          onChange={handlers.changeInput}
          withinFilters
          name="locationId"
          errorMessage={errors.locationId}
        />
      )}
      <MDRadios
        label={intl.formatMessage(messages.eventType)}
        elements={eventTypesOptions}
        selectedItemType={scheduleEventType}
        onChangeType={selectEventType}
        showLabelsColor
      />
      <MDTimeRangeInput
        label={intl.formatMessage(messages.eventHours)}
        value={workingHours}
        onChange={changeWorkingHours}
        id="workingHours"
        onBlur={handlers.validateInput}
        modifiers={modifiers.timeRangeInput}
        errorMessage={errors.workingHours}
      />
      <MDTextArea
        label={intl.formatMessage(messages.eventNote)}
        placeholder={intl.formatMessage(messages.notePlaceholder)}
        value={scheduleEventDescription}
        onChange={handlers.changeInput}
        name="scheduleEventDescription"
        onBlur={handlers.validateInput}
        errorMessage={errors.scheduleEventDescription}
      />
      {!editMode && (
        <RepeatShift
          {...{ displayRepeat, activeRepeatTab, selectedRange, selectedChoices, selectedWeekdays, perWhichDay }}
          handleInputChange={handleInputChange}
          nonWorkingDays={nonWorkingDays}
          toggleNonWorkingDays={toggleNonWorkingDays}
          repeatShiftMessage={<FormattedMessage id="event.repeatEvent" defaultMessage="Powtórz wydarzenie" />}
        />
      )}
    </MDKadroModal>
  );
};

EditEventModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EditEventModal.propTypes = {
  companyId: PropTypes.string,
  modalObject: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    hours: PropTypes.string,
    location: PropTypes.shape({}),
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  hideModal: PropTypes.func,
  changeUserEvent: PropTypes.func,
  addUserEvent: PropTypes.func,
  showModal: PropTypes.string,
  deleteUserEvent: PropTypes.func,
  toggleNonWorkingDays: PropTypes.func,
  nonWorkingDays: PropTypes.bool,
  addMassUserEvent: PropTypes.func,
  mainDateStore: PropTypes.shape({
    customDate: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }),
    dateArray: PropTypes.arrayOf(PropTypes.string),
    dateMode: PropTypes.string,
    dateShiftInt: PropTypes.number,
    headerDateArray: PropTypes.arrayOf(PropTypes.string),
    previousDateArray: PropTypes.arrayOf(PropTypes.string),
    templatesDateArray: PropTypes.arrayOf(PropTypes.number),
    today: PropTypes.string,
  }),
  getDaysFreeFromWork: PropTypes.func,
};

export default EditEventModal;
