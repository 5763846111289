import { LOAN_EMPLOYEES_ENABLE, OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE } from '@/constants/Permissions';

export const userHasPermissionsToAccessOtherLocationsWithinCompany = (userPermissions: { permissions: string[] }) => {
  if (!userPermissions) return false;
  const permissionsWhichAllowAccessLocationsWithinCompany = [
    LOAN_EMPLOYEES_ENABLE,
    OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE,
  ];
  return permissionsWhichAllowAccessLocationsWithinCompany.some(permission =>
    userPermissions.permissions.includes(permission),
  );
};
