import { CellContext } from '@tanstack/react-table';
import { useCallback } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { changePayrollDaysStatus } from '@/actions/payroll/payroll';
import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectSingleEmployeeIdFilter } from '@/redux-store/singleEmployeeFilter';

import { getPayrollStatus } from '../../PayrollTable.helpers';
import { messages } from '../../PayrollTable.messages';
import { PayrollRow } from '../../PayrollTable.types';

type Props = CellContext<TableRow<PayrollRow>, unknown>;

const getStatusColumnLabel = (date: PayrollRow['date'], isUnapproved: PayrollRow['isUnapproved'], intl: IntlShape) => {
  if (date < PAYROLL_APPROVAL_START_DATE)
    return intl.formatMessage(messages.payrollApprovalUnavailable, { date: PAYROLL_APPROVAL_START_DATE });
  return intl.formatMessage(isUnapproved ? messages.approveTooltip : messages.unapproveTooltip);
};

export const PayrollStatusCell = (props: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const filteredEmployeeId = useAppSelector(selectSingleEmployeeIdFilter);
  const { isLoanedEmployee, date, isUnapproved } = props.cell.row.original;
  const status = getPayrollStatus(isUnapproved);

  const onClick = useCallback(() => {
    dispatch(changePayrollDaysStatus(filteredEmployeeId, [date], getPayrollStatus(!isUnapproved)));
  }, [dispatch, filteredEmployeeId, date, isUnapproved]);

  const disabled = date < PAYROLL_APPROVAL_START_DATE || isLoanedEmployee;
  const label = getStatusColumnLabel(date, isUnapproved, intl);

  return (
    <MDIconButton
      disabled={disabled}
      label={label}
      icon="check_circle"
      className={`k-payrollTable__approvalIcon--${status}`}
      onClick={onClick}
    />
  );
};
