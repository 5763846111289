import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Location } from '@/types';

import { ScheduleTableRow } from '../../ScheduleTableRow/ScheduleTableRow';
import ScheduleTableSingleRow from '../../ScheduleTableSingleRow/ScheduleTableSingleRow.redux';
import { useSupplementaryEmployees } from './useSupplementaryEmployees';

type Props = {
  locationId: Location['id'];
};

export const SupplementaryEmployees = memo(({ locationId }: Props) => {
  const { colSpan, hideSupplementaryEmployees, isSingleDay, supplementaryEmployeeIds } =
    useSupplementaryEmployees(locationId);

  if (hideSupplementaryEmployees) return null;

  return (
    <>
      <tr>
        <td>
          <FormattedMessage id="schedule.supplementaryEmployees" defaultMessage="Pracownicy pomocniczy" />
        </td>
        <td colSpan={colSpan} />
      </tr>
      {supplementaryEmployeeIds.map(employeeId =>
        isSingleDay ? (
          <ScheduleTableSingleRow key={employeeId} employeeId={employeeId} locationId={locationId} />
        ) : (
          <ScheduleTableRow key={employeeId} employeeId={employeeId} locationId={locationId} />
        ),
      )}
    </>
  );
});

SupplementaryEmployees.displayName = 'SupplementaryEmployees';
