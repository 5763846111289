import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { deleteBudgetMetric } from '@/actions/budgetMetrics';
import { showModal } from '@/actions/uiState';
import { ADD_OR_EDIT_BUDGET_METRIC_MODAL } from '@/constants/modalTypes';
import { useAppDispatch } from '@/redux-store';

import { BudgetMetricRow } from '../../BudgetMetricsTable.types';
import { getOptions } from './BudgetMetricOptionCell.utils';

export const useBudgetMetricOptionCell = (rowData: BudgetMetricRow, canEdit: boolean) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const onEdit = useCallback(() => {
    dispatch(showModal(ADD_OR_EDIT_BUDGET_METRIC_MODAL, rowData.id));
  }, [dispatch, rowData]);

  const onDelete = useCallback(() => {
    dispatch(deleteBudgetMetric(rowData.id));
  }, [dispatch, rowData]);

  const options = useMemo(() => getOptions(onEdit, onDelete, intl, canEdit), [onEdit, onDelete, intl, canEdit]);

  return { options };
};
