import moment from 'moment';
import { useIntl } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import {
  CANCEL_MODIFIERS,
  CONFIRM_MODIFIERS,
} from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTracker.helpers.ts';
import { EmployeeShift } from '@/types';

import { messages } from './DashboardTimeTracker.messages.ts';

type Props = {
  handleConfirm: () => void;
  handleCancel: () => void;
  previousShift: EmployeeShift;
  nextShift: EmployeeShift;
};

export const DashboardTimeTrackerConfirm = ({ handleConfirm, handleCancel, nextShift, previousShift }: Props) => {
  const intl = useIntl();
  const now = moment();
  const numberOfMinutesIn12Hours = 12 * 60;
  const isNextShiftRelevantForWarning =
    nextShift && moment(nextShift.start_timestamp).diff(now, 'minutes') <= numberOfMinutesIn12Hours;
  const relevantNextShift = isNextShiftRelevantForWarning ? nextShift : null;
  const isNextShiftTomorrow =
    relevantNextShift && moment(relevantNextShift.start_timestamp).isAfter(moment().endOf('day'));
  const isPreviousShiftYesterday =
    previousShift && moment(previousShift.end_timestamp).isBefore(moment().startOf('day'));

  return (
    <>
      <div className="dashboardTimeTracker__confirmTextWrapper">
        {previousShift && (
          <div className="dashboardTimeTracker__confirmText">
            {intl.formatMessage(messages.previousShift, {
              day: isPreviousShiftYesterday ? ` ${intl.formatMessage(messages.yesterday)}` : '',
              at: intl.formatMessage(messages.at),
              hour: moment(previousShift.end_timestamp).format('HH:mm'),
            })}
          </div>
        )}
        {relevantNextShift && (
          <div className="dashboardTimeTracker__confirmText">
            {intl.formatMessage(messages.nextShiftStarts, {
              day: isNextShiftTomorrow ? ` ${intl.formatMessage(messages.tomorrow)}` : '',
              at: intl.formatMessage(messages.at),
              hour: moment(relevantNextShift.start_timestamp).format('HH:mm'),
            })}
          </div>
        )}
        {!previousShift && !relevantNextShift && (
          <div className="dashboardTimeTracker__confirmText">{intl.formatMessage(messages.noNextShift)}</div>
        )}
        <div className="dashboardTimeTracker__confirmText">{intl.formatMessage(messages.confirmText)}</div>
      </div>
      <div className="dashboardTimeTracker__bottomButtons">
        <Button modifiers={CANCEL_MODIFIERS} onClick={handleCancel}>
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button modifiers={CONFIRM_MODIFIERS} onClick={handleConfirm}>
          {intl.formatMessage(messages.confirm)}
        </Button>
      </div>
    </>
  );
};
