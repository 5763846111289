import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getAttendanceImageUrls } from '@/actions';
import { showModal } from '@/actions/uiState';
import { ATTENDANCE_MAP_MODAL, EDIT_ATTENDANCE_MODAL, PHOTO_PREVIEW_MODAL } from '@/constants/modalTypes';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectRcpPhotosDataByAttendanceId } from '@/redux-store/rcpPhotos';
import { selectLocationSetting } from '@/redux-store/settings/locationSettings';
import { isEmptyObject } from '@/utils/objectHelpers/objectHelpers';

import { SubRowType } from '../../../AttendanceManyDaysTable.types';
import { useEditAttendanceDisabled } from '../../useEditAttendanceDisabled';
import { getOptions } from './KebabOptions.utils';

export const useKebabOptions = ({ attendanceObject, employeeObject, location }: SubRowType) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const locationSetting = useAppSelector(selectLocationSetting(location.id));
  const availablePhotos = useAppSelector(selectRcpPhotosDataByAttendanceId(attendanceObject.id));
  const { isRowDisabled, isAttendanceEditDisabled } = useEditAttendanceDisabled({
    attendanceObject,
    employeeObject,
    location,
  });
  const isGeolocationEnabled = locationSetting.enable_localization_check || locationSetting.rcp_requires_geolocation;
  const hasAttendanceCoords =
    !isEmptyObject(attendanceObject.open_coords) || !isEmptyObject(attendanceObject.close_coords);

  const showAttendanceMapModal = useCallback(() => {
    dispatch(
      showModal(ATTENDANCE_MAP_MODAL, {
        attendance: attendanceObject,
        locationSettings: locationSetting,
        location,
      }),
    );
  }, [attendanceObject, dispatch, location, locationSetting]);

  const showPhotoPreviewModal = useCallback(() => {
    dispatch(getAttendanceImageUrls(attendanceObject.location.id, attendanceObject.id));
    dispatch(
      showModal(PHOTO_PREVIEW_MODAL, {
        attendanceId: attendanceObject.id,
        locationId: attendanceObject.location.id,
      }),
    );
  }, [attendanceObject.id, attendanceObject.location.id, dispatch]);

  const showEditModal = useCallback(() => {
    dispatch(
      showModal(EDIT_ATTENDANCE_MODAL, {
        employee: employeeObject,
        attendance: attendanceObject,
      }),
    );
  }, [attendanceObject, dispatch, employeeObject]);

  const options = useMemo(
    () =>
      getOptions(
        attendanceObject,
        availablePhotos,
        dispatch,
        hasAttendanceCoords,
        intl,
        isAttendanceEditDisabled,
        isGeolocationEnabled,
        showAttendanceMapModal,
        showEditModal,
        showPhotoPreviewModal,
      ),
    [
      attendanceObject,
      availablePhotos,
      dispatch,
      hasAttendanceCoords,
      intl,
      isAttendanceEditDisabled,
      isGeolocationEnabled,
      showAttendanceMapModal,
      showEditModal,
      showPhotoPreviewModal,
    ],
  );

  return { disabled: isRowDisabled, options };
};
