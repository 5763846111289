import { GoogleOAuthProvider } from '@react-oauth/google';
import ReduxToastr from 'react-redux-toastr';

import { KadroRoutes } from '@/components/KadroRoutes';
import { browserHistory } from '@/constants/browserHistory.ts';
import { GOOGLE_CLIENT_ID } from '@/constants/envVariables.ts';

import { conn } from './actions';
import { getUnlockPaymentData, initNotOwnerAccountBlocked, initPaymentUnlock, logoutUser } from './actions/auth';
import { AppProvider } from './components/providers/appProvider/AppProvider';
import { IntlContextProvider } from './components/providers/intl/IntlContextProvider';
import { useApp } from './hooks/useApp/useApp';

import './css/style.scss';
import './css/kadro.scss';

const getGoogleClientId = () => {
  if (GOOGLE_CLIENT_ID?.length > 0) return GOOGLE_CLIENT_ID;
  return 'TMP_GOOGLE_CLIENT_ID';
};

const App = () => {
  useApp();

  return (
    <AppProvider>
      <IntlContextProvider>
        <GoogleOAuthProvider clientId={getGoogleClientId()}>
          <KadroRoutes />
        </GoogleOAuthProvider>
        <ReduxToastr
          timeOut={3500}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="bounceIn"
          transitionOut="bounceOut"
          progressBar
        />
      </IntlContextProvider>
    </AppProvider>
  );
};

export default App;

conn.setAuthorizationErrorHandler(async (error, dispatch) => {
  if (error?.response?.data?.error === 'payment required') {
    dispatch(initPaymentUnlock(error.response.data.client_status));
    dispatch(getUnlockPaymentData());
    browserHistory.push('/blocked-account');
  } else if (error?.response?.data?.error === 'account blocked') {
    dispatch(initNotOwnerAccountBlocked(error.response.data.error));
    browserHistory.push('/blocked-account');
  } else if (!['User is not invited', 'User is inactive'].includes(error?.response?.data?.message)) {
    try {
      const { data } = await conn.validateToken();
      if (!data?.valid) dispatch(logoutUser());
    } catch {
      dispatch(logoutUser());
    }
  }
});
