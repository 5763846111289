import MDTable from '@/components/common/MDTable/MDTable';

import { AttendanceManyDaysTableButtonBar } from './ButtonBar/AttendanceManyDaysTableButtonBar';
import { AttendanceOptionCell } from './Cells';
import { AttendanceManyDaysEmptyTable } from './Empty/AttendanceManyDaysEmptyTable';
import { useAttendanceManyDaysTable } from './useAttendanceManyDaysTable';

export const AttendanceManyDaysTable = () => {
  const { columns, data, expandedRows, onExpand } = useAttendanceManyDaysTable();

  return (
    <MDTable
      data={data}
      columns={columns}
      enableRowSelection
      expandedRows={expandedRows}
      onExpand={onExpand}
      options={<AttendanceOptionCell />}
      tableEmpty={<AttendanceManyDaysEmptyTable />}
      tableOptions={<AttendanceManyDaysTableButtonBar />}
    />
  );
};
