import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { mainDateChangeMode } from '@/actions/mainDate.js';
import { dateModes } from '@/constants/dateModes.ts';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectDateMode } from '@/redux-store/mainDateStore';

import './DashboardDateMode.scss';

const messages = defineMessages({
  days: {
    id: 'topBar.days',
    defaultMessage: 'dni',
  },
});

type Props = {
  modes: number[];
};

export const DashboardDateMode = ({ modes }: Props) => {
  const intl = useIntl();
  const dateMode = useAppSelector(selectDateMode);
  const dispatch = useAppDispatch();
  const objectsToChoose = useMemo(() => dateModes.filter(mode => modes.includes(mode.id)), [modes]);

  const onChange = useCallback(
    mode => {
      dispatch(mainDateChangeMode(mode.type));
    },
    [dispatch],
  );

  const selectedObject = useMemo(
    () => objectsToChoose.find(mode => mode.type === dateMode) || objectsToChoose[0],
    [dateMode, objectsToChoose],
  );

  return (
    <div className="k-topBarFilter k-topBarDateMode">
      {objectsToChoose.map(object => {
        const modeClassnames = classnames('k-topBarDateMode__mode', {
          'k-topBarDateMode__mode--selected': object.id === selectedObject.id,
        });
        return (
          <button className={modeClassnames} key={object.id} onClick={() => onChange(object)}>
            <span className="k-topBarDateMode__modeLength">
              {object.length} {intl.formatMessage(messages.days)}
            </span>
          </button>
        );
      })}
    </div>
  );
};
