import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { showModal } from '@/actions/uiState';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { ADD_ABSENCE_PROPOSAL_MODAL } from '@/constants/modalTypes.js';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { selectIsQuickPlanningEnabled } from '@/redux-store/schedule/quickPlanning';
import { selectIsAvailabilitiesViewMode } from '@/redux-store/schedule/viewMode';
import { selectIsLoggedUserEmployee, selectRestrictionsDict } from '@/redux-store/userPermissions';
import { checkIsMonthlyScheduleView, getScheduleBlockSize } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import './ScheduleAbsenceBlock.scss';

const ScheduleAbsenceBlock = props => {
  const { absence, date, absenceName, employeeName, typeName, statusName } = props;
  const dispatch = useAppDispatch();
  const isDraft = absence.status === 'draft';
  const dateArray = useAppSelector(selectDateArray);
  const isMonthView = useMemo(() => checkIsMonthlyScheduleView(dateArray), [dateArray]);
  const lastVisibleDate = dateArray.at(-1);
  const size = useMemo(() => getScheduleBlockSize(dateArray), [dateArray]);
  const isQuickPlanningEnabled = useAppSelector(selectIsQuickPlanningEnabled);
  const isAvaViewMode = useAppSelector(selectIsAvailabilitiesViewMode);
  const restrictions = useAppSelector(selectRestrictionsDict);
  const isEditDisabled = restrictions[SCHEDULE_EDIT_DISABLE];
  const isEmployee = useAppSelector(selectIsLoggedUserEmployee);

  const absenceClass = classnames('k-absenceBlock', `k-absenceBlock--${size}`, {
    'k-absenceBlock--employee': isEmployee,
    'k-absenceBlock--draft': isDraft,
    'k-absenceBlock--disabled': isEditDisabled,
    'k-absenceBlock--avaMode': isAvaViewMode,
  });
  const lastDate = lastVisibleDate < absence.to ? lastVisibleDate : absence.to;
  const numberOfDays = moment(lastDate).diff(date, 'days') + 1;
  const hasBoldBorder = !isMonthView && moment(absence.from).isoWeekday() < 6 && moment(absence.to).isoWeekday() >= 6;
  const isMonth = size === 'month';
  const cellPaddingWidth = isMonth ? 4 : 17;
  const width = `calc((100% + ${cellPaddingWidth}px) * ${numberOfDays} - ${isMonth ? 1 : cellPaddingWidth}px + ${
    hasBoldBorder ? '4px' : '0px'
  })`;
  const marginTop = isMonth ? '2px' : '8px';

  return (
    <div
      onClick={event => {
        event.stopPropagation();
        if (!isEmployee && !isQuickPlanningEnabled && !isEditDisabled) {
          dispatch(
            showModal(ADD_ABSENCE_PROPOSAL_MODAL, {
              ...absence,
              employee_name: employeeName,
              type_name: typeName,
              status_name: statusName,
            }),
          );
        }
      }}
      className={absenceClass}
      role="presentation"
      style={{ width, marginTop: !isEmployee ? marginTop : '6px' }}
    >
      <div className="k-absenceBlock__connector" />
      <div className="sch_block__info">
        <div className="absence-name">
          {absenceName} {absence.absence_hours}
        </div>
      </div>
      <Icon name="fact_check" />
    </div>
  );
};

ScheduleAbsenceBlock.propTypes = {
  absence: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    absence_hours: PropTypes.string,
    status: PropTypes.string,
  }),
  date: PropTypes.string,
  absenceName: PropTypes.string,
  employeeName: PropTypes.string,
  typeName: PropTypes.string,
  statusName: PropTypes.string,
};

export default ScheduleAbsenceBlock;
