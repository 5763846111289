import moment from 'moment';
import { defineMessages } from 'react-intl';

import { getExports } from '@/components/common/exports/exportsConfig.ts';
import * as AT from '@/constants/ActionTypes';
import { defaultAvailabilityTypes } from '@/constants/availabilityDefaultTypes.js';
import { ANNONYMOUS_ABSENCE_NAME, EXPORT_FILE_TYPES, EXPORT_MODE } from '@/constants/exportConstants.js';
import {
  AttendanceExportData,
  dayReportExportData,
  payrollExportData,
  payrollLocationExportData,
} from '@/constants/exportData.js';
import { SCHEDULE_IMPORTS } from '@/constants/imports.js';
import { IMPORT_SHIFT_AND_ATTENDANCE_ERROR_MODAL } from '@/constants/modalTypes.js';
import { selectAllLoanedEmployeesIds } from '@/redux-store/scheduleState/locations';
import { unionBy } from '@/utils/array/array.helpers';
import { generateAttendancesDataForEmployee } from '@/utils/attendanceHelpers';
import {
  generateShortName,
  getAvailbilitiesWithGivenDates,
  groupAvailabilitiesByDuration,
} from '@/utils/availabilitiesHelpers';
import { listArrayItems, removeLatinAndSpecial, roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import { calculateDailyHoursAndCosts } from '@/utils/budgetHelpers';
import { calculateDurationBetweenTimestamps, humanFormToMinutes, parseMinutesToFormat } from '@/utils/dateHelper.js';
import { getHiringDate, workingMinutesToHours } from '@/utils/employmentConditionsHelpers.ts';
import {
  bonusSystemFormattingHeaders,
  bonusSystemOmitRows,
  getBonusSystemExportsSheets,
} from '@/utils/exports/bonusSystemExports/bonusSystemExports.js';
import {
  createComarchXLSandDownload,
  createComarchXlsShifts,
  createEnovaXMLandDownload,
  createPayrollLocationExportSummary,
  downloadFileFromUrl,
  exportEnovaShifts,
  fillInEmptyDays,
  filterOnlyActiveTypes,
  generateTermAndWageString,
  getLocationForExportMetaData,
  getPayrollLocationBasicXlsRow,
  mergePerDay,
  payrollLocationFreeDays,
  payrollLocationMergeInDay,
  payrollLocationRowObjectToArray,
  payrollLocationRowObjectToArrayBrief,
  payrollRowObjectToArray,
  prepareCSVandDownload,
  replaceForbiddenCharsForCSV,
} from '@/utils/exports/exportHelpers.js';
import { getGsnExportSheets } from '@/utils/exports/gsnExports/gsnExports.js';
import { r2platnikExport } from '@/utils/exports/r2platnikExports/r2platnikExports.js';
import { sageExport } from '@/utils/exports/sageExports';
import XLSExport from '@/utils/exports/XLSExport.js';
import { formattingHeaders } from '@/utils/exports/XLSFormatting';
import {
  absencesSummaryXLSHeaders,
  payrollLocationBasicXLSHeaders,
  payrollLocationBriefHeaders,
  payrollLocationECPFullHeaders,
  payrollLocationScheduleFullHeaders,
  payrollLocationShiftsUnlessFixedFullHeaders,
} from '@/utils/exports/XLSHeaders.js';
import { intercomTrackEvent } from '@/utils/intercomHelpers.js';
import {
  getAvailabilitiesHeadersForPayrollExport,
  getLocationNameForId,
  getMatchingIndividualWage,
  getMatchingIndividualWageForShift,
} from '@/utils/payrollHelpers';
import {
  divideLocationsIntoMainAndSupplementary,
  getEmployeeNameShort,
  getReadablePermissionName,
} from '@/utils/userEmployeesHelpers.js';

import { getRouteInitData } from './auth.jsx';
import { getBudgetEstimates } from './budgetEstimates.js';
import { createComarchXlsAbsencesSheet, formatAbsencesExportDataAoA } from './exportAbsences.js';
import { conn, getAttendances, getSchedule, getScheduleAndAttendancesForLocations } from './index';
import {
  getVisibleEmployeesIdsForScheduleView,
  toggleImportBudgetModalInputError,
  toggleImportBudgetModalOverlay,
} from './schedule.jsx';
import { getBudgetTargets } from './schedule/budget.js';
import { hideModal, showModal } from './uiState.js';

// -----------------------------------------------------------------------------
//  New exports
// -----------------------------------------------------------------------------

const messages = defineMessages({
  getExportSuccessTitle: {
    id: 'exports.customNotifications.sendNotificationSuccessTitle',
    defaultMessage: 'Plik gotowy',
  },
  getExportSuccess: {
    id: 'exports.customNotifications.sendNotificationSuccess',
    defaultMessage: 'Udało się poprawnie wygenerować eksport',
  },
  importScheduleSuccess: {
    id: 'exports.customNotifications.importScheduleSuccess',
    defaultMessage: 'Pomyślnie dodano zmiany w grafiku pracy',
  },
  importSuccessTitle: {
    id: 'exports.customNotifications.importSuccessTitle',
    defaultMessage: 'Sukces',
  },
  getExportErrorTitle: {
    id: 'exports.customNotifications.sendNotificationErrorTitle',
    defaultMessage: 'Błąd',
  },
  getExportError: {
    id: 'exports.customNotifications.sendNotificationError',
    defaultMessage: 'Nie udało się poprawnie wygenerować eksportu',
  },
  importErrorTitle: {
    id: 'exports.customNotifications.importErrorTitle',
    defaultMessage: 'Błąd',
  },
  importError: {
    id: 'exports.customNotifications.importError',
    defaultMessage: 'Nie udało się poprawnie zaimportować grafiku',
  },
  importShiftsAndAttendancesSuccessTitle: {
    id: 'exports.customNotifications.importShiftsAndAttendancesSuccessTitle',
    defaultMessage: 'Import gotowy',
  },
  importShiftsAndAttendancesSuccess: {
    id: 'exports.customNotifications.importShiftsAndAttendancesSuccess',
    defaultMessage: 'Godziny pracy zostały zaimportowane',
  },
  importBudgetAndTargetBudgetSuccess: {
    id: 'export.customNotifications.importBudgetAndTargetBudgetSuccess',
    defaultMessage: 'Pomyślnie dodano dane w tabeli budżet',
  },
  locationNotFound: {
    id: 'export.location.locationNotFound',
    defaultMessage: 'Brak lokalizacji',
  },
});

export const getAvailableExports = () => dispatch => {
  conn.getAvailableExports().then(result => {
    dispatch({
      type: AT.GET_AVAILABLE_EXPORTS_SUCCESS,
      payload: result.data.available_exports,
    });
  });
};

export const getExportError = id => (dispatch, getState, intl) => {
  const { availableExports } = getState().reducer.exports;
  const { category } = availableExports.find(e => e.id === id);
  dispatch({
    type: AT.GET_EXPORT_ERROR,
    payload: id,
  });

  if (category === 'SCHEDULE_IMPORT') {
    dispatch({
      type: AT.GET_EXPORT_SUCCESS_NOTIFICATION,
      notification: {
        type: 'error',
        title: intl.formatMessage(messages.importErrorTitle, {}),
        description: intl.formatMessage(messages.importError, {}),
      },
    });

    return;
  }

  dispatch({
    type: AT.GET_EXPORT_ERROR_NOTIFICATION,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.getExportErrorTitle, {}),
      description: intl.formatMessage(messages.getExportError, {}),
    },
  });
};

export const runExport = (exportId, displayName, payload) => (dispatch, getState) => {
  const { fileIconText, ...paylaodToSend } = payload;
  const {
    currentUser: { user },
  } = getState().reducer;
  const intercomData = { userId: user.id, companyId: user.company_id };
  return new Promise((resolve, reject) => {
    conn
      .runExport(exportId, paylaodToSend)
      .then(result => {
        const timeoutId = setTimeout(() => dispatch(getExportError(result.data.task_id)), 1000 * 1200);
        dispatch({
          type: AT.POST_EXPORT,
          payload: {
            id: result.data.task_id,
            name: displayName,
            timeoutId,
            type: exportId,
            isTemplate: paylaodToSend.type === 'template',
            isShownInExportsWidget: !SCHEDULE_IMPORTS.includes(exportId),
            fileIconText,
          },
        });
        intercomTrackEvent(`EXPORTS_EXPORT_${displayName.replace(/ /g, '_').toUpperCase()}`, intercomData);
        resolve();
      })
      .catch(reject);
  });
};

export const showImportAndExportSuccessNotification = (titleMessage, descriptionMessage) => (dispatch, _, intl) => {
  dispatch({
    type: AT.GET_EXPORT_SUCCESS_NOTIFICATION,
    notification: {
      type: 'success',
      title: intl.formatMessage(titleMessage),
      description: intl.formatMessage(descriptionMessage),
    },
  });
};

export const getExportSuccess = exportObject => (dispatch, getState) => {
  const { reducer } = getState();
  const { multipleLocationFilter, currentCompany, scheduleLocationFilter } = reducer;
  const [locationId] = scheduleLocationFilter;
  const { availableExports, pendingExports } = reducer.exports;
  const { start, end } = reducer.mainDateStore.customDate;
  const exportType = pendingExports.find(e => e.id === exportObject.id).type;
  const { category } = availableExports.find(e => e.id === exportType);
  const processReturnedDownloadLink = !!exportObject.link;

  dispatch({
    type: AT.GET_EXPORT_SUCCESS,
    payload: exportObject,
  });

  if (category === 'SCHEDULE_IMPORT') {
    const { importSuccessTitle, importScheduleSuccess } = messages;
    dispatch(hideModal());
    dispatch(showImportAndExportSuccessNotification(importSuccessTitle, importScheduleSuccess));
    dispatch(getSchedule(locationId, start, end, 'blocking'));
    return;
  }

  if (category === 'BUDGET_IMPORT') {
    if (processReturnedDownloadLink) dispatch(toggleImportBudgetModalOverlay(false));
    else {
      const { importSuccessTitle, importBudgetAndTargetBudgetSuccess } = messages;
      dispatch(showImportAndExportSuccessNotification(importSuccessTitle, importBudgetAndTargetBudgetSuccess));
      dispatch(toggleImportBudgetModalOverlay(false));
      dispatch(hideModal());
      dispatch(getBudgetEstimates(start, end, [locationId]));
    }
    return;
  }

  if (category === 'SHIFTS_AND_ATTENDANCES_IMPORT') {
    const { importShiftsAndAttendancesSuccessTitle, importShiftsAndAttendancesSuccess } = messages;
    dispatch(
      showImportAndExportSuccessNotification(importShiftsAndAttendancesSuccessTitle, importShiftsAndAttendancesSuccess),
    );
    dispatch(getScheduleAndAttendancesForLocations(multipleLocationFilter, start, end, null, 'blocking'));
    dispatch(getAttendances(currentCompany.id, multipleLocationFilter, start, end, 'blocking'));
    if (exportObject.result?.errors?.length > 0) {
      dispatch(showModal(IMPORT_SHIFT_AND_ATTENDANCE_ERROR_MODAL, { errors: exportObject.result.errors }));
    } else {
      dispatch(hideModal());
    }
    return;
  }

  if (category === 'BUDGET_TARGETS_IMPORT') {
    const exportReturnedImportTemplate = processReturnedDownloadLink && scheduleLocationFilter.length === 1;
    if (exportReturnedImportTemplate) return;
    const { importSuccessTitle, importBudgetAndTargetBudgetSuccess } = messages;
    dispatch(showImportAndExportSuccessNotification(importSuccessTitle, importBudgetAndTargetBudgetSuccess));
    dispatch(getBudgetTargets(start, end, locationId));
    return;
  }

  if (category === 'BUDGET_METRICS_IMPORT') {
    if (processReturnedDownloadLink) return;
    dispatch(getRouteInitData());
    const { importSuccessTitle, importBudgetAndTargetBudgetSuccess } = messages;
    dispatch(showImportAndExportSuccessNotification(importSuccessTitle, importBudgetAndTargetBudgetSuccess));
    return;
  }

  const { getExportSuccessTitle, getExportSuccess } = messages;
  dispatch(showImportAndExportSuccessNotification(getExportSuccessTitle, getExportSuccess));
};

export const getExportFailed = id => (dispatch, getState, intl) => {
  const { reducer } = getState();
  const { availableExports, pendingExports } = reducer.exports;
  const exportType = pendingExports.find(e => e.id === id).type;
  const { category } = availableExports.find(e => e.id === exportType);

  if (category === 'BUDGET_IMPORT') {
    dispatch(toggleImportBudgetModalOverlay(false));
    dispatch(toggleImportBudgetModalInputError(true));
    return;
  }

  dispatch({
    type: AT.GET_EXPORT_FAILED,
    payload: id,
  });
  dispatch({
    type: AT.GET_EXPORT_ERROR_NOTIFICATION,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.getExportErrorTitle, {}),
      description: intl.formatMessage(messages.getExportError, {}),
    },
  });
};

// -----------------------------------------------------------------------------
// Export common actions
// -----------------------------------------------------------------------------

export const getExportMetaData = () => (dispatch, getState) => {
  const { mainDateStore, currentCompany, scheduleLocationFilter, userLocations } = getState().reducer;
  const { location, locationObject } = getLocationForExportMetaData(scheduleLocationFilter, userLocations);
  const { dateArray } = mainDateStore;
  const from = dateArray[0];
  const to = dateArray[dateArray.length - 1];
  return {
    location,
    from,
    to,
    locationObject,
    companyName: currentCompany.name,
    dateArray,
  };
};

export const getPayrollExportMetaData = () => (dispatch, getState) => {
  const { mainDateStore, currentCompany } = getState().reducer;
  const { dateArray } = mainDateStore;
  const from = dateArray[0];
  const to = dateArray[dateArray.length - 1];
  return { from, to, companyName: currentCompany.name };
};

export const getExportFormatting = (headers, headersFormatting, timeFormat) => {
  const formatting = [];
  if (timeFormat === 'decimal') {
    headers.forEach(header => {
      if (headersFormatting[header]) {
        formatting.push(headersFormatting[header]);
        return;
      }
      formatting.push(null);
    });
  }
  return formatting;
};

// -----------------------------------------------------------------------------
// Schedule export
// -----------------------------------------------------------------------------

export const formatAvailabilitiesExportDataAoA = rawData => (dispatch, getState) => {
  const { mainDateStore, userCustomTypes } = getState().reducer;
  const metaData = dispatch(getExportMetaData());
  const availabilities = [...defaultAvailabilityTypes, ...userCustomTypes];
  const employeesAvailabilities = rawData.sortedAndRelevantEmployees.reduce((acc, val) => {
    const availabilitiesForGivenDate = getAvailbilitiesWithGivenDates(val.availability_blocks, mainDateStore.dateArray);
    const selectedAvailabililitiesIds = rawData.options.selectedColumns.map(id => availabilities[id].id);
    const selectedAvailabililities = availabilitiesForGivenDate.filter(ava =>
      selectedAvailabililitiesIds.includes(ava.type_id || ava.type),
    );

    const availabilitiesGroupedByDuration = groupAvailabilitiesByDuration(selectedAvailabililities);
    return acc.concat(
      availabilitiesGroupedByDuration.map(ava => ({
        firstName: val.first_name,
        lastName: val.last_name,
        referenceId: val.reference_id,
        vacationLeave: ava,
      })),
    );
  }, []);

  const body = employeesAvailabilities.map(val => {
    const avaName = (availabilities.find(type => type.id === val.vacationLeave.id) || {}).name;
    return [
      val.referenceId,
      val.lastName,
      val.firstName,
      avaName,
      avaName,
      val.vacationLeave.start,
      val.vacationLeave.end,
      'Nie dotyczy',
      '',
      0,
    ];
  });

  return {
    body,
    metaData,
  };
};

export const formatRecommendedScheduleExportDataAoA = () => (dispatch, getState) => {
  const { mainDateStore, jobtitleFilter } = getState().reducer;
  const hours = Array.from({ length: 24 }, (v, k) => (k < 10 ? `0${k}:00` : `${k}:00`));
  const body = [];
  mainDateStore.dateArray.forEach(date => {
    hours.forEach(hour => {
      body.push([date, hour, ...Array.from({ length: jobtitleFilter.selectedJobtitlesGrouped.length }, () => '')]);
    });
  });
  return body;
};

export const formatScheduleExportDataAoA = () => (dispatch, getState, intl) => {
  const { userLocations, userEmployees, mainDateStore, schedule, scheduleLoanedEmployees } = getState().reducer;
  const { customDate } = mainDateStore;
  const { start, end } = customDate;
  const showShiftsFromOtherLocations = schedule.viewSettings.showShiftsFromOtherLocations.value;
  const metaData = dispatch(getExportMetaData());
  const { locationObject } = metaData;
  const visibleEmployeeIds = dispatch(getVisibleEmployeesIdsForScheduleView({ includeLoaned: true }));

  const body = visibleEmployeeIds.reduce((acc, id) => {
    const employee = userEmployees.find(e => e.id === id);
    const loanedEmployee = scheduleLoanedEmployees.scheduleLoanedEmployees[id];
    const parsedLoanedEmployee = { ...loanedEmployee, shifts: loanedEmployee?.shiftsData };
    const relevantEmployee = employee || parsedLoanedEmployee;

    const { shifts, first_name: firstName, last_name: lastName } = relevantEmployee;
    let correctShifts = [];
    if (Boolean(relevantEmployee) && shifts.length > 0) {
      correctShifts = shifts.reduce(
        (
          accShifts,
          {
            date,
            job_title: jobTitle,
            end_timestamp: shiftsEndTimestamp,
            start_timestamp: shiftsStartTimestamp,
            location,
            working_hours: workingHours,
          },
        ) => {
          const [startTimestamp] = shiftsStartTimestamp.split(' ');
          const [startWorkHour, endWorkHour] = workingHours.split('-');
          const isShiftToExport =
            end >= startTimestamp &&
            start <= startTimestamp &&
            (location.id === locationObject.id || showShiftsFromOtherLocations);
          if (isShiftToExport) {
            return [
              ...accShifts,
              [
                date,
                `${firstName} ${lastName}`,
                jobTitle.title,
                getLocationNameForId(
                  location.id,
                  userLocations,
                  intl.formatMessage(messages.locationNotFound),
                  location.name,
                ),
                startWorkHour,
                endWorkHour,
                roundToTwoSigDigits(calculateDurationBetweenTimestamps(shiftsEndTimestamp, shiftsStartTimestamp) / 60),
              ],
            ];
          }
          return accShifts;
        },
        [],
      );
    }
    return [...acc, ...correctShifts];
  }, []);
  return { data: body, metaData };
};

const getAbsencesBlocks = (employee, scheduleAbsences, selectedAbsenceTypes) => {
  if (!Object.keys(selectedAbsenceTypes).length || !scheduleAbsences[employee.id]) {
    return [];
  }

  return scheduleAbsences[employee.id]
    .map(absence => {
      const absenceType = selectedAbsenceTypes[absence.type_id];
      if (!absenceType) return null;
      const { shortName, name } = selectedAbsenceTypes[absence.type_id];
      return { ...absence, short_name: shortName, name };
    })
    .filter(Boolean);
};

const getAbsencesDatesArray = employeeAbsences => {
  if (!employeeAbsences?.length) {
    return [];
  }
  return employeeAbsences.reduce((acc, cur) => {
    if (cur.absence_hours) {
      return acc;
    }

    const from = moment(cur.from);
    const to = moment(cur.to);
    const absenceDuration = to.diff(from, 'days') + 1;

    return [
      ...acc,
      ...Array.from({ length: absenceDuration }).map((_, idx) => from.clone().add(idx, 'days').format('YYYY-MM-DD')),
    ];
  }, []);
};

export const formatScheduleExportDataJSON = rawData => (dispatch, getState, intl) => {
  const {
    absences: { absencesTypes, scheduleAbsences },
    userLocations,
    currentCompany,
    userEmployees,
    userCustomTypes,
    contracts,
    scheduleLoanedEmployees,
    userJobTitles,
  } = getState().reducer;
  const state = getState();
  const visibleEmployeeIds = dispatch(getVisibleEmployeesIdsForScheduleView());
  const { from, to, location, locationObject, dateArray } = dispatch(getExportMetaData());
  const { options } = rawData;

  let budgetStatistics, totalHours;
  const avaTypesById = {};
  const absenceTypesById = {};
  const activeAbsencesTypes = filterOnlyActiveTypes(absencesTypes);

  const visibleEmployees = visibleEmployeeIds.map(id => userEmployees.find(e => e.id === id)).filter(Boolean);
  const visibleLoanedEmployeeIds = selectAllLoanedEmployeesIds(state);
  const visibleLoanedEmployees = visibleLoanedEmployeeIds.map(
    id => scheduleLoanedEmployees.scheduleLoanedEmployees[id],
  );
  const parsedScheduleLoanedEmployees = Object.values(visibleLoanedEmployees).map(employee => ({
    ...employee,
    shifts: [...employee.shiftsData, ...employee.shifts_for_other_locations],
    isLoaned: true,
  }));

  const employees = parsedScheduleLoanedEmployees.length
    ? unionBy(visibleEmployees, parsedScheduleLoanedEmployees, 'id')
    : visibleEmployees;

  if (options.showSummary) {
    budgetStatistics = dateArray.map(day =>
      calculateDailyHoursAndCosts(
        visibleEmployees,
        day,
        {
          filterByJobTitle: false,
          filterByLocation: !options.showOtherLocations,
          selectedLocationsIds: [locationObject.id],
        },
        contracts,
        userJobTitles,
      ),
    );
    totalHours = budgetStatistics.map(s => s.dailyHours / 60);
  }

  if (options.exportType === EXPORT_MODE.SCHEDULE.AVAILABILITIES && options.selectedAvailabilityTypes?.length) {
    const allAvailabilities = [
      ...defaultAvailabilityTypes.map(a => ({ ...a, name: intl.formatMessage(a.name) })),
      ...userCustomTypes,
    ];
    options.selectedAvailabilityTypes.forEach(id => {
      const avaId = allAvailabilities[id].id || allAvailabilities[id].type;
      avaTypesById[avaId] = {
        name: allAvailabilities[id].name,
        shortName: allAvailabilities[id].availability_short_name || generateShortName(allAvailabilities[id].name),
      };
    });
  }
  if (options.exportType === EXPORT_MODE.SCHEDULE.ABSENCES && options.selectedAbsencesTypes?.length) {
    options.selectedAbsencesTypes.forEach(id => {
      const name = options.showLegend ? activeAbsencesTypes[id].name : ANNONYMOUS_ABSENCE_NAME;
      const shortName = options.showLegend
        ? activeAbsencesTypes[id].short_name || generateShortName(activeAbsencesTypes[id].name)
        : ANNONYMOUS_ABSENCE_NAME;

      absenceTypesById[activeAbsencesTypes[id].id] = {
        name,
        shortName,
      };
    });
  }
  const data = employees.map(employee => ({
    first_name: employee.first_name,
    last_name: employee.last_name,
    totalHours: budgetStatistics
      ? budgetStatistics.reduce((sum, current) => sum + current.dailyHoursPerEmployee[employee.id], 0) / 60
      : undefined,
    id: employee.id,
    reference_id: employee.reference_id,
    shifts: employee.shifts
      .filter(shift => {
        const employeeAbsences = scheduleAbsences[employee];
        const absencesDatesArray = getAbsencesDatesArray(employeeAbsences);
        const canShowShift = employee.isLoaned ? shift.location.id === locationObject.id : true;

        return !absencesDatesArray.includes(shift.date) && canShowShift;
      })
      .map(shift => {
        const locationObj = userLocations.find(l => l.id === shift.location.id);
        const { id, name } = locationObj || { id: '-1', name: 'brak' };
        return { ...shift, location: { id, name } };
      }),
    availability_blocks: employee.availability_blocks
      .filter(ava => avaTypesById[ava.type_id || ava.type] !== undefined)
      .map(ava => ({
        ...ava,
        name: avaTypesById[ava.type_id || ava.type].shortName,
      })),
    absences: getAbsencesBlocks(employee, scheduleAbsences, absenceTypesById, options),
  }));

  const legendItems = options.exportType === 'absences' ? absenceTypesById : avaTypesById;

  const metaData = {
    from,
    to,
    companyName: currentCompany.name,
    selectedLocationName: location,
    selectedLocation: locationObject,
    options,
    totalHoursArray: totalHours,
    dateArray: [],
    legend: Object.keys(legendItems).map(key => ({
      name: legendItems[key].name,
      shortName: legendItems[key].shortName,
    })),
  };
  return { data, metaData };
};

export const exportRecommendedSchedule = () => (dispatch, getState) =>
  new Promise(resolve => {
    const { jobtitleFilter } = getState().reducer;
    const exportData = dispatch(formatRecommendedScheduleExportDataAoA());
    const selectedJobTitle = jobtitleFilter.selectedJobtitlesGrouped.map(jobTitle => jobTitle.title);
    const headers = ['Data', 'Godzina', ...selectedJobTitle];
    const fileName = 'szablon_prognozowanego_grafiku.xlsx';
    const body = exportData;
    const xls = new XLSExport();
    body.unshift(headers);
    xls.aoaToXLS(body, 'prognozowany grafik');
    xls.download(fileName);
    resolve(null);
  });

/**
 * Given the format this action either directly downloads the file (like in the case of CSV) or returns a fileURLL
 * @param {string} format - one of CSV, PDF, XLS,
 * @param {Array<Object>} rawData - array of employee objects
 */

export const exportScheduleData = (format, rawData) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    switch (format) {
      case EXPORT_FILE_TYPES.XLS: {
        const exportData = dispatch(formatScheduleExportDataAoA(rawData));
        const { location, from, to } = exportData.metaData;
        const headers = ['Data', 'Pracownik', 'Stanowisko', 'Lokalizacja', 'Start', 'Koniec', 'Suma Godzin'];
        const fileName = `export_${location}_${from}-${to}.xls`;
        const body = exportData.data;
        const xls = new XLSExport();
        body.unshift(headers);
        xls.aoaToXLS(body, 'Zmiany');
        xls.download(fileName);
        resolve(null);
        break;
      }
      case EXPORT_FILE_TYPES.CSV: {
        const exportData = dispatch(formatScheduleExportDataAoA(rawData));
        const { location, from, to } = exportData.metaData;
        const csvHeaders = ['Data', 'Pracownik', 'Stanowisko', 'Lokalizacja', 'Start', 'Koniec', 'Suma Godzin'];
        const csvFilename = `export_${location}_${from}-${to}.csv`;
        const body = exportData.data;
        const csvBody = body.map(row => row.join(','));
        prepareCSVandDownload(csvHeaders, csvBody, csvFilename);
        resolve(null);
        break;
      }
      case EXPORT_FILE_TYPES.PDF: {
        const exportDataJSON = dispatch(formatScheduleExportDataJSON(rawData));
        conn
          .exportSchedule(format, exportDataJSON.data, exportDataJSON.metaData)
          .then(result => {
            resolve(result.data.downloadLink);
          })
          .catch(err => reject(err));
        break;
      }
      case EXPORT_FILE_TYPES.XML_ENOVA: {
        const { mainDateStore } = getState().reducer;
        const data = dispatch(formatScheduleExportDataJSON(rawData));
        const { from } = dispatch(getExportMetaData());
        const rows = [];
        data.data.forEach(employee => {
          const { shifts } = employee;
          mainDateStore.dateArray.forEach(date => {
            const shift = shifts.find(b => b.date === date) || { free: true };
            rows.push({ employee, id: employee.id, date, shift });
          });
        });
        exportEnovaShifts(rows, `Enova_DniPlanu${moment(from).format('YYYY.MM')}.xml`);
        resolve(null);
        break;
      }
      case EXPORT_FILE_TYPES.XML_COMARCH_OPTIMA: {
        const exportData = dispatch(formatAvailabilitiesExportDataAoA(rawData));
        const { location, from, to } = exportData.metaData;
        const fileName = `export_absences_${location}_${from}-${to}.xls`;
        const headers = [
          'Kod',
          'Nazwisko',
          'Imie',
          'Nazwa_do_importu',
          'Nazwa_zrodlowa',
          'Data_od',
          'Data_do',
          'Przyczyna',
          'Nieobecnosc_na_czesc_dnia',
          'Urlop_na_zadanie',
        ];
        const xls = new XLSExport();
        const bodyWithHeaders = [headers, ...exportData.body];
        xls.aoaToXLS(bodyWithHeaders, 'Zmiany');
        xls.download(fileName);
        resolve(null);
        break;
      }
      default:
        console.warn('Chosen export format is unsupported!');
        reject();
    }
  });

export const formatEmployeeScheduleExportDataJSON = rawData => (dispatch, getState) => {
  const { userLocations } = getState().reducer;
  return {
    first_name: rawData.employee.first_name,
    last_name: rawData.employee.last_name,
    shifts: rawData.employee.shifts.map(shift => {
      const { id, name } = shift.location.name
        ? shift.location
        : userLocations.find(location => location.id === shift.location.id);

      return { ...shift, location: { id, name } };
    }),
  };
};

/**
 * Given the format this action either directly downloads the file (like in the case of CSV) or returns a fileURLL
 * @param {string} format - one of CSV, PDF, XLS,
 * @param {Array<Object>} rawData - array of employee objects
 */
export const exportEmployeeScheduleData = (format, rawData) => dispatch =>
  new Promise((resolve, reject) => {
    const { from, to } = dispatch(getPayrollExportMetaData());
    const exportMetaData = { from, to };
    const employee = dispatch(formatEmployeeScheduleExportDataJSON(rawData));
    conn
      .exportEmployeeSchedule(format, { employee }, exportMetaData)
      .then(result => {
        resolve(result.data.downloadLink);
        downloadFileFromUrl(result.data.downloadLink);
      })
      .catch(err => reject(err));
  });

// -----------------------------------------------------------------------------
// Attendance export
// -----------------------------------------------------------------------------

export const formatAttendanceExportDataJSON = rawData => {
  const { relevantEmployees } = rawData;
  const { selectedColumns } = rawData.options;
  let rows = [];
  relevantEmployees.forEach(employee => {
    let shiftHours = [];
    let attendanceHours = [];
    let locationNames = [];

    const { shifts, attendances } = employee;
    shifts.forEach(shift => {
      shiftHours.push(shift.working_hours);
      const location = employee.locations?.find(l => l.id === shift.location.id);
      locationNames.push(location ? location.name : '');
    });
    attendances.forEach(attentance => {
      attendanceHours.push(attentance.hours);
    });
    shiftHours = shiftHours.join('\n');
    attendanceHours = attendanceHours.join('\n');
    locationNames = locationNames.join('\n');
    rows.push([getEmployeeNameShort(employee, 20), locationNames, shiftHours, attendanceHours, '', '']);
  });
  rows = rows.map(row => row.filter((a, i) => selectedColumns.includes(i)));
  return rows;
};

/**
 * Given the format this action either directly downloads the file (like in the case of CSV) or returns a fileURLL
 * @param {string} format - one of CSV, PDF, XLS,
 * @param {Array<Object>} rawData - array of employee objects
 */
export const exportAttendanceData = (format, rawData) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    switch (format) {
      case EXPORT_FILE_TYPES.PDF: {
        const { selectedColumns } = rawData.options;
        const { mainDateStore, currentCompany, scheduleLocationFilter } = getState().reducer;
        const locationObject = scheduleLocationFilter.length === 1 ? dispatch(getExportMetaData()).locationObject : {};
        const data = formatAttendanceExportDataJSON(rawData);
        const headers = AttendanceExportData.headers.filter((c, i) => selectedColumns.includes(i));
        const columnsWidth = AttendanceExportData.columnsWidth.filter((c, i) => selectedColumns.includes(i));
        const columnsStyle = AttendanceExportData.columnsStyle.filter((c, i) => selectedColumns.includes(i));
        conn
          .exportAttendances(
            format,
            { rows: data, headers, columnsWidth, columnsStyle },
            {
              from: mainDateStore.dateArray[0],
              to: mainDateStore.dateArray[0],
              location: locationObject,
              companyName: currentCompany.name,
            },
          )
          .then(result => {
            resolve(result.data.downloadLink);
          })
          .catch(err => reject(err));

        break;
      }
      default:
        break;
    }
  });

// -----------------------------------------------------------------------------
// Payroll export
// -----------------------------------------------------------------------------

export const formatPayrollExportDataAoA =
  (rawData, columns, absenceTypes, format = '') =>
  (dispatch, getState) => {
    const { userLocations, payrollSettings, mainDateStore } = getState().reducer;
    const { employee, relevantRows, options } = rawData;
    const { dateArray } = mainDateStore;
    const { mergeInDay, freeDays, availabilities } = options;
    const timeFormatType = payrollSettings.timeFormatSetting.type;

    let rows = [];
    relevantRows.forEach(row => {
      row.details.forEach(subRow => {
        // TODO: handle overtime collections in exports
        if (subRow.isOvertimeCollection) {
          return;
        }
        let start;
        let end;
        let startShift;
        let startAtt;
        let endShift;
        let endAtt;

        const isAbsenceWithHours = subRow.absence && (subRow.absence.absence_hours || subRow.working_hours);

        switch (payrollSettings.payoutSetting.type) {
          case 'shifts':
            startShift = subRow.start_timestamp ? subRow.start_timestamp.slice(11, 16) : '__:__';
            endShift = subRow.end_timestamp ? subRow.end_timestamp.slice(11, 16) : '__:__';
            startAtt = '';
            endAtt = '';
            start = subRow.start_timestamp ? subRow.start_timestamp.slice(11, 16) : '__:__';
            end = subRow.end_timestamp ? subRow.end_timestamp.slice(11, 16) : '__:__';
            break;
          case 'shifts_no_extra':
          case 'shifts_unless_fixed':
          default: {
            if (subRow.matching_shift && subRow.matching_shift.start_timestamp) {
              startShift = subRow.matching_shift.start_timestamp.slice(11, 16);
              endShift = subRow.matching_shift.end_timestamp.slice(11, 16);
            } else if (subRow.isAvailability) {
              startShift = subRow.start ? subRow.start.slice(11, 16) : '__:__';
              endShift = subRow.end ? subRow.end.slice(11, 16) : '__:__';
            } else {
              startShift = subRow.startShift ? subRow.startShift.slice(11, 16) : '__:__';
              endShift = subRow.endShift ? subRow.endShift.slice(11, 16) : '__:__';
            }
            startAtt = subRow.startAtt ? subRow.startAtt.slice(11, 16) : '__:__';
            endAtt = subRow.endAtt ? subRow.endAtt.slice(11, 16) : '__:__';
            start = subRow.start ? subRow.start.slice(11, 16) : '__:__';
            end = subRow.end ? subRow.end.slice(11, 16) : '__:__';
            break;
          }
        }

        if (isAbsenceWithHours) {
          startShift = subRow.absence.absence_hours?.slice(0, 5) || subRow.working_hours.slice(0, 5);
          endShift = subRow.absence.absence_hours?.slice(6, 11) || subRow.working_hours.slice(6, 11);
          if (format === 'PDF') {
            start = startShift;
            end = endShift;
          }
        }

        const scheduleCycleOvertime = employee.overtimeStats.overtime
          ? employee.overtimeStats.overtime.scheduleCycleOvertime
          : 0;

        const {
          date,
          timeWorked,
          timeWorkedForXls,
          nightTimeWorked,
          bonus_amount: bonusAmount,
          sumPaid,
          sumBreaks,
          timeWorkedReal,
          timePlanned,
          overtime,
          isAvailability,
          availability,
          isAbsence,
          absence,
          absence_time: absenceTime,
          absence_short_name: absenceShortName,
          startForXls,
          endForXls,
        } = subRow;

        let { jobTitle, wage } = subRow;

        if (jobTitle === 'Brak przypisanej zmiany') jobTitle = '';
        if (wage === 'Brak danych') wage = '';
        wage = isAvailability ? '' : wage;
        const location = isAvailability || isAbsence ? '' : getLocationNameForId(subRow.location.id, userLocations);
        const bonus = bonusAmount || 0;

        const r = {
          employee,
          referenceId: employee.reference_id,
          date,
          timeWorked: (timeWorkedForXls || timeWorkedForXls === 0) && format === 'XLS' ? timeWorkedForXls : timeWorked,
          nightTimeWorked,
          sumPaid,
          location,
          jobTitle,
          bonus,
          sumBreaks,
          start: startForXls === null && !isAbsenceWithHours ? '__:__' : start,
          end: endForXls === null && !isAbsenceWithHours ? '__:__' : end,
          startShift,
          startAtt,
          endShift,
          endAtt,
          sumOvertime50: (overtime || {}).overtime50,
          sumOvertime100: (overtime || {}).overtime100,
          scheduleCycleOvertime,
          wage,
          timeWorkedReal,
          timePlanned,
          isAvailability,
          availability,
          absence,
          absence_time: absenceTime,
          absence_short_name: absenceShortName,
        };

        const availabilityToAdd = availability && availabilities ? availability.type_name : '';
        rows.push({ ...r, availability: availabilityToAdd });
      });
    });

    if (mergeInDay) {
      rows = mergePerDay(rows).map(day => ({
        ...day,
        wage: listArrayItems(day.wages),
        jobTitle: listArrayItems(day.jobTitles),
        location: listArrayItems(day.locations),
      }));
    }

    if (freeDays) {
      rows = fillInEmptyDays(rows, dateArray, employee);
    }

    if (format === 'XLS') {
      rows = rows.map(r =>
        payrollLocationRowObjectToArray(
          r,
          timeFormatType,
          payrollSettings.payoutSetting.type,
          absenceTypes,
          availabilities,
        ),
      );
    } else {
      rows = rows.map(r => payrollRowObjectToArray(r, timeFormatType));
      rows = rows.map(row => {
        const requiredData = [];
        columns.forEach(column => {
          requiredData.push(row[column]);
        });
        return requiredData;
      });
    }
    return { rows };
  };

/**
 * Exports the payroll data
 * @param {string} format - THe format of the export in [CSV, PDF or PNG]
 * @param {Array<Object>} rawData - Raw data far payroll.
 * @param {bool} justFormat - if true then instead of sending request function will return request data
 */

export const exportPayrollData =
  (format, rawData, justFormat = false) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { from, to, companyName } = dispatch(getPayrollExportMetaData());
      const {
        payrollSettings,
        userPermissions,
        absences,
        currentUser: { user },
      } = getState().reducer;
      const { absencesTypes } = absences;
      const intercomData = { userId: user.id, companyId: user.company_id };
      const sortedAbsencesTypes = absencesTypes.sort((a, b) => (a.name > b.name ? 1 : -1));
      const { availabilities, absences: showAbsences } = rawData.options;
      let headers = [
        'Imie',
        'Nazwisko',
        'Data',
        'Lokalizacja',
        'Od',
        'Do',
        'Stanowisko',
        'Stawka',
        'Suma godzin',
        'Godziny nocne',
        'Bonus',
        'Realizacja / Plan',
        'Róznica',
        'Przerwy',
        'Do Wyplaty',
      ];

      switch (format) {
        case EXPORT_FILE_TYPES.XLS: {
          let XLSHeaders;
          if (payrollSettings.payoutSetting.type === 'ecp') XLSHeaders = payrollLocationECPFullHeaders;
          else if (payrollSettings.payoutSetting.type === 'shifts') XLSHeaders = payrollLocationScheduleFullHeaders;
          else XLSHeaders = payrollLocationShiftsUnlessFixedFullHeaders;

          const absenceHeaders = [
            ...absencesSummaryXLSHeaders,
            ...sortedAbsencesTypes.map(absenceType => absenceType.name),
          ];
          const columns = [0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
          const { employee } = rawData;
          const { rows } = dispatch(
            formatPayrollExportDataAoA(rawData, columns, showAbsences ? sortedAbsencesTypes : [], 'XLS', showAbsences),
          );
          const allHeaders = [
            ...XLSHeaders,
            ...(availabilities ? ['Dostępności'] : []),
            ...(showAbsences ? absenceHeaders : []),
          ];
          const fileName = `export_payroll_${employee.first_name}_${employee.last_name}_${from}-${to}.xls`;
          const data = {
            rows,
            employee,
            headers: allHeaders,
          };
          const formatting = getExportFormatting(
            data.headers,
            formattingHeaders,
            payrollSettings.timeFormatSetting.type,
          );
          const metaData = { from, to, companyName };
          if (justFormat) {
            resolve({ data, metaData });
            break;
          }
          const xls = new XLSExport();
          rows.unshift(data.headers);
          xls.aoaToXLS(rows, 'Karta pracownika', formatting);
          xls.download(fileName);
          intercomTrackEvent('PAYROLL_EXPORT_XLS', intercomData);
          resolve(null);
          break;
        }
        case EXPORT_FILE_TYPES.CSV: {
          const columns = [0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15];
          const { rows } = dispatch(formatPayrollExportDataAoA(rawData, columns));
          const body = rows.map(r => r.join(','));
          const { employee } = rawData;
          const csvFilename = `export_payroll_${employee.first_name}_${employee.last_name}_${from}-${to}.csv`;
          prepareCSVandDownload(headers, body, csvFilename);
          intercomTrackEvent('PAYROLL_EXPORT_CSV', intercomData);
          resolve(null);
          break;
        }
        case EXPORT_FILE_TYPES.PDF: {
          // Filter desired data out of generic payroll data
          const columns = [2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
          // Column widths to determine if we can display in portrait mode
          const colWidths = [10, 10, 4, 4, 10, 4, 6, 8, 8, 8, 6, 6, 6, 6, 6, 6, 6, 6, 6, 14];
          const { selectedColumns } = rawData.options;

          const { rows } = dispatch(formatPayrollExportDataAoA(rawData, columns, [], 'PDF'));

          const body = rows.map(row => row.filter((c, i) => selectedColumns.includes(i)));
          let width = 0;
          selectedColumns.forEach(column => {
            width += colWidths[column];
          });
          const orientation = width < 55 ? 'portrait' : 'landscape';
          const { permissions } = userPermissions;

          const filterColumnsPredicate = (c, i) =>
            selectedColumns.includes(i) &&
            (!payrollExportData.columnsPermission[i] || permissions.includes(payrollExportData.columnsPermission[i]));
          headers = payrollExportData.headers.filter(filterColumnsPredicate);
          const summaryHeaders = payrollExportData.summaryHeaders.filter(filterColumnsPredicate);
          const columnsWidth = payrollExportData.columnsWidth.filter(filterColumnsPredicate);
          const columnsStyle = payrollExportData.columnsStyle.filter(filterColumnsPredicate);
          const pdfSumHoursPlanned = rawData.relevantRows
            .flatMap(r => r.details)
            .reduce((sum, detail) => {
              if (detail.absence || detail.isOvertimeCollection) {
                return sum;
              }

              return sum + detail.timePlanned;
            }, 0);
          const roundedPdfSumHoursPlanned = roundToTwoSigDigits(pdfSumHoursPlanned / 60);

          // this hack is needed since rawData has already formatted values
          // to be changed during payroll refactor
          const sumHoursRealInMinutes =
            rawData.sumHoursReal.includes && rawData.sumHoursReal.includes('h')
              ? humanFormToMinutes(rawData.sumHoursReal)
              : parseFloat(rawData.sumHoursReal) * 60;

          const difference = parseMinutesToFormat(
            sumHoursRealInMinutes - pdfSumHoursPlanned,
            payrollSettings.timeFormatSetting.type,
          );
          const summary = [
            '',
            '',
            '',
            '',
            '',
            '',
            rawData.sumAbsences,
            rawData.sumHours,
            rawData.sumNightHours,
            rawData.sumBonuses.toFixed(2),
            `${rawData.sumHoursReal} / ${roundedPdfSumHoursPlanned}`,
            `${difference}`,
            rawData.sumOvertime50,
            rawData.sumOvertime100,
            rawData.sumedScheduleCycleOvertime,
            rawData.sumBreaks,
            rawData.sumPayout.toFixed(2),
            '',
            '',
            '',
          ].filter((c, i) => selectedColumns.includes(i));
          const data = {
            rows: body,
            employee: rawData.employee,
            headers,
            summaryHeaders,
            summary,
            columnsWidth,
            columnsStyle,
          };
          const metaData = { from, to, orientation, companyName };
          if (justFormat) {
            resolve({ data, metaData });
            break;
          }
          conn
            .exportPayroll(format, data, metaData)
            .then(result => {
              intercomTrackEvent('PAYROLL_EXPORT_PDF', intercomData);
              resolve(result.data.downloadLink);
            })
            .catch(err => reject(err));
          break;
        }
        default:
          break;
      }
    });

export const exportMultiplePayrollData = (format, rawData) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { payrollSettings } = getState().reducer;
    const files = rawData.employeeData.map(data =>
      dispatch(exportPayrollData(format, { ...data, options: rawData.options }, true)),
    );
    Promise.all(files).then(data => {
      if (format === 'PDF') {
        const exportData = data.map(d => d.data);
        conn.exportMultiplePayroll(format, exportData, data[0].metaData).then(result => {
          resolve(result.data.downloadLink);
        });
      } else {
        const exportData = data.map(d => {
          const formatting = getExportFormatting(
            d.data.headers,
            formattingHeaders,
            payrollSettings.timeFormatSetting.type,
          );
          return {
            data: [d.data.headers, ...d.data.rows],
            filename: `${removeLatinAndSpecial(`${d.data.employee.first_name}_${d.data.employee.last_name}`)}_${
              d.metaData.from
            }-${d.metaData.to}.xls`,
            sheetName: 'rozliczenie',
            formatting,
          };
        });
        conn
          .exportXLS({
            files: exportData,
            archiveName: 'employees_payroll_export.zip',
          })
          .then(result => {
            resolve(result.data.downloadLink);
          })
          .catch(err => reject(err));
      }
    });
  });

// -----------------------------------------------------------------------------
// Payroll location export
// -----------------------------------------------------------------------------

export const formatPayrollLocationExportDataAoABrief =
  (rawData, columns, availabilitiesTypes = [], round = true, exportFormat) =>
  (dispatch, getState) => {
    const { payrollSettings, mainDateStore, calendarData } = getState().reducer;
    const { relevantEmployeeRows } = rawData;
    const timeFormatType = payrollSettings.timeFormatSetting.type;

    if (exportFormat === 'XLS') {
      return relevantEmployeeRows.map(row =>
        getPayrollLocationBasicXlsRow(
          row,
          columns,
          availabilitiesTypes,
          timeFormatType,
          round,
          mainDateStore,
          calendarData.monthlyNorms,
        ),
      );
    }

    return relevantEmployeeRows.map(row =>
      payrollLocationRowObjectToArrayBrief(row, columns, availabilitiesTypes, timeFormatType, round),
    );
  };

const getTimeWorked = (timeWorked, timeWorkedForXls, subRow) => {
  if (!subRow.startShift && !subRow.matching_shift) {
    return 0;
  }

  return timeWorkedForXls || timeWorkedForXls === 0 ? timeWorkedForXls : timeWorked;
};

export const formatPayrollLocationExportDataAoA =
  (rawData, refId = false, options = {}, absenceTypes, exportType = 'default') =>
  (dispatch, getState) => {
    const { userLocations, payrollSettings, mainDateStore } = getState().reducer;
    const { relevantEmployeeRows } = rawData;
    const { mergeInDay, freeDays, availabilities, showAbsences } = options;
    const timeFormatType = payrollSettings.timeFormatSetting.type;
    let body = [];
    relevantEmployeeRows.forEach(row => {
      const { employee } = row;
      row.relevantRowInfo.details.forEach(subRow => {
        // TODO: handle overtime collections in exports
        if (subRow.isOvertimeCollection) {
          return;
        }
        let start;
        let end;
        let startShift;
        let startAtt;
        let endShift;
        let endAtt;

        const {
          jobTitle,
          date,
          sumPaid,
          timeWorked,
          nightTimeWorked,
          overtime,
          availability,
          timeWorkedReal,
          timePlanned,
          isAvailability,
          isAbsence,
          absence,
          absence_time: absenceTime,
          timeWorkedForXls,
          startForXls,
          endForXls,
          sumBreaks,
        } = subRow;

        const location =
          subRow.location && !isAvailability && !isAbsence
            ? getLocationNameForId(subRow.location.id, userLocations)
            : '';
        const wage = !subRow.isAvailability && (subRow.wage || subRow.wage === 0) ? subRow.wage : 0;
        const bonus = subRow.bonus_amount ? subRow.bonus_amount : 0;
        const referenceId = refId ? employee.reference_id : undefined;

        switch (payrollSettings.payoutSetting.type) {
          case 'shifts':
            startShift = subRow.start_timestamp ? subRow.start_timestamp.slice(11, 16) : '__:__';
            endShift = subRow.end_timestamp ? subRow.end_timestamp.slice(11, 16) : '__:__';
            startAtt = '';
            endAtt = '';
            start = subRow.start_timestamp ? subRow.start_timestamp.slice(11, 16) : '__:__';
            end = subRow.end_timestamp ? subRow.end_timestamp.slice(11, 16) : '__:__';
            break;
          case 'shifts_no_extra':
          case 'shifts_unless_fixed':
          default: {
            if (subRow.matching_shift && subRow.matching_shift.start_timestamp) {
              startShift = subRow.matching_shift.start_timestamp.slice(11, 16);
              endShift = subRow.matching_shift.end_timestamp.slice(11, 16);
            } else {
              startShift = subRow.startShift ? subRow.startShift.slice(11, 16) : '__:__';
              endShift = subRow.endShift ? subRow.endShift.slice(11, 16) : '__:__';
            }
            startAtt = subRow.startAtt ? subRow.startAtt.slice(11, 16) : '__:__';
            endAtt = subRow.endAtt ? subRow.endAtt.slice(11, 16) : '__:__';
            start = subRow.start && (subRow.startShift || subRow.matching_shift) ? subRow.start.slice(11, 16) : '__:__';
            end = subRow.end && (subRow.endShift || subRow.matching_shift) ? subRow.end.slice(11, 16) : '__:__';
            break;
          }
        }
        const adjustedTimeWorked = getTimeWorked(timeWorked, timeWorkedForXls, subRow);

        const scheduleCycleOvertime = employee.overtimeStats.overtime
          ? employee.overtimeStats.overtime.scheduleCycleOvertime
          : 0;

        const r = {
          start: startForXls === null ? '__:__' : start,
          end: endForXls === null ? '__:__' : end,
          startShift,
          endShift,
          startAtt,
          endAtt,
          employee,
          location,
          wage,
          bonus,
          referenceId,
          jobTitle,
          date,
          sumOvertime50: overtime.overtime50,
          sumOvertime100: overtime.overtime100,
          scheduleCycleOvertime,
          sumPaid,
          timeWorked: adjustedTimeWorked,
          nightTimeWorked,
          timePlanned,
          timeWorkedReal,
          isAvailability,
          absence,
          absence_time: absenceTime,
          sumBreaks,
        };

        const availabilityToAdd = availabilities && availability.type_name ? availability.type_name : '';

        body.push({ ...r, availability: availabilityToAdd });
      });
    });

    if (mergeInDay) {
      const singleDays = mergePerDay(body);
      body = payrollLocationMergeInDay(singleDays);
    }

    if (freeDays) {
      body = payrollLocationFreeDays(body, mainDateStore.dateArray);
    }

    if (exportType === 'comarch') return body;

    return body.map(r =>
      payrollLocationRowObjectToArray(
        r,
        timeFormatType,
        payrollSettings.payoutSetting.type,
        showAbsences ? absenceTypes : [],
        availabilities,
      ),
    );
  };

/**
 * Exports the location payroll data
 * @param {string} format - THe format of the export in [CSV, PDF or PNG]
 * @param {Array<Object>} rawData - Raw data far payroll.
 */
export const exportPayrollLocationData = (format, rawData) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const availabilitiesHeadersForPayrollExport = getAvailabilitiesHeadersForPayrollExport(
      rawData.relevantEmployeeRows,
    );
    const {
      payrollSettings,
      absences,
      currentUser: { user },
    } = getState().reducer;
    const intercomData = { userId: user.id, companyId: user.company_id };
    const sortedAbsencesTypes = absences.absencesTypes.sort((a, b) => (a.name > b.name ? 1 : -1));
    const absenceHeaders = [...absencesSummaryXLSHeaders, ...sortedAbsencesTypes.map(type => type.name)];
    const timeFormatType = payrollSettings.timeFormatSetting.type;
    const { from, to, companyName } = dispatch(getPayrollExportMetaData());
    const { version, mergeInDay, freeDays, availabilities, absences: showAbsences } = rawData.options;
    let fullHeaders;
    if (payrollSettings.payoutSetting.type === 'ecp') fullHeaders = payrollLocationECPFullHeaders;
    else if (payrollSettings.payoutSetting.type === 'shifts') fullHeaders = payrollLocationScheduleFullHeaders;
    else fullHeaders = payrollLocationShiftsUnlessFixedFullHeaders;
    switch (format) {
      case EXPORT_FILE_TYPES.XLS: {
        let headers;
        let body;
        if (version === 'details') {
          body = dispatch(
            formatPayrollLocationExportDataAoA(
              rawData,
              true,
              { mergeInDay, freeDays, availabilities, showAbsences },
              sortedAbsencesTypes,
            ),
          );
          const summary = createPayrollLocationExportSummary(body, timeFormatType, payrollSettings.payoutSetting.type);
          headers = [
            ...fullHeaders,
            ...(availabilities ? ['Dostępności'] : []),
            ...(showAbsences ? absenceHeaders : []),
          ];
          body.unshift(headers);
          body = [summary, []].concat(body);
        } else {
          headers = [...payrollLocationBasicXLSHeaders, ...availabilitiesHeadersForPayrollExport];
          body = dispatch(
            formatPayrollLocationExportDataAoABrief(
              rawData,
              [0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 20],
              availabilitiesHeadersForPayrollExport,
              true,
              format,
            ),
          );
          body.unshift(headers);
        }

        const formatting = getExportFormatting(headers, formattingHeaders, payrollSettings.timeFormatSetting.type);
        const fileName = `export_payroll_location_${from}-${to}.xls`;
        const xls = new XLSExport();

        xls.aoaToXLS(body, 'Wypłaty', formatting);
        xls.download(fileName);
        intercomTrackEvent('PAYROLL_LOCATION_EXPORT_XLS', intercomData);
        resolve(null);
        break;
      }
      case EXPORT_FILE_TYPES.CSV: {
        let headers;
        let body;
        if (rawData.options.version === 'details') {
          headers = fullHeaders;
          body = dispatch(formatPayrollLocationExportDataAoA(rawData, true)).map(it => it.slice(0, 25));
        } else {
          headers = payrollLocationBriefHeaders;
          body = dispatch(
            formatPayrollLocationExportDataAoABrief(rawData, [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15], [], false),
          );
        }

        const fileName = `export_payroll_location_${from}-${to}.csv`;

        prepareCSVandDownload(headers, body, fileName);
        // Null causes the export modal to hide after the download from above completes.
        intercomTrackEvent('PAYROLL_LOCATION_EXPORT_CSV', intercomData);
        resolve(null);
        break;
      }
      case EXPORT_FILE_TYPES.PDF: {
        const rows = dispatch(
          formatPayrollLocationExportDataAoABrief(rawData, [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15]),
        ).map(r => r.filter((c, i) => rawData.options.selectedColumns.includes(i)));
        const headers = (
          rawData.options.employeeContractor === 'contractor'
            ? payrollLocationExportData.contractorHeaders
            : payrollLocationExportData.headers
        ).filter((c, i) => rawData.options.selectedColumns.includes(i));
        const summaryHeaders = payrollLocationExportData.summaryHeaders.filter((c, i) =>
          rawData.options.selectedColumns.includes(i),
        );
        const columnsWidth = payrollLocationExportData.columnsWidth.filter((c, i) =>
          rawData.options.selectedColumns.includes(i),
        );
        const columnsStyle = payrollLocationExportData.columnsStyle.filter((c, i) =>
          rawData.options.selectedColumns.includes(i),
        );
        const summary = [
          '',
          '',
          '',
          parseMinutesToFormat(rawData.totalAbsences, timeFormatType),
          parseMinutesToFormat(rawData.totalHours, timeFormatType),
          parseMinutesToFormat(rawData.totalNightHours, timeFormatType),
          rawData.totalBonuses.toFixed(2),
          `${parseMinutesToFormat(rawData.totalHoursReal, timeFormatType)} / ${parseMinutesToFormat(
            rawData.totalHoursPlanned,
            timeFormatType,
          )}`,
          parseMinutesToFormat(rawData.totalHoursReal, timeFormatType) -
            parseMinutesToFormat(rawData.totalHoursPlanned, timeFormatType),
          parseMinutesToFormat(rawData.totalOvertime50, timeFormatType),
          parseMinutesToFormat(rawData.totalOvertime100, timeFormatType),
          rawData.totalPayout.toFixed(2),
          '',
          '',
          '',
        ].filter((c, i) => rawData.options.selectedColumns.includes(i));

        conn
          .exportPayrollLocation(
            format,
            {
              rows,
              headers,
              summaryHeaders,
              summary,
              columnsWidth,
              columnsStyle,
            },
            { from, to, companyName },
          )
          .then(result => {
            intercomTrackEvent('PAYROLL_LOCATION_EXPORT_PDF', intercomData);
            resolve(result.data.downloadLink);
          })
          .catch(err => reject(err));
        break;
      }
      case 'XMLEnova': {
        const { mainDateStore } = getState().reducer;
        const { relevantEmployeeRows } = rawData;
        const data = [];
        relevantEmployeeRows.forEach(row => {
          const { employee } = row;
          const { details } = row.relevantRowInfo;
          mainDateStore.dateArray.forEach(date => {
            const detail = details.find(d => d.date === date) || { free: true };
            if (
              payrollSettings.payoutSetting.type === 'shifts' ||
              detail.free ||
              employee.attendances.some(({ id }) => id === detail.id)
            ) {
              data.push({ ...detail, employee, date });
            }
          });
        });
        createEnovaXMLandDownload(data, `Enova_CzasPracy${moment(from).format('YYYY.MM')}.xml`);
        intercomTrackEvent('PAYROLL_LOCATION_EXPORT_XML_ENOVA', intercomData);
        resolve(null);
        break;
      }
      case 'XLSComarch': {
        const shiftsData = dispatch(formatPayrollLocationExportDataAoA(rawData, true, {}, [], 'comarch'));
        const shiftsBody = createComarchXlsShifts(shiftsData, payrollSettings.payoutSetting.type);

        const absencesFormattedData = dispatch(formatAbsencesExportDataAoA(rawData));
        const absencesBody = createComarchXlsAbsencesSheet(absencesFormattedData);

        const sheets = [
          {
            name: payrollSettings.payoutSetting.type === 'shifts' ? 'Plan_pracy' : 'Czas_przepracowany',
            data: shiftsBody,
          },
          {
            name: 'Nieobecności',
            data: absencesBody,
          },
        ];

        createComarchXLSandDownload(sheets, `payroll_${from}-${to}.xls`);
        intercomTrackEvent('PAYROLL_LOCATION_EXPORT_XLS_COMARCH', intercomData);
        resolve(null);
        break;
      }
      case 'XLSSage': {
        const { mainDateStore, userCustomTypes } = getState().reducer;
        const files = sageExport(rawData, mainDateStore, userCustomTypes, payrollSettings);
        conn
          .exportXLS({ files, archiveName: 'exportSage.zip' })
          .then(result => {
            intercomTrackEvent('PAYROLL_LOCATION_EXPORT_SAGE', intercomData);
            resolve(result.data.downloadLink);
          })
          .catch(err => reject(err));
        break;
      }
      case 'XLSR2Płatnik': {
        const { selectedEmployees } = getState().reducer.payrollUI;
        const sheets = r2platnikExport(rawData, absences.absencesTypes, selectedEmployees);
        const fileName = 'Ewidencja czasu pracy.xls';

        const xls = new XLSExport();
        xls.aoaToXlsMultipleSheets(sheets, null, 'biff8');
        xls.download(fileName);
        intercomTrackEvent('PAYROLL_LOCATION_EXPORT_XLS_R2PLATNIK', intercomData);
        resolve(null);
        break;
      }
      case 'XLSGSN': {
        const { calendarData, mainDateStore } = getState().reducer;
        const sheets = getGsnExportSheets(rawData, mainDateStore, calendarData.monthlyNorms, timeFormatType);
        const fileName = 'szablon_getsix.xlsx';

        const xls = new XLSExport();
        xls.aoaToXlsMultipleSheets(sheets, null, 'biff8');
        xls.download(fileName);
        intercomTrackEvent('PAYROLL_LOCATION_EXPORT_XLS_GSN', intercomData);
        resolve(null);
        break;
      }
      default:
        break;
    }
  });

// -----------------------------------------------------------------------------
// Employees exports
// -----------------------------------------------------------------------------

/**
 * Given the format this action either directly downloads the file (like in the case of CSV) or returns a fileURLL
 * @param {string} format - one of CSV, PDF, XLS,
 * @param {Array<Object>} rawData - array of employee objects
 */
export const exportEmployeesData = (format, rawData) => () =>
  new Promise(resolve => {
    const { employees, companyRoles, employmentConditions } = rawData;

    switch (format) {
      case EXPORT_FILE_TYPES.CSV: {
        const headers = [
          'Ref. ID',
          'Imię',
          'Nazwisko',
          'Manager',
          'Lokalizacje główne',
          'Lokalizacje pomocnicze',
          'Stanowiska',
          'E-mail',
          'Telefon',
          'PIN',
          'Alias',
          'Kod NFC',
          'Warunki zatrudnienia',
          'Tygodniowy wymiar czasu',
          'Dobowy wymiar czasu',
          'Początek okresu rozliczeniowego',
          'Długość okresu rozliczeniowego',
          'Zasady harmonogramowania',
          'Rola',
          'Uprawnienia',
        ];
        const body = employees.map(e => {
          const { main, supplementary } = divideLocationsIntoMainAndSupplementary(e);
          const userRole = e.role_id ?? e.role;
          const companyRole = companyRoles.find(role => role.id === userRole);
          const employmentCondition = employmentConditions.find(
            condition => condition.id === e.employment_conditions.template_id,
          );
          const employmentConditionName = employmentCondition
            ? replaceForbiddenCharsForCSV(employmentCondition.name)
            : 'Własny';

          return [
            replaceForbiddenCharsForCSV(e.reference_id),
            replaceForbiddenCharsForCSV(e.first_name),
            replaceForbiddenCharsForCSV(e.last_name),
            e.role === 'manager' ? 'TAK' : '',
            main.map(l => replaceForbiddenCharsForCSV(l.name)).join(' | '),
            supplementary.map(l => replaceForbiddenCharsForCSV(l.name)).join(' | '),
            e.terms.map(t => generateTermAndWageString(t)).join(' | '),
            replaceForbiddenCharsForCSV(e.email),
            e.phone,
            e.pin,
            replaceForbiddenCharsForCSV(e.alias),
            replaceForbiddenCharsForCSV(e.nfc_code),
            employmentConditionName,
            workingMinutesToHours(e.employment_conditions.weekly_working_minutes),
            workingMinutesToHours(e.employment_conditions.max_daily_working_minutes),
            e.employment_conditions.schedule_cycle ? getHiringDate(e.employment_conditions.schedule_cycle) : 'Brak',
            e.employment_conditions.schedule_cycle?.duration ?? 'Brak',
            e.employment_conditions.validate_working_rules ? 'TAK' : 'NIE',
            companyRole.name,
            companyRole.permissions.map(p => getReadablePermissionName(p)).join(' | '),
          ];
        });

        const fileName = 'pracownicy.csv';

        prepareCSVandDownload(headers, body, fileName);
        // Null causes the export modal to hide after the download from above completes.
        resolve(null);
        break;
      }
      case EXPORT_FILE_TYPES.QR_CODES: {
        const { options } = rawData;
        const { version } = options;
        let promise = null;
        const data = {
          employees: employees.map(e => ({
            id: e.id,
            first_name: e.first_name,
            last_name: e.last_name,
            pin: e.pin,
          })),
        };
        if (version === 'single') {
          promise = conn.exportEmployeesForQRCodes('PDF', data, {});
        } else {
          promise = conn.exportMultipleQRCodes('PDF', data, {});
        }

        promise
          .then(result => {
            resolve(result.data.downloadLink);
          })
          .catch(err => {
            console.error(err.response);
          });

        break;
      }
      default:
        break;
    }
  });

export const getReportsLocationsData = (rawData, locations, attendancesData) => {
  const selectedLocations = rawData.multipleLocationFilter.map(locationId =>
    locations.find(location => location.id === locationId),
  );
  const relevantLocations = selectedLocations.reduce(
    (prev, location) => ({
      ...prev,
      [location.id]: {
        locationName: location.name,
        sumHours: 0,
        sumHoursPlanned: 0,
        sumDiff: 0,
        sumCosts: 0,
        sumCostsPlanned: 0,
        totalAttendances: 0,
        totalLate: 0,
        totalAbsent: 0,
      },
    }),
    {},
  );

  return rawData.employeesToRender.reduce((prev, employee) => {
    selectedLocations.forEach(location => {
      let totalLate = 0;
      let totalAbsent = 0;
      let totalAttendances = 0;

      const relevantShifts = employee.shifts.filter(
        shift => shift.location.id === location.id && rawData.dateArray.includes(shift.date),
      );

      const relevantAttendances =
        attendancesData.attendances[employee.id]?.filter(
          attendance => attendance.location.id === location.id && rawData.dateArray.includes(attendance.date),
        ) || [];

      const shiftData = relevantShifts.reduce(
        (prevSum, shift) => {
          let wage = getMatchingIndividualWageForShift(shift, employee);
          if (!wage) {
            wage = 0;
          }
          const timeWorked = calculateDurationBetweenTimestamps(shift.end_timestamp, shift.start_timestamp);
          return {
            sumHoursPlanned: prevSum.sumHoursPlanned + timeWorked,
            sumCostsPlanned: prevSum.sumCostsPlanned + ((wage / 100) * timeWorked) / 60,
          };
        },
        { sumHoursPlanned: 0, sumCostsPlanned: 0 },
      );

      const attendanceData = relevantAttendances.reduce(
        (prevSum, attendance) => {
          let wage = 0;
          if (attendance.matching_shift) {
            wage = getMatchingIndividualWage(attendance, employee);
            if (!wage) {
              wage = 0;
            }
          }
          const timeWorked = attendance.end_timestamp
            ? calculateDurationBetweenTimestamps(attendance.end_timestamp, attendance.start_timestamp)
            : 0;

          return {
            sumHours: prevSum.sumHours + timeWorked,
            sumCosts: prevSum.sumCosts + ((wage / 100) * timeWorked) / 60,
          };
        },
        { sumHours: 0, sumCosts: 0 },
      );

      const sumDiff = attendanceData.sumHours - shiftData.sumHoursPlanned;

      rawData.dateArray.forEach(date => {
        const attendancesBlocksData = generateAttendancesDataForEmployee(
          employee,
          date,
          relevantShifts.filter(shift => shift.date === date),
          relevantAttendances.filter(attendance => attendance.date === date),
        );

        attendancesBlocksData.blocks.forEach(block => {
          switch (block.type.name) {
            case 'absence':
              totalAbsent++;
              break;
            case 'attedance_late_start':
              totalLate++;
              break;
            case 'present':
              totalAttendances++;
              break;
            default:
              break;
          }
        });
      });

      prev[location.id] = {
        locationName: prev[location.id].locationName,
        sumHours: prev[location.id].sumHours + attendanceData.sumHours,
        sumHoursPlanned: prev[location.id].sumHoursPlanned + shiftData.sumHoursPlanned,
        sumDiff: prev[location.id].sumDiff + sumDiff,
        sumCosts: prev[location.id].sumCosts + attendanceData.sumCosts,
        sumCostsPlanned: prev[location.id].sumCostsPlanned + shiftData.sumCostsPlanned,
        totalAttendances: prev[location.id].totalAttendances + totalAttendances,
        totalLate: prev[location.id].totalLate + totalLate,
        totalAbsent: prev[location.id].totalAbsent + totalAbsent,
      };
    });
    return prev;
  }, relevantLocations);
};

export const getReportsRows = locationsData =>
  Object.values(locationsData).map(locationData => {
    const {
      locationName,
      sumHours,
      sumHoursPlanned,
      sumDiff,
      sumCosts,
      sumCostsPlanned,
      totalAttendances,
      totalLate,
      totalAbsent,
    } = locationData;
    return [
      locationName,
      parseMinutesToFormat(sumHours),
      parseMinutesToFormat(sumHoursPlanned),
      parseMinutesToFormat(sumDiff),
      roundToTwoSigDigits(sumCosts),
      roundToTwoSigDigits(sumCostsPlanned),
      totalAttendances,
      totalLate,
      totalAbsent,
      `${totalAttendances ? Math.round((totalAttendances / (totalAttendances + totalAbsent)) * 1000) / 10 : 0}%`,
    ];
  });

export const getReportsLocationsSummaryData = locationsData => {
  const data = Object.values(locationsData).reduce((prev, locationData, index) => {
    if (index === 0) {
      return locationData;
    }
    return {
      locationName: 'Podsumowanie',
      sumHours: prev.sumHours + locationData.sumHours,
      sumHoursPlanned: prev.sumHoursPlanned + locationData.sumHoursPlanned,
      sumDiff: prev.sumDiff + locationData.sumDiff,
      sumCosts: prev.sumCosts + locationData.sumCosts,
      sumCostsPlanned: prev.sumCostsPlanned + locationData.sumCostsPlanned,
      totalAttendances: prev.totalAttendances + locationData.totalAttendances,
      totalLate: prev.totalLate + locationData.totalLate,
      totalAbsent: prev.totalAbsent + locationData.totalAbsent,
    };
  }, {});

  return [
    'Podsumowanie',
    parseMinutesToFormat(data.sumHours),
    parseMinutesToFormat(data.sumHoursPlanned),
    parseMinutesToFormat(data.sumDiff),
    roundToTwoSigDigits(data.sumCosts),
    roundToTwoSigDigits(data.sumCostsPlanned),
    data.totalAttendances,
    data.totalLate,
    data.totalAbsent,
    `${
      data.totalAttendances
        ? Math.round((data.totalAttendances / (data.totalAttendances + data.totalAbsent)) * 1000) / 10
        : 0
    }%`,
  ];
};

export const exportReportsData = (format, rawData) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { userLocations, attendances } = getState().reducer;
    const { attendancesData } = attendances;
    const { from, to, companyName } = dispatch(getPayrollExportMetaData());
    let { headers, summaryHeaders, columnsWidth, columnsStyle } = dayReportExportData;
    const { selectedColumns } = rawData.options;

    const locationsData = getReportsLocationsData(rawData, userLocations, attendancesData);
    let rows = getReportsRows(locationsData);

    switch (format) {
      case EXPORT_FILE_TYPES.XLS: {
        const body = rows;
        const fileName = from !== to ? `export_report_${from}_${to}.xls` : `export_day_report_${from}.xls`;
        const xls = new XLSExport();
        body.unshift(headers);
        xls.aoaToXLS(body, 'Raport dniowy');
        xls.download(fileName);
        resolve(null);
        break;
      }
      case EXPORT_FILE_TYPES.PDF: {
        rows = rows.map(row => row.filter((c, i) => selectedColumns.includes(i)));
        headers = headers.filter((c, i) => selectedColumns.includes(i));
        summaryHeaders = summaryHeaders.filter((c, i) => selectedColumns.includes(i));
        columnsWidth = columnsWidth.filter((c, i) => selectedColumns.includes(i));
        columnsStyle = columnsStyle.filter((c, i) => selectedColumns.includes(i));
        const summaryData = getReportsLocationsSummaryData(locationsData);

        const summary = summaryData.filter((c, i) => selectedColumns.includes(i));

        conn
          .exportPayrollLocation(
            format,
            {
              rows,
              headers,
              summaryHeaders,
              summary,
              columnsWidth,
              columnsStyle,
            },
            { from, to, companyName },
          )
          .then(result => {
            resolve(result.data.downloadLink);
          })
          .catch(err => reject(err));
        break;
      }
      default:
        break;
    }
  });

// -----------------------------------------------------------------------------
// New reports -exports
// -----------------------------------------------------------------------------

export const exportSpmhReportsData = (format, rawData) => (dispatch, getState) =>
  new Promise(resolve => {
    switch (format) {
      case EXPORT_FILE_TYPES.XLS: {
        const { userLocations, reports } = getState().reducer;
        const { start, end } = reports.dateStore.customDate;
        const fileName = start !== end ? `export_report_${start}_${end}.xls` : `export_day_report_${start}.xls`;
        const xls = new XLSExport();
        const sheets = getBonusSystemExportsSheets(rawData, userLocations);
        if (!sheets.length) throw new Error('no_data_to_export');
        xls.aoaToXlsMultipleSheets(sheets, bonusSystemFormattingHeaders, 'biff8', bonusSystemOmitRows);
        xls.download(fileName);
        resolve(null);
        break;
      }
      default:
        break;
    }
  });

// -----------------------------------------------------------------------------
// Main export function
// -----------------------------------------------------------------------------

/**
 * Exports the data based on the type provided in rawData. Returns a promise or null
 * @param {string} format
 * @param {Object} rawData
 * @returns {Promise}
 */
export const exportData = (format, rawData) => {
  switch (rawData.type) {
    case 'schedule':
      return exportScheduleData(format, rawData);
    case 'payroll':
      return exportPayrollData(format, rawData);
    case 'payroll-multiple':
      return exportMultiplePayrollData(format, rawData);
    case 'payrollLocation':
    case 'payrollLocation-full':
      return exportPayrollLocationData(format, rawData);
    case 'attendance':
      return exportAttendanceData(format, rawData);
    case 'employees':
      return exportEmployeesData(format, rawData);
    case 'reports':
      return exportReportsData(format, rawData);
    case 'recommendedSchedule':
      return exportRecommendedSchedule('XLS', rawData);
    default:
      console.error('Unkown type provided to export data');
      return null;
  }
};

/*
 * Function exporting single qr code for employee
 */
export const exportSingleQRCode = employee => () => {
  const data = {
    employees: [
      {
        id: employee.id,
        first_name: employee.first_name,
        last_name: employee.last_name,
        pin: employee.pin,
      },
    ],
  };

  conn
    .exportMultipleQRCodes('PDF', data, {})
    .then(response => {
      downloadFileFromUrl(response.data.downloadLink);
    })
    .catch(err => {
      console.error(err);
    });
};

export const clearScheduleImports = () => ({
  type: AT.CLEAR_SCHEDULE_IMPORTS,
});

export const clearBudgetImports = () => ({
  type: AT.CLEAR_BUDGET_IMPORTS,
});

export const clearBudgetTargetsImports = () => ({
  type: AT.CLEAR_BUDGET_TARGETS_IMPORTS,
});

export const clearBudgetMetricsImports = () => ({
  type: AT.CLEAR_BUDGET_METRICS_IMPORTS,
});

export const notifyAboutExportFail = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.GET_EXPORT_FAILED,
    payload: {},
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.getExportErrorTitle),
      description: intl.formatMessage(messages.getExportError),
    },
  });
};

export const exportSelectedEmployeesAsFile =
  (fileType, relevantEmployees, relevantSelectedEmployeesIds, options) => (dispatch, getState) => {
    const { reducer } = getState();
    const { companyRoles } = reducer.roles;
    const { employmentConditions } = reducer;
    let rawData = {
      employees: relevantEmployees.filter(employee => relevantSelectedEmployeesIds.includes(employee.id)),
      companyRoles,
      employmentConditions,
      type: 'employees',
    };
    if (options) {
      rawData = { options, ...rawData };
    }
    dispatch(exportData(fileType, rawData))
      .then(url => {
        if (url === null) {
          dispatch(hideModal());
        } else {
          downloadFileFromUrl(url);
          dispatch(hideModal());
        }
      })
      .catch(err => {
        console.error('Couldnt get requested export file', err);
        dispatch(hideModal());
        dispatch(notifyAboutExportFail());
      });
  };

export const getExportsConfig = () => (dispatch, getState, intl) => {
  const { payrollSettings, userPermissions } = getState().reducer;
  const { permissions } = userPermissions;
  const { type } = payrollSettings.payoutSetting;
  return getExports(showModal, type, permissions, intl);
};
