import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import Select from '@/components/common/inputs/MDSelect/Select';
import KadroModal from '@/components/common/KadroModal.jsx';
import { tradeShiftStatus } from '@/constants/intl.js';
import { mapTimestampsToShift } from '@/utils/dateHelper.js';
import { checkIfShiftOverlapsWithExistingShifts, getAllShiftsForUser } from '@/utils/schedule.helpers.ts';
import { availableEmployeesForShift } from '@/utils/userEmployeesHelpers';

import './approvalTradeShiftModal.scss';

const modalMessages = defineMessages({
  title: {
    id: 'tradeShift.approvalModal.title',
    defaultMessage: 'Potwierdź wymianę',
  },
  confirmText: {
    id: 'tradeShift.approvalModal.confirmText',
    defaultMessage: 'Wymień',
  },
  cancelText: {
    id: 'tradeShift.approvalModal.cancelText',
    defaultMessage: 'Odrzuć',
  },
  cancelModalTitle: {
    id: 'tradeShift.approvalModal.cancelModalTitle',
    defaultMessage: 'Odrzuć wymianę',
  },
  cancelModalBody: {
    id: 'tradeShift.approvalModal.cancelModalBody',
    defaultMessage: 'Czy na pewno chcesz odrzucić tę wymianę?',
  },
  overlapError: {
    id: 'tradeShift.approvalModal.overlapError',
    defaultMessage: 'Nie można przypisać zmiany, ponieważ nachodzi na wcześniej dodaną zmianę.',
  },
  missingEmployeeError: {
    id: 'tradeShift.approvalModal.missingEmployeeError',
    defaultMessage: 'Musisz wybrać pracownika do wymiany',
  },
});

class ApprovalTradeShiftModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onHide = this.onHide.bind(this);
    this.selectOtherEmployee = this.selectOtherEmployee.bind(this);
    this.createOpenShiftFromTradeShift = this.createOpenShiftFromTradeShift.bind(this);
    this.state = {
      showError: false,
      errorId: 'overlapError',
      indicatedCoworker: null,
      otherEmployee: '0',
    };
  }

  onHide() {
    this.setState({
      showError: false,
      errorId: 'overlapError',
      indicatedCoworker: null,
      otherEmployee: '0',
    });
    this.props.onHide();
  }

  onReject() {
    this.props.showConfirmModal({
      title: this.context.intl.formatMessage(modalMessages.cancelModalTitle, {}),
      description: this.context.intl.formatMessage(modalMessages.cancelModalBody, {}),
      confirmText: this.context.intl.formatMessage(modalMessages.cancelText, {}),
      confirmFunc: () => {
        this.props.changeTradeShift(this.props.modalObject.tradeShiftData.id, {
          status: 'rejected',
        });
        this.onHide();
      },
      confirmType: 'warning',
    });
  }

  onSubmit() {
    const employeeId = this.state.indicatedCoworker || this.state.otherEmployee;

    if (!this.state.indicatedCoworker && this.state.otherEmployee === '0') {
      this.setState({
        showError: true,
        errorId: 'missingEmployeeError',
      });
      return;
    }
    const allCurrentShifts = getAllShiftsForUser(this.props.userEmployees?.find(user => user.id === employeeId));
    const shiftOverlapsWithCurrentShifts = checkIfShiftOverlapsWithExistingShifts(
      mapTimestampsToShift(this.props.modalObject.tradeShiftData),
      allCurrentShifts,
    );
    if (shiftOverlapsWithCurrentShifts) {
      this.setState({
        showError: true,
        errorId: 'overlapError',
      });
      return;
    }

    this.props.closeTradeShift(this.props.modalObject.tradeShiftData, employeeId);
    this.onHide();
  }

  selectOtherEmployee(employeeId) {
    this.setState({
      indicatedCoworker: null,
      otherEmployee: employeeId,
    });
  }

  selectIndicatedCoworker(employeeId) {
    if (this.state.indicatedCoworker === employeeId) {
      this.setState({
        otherEmployee: '0',
        indicatedCoworker: null,
      });
    } else {
      this.setState({
        otherEmployee: '0',
        indicatedCoworker: employeeId,
      });
    }
  }

  async createOpenShiftFromTradeShift() {
    const { id, user, shift } = this.props.modalObject.tradeShiftData;

    await this.props.createOpenShiftFromTradeShift(id);
    this.props.deleteShiftRequest(user.id, shift.id, false);
    this.onHide();
  }

  render() {
    const errorClassNames = classnames('k-trade-shifts__error', {
      'k-trade-shifts__error--show': this.state.showError,
    });
    const tradeShift = this.props.modalObject.tradeShiftData;
    const indicatedCoworkersIds = tradeShift.indicated_coworkers.map(c => c.user.id.toString());
    const relevantEmployees = availableEmployeesForShift(
      this.props.userEmployees,
      tradeShift.date,
      tradeShift,
      tradeShift.location.id,
      tradeShift.job_title.id,
      this.props.contracts,
      false,
    ).filter(employee => !indicatedCoworkersIds.includes(employee.id));

    const jobTitle = {
      title: tradeShift.job_title && tradeShift.job_title.name ? tradeShift.job_title.name : '',
      color: tradeShift.job_title && tradeShift.job_title.color ? tradeShift.job_title.color : '#FF0',
    };

    const title = this.context.intl.formatMessage(modalMessages.title, {});
    const confirmText = this.context.intl.formatMessage(modalMessages.confirmText, {});
    const cancelText = this.context.intl.formatMessage(modalMessages.cancelText, {});

    const coworkerTableClassNames = classnames('k-trade-shifts__coworker-table', {
      'k-trade-shifts__coworker-table--disabled': this.state.otherEmployee !== '0',
      'k-trade-shifts__coworker-table--empty': !tradeShift.indicated_coworkers.length,
    });

    const modalContent = (
      <div className="k-trade-shifts__coworker">
        <div className="k-trade-shifts__detail">
          <div className="k-trade-shifts__detail-name">
            <FormattedMessage id="tradeShift.approvalModal.employee" defaultMessage="Pracownik" />
          </div>
          <div className="k-trade-shifts__detail-value">
            {tradeShift.user.first_name} {tradeShift.user.last_name}
          </div>
        </div>
        <div className="k-trade-shifts__detail">
          <div className="k-trade-shifts__detail-name">
            <FormattedMessage id="tradeShift.approvalModal.date" defaultMessage="Data" />
          </div>
          <div className="k-trade-shifts__detail-value">{tradeShift.date}</div>
        </div>
        <div className="k-trade-shifts__detail">
          <div className="k-trade-shifts__detail-name">
            <FormattedMessage id="tradeShift.approvalModal.workingHours" defaultMessage="Czas" />
          </div>
          <div className="k-trade-shifts__detail-value">{tradeShift.working_hours}</div>
        </div>
        <div className="k-trade-shifts__detail">
          <div className="k-trade-shifts__detail-name">
            <FormattedMessage id="tradeShift.approvalModal.location" defaultMessage="Lokalizacja" />
          </div>
          <div className="k-trade-shifts__detail-value">{tradeShift.location.name}</div>
        </div>
        <div className="k-trade-shifts__detail">
          <div className="k-trade-shifts__detail-name">
            <FormattedMessage id="tradeShift.approvalModal.jobTitle" defaultMessage="Stanowisko" />
          </div>
          <div className="k-trade-shifts__detail-value">
            <ColorLabel text={jobTitle.title} color={jobTitle.color} />
          </div>
        </div>
        <div className="k-trade-shifts__detail">
          <div className="k-trade-shifts__detail-name">
            <FormattedMessage id="tradeShift.approvalModal.comment" defaultMessage="Komentarz" />
          </div>
          <div className="k-trade-shifts__detail-value k-trade-shifts__detail-value--comment">{tradeShift.comment}</div>
        </div>
        <div className="k-trade-shifts__select-block">
          <FormattedMessage id="tradeShift.approvalModal.indicatedCoworkers" defaultMessage="Sugerowani pracownicy">
            {text => (
              <div>
                <h3>{text}</h3>
              </div>
            )}
          </FormattedMessage>
          <div className={coworkerTableClassNames}>
            {tradeShift.indicated_coworkers.length ? (
              tradeShift.indicated_coworkers
                .filter(coworker => {
                  const user = this.props.userEmployees.find(u => u.id === coworker.user.id);
                  if (!user || user.inactive) return false;
                  return true;
                })
                .map(coworker => {
                  const coworkerClassNames = classnames('k-trade-shifts__coworker-table-item', {
                    'k-trade-shifts__coworker-table-item--selected': this.state.indicatedCoworker === coworker.user.id,
                  });
                  const statusClassNames = classnames(
                    'k-trade-shifts__coworker-table-status',
                    `k-trade-shifts__coworker-table-status--${coworker.status}`,
                  );
                  return (
                    <div
                      className={coworkerClassNames}
                      key={coworker.id}
                      onClick={() => this.selectIndicatedCoworker(coworker.user.id)}
                      role="presentation"
                    >
                      <span className="k-trade-shifts__coworker-table-name">
                        {coworker.user.first_name} {coworker.user.last_name}
                      </span>
                      <span className={statusClassNames}>
                        {this.context.intl.formatMessage(tradeShiftStatus[coworker.status], {})}
                      </span>
                    </div>
                  );
                })
            ) : (
              <FormattedMessage
                id="tradeShift.approvalModal.indicatedCoworkersEmptyList"
                defaultMessage="Brak sugerowanych pracowników"
              />
            )}
          </div>
        </div>
        <div className="k-trade-shifts__select-block">
          <FormattedMessage id="tradeShift.approvalModal.otherEmployees" defaultMessage="Pozostali pracownicy">
            {text => (
              <div>
                <h3>{text}</h3>
              </div>
            )}
          </FormattedMessage>
          <p className="k-trade-shifts__other-employees-info">
            <FormattedMessage
              id="tradeShift.approvalModal.otherEmployeesInfo"
              defaultMessage="Jeżeli chcesz możesz wybrać innego pracownika niż sugerowani"
            />
          </p>
          <Select
            name="Pracownicy"
            id="Pracownicy"
            closeOnClick
            options={[{ id: '0', first_name: '-- Wybierz pracownika --', last_name: '' }, ...relevantEmployees].map(
              employee => ({
                value: employee.id,
                key: `${employee.first_name} ${employee.last_name}`,
              }),
            )}
            onChange={this.selectOtherEmployee}
            defaultValue={this.state.otherEmployee}
          />
        </div>
        {this.props.enableOpenShifts && (
          <div className="k-trade-shifts__open-shift-change">
            <span onClick={this.createOpenShiftFromTradeShift} role="presentation">
              <FormattedMessage
                id="tradeShift.approvalModal.createOpenShiftFromTradeShift"
                defaultMessage="Zamień w otwartą zmiane"
              />
            </span>
          </div>
        )}
        <div className={errorClassNames}>{this.context.intl.formatMessage(modalMessages[this.state.errorId], {})}</div>
      </div>
    );

    return (
      <KadroModal
        className="k-approvalTradeShiftModal"
        showModal={this.props.showModal}
        title={title}
        confirmText={confirmText}
        cancelText={cancelText}
        cancelBtnModifiers={['reverse-red']}
        onSubmit={this.onSubmit}
        onHide={this.onHide}
        onCancel={this.onReject}
      >
        {modalContent}
      </KadroModal>
    );
  }
}

ApprovalTradeShiftModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ApprovalTradeShiftModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    tradeShiftData: PropTypes.shape({
      id: PropTypes.string,
      user: PropTypes.shape({ id: PropTypes.string }),
      shift: PropTypes.shape({ id: PropTypes.string }),
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  onHide: PropTypes.func,
  closeTradeShift: PropTypes.func,
  changeTradeShift: PropTypes.func,
  createOpenShiftFromTradeShift: PropTypes.func,
  showConfirmModal: PropTypes.func,
  enableOpenShifts: PropTypes.bool,
  deleteShiftRequest: PropTypes.func,
};

export default ApprovalTradeShiftModal;
