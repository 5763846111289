import PropTypes from 'prop-types';

import Tooltip from '@/components/common/Tooltip/Tooltip.jsx';

import Button from './Basic/Button.jsx';

const PublishButton = props => {
  const content = (
    <div className="k-publishButton">
      <Button onClick={props.onClickFunc} modifiers="publish" disabled={props.number <= 0 || props.disabled}>
        <i className="material-icons k-publishButton__sendIcon">{props.icon || 'send'}</i>
        <span>{props.text}</span>
      </Button>
    </div>
  );

  return props.tooltip ? (
    <Tooltip content={props.tooltip} position="bottom" popoverStyle={{ width: '130px' }} xOffset={-20} yOffset={-10}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

PublishButton.propTypes = {
  onClickFunc: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  number: PropTypes.number,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
};

export default PublishButton;
