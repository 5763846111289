import PropTypes from 'prop-types';
import { useState } from 'react';

import MDTablePagination from '@/components/common/MDTable/TablePagination/MDTablePagination';

const ExportPagination = props => {
  const [activeSelect, setActiveSelect] = useState(false);
  const [pageNumber, setPageNumber] = useState(props.currentPage);

  const changeCurrentPage = newPage => {
    props.changeExportsHistoryData(newPage, props.numberOfItemsPerPage);
  };

  const handlePageOnBlur = () => {
    if (!(pageNumber >= 1 && pageNumber <= props.numberOfPages)) {
      setPageNumber(props.currentPage);
    } else if (pageNumber !== props.currentPage) {
      changeCurrentPage(pageNumber);
    }
  };

  const changeNumberOfItemsPerPage = event => {
    props.changeExportsHistoryData(1, event.target.value);
  };

  return (
    <MDTablePagination
      currentPage={props.currentPage}
      changeCurrentPage={changeCurrentPage}
      numberOfPages={props.numberOfPages}
      activeSelect={activeSelect}
      setActiveSelect={setActiveSelect}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      handlePageOnBlur={handlePageOnBlur}
      numberOfItemsPerPage={props.numberOfItemsPerPage}
      changeNumberOfItemsPerPage={changeNumberOfItemsPerPage}
    />
  );
};

ExportPagination.propTypes = {
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  numberOfItemsPerPage: PropTypes.number,
  changeExportsHistoryData: PropTypes.func,
};

export default ExportPagination;
