export type Modifiers = string | string[];

const getModifiers = (modifiers: Modifiers) => {
  if (!modifiers) return [];
  if (!Array.isArray(modifiers)) return modifiers.split(' ');
  return modifiers;
};

export const mapModifiers = (modifiers: Modifiers, prefix: string) =>
  getModifiers(modifiers).map(modifier => `${prefix}--${modifier}`);
