import {
  AddShiftButton,
  AttendanceAcceptOvertime,
  AttendanceAddButton,
  AttendanceDeleteButton,
  AttendanceEditButton,
  AttendanceRefreshButton,
  AttendancesSettingsButtonRedux,
} from '@/components/attendance/AttendanceButtonBar/Buttons';
import Button from '@/components/common/Basic/Button.jsx';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { messages } from './AttendanceManyDaysTableButtonBar.messages';
import { classes } from './AttendanceManyDaysTableButtonBar.utils';
import { AttendanceManyDaysColumnFilter } from './ColumnFilter/AttendanceManyDaysColumnFilter';
import { Props, useAttendanceManyDaysTableButtonBar } from './useAttendanceManyDaysTableButtonBar';

import './AttendanceManyDaysTableButtonBar.scss';

export const AttendanceManyDaysTableButtonBar = (props: Props) => {
  const {
    attendancesToCreate,
    createAttendance,
    disabled,
    intl,
    selectedAttendancesData,
    selectedData,
    selectedDataWithOvertime,
  } = useAttendanceManyDaysTableButtonBar(props);

  return (
    <div className={classes.container}>
      <div>
        <Button modifiers="orange small" onClick={createAttendance}>
          <i className="material-icons">add</i>
          <span>{intl.formatMessage(messages.createAttendance)}</span>
        </Button>
      </div>
      <div className={classes.right}>
        <div className={classes.actionsBtnContainer}>
          <AttendanceAcceptOvertime disabled={isEmptyArray(selectedDataWithOvertime)} data={selectedDataWithOvertime} />
          <AddShiftButton data={selectedAttendancesData} />
          <AttendanceAddButton disabled={disabled} data={attendancesToCreate} />
          <AttendanceEditButton disabled={disabled} data={selectedData} />
          <AttendanceDeleteButton disabled={disabled} data={selectedAttendancesData} />
          <AttendanceRefreshButton />
        </div>
        <AttendancesSettingsButtonRedux />
        <AttendanceManyDaysColumnFilter />
      </div>
    </div>
  );
};
