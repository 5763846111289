import { KebabMenu } from '@/components/common/Basic/KebabMenu/KebabMenu';

import { AbsenceTypeRow } from '../ManageAbsenceTypesView.types';
import { useAbsenceTypesOptionCell } from './useAbsenceTypesOptionCell';

type Props = {
  rowData?: AbsenceTypeRow;
};

export const AbsenceTypesOptionCell = ({ rowData }: Props) => {
  const { options } = useAbsenceTypesOptionCell(rowData);

  return <KebabMenu options={options} />;
};
