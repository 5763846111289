import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ModalHeader.scss';

const ModalHeader = ({
  title,
  options = [],
  onHide,
  withoutOptions = false,
  headerModifiers,
  withOverlayScrollContent,
}) => {
  const closeHeader = { icon: 'close', handleClick: onHide, testId: 'closeModal' };
  const headerOptions = [...options, closeHeader];
  const modifiers = headerModifiers || [];

  const modalHeaderClassNames = classnames(
    'mdKadroModalHeader',
    ...modifiers.map(modifier => `mdKadroModalHeader--${modifier}`),
    { 'mdKadroModalHeader--scrollContent': withOverlayScrollContent },
  );

  return (
    <div className={modalHeaderClassNames}>
      <div className="mdKadroModalHeader__title">{title}</div>
      {!withoutOptions && (
        <div className="mdKadroModalHeaderOptions">
          {headerOptions.map(option => (
            <span
              onClick={e => option.handleClick(e)}
              className="material-icons mdKadroModalHeaderOptions__icon"
              key={option.icon}
              role="presentation"
              data-test={option.testId}
            >
              {option.icon}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onHide: PropTypes.func.isRequired,
  withoutOptions: PropTypes.bool,
  headerModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ModalHeader;
