import moment from 'moment';

import { getAttendancesForEmployee } from '@/actions/attendances.js';
import { getScheduleShiftsForEmployee } from '@/actions/schedule/shifts.js';
import { GET_DASHBOARD_FAILURE, GET_DASHBOARD_SUCCESSFUL, SET_DASHBOARD_SORTING } from '@/constants/ActionTypes.js';

import { conn } from '.';

export const getDashboard = () => async dispatch => {
  try {
    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    const { data } = await conn.getDashboardForManager(timestamp);

    Object.keys(data.locationSummary).forEach(location => {
      const { numberOfAttendances, numberOfShifts } = data.locationSummary[location];
      data.locationSummary[location].frequency =
        numberOfShifts > 0 ? Math.round((numberOfAttendances / numberOfShifts) * 100) : 0;
    });

    dispatch({
      type: GET_DASHBOARD_SUCCESSFUL,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_DASHBOARD_FAILURE,
    });
  }
};

export const getDashboardDataForEmployee = (from, to) => async dispatch => {
  const today = moment().format('YYYY-MM-DD');
  const todayPlus30Days = moment().add(30, 'days').format('YYYY-MM-DD');
  dispatch(getAttendancesForEmployee(from, to));
  dispatch(getScheduleShiftsForEmployee(today, todayPlus30Days));
};

export const setSortingColumn = (tableName, columnName) => (dispatch, getState) => {
  const { sortColumn, sortDecreasing } = getState().reducer.dashboard[tableName];

  const defaultSortColumn = sortDecreasing ? '' : columnName;
  const newSortColumn = sortColumn !== columnName ? columnName : defaultSortColumn;
  const newSortDecreasing = sortColumn !== columnName ? false : !sortDecreasing;

  dispatch({
    type: SET_DASHBOARD_SORTING,
    payload: {
      [tableName]: {
        sortColumn: newSortColumn,
        sortDecreasing: newSortDecreasing,
      },
    },
  });
};
