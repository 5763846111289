import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import EmptyState from '@/components/emptyState/EmptyState';

const messages = defineMessages({
  emptyStateHeader: {
    id: 'attendanceManyDays.emptyTable.header',
    defaultMessage: 'Brak wyników do wyświetlenia',
  },
  emptyStateText: {
    id: 'attendanceManyDays.emptyTable.emptySearch',
    defaultMessage: 'Upewnij się, że wybrane zostały lokalizacja, stanowisko i warunki zatrudnienia',
  },
});

export const AttendanceManyDaysEmptyTable = memo(() => {
  const intl = useIntl();

  return (
    <EmptyState
      imgSrc="/img/scheduleCTA.png"
      emptyStateHeader={intl.formatMessage(messages.emptyStateHeader)}
      emptyStateText={intl.formatMessage(messages.emptyStateText)}
    />
  );
});

AttendanceManyDaysEmptyTable.displayName = 'AttendanceManyDaysEmptyTable';
