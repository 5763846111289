export const ADD_SHIFT_MODAL = 'ADD_SHIFT_MODAL';
export const EDIT_SHIFT_MODAL = 'EDIT_SHIFT_MODAL';

export const ADD_ABSENCE_PROPOSAL_MODAL = 'ADD_ABSENCE_PROPOSAL_MODAL';
export const ABSENCE_TYPE_MODAL = 'ABSENCE_TYPE_MODAL';
export const ABSENCE_ADD_MODAL = 'ABSENCE_ADD_MODAL';
export const ADD_SHIFT_AND_ABSENCE_MODAL = 'ADD_SHIFT_AND_ABSENCE_MODAL';
export const ABSENCE_HISTORY_MODAL = 'ABSENCE_HISTORY_MODAL';
export const ABSENCE_CALENDAR_MODAL = 'ABSENCE_CALENDAR_MODAL';

export const ADD_POSITION_SHIFT_MODAL = 'ADD_POSITION_SHIFT_MODAL';
export const ADD_TEMPLATES_SHIFT_MODAL = 'ADD_TEMPLATES_SHIFT_MODAL';

export const ADD_EVENT_MODAL = 'ADD_EVENT_MODAL';
export const EDIT_EVENT_MODAL = 'EDIT_EVENT_MODAL';
export const INFO_EVENT_MODAL = 'INFO_EVENT_MODAL';

export const EDIT_ATTENDANCE_MODAL = 'EDIT_ATTENDANCE_MODAL';
export const ALIGN_ATTENDANCES_MODAL = 'ALIGN_ATTENDANCES_MODAL';
export const ALIGN_ATTENDANCES_MODAL_WEEK = 'ALIGN_ATTENDANCES_MODAL_WEEK';
export const ADD_ATTENDANCE_MODAL = 'ADD_ATTENDANCE_MODAL';

export const OBJECT_HISTORY_MODAL = 'OBJECT_HISTORY_MODAL';

export const EMPLOYEE_MODAL = 'EMPLOYEE_MODAL';
export const EDIT_MASS_EMPLOYEES_MODAL = 'EDIT_MASS_EMPLOYEES_MODAL';
export const EDIT_MASS_EMPLOYEES_ADDITIONAL_OPTIONS_MODAL = 'EDIT_MASS_EMPLOYEES_ADDITIONAL_OPTIONS_MODAL';

export const ADD_TEMPLATE_MODAL = 'ADD_TEMPLATE_MODAL';
export const ADD_NEW_TEMPLATE_MODAL = 'ADD_NEW_TEMPLATE_MODAL';
export const ADD_TEMPLATE_FROM_EXISTING_MODAL = 'ADD_TEMPLATE_FROM_EXISTING_MODAL';

export const APP_IN_MAINTANANCE_MODAL = 'APP_IN_MAINTANANCE_MODAL';
export const INACTIVE_USER_MODAL = 'INACTIVE_USER_MODAL';

export const EXPORT_DAY_REPORT_MODAL = 'EXPORT_DAY_REPORT_MODAL';

export const EMPLOYMENT_CONDITIONS_MODAL = 'EMPLOYMENT_CONDITIONS_MODAL';
export const EMPLOYMENT_CONDITIONS_DELETE_MODAL = 'EMPLOYMENT_CONDITIONS_DELETE_MODAL';

export const ADD_EMPLOYEE_MODAL = 'ADD_EMPLOYEE_MODAL';

export const BUDGET_IMPORT_MODAL = 'BUDGET_IMPORT_MODAL';

export const LOAD_RECOMMENDED_SCHEDULE_MODAL = 'LOAD_RECOMMENDED_SCHEDULE_MODAL';
export const RECOMMENDED_SCHEDULE_IMPORT_MODAL = 'RECOMMENDED_SCHEDULE_IMPORT_MODAL';
export const RECOMMENDED_SCHEDULE_FROM_EXISTING_TEMPLATE = 'RECOMMENDED_SCHEDULE_FROM_EXISTING_TEMPLATE';
export const SCHEDULE_IMPORT_MODAL = 'SCHEDULE_IMPORT_MODAL';

export const SAVE_SCHEDULE_AS_TEMPLATE_MODAL = 'SAVE_SCHEDULE_AS_TEMPLATE_MODAL';

export const DELETE_SCHEDULE_ITEMS_MODAL = 'DELETE_SCHEDULE_ITEMS_MODAL';

export const EDIT_SHIFTS_AND_ATTENDANCES_MODAL = 'EDIT_SHIFTS_AND_ATTENDANCES_MODAL';

export const CONFLICTS_MODAL = 'CONFLICTS_MODAL';

export const CONFIRM_MODAL = 'CONFIRM_MODAL';

export const ACCEPT_OVERTIME_MODAL = 'ACCEPT_OVERTIME_MODAL';

export const MANAGER_NOTE_MODAL = 'MANAGER_NOTE_MODAL';

export const CUSTOM_NOTIFICATION_MODAL = 'CUSTOM_NOTIFICATION_MODAL';

export const AUTO_GENERATE_SCHEDULE_MODAL = 'AUTO_GENERATE_SCHEDULE_MODAL';
export const AUTO_GENERATE_STEPS_MODAL = 'AUTO_GENERATE_STEPS_MODAL';

export const ROLE_MODAL = 'ROLE_MODAL';
export const ROLE_DELETE_MODAL = 'ROLE_DELETE_MODAL';

export const ADD_DEVICE_MODAL = 'ADD_DEVICE_MODAL';
export const SETTINGS_DEVICE_MODAL = 'SETTINGS_DEVICE_MODAL';

export const REPORTS_FILTERS_MODAL = 'REPORTS_FILTERS_MODAL';

export const NEW_CHAT_MODAL = 'NEW_CHAT_MODAL';

export const EMPLOYEE_SHIFT_MODAL = 'EMPLOYEE_SHIFT_MODAL';

export const QUICK_PLANNING_WARNING_MODAL = 'QUICK_PLANNING_WARNING_MODAL';

export const SHORTCUTS_MODAL = 'SHORTCUTS_MODAL';

export const SHIFTS_AND_ATTENDANCES_IMPORT_MODAL = 'SHIFTS_AND_ATTENDANCES_IMPORT_MODAL';

export const PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL = 'PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL';

export const NEW_EXPORT_MODAL = 'NEW_EXPORT_MODAL';

export const NEW_PAYROLL_EXPORT_MODAL = 'NEW_PAYROLL_EXPORT_MODAL';

export const PREVIEW_MODAL = 'PREVIEW_MODAL';

export const IMPORT_SHIFT_AND_ATTENDANCE_ERROR_MODAL = 'IMPORT_SHIFT_AND_ATTENDANCE_ERROR_MODAL';

export const BUDGET_TARGET_IMPORT_MODAL = 'BUDGET_TARGET_IMPORT_MODAL';

export const VIDEO_TUTORIAL_MODAL = 'VIDEO_TUTORIAL_MODAL';

export const GENERATE_QR_CODE_MODAL = 'GENERATE_QR_CODE_MODAL';

export const LOAD_BUDGET_TARGET_MODAL = 'LOAD_BUDGET_TARGET_MODAL';

export const ADD_OR_EDIT_BUDGET_METRIC_MODAL = 'ADD_OR_EDIT_BUDGET_MODAL';

export const EDIT_BILLING_DATA_MODAL = 'EDIT_BILLING_DATA_MODAL';

export const BUDGET_METRICS_IMPORT_MODAL = 'BUDGET_METRICS_IMPORT_MODAL';

export const PAYROLL_LOCATION_FILTERS_MODAL = 'PAYROLL_LOCATION_FILTERS_MODAL';

export const PHOTO_PREVIEW_MODAL = 'PHOTO_PREVIEW_MODAL';

export const MASS_EMPLOYEES_ADD_MODAL = 'MASS_EMPLOYEES_ADD_MODAL';

export const EXPORT_PREVIEW_MODAL = 'EXPORT_PREVIEW_MODAL';

export const NO_LOCATIONS_MODAL = 'NO_LOCATIONS_MODAL';

export const DELETE_RELEASED_EMPLOYEES_DATA_MODAL = 'DELETE_RELEASED_EMPLOYEES_DATA_MODAL';

export const CHOOSE_PAYROLL_SUMMARY_VALUES_MODAL = 'CHOOSE_PAYROLL_SUMMARY_VALUES_MODAL';
export const ATTENDANCE_MAP_MODAL = 'ATTENDANCE_MAP_MODAL';

export const PROFILE_DETAILS_MODAL = 'PROFILE_DETAILS_MODAL';
