import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { deleteAttendances } from '@/actions/attendances';
import Button from '@/components/common/Basic/Button';
import { BUTTON_BAR_TOOLTIP_OFFSET } from '@/components/common/Basic/ButtonBar/ButtonBar.utils';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { useAppDispatch } from '@/redux-store';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { SubRowType } from '../../../AttendanceManyDaysView/Table/AttendanceManyDaysTable.types';
import { messages } from '../../AttendanceButtonBar.messages';

type Props = {
  data: SubRowType['attendanceObject'][];
  disabled: boolean;
};

export const AttendanceDeleteButton = ({ data, disabled }: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const attendanceData = useMemo(() => data.filter(({ id }) => Boolean(id)), [data]);

  const onClick = useCallback(() => {
    dispatch(deleteAttendances(attendanceData));
  }, [attendanceData, dispatch]);

  return (
    <Tooltip content={intl.formatMessage(messages.delete)} yOffset={BUTTON_BAR_TOOLTIP_OFFSET}>
      <Button disabled={disabled || isEmptyArray(attendanceData)} onClick={onClick} modifiers="reverse-blue onlyIcon">
        <i className="material-icons">delete</i>
      </Button>
    </Tooltip>
  );
};
