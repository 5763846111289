import { connect } from 'react-redux';

import { getPayrollViewData } from '@/actions/payroll/payroll.js';

import PayrollTable from './PayrollTable';

const mapStateToProps = state => ({
  data: state.reducer.payroll.data,
  visibleColumns: state.reducer.payroll.visibleColumns,
  timeFormatType: state.reducer.payrollSettings.timeFormatSetting.type,
  payoutSettingType: state.reducer.payrollSettings.payoutSetting.type,
  selectedEmployee: state.reducer.singleEmployeeFilter,
   userPermissions: state.reducer.userPermissions,
   companySettings: state.reducer.currentCompany.settings,
  payrollApprovalEnabled: state.reducer.currentCompany.settings.payroll_approval_enabled,
  payrollLoanedEmployees: state.reducer.payrollLoanedEmployees.data,
});

const mapDispatchToProps = {
    getPayrollViewData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollTable);
