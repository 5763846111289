import { CellContext } from '@tanstack/react-table';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import AttendanceTimeSpan from '@/components/attendance/AttendanceTimeSpan/AttendanceTimeSpan';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { ATTENDANCE_ABSENCE, ATTENDANCE_WITHOUT_SHIFT } from '@/constants/attendanceDetailTypes';
import { ATTENDANCE_ABSENCE_COLOR } from '@/constants/colors';
import { HOUR_PLACEHOLDER } from '@/constants/strings';
import { ATTENDANCE_TOOLTIP_OFFSET_Y, getNoAttendanceTooltip } from '@/utils/attendance/attendance.utils';

import { AttendanceRowItem } from '../../AttendanceManyDaysTable.types';
import { useEditAttendanceDisabled } from '../useEditAttendanceDisabled';

import './TimeCell.scss';

export type TimeCellProps = CellContext<TableRow<AttendanceRowItem>, unknown>;

const Component = memo(({ cell, column }: TimeCellProps) => {
  const { attendanceObject, attendanceWithShift, employeeObject, location, startTimeShift, endTimeShift, type } =
    cell.row.original;
  const intl = useIntl();
  const { isAttendanceEditDisabled, isShiftEditDisabled } = useEditAttendanceDisabled({
    attendanceObject,
    employeeObject,
    location,
  });
  const time = column.id === 'start' ? startTimeShift : endTimeShift;
  const isShiftWithoutAttendance = type === ATTENDANCE_ABSENCE;

  return (
    <div className="k-attendanceTimeCell">
      {isShiftWithoutAttendance ? (
        <Tooltip content={getNoAttendanceTooltip(intl)} yOffset={ATTENDANCE_TOOLTIP_OFFSET_Y}>
          <span style={{ color: ATTENDANCE_ABSENCE_COLOR }}>{HOUR_PLACEHOLDER}</span>
        </Tooltip>
      ) : (
        <AttendanceTimeSpan
          type={column.id}
          attendance={attendanceObject}
          employee={employeeObject}
          isAttendanceEditingBlocked={isAttendanceEditDisabled}
          isShiftEditingBlocked={isShiftEditDisabled}
          showPhotoPreview={false}
          blocks={
            !attendanceWithShift && [
              {
                startTimestamp: attendanceObject.start_timestamp,
                endTimestamp: attendanceObject.end_timestamp,
                type: { name: ATTENDANCE_WITHOUT_SHIFT },
              },
            ]
          }
        />
      )}
      <div>/</div>
      {attendanceWithShift || isShiftWithoutAttendance ? time : HOUR_PLACEHOLDER}
    </div>
  );
});

Component.displayName = 'TimeCellComponent';

export const AttendanceTimeCell = memo((props: TimeCellProps) => {
  if ('subRows' in props.row.original) return null;

  return <Component {...props} />;
});

AttendanceTimeCell.displayName = 'TimeCell';
