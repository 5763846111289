import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Popover from '@/components/common/Popover/Popover.jsx';
import { useCurrentAttendanceAndBreakDuration } from '@/components/dashboard/DashboardTimeTracker/useCurrentAttendanceAndBreakDuration.ts';
import { useDashboardTimeTracker } from '@/components/dashboard/DashboardTimeTracker/useDashboardTimeTracker.ts';
import { useMediaQuery } from '@/hooks';
import { useAppSelector } from '@/redux-store';
import { selectCurrentUser } from '@/redux-store/currentUser/currentUser.selectors.ts';
import { selectRcpData } from '@/redux-store/rcp/rcp.selectors.ts';
import { selectLocationSettings } from '@/redux-store/settings/locationSettings/locationSettings.selectors.ts';
import { sortShiftsByStartTimestamp } from '@/utils/dateHelper.js';

import { messages } from './DashboardTimeTracker.messages.ts';
import { DashboardTimeTrackerContent } from './DashboardTimeTrackerContent.tsx';

import './DashboardTimeTracker.scss';

const BUTTON_MODIFIERS = ['teeny', 'high', 'reverse-orange'];

export const DashboardTimeTracker = () => {
  const { isAtLeastBigDesktop } = useMediaQuery();
  const intl = useIntl();
  const [showPopover, setShowPopover] = useState(false);
  const rcpData = useAppSelector(selectRcpData);
  const locationSettings = useAppSelector(selectLocationSettings);
  const currentUser = useAppSelector(selectCurrentUser);
  const { nextShift, employee, relevantLocations, previousShift } = useDashboardTimeTracker();
  const isOwner = currentUser?.role === 'owner';
  const filteredRcpData = rcpData.filter(
    data => locationSettings[data.location_id]?.allow_employee_attendace_open_close,
  );
  const currentAttendance = filteredRcpData.length > 0 && sortShiftsByStartTimestamp(filteredRcpData)[0];

  const { currentAttendanceDurationFormatted, currentBreak } = useCurrentAttendanceAndBreakDuration(currentAttendance);

  const buttonModifiers = useMemo(
    () => [
      ...BUTTON_MODIFIERS,
      currentAttendance && !currentBreak ? 'orange-text' : '',
      currentBreak ? 'reverse-gray' : '',
    ],
    [currentAttendance, currentBreak],
  );

  if (isOwner || relevantLocations?.length === 0) {
    return null;
  }

  return (
    <Popover
      content={
        <DashboardTimeTrackerContent
          nextShift={nextShift}
          employee={employee}
          relevantLocations={relevantLocations}
          currentAttendance={currentAttendance}
          locationSettings={locationSettings}
          previousShift={previousShift}
          hidePopover={() => setShowPopover(false)}
        />
      }
      isControlled
      isVisible={showPopover}
      showShadow={false}
      noArrow
      hideOnBlur
      addUpdateListeners
      popoverStyle={{ width: isAtLeastBigDesktop ? '432px' : '396px', minHeight: '220px' }}
    >
      <div className="dashboardTimeTracker">
        <Button modifiers={buttonModifiers} onClick={() => setShowPopover(true)}>
          <i className="material-symbols-outlined">{currentBreak ? 'timer_pause' : 'timer_play'}</i>
          {currentAttendance ? currentAttendanceDurationFormatted : intl.formatMessage(messages.registerTime)}
        </Button>
      </div>
    </Popover>
  );
};
