import { ColumnDef, ExpandedState, Row, SortingState } from '@tanstack/react-table';

import { InputEvent } from '@/hooks/useInputChange/useInputChange.types';

export enum ColumnStaticAccessorEnum {
  CHECKBOX = 'checkbox',
  OPTION = 'option',
}

export enum FilteringFnEnum {
  SUB_ROWS = 'SUB_ROWS',
}

export enum SortingFnEnum {
  CURRENCY = 'CURRENCY',
  DATE_TIME = 'datetime',
  LABELS = 'LABELS',
  LOCALE_IGNORE_CASE = 'LOCALE_IGNORE_CASE',
  LOCATIONS = 'LOCATIONS',
  NUMBER = 'NUMBER',
  PAYROLL_STATUS = 'PAYROLL_STATUS',
  PAYROLL_LOCATION_STATUS = 'PAYROLL_LOCATION_STATUS',
}

export type TableRow<T extends object> = {
  subRows?: TableRow<T>[];
} & T;

export type TableTab = {
  color: string;
  id: string;
  label: string;
  lightColor: string;
  numberOfItems: number;
  onSelect: () => void;
};

export type TableColumnType<T extends object> = ColumnDef<TableRow<T>> & {
  accessorKey: string;
  centerColumn?: boolean;
  customSize?: boolean;
  cannotBeHidden?: boolean;
  showOnlyForMobile?: boolean;
  sticky?: TABLE_COLUMN_STICKY_ENUM;
  withoutVerticalPadding?: boolean;
};

export type TableStyles = {
  hasNoHover?: boolean;
  hasHeaderTextWrapping?: boolean;
  hasDensePadding?: boolean;
  hasLightShadow?: boolean;
};

export type MDTableProps<T extends object, FooterT extends object = object> = {
  activeTab?: TableTab['id'];
  changeCurrentPage?: (newPage: number) => void;
  changeNumberOfItemsPerPage?: (e: InputEvent) => void;
  columns: Array<TableColumnType<any>>;
  currentPage?: number;
  customOnSelectHandler?: (row: Row<TableRow<T>>, rows: Row<TableRow<T>>[]) => void;
  data: T[];
  defaultSortByColumn?: TODO;
  enableRowSelection?: boolean | ((row: Row<T>) => boolean);
  evenlyColumns?: boolean;
  expandedRows?: ExpandedState;
  footerData?: FooterT[];
  manualFiltering?: boolean;
  numberOfItemsPerPage?: number;
  numberOfPages?: number;
  onExpand?: (state: ExpandedState) => void;
  onSort?: (state: SortingState) => void;
  onRowClick?: (row: Row<TableRow<T>>) => void;
  options?: JSX.Element;
  tableEmpty?: React.ReactNode;
  tableOptions?: JSX.Element;
  tableStyles?: TableStyles;
  tabs?: TableTab[];
  withPagination?: boolean;
  withSearch?: boolean;
};

export enum TABLE_COLUMN_STICKY_ENUM {
  RIGHT = 'right',
  LEFT = 'left',
}

export type ColumnsWidths = {
  columnWidth?: number;
  forceEvenlyColumnWidth?: number; // if there is only few columns selected, there will have the same width
};
