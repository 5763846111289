import { FormattedAttendance } from 'kadro-helpers/lib/types';
import moment from 'moment';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { endAttendance } from '@/actions/attendances.js';
import Button from '@/components/common/Basic/Button.jsx';
import {
  CANCEL_MODIFIERS,
  CONFIRM_MODIFIERS,
} from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTracker.helpers.ts';
import { useAppDispatch } from '@/redux-store';
import { parseMinutesToHumanForm } from '@/utils/dateHelper.js';

import { messages } from './DashboardTimeTracker.messages.ts';

type Props = {
  currentAttendance: FormattedAttendance;
  showAttendanceState: () => void;
};

export const DashboardTimeTrackerContentSummary = ({ currentAttendance, showAttendanceState }: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const breaksDuration = useMemo(
    () =>
      currentAttendance?.breaks?.reduce((acc, br) => {
        const endTimestamp = br.end_timestamp || moment();
        return acc + moment(endTimestamp).diff(br.start_timestamp, 'seconds');
      }, 0),
    [currentAttendance.breaks],
  );
  const attendanceDuration = moment().diff(currentAttendance.start_timestamp, 'seconds');
  const breaksDurationMinutes = Math.round(breaksDuration / 60);
  const attendanceDurationMinutes = Math.round(attendanceDuration / 60);

  const handleEndAttendance = async () => {
    await dispatch(endAttendance(currentAttendance.id));
    showAttendanceState();
  };

  return (
    <>
      <div className="dashboardTimeTracker__summaryItems">
        <div className="dashboardTimeTracker__summaryItem">
          <div className="dashboardTimeTracker__summaryLabel">{intl.formatMessage(messages.startHour)}</div>
          <div className="dashboardTimeTracker__summaryValue">
            {moment(currentAttendance.start_timestamp).format('HH:mm')}
          </div>
        </div>
        <div className="dashboardTimeTracker__summaryItem">
          <div className="dashboardTimeTracker__summaryLabel">{intl.formatMessage(messages.endHour)}</div>
          <div className="dashboardTimeTracker__summaryValue">{moment().format('HH:mm')}</div>
        </div>
        <div className="dashboardTimeTracker__summaryItem">
          <div className="dashboardTimeTracker__summaryLabel">{intl.formatMessage(messages.breakTime)}</div>
          <div className="dashboardTimeTracker__summaryValue">{parseMinutesToHumanForm(breaksDurationMinutes)}</div>
        </div>
        <div className="dashboardTimeTracker__summaryItem">
          <div className="dashboardTimeTracker__summaryLabel">{intl.formatMessage(messages.totalTime)}</div>
          <div className="dashboardTimeTracker__summaryValue">{parseMinutesToHumanForm(attendanceDurationMinutes)}</div>
        </div>
      </div>
      <div className="dashboardTimeTracker__bottomButtons">
        <Button modifiers={CANCEL_MODIFIERS} onClick={showAttendanceState}>
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button modifiers={CONFIRM_MODIFIERS} onClick={handleEndAttendance}>
          {intl.formatMessage(messages.end)}
        </Button>
      </div>
    </>
  );
};
