import { z } from 'zod';

export const settingsSchema = z.object({
  version: z.number(),
  dateMode: z.number(),
  selectedLocation: z.union([
    z.string(),
    z.object({
      id: z.string(),
      name: z.string(),
      color: z.string().optional(),
      slug: z.string().optional(),
      pin: z.string().optional(),
      city: z.string().optional(),
      address: z.string().optional(),
      postal_code: z.string().nullable().optional(),
      lat: z.number().optional(),
      lng: z.number().optional(),
      additional_information: z.string().nullable().optional(),
      company_id: z.string().optional(),
      opening_hours: z
        .object({
          monday: z.string().nullable().optional(),
          tuesday: z.string().nullable().optional(),
          wednesday: z.string().nullable().optional(),
          thursday: z.string().nullable().optional(),
          friday: z.string().nullable().optional(),
          saturday: z.string().nullable().optional(),
          sunday: z.string().nullable().optional(),
        })
        .optional(),
    }),
  ]),
  employeeSorting: z.number(),
  selectedGroups: z.array(z.string()),
  selectedJobtitles: z.array(
    z.object({
      id: z.string(),
      color: z.string(),
      title: z.string(),
      factor: z.string(),
      company_id: z.string(),
      hourly_wage: z.number(),
      deleted_at: z.string().nullable(),
      referenceId: z.string().nullable(),
    }),
  ),
  selectedJobtitlesGrouped: z.array(
    z.object({
      id: z.string(),
      color: z.string(),
      title: z.string(),
      factor: z.string(),
      company_id: z.string(),
      hourly_wage: z.number(),
      deleted_at: z.string().nullable(),
      referenceId: z.string().nullable(),
    }),
  ),
  selectedLocations: z.array(z.union([z.string(), z.object({ id: z.string() })])),
  scheduleSelectedLocations: z.array(z.string()),
  selectedEmploymentConditions: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      weekly_working_minutes: z.number().optional(),
      max_daily_working_minutes: z.number().optional(),
      validate_working_rules: z.boolean().optional(),
      schedule_cycle: z
        .union([
          z.object({
            month: z.number(),
            year: z.number(),
            duration: z.number(),
          }),
          z.number(),
        ])
        .nullable()
        .optional(),
      availability_limits: z.array(z.any()).optional(),
      show_absences: z.boolean().optional(),
      elastic_work_day: z.boolean().optional(),
      is_medical: z.boolean().optional(),
      allowed_to_work_at_night_hours: z.boolean().optional(),
    }),
  ),
  selectedEmployee: z.object({
    id: z.string(),
    reference_id: z.string().nullable().optional(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().optional(),
    phone: z.string().optional(),
    pin: z.string().optional(),
    invited: z.boolean().optional(),
    locations: z
      .array(
        z.object({
          id: z.string(),
          color: z.string().optional(),
          name: z.string(),
          slug: z.string().optional(),
          pin: z.string().optional(),
          city: z.string().optional(),
          address: z.string().optional(),
          postal_code: z.string().nullable().optional(),
          lat: z.number().optional(),
          lng: z.number().optional(),
          additional_information: z.string().nullable().optional(),
          company_id: z.string().optional(),
          opening_hours: z
            .object({
              monday: z.string().nullable().optional(),
              tuesday: z.string().nullable().optional(),
              wednesday: z.string().nullable().optional(),
              thursday: z.string().nullable().optional(),
              friday: z.string().nullable().optional(),
              saturday: z.string().nullable().optional(),
              sunday: z.string().nullable().optional(),
            })
            .optional(),
        }),
      )
      .optional(),
    supplementary_locations_ids: z.array(z.any()).optional(),
    inactive: z.boolean().optional(),
    role: z.string().optional(),
    company_id: z.string().optional(),
    created_at: z.string().optional(),
    updated_at: z.string().nullable().optional(),
    avatar: z.string().nullable().optional(),
    deleted: z.boolean().optional(),
    phone_prefix: z.string().optional(),
    oauth_provider: z.string().nullable().optional(),
    terms: z
      .array(
        z.object({
          id: z.string(),
          employee_id: z.string(),
          job_title: z.object({
            id: z.string(),
            color: z.string().optional(),
            title: z.string(),
            factor: z.string().optional(),
            company_id: z.string().optional(),
            hourly_wage: z.number().optional(),
            deleted_at: z.string().nullable().optional(),
            referenceId: z.string().nullable().optional(),
          }),
          has_individual_hourly_wage: z.boolean().optional(),
          individual_hourly_wage: z.number().nullable().optional(),
        }),
      )
      .optional(),
    contracts: z.array(z.any()).optional(),
    alias: z.string().nullable().optional(),
    nfc_code: z.string().nullable().optional(),
    settings: z
      .object({
        language: z.string().optional(),
      })
      .optional(),
    employment_conditions: z
      .object({
        user_id: z.string().optional(),
        weekly_working_minutes: z.number().optional(),
        max_daily_working_minutes: z.number().optional(),
        schedule_cycle: z
          .object({
            year: z.number().optional(),
            month: z.number().optional(),
            duration: z.number().optional(),
          })
          .optional(),
        validate_working_rules: z.boolean().optional(),
        template_id: z.string().optional(),
        elastic_work_day: z.boolean().optional(),
        show_absences: z.boolean().optional(),
        hire_date: z.string().nullable().optional(),
        release_date: z.string().nullable().optional(),
        is_medical: z.boolean().optional(),
        allowed_to_work_at_night_hours: z.boolean().optional(),
      })
      .optional(),
    role_id: z.string().optional(),
    shifts: z.array(z.any()).optional(),
    availability_blocks: z.array(z.any()).optional(),
    shifts_for_other_locations: z.array(z.any()).optional(),
    overtimeStats: z.object({}).optional(),
    name: z.string(),
  }),
  payrollLocationVisibleColumns: z.array(
    z.object({
      id: z.number(),
      title: z.object({
        id: z.string(),
        defaultMessage: z.string(),
      }),
      columns: z.array(z.number()),
    }),
  ),
  payrollVisibleColumns: z.array(
    z.object({
      id: z.number(),
      title: z.object({
        id: z.string(),
        defaultMessage: z.string(),
      }),
      columns: z.array(z.number()),
    }),
  ),
  employeesTableVisibleColumns: z.array(
    z.object({
      key: z.string(),
      id: z.string(),
      defaultMessage: z.string(),
    }),
  ),
  absencesTable: z.object({
    currentPage: z.number(),
    numberOfItemsPerPage: z.number(),
    numberOfItems: z.number(),
  }),
  absenceLimitsUsageTable: z.object({
    currentPage: z.number(),
    numberOfItemsPerPage: z.number(),
    numberOfItems: z.number(),
  }),
  newPayrollVisibleColumns: z.array(
    z.object({
      accessorKey: z.string(),
      cannotBeHidden: z.boolean().optional(),
      shouldBeHiddenForColumnFilter: z.boolean().optional(),
      width: z.number(),
      sticky: z.string().optional(),
      id: z.union([z.number(), z.string()]),
      title: z.object({
        id: z.string(),
        defaultMessage: z.string(),
      }),
      filterTitle: z.object({
        id: z.string(),
        defaultMessage: z.string(),
      }),
      restrictions: z.array(z.string()),
      permissions: z.array(z.any()),
    }),
  ),
  newPayrollLocationVisibleColumns: z.array(
    z.object({
      accessorKey: z.string(),
      cannotBeHidden: z.boolean().optional(),
      shouldBeHiddenForColumnFilter: z.boolean().optional(),
      width: z.number(),
      sticky: z.string().optional(),
      sortingKey: z.string().optional(),
      id: z.union([z.number(), z.string()]),
      title: z.object({
        id: z.string(),
        defaultMessage: z.string(),
      }),
      filterTitle: z.object({
        id: z.string(),
        defaultMessage: z.string(),
      }),
      restrictions: z.array(z.string()),
      permissions: z.array(z.any()),
    }),
  ),
  newPayrollSummaryValues: z.array(
    z.object({
      id: z.string(),
      active: z.boolean(),
      restrictions: z.array(z.string()).optional(),
    }),
  ),
  attendanceVisibleColumns: z.object({
    locationName: z.boolean().optional(),
    statusFormatted: z.boolean().optional(),
    position: z.boolean().optional(),
    start: z.boolean().optional(),
    end: z.boolean().optional(),
    breaksFormatted: z.boolean().optional(),
    durationFormatted: z.boolean().optional(),
    alerts: z.boolean().optional(),
  }),
  attendanceNotExpandedRows: z.array(z.string()),
});
