import { connect } from 'react-redux';

import EmployeeDashboard from '@/components/dashboard/employee/EmployeeDashboard.jsx';
import { selectUserEventsArray } from '@/redux-store/events';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
  userEmployees: state.reducer.userEmployees,
  userEvents: selectUserEventsArray(state),
  mainDateStore: state.reducer.mainDateStore,
  locationSettings: state.reducer.settings.locationSettings,
  attendances: state.reducer.attendances.attendancesData,
});

const EmployeeDashboardContainer = connect(mapStateToProps)(EmployeeDashboard);

export default EmployeeDashboardContainer;
