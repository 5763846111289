import cn from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { BUDGET_INFO_HIDE } from '@/constants/Restrictions';
import { useAppSelector } from '@/redux-store';
import { selectIsEmployeeLoanedForLocation } from '@/redux-store/scheduleState/locations';
import { roundToTwoSigDigits } from '@/utils/baseHelpers';
import { parseMinutesToFormat } from '@/utils/dateHelper.js';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers';

import ScheduleTableRowTitleDropArea from './ScheduleTableRowTitleDropArea/ScheduleTableRowTitleDropArea.redux';
import { ScheduleTableRowTitleKPIcon } from './ScheduleTableRowTitleKPIcon/ScheduleTableRowTitleKPIcon';
import ScheduleTableRowTitlePopover from './ScheduleTableRowTitlePopover/ScheduleTableRowTitlePopover.redux';

import './ScheduleTableRowTitle.scss';

const dragStart = (e, employeeId, locationId) => {
  e.dataTransfer.setData('employeeId', employeeId);
  e.dataTransfer.setData(locationId, 'true');
};

const ScheduleTableRowTitle = ({
  firstName,
  lastName,
  rearrangingEmployeesOrder,
  employeeId,
  locationId,
  workedHoursForSelectedPeriod,
  norm,
  balance,
  totalWage,
  showScheduleCycleBalance,
  onRowClick,
  budgetEnabled,
  hoursBalanceEnabled,
  userPermissions,
}) => {
  const isLoanedEmployee = useAppSelector(selectIsEmployeeLoanedForLocation(locationId, employeeId));
  const employeeName = useMemo(
    () => getEmployeeNameShort({ first_name: firstName, last_name: lastName }, 17),
    [firstName, lastName],
  );
  const isBudgetRestricted = userPermissions && userPermissions.restrictions.includes(BUDGET_INFO_HIDE);
  const showBudget = !isBudgetRestricted && budgetEnabled;
  const formattedWorkedHours =
    workedHoursForSelectedPeriod === undefined ? '-' : parseMinutesToFormat(workedHoursForSelectedPeriod * 60);
  const formattedNormHours = norm === undefined ? '-' : parseMinutesToFormat(norm * 60);
  const formattedBalanceHours = balance === undefined ? '-' : parseMinutesToFormat(balance * 60);
  const formattedTotalWage = roundToTwoSigDigits(totalWage);
  const className = cn('k-scheduleTableRowTitle', {
    'k-scheduleTableRowTitle--draggable': rearrangingEmployeesOrder,
  });

  return (
    <td
      className={className}
      draggable={rearrangingEmployeesOrder}
      onDragStart={e => dragStart(e, employeeId, locationId)}
      onClick={e => {
        onRowClick(locationId, employeeId, { ctrl: e.ctrlKey || e.metaKey, shift: e.shiftKey });
      }}
      role="presentation"
    >
      <div className="k-scheduleTableRowTitle__cell">
        <div className="k-scheduleTableRowTitle__leftSpacing" />
        <div className="k-scheduleTableRowTitle__content">
          <div className="k-scheduleTableRowTitle__title">{employeeName}</div>
          <div>
            {showScheduleCycleBalance && hoursBalanceEnabled && !isLoanedEmployee && (
              <div className="k-scheduleTableRowTitle__balance">
                {`${formattedWorkedHours}/${formattedNormHours} | ${formattedBalanceHours}`}
              </div>
            )}
            {hoursBalanceEnabled && !showScheduleCycleBalance && !isLoanedEmployee && (
              <div className="k-scheduleTableRowTitle__hours">{formattedWorkedHours} </div>
            )}
            {showBudget && formattedTotalWage > 0.0 && !isLoanedEmployee && (
              <div className="k-scheduleTableRowTitle__wage">{`${formattedTotalWage} PLN`}</div>
            )}
          </div>
          {rearrangingEmployeesOrder && <i className="material-icons rowDragIcon">swap_vert</i>}
          {!isLoanedEmployee && (
            <>
              <ScheduleTableRowTitleDropArea employeeId={employeeId} locationId={locationId} />
              <ScheduleTableRowTitleKPIcon employeeId={employeeId} />
              <ScheduleTableRowTitlePopover employeeId={employeeId} />
            </>
          )}
        </div>
      </div>
    </td>
  );
};

ScheduleTableRowTitle.propTypes = {
  hoursBalanceEnabled: PropTypes.bool,
  employeeId: PropTypes.string,
  locationId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  rearrangingEmployeesOrder: PropTypes.bool,
  workedHoursForSelectedPeriod: PropTypes.number,
  norm: PropTypes.number,
  balance: PropTypes.number,
  totalWage: PropTypes.number,
  showScheduleCycleBalance: PropTypes.bool,
  onRowClick: PropTypes.func,
  budgetEnabled: PropTypes.bool,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ScheduleTableRowTitle;
