import { ShiftTimeBlock } from '@/types/shifts.types.ts';

export const getAllShiftsForUser = (user?: TODO) => [
  ...(user?.shifts || []),
  ...(user?.shifts_for_other_locations || []),
];

export const checkIfShiftsOverlap = (shiftA: ShiftTimeBlock, shiftB: ShiftTimeBlock) =>
  shiftA.start_timestamp < shiftB.end_timestamp && shiftA.end_timestamp > shiftB.start_timestamp;

export const checkIfShiftOverlapsWithExistingShifts = (newShift: ShiftTimeBlock, existingShifts: ShiftTimeBlock[]) =>
  existingShifts.some(existingShift => checkIfShiftsOverlap(newShift, existingShift));
