import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { MANAGER_ROLE_HIDE } from '@/constants/Restrictions';
import { permissionsLangs, ROLES } from '@/constants/Roles';
import {
  getJobTitleIdsFromContracts,
  getRelevantContractForDate,
  getRelevantContractsForMultipleDates,
} from '@/utils/contracts';
import { getFromToFromDateStore } from '@/utils/dateHelper';

import { mergeArrayById, sortByKey } from './arrayHelpers.js';
import { checkIfShiftIsIncludedInHourlyRange, checkShiftOverlap } from './schedulerHelpers';

export function getHourFromTimestamp(ts) {
  if (ts === null) {
    return '__:__';
  }
  const hr = ts.split(' ');
  const splitHr = hr.length === 1 ? hr[0] : hr[1];
  return splitHr.slice(0, 5);
}

/**
 * Maps hours as well as date to attendance
 * @param {array} attendances
 */
export function mapHoursToAttendance(attendances) {
  return attendances.map(attendance => ({
    ...attendance,
    hours: `${getHourFromTimestamp(attendance.start_timestamp)}-${getHourFromTimestamp(attendance.end_timestamp)}`,
    date: attendance.start_timestamp === null ? '' : attendance.start_timestamp.split(' ')[0],
  }));
}

export function mapAdditionalInfoToAttendance(attendance) {
  return {
    ...attendance,
    hours: `${getHourFromTimestamp(attendance.start_timestamp)}-${getHourFromTimestamp(attendance.end_timestamp)}`,
    date: attendance.start_timestamp === null ? '' : attendance.start_timestamp.split(' ')[0],
  };
}

const arrayIntoMap = arr => arr.reduce((obj, current) => ({ ...obj, [current.id]: current }), {});

export const extractShiftsFromReqeustData = pulledData => {
  const result = {};
  pulledData.forEach(employee => {
    const employeeData = {};
    employeeData.shifts = arrayIntoMap([
      ...employee.shifts.map(s => ({ ...s })),
      ...employee.shifts_for_other_locations.map(s => ({ ...s })),
    ]);
    employeeData.availability_blocks = arrayIntoMap(employee.availability_blocks.map(s => ({ ...s })));
    result[employee.id] = employeeData;
  });
  return result;
};

/**
 * Maps Shifts pulled from /schedule to userEmployees
 * TODO: Refactor?
 *
 * @export
 * @param {array} pulledEmployees - array of Employee objects
 * @param {array} oldEmployees - array of Employee objects
 * @returns
 */
export function mapNewShiftsToEmployees(pulledEmployees, oldEmployees) {
  if (!pulledEmployees) {
    return oldEmployees;
  }
  const newEmployees = pulledEmployees
    .filter(p => p.shifts.length || p.availability_blocks.length || p.shifts_for_other_locations.length)
    .map(p => {
      // Do not check if exists -> this should break.
      const foundEmployee = oldEmployees.find(ol => ol.id === p.id);
      if (!foundEmployee) {
        return p;
      }

      // For CODENAMES
      const { first_name: firstName, last_name: lastName } = foundEmployee;
      p.first_name = firstName;
      p.last_name = lastName;

      p.shifts = mergeArrayById(mergeArrayById(foundEmployee.shifts, p.shifts), p.shifts_for_other_locations);
      p.shifts = sortByKey(p.shifts, 'id');
      // TODO: Remove as this is currently handled by the backend
      // p.shifts = p.shifts.map(s => mapTimestampsToShift(s));

      p.availability_blocks = mergeArrayById(foundEmployee.availability_blocks, p.availability_blocks);
      p.availability_blocks = sortByKey(p.availability_blocks, 'id');
      // We can do this since pulledEmployee from schedule does not have attendances to merge from.
      // We also added this here, because it caused disappearing attendances in /attendances
      // Probably because the new attendances are returned quicker.
      return {
        ...foundEmployee,
        ...p,
      };
    });
  const updatedEmployees = oldEmployees.map(o => {
    const [toBeUpdated] = newEmployees.filter(p => p.id === o.id);
    if (!toBeUpdated) {
      return o;
    }
    const updatedEmp = { ...o, ...toBeUpdated };
    return updatedEmp;
    // return stripEmployee(updatedEmp);
  });
  return updatedEmployees;
}

export function mapNewScheduleShiftsToEmployeesForEmployee(shifts, oldEmployees) {
  if (!shifts.length) {
    return oldEmployees;
  }

  const shiftsByEmployees = shifts.reduce((acc, shift) => {
    if (!acc[shift.employee.id]) {
      acc[shift.employee.id] = [];
    }
    acc[shift.employee.id].push(shift);
    return acc;
  }, {});

  const newEmployees = oldEmployees.map(employee => {
    const newShifts = shiftsByEmployees[employee.id];

    if (!newShifts) {
      return employee;
    }

    return {
      ...employee,
      shifts: newShifts,
    };
  });

  return newEmployees;
}

export function mapNewShiftsToEmployeesForEmployee(pulledEmployees, oldEmployees) {
  if (!pulledEmployees) {
    return oldEmployees;
  }
  const newEmployees = pulledEmployees.map(p => {
    // Do not check if exists -> this should break.
    const foundEmployee = oldEmployees.find(ol => ol.id === p.id);
    if (!foundEmployee) {
      return p;
    }

    const result = { ...foundEmployee };

    const [requestLocation] = p.locations;

    if (!foundEmployee.locations.find(l => l.id === requestLocation.id)) {
      result.locations = [...foundEmployee.locations, ...p.locations];
    } else {
      result.locations = [...foundEmployee.locations];
    }

    result.locations = result.locations.map(l => {
      if (l.id === requestLocation.id && p.is_supplementary_location_for_employee) {
        return { ...l, isSupplementary: true };
      }
      return l;
    });

    if (!p.shifts.length && !p.availability_blocks.length && !p.shifts_for_other_locations.length) {
      return result;
    }

    result.shifts = mergeArrayById(foundEmployee.shifts, p.shifts);
    result.shifts = sortByKey(result.shifts, 'id');

    result.shifts_for_other_locations = mergeArrayById(
      foundEmployee.shifts_for_other_locations,
      p.shifts_for_other_locations,
    );
    result.shifts_for_other_locations = sortByKey(result.shifts_for_other_locations, 'id');

    result.availability_blocks = mergeArrayById(foundEmployee.availability_blocks, p.availability_blocks);
    result.availability_blocks = sortByKey(result.availability_blocks, 'id');
    result.attendances = p.attendances;
    return result;
  });

  const updatedEmployees = oldEmployees.map(o => {
    const [toBeUpdated] = newEmployees.filter(p => p.id === o.id);
    if (!toBeUpdated) {
      return o;
    }
    const updatedEmp = { ...o, ...toBeUpdated };
    return updatedEmp;
    // return stripEmployee(updatedEmp);
  });

  newEmployees.forEach(newEmployee => {
    const present = updatedEmployees.find(e => e.id === newEmployee.id);
    if (!present) updatedEmployees.push(newEmployee);
  });

  return updatedEmployees;
}

export const mergeNewAttendances = (userAttendances, pulledAttendances) => {
  const mergedAttendances = [];
  let attendances = pulledAttendances.slice();

  userAttendances.forEach(attendance => {
    let { images } = attendance;
    let pulledAttendance = {};
    const pulledAttendanceIndex = attendances.findIndex(a => a.id === attendance.id);
    if (~pulledAttendanceIndex) {
      pulledAttendance = attendances[pulledAttendanceIndex];
      attendances = [...attendances.slice(0, pulledAttendanceIndex), ...attendances.slice(pulledAttendanceIndex + 1)];
      images = pulledAttendance.images && pulledAttendance.images.length ? pulledAttendance.images : attendance.images;
    }

    mergedAttendances.push({
      ...attendance,
      ...pulledAttendance,
      images,
    });
  });
  return [...mergedAttendances, ...attendances];
};

/**
 * Adds .attendances to every employee object in userEmployees.
 * @param {Array} pulledData
 * @param {Array} userEmployees - current userEmployees
 */
export function mapNewAttendancesToEmployeesFromPayroll(pulledData, userEmployees) {
  if (!pulledData.location_payroll || (pulledData.location_payroll && !pulledData.location_payroll.length)) {
    return userEmployees;
  }
  return userEmployees.map(employee => {
    const foundData = pulledData.location_payroll.find(r => r.employee.id === employee.id);

    if (foundData) {
      return {
        ...employee,
        attendances: mergeNewAttendances(employee.attendances, mapHoursToAttendance(foundData.attendances)),
        availability_blocks: employee.availability_blocks,
      };
    }

    return employee;
  });
}

export function getUnloggedAttendances(attendances) {
  return attendances.filter(attendance => !attendance.end_timestamp);
}

/**
 * Returns employee name string based on last name length
 * @param {object} employee - object with first and last name
 * @param {string} dateMode - string with dateMode
 *
 * @return {string} employee name
 */
export function getEmployeeName(employee, dateMode) {
  const nameLength = (employee.first_name + employee.last_name).length;
  if (nameLength < 12 || dateMode === 'day') {
    return `${employee.first_name} ${employee.last_name}`;
  }
  if (employee.last_name.length <= 7) {
    return `${employee.first_name[0]}. ${employee.last_name}`;
  }
  return `${employee.first_name[0]}. ${employee.last_name.slice(0, 6)}...`;
}

/**
 * Returns employee name string that has given length
 * @param {object} employee - object with first and last name
 * @param {number} length - length of the returned string
 *
 * @return {string} employee name
 */
export function getEmployeeNameShort(employee, length) {
  const firstName = employee.first_name;
  const lastName = employee.last_name;

  if (!firstName || !lastName) return '';

  let result = `${firstName} ${lastName}`;
  if (result.length > length) {
    if (lastName === ' ') {
      result = `${result.slice(0, length - 3)}...`;
    } else {
      result = `${firstName.slice(0, 1)}. ${lastName}`;
    }
  }
  if (result.length > length) {
    result = `${result.slice(0, length - 3)}...`;
  }
  return result;
}

/**
 * Returns a stripped down employee object, to save bandwith on transport.
 * (Usage for now in export)
 * @param {object} employee - object with first and last name etc.
 *
 * @return {object} employee
 */
export const stripEmployeeForTransit = employee => ({
  first_name: employee.first_name,
  last_name: employee.last_name,
  id: employee.id,
  reference_id: employee.reference_id,
  overtimeStats: employee.overtimeStats,
  overtime: employee.overtime,
});

export function sortEmployees(employees, props, location, contracts, userJobTitles) {
  const { currentSorting, employeeOrders } = props.employeeSorting;
  const sortFunc = currentSorting.sortFunc || (() => null);
  switch (currentSorting.type) {
    // Dirty way to sort by relevant jobtitle count
    case 'jobtitle_count':
      return employees
        .map(e => {
          e.currentWeekArray = props.mainDateStore.dateArray;
          return e;
        })
        .sort(sortFunc);
    case 'custom': {
      return employees.sort(sortFunc).sort((a, b) => {
        if (!employeeOrders[location]) return null;
        const aIndex = employeeOrders[location][a.id];
        const bIndex = employeeOrders[location][b.id];
        if (!aIndex) return 1;
        if (!bIndex) return -1;
        return aIndex > bIndex ? 1 : -1;
      });
    }
    case 'job_title': {
      const { start: from, end: to } = props.mainDateStore.customDate;
      const employeesWithJobTitles = employees.map(employee => {
        const employeeContracts = contracts[employee.id] || [];
        const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
        const relevantJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
        const jobTitles = userJobTitles.filter(({ id }) => relevantJobTitleIds.includes(id));

        return {
          ...employee,
          jobTitles,
        };
      });

      return employeesWithJobTitles.sort(sortFunc);
    }
    default: {
      return [...employees].sort(sortFunc);
    }
  }
}

/*
 * mapObjectToEmployee - maps some object with employee_id to full employee object
 *
 * @param {Array} objects - array of objects with employee_id
 * @param {Array} userEmployees - array of all userEmployees
 * @param {String} key - string with key to get employee id, defaults to 'employee_id'
 *
 * @return {Array} - mapped array of objects with found employee
 */
export const mapObjectToEmployee = (objects, userEmployees, key = 'employee_id') =>
  objects.reduce((prev, obj) => {
    const foundEmployee = userEmployees.find(employee => parseInt(employee.id) === parseInt(get(obj, key)));
    if (foundEmployee) {
      return [
        ...prev,
        {
          ...obj,
          employee: {
            ...foundEmployee,
          },
        },
      ];
    }
    return prev;
  }, []);

export const filterActiveEmployeesByLocations = (employees, locationIds) => {
  const activeEmployees = employees.filter(employee => employee.inactive === false);
  return activeEmployees.filter(employee =>
    employee.locations.map(l => l.id).some(locationId => locationIds.includes(locationId)),
  );
};

export const searchEmployees = (employees, searchString) =>
  employees.filter(e => `${e.first_name} ${e.last_name}`.toLowerCase().includes(searchString.toLowerCase()));

export const isEmployeeInactive = (employeeId, employees) => {
  const employee = employees && employees.find(e => e.id === employeeId);
  return employee ? employee.inactive : false;
};

export const hasEmployeeTermByGivenJobTitleId = (jobTitleId, employeeContracts, date) => {
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const employeeJobTitleIds = relevantContract?.job_titles?.map(({ job_title_id }) => job_title_id);

  return employeeJobTitleIds?.includes(jobTitleId);
};

export const hasEmployeeLocationByGivenLocationId = (employee, locationId) =>
  employee.locations.some(location => location.id === locationId);

export const isEmployeeAssignedToLocationGroup = (employee, locationIdsFromLocationGroups) => {
  const employeesLocationIds = employee.locations.map(location => location.id);
  return employeesLocationIds.some(locationId => locationIdsFromLocationGroups.includes(locationId));
};

export const findEmployeeAvailabilityInGivenDate = (employee, date) =>
  employee.availability_blocks.find(ava => ava.date === date);

export const checkIfEmployeeIsAvailableInGivenDateAndTime = (employee, date, time) => {
  const employeeAvailabilityForADay = findEmployeeAvailabilityInGivenDate(employee, date);
  return (
    !employeeAvailabilityForADay ||
    employeeAvailabilityForADay.type === 'availability' ||
    (employeeAvailabilityForADay.type === 'freetime' &&
      checkIfShiftIsIncludedInHourlyRange(time, employeeAvailabilityForADay.hours))
  );
};

export const checkIfEmployeeHasEmploymentCondition = (employmentConditions, employee) => {
  if (!employmentConditions) return true;
  return employmentConditions.includes(employee.employment_conditions.template_id);
};

export const availableEmployeesForShift = (
  employees,
  date,
  openShift,
  locationId,
  jobTitleId,
  contracts,
  checkAvailability = true,
  locationIdsFromLocationGroups = [],
) =>
  employees.filter(
    employee =>
      !employee.inactive &&
      hasEmployeeTermByGivenJobTitleId(jobTitleId, contracts[employee.id] || [], date) &&
      (hasEmployeeLocationByGivenLocationId(employee, locationId) ||
        isEmployeeAssignedToLocationGroup(employee, locationIdsFromLocationGroups)) &&
      !checkShiftOverlap(employee, openShift) &&
      (!checkAvailability || checkIfEmployeeIsAvailableInGivenDateAndTime(employee, date, openShift.working_hours)) &&
      checkIfEmployeeHasEmploymentCondition(openShift.employmentConditions, employee),
  );

export const assignShiftToEmployee = (employees, editedOpenShift, openShift, userJobTitles, contracts) =>
  employees.reduce((acc, employee) => {
    const employeeContracts = contracts[employee.id] || [];
    const relevantContract = getRelevantContractForDate(employeeContracts, editedOpenShift.date);
    const employeeJobTitleIds = relevantContract?.job_titles?.map(({ job_title_id }) => job_title_id);
    const hasJobTitle = employeeJobTitleIds?.includes(editedOpenShift.job_title.id);

    if (!hasJobTitle) {
      return acc;
    }

    const jobTitle = userJobTitles.find(({ id }) => id === editedOpenShift.job_title.id);
    const shift = {
      ...openShift,
      ...editedOpenShift,
      employee: { id: employee.id },
      job_title: jobTitle,
    };

    return acc.concat({ employee, shift });
  }, []);

/**
 *
 * @param employees - list of employee objects
 * @param locationId - id of current location
 * @returns list of employees with supplementary ones at the and and with extra flag 'supplementary'
 */
export const sortOutSupplementaryEmployees = (employees, locationId) => {
  const regularEmployees = [];
  const supplementaryEmployees = [];
  employees.forEach(e => {
    if (e.supplementary_locations_ids.includes(parseInt(locationId))) {
      supplementaryEmployees.push({ ...e, supplementary: true });
    } else {
      regularEmployees.push(e);
    }
  });

  return { regularEmployees, supplementaryEmployees };
};

export const getEmployeeJobTitlesAsSelectOptions = (employeeContracts, userJobTitles, date) => {
  if (!employeeContracts?.length) return [];
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const relevantJobTItleIds = relevantContract?.job_titles?.map(({ job_title_id }) => job_title_id);
  const relevantJobTitles = userJobTitles.filter(({ id }) => relevantJobTItleIds.includes(id));
  return relevantJobTitles.map(({ id, title }) => ({ key: title, value: id }));
};

export const formatEmployeesToUpdate = employees =>
  employees.map(employee => {
    const {
      id,
      first_name,
      last_name,
      supplementary_locations,
      role,
      role_id,
      email,
      phone,
      phone_prefix,
      pin,
      reference_id,
      alias,
      nfc_code,
      inactive,
      company_id,
      limits,
      custom_fields,
      employment_conditions,
      locations,
      contracts,
    } = employee;

    return {
      id,
      first_name,
      last_name,
      supplementary_locations,
      role,
      role_id,
      email,
      phone,
      phone_prefix,
      pin,
      reference_id,
      alias,
      nfc_code,
      inactive,
      company_id,
      limits,
      custom_fields,
      employment_conditions,
      locations,
      contracts,
    };
  });

export const formatSupplementaryLocationsIdsForEmployeeUpdate = employees =>
  employees.map(employee => {
    const processedEmployee = { ...employee };
    if (Array.isArray(processedEmployee.supplementary_locations_ids) && processedEmployee.role !== 'manager') {
      processedEmployee.supplementary_locations = processedEmployee.supplementary_locations_ids.join(',');
    }
    return processedEmployee;
  });

export const checkEmployeeIsManager = (employee, companyRoles) => {
  const { role, role_id: roleId } = employee;
  if (isEmpty(companyRoles)) return null;
  if (roleId && roleId !== 'owner') {
    const employeeRole = companyRoles.find(({ id }) => id === roleId);
    return employeeRole.is_manager;
  }

  return role === 'manager';
};

export const MAX_RANK = 10;
export const MIN_RANK = 1;

export const getEmployeeRank = (employee, companyRoles) => {
  const employeeRole = employee.role_id || employee.role;

  const isOwner = employeeRole === 'owner';
  if (isOwner) {
    return MAX_RANK + 1;
  }

  const isManager = employeeRole === 'manager';
  if (isManager) {
    return MAX_RANK;
  }
  const employeeRank = companyRoles.find(role => role.id === employeeRole);
  const rank = (employeeRank && employeeRank.rank) || MIN_RANK;

  return rank;
};

/**
 *
 * @param {string} comparedEmployeeId - id to compare current user is editing user e.g. in modals
 *
 * @returns array of roles with rank below current user rank and role of current user if it's edit
 */
export const getRolesAvailableForCurrentUser = (
  currentUser,
  companyRoles,
  userPermissions,
  comparedEmployeeId,
  sameRankIsVisible,
) => {
  const currentUserRank = getEmployeeRank(currentUser, companyRoles);
  return companyRoles.filter(role => {
    const rankCond = sameRankIsVisible ? role.rank <= currentUserRank : role.rank < currentUserRank;
    const sameUserRoleCond = comparedEmployeeId === currentUser.id && role.id === currentUser.role_id;
    const managerCond = role.id !== 'manager' || !userPermissions.restrictions.includes(MANAGER_ROLE_HIDE);
    return (rankCond || sameUserRoleCond) && managerCond;
  });
};

/**
 * Function which separates main locations and supplementary location into
 * arrays.
 * @param {Object} employee - employee object
 *
 * @returns object containing separated locations, main and supplementary
 */
export const divideLocationsIntoMainAndSupplementary = employee => {
  const dividedLocations = employee.locations.reduce(
    (locations, location) =>
      employee.supplementary_locations_ids.includes(parseInt(location.id))
        ? {
            main: locations.main,
            supplementary: [...locations.supplementary, location],
          }
        : {
            main: [...locations.main, location],
            supplementary: locations.supplementary,
          },
    {
      main: [],
      supplementary: [],
    },
  );
  return dividedLocations;
};

export const getReadablePermissionName = permission => permissionsLangs[permission]?.defaultMessage ?? permission;

export const filterEmployeesForValidatingWorkingRules = (
  employees,
  from,
  to,
  options = {
    validateOnlyEmployeesWithDrafts: false,
    validateAllLocations: false,
  },
) =>
  employees.filter(employee => {
    const relevantShifts = employee.shifts.filter(
      shift => shift.date <= to && shift.date >= from && (!options.validateOnlyEmployeesWithDrafts || shift.draft),
    );
    const shouldBeEmployeeValidated = !employee.inactive && employee.employment_conditions?.validate_working_rules;

    if (options.validateAllLocations) return shouldBeEmployeeValidated && relevantShifts.length > 0;

    const relevantShiftsForOtherLocations = employee.shifts_for_other_locations.filter(
      shift => shift.date <= to && shift.date >= from && (!options.validateOnlyEmployeesWithDrafts || shift.draft),
    );

    return shouldBeEmployeeValidated && relevantShifts.length - relevantShiftsForOtherLocations.length > 0;
  });

export const getDefaultJobTitleForEmployee = (employee, employeeContracts, userJobTitles, date, initialJobTitleId) => {
  const { lastJobTitleId } = employee;
  const relevantJobTitleId = initialJobTitleId || lastJobTitleId;
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const employeeJobTitlesIds = relevantContract ? getJobTitleIdsFromContracts([relevantContract]) : [];
  const jobTitleId = employeeJobTitlesIds.includes(relevantJobTitleId) ? relevantJobTitleId : employeeJobTitlesIds[0];
  const jobTitle = userJobTitles.find(({ id }) => id === jobTitleId);

  return jobTitle;
};

export const isEmployeeHiredInPeriod = (employee, dateArray) => {
  const [start, end] = getFromToFromDateStore({ dateArray });
  const hireDate = employee.employment_conditions?.hire_date;
  const releaseDate = employee.employment_conditions?.release_date;
  const isHiredBeforeEnd = !hireDate || moment(hireDate).isSameOrBefore(end);
  const isReleasedBeforeStart = !releaseDate || moment(releaseDate).isSameOrAfter(start);
  return isHiredBeforeEnd && isReleasedBeforeStart;
};

export const getWorkedMinutesFromShifts = shifts =>
  shifts.reduce((agg, shift) => {
    const minutes = agg;
    return minutes + shift.duration;
  }, 0);

export const canEmployeeEditLocations = (companyRoles, userRoleId) =>
  userRoleId === 'owner'
    ? true
    : companyRoles
        .find(companyRole => companyRole.id === userRoleId)
        .permissions.includes(ROLES.COMPANY_MANAGE_LOCATIONS_EDIT);

export const getRoleWithTheHighestRank = (companyRoles, currentUser, sameRankIsVisible, modalObject) => {
  const currentUserRank = getEmployeeRank(currentUser, companyRoles);

  const filteredAndSortedCompanyRolesByRank = companyRoles.reduce((acc, role) => {
    const { rank, id } = role;
    if (id !== modalObject.id && ((sameRankIsVisible && rank <= currentUserRank) || rank < currentUserRank)) {
      return acc.rank > rank ? acc : role;
    }
    return acc;
  }, {});

  return filteredAndSortedCompanyRolesByRank;
};

export const getEmployeeById = (userEmployees, employeeId) =>
  userEmployees.find(employee => employee.id === employeeId);

export const mapAdditionalInfoToMassAttendance = attendances =>
  attendances.map(attendance => ({
    ...attendance,
    hours: `${getHourFromTimestamp(attendance.start_timestamp)}-${getHourFromTimestamp(attendance.end_timestamp)}`,
    date: attendance.start_timestamp === null ? '' : attendance.start_timestamp.split(' ')[0],
    location: { id: attendance.location_id },
  }));

export const getUnreleasedEmployees = employees =>
  employees.filter(employee => !employee.employment_conditions.release_date);

export const getUnreleasedEmployeesByIds = (userEmployees, employeeIds) => {
  const employees = employeeIds.map(employeeId => getEmployeeById(userEmployees, employeeId));
  return getUnreleasedEmployees(employees);
};

export const getFirstAndLastNameOfEmployee = employee => `${employee.first_name} ${employee.last_name}`;

export const filterEmployeesByFilters = (
  employees,
  selectedLocationIds,
  selectedJobTitleIds,
  selectedEmploymentConditionIds,
  contracts,
  from,
  to,
) =>
  employees.filter(employee => {
    const hasSelectedLocation = employee.locations.some(({ id }) => selectedLocationIds.includes(id));
    const hasSelectedEmploymentCondition = selectedEmploymentConditionIds.includes(
      employee.employment_conditions.template_id,
    );
    const employeeContracts = contracts[employee.id] || [];
    const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
    const employeeJobTitleIds = [
      ...new Set(relevantContracts.flatMap(({ job_titles }) => job_titles.map(({ job_title_id }) => job_title_id))),
    ];

    const hasSelectedJobTitle = employeeJobTitleIds.some(jobTitleId => selectedJobTitleIds.includes(jobTitleId));
    return hasSelectedLocation && hasSelectedEmploymentCondition && hasSelectedJobTitle;
  });
