import moment from 'moment';

import { SortingFnEnum, TABLE_COLUMN_STICKY_ENUM } from '@/components/common/MDTable/MDTable.types';
import {
  PayrollAlertCell,
  PayrollBonusCell,
  PayrollLabelCell,
  PayrollStatusCell,
  PayrollTimeCell,
} from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTableCells';
import { FREE_DAYS_MARKING_ENABLE, LABELS_MANAGE, OVERTIME_V2_ENABLE } from '@/constants/Permissions';
import {
  BUDGET_INFO_HIDE,
  FREEMIUM_HIDE_ABSENCES_VIEW,
  FREEMIUM_HIDE_ATTENDANCE_VIEW,
} from '@/constants/Restrictions.js';

import { filterMessages, messages } from './payrollTable.messages';

export const payrollTableColumnsOptions = [
  {
    accessorKey: 'date',
    cannotBeHidden: true,
    shouldBeHiddenForColumnFilter: true,
    width: 100,
    sticky: TABLE_COLUMN_STICKY_ENUM.LEFT,
    cell: ({ row }) => <span>{moment(row.original.date).format('dd DD.MM')}</span>,
    sortingFn: SortingFnEnum.DATE_TIME,
  },
  {
    accessorKey: 'freeDayMarkingName',
    permissions: [FREE_DAYS_MARKING_ENABLE],
    width: 100,
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
  },
  {
    accessorKey: 'from',
    width: 140,
    cell: props => <PayrollTimeCell {...props} />,
  },
  {
    accessorKey: 'to',
    width: 140,
    cell: props => <PayrollTimeCell {...props} />,
  },
  {
    accessorKey: 'workHours',
    width: 150,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'breaks',
    width: 100,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ABSENCES_VIEW],
    accessorKey: 'absence',
    width: 130,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    accessorKey: 'workTime',
    width: 140,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    accessorKey: 'jobTitleName',
    width: 150,
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
  },
  {
    accessorKey: 'locationName',
    width: 150,
    sortingFn: SortingFnEnum.LOCALE_IGNORE_CASE,
  },
  {
    accessorKey: 'nightWorkTime',
    width: 150,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'overtime50',
    shouldBeHiddenForColumnFilter: true,
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [],
    accessorKey: 'overtime100',
    shouldBeHiddenForColumnFilter: true,
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [OVERTIME_V2_ENABLE],
    accessorKey: 'potentialOvertime',
    width: 160,
    hideForShiftsPayoutType: true,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [OVERTIME_V2_ENABLE],
    accessorKey: 'fillUp',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'overtime_50_100',
    shouldBeHiddenInTable: true,
    columnAccessorsToShow: ['overtime50', 'overtime100'],
    hideForShiftsPayoutType: true,
    width: 150,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'plan',
    width: 100,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'difference',
    width: 100,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [BUDGET_INFO_HIDE, FREEMIUM_HIDE_ABSENCES_VIEW],
    accessorKey: 'bonusAmount',
    width: 90,
    cell: props => <PayrollBonusCell {...props} />,
    withoutVerticalPadding: true,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    restrictions: [BUDGET_INFO_HIDE],
    accessorKey: 'wage',
    width: 100,
    sortingFn: SortingFnEnum.CURRENCY,
  },
  {
    accessorKey: 'labels',
    width: 150,
    permissions: [LABELS_MANAGE],
    cell: props => <PayrollLabelCell {...props} />,
    sortingFn: SortingFnEnum.LABELS,
  },
  {
    accessorKey: 'payout',
    width: 130,
    sortingFn: SortingFnEnum.CURRENCY,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'warnings',
    centerColumn: true,
    width: 100,
    sticky: TABLE_COLUMN_STICKY_ENUM.RIGHT,
    withoutVerticalPadding: true,
    cell: props => <PayrollAlertCell {...props} />,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    companySettings: ['payroll_approval_enabled'],
    accessorKey: 'status',
    centerColumn: true,
    width: 70,
    sticky: TABLE_COLUMN_STICKY_ENUM.RIGHT,
    sortingFn: SortingFnEnum.PAYROLL_STATUS,
    withoutVerticalPadding: true,
    cell: props => <PayrollStatusCell {...props} />,
  },
].map(option => ({
  ...option,
  id: option.accessorKey,
  title: messages[option.accessorKey],
  filterTitle: filterMessages[option.accessorKey] || messages[option.accessorKey],
  restrictions: option.restrictions || [],
  permissions: option.permissions || [],
}));

export const TIME_FORMAT = '__:__';
export const PAYROLL_HOURS_RANGE_PLACEHOLDER = `${TIME_FORMAT} / ${TIME_FORMAT}`;
export const PAYROLL_WORK_HOURS_PLACEHOLDER = `${TIME_FORMAT} - ${TIME_FORMAT}`;
