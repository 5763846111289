import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { KebabMenu } from '@/components/common/Basic/KebabMenu/KebabMenu';
import {
  ABSENCE_ADD_MODAL,
  ADD_ABSENCE_PROPOSAL_MODAL,
  ADD_ATTENDANCE_MODAL,
  ADD_SHIFT_MODAL,
  PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL,
} from '@/constants/modalTypes';
import { PAYROLL_MODES } from '@/constants/payrollSettings';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions.js';
import { checkAbsenceEditDisabled } from '@/utils/absenceHelpers.js';
import { isAttendanceEditDisabled } from '@/utils/attendanceHelpers';

import { messages } from './PayrollTableOptions.messages';

import './PayrollTableOptions.scss';

const PayrollTableOptions = (
  {
    showModal,
    rowData,
    selectedEmployee,
    userPermissions,
    payrollMode,
    currentUser,
    showAcceptOvertimeModalForPayroll,
    companyRoles,
    addShiftForPayroll,
    userEmployees,
  },
  { intl },
) => {
  const showPayrollEditShiftAndAttendance = useCallback(
    isLoanedEmployee => {
      showModal(PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL, {
        shiftId: rowData.shiftId,
        attendanceId: rowData.attendanceId,
        selectedEmployee,
        isLoanedEmployee,
      });
    },
    [rowData.shiftId, rowData.attendanceId, selectedEmployee],
  );

  const isAttendanceEditRestricted = useMemo(
    () => isAttendanceEditDisabled(userPermissions, currentUser, selectedEmployee, companyRoles),
    [userPermissions, currentUser, selectedEmployee, companyRoles],
  );

  const isShiftEditDisabled = useMemo(
    () => userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE) || (rowData.isLoaned && !rowData.attendanceId),
    [userPermissions, rowData],
  );

  const isSupervisor = useMemo(
    () => userEmployees.some(employee => employee.id === selectedEmployee.id),
    [userEmployees, selectedEmployee],
  );

  const isEditingModeEnabled = payrollMode === PAYROLL_MODES.editable;

  const editLabel = isEditingModeEnabled ? (
    intl.formatMessage(messages.edit)
  ) : (
    <div className="k-payrollTableOptions__label">
      {intl.formatMessage(messages.editBlocked)}
      <i className="material-icons-outlined">lock_open</i>
    </div>
  );

  const isAbsence = Boolean(rowData.absenceId);
  const isShift = Boolean(rowData.shiftId) || (Boolean(rowData.matchingShiftFrom) && Boolean(rowData.matchingShiftTo));
  const isAttendance = Boolean(rowData.attendanceId);
  const isShiftOrAttendance = isShift || isAttendance;
  const isLoanedEmployee = Boolean(rowData.isLoanedEmployee);
  const { hasOvertimeToAccept } = rowData;
  const isAbsencesEditBlocked = useMemo(
    () => checkAbsenceEditDisabled(userPermissions.restrictions, selectedEmployee, currentUser.user.id),
    [userPermissions.restrictions, selectedEmployee, currentUser],
  );

  const options = [
    {
      id: '0',
      icon: 'create',
      title: intl.formatMessage(messages.edit),
      onClick: () => showPayrollEditShiftAndAttendance(isLoanedEmployee),
      show: isShiftOrAttendance && (!isShiftEditDisabled || !isAttendanceEditRestricted),
    },
    {
      id: '1',
      icon: 'more_time',
      title: intl.formatMessage(messages.acceptOvertime),
      onClick: async () => {
        await showAcceptOvertimeModalForPayroll(rowData.attendanceId, rowData.shiftId);
      },
      show: !isLoanedEmployee && hasOvertimeToAccept && !isAttendanceEditRestricted,
    },
    {
      id: '2',
      icon: 'description',
      title: intl.formatMessage(messages.absenceHistory),
      onClick: () =>
        showModal(ADD_ABSENCE_PROPOSAL_MODAL, {
          id: rowData.absenceId,
          employee_id: selectedEmployee.id,
          date: rowData.date,
        }),
      show: !isLoanedEmployee && isAbsence && isSupervisor,
    },
    {
      id: '3',
      icon: 'today',
      title: intl.formatMessage(messages.addShift),
      onClick: () =>
        showModal(ADD_SHIFT_MODAL, {
          employee: selectedEmployee,
          addShiftWithLocationAndDateSelect: true,
          preApproved: true,
          hideRepeatShift: true,
          customAddShift: (shift, addAttendanceForShift) => addShiftForPayroll(shift, addAttendanceForShift),
          showAddAttendanceForShift: !isAttendance,
          date: rowData.date,
          locationId: rowData.locationId,
        }),
      show: true,
      disabled: isShift,
    },
    {
      id: '4',
      icon: 'event_available',
      title: intl.formatMessage(messages.addAttendance),
      onClick: () => showModal(ADD_ATTENDANCE_MODAL, { date: rowData.date }),
      show: true,
      disabled: isAttendance,
    },
    {
      id: '5',
      icon: 'airplanemode_active',
      title: intl.formatMessage(messages.addAbsence),
      onClick: () =>
        showModal(ABSENCE_ADD_MODAL, {
          employee: {
            name: `${selectedEmployee.first_name} ${selectedEmployee.last_name}`,
            id: selectedEmployee.id,
            from: rowData.date,
            to: rowData.date,
          },
        }),
      show: true,
      disabled: isAbsence || isAbsencesEditBlocked,
    },
  ];
  const relevantOptions = options.filter(option => option.show);
  const kebabMenuDisabled = !isEditingModeEnabled || relevantOptions.length === 0;
  return <KebabMenu title={editLabel} options={relevantOptions} disabled={kebabMenuDisabled} />;
};

PayrollTableOptions.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollTableOptions.propTypes = {
  showModal: PropTypes.func,
  rowData: PropTypes.shape({
    shiftId: PropTypes.string,
    attendanceId: PropTypes.string,
    absenceId: PropTypes.string,
    plan: PropTypes.string,
    workHours: PropTypes.string,
    plannedTime: PropTypes.number,
    workTime: PropTypes.string,
    difference: PropTypes.number,
    hasOvertimeToAccept: PropTypes.bool,
    date: PropTypes.string,
    matchingShiftFrom: PropTypes.string,
    matchingShiftTo: PropTypes.string,
    isLoanedEmployee: PropTypes.bool,
    locationId: PropTypes.string,
  }),
  selectedEmployee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  payrollMode: PropTypes.string,
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  showAcceptOvertimeModalForPayroll: PropTypes.func,
  companyRoles: PropTypes.shape({}),
  addShiftForPayroll: PropTypes.func,
};

export default PayrollTableOptions;
