import { Row } from '@tanstack/react-table';

import { AttendanceToCreate } from '@/components/attendance/AttendanceButtonBar/Buttons';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { checkIsRowHidden } from '@/components/common/MDTable/TableBody/TableBody.utils';
import { ATTENDANCE_ABSENCE } from '@/constants/attendanceDetailTypes';
import { dateTimeToTimestamp, getEndDateBasedOnFromTo } from '@/utils/dates/dates.helpers';
import { getBem } from '@/utils/styles';

import { AttendanceRowItem, SubRowType } from '../AttendanceManyDaysTable.types';

const bem = getBem('k-attendanceManyDaysTableButtonBar');

export const classes = {
  container: bem.element('container'),
  right: bem.element('right'),
  actionsBtnContainer: bem.element('actionsBtnContainer'),
};

export const getSelectedData = (flatRows: Row<TableRow<AttendanceRowItem>>[]) =>
  flatRows.reduce((prev, row) => {
    if (row.depth !== 1) return prev;
    return checkIsRowHidden(row) ? prev : [...prev, row.original];
  }, []);

const checkIsOvertime = (attendance: AttendanceRowItem['attendanceObject']) =>
  attendance.end_timestamp &&
  (!attendance.matching_shift ||
    attendance.start_timestamp < attendance.matching_shift.start_timestamp ||
    attendance.end_timestamp > attendance.matching_shift.end_timestamp);

export const getSelectedOvertimeData = (selectedData: Partial<SubRowType>[]) =>
  selectedData.reduce(
    (p, { attendanceObject: attendance, employeeObject: employee, ...rest }) =>
      checkIsOvertime(attendance) ? [...p, { ...rest, attendance, employee }] : p,
    [],
  );

export const getAttendancesToCreate = (selectedData: Partial<SubRowType>[]) =>
  selectedData.reduce<AttendanceToCreate[]>((prev, row) => {
    if (row.type !== ATTENDANCE_ABSENCE) return prev;
    const endDate = getEndDateBasedOnFromTo(row.startTimeShift, row.endTimeShift, row.mobileDate);
    return [
      ...prev,
      {
        location_id: row.location.id,
        employee_id: row.employeeObject.id,
        draft: false,
        start_timestamp: dateTimeToTimestamp(row.mobileDate, row.startTimeShift),
        end_timestamp: dateTimeToTimestamp(endDate, row.endTimeShift),
      },
    ];
  }, []);
