import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import MDTable from '@/components/common/MDTable/MDTable';
import { tabsMessages } from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationTable/PayrollLocationTable.messages';
import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';
import { mapId } from '@/utils/array/array.helpers';
import { filterEmployeesByFilters } from '@/utils/userEmployeesHelpers';

import PayrollLocationButtonBar from './PayrollLocationButtonBar/PayrollLocationButtonBar.redux';
import { getColumns, getPayrollLocationColumns, getTableData, tableStyles, TABS } from './PayrollLocationTable.helpers';
import PayrollLocationTableEmpty from './PayrollLocationTableEmpty/PayrollLocationTableEmpty';
import PayrollLocationTableOptions from './PayrollLocationTableOptions/PayrollLocationTableOptions.redux';
import { usePayrollLocationTable } from './usePayrollLocationTable';

import './PayrollLocationTable.scss';

const PayrollLocationTable = (props, { intl }) => {
  const {
    data,
    visibleColumns,
    timeFormatType,
    sortingUseLastName,
    overtimeStats,
    userPermissions,
    dateFilter,
    payoutSettingType,
    userEmployees,
    selectedLocationIds,
    selectedEmploymentConditions,
    selectedJobTitles,
    companySettings,
    getPayrollLocationViewData,
    showSkeletons,
    contracts,
  } = props;

  const { onRowClick } = usePayrollLocationTable();

  const tabs = useMemo(() => {
    if (!companySettings.payroll_approval_enabled) return [];

    return TABS.map(tab => {
      const numberOfItems = tab.id === 'all' ? data.length : data.filter(row => row.status === tab.id).length;

      return {
        ...tab,
        label: intl.formatMessage(tabsMessages[tab.id]),
        onSelect: () => {
          const allSelected = tab.id === 'all';
          setActiveTab(tab.id);
          getPayrollLocationViewData(allSelected ? null : tab.id, { staleWhileRevalidate: true });
        },
        numberOfItems,
      };
    });
  }, [intl, getPayrollLocationViewData, data]);
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const selectedEmploymentConditionIds = useMemo(
    () => mapId(selectedEmploymentConditions),
    [selectedEmploymentConditions],
  );
  const selectedJobTitleIds = useMemo(() => mapId(selectedJobTitles), [selectedJobTitles]);
  const filteredEmployees = useMemo(
    () =>
      filterEmployeesByFilters(
        userEmployees,
        selectedLocationIds,
        selectedJobTitleIds,
        selectedEmploymentConditionIds,
        contracts,
        dateFilter.start,
        dateFilter.end,
      ),
    [userEmployees, selectedLocationIds, selectedJobTitleIds, selectedEmploymentConditionIds],
  );
  const allColumns = getPayrollLocationColumns(userPermissions, payoutSettingType, companySettings);
  const columns = useMemo(() => getColumns(allColumns, visibleColumns, intl), [visibleColumns, allColumns, intl]);
  const tableData = useMemo(() => {
    const filteredData =
      activeTab === 'all' || tabs.length === 0 ? data : data.filter(item => item.status === activeTab);
    return getTableData(
      filteredData,
      timeFormatType,
      sortingUseLastName,
      overtimeStats,
      intl,
      dateFilter,
      showSkeletons,
      filteredEmployees.length,
    );
  }, [
    data,
    timeFormatType,
    sortingUseLastName,
    overtimeStats,
    dateFilter,
    showSkeletons,
    filteredEmployees,
    activeTab,
  ]);
  const disableSelectAllCheckbox = dateFilter.end < PAYROLL_APPROVAL_START_DATE;

  return (
    <MDTable
      tabs={tabs}
      activeTab={activeTab}
      data={tableData}
      columns={columns}
      enableRowSelection={
        companySettings.payroll_approval_enabled
          ? row => !row.original.disabledCheckbox || disableSelectAllCheckbox
          : false
      }
      withSearch={false}
      tableEmpty={
        <PayrollLocationTableEmpty
          hasTabs={companySettings.payroll_approval_enabled}
          numberOfFetchedItems={data.length}
        />
      }
      options={<PayrollLocationTableOptions />}
      tableOptions={<PayrollLocationButtonBar />}
      onRowClick={!showSkeletons ? onRowClick : undefined}
      tableStyles={tableStyles}
    />
  );
};

PayrollLocationTable.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollLocationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  visibleColumns: PropTypes.arrayOf(PropTypes.shape({})),
  timeFormatType: PropTypes.string,
  sortingUseLastName: PropTypes.bool,
  overtimeStats: PropTypes.shape({}),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  dateFilter: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  payoutSettingType: PropTypes.string,
  companySettings: PropTypes.shape({
    payroll_approval_enabled: PropTypes.bool,
  }),
  showSkeletons: PropTypes.bool,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  selectedLocationIds: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  selectedEmploymentConditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  getPayrollLocationViewData: PropTypes.func,
};

export default PayrollLocationTable;
