import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { DIRECTIONS } from '@/constants/ui';
import { InputEvent } from '@/hooks/useInputChange/useInputChange.types';

import Popover from '../../Popover/Popover.jsx';
import MDTextInput from '../MDTextInput/MDTextInput.jsx';
import { MonthPickerContent } from './Content/Content';

import './MDMonthPicker.scss';

const popoverStyle: React.CSSProperties = {
  padding: 0,
  border: 'none',
};

type Props = {
  disabled?: boolean;
  label: string;
  name: string;
  onChange: (v: InputEvent<Props['value']>) => void;
  value?: { month: number; year: number };
  testId?: string;
};

const MonthPicker = (props: Props) => {
  const { disabled, label, name, onChange, value, testId } = props;

  const [year, setYear] = useState(value?.year || moment().year());
  const [month, setMonth] = useState(value?.month || moment().month() + 1);

  useEffect(() => {
    if (value) {
      setYear(value.year);
      setMonth(value.month);
    }
  }, [value]);

  const handleChange = useCallback(
    (monthYear: Props['value']) => {
      onChange({
        target: { type: 'monthPicker', value: monthYear, name },
      });
    },
    [name, onChange],
  );

  const changeYear = useCallback(
    (diff: number) => {
      setYear(prevYear => prevYear + diff);
      handleChange({ month, year: year + diff });
    },
    [month, year, handleChange],
  );

  const changeMonth = useCallback(
    (newMonth: number) => {
      setMonth(newMonth);
      handleChange({ month: newMonth, year });
    },
    [year, handleChange],
  );

  if (disabled) return <MDTextInput label={label} modifiers="modal disabled" disabled />;

  return (
    <Popover
      position={DIRECTIONS.TOP}
      yOffset={50}
      popoverStyle={popoverStyle}
      content={<MonthPickerContent {...{ changeMonth, changeYear, month, year }} />}
    >
      <div>
        <MDTextInput
          label={label}
          value={moment(`${year}/${month}/01`).format('MMMM YYYY')}
          modifiers="modal"
          testId={testId}
        />
      </div>
    </Popover>
  );
};

export default MonthPicker;
