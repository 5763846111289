import moment from 'moment';

import { useAppSelector } from '@/redux-store';
import { selectCurrentUser } from '@/redux-store/currentUser/currentUser.selectors.ts';
import { selectLocationSettings } from '@/redux-store/settings/locationSettings/locationSettings.selectors.ts';
import { selectUserEmployees } from '@/redux-store/userEmployees/userEmployees.selectors.ts';

export const useDashboardTimeTracker = () => {
  const now = moment();
  const userEmployees = useAppSelector(selectUserEmployees);
  const currentUser = useAppSelector(selectCurrentUser);
  const locationSettings = useAppSelector(selectLocationSettings);
  const employee = userEmployees.find(emp => emp.id === currentUser.user.id);
  const allEmployeeShifts = [...(employee?.shifts || []), ...(employee?.shifts_for_other_locations || [])];
  const filteredShifts = allEmployeeShifts.filter(shift => {
    const isAllowAttendanceOpenClose = locationSettings[shift.location.id]?.allow_employee_attendace_open_close;

    return isAllowAttendanceOpenClose;
  });
  const nextShift = filteredShifts
    .filter(shift => {
      const isShiftEndAfterNow = moment(shift.end_timestamp).diff(now, 'minutes') >= 0;

      return isShiftEndAfterNow;
    })
    .sort((a, b) => moment(a.start_timestamp).diff(moment(b.start_timestamp)))[0];

  if (!employee) {
    return {
      relevantLocations: [],
      employee: null,
      nextShift: null,
      previousShift: null,
    };
  }

  const previousShift = filteredShifts
    .filter(shift => {
      const isShiftEndBeforeNow = moment(shift.end_timestamp).diff(now, 'minutes') < 0;
      const isShiftWithin12Hours = moment(shift.end_timestamp).diff(now, 'minutes') >= -12 * 60;

      return isShiftEndBeforeNow && isShiftWithin12Hours;
    })
    .sort((a, b) => moment(b.end_timestamp).diff(moment(a.end_timestamp)))[0];

  const locationsFromShiftsWithin24Hours = filteredShifts
    .filter(shift => {
      const now = moment();
      const startShiftMinus24Hours = moment(shift.start_timestamp).subtract(24, 'hours');
      const endShiftPlus24Hours = moment(shift.end_timestamp).add(24, 'hours');

      return now.isBetween(startShiftMinus24Hours, endShiftPlus24Hours);
    })
    .map(shift => shift.location);

  const relevantLocations = [...(employee.locations || []), ...locationsFromShiftsWithin24Hours].reduce(
    (acc, location) => {
      const isLocationAlreadyAdded = acc.some(loc => loc.id === location.id);
      const isAllowAttendanceOpenClose = locationSettings[location.id]?.allow_employee_attendace_open_close;

      if (!isLocationAlreadyAdded && isAllowAttendanceOpenClose) {
        acc.push(location);
      }

      return acc;
    },
    [],
  );

  return {
    relevantLocations,
    employee,
    nextShift,
    previousShift,
  };
};
