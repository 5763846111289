import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './MDMultiSelectHeader.scss';

const MDMultiSelectHeader = props => {
  const {
    icon,
    title,
    selectAllMessage = <FormattedMessage id="common.multiSelect.selectAll" defaultMessage="Wszystkie" />,
    deselectAllMessage = <FormattedMessage id="common.multiSelect.deselectAll" defaultMessage="Żadna" />,
    selectAll,
    isSelectAllDisabled,
    deselectAll,
    isDeselectAllDisabled,
    withoutSelectAll,
    testId,
    withoutDeselectAll,
  } = props;
  return (
    <div className="mdMultiSelectHeader">
      <div className="mdMultiSelectHeader__content">
        {icon && <span className="material-icons mdMultiSelectHeader__icon">{icon}</span>}
        <span className="mdMultiSelectHeader__title">{title}</span>
      </div>
      <div className="mdMultiSelectHeader__buttonsContainer">
        {!withoutSelectAll && (
          <button
            disabled={isSelectAllDisabled}
            className="mdMultiSelectHeader__btn"
            onClick={selectAll}
            data-test={testId && `${testId}-button-selectAll`}
          >
            {selectAllMessage}
          </button>
        )}
        {!withoutDeselectAll && (
          <button disabled={isDeselectAllDisabled} className="mdMultiSelectHeader__btn" onClick={deselectAll}>
            {deselectAllMessage}
          </button>
        )}
      </div>
    </div>
  );
};

MDMultiSelectHeader.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isSelectAllDisabled: PropTypes.bool,
  isDeselectAllDisabled: PropTypes.bool,
  selectAll: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  selectAllMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  deselectAllMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  withoutSelectAll: PropTypes.bool,
  testId: PropTypes.string,
  withoutDeselectAll: PropTypes.bool,
};

export default MDMultiSelectHeader;
