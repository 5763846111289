import classNames from 'classnames';
import { memo } from 'react';

import './ExpandButton.scss';

type Props = {
  isOpen: boolean;
  toggle?: () => void;
  smallMargin?: boolean;
};

export const ExpandButton = memo(({ isOpen, toggle, smallMargin }: Props) => {
  const className = classNames('material-icons k-expandButton__icon', {
    'k-expandButton__icon--open': isOpen,
  });

  return (
    <div className={classNames('k-expandButton', { 'k-expandButton--smallMargin': smallMargin })}>
      <i role="presentation" className={className} onClick={toggle}>
        keyboard_arrow_up
      </i>
    </div>
  );
});

ExpandButton.displayName = 'ExpandButton';
