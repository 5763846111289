import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { startAttendance } from '@/actions/attendances.js';
import Button from '@/components/common/Basic/Button.jsx';
import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';
import {
  CANCEL_MODIFIERS,
  CONFIRM_MODIFIERS,
} from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTracker.helpers.ts';
import { useAppDispatch } from '@/redux-store';
import { EmployeeShift, UserLocation } from '@/types';

import { messages } from './DashboardTimeTracker.messages.ts';

type Props = {
  relevantLocations: UserLocation[];
  nextShift: EmployeeShift & { location: UserLocation };
  showAttendanceState: () => void;
  handleCancel: () => void;
  employeeId: string;
};

export const DashboardTimeTrackerSelectLocation = ({
  relevantLocations,
  nextShift,
  handleCancel,
  showAttendanceState,
  employeeId,
}: Props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const defaultLocation = nextShift?.location || relevantLocations[0];
  const [selectedLocation, setSelectedLocation] = useState(defaultLocation.id);
  const options = useMemo(
    () =>
      relevantLocations.map(location => ({
        value: location.id,
        key: location.name,
      })),
    [relevantLocations],
  );

  const handleConfirm = async (selectedLocationId: string) => {
    await dispatch(startAttendance(selectedLocationId, employeeId));
    showAttendanceState();
  };

  return (
    <>
      <div className="dashboardTimeTracker__locationSelect">
        <MDSelect
          name={intl.formatMessage(messages.location)}
          defaultValue={selectedLocation}
          options={options}
          onChange={key => {
            setSelectedLocation(key);
          }}
          closeOnClick
          withSearch
        />
      </div>
      <div className="dashboardTimeTracker__bottomButtons">
        <Button modifiers={CANCEL_MODIFIERS} onClick={handleCancel}>
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button modifiers={CONFIRM_MODIFIERS} onClick={() => handleConfirm(selectedLocation)}>
          {intl.formatMessage(messages.begin)}
        </Button>
      </div>
    </>
  );
};
