import {
  filterActiveEmployeesByLocations,
  filterEmployeesForValidatingWorkingRules,
} from '@/utils/userEmployeesHelpers.js';

export const getInitialState = () => ({
  sendNotif: true,
  sendSms: false,
  publishShifts: true,
  publishOpenShifts: true,
  comment: '',
  isWorkingRulesValidating: false,
  isBudgetTargetValidating: false,
  showWorkingRulesError: false,
});

export const isWorkingRulesEnabled = (locationSettings, scheduleLocationFilter) =>
  locationSettings[scheduleLocationFilter[0]]?.enable_working_rules_check;

const getEmployeesIdsVisibleOnSchedule = (locationsIds, scheduleState) => {
  const visibleEmployeeIds = locationsIds.reduce(
    (result, locationId) => [
      ...result,
      ...(scheduleState.locations[locationId]?.visible || []),
      ...(scheduleState.locations[locationId]?.supplementary || []),
    ],
    [],
  );
  return [...new Set(visibleEmployeeIds)];
};

export const getEmployeesIdsForWorkingRules = (locationsIds, mainDateStore, userEmployees) => {
  const { start, end } = mainDateStore.customDate;
  const employeesForCurrentLocation = filterActiveEmployeesByLocations(userEmployees, locationsIds);
  const relevantEmployees = filterEmployeesForValidatingWorkingRules(employeesForCurrentLocation, start, end, {
    validateOnlyEmployeesWithDrafts: true,
  });
  return relevantEmployees.map(employee => employee.id);
};

export const shouldShowWorkingRulesError = (workingRules, locationsIds, scheduleState, userEmployees) => {
  const visibleEmployeeIds = getEmployeesIdsVisibleOnSchedule(locationsIds, scheduleState);
  const employeeIdFromWorkingRules = workingRules.reduce((acc, rule) => {
    if (rule.employee_id && !acc.includes(rule.employee_id)) {
      acc.push(rule.employee_id);
    }
    return acc;
  }, []);

  const isEmployeeWithOneMonthScheduleCycle = userEmployees.some(
    employee =>
      employeeIdFromWorkingRules.includes(employee.id) && employee.employment_conditions.schedule_cycle.duration === 1,
  );

  return workingRules.some(
    rule => visibleEmployeeIds.includes(rule.employee_id) && isEmployeeWithOneMonthScheduleCycle,
  );
};

const countDraftShifts = (userEmployees, locationsIds, scheduleState, dateArray) => {
  const visibleEmployeeIds = getEmployeesIdsVisibleOnSchedule(locationsIds, scheduleState);
  return userEmployees.reduce((shiftsNumber, e) => {
    if (e.inactive) return shiftsNumber;
    return (
      shiftsNumber +
      e.shifts.filter(
        s =>
          s.draft &&
          visibleEmployeeIds.includes(s.employee.id) &&
          locationsIds.includes(s.location.id) &&
          dateArray.includes(s.date),
      ).length
    );
  }, 0);
};

const countDraftOpenShifts = (openShifts, locationsIds, dateArray, selectedJobTitles) => {
  const selectedJobTitlesIds = selectedJobTitles.map(({ id }) => id);
  return openShifts.filter(
    shift =>
      shift.draft &&
      dateArray.includes(shift.date) &&
      locationsIds.includes(shift.location.id) &&
      selectedJobTitlesIds.includes(shift.job_title.id),
  ).length;
};

const countShiftsWithErrors = (userEmployees, userJobTitles, locationsIds, dateArray) => {
  const jobiTitlesIds = userJobTitles.map(t => t.id);
  return userEmployees.reduce((shiftsNumber, e) => {
    if (e.inactive) return shiftsNumber;
    return (
      shiftsNumber +
      e.shifts.filter(
        s =>
          s.draft &&
          locationsIds.includes(s.location.id) &&
          !jobiTitlesIds.includes(s.job_title.id) &&
          dateArray.includes(s.date),
      ).length
    );
  }, 0);
};

export const countShifts = ({
  scheduleLocationFilter: locationsIds,
  scheduleState,
  mainDateStore,
  userEmployees,
  userJobTitles,
  openShifts,
  selectedJobTitles,
}) => {
  const { dateArray } = mainDateStore;
  const numberOfDraftShifts = countDraftShifts(userEmployees, locationsIds, scheduleState, dateArray);
  const numberOfDraftOpenShifts = countDraftOpenShifts(openShifts, locationsIds, dateArray, selectedJobTitles);
  const numberOfShiftsWithErrors = countShiftsWithErrors(userEmployees, userJobTitles, locationsIds, dateArray);
  return { numberOfDraftShifts, numberOfDraftOpenShifts, numberOfShiftsWithErrors };
};
