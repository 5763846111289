import { HeaderContext } from '@tanstack/react-table';
import classNames from 'classnames';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandButton } from '@/components/common/Basic/ExpandButton/ExpandButton';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { useMediaQuery } from '@/hooks';

import { ReportRow } from '../../ReportsTable.types';

import './FooterSummaryCell.scss';

type Props = HeaderContext<TableRow<ReportRow>, unknown> & {
  footerRowIndex: number;
};

export const FooterSummaryCell = ({ table: { getState, toggleFooterRowExpanding }, footerRowIndex }: Props) => {
  const { isAtLeastTabletLandscape } = useMediaQuery();
  const isExpand = getState().footerRowsExpanding[footerRowIndex];

  const toggleExpand = useCallback(() => {
    toggleFooterRowExpanding(footerRowIndex);
  }, [toggleFooterRowExpanding, footerRowIndex]);

  if (!isAtLeastTabletLandscape) return <FormattedMessage id="reportsTable.summary" defaultMessage="Podsumowanie" />;

  return (
    <div className={classNames('k-reportFooterSummaryCell', isExpand && 'k-reportFooterSummaryCell--expanded')}>
      <ExpandButton isOpen={!isExpand} toggle={toggleExpand} smallMargin />
      <FormattedMessage id="reportsTable.summary" defaultMessage="Podsumowanie" />
    </div>
  );
};
