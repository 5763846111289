import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import MDTable from '@/components/common/MDTable/MDTable';
import { NUMBER_OF_MINUTES_IN_DAY } from '@/constants/budgetMetrics';

import { getColumns } from './BudgetMetricsTable.utils';
import BudgetMetricsTableOptions from './BudgetMetricsTableOptions/BudgetMetricsTableOptions';
import { BudgetMetricOptionCell } from './Cells/BudgetMetricOptionCell/BudgetMetricOptionCell';
import messages from './messages';

const BudgetMetricsTable = ({ budgetMetrics, showModal, deleteBudgetMetrics, canEdit }) => {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);

  const data = useMemo(
    () =>
      budgetMetrics.map(({ id, name, color, locations, time_interval: timeInterval, icon, type }) => ({
        icon,
        id,
        name,
        color,
        type: intl.formatMessage(messages[type]),
        locations: locations ?? [],
        time_interval:
          parseInt(timeInterval) === NUMBER_OF_MINUTES_IN_DAY
            ? intl.formatMessage(messages.budgetMetricsTableDailyInterval)
            : intl.formatMessage(messages.budgetMetricsTableMinutelyInterval, {
                value: timeInterval,
              }),
      })),
    [budgetMetrics, intl],
  );

  return (
    <MDTable
      columns={columns}
      data={data}
      options={<BudgetMetricOptionCell canEdit={canEdit} />}
      tableOptions={
        <BudgetMetricsTableOptions deleteBudgetMetrics={deleteBudgetMetrics} showModal={showModal} canEdit={canEdit} />
      }
      enableRowSelection={canEdit}
      evenlyColumns
    />
  );
};

BudgetMetricsTable.propTypes = {
  budgetMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      time_interval: PropTypes.number,
      color: PropTypes.string,
      icon: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          color: PropTypes.string,
        }),
      ),
    }),
  ),
  deleteBudgetMetric: PropTypes.func,
  deleteBudgetMetrics: PropTypes.func,
  showModal: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default BudgetMetricsTable;
