import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { refreshData } from '@/actions/attendances';
import Button from '@/components/common/Basic/Button';
import { BUTTON_BAR_TOOLTIP_OFFSET } from '@/components/common/Basic/ButtonBar/ButtonBar.utils';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { useAppDispatch } from '@/redux-store';

import { messages } from '../../AttendanceButtonBar.messages';

export const AttendanceRefreshButton = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(refreshData());
  }, [dispatch]);

  return (
    <Tooltip content={intl.formatMessage(messages.refresh)} yOffset={BUTTON_BAR_TOOLTIP_OFFSET}>
      <Button onClick={onClick} modifiers="reverse-blue onlyIcon">
        <i className="material-icons">sync</i>
      </Button>
    </Tooltip>
  );
};
