/* eslint no-use-before-define: 0 camelcase: 0 */
import {
  ADD_AVAILABILITY_SUCCESFUL,
  ADD_MASS_EMPLOYEES,
  ADD_MASS_SHIFTS_SUCCESFUL,
  ADD_NEW_EMPLOYEE,
  ADD_SHIFT_FAILURE,
  ADD_SHIFT_SUCCESFUL,
  ADD_SHIFT_TO_EMPLOYEE,
  ADD_SHIFTS_FOR_CURRENT_USER_SUCCESS,
  ADD_SHIFTS_SUCCESFUL,
  ADD_SHIFTS_TO_EMPLOYEES,
  CHANGE_AVAILABILITY_SUCCESFUL,
  CHANGE_USER_ROLE_SUCCESS,
  CLEAR_DEMO_DATA,
  CLOSE_TRADE_SHIFT_SUCCESFUL,
  DELETE_AVAILABILITIES_SUCCESFUL,
  DELETE_AVAILABILITY_SUCCESFUL,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SET,
  DELETE_EMPLOYMENT_CONDITION,
  DELETE_LOCATION,
  DELETE_MULTIPLE_SHIFTS_SUCCESFUL,
  DELETE_SHIFT,
  DELETE_SHIFT_FOR_PAYROLL_SUCCESS,
  DELETE_SHIFTS_FOR_CURRENT_USER_SUCCESS,
  DUPLICATE_PREVIOUS_VIEW_SUCCESS,
  EDIT_SHIFT_FOR_CURRENT_USER_SUCCESS,
  EDIT_SHIFT_SUCCESFUL,
  GET_AVAILABILITIES_SUCCESFUL,
  GET_CURRENT_USER_SUCCESFUL,
  GET_EMPLOYEES_SUCCESFUL,
  GET_OVERTIME_STATS_SUCCESS,
  GET_OVERTIME_SUCCESS,
  GET_PRODUCTION_QUOTAS_PAYROLL_SUCCESFUL,
  GET_SCHEDULE_SHIFTS_SUCCESFUL_FOR_EMPLOYEE,
  GET_SCHEDULE_STATS,
  GET_SCHEDULE_SUCCESFUL,
  GET_SCHEDULE_SUCCESFUL_FOR_EMPLOYEE,
  HIDE_REDUNDANT_OVERTIME_STATS,
  PUBLISH_DRAFT_SHIFTS_SUCCESFUL,
  REMOVE_SUPPLEMENTARY_LOCATIONS_AFTER_ROLE_ASSIGN,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_AVAILABILITIES,
  UPDATE_EMPLOYEE_MASS_SUCCESS,
  UPDATE_EMPLOYMENT_CONDITION,
  UPDATE_JOBTITLE,
  UPDATE_LOCATION,
} from '@/constants/ActionTypes';
import { mergeArrayById } from '@/utils/arrayHelpers';
import { isEmptyObject } from '@/utils/baseHelpers';
import {
  mapNewScheduleShiftsToEmployeesForEmployee,
  mapNewShiftsToEmployees,
  mapNewShiftsToEmployeesForEmployee,
} from '@/utils/userEmployeesHelpers.js';

const initialState = [];

function map_pulled_emps(employees) {
  for (const emp of employees) {
    emp.shifts = [];
    emp.availability_blocks = [];
    emp.shifts_for_other_locations = [];
    emp.overtimeStats = {};
  }
  return employees;
}

const userEmployees = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_SUCCESFUL: {
      let newState = action.payload;
      newState = map_pulled_emps(newState);
      return newState;
    }
    case GET_CURRENT_USER_SUCCESFUL: {
      return [...state, ...map_pulled_emps([action.payload.user])];
    }
    case ADD_NEW_EMPLOYEE: {
      return [...state, ...map_pulled_emps([action.payload])];
    }
    case GET_SCHEDULE_SUCCESFUL:
      return mapNewShiftsToEmployees(action.payload, state);
    case GET_SCHEDULE_SUCCESFUL_FOR_EMPLOYEE:
      return mapNewShiftsToEmployeesForEmployee(action.payload, state);
    case GET_SCHEDULE_SHIFTS_SUCCESFUL_FOR_EMPLOYEE:
      return mapNewScheduleShiftsToEmployeesForEmployee(action.payload, state);
    case GET_OVERTIME_SUCCESS: {
      return state.map(employee => ({ ...employee, overtime: action.payload[employee.id] || {} }));
    }
    case GET_OVERTIME_STATS_SUCCESS: {
      return state.map(employee => ({
        ...employee,
        overtimeStats: action.payload[employee.id] || employee.overtimeStats,
      }));
    }
    case DELETE_EMPLOYEE: {
      return state.filter(employee => employee.id !== action.payload);
    }
    case DELETE_EMPLOYEE_SET: {
      return state.filter(e => !action.payload.includes(e.id));
    }
    case CLEAR_DEMO_DATA: {
      return initialState;
    }
    case DELETE_LOCATION: {
      return state.map(({ locations, ...employee }) => {
        const filteredLocations = locations.filter(({ id }) => id !== action.payload);

        return { locations: filteredLocations, ...employee };
      });
    }
    case UPDATE_EMPLOYEE:
    case ADD_SHIFT_TO_EMPLOYEE:
    case ADD_SHIFT_SUCCESFUL:
    case ADD_SHIFT_FAILURE:
    case DELETE_SHIFT:
    case DELETE_SHIFT_FOR_PAYROLL_SUCCESS:
    case PUBLISH_DRAFT_SHIFTS_SUCCESFUL:
    case EDIT_SHIFT_SUCCESFUL:
    case GET_AVAILABILITIES_SUCCESFUL:
    case ADD_AVAILABILITY_SUCCESFUL:
    case CHANGE_AVAILABILITY_SUCCESFUL:
    case DELETE_AVAILABILITY_SUCCESFUL:
    case UPDATE_EMPLOYEE_AVAILABILITIES:
    case UPDATE_LOCATION:
    case UPDATE_JOBTITLE:
    case CLOSE_TRADE_SHIFT_SUCCESFUL:
    case GET_PRODUCTION_QUOTAS_PAYROLL_SUCCESFUL:
    case GET_SCHEDULE_STATS:
    case HIDE_REDUNDANT_OVERTIME_STATS:
    case UPDATE_EMPLOYMENT_CONDITION:
    case DELETE_EMPLOYMENT_CONDITION:
    case ADD_MASS_SHIFTS_SUCCESFUL:
    case DUPLICATE_PREVIOUS_VIEW_SUCCESS:
    case CHANGE_USER_ROLE_SUCCESS:
    case REMOVE_SUPPLEMENTARY_LOCATIONS_AFTER_ROLE_ASSIGN:
    case DELETE_MULTIPLE_SHIFTS_SUCCESFUL:
    case DELETE_SHIFTS_FOR_CURRENT_USER_SUCCESS:
    case ADD_SHIFTS_FOR_CURRENT_USER_SUCCESS:
    case EDIT_SHIFT_FOR_CURRENT_USER_SUCCESS:
    case DELETE_AVAILABILITIES_SUCCESFUL:
      return state.map(e => employee(e, action));
    case UPDATE_EMPLOYEE_MASS_SUCCESS: {
      const updatedEmployees = action.payload.employees;
      return state.reduce((acc, employeeData) => {
        const updateEmployee = updatedEmployees.find(({ id }) => id === employeeData.id);
        if (updateEmployee && !isEmptyObject(updateEmployee)) return [...acc, { ...employeeData, ...updateEmployee }];
        return [...acc, employeeData];
      }, []);
    }
    case ADD_SHIFTS_TO_EMPLOYEES:
      return state.map(employee => {
        const newEmployeeShifts = action.payload.filter(shift => shift.employee.id === employee.id);

        return {
          ...employee,
          shifts: [...employee.shifts, ...newEmployeeShifts],
        };
      });
    case ADD_SHIFTS_SUCCESFUL:
      return state.map(employee => {
        const newEmployeeShifts = action.newShifts.filter(shift => shift.employee.id === employee.id);
        const filteredCurrentShifts = employee.shifts.filter(shift => !action.uuids.includes(shift.id));

        return {
          ...employee,
          shifts: [...filteredCurrentShifts, ...newEmployeeShifts],
        };
      });
    case ADD_MASS_EMPLOYEES: {
      return [...state, ...map_pulled_emps(action.payload)];
    }
    default:
      return state;
  }
};

const employee = (state, action) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE:
      if (state.id === action.payload.id) {
        const newEmp = { ...state, ...action.payload };
        return newEmp;
      }
      return state;
    case ADD_SHIFT_TO_EMPLOYEE:
      if (state.id === action.payload.employee_id) {
        const newEmp = { ...state };
        newEmp.shifts = [...state.shifts, action.payload.shift];
        newEmp.lastJobTitleId = action.payload.shift.job_title.id;
        return newEmp;
      }
      return state;
    case ADD_SHIFT_SUCCESFUL:
      if (state.id === action.payload.employee_id) {
        const newEmp = { ...state };
        newEmp.shifts = state.shifts.map(shift => {
          if (shift.id === action.payload.uid) {
            const newShift = action.payload.new_shift;

            return newShift;
          }
          return shift;
        });
        return newEmp;
      }
      return state;
    case ADD_SHIFTS_FOR_CURRENT_USER_SUCCESS:
      if (state.id === action.payload.employee_id) {
        return {
          ...state,
          shifts: [...state.shifts, ...action.payload.newShifts],
        };
      }
      return state;
    case ADD_SHIFT_FAILURE:
    case DELETE_SHIFT:
      if (state.id === action.payload.employee_id) {
        const shiftToDeleteIndex = state.shifts.findIndex(shift => shift.id === action.payload.shift_id);
        const shifts_for_other_locations = state.shifts_for_other_locations.filter(
          shift => shift.id !== action.payload.shift_id,
        );
        return {
          ...state,
          shifts: [...state.shifts.slice(0, shiftToDeleteIndex), ...state.shifts.slice(shiftToDeleteIndex + 1)],
          shifts_for_other_locations,
        };
      }
      return state;
    case DELETE_SHIFT_FOR_PAYROLL_SUCCESS: {
      const { shift } = action.payload;
      const employeeId = shift.employee.id;
      if (state.id === employeeId) {
        const filteredShifts = state.shifts.filter(({ id }) => id !== shift.id);
        return {
          ...state,
          shifts: filteredShifts,
        };
      }
      return state;
    }
    case DELETE_MULTIPLE_SHIFTS_SUCCESFUL:
    case DELETE_SHIFTS_FOR_CURRENT_USER_SUCCESS: {
      const itemsToDelete = action.payload[state.id];
      if (!itemsToDelete) return state;
      const shiftIds = itemsToDelete.map(item => item.shiftId);
      return {
        ...state,
        shifts: state.shifts.filter(shift => !shiftIds.includes(shift.id.toString())),
      };
    }
    case DELETE_AVAILABILITIES_SUCCESFUL: {
      return {
        ...state,
        availability_blocks: state.availability_blocks.filter(ava => !action.payload.includes(ava.id)),
      };
    }
    case PUBLISH_DRAFT_SHIFTS_SUCCESFUL: {
      const { dateArray, location_id, employeeIds } = action.payload;

      if (!employeeIds.includes(state.id)) {
        return state;
      }

      const newEmp = { ...state };

      newEmp.shifts = state.shifts.map(shift => {
        if (dateArray.includes(shift.date) && shift.location.id === location_id && shift.draft === true) {
          return { ...shift, draft: false };
        }
        return shift;
      });
      return newEmp;
    }
    case EDIT_SHIFT_SUCCESFUL:
    case EDIT_SHIFT_FOR_CURRENT_USER_SUCCESS: {
      if (state.id === action.payload.employee_id) {
        const newEmp = { ...state };
        newEmp.shifts = state.shifts.map(shift => {
          if (shift.id === action.payload.newShiftObject.id) {
            return action.payload.newShiftObject;
          }
          return shift;
        });
        return newEmp;
      }
      return state;
    }
    case UPDATE_EMPLOYEE_AVAILABILITIES: {
      if (state.id === action.payload.employee.id) {
        return {
          ...state,
          availability_blocks: mergeArrayById(action.payload.availability_blocks, state.availability_blocks),
        };
      }
      return state;
    }
    case GET_AVAILABILITIES_SUCCESFUL:
      return {
        ...state,
        availability_blocks: action.payload.filter(
          availability_block => availability_block.employee_id === parseInt(state.id),
        ),
      };
    case ADD_AVAILABILITY_SUCCESFUL: {
      if (state.id === action.payload.employee.id) {
        const newEmp = { ...state };
        newEmp.availability_blocks = [...newEmp.availability_blocks, action.payload];
        return newEmp;
      }
      return state;
    }
    case DELETE_AVAILABILITY_SUCCESFUL: {
      if (state.id === action.payload.employee_id) {
        const newEmp = { ...state };
        newEmp.availability_blocks = newEmp.availability_blocks.filter(
          a => a.id !== action.payload.availabilityObject.id,
        );
        return newEmp;
      }
      return state;
    }
    case CHANGE_AVAILABILITY_SUCCESFUL: {
      if (state.id === action.payload.employee.id) {
        const newEmp = { ...state };
        newEmp.availability_blocks = [
          ...newEmp.availability_blocks.filter(a => a.id !== action.payload.id),
          action.payload,
        ];
        return newEmp;
      }
      return state;
    }
    case UPDATE_LOCATION:
      return {
        ...state,
        locations: state.locations.map(location => {
          if (location.id === action.payload.id) return action.payload;
          return location;
        }),
      };

    case CLOSE_TRADE_SHIFT_SUCCESFUL: {
      // when we close trade shift first we need to remove shift from previously assigned employee
      if (state.id === action.payload.tradeShift.user.id) {
        const shiftToDeleteIndex = state.shifts.findIndex(shift => shift.id === action.payload.tradeShift.shift.id);
        return {
          ...state,
          shifts: [...state.shifts.slice(0, shiftToDeleteIndex), ...state.shifts.slice(shiftToDeleteIndex + 1)],
        };
      }
      // and then assign it to new employee
      if (state.id === action.payload.employeeId) {
        return {
          ...state,
          shifts: [...state.shifts, action.payload.tradeShift.shift],
        };
      }
      return state;
    }
    case GET_PRODUCTION_QUOTAS_PAYROLL_SUCCESFUL: {
      const employeePayroll = action.payload.find(payroll => payroll.employee.id === state.id);
      if (employeePayroll) {
        return {
          ...state,
          payroll: {
            ...employeePayroll.payout,
            payout_breakdown_per_day: state.payroll
              ? { ...state.payroll.payout_breakdown_per_day, ...employeePayroll.payout.payout_breakdown_per_day }
              : { ...employeePayroll.payout.payout_breakdown_per_day },
          },
        };
      }
      return state;
    }
    case GET_SCHEDULE_STATS: {
      let scheduleStats = action.payload.find(item => item.employee_id === state.id);
      if (scheduleStats) {
        ({ scheduleStats } = scheduleStats);
      }
      return { ...state, scheduleStats: scheduleStats || [] };
    }
    case HIDE_REDUNDANT_OVERTIME_STATS: {
      const { from, to } = state.overtimeStats;
      const newDate = action.payload;
      if (newDate < from || newDate > to) return { ...state, overtimeStats: {} };
      return state;
    }
    case UPDATE_EMPLOYMENT_CONDITION:
    case DELETE_EMPLOYMENT_CONDITION: {
      const newEmploymentCondition = action.payload.updatedEmploymentConditions.find(
        condition => condition.user_id === state.id,
      );
      if (newEmploymentCondition) {
        return {
          ...state,
          employment_conditions: newEmploymentCondition,
        };
      }
      return state;
    }
    case DUPLICATE_PREVIOUS_VIEW_SUCCESS: {
      const shiftsToAdd = action.payload.filter(s => s.employee.id === state.id);
      return {
        ...state,
        shifts: [...state.shifts, ...shiftsToAdd],
      };
    }
    case ADD_MASS_SHIFTS_SUCCESFUL: {
      const shiftsToAdd = action.payload.filter(s => s.employee.id === state.id);
      return { ...state, shifts: [...state.shifts, ...shiftsToAdd] };
    }
    case CHANGE_USER_ROLE_SUCCESS: {
      const userObj = action.payload.find(item => item.id === state.id);
      if (userObj) {
        return { ...state, role_id: userObj.role_id, role: userObj.role };
      }
      return state;
    }
    case REMOVE_SUPPLEMENTARY_LOCATIONS_AFTER_ROLE_ASSIGN: {
      if (action.payload.includes(state.id)) {
        return { ...state, supplementary_locations_ids: [] };
      }
      return state;
    }

    default:
      return state;
  }
};

export default userEmployees;
