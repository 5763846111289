import { memo, useMemo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectContractsByEmployeeId } from '@/redux-store/contracts';
import { selectShiftForEmployeeByShiftId } from '@/redux-store/shifts';
import { selectUserLocationsDict } from '@/redux-store/userLocations';
import { selectUserJobTitles } from '@/selectors/userJobTitles.selectors';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { checkContractsHasJobTitle, getRelevantContractForDate } from '@/utils/contracts';
import { findJobTitleForShift } from '@/utils/shiftHelpers';

import { ScheduleShiftProps } from './ScheduleShift.types';
import { ScheduleShiftContent } from './ScheduleShiftContent/ScheduleShiftContent';
import { ScheduleShiftPopup } from './ScheduleShiftPopup/ScheduleShiftPopup';

export const ScheduleShift = memo(
  ({
    absencesForDate,
    currentLocationId,
    employeeId,
    groupNodeId,
    isAbsenceConnectingWithAnyScheduleBlock,
    shiftId,
  }: ScheduleShiftProps) => {
    const shift = useAppSelector(selectShiftForEmployeeByShiftId(employeeId, shiftId));
    const employeeContracts = useAppSelector(selectContractsByEmployeeId(employeeId));
    const userLocationsDict = useAppSelector(selectUserLocationsDict);
    const userJobTitles = useAppSelector(selectUserJobTitles);

    const { date, job_title, location } = shift || {};
    const shiftJobTitle = useMemo(() => findJobTitleForShift(shift, userJobTitles), [shift, userJobTitles]);

    const isJobTitleNotAssigned = useMemo(() => {
      if (isEmptyArray(employeeContracts)) return null;
      const relevantContract = getRelevantContractForDate(employeeContracts, date);
      return !checkContractsHasJobTitle([relevantContract], job_title?.id);
    }, [employeeContracts, date, job_title?.id]);

    const shiftLocationName = useMemo(
      () => (location?.name ? location : userLocationsDict[location?.id])?.name,
      [location, userLocationsDict],
    );
    const isFromOtherLocation = location?.id !== currentLocationId;

    return (
      <ScheduleShiftPopup
        {...{
          employeeId,
          isFromOtherLocation,
          isJobTitleNotAssigned,
          shiftId,
          shiftJobTitle,
          shiftLocationName,
        }}
      >
        <ScheduleShiftContent
          {...{
            absencesForDate,
            employeeId,
            groupNodeId,
            isAbsenceConnectingWithAnyScheduleBlock,
            isFromOtherLocation,
            isJobTitleNotAssigned,
            shiftId,
            shiftJobTitle,
            shiftLocationName,
            currentLocationId,
          }}
        />
      </ScheduleShiftPopup>
    );
  },
);

ScheduleShift.displayName = 'ScheduleShift';
