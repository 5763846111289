import classNames from 'classnames';
import moment from 'moment';
import { IntlShape, useIntl } from 'react-intl';

import { ContentState } from '@/components/dashboard/DashboardTimeTracker/DashboardTimeTracker.helpers.ts';
import { EmployeeShift, UserLocation } from '@/types';

import { messages } from './DashboardTimeTracker.messages.ts';

type Props = {
  nextShift: EmployeeShift & { location: UserLocation };
  contentState: ContentState;
  hidePopover: () => void;
};

const getDayLabel = (shift: EmployeeShift, intl: IntlShape) => {
  const isToday = moment().isSame(shift.start_timestamp, 'day');

  if (isToday) {
    return intl.formatMessage(messages.todayCapitalized);
  }

  const isTomorrow = moment().add(1, 'day').isSame(shift.start_timestamp, 'day');

  if (isTomorrow) {
    return intl.formatMessage(messages.tomorrowCapitalized);
  }

  return moment(shift.start_timestamp).format('D MMMM') as string;
};

export const DashboardTimeTrackerContentHeader = ({ nextShift, contentState, hidePopover }: Props) => {
  const intl = useIntl();
  const isNextShiftCurrent = nextShift && moment().isBetween(nextShift.start_timestamp, nextShift.end_timestamp);

  return (
    <div
      className={classNames('dashboardTimeTracker__header', {
        'dashboardTimeTracker__header--withBorder': contentState === ContentState.ATTENDANCE,
      })}
    >
      {contentState === ContentState.ATTENDANCE && (
        <div className="dashboardTimeTracker__shiftInfo">
          {nextShift ? (
            <>
              <div className="dashboardTimeTracker__shiftHours">
                {intl.formatMessage(isNextShiftCurrent ? messages.currentShift : messages.nextShift)}:{' '}
                {!isNextShiftCurrent && `${getDayLabel(nextShift, intl)},`} {nextShift.working_hours}
              </div>
              <div className="dashboardTimeTracker__shiftDetails">
                {nextShift.location.name} | {nextShift.job_title.title}
              </div>
            </>
          ) : (
            intl.formatMessage(messages.noShifts)
          )}
        </div>
      )}
      {contentState === ContentState.SUMMARY && (
        <div className="dashboardTimeTracker__title">{intl.formatMessage(messages.workSummary)}</div>
      )}
      {contentState === ContentState.SELECT_LOCATION && (
        <div className="dashboardTimeTracker__title">{intl.formatMessage(messages.selectLocation)}</div>
      )}
      {contentState === ContentState.NO_SHIFT_CONFIRM && (
        <div className="dashboardTimeTracker__title">{intl.formatMessage(messages.startAttendance)}</div>
      )}
      <div className="dashboardTimeTracker__closeButton" onClick={hidePopover}>
        <i className="material-icons">close</i>
      </div>
    </div>
  );
};
