import { filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings } from '@/components/newPayrollViews/columns.helpers';
import { BLUE_100, BLUE_500, GREEN_100, GREEN_500, RED_100, RED_500 } from '@/constants/colors';
import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';
import { SHOW_SKELETON_CELL } from '@/constants/tables';
import {
  PAYROLL_WORK_HOURS_PLACEHOLDER,
  payrollTableColumnsOptions,
} from '@/constants/tables/payroll/payrollTableColumns';
import { parseMinutesToFormat, parseMinutesToHumanForm } from '@/utils/dateHelper';
import { getFreeDays } from '@/utils/freeDaysMarking/freeDaysMarking';

import { messages } from './PayrollTable.messages';
import { PayloadStatus } from './PayrollTable.types';

export const TABS = [
  {
    id: 'all',
    color: BLUE_500,
    lightColor: BLUE_100,
  },
  {
    id: 'approved',
    color: GREEN_500,
    lightColor: GREEN_100,
  },
  {
    id: 'unapproved',
    color: RED_500,
    lightColor: RED_100,
  },
];

const getColumnsForFilterWithHiddenColumn = (allColumns, column, intl) => {
  const columns = [];
  column.columnAccessorsToShow.forEach(additionalColumnAccessor => {
    const additionalColumn = allColumns.find(aCol => aCol.accessorKey === additionalColumnAccessor);
    if (additionalColumn)
      columns.push({
        ...additionalColumn,
        size: additionalColumn.width,
        header: intl.formatMessage(additionalColumn.title),
        accessorKey: additionalColumn.accessorKey,
      });
  });
  return columns;
};

export const getColumns = (allColumns, visibleColumns, intl) => {
  const columns = [];
  visibleColumns.forEach(col => {
    if (!allColumns.some(column => column.accessorKey === col.accessorKey)) {
      return;
    }

    if (col.shouldBeHiddenInTable) {
      columns.push(...getColumnsForFilterWithHiddenColumn(allColumns, col, intl));
    } else {
      columns.push({
        ...col,
        header: intl.formatMessage(col.title),
        size: col.width,
      });
    }
  });

  return columns;
};

const getAbsenceColumnContent = ({ absenceName, absenceTime }) => {
  if (!absenceName) return '-';
  const absenceTimeFormatted = parseMinutesToHumanForm(absenceTime);
  return `${absenceName} - ${absenceTimeFormatted}`;
};

const formatMinutes = (value, timeFormatType) => {
  if (value) return parseMinutesToFormat(value, timeFormatType);
  return '-';
};

const formatMinutesWithZero = (value, timeFormatType) => parseMinutesToFormat(value || 0, timeFormatType);

const formatMoney = value => (value || value === 0 ? `${(value / 100).toFixed(2)} PLN` : '-');

const formatMoneyWithZero = value => `${((value || 0) / 100).toFixed(2)} PLN`;

const getOvertime50ColumnContent = ({ overtimeCollectionId, overtime50, overtimeCollectionTime }, timeFormatType) => {
  if (!overtimeCollectionId) return formatMinutes(overtime50?.value, timeFormatType);
  return `-${formatMinutes(overtimeCollectionTime, timeFormatType)}`;
};

const getJobTitleColumnContent = ({ jobTitleName, overtimeCollectionId, availabilityName }, intl) => {
  if (overtimeCollectionId) return intl.formatMessage(messages.overtimeCollection);
  if (availabilityName) return availabilityName;
  return jobTitleName || '-';
};

const getDifferenceContent = (rowData, timeFormatType) => {
  const difference = rowData.realization - rowData.plannedTime;

  return difference || difference === 0 ? formatMinutesWithZero(difference, timeFormatType) : '-';
};

const checkIfHasOvertimeToAccept = rowData => {
  const isAttendance = Boolean(rowData.attendanceId);
  if (!isAttendance) {
    return false;
  }
  const isFinishedAttendance = Boolean(rowData.to);
  if (!isFinishedAttendance) {
    return false;
  }
  const isAttendanceWithoutShift = isAttendance && !rowData.matchingShiftFrom;
  if (isAttendanceWithoutShift) {
    return true;
  }
  const hasEarlyIn = rowData.from < rowData.matchingShiftFrom;
  const hasLateOut = rowData.to > rowData.matchingShiftTo;

  return hasEarlyIn || hasLateOut;
};

const skeletonData = {
  from: SHOW_SKELETON_CELL,
  to: SHOW_SKELETON_CELL,
  workHours: SHOW_SKELETON_CELL,
  breaks: SHOW_SKELETON_CELL,
  absence: SHOW_SKELETON_CELL,
  workTime: SHOW_SKELETON_CELL,
  jobTitleName: SHOW_SKELETON_CELL,
  nightWorkTime: SHOW_SKELETON_CELL,
  overtime50: SHOW_SKELETON_CELL,
  overtime100: SHOW_SKELETON_CELL,
  potentialOvertime: SHOW_SKELETON_CELL,
  fillUp: SHOW_SKELETON_CELL,
  overtimeWeekly: SHOW_SKELETON_CELL,
  plan: SHOW_SKELETON_CELL,
  difference: SHOW_SKELETON_CELL,
  wage: SHOW_SKELETON_CELL,
  payout: SHOW_SKELETON_CELL,
  warnings: SHOW_SKELETON_CELL,
  status: SHOW_SKELETON_CELL,
  labels: SHOW_SKELETON_CELL,
};

export const getTableData = (data, selectedEmployee, timeFormatType, intl, payrollLoanedEmployees) =>
  data.map(rowData => {
    const hasOvertimeToAccept = checkIfHasOvertimeToAccept(rowData);
    if (rowData.isRefreshing) {
      return {
        ...rowData,
        ...skeletonData,
        date: rowData.date,
        locationName: rowData.locationName || '-',
        disabledCheckbox: rowData.date < PAYROLL_APPROVAL_START_DATE,
        hasOvertimeToAccept,
      };
    }

    const workHours = rowData.workHours || PAYROLL_WORK_HOURS_PLACEHOLDER;
    const isLoanedEmployee = payrollLoanedEmployees.some(({ id }) => id === selectedEmployee?.id);
    const freeDays = getFreeDays(intl);
    const freeDayMarkingName = freeDays[rowData.freeDayMarkingId]?.shortcut || '';

    const baseData = {
      ...rowData,
      date: rowData.date,
      workHours,
      breaks: formatMinutesWithZero(rowData.breaksTime, timeFormatType),
      absence: getAbsenceColumnContent(rowData),
      workTime: formatMinutesWithZero(rowData.workTime, timeFormatType),
      jobTitleName: getJobTitleColumnContent(rowData, intl),
      locationName: rowData.locationName || '-',
      nightWorkTime: formatMinutesWithZero(rowData.nightWorkTime, timeFormatType),
      overtime50: getOvertime50ColumnContent(rowData, timeFormatType),
      overtime100: formatMinutes(rowData.overtime100?.value, timeFormatType),
      potentialOvertime: formatMinutes(rowData.potentialOvertime.value, timeFormatType),
      fillUp: formatMinutes(rowData.fillUp, timeFormatType),
      overtimeWeekly: '-',
      plan: formatMinutesWithZero(rowData.plannedTime, timeFormatType),
      difference: getDifferenceContent(rowData, timeFormatType),
      wage: formatMoney(rowData.wage),
      payout: formatMoneyWithZero(rowData.payout),
      warnings: rowData.warnings,
      isLoanedEmployee,
      disabledCheckbox: rowData.date < PAYROLL_APPROVAL_START_DATE,
      hasOvertimeToAccept,
      labels: rowData.labels,
      freeDayMarkingName,
    };

    if (rowData.isRefreshingOvertime) {
      return {
        ...baseData,
        overtime50: SHOW_SKELETON_CELL,
        overtime100: SHOW_SKELETON_CELL,
        potentialOvertime: SHOW_SKELETON_CELL,
        fillUp: SHOW_SKELETON_CELL,
      };
    }

    return baseData;
  });

export const getPayrollColumns = (userPermissions, payoutSettingType, companySettings) =>
  filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings(
    payrollTableColumnsOptions,
    userPermissions,
    payoutSettingType,
    companySettings,
  );

export const getPayrollStatus = isUnapproved => (isUnapproved ? PayloadStatus.UNAPPROVED : PayloadStatus.APPROVED);

export const tableStyles = { hasHeaderTextWrapping: true };
