import EmptyColumnFilter from '@/components/common/EmptyColumnFilter/EmptyColumnFilter';
import { SortingFnEnum, TABLE_COLUMN_STICKY_ENUM } from '@/components/common/MDTable/MDTable.types';
import {
  PayrollLocationAlertCell,
  PayrollLocationStatusCell,
} from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationTable/PayrollLocationTableCells';
import { OVERTIME_V2_ENABLE } from '@/constants/Permissions';
import {
  BUDGET_INFO_HIDE,
  FREEMIUM_HIDE_ABSENCES_VIEW,
  FREEMIUM_HIDE_ATTENDANCE_VIEW,
} from '@/constants/Restrictions.js';

import { filterMessages, messages } from './payrollLocationTable.messages';

export const payrollLocationTableColumnsOptions = [
  {
    accessorKey: 'employee',
    cannotBeHidden: true,
    shouldBeHiddenForColumnFilter: true,
    width: 200,
    sticky: TABLE_COLUMN_STICKY_ENUM.LEFT,
  },
  {
    restrictions: [FREEMIUM_HIDE_ABSENCES_VIEW],
    accessorKey: 'absences',
    width: 150,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    accessorKey: 'workTime',
    width: 130,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    accessorKey: 'nightWorkTime',
    width: 130,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    accessorKey: 'timePlanned',
    width: 100,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    accessorKey: 'timeDiff',
    width: 130,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'overtime50',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'overtime100',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [OVERTIME_V2_ENABLE],
    accessorKey: 'potentialOvertime',
    hideForShiftsPayoutType: true,
    width: 150,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [OVERTIME_V2_ENABLE],
    accessorKey: 'fillUp',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFn: SortingFnEnum.NUMBER,
  },
  {
    restrictions: [BUDGET_INFO_HIDE, FREEMIUM_HIDE_ABSENCES_VIEW],
    accessorKey: 'bonus',
    width: 100,
    sortingFn: SortingFnEnum.CURRENCY,
  },
  {
    restrictions: [BUDGET_INFO_HIDE],
    accessorKey: 'payout',
    width: 150,
    sortingFn: SortingFnEnum.CURRENCY,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessorKey: 'warnings',
    centerColumn: true,
    width: 70,
    Filter: EmptyColumnFilter,
    sticky: TABLE_COLUMN_STICKY_ENUM.RIGHT,
    cell: props => <PayrollLocationAlertCell {...props} />,
    enableColumnFilter: false,
    enableSorting: false,
    withoutVerticalPadding: true,
  },
  {
    accessorKey: 'status',
    width: 130,
    sticky: TABLE_COLUMN_STICKY_ENUM.RIGHT,
    companySettings: ['payroll_approval_enabled'],
    cell: props => <PayrollLocationStatusCell {...props} />,
    sortingFN: SortingFnEnum.PAYROLL_LOCATION_STATUS,
    withoutVerticalPadding: true,
  },
].map(option => ({
  ...option,
  id: option.accessorKey,
  title: messages[option.accessorKey],
  filterTitle: filterMessages[option.accessorKey] || messages[option.accessorKey],
  restrictions: option.restrictions || [],
  permissions: option.permissions || [],
  sticky: option.sticky,
}));
