import { KebabMenu } from '@/components/common/Basic/KebabMenu/KebabMenu';

import { BudgetMetricRow } from '../../BudgetMetricsTable.types';
import { useBudgetMetricOptionCell } from './useBudgetMetricOptionCell';

type Props = {
  rowData?: BudgetMetricRow;
  canEdit: boolean;
};

export const BudgetMetricOptionCell = ({ canEdit, rowData }: Props) => {
  const { options } = useBudgetMetricOptionCell(rowData, canEdit);

  return <KebabMenu options={options} />;
};
