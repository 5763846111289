import { isNumberType } from './variableTypes/variableTypes';

export const roundNumber = (number: number, decimalPlaces = 0) => {
  const scale = 10 ** decimalPlaces;
  return Math.round(number * scale) / scale;
};

export const isOdd = (number: number) => number % 2 !== 0;

export const isEven = (number: number) => number % 2 === 0;

export const compareNumbers = (numA: number, numB: number) => {
  if (Number.isNaN(numA) && Number.isNaN(numB)) return 0;
  if (Number.isNaN(numA)) return 1;
  if (Number.isNaN(numB)) return -1;
  return numA - numB;
};

export const stringToFloat = (value: string) => parseFloat(value.replace(/[^0-9.-]+/g, ''));

export const formatPercentage = (value: unknown) => (isNumberType(value) ? `${value.toFixed(2)} %` : value);

export const formatCurrency = (value: unknown, unit = 'PLN') =>
  isNumberType(value) ? `${value.toFixed(2)} ${unit}` : value;
