import PropTypes from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { ADD_OR_EDIT_BUDGET_METRIC_MODAL } from '@/constants/modalTypes.js';
import { isEmptyObject } from '@/utils/objectHelpers/objectHelpers';

import messages from './messages';

import './BudgetMetricsTableOptions.scss';

const BudgetMetricsTableOptions = ({ deleteBudgetMetrics, visibleRows, rowSelection, showModal, canEdit }) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const handleRemoveAbsenceTypes = async () => {
    setLoading(true);
    const ids = Object.keys(rowSelection).map(rowIndex => visibleRows[Number(rowIndex)].id);
    await deleteBudgetMetrics(ids);
    setLoading(false);
  };

  return (
    <div className="k-budgetMetricsTableOptions">
      <Button
        className="k-budgetMetricsTableOptions__button"
        modifiers={['orange']}
        onClick={() => showModal(ADD_OR_EDIT_BUDGET_METRIC_MODAL)}
        disabled={!canEdit}
      >
        <i className="material-icons">add</i>
        {intl.formatMessage(messages.addButton)}
      </Button>
      {canEdit && (
        <Button
          className="k-budgetMetricsTableOptions__button"
          modifiers={['reverse-orange']}
          onClick={handleRemoveAbsenceTypes}
          disabled={isEmptyObject(rowSelection) || loading}
        >
          <i className="material-icons">remove</i>
          {intl.formatMessage(messages.deleteButton)}
        </Button>
      )}
    </div>
  );
};

BudgetMetricsTableOptions.propTypes = {
  visibleRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  rowSelection: PropTypes.shape({}),
  deleteBudgetMetrics: PropTypes.func,
  showModal: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default BudgetMetricsTableOptions;
