import { FREEMIUM_HIDE_ABSENCES_VIEW, FREEMIUM_HIDE_ATTENDANCE_VIEW } from '@/constants/Restrictions';
import { payrollTableColumnsOptions } from '@/constants/tables/payroll/payrollTableColumns.jsx';

export const getInitPayrollColumns = () => {
  const initialColumns = [
    'date',
    'from',
    'to',
    'workHours',
    'absence',
    'workTime',
    'jobTitleName',
    'payout',
    'warnings',
  ];
  return payrollTableColumnsOptions.filter(option => initialColumns.includes(option.accessorKey));
};

export const getInitSummaryValues = () => [
  {
    id: 'workTime',
    active: true,
  },
  {
    id: 'absenceTime',
    active: true,
    restrictions: [FREEMIUM_HIDE_ABSENCES_VIEW],
  },
  {
    id: 'overtime',
    active: true,
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
  },
  {
    id: 'payout',
    active: true,
  },
  {
    id: 'bonuses',
    active: false,
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
  },
  {
    id: 'nightWorkTime',
    active: false,
  },
];
