import {
  ColumnFiltersState,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIsOverflow, useToggle } from '@/hooks';
import { useAppSelector } from '@/redux-store';
import { selectWindowWidth } from '@/redux-store/deviceInfo';

import { getCheckboxColumn } from './Cells/CheckboxCell';
import { getOptionsColumn } from './Cells/OptionCell';
import { ColumnsWidths, MDTableProps, TableRow } from './MDTable.types';
import { TableBody } from './TableBody/TableBody';
import { TableFooter } from './TableFooter/TableFooter';
import { TableHead } from './TableHead/TableHead';
import MDTablePagination from './TablePagination/MDTablePagination';
import { TableTabs } from './TableTabs/TableTabs';
import { TableTopContent } from './TableTopContent/TableTopContent';
import {
  filterFns,
  FooterRowExpandingFeature,
  FooterRowsExpandingState,
  getColumnWidth,
  getForceEvenlyColumnWidth,
  getInitialFooterRowsExpanding,
  getInitialPinnedColumns,
  sortingFns,
} from './TableUtils';

import './MDTable.scss';

const MDTable = <T extends object>(props: MDTableProps<TableRow<T>>) => {
  const {
    changeCurrentPage,
    columns,
    currentPage,
    customOnSelectHandler,
    data,
    enableRowSelection,
    evenlyColumns,
    expandedRows,
    footerData,
    manualFiltering,
    numberOfPages,
    onExpand,
    onSort,
    options,
  } = props;
  const [isFilterRowVisible, toggleFilterRow] = useToggle();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [footerRowsExpanding, setFooterRowsExpanding] = useState<FooterRowsExpandingState>(
    getInitialFooterRowsExpanding(footerData),
  );

  const [activeSelect, setActiveSelect] = useState(false);
  const [pageNumber, setPageNumber] = useState(props.currentPage);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const overflowDependencies = useMemo(() => [columns.length], [columns]);
  const { isOverflowX } = useIsOverflow(tableContainerRef, overflowDependencies);
  const windowWidth = useAppSelector(selectWindowWidth);

  const tableStyles = useMemo(() => props.tableStyles || {}, [props.tableStyles]);

  const allColumns = useMemo(
    () =>
      [
        enableRowSelection && getCheckboxColumn(customOnSelectHandler),
        ...columns,
        options && getOptionsColumn(options),
      ].filter(Boolean),
    [columns, options, enableRowSelection, customOnSelectHandler],
  );

  const columnsWidths: ColumnsWidths = useMemo(() => {
    if (!tableContainerRef.current) return {};
    const { clientWidth } = tableContainerRef.current;
    const tableProps = { columns, enableRowSelection, options };
    if (!evenlyColumns) return { forceEvenlyColumnWidth: getForceEvenlyColumnWidth(clientWidth, tableProps) };
    return { columnWidth: getColumnWidth(clientWidth, tableProps, isOverflowX) };
  }, [columns, enableRowSelection, evenlyColumns, isOverflowX, options, tableContainerRef, windowWidth]);

  const handleExpandRow = useCallback(
    (callback: (e: ExpandedState) => ExpandedState) => {
      if (!onExpand) return;
      const rows = callback(expandedRows);
      onExpand(rows);
    },
    [expandedRows, onExpand],
  );

  const handleSortColumn = useCallback(
    (callback: (e: SortingState) => SortingState) => {
      setSorting(p => {
        const newSorting = callback(p);
        if (onSort) onSort(newSorting);
        return newSorting;
      });
    },
    [setSorting, onSort],
  );

  const handlePageOnBlur = useCallback(() => {
    if (!(pageNumber >= 1 && pageNumber <= numberOfPages)) {
      setPageNumber(currentPage);
    } else if (pageNumber !== currentPage) {
      changeCurrentPage(pageNumber);
    }
  }, [pageNumber, currentPage, numberOfPages, changeCurrentPage]);

  const table = useReactTable({
    data,
    columns: allColumns,
    enableExpanding: !!onExpand,
    enableSubRowSelection: !!onExpand,
    enableRowSelection,
    enableMultiSort: !onSort,
    _features: [FooterRowExpandingFeature],
    manualFiltering,
    manualSorting: !!onSort,
    maxLeafRowFilterDepth: 0,
    state: {
      columnFilters,
      columnPinning: getInitialPinnedColumns(allColumns),
      expanded: props.expandedRows || {},
      footerRowsExpanding,
      rowSelection,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: handleSortColumn,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: handleExpandRow,
    onRowsExpandingChange: setFooterRowsExpanding,
    onRowSelectionChange: setRowSelection,
    getSubRows: row => row.subRows,
    sortingFns,
    filterFns,
  });

  useEffect(() => {
    setRowSelection({});
  }, [data, setRowSelection]);

  const { rows } = table.getRowModel();
  const visibleRows = useMemo(() => rows.map(row => row.original), [rows]);

  return (
    <div className={classNames('mdTable', props.withPagination && 'mdTable--withPagination')}>
      <TableTopContent
        table={table}
        rowSelection={rowSelection}
        tableOptions={props.tableOptions}
        toggleFilterRow={toggleFilterRow}
        visibleRows={visibleRows}
        withSearch={props.withSearch}
      />
      <TableTabs activeTab={props.activeTab} tabs={props.tabs} />
      <div
        ref={tableContainerRef}
        className={classNames('mdTable__container', {
          'mdTable__container--withPagination': props.withPagination,
          'mdTable__container--lightShadow': tableStyles.hasLightShadow,
        })}
      >
        <table className="mdTable__table">
          <TableHead {...{ columnsWidths, isOverflowX, table, tableStyles }} />
          <TableBody
            columnsWidths={columnsWidths}
            table={table}
            isFilterRowVisible={isFilterRowVisible}
            isOverflowX={isOverflowX}
            manualFiltering={manualFiltering}
            tableStyles={tableStyles}
            onRowClick={props.onRowClick}
            tableEmpty={props.tableEmpty}
          />
          <TableFooter {...{ columnsWidths, footerData, isOverflowX, table, tableStyles }} />
        </table>
      </div>
      {props.withPagination && data.length !== 0 && (
        <MDTablePagination
          isTable
          currentPage={props.currentPage}
          changeCurrentPage={props.changeCurrentPage}
          numberOfPages={props.numberOfPages}
          activeSelect={activeSelect}
          setActiveSelect={setActiveSelect}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          handlePageOnBlur={handlePageOnBlur}
          numberOfItemsPerPage={props.numberOfItemsPerPage}
          changeNumberOfItemsPerPage={props.changeNumberOfItemsPerPage}
        />
      )}
    </div>
  );
};

export default MDTable;
