import { InputHTMLAttributes, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { messages } from './DebouncedInput.utils';

import './DebouncedInput.scss';

export const DebouncedInput = memo(
  ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
  } & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const [value, setValue] = useState(initialValue);
    const intl = useIntl();

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);

    return (
      <input
        {...props}
        className="mdTableDebouncedInput"
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={intl.formatMessage(messages.filter)}
      />
    );
  },
);

DebouncedInput.displayName = 'DebouncedInput';
