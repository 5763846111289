import cn from 'classnames';
import { memo, useCallback } from 'react';

import { useToggle } from '@/hooks';
import { throttle } from '@/utils/performance/performance.utils';

import './DropArea.scss';

type Props = {
  activeDrag: boolean;
  blocked: boolean;
  enabled: boolean;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  isSwapping: boolean;
};

const DropArea = memo(({ activeDrag, blocked, enabled, isSwapping, onDrop }: Props) => {
  const [isShown, , show, hide] = useToggle();

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      onDrop(e);
      hide();
    },
    [onDrop, hide],
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const blockedClassNames = cn('k-scheduleDropArea', { 'k-scheduleDropArea--blocked': !isSwapping });

  const className = cn('k-scheduleDropArea', {
    'k-scheduleDropArea--selected': isShown && !isSwapping && activeDrag,
  });

  const handleDragEnter = useCallback(
    throttle(() => {
      show();
    }),
    [show],
  );

  const handleDragLeave = useCallback(
    throttle(() => {
      hide();
    }),
    [hide],
  );

  if (!enabled) return null;

  if (blocked) return <div className={blockedClassNames} />;

  return (
    <div
      className={className}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    />
  );
});

DropArea.displayName = 'DropArea';

export default DropArea;
