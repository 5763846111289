import { CellContext } from '@tanstack/react-table';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { showModal } from '@/actions/uiState';
import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import LabelsContainer from '@/components/common/UI/LabelsContainer/LabelsContainer';
import { ADD_OR_EDIT_BUDGET_METRIC_MODAL } from '@/constants/modalTypes';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { BudgetMetricRow } from '../../BudgetMetricsTable.types';
import messages from '../../messages';

import './BudgetMetricLocationsCell.scss';

type Props = CellContext<TableRow<BudgetMetricRow>, unknown>;

export const BudgetMetricLocationsCell = memo((props: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locations, id } = props.cell.row.original;

  const openModal = useCallback(() => {
    dispatch(showModal(ADD_OR_EDIT_BUDGET_METRIC_MODAL, id));
  }, [dispatch, id]);

  if (isEmptyArray(locations)) return intl.formatMessage(messages.budgetMetricsTableNoLocations);

  return (
    <div className="k-budgetMetricsTableLocationsCell">
      <LabelsContainer limit={4} moreAction={openModal}>
        {locations.map(location => (
          <ColorLabel key={location.id} text={location.name} color={location.color} />
        ))}
      </LabelsContainer>
    </div>
  );
});

BudgetMetricLocationsCell.displayName = 'BudgetMetricLocationsCell';
