import { Table } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { showModal } from '@/actions/uiState';
import { TableRow } from '@/components/common/MDTable/MDTable.types';
import { ADD_ATTENDANCE_MODAL } from '@/constants/modalTypes';
import { ATTENDANCE_EDIT_OTHERS_DISABLE, MANAGER_EDIT_OWN_ATTENDANCES } from '@/constants/Restrictions';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectRestrictionsDict } from '@/redux-store/userPermissions';
import { selectCurrentUserId } from '@/selectors/currentUser.selectors';

import { AttendanceRowItem } from '../AttendanceManyDaysTable.types';
import {
  getAttendancesToCreate,
  getSelectedData,
  getSelectedOvertimeData,
} from './AttendanceManyDaysTableButtonBar.utils';

export type Props = {
  table?: Table<TableRow<AttendanceRowItem>>;
};

export const useAttendanceManyDaysTableButtonBar = ({ table: { getFilteredSelectedRowModel } }: Props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const restrictions = useAppSelector(selectRestrictionsDict);
  const currentUserId = useAppSelector(selectCurrentUserId);

  const createAttendance = useCallback(() => {
    dispatch(showModal(ADD_ATTENDANCE_MODAL));
  }, [dispatch]);

  const filteredSelectedFlatRows = getFilteredSelectedRowModel().flatRows;

  const selectedData = useMemo(() => getSelectedData(filteredSelectedFlatRows), [filteredSelectedFlatRows]);

  const selectedDataWithOvertime = useMemo(() => getSelectedOvertimeData(selectedData), [selectedData]);

  const selectedAttendancesData = useMemo(() => selectedData.map(r => r.attendanceObject), [selectedData]);

  const attendancesToCreate = useMemo(() => getAttendancesToCreate(selectedData), [selectedData]);

  const isEditOwnSelectedDisabled =
    restrictions[MANAGER_EDIT_OWN_ATTENDANCES] && selectedData.some(data => data.employeeObject.id === currentUserId);

  const isEditOthersSelectedDisabled =
    restrictions[ATTENDANCE_EDIT_OTHERS_DISABLE] && selectedData.some(data => data.employeeObject.id !== currentUserId);

  const disabled = isEditOwnSelectedDisabled || isEditOthersSelectedDisabled;

  return {
    createAttendance,
    disabled,
    intl,
    selectedDataWithOvertime,
    selectedAttendancesData,
    attendancesToCreate,
    selectedData,
  };
};
