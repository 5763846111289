import MDTable from '@/components/common/MDTable/MDTable';

import { tableStyles } from './reportsTable.helpers';
import { messages } from './reportsTable.messages.ts';
import { useReportsTable } from './useReportsTable';

import './reportsTable.scss';

const ReportsTable = () => {
  const { data, columns, footerData, intl } = useReportsTable();

  return (
    <div className="k-reportsTable">
      <div className="k-reportsTable__title">{intl.formatMessage(messages.tableTitle)}</div>
      <MDTable {...{ data, columns, footerData, tableStyles }} withSearch={false} />
    </div>
  );
};

export default ReportsTable;
