import { CheckboxCellProps } from './CheckboxCell.types';
import MDTableCheckbox from './MDTableCheckbox';

type Props = CheckboxCellProps;

const CheckboxCell = ({ checked, indeterminate, disabled, onChange, onCustomChange }: Props) => (
  <MDTableCheckbox
    checked={checked}
    indeterminate={indeterminate}
    disabled={disabled}
    onChange={onChange}
    onCustomChange={onCustomChange}
  />
);

export default CheckboxCell;
