import classnames from 'classnames';

import MDIconButton from '../Basic/MDIconButton/MDIconButton';

import './dropdownMenu.scss';

export type DropDownOption = {
  id: string;
  icon: string;
  title: string;
  onClick: () => void;
  disabled?: boolean;
  show?: boolean;
};

type Props = {
  options: DropDownOption[];
};

const DropdownMenu = ({ options }: Props) => (
  <div className="k-dropdownMenu">
    {options.map(({ id, icon, title, onClick, disabled, show }) => {
      const buttonClassName = classnames('k-dropdownMenu__button', { 'k-dropdownMenu__button--disabled': disabled });
      if (!show) return null;
      return (
        <button type="button" className={buttonClassName} key={id} onClick={onClick} disabled={disabled}>
          <MDIconButton icon={icon} />
          <span className="k-dropdownMenu__title">{title}</span>
        </button>
      );
    })}
  </div>
);

export default DropdownMenu;
