import { parseMinutesToHumanForm } from 'kadro-helpers/lib/helpers';
import { IntlShape } from 'react-intl';

import { TABLE_COLUMN_STICKY_ENUM, TableColumnType, TableStyles } from '@/components/common/MDTable/MDTable.types';
import { PayrollSetting } from '@/redux-store/payrollSettings';
import { ReportViewData } from '@/types/reports.types';
import { isEmptyObject, roundToTwoSigDigits } from '@/utils/baseHelpers';
import { formatCurrency, formatPercentage } from '@/utils/numberHelpers';

import { FooterSummaryCell } from './Cells';
import { messages } from './reportsTable.columns.messages';
import { ReportRow } from './ReportsTable.types';

export const tableStyles: TableStyles = {
  hasNoHover: true,
  hasHeaderTextWrapping: true,
  hasDensePadding: true,
  hasLightShadow: true,
};

export const COLUMNS_FOR_SHIFTS_PAYOUT_SETTINGS = [
  'date',
  'plannedMinutes',
  'employeesCount',
  'plannedLaborCost',
  'absencesMinutes',
];

const columnWidth = 160;
const columnWidthXL = 190;

export const getReportTableColumns = (intl: IntlShape, payoutSetting: PayrollSetting): TableColumnType<ReportRow>[] => {
  const isShiftType = payoutSetting.type === 'shifts';

  const columns: TableColumnType<ReportRow>[] = [
    {
      accessorKey: 'date',
      size: 140,
      header: intl.formatMessage(messages.date),
      sticky: TABLE_COLUMN_STICKY_ENUM.LEFT,
      footer: props => <FooterSummaryCell {...props} />,
    },
    {
      accessorKey: 'plannedMinutes',
      size: columnWidth,
      header: intl.formatMessage(messages.plannedMinutes),
      cell: ({ cell }) => parseMinutesToHumanForm(cell.row.original.plannedMinutes),
    },
    {
      accessorKey: 'workedMinutes',
      size: columnWidth,
      header: intl.formatMessage(messages.workedMinutes),
      cell: ({ cell }) => parseMinutesToHumanForm(cell.row.original.workedMinutes),
    },
    {
      accessorKey: 'differenceMinutes',
      size: columnWidth,
      header: intl.formatMessage(messages.differenceMinutes),
      cell: ({ cell }) => parseMinutesToHumanForm(cell.row.original.differenceMinutes),
    },
    {
      accessorKey: 'differencePercent',
      size: columnWidthXL,
      header: intl.formatMessage(messages.differencePercent),
      cell: ({ cell }) => formatPercentage(cell.row.original.differencePercent),
    },
    {
      accessorKey: 'employeesCount',
      size: columnWidth,
      header: intl.formatMessage(messages.employeesCount),
    },
    {
      accessorKey: 'frequency',
      size: columnWidth,
      header: intl.formatMessage(messages.frequency),
      cell: ({ cell }) => formatPercentage(cell.row.original.frequency),
    },
    {
      accessorKey: 'plannedLaborCost',
      size: columnWidthXL,
      header: intl.formatMessage(messages.plannedLaborCost),
      cell: ({ cell }) => formatCurrency(roundToTwoSigDigits(cell.row.original.plannedLaborCost / 100)),
    },
    {
      accessorKey: 'actualLaborCost',
      size: columnWidth,
      header: intl.formatMessage(messages.actualLaborCost),
      cell: ({ cell }) => formatCurrency(roundToTwoSigDigits(cell.row.original.actualLaborCost / 100)),
    },
    {
      accessorKey: 'differenceCost',
      size: columnWidth,
      header: intl.formatMessage(messages.differenceCost),
      cell: ({ cell }) => formatCurrency(roundToTwoSigDigits(cell.row.original.differenceCost / 100)),
    },
    {
      accessorKey: 'differenceCostPercent',
      size: columnWidthXL,
      header: intl.formatMessage(messages.differenceCostPercent),
      cell: ({ cell }) => formatPercentage(cell.row.original.differenceCostPercent),
    },
    {
      accessorKey: 'absencesMinutes',
      size: columnWidth,
      header: intl.formatMessage(messages.absencesMinutes),
      cell: ({ cell }) => parseMinutesToHumanForm(cell.row.original.absencesMinutes),
    },
  ];
  return columns.filter(column => !isShiftType || COLUMNS_FOR_SHIFTS_PAYOUT_SETTINGS.includes(column.accessorKey));
};

export const prepareData = ({ summary: _, ...data }: ReportViewData['tableData']) =>
  Object.entries(data).map(([date, columnDate]) => ({
    ...columnDate,
    date,
  }));

export const getFooterData = ({ summary, ...data }: ReportViewData['tableData']) =>
  isEmptyObject(data) || isEmptyObject(summary)
    ? []
    : [
        {
          plannedMinutes: parseMinutesToHumanForm(summary.plannedMinutes),
          workedMinutes: parseMinutesToHumanForm(summary.workedMinutes),
          differenceMinutes: parseMinutesToHumanForm(summary.differenceMinutes),
          differencePercent: formatPercentage(summary.differencePercent),
          employeesCount: summary.employeesCount,
          frequency: formatPercentage(summary.frequency),
          plannedLaborCost: formatCurrency(roundToTwoSigDigits(summary.plannedLaborCost / 100)),
          actualLaborCost: formatCurrency(roundToTwoSigDigits(summary.actualLaborCost / 100)),
          differenceCost: formatCurrency(roundToTwoSigDigits(summary.differenceCost / 100)),
          differenceCostPercent: formatPercentage(summary.differenceCostPercent),
          absencesMinutes: parseMinutesToHumanForm(summary.absencesMinutes),
        },
      ];
