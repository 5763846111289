import { connect } from 'react-redux';

import { changeEmploymentConditionsFilter } from '@/actions/employmentConditionsFilter';
import { changeJobTitleFilter } from '@/actions/jobtitleFilter';
import { mainDateChangeModeAndDate } from '@/actions/mainDate';
import { changeMultipleLocationFilter } from '@/actions/multipleLocationFilter';
import { getPayrollLocationViewData } from '@/actions/payrollLocation/payrollLocation';
import { hideModal, showModal } from '@/actions/uiState.js';
import { REPORTS_FILTERS_MODAL } from '@/constants/modalTypes';

import PayrollLocationFiltersModal from './PayrollLocationFiltersModal';

const mapStateToProps = ({ reducer }) => ({
  isOpen: reducer.uiState.showModal === REPORTS_FILTERS_MODAL,
  mainDateStore: reducer.mainDateStore,
  locations: reducer.userLocations,
  jobTitles: reducer.userJobTitles,
  employmentConditions: reducer.employmentConditions,
  selectedLocations: reducer.multipleLocationFilter,
  selectedJobTitles: reducer.jobtitleFilter.selectedJobtitles,
  selectedEmploymentConditions: reducer.employmentConditionsFilter.selected,
});

const mapDispatchToProps = {
  hideModal,
  showModal,
  changeMultipleLocationFilter,
  changeJobTitleFilter,
  changeEmploymentConditionsFilter,
  getPayrollLocationViewData,
  mainDateChangeModeAndDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationFiltersModal);
