import { ATTENDANCE_ABSENCE } from '@/constants/attendanceDetailTypes';

import { SubRowType } from '../../AttendanceManyDaysTable.types';
import { AttendanceKebabOptions } from './KebabOptions/KebabOptions';

type Props = {
  rowData?: SubRowType;
};

export const AttendanceOptionCell = ({ rowData }: Props) => {
  if (!rowData || rowData?.type === ATTENDANCE_ABSENCE) return null;

  return <AttendanceKebabOptions rowData={rowData} />;
};
