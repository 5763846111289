import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { ScheduleItemType } from '@/constants/dragAndDrop';
import { useAppSelector } from '@/redux-store';
import { selectShiftForEmployeeByShiftId } from '@/redux-store/shifts';
import { selectUserJobTitle } from '@/redux-store/userJobTitles';
import { selectLocationById } from '@/redux-store/userLocations';
import { getPositionAndLengthForBlock } from '@/utils/shiftHelpers';

import { ScheduleShiftPopup } from '../ScheduleShift/ScheduleShiftPopup/ScheduleShiftPopup';
import { addTooltipToDragImage, dragAndDropCleanup } from '../ScheduleShiftBlock/ScheduleShiftBlock.helpers';
import {
  getClassNames,
  getNewEndForShiftResizing,
  getNewStartAndEndForShiftMoving,
  getNewStartForShiftResizing,
  getWorkingHoursForNewShift,
} from './ScheduleSingleDayShiftBlock.helpers';

const ScheduleSingleDayShiftBlock = (
  {
    title,
    from,
    to,
    draft,
    color,
    hasComment,
    otherLocation,
    isEditingScheduleDisabled,
    blocked,
    date,
    onDragStart,
    onDragEnd,
    editEmployeeShift,
    showEditShiftModal,
    deleteEmployeeShift,
    fullOpacity = false,
    isInvalid,
    shouldConnectWithAbsence,
    isAbsenceDraft,
    className,
    onClick,
    hourFirst,
    children,
    type,
    shiftId,
    employeeId,
    jobTitleNotAssigned,
  },
  { intl },
) => {
  const [isShiftResizing, setIsShiftResizing] = useState(false);
  const [start, setStart] = useState(from);
  const [end, setEnd] = useState(to);
  const [mouseAction, setMouseAction] = useState(null);
  const [currentX, setCurrentX] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isMoved, setIsMoved] = useState(false);
  const isBlockDraggable = !otherLocation && !isEditingScheduleDisabled;
  const isScheduleEditingBlocked = blocked || otherLocation || isEditingScheduleDisabled;
  const blockContent = hourFirst ? `${start}-${end} ${title}` : `${title} ${start}-${end}`;
  const shift = useAppSelector(selectShiftForEmployeeByShiftId(employeeId, shiftId));
  const location = useAppSelector(selectLocationById(shift?.location?.id));
  const jobTitle = useAppSelector(selectUserJobTitle(shift?.job_title?.id));

  useEffect(() => setStart(from), [from]);
  useEffect(() => setEnd(to), [to]);

  const handleMouseMove = useCallback(
    e => {
      if (e.timeStamp < 100) return;
      if (mouseAction === 'resizeLeft') {
        setStart(getNewStartForShiftResizing(from, end, e.clientX, currentX));
      } else if (mouseAction === 'resizeRight') {
        setEnd(getNewEndForShiftResizing(start, to, e.clientX, currentX));
      } else if (mouseAction === 'blockMove') {
        const { newStart, newEnd } = getNewStartAndEndForShiftMoving(from, to, e.clientX, currentX);
        setStart(newStart);
        setEnd(newEnd);
      }
      setCurrentX(e.clientX);
    },
    [mouseAction, from, to, start, end],
  );

  const handleMouseUp = useCallback(
    async e => {
      if (!mouseAction) return;
      e.preventDefault();
      setIsShiftResizing(false);
      setMouseAction(null);
      setIsMoved(false);
      try {
        setLoading(true);
        const workingHours = getWorkingHoursForNewShift(from, to, mouseAction, e.clientX, currentX);
        await editEmployeeShift(workingHours);
      } catch (err) {
        setStart(from);
        setEnd(to);
      } finally {
        setLoading(false);
      }
    },
    [mouseAction, from, to],
  );

  const removeListeners = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (isShiftResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      removeListeners();
    }
    return () => {
      removeListeners();
    };
  }, [isShiftResizing]);

  const handleStartingModyfingShift = (e, action) => {
    if (otherLocation || isEditingScheduleDisabled || loading) return;
    e.preventDefault();
    e.stopPropagation();
    setIsShiftResizing(true);
    setMouseAction(action);
    setCurrentX(e.clientX);
  };

  const resizeLeftStart = e => {
    handleStartingModyfingShift(e, 'resizeLeft');
  };

  const resizeRightStart = e => {
    handleStartingModyfingShift(e, 'resizeRight');
  };

  const moveShiftHorizontal = e => {
    if (otherLocation || isEditingScheduleDisabled || loading || isInvalid) return;
    const dataElement = e.target.getAttribute('data-element');
    if (dataElement !== 'shiftItem' || dataElement === 'dragHandle') return;
    setIsMoved(true);
    handleStartingModyfingShift(e, 'blockMove');
  };

  const editShift = e => {
    if (otherLocation || isEditingScheduleDisabled || loading) return;
    e.stopPropagation();
    showEditShiftModal();
  };

  const deleteShift = e => {
    if (otherLocation || isEditingScheduleDisabled || loading) return;
    e.stopPropagation();
    deleteEmployeeShift();
  };

  const { position, length, minified } = getPositionAndLengthForBlock(start, end, date);

  const {
    className: additionalClassNames,
    textClassName,
    leftDragClassName,
    rightDragClassName,
  } = getClassNames(
    draft,
    otherLocation,
    isEditingScheduleDisabled,
    isMoved,
    fullOpacity,
    isInvalid,
    shouldConnectWithAbsence,
    isAbsenceDraft,
  );

  return (
    <div
      className={classNames(className, additionalClassNames)}
      style={{
        backgroundColor: color,
        left: position,
        width: `${length}%`,
        visibility: length === 0 ? 'hidden' : 'visible',
      }}
      onMouseDown={moveShiftHorizontal}
      role="presentation"
      onClick={onClick}
      draggable={isBlockDraggable && minified}
      onDragStart={e => {
        addTooltipToDragImage(e, intl);
        onDragStart(e);
      }}
      onDragEnd={e => {
        dragAndDropCleanup();
        onDragEnd(e);
      }}
    >
      <ScheduleShiftPopup
        employeeId={employeeId}
        shiftId={shiftId}
        shiftJobTitle={jobTitle}
        isJobTitleNotAssigned={jobTitleNotAssigned}
        isFromOtherLocation={otherLocation}
        shiftLocationName={shift?.location?.name || location?.name}
        withoutPopover={!shiftId}
      >
        <div data-element="shiftItem">
          <div className={leftDragClassName} role="presentation" onMouseDown={resizeLeftStart} />
          {minified ? (
            <>
              {isBlockDraggable && (
                <i className="material-icons  k-shiftBlock__settingsIcon" onClick={editShift} role="presentation">
                  settings
                </i>
              )}
              {hasComment && <i className="material-icons k-shiftBlock__commentIcon">message</i>}
            </>
          ) : (
            <>
              <div className={textClassName} role="presentation" onClick={editShift}>
                {blockContent}
              </div>
              {!isScheduleEditingBlocked ? (
                <i className="material-icons k-shiftBlock__leftIcon" onClick={deleteShift} role="presentation">
                  delete_forever
                </i>
              ) : null}
              {isScheduleEditingBlocked || type === ScheduleItemType.OPEN_SHIFT ? null : (
                <i
                  className="material-icons k-shiftBlock__rightIcon"
                  data-element="dragHandle"
                  role="presentation"
                  draggable={isBlockDraggable}
                  onDragStart={e => {
                    addTooltipToDragImage(e, intl);
                    onDragStart(e);
                  }}
                  onDragEnd={e => {
                    dragAndDropCleanup();
                    onDragEnd(e);
                  }}
                >
                  swap_vert
                </i>
              )}
              {hasComment && <i className="material-icons k-shiftBlock__commentIcon">message</i>}
            </>
          )}
          <div className={rightDragClassName} role="presentation" onMouseDown={resizeRightStart} />
        </div>
        {children}
      </ScheduleShiftPopup>
    </div>
  );
};

ScheduleSingleDayShiftBlock.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleSingleDayShiftBlock.propTypes = {
  title: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  draft: PropTypes.bool,
  color: PropTypes.string,
  shiftId: PropTypes.string,
  employeeId: PropTypes.string,
  jobTitleNotAssigned: PropTypes.bool,
  otherLocation: PropTypes.bool,
  isEditingScheduleDisabled: PropTypes.bool,
  blocked: PropTypes.bool,
  date: PropTypes.string,
  editEmployeeShift: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  showEditShiftModal: PropTypes.func,
  deleteEmployeeShift: PropTypes.func,
  fullOpacity: PropTypes.bool,
  isInvalid: PropTypes.bool,
  shouldConnectWithAbsence: PropTypes.bool,
  isAbsenceDraft: PropTypes.bool,
  hourFirst: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  hasComment: PropTypes.bool,
};

export default ScheduleSingleDayShiftBlock;
