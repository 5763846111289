import { getAbsenceTypePeriod } from '@/utils/absenceHelpers.js';
import { parseMinutesToHumanForm } from '@/utils/dateHelper.js';

import { messages } from './messages.js';

export const getTableData = (
  absenceLimitsUsage,
  absenceLimitsForEmployee,
  absencesTypes,
  userEmployees,
  mainDateStore,
  isEmployee,
  intl,
) =>
  (!isEmployee
    ? absenceLimitsUsage
    : absenceLimitsForEmployee.filter(absenceLimit => {
        const absenceType = absencesTypes.find(type => type.id === absenceLimit.absence_type_id);
        return (
          absenceType.limit_period === mainDateStore.dateMode &&
          absenceLimit.from === mainDateStore.customDate.start &&
          absenceLimit.to === mainDateStore.customDate.end
        );
      })
  ).map(absenceLimit => {
    const employee = userEmployees.find(emp => emp.id === absenceLimit.employee_id);
    const absenceType = absencesTypes.find(type => type.id === absenceLimit.absence_type_id);

    const limit =
      absenceType.limit_unit === 'minutes' ? parseMinutesToHumanForm(absenceLimit.limit) : absenceLimit.limit;
    const left = absenceType.limit_unit === 'minutes' ? parseMinutesToHumanForm(absenceLimit.left) : absenceLimit.left;
    const used = absenceType.limit_unit === 'minutes' ? parseMinutesToHumanForm(absenceLimit.used) : absenceLimit.used;

    const unit = absenceType.limit_unit === 'days' ? intl.formatMessage(messages.absenceTypeUnitDays) : '';
    const period = getAbsenceTypePeriod(absenceType, intl);

    return {
      ...absenceLimit,
      employee: `${employee?.first_name} ${employee?.last_name}`,
      limit:
        absenceLimit.limit || absenceLimit.limit === 0
          ? `${limit} ${unit} / ${period}`
          : intl.formatMessage(messages.absencesLimitsViewAbsenceLimitUnlimited, {}),
      left,
      unit,
      period,
      used:
        absenceLimit.used || absenceLimit.used === 0
          ? `${used} ${unit} / ${period}`
          : intl.formatMessage(messages.absencesLimitsViewAbsenceLimitUnlimited, {}),
      absenceType: absenceType.name,
    };
  });
