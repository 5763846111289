import { defineMessages, IntlShape } from 'react-intl';

import { DropDownOption } from '@/components/common/DropdownMenu/DropdownMenu';

import { AbsenceTypeRow } from '../ManageAbsenceTypesView.types';

export const messages = defineMessages({
  absenceTypesRowOptionsEditIcon: {
    id: 'absenceTypesRowOptions.edit.icon',
    defaultMessage: 'Edytuj',
  },
  absenceTypesRowOptionsDeleteIcon: {
    id: 'absenceTypesRowOptions.delete.icon',
    defaultMessage: 'Usuń',
  },
});

export const getOptions = (
  onEdit: () => void,
  onDelete: () => void,
  intl: IntlShape,
  rowData: AbsenceTypeRow,
): DropDownOption[] => [
  {
    id: 'edit',
    icon: 'create',
    title: intl.formatMessage(messages.absenceTypesRowOptionsEditIcon),
    onClick: onEdit,
    show: true,
  },
  {
    id: 'delete',
    icon: 'delete',
    title: intl.formatMessage(messages.absenceTypesRowOptionsDeleteIcon),
    onClick: onDelete,
    disabled: rowData.is_default_uw_absence || rowData.isOvertimeCollection,
    show: true,
  },
];
