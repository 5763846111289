import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import MDTable from '@/components/common/MDTable/MDTable';
import EmptyState from '@/components/emptyState/EmptyState';

import EmployeeTimesheetButtonBar from '../EmployeeTimesheetButtonBar/EmployeeTimesheetButtonBar';
import EmployeeTimesheetOptions from '../EmployeeTimesheetOptions/EmployeeTimesheetOptions.redux';
import { getColumns } from './columns';
import { getTableData } from './EmployeeTimesheetTable.helpers';
import { messages } from './EmployeeTimesheetTable.messages';

const EmployeeTimesheetTable = props => {
  const intl = useIntl();

  const columns = useMemo(() => getColumns(intl), [intl]);

  const employee = props.userEmployees.find(({ id }) => props.userId === id);
  const absences = props.scheduleAbsences[employee.id] || [];

  return (
    <MDTable
      data={getTableData(props.mainDateStore, employee, absences, props.userLocations, props.absencesTypes)}
      columns={columns}
      tableOptions={<EmployeeTimesheetButtonBar showModal={props.showModal} />}
      options={<EmployeeTimesheetOptions />}
      withSearch={false}
      tableEmpty={
        <EmptyState
          name="jobtitleCTA"
          imgSrc="/img/jobtitlesCTA.png"
          emptyStateHeader={intl.formatMessage(messages.emptyTable)}
          shiftX="0"
        />
      }
    />
  );
};

EmployeeTimesheetTable.propTypes = {
  userId: PropTypes.string,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  scheduleAbsences: PropTypes.shape({}),
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EmployeeTimesheetTable;
